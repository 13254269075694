<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="createModel.account" />
              <span v-if="accountError" class="input-error">{{ $t(`${i18nPrefix}dialog.accountCheck`) }}</span>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.password`) }}
              <input type="text" v-model="createModel.password" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}customRole`) }}
              <select v-model="createModel.customRoleId">
                <option
                  v-for="list of customRoleList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click.self="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @mousedown.self="$emit('toggle')">
              {{ $t("default.cancel") }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validForm } from "./utils";
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ["customRoleList","i18nPrefix"],
  data() {
    return {
      createModel: {
        status: 1,
        account: undefined,
        password: undefined,
        customRoleId: this.customRoleList[0]?.id,
      },
      accountError: false,
    };
  },
  computed: {
    ...mapGetters(["getStatusList"]),
  },
  watch: {
    "createModel.account"(val) {
      var reg = /^[A-Za-z0-9]+$/;
      if (reg.test(val)) {
        this.accountError = false;
      } else {
        this.accountError = true
      }
    }
  },
  methods: {
    submit() {
      if (this.accountError) {
        Message.error(
          this.$t(`${this.i18nPrefix}dialog.accountCheck`),
          1000
        );
        return;
      }

      let result = validForm(this.createModel);
      result ? alert(result) : this.$emit("create", this.createModel);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-block {
  label {
    width: 80%;
    position: relative;
    .input-error {
      position: absolute;
      bottom: -20px;
      color: #f00;
    }
  }
}
span {
    display: inline;
}
</style>