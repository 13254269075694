import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getList(data) {
    return request({
        url: "/MemberRank/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}


export function getMemberRankExcel(data) {
    return request({
        url: "/MemberRank/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}