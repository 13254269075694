export let searchModel = {
    startTime: undefined,
    endTime: undefined,
    memberAccount: "",
    gamePlatform: "0",
    agentId: 0,
    gameOrderNumber: "",
    page:  {
        "take": 10,
        "skip": 0
    },
    sort: [
        {
            "column": "betTime",
            "order": 2
        }
    ]
}