<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.detail") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("default.member") }}</th>
                  <th>{{ $t("default.gamesCategory") }}</th>
                  <th>{{ $t(`${i18nPrefix}validBetAmount`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!tableList || !tableList.length">
                  <td colspan="10">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>{{ list.memberAccount }}</td>
                  <td>{{ GLOBAL.i18nCheckName(GamesCategorys.find(data => data.id === list.gamesCategory)) }}</td>
                  <td>{{ list.validBetAmount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRebateDetail
} from "@/api/campaign/memberReferral";

export default {
  name: "Detail",
  props: ["id", "GamesCategorys", "i18nPrefix"],
  data() {
    return {
      tableList: [],
    };
  },
  async created() {
    await getRebateDetail({ rebateListId: this.id }).then(res => { this.tableList = res.data })
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 80%;
}
</style>