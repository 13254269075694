<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-status">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.status") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="member-info">
              <label>
                {{ $t(`${i18nPrefix}dialog.status.whetherToOpen`) }}
                <div class="slide-block" :class="{ 'slide-block-on': statusModel.status }">
                  <div
                    class="slide"
                    @click.self="toggleSlide"
                    :class="{ 'slide-on': statusModel.status }"
                  >
                    <label for="status"></label>
                    <input type="checkbox" id="status" v-model="statusModel.status" />
                  </div>
                </div>
              </label>
              <label>
                {{ $t(`${i18nPrefix}new`) }}
                <div class="slide-block" :class="{ 'slide-block-on': statusModel.isNew }">
                  <div
                    class="slide"
                    @click.self="toggleSlide"
                    :class="{ 'slide-on': statusModel.isNew }"
                  >
                    <label for="isNew"></label>
                    <input type="checkbox" id="isNew" v-model="statusModel.isNew" />
                  </div>
                </div>
              </label>
              <label>
                {{ $t(`${i18nPrefix}seamless`) }}
                <div class="slide-block" :class="{ 'slide-block-on': statusModel.enableSeamless }">
                  <div
                    class="slide"
                    @click.self="toggleSlide"
                    :class="{ 'slide-on': statusModel.enableSeamless }"
                  >
                    <label for="enableSeamless"></label>
                    <input type="checkbox" id="enableSeamless" v-model="statusModel.enableSeamless" />
                  </div>
                </div>
              </label>
            </div>
            <div class="deposit-channel-info">
              <h4>{{ $t(`${i18nPrefix}dialog.status.depositChannel`) }}</h4>
              <div class="divider"></div>
              <label
                v-for="list of statusModel.paymentGateways"
                :key="list.paymentGatewayId"
              >
                {{ $t("DepositTypeList." + list.paymentGatewayId)}}
                <div class="slide-block" :class="{ 'slide-block-on': list.enable }">
                  <div class="slide" @click.self="toggleSlide" :class="{ 'slide-on': list.enable }">
                    <label :for="list.paymentGatewayId"></label>
                    <input type="checkbox" :id="list.paymentGatewayId" v-model="list.enable" />
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { toggleStyle } from "@/utils/slideUtils";

export default {
  name: "Status",
  props: [
    "id",
    "status",
    "isNew",
    "enableSeamless",
    "paymentGateways",
    "i18nPrefix"
  ],
  data() {
    return {
      statusModel: {
        id: this.id,
        status: this.status,
        isNew: this.isNew,
        enableSeamless: this.enableSeamless,
        paymentGateways: _.cloneDeep(this.paymentGateways)
      }
    };
  },
  created() {
    for (let i in this.statusModel) {
      if (this.statusModel[i] === 1) this.statusModel[i] = true;
      if (this.statusModel[i] === 2) this.statusModel[i] = false;
    }
  },
  methods: {
    toggleSlide: toggleStyle,
    submit() {
      let data = _.cloneDeep(this.statusModel);
      for (let i in data) {
        if (data[i] === true) data[i] = 1;
        if (data[i] === false) data[i] = 2;
      }
      this.$emit("status", data);
    }
  }
};
</script>
<style lang="scss" scoped>
main {
  .input-block {
    h4 {
      margin-top: 40px;
      display: block;
    }
    label {
      align-items: center;
      width: 30%;
    }
    .member-info,
    .deposit-channel-info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .member-info {
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 960px) {
  .input-block {
    label {
      width: 30% !important;
    }
  }
  .advance-block {
    align-items: center;
    label {
      margin: 5px auto;
    }
    button {
      margin: 5px auto;
    }
  }
}
</style>