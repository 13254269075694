import Vue from 'vue'
import Vuex from 'vuex'
import app from "./modules/app";
import user from "./modules/user";
import confirm from "./modules/confirm";
import dialog from "./modules/dialog";
import select_menu from "./modules/select_menu";
import websocket from "./modules/websocket";
import getters from "./getters";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex)

const appStore = vuexPersistence("app");
const userStore = vuexPersistenceStorage("user");
const confirmStore = vuexPersistence("confirm");
const dialogStore = vuexPersistence("dialog");
const select_menuStore = vuexPersistence("select_menu");

const store = new Vuex.Store({
  modules: {
    app,
    user,
    confirm,
    dialog,
    select_menu,
    // websocket
  },
  getters,
  plugins: [
    appStore,
    userStore,
    confirmStore,
    dialogStore,
    select_menuStore,
  ]
});

function vuexPersistence(name) {
  return createPersistedState({
    paths: [name],
    key: `${name}Store`,
    getState: (key) => Cookies.getJSON(key),
    setState: (key, state) => Cookies.set(key, state)
  });
}

function vuexPersistenceStorage(name) {
  return createPersistedState({
    // 存储方式：localStorage、sessionStorage、cookies
    storage: window.localStorage,
    // 存储的 key 的key值
    key: name,
    render(state) {
      // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
      return { ...state };
    }
  });
}

export default store;