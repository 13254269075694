var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t("default.create") +
                    " " +
                    _vm.$t("title." + _vm.$route.meta.title)
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.DiscountSettingId,
                        expression: "createModel.DiscountSettingId",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "DiscountSettingId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.discountSettings, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(" " + _vm._s(list.name) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("label"),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.SelectMembers.type,
                        expression: "createModel.SelectMembers.type",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel.SelectMembers,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.member, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(" " + _vm._s(list.name) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", { staticStyle: { "justify-content": "flex-end" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.createModel.SelectMembers.type === 2,
                        expression: "createModel.SelectMembers.type === 2",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.SelectMembers.agentid,
                        expression: "createModel.SelectMembers.agentid",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel.SelectMembers,
                          "agentid",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.agentList, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(" " + _vm._s(list.name) + " ")]
                    )
                  }),
                  0
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.createModel.SelectMembers.type === 3,
                      expression: "createModel.SelectMembers.type === 3",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.members,
                      expression: "members",
                    },
                  ],
                  attrs: {
                    placeholder: _vm.$t(`${_vm.i18nPrefix}dialog.placeholder`),
                  },
                  domProps: { value: _vm.members },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.members = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}firstDepositAmount`)) +
                      " "
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.FirstDepositAmount,
                      expression: "createModel.FirstDepositAmount",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.FirstDepositAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "FirstDepositAmount",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}bonusReceive`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.bonusReceive,
                      expression: "createModel.bonusReceive",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.bonusReceive },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "bonusReceive",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}totalBetAmountLimit`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.totalBetAmountLimit,
                      expression: "createModel.totalBetAmountLimit",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.totalBetAmountLimit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "totalBetAmountLimit",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "remark" }, [
                _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.Remark,
                      expression: "createModel.Remark",
                    },
                  ],
                  domProps: { value: _vm.createModel.Remark },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "Remark", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.create } },
                [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }