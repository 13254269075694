export function download(res, fileName) {
    var FILE = window.URL.createObjectURL(res);
    var docUrl = document.createElement('a');
    docUrl.href = FILE;
    docUrl.setAttribute('download', fileName);
    document.body.appendChild(docUrl);
    docUrl.click();
}

export function downloadCanvas(res, fileName) {
    var docUrl = document.createElement('a');
    docUrl.download = fileName;
    docUrl.href = res;
    document.body.appendChild(docUrl);
    docUrl.click();
    docUrl.remove()
}