import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function officeuserList() {
    return request({
        url: "/officeuser/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createOfficeuser(data) {
    return request({
        url: "/officeuser/create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function editOfficeuser(data) {
    return request({
        url: "/officeuser/update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}