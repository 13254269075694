export let searchModel = {
    agentId: 0,
    path: '',
    status: 0,
    title: undefined,
    publishStateTime: undefined,
    publishEndTime: undefined,
    page:  {
        "take": 10,
        "skip": 0
    },
    sort: [
        {
            "column": "publishTime",
            "order": 2
        }
    ]
}