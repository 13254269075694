<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}editTag`) }}</h4>
        </div>
        <div class="divider"></div>
        <h5>{{ $t(`${i18nPrefix}dialog.editTag.optionalTag`) }}</h5>
        <template v-for="(tag) of tagList">
          <span :style="{ 'color': tag.fontColor ,
                          'background-color': tag.backgroundColor,
                          'font-weight': tag.isBoldFont ? 'bolder' : 'normal'
                        }"
                @click="$emit('editTag', { type: 'add', data:{ memberId: id, tagId: tag.id }})">
            {{ tag.name }}
          </span>
        </template>
        <h5 v-if="haveTag" style="margin-top: 20px">{{ $t(`${i18nPrefix}dialog.editTag.selectedTag`) }}</h5>
        <template v-for="(tag) of haveTag">
          <span :style="{ 'color': Tags.find(data => data.id === tag).fontColor ,
                          'background-color': Tags.find(data => data.id === tag).backgroundColor,
                          'font-weight': Tags.find(data => data.id === tag).isBoldFont ? 'bolder' : 'normal'
                        }"
                @click="$emit('editTag', { type: 'delete', data:{ memberId: id, tagId: tag }})">
            {{ Tags.find(data => data.id === tag).name }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "EditTag",
  props: [
    "id",
    "Tags",
    "haveTag",
    "i18nPrefix"
  ],
  data() {
    return {
      tagList: [],
    }
  },
  created() {
    this.tagList = (this.haveTag) ? this.Tags.filter(el => !this.haveTag.map(data => { return data }).includes(el.id)) : this.Tags;
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  h4 {
    white-space:nowrap;
  }
  span {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #999;
    border-radius: 4px;
    min-width: 50px;
    margin: 5px 2px;
    text-align: center;
    font-size: 1.5em !important;
    padding: 0 10px;
  }
}
</style>