var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "header" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            _c(
              "button",
              {
                staticClass: "confirm-btn main-style-btn",
                attrs: {
                  disabled:
                    !_vm.$store.getters.getUserinfo.permissions.includes(70201),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(
                      { customRoleList: _vm.customRoleList },
                      "Create"
                    )
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.create")))]
            ),
          ]),
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "15%" } }),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}account`))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}customRole`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createTime`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateTime`))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.$store.getters.getUserinfo.permissions.includes(
                                  70202
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(
                                  {
                                    data: list,
                                    customRoleList: _vm.customRoleList,
                                  },
                                  "Edit"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.edit")))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.account))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            list.status === 1
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.customRoleList.find(
                                  (data) => data.id === list.customRoleId
                                )
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.createTime))]),
                      _c("td", [_vm._v(_vm._s(list.updateTime))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }