<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-status">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.star") }}</h4>
          <button class="confirm-btn main-style-btn" @click="$emit('updateLevel', member)">{{ $t("default.confirm") }}</button>
        </div>
        <div class="divider"></div>
        <label>
            <select v-model="member.star">
              <option
                v-for="list of stars"
                :key="list.star"
                :value="list.star"
              >{{ list.star + $t("default.star") }}</option>
            </select>
          </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Level",
  props: [
    "id",
    "star",
    "stars",
    "i18nPrefix"
  ],
  data() {
    return {
      member: {
        id: this.id,
        star: this.star
      }
    }
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.dialog-container{
  width: 20% !important;
  label {
    width: 100%;
  }
  select{
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .dialog-container{
    width: 90% !important;
  }
}
</style>