import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function starLevelList(data) {
    return request({
        url: "/memberStarLevel/list",
        method: "POST",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createStarLevel(data) {
    return request({
        url: "/memberStarLevel/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateStarLevel(data) {
    return request({
        url: "/memberStarLevel/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteStarLevel(data) {
    return request({
        url: "/memberStarLevel/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function upRecordList() {
    return request({
        url: "/starLevelUpRecord/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function upRecordCalculate(data) {
    return request({
        url: "/starLevelUpRecord/calculate",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteUpCalculate(data) {
    return request({
        url: "/starLevelUpRecord/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function starLevelUpList(data) {
    return request({
        url: "/starLevelUpList/query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function starLevelUpAudit(data) {
    return request({
        url: "/starLevelUpList/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function rebateWeekly(data) {
    return request({
        url: "/memberRebate/calculate",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function selectMenu() {
    return request({
        url: "/starLevelUpList/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function upRecordSelectMenu() {
    return request({
        url: "/starLevelUpRecord/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDiscountList() {
    return request({
        url: "/memberRebate/DiscountSettings",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function rebateList() {
    return request({
        url: "/memberRebate/List",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function starLevelListV2() {
    return request({
        url: "/MemberStarLevelV2/list",
        method: "POST",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createStarLevelV2(data) {
    return request({
        url: "/MemberStarLevelV2/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateStarLevelV2(data) {
    return request({
        url: "/MemberStarLevelV2/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteStarLevelV2(data) {
    return request({
        url: "/MemberStarLevelV2/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
