import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function SelectMenu() {
    return request({
        url: "/GameInfo/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGameInfoList(data) {
    return request({
        url: "/GameInfo/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createGameInfo(data) {
    return request({
        url: "/GameInfo/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteGameInfo(data) {
    return request({
        url: "/GameInfo/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateGameInfo(data) {
    return request({
        url: "/GameInfo/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateIsHot(data) {
    return request({
        url: "/GameInfo/UpdateIsHot",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateIsNew(data) {
    return request({
        url: "/GameInfo/UpdateIsNew",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}