<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button
          class="create-btn main-style-btn"
          @click="create"
          :disabled="!$store.getters.getUserinfo.permissions.includes(61701)"
        >{{ $t(`${i18nPrefix}create`) }}</button>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}name`) }}
            <input type="text" v-model="createForm.name" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}fontColor`) }}
            <input
              class="color"
              type="color"
              v-model="createForm.fontColor"
            />
          </label>
          <label>
            {{ $t(`${i18nPrefix}backgroundColor`) }}
            <input
              class="color"
              type="color"
              v-model="createForm.backgroundColor"
            />
          </label>
          <label>
            {{ $t(`${i18nPrefix}isBoldFont`) }}
            <input
              type="checkbox"
              v-model="createForm.isBoldFont"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}name`) }}</th>
              <th>{{ $t(`${i18nPrefix}fontColor`) }}</th>
              <th>{{ $t(`${i18nPrefix}backgroundColor`) }}</th>
              <th>{{ $t(`${i18nPrefix}isBoldFont`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || tableList.length === 0">
              <td colspan="6">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList">
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog( list , 'edit')"
                  :disabled="!$store.getters.getUserinfo.permissions.includes(61702)"
                >{{ $t("default.edit") }}</button>
              </td>
              <td>{{ list.name }}</td>
              <td>
                <div class="tdColor" :style="{ backgroundColor: list.fontColor }"></div>
              </td>
              <td>
                <div class="tdColor" :style="{ backgroundColor: list.backgroundColor }"></div>
              </td>
              <td>{{ list.isBoldFont ? $t("default.active") : $t("default.inactive") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @update="update"
      ></component>
    </transition>
  </div>
</template>

<script>
import { tagList, createTag, updateTag } from "@/api/features/tagManagement";
import Message from "@/layout/components/Message";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Edit from "./dialog/edit.vue";

export default {
  name: "TagManagement",
  data() {
    return {
      createForm: {
        name: undefined,
        fontColor: "#000000",
        backgroundColor: "#FFFFFF",
        isBoldFont: false
      },

      i18nPrefix: "features.tagManagement."
    };
  },
  components: {
    Edit,
    Pagination
  },
  mixins: [handlePageUtils, handleDialog],
  created() {
    this.search();
  },
  methods: {
    search() {
      this.closeDialog();
      tagList().then(res => (this.allData = res.data));
    },
    create() {
      if (!this.createForm.name) {
        Message.error(
          this.$t("default.enterPlz") + " " + this.$t(`${this.i18nPrefix}name`),
          1000
        );
        return;
      }
      createTag(this.createForm).then(res => {
        if (res) this.search();
      });
    },
    update(data) {
      updateTag(data).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.color {
  padding: 0 10px !important;
}
label {
  align-items: center !important;
}
.tdColor {
  padding: 5%;
  border-style: solid;
  border-radius: 10px;
}
</style>