var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t(`${_vm.i18nPrefix}dialog.game-wallet.gameTransfer`)
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("div", { staticClass: "member-info" }, [
                _c("label", { staticClass: "member" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customGameAccount,
                        expression: "customGameAccount",
                      },
                    ],
                    attrs: { type: "text", disabled: true },
                    domProps: { value: _vm.customGameAccount },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.customGameAccount = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.amount")) + " "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.amount,
                        expression: "amount",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number", min: _vm.multiples * 1 },
                    domProps: { value: _vm.amount },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.amount = _vm._n($event.target.value)
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "transfer" },
                  [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.game-wallet.from`)
                          ) +
                          " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gamePlatformFrom,
                            expression: "gamePlatformFrom",
                          },
                        ],
                        attrs: { type: "text", disabled: true },
                        domProps: { value: _vm.gamePlatformFrom },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.gamePlatformFrom = $event.target.value
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `${_vm.i18nPrefix}dialog.game-wallet.balance`
                            )
                          ) +
                          " "
                      ),
                      _c("input", {
                        attrs: { type: "text", disabled: true },
                        domProps: {
                          value: new Intl.NumberFormat().format(_vm.amountFrom),
                        },
                      }),
                    ]),
                    _c("font", { on: { click: _vm.change } }, [_vm._v("⇌")]),
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.game-wallet.to`)
                          ) +
                          " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gamePlatformTo,
                            expression: "gamePlatformTo",
                          },
                        ],
                        attrs: { type: "text", disabled: true },
                        domProps: { value: _vm.gamePlatformTo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.gamePlatformTo = $event.target.value
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `${_vm.i18nPrefix}dialog.game-wallet.balance`
                            )
                          ) +
                          " "
                      ),
                      _c("input", {
                        attrs: { type: "text", disabled: true },
                        domProps: {
                          value: new Intl.NumberFormat().format(_vm.amountTo),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: { click: _vm.alert },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.game-wallet.transfer`)
                    )
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.transfer,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }