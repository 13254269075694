var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(10601),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}create`)) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "divider" }),
        _c("h5", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}setStar`)))]),
        _c("main", [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}memberLevel`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}count`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}amountRange`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}liveCasinoRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}slotRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}fishingRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}lotteryRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}sportRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}eSportRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}cardGameRate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}cockFighting`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}arcade`)))]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.starLevelList || !_vm.starLevelList.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "12" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.starLevelList, function (list, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "normal-btn mr-3",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(10602),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(list, "Edit")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(10603),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(list, "deleteStarLevel")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.delete")) + " ")]
                        ),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(list.star + _vm.$t("default.star"))),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.count))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.digital.format(list.fromAmount)) +
                            " - " +
                            _vm._s(_vm.digital.format(list.toAmount))
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.promotionBonus))]),
                      _c("td", [_vm._v(_vm._s(list.liveCasinoRate))]),
                      _c("td", [_vm._v(_vm._s(list.slotRate))]),
                      _c("td", [_vm._v(_vm._s(list.fishingRate))]),
                      _c("td", [_vm._v(_vm._s(list.lotteryRate))]),
                      _c("td", [_vm._v(_vm._s(list.sportRate))]),
                      _c("td", [_vm._v(_vm._s(list.eSportRate))]),
                      _c("td", [_vm._v(_vm._s(list.cardGameRate))]),
                      _c("td", [_vm._v(_vm._s(list.cockFightingRate))]),
                      _c("td", [_vm._v(_vm._s(list.arcadeRate))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}starUp`)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                staticStyle: { margin: "auto" },
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(10604),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleCalc()
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}handleCalc`)) + " "
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "divider" }),
        _c("main", [
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}betTimeFrom`)) + " "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.startTime,
                    expression: "startTime",
                  },
                ],
                attrs: { type: "date", step: "1" },
                domProps: { value: _vm.startTime },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.startTime = $event.target.value
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}betTimeTo`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.endTime,
                    expression: "endTime",
                  },
                ],
                attrs: { type: "date", step: "1" },
                domProps: { value: _vm.endTime },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.endTime = $event.target.value
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}calculate`)) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.upRecord.type,
                      expression: "upRecord.type",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.upRecord,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.upRecordType, function (list) {
                  return _c(
                    "option",
                    { key: list.id, domProps: { value: list.id } },
                    [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.upRecord.remark,
                    expression: "upRecord.remark",
                  },
                ],
                domProps: { value: _vm.upRecord.remark },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.upRecord, "remark", $event.target.value)
                  },
                },
              }),
            ]),
            _c("label", { staticClass: "deleteBox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deleteCheck,
                    expression: "deleteCheck",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.deleteCheck)
                    ? _vm._i(_vm.deleteCheck, null) > -1
                    : _vm.deleteCheck,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.deleteCheck,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.deleteCheck = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.deleteCheck = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.deleteCheck = $$c
                    }
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}showDel`)) + " "),
            ]),
          ]),
          _c("main", [
            _c(
              "div",
              { staticClass: "table-block" },
              [
                _c("table", { staticClass: "mt-3" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}id`)))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betTimeFrom`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betTimeTo`))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}upMemberCount`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}downMemberCount`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}untreatedCount`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}approveCount`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}refuseCount`))),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.admin")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}approveTime`))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.tableList, function (list, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "normal-btn mr-3",
                              attrs: {
                                disabled:
                                  list.starLevelUpStatus === 4 ||
                                  !_vm.getUserinfo.permissions.includes(10604),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(list, "UpRecordEdit")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(list.id) + " ")]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: list.starLevelUpStatus !== 4,
                                  expression: "list.starLevelUpStatus !== 4",
                                },
                              ],
                              staticClass: "delete-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(10604),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    list,
                                    "deleteUpCalculate"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("default.delete")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.betTimeFrom))]),
                        _c("td", [_vm._v(_vm._s(list.betTimeTo))]),
                        _c("td", [_vm._v(_vm._s(list.upMemberCount))]),
                        _c("td", [_vm._v(_vm._s(list.downMemberCount))]),
                        _c("td", [_vm._v(_vm._s(list.untreatedCount))]),
                        _c("td", [_vm._v(_vm._s(list.approveCount))]),
                        _c("td", [_vm._v(_vm._s(list.refuseCount))]),
                        _c("td", [_vm._v(_vm._s(list.remark))]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.GLOBAL.i18nCheckName(
                                  _vm.i18nPrefix +
                                    _vm.starLevelUpStatusList.find(
                                      (data) =>
                                        data.id === list.starLevelUpStatus
                                    )
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.approveAccount))]),
                        _c("td", [_vm._v(_vm._s(list.approveTime))]),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm.totalCount > 0
                  ? _c("Pagination", {
                      attrs: { totalCount: _vm.totalCount },
                      on: { handlePage: _vm.handlePage },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.getShowConfirm
            ? _c("Confirm", {
                on: { toggle: _vm.TOGGLE_CONFIRM, delete: _vm.deleteApi },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.upRecordCalc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  agentList: _vm.getAgentList,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                  UpAudit: _vm.starLevelUpAudit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }