<template>
  <div>
    <div class="imgDiv">
      <div class="previewDiv" @click="upload('File')" @drop="drop" @dragenter="stopDefault" @dragleave="stopDefault"
        @dragover="stopDefault">
        <div v-if="!File" class="upload">
          <img class="icon" src="@/assets/icon/upload.svg" />
          <span>{{ $t('default.uploadFile') }}</span>
        </div>
        <span v-else>{{ File.name }}</span>
      </div>
    </div>
    <input type="file" ref="file" class="custom-file" @change="fileSelect" />
  </div>
</template>

<script>
export default {
  name: "Excel",
  data() {
    return {
      File: undefined,
      chooiceFile: undefined
    };
  },
  methods: {
    upload(tag) {
      this.chooiceFile = tag;
      this[tag] = "";
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e, drop = false) {
      const dt = drop ? e.dataTransfer.files[0] : e.target.files[0];
      if (!dt) return;
      this.File = dt;

      this.$emit("UpdateFile", dt);
    },
    drop(e) {
      this.stopDefault(e);
      this.fileSelect(e, true);
    },
    stopDefault(e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped>
.imgDiv {
  display: flex;
  flex-wrap: wrap;

  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 400px;
    // padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;

    span {
      display: block;
      font-size: 20px;
    }

    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }

    .upload {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #749AAF;
      border: 1px solid #537081;
      border-radius: 10px;
      color: #fff;

      .icon {
        width: 60px;
      }
    }
  }
}

.custom-file {
  position: absolute;
  visibility: hidden;
}
</style>