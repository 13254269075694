import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberWinLoseList(data) {
    return request({
        url: "/MemberWinLose/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}