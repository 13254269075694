var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-status" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.status")))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("div", { staticClass: "member-info" }, [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}dialog.status.whetherToOpen`)
                      ) +
                      " "
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "slide-block",
                      class: { "slide-block-on": _vm.statusModel.status },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "slide",
                          class: { "slide-on": _vm.statusModel.status },
                          on: {
                            click: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.toggleSlide.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("label", { attrs: { for: "status" } }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.statusModel.status,
                                expression: "statusModel.status",
                              },
                            ],
                            attrs: { type: "checkbox", id: "status" },
                            domProps: {
                              checked: Array.isArray(_vm.statusModel.status)
                                ? _vm._i(_vm.statusModel.status, null) > -1
                                : _vm.statusModel.status,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.statusModel.status,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.statusModel,
                                        "status",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.statusModel,
                                        "status",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.statusModel, "status", $$c)
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}new`)) + " "),
                  _c(
                    "div",
                    {
                      staticClass: "slide-block",
                      class: { "slide-block-on": _vm.statusModel.isNew },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "slide",
                          class: { "slide-on": _vm.statusModel.isNew },
                          on: {
                            click: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.toggleSlide.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("label", { attrs: { for: "isNew" } }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.statusModel.isNew,
                                expression: "statusModel.isNew",
                              },
                            ],
                            attrs: { type: "checkbox", id: "isNew" },
                            domProps: {
                              checked: Array.isArray(_vm.statusModel.isNew)
                                ? _vm._i(_vm.statusModel.isNew, null) > -1
                                : _vm.statusModel.isNew,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.statusModel.isNew,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.statusModel,
                                        "isNew",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.statusModel,
                                        "isNew",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.statusModel, "isNew", $$c)
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("label", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}seamless`)) + " "
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "slide-block",
                      class: {
                        "slide-block-on": _vm.statusModel.enableSeamless,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "slide",
                          class: { "slide-on": _vm.statusModel.enableSeamless },
                          on: {
                            click: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.toggleSlide.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("label", { attrs: { for: "enableSeamless" } }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.statusModel.enableSeamless,
                                expression: "statusModel.enableSeamless",
                              },
                            ],
                            attrs: { type: "checkbox", id: "enableSeamless" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.statusModel.enableSeamless
                              )
                                ? _vm._i(_vm.statusModel.enableSeamless, null) >
                                  -1
                                : _vm.statusModel.enableSeamless,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.statusModel.enableSeamless,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.statusModel,
                                        "enableSeamless",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.statusModel,
                                        "enableSeamless",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.statusModel,
                                    "enableSeamless",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "deposit-channel-info" },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}dialog.status.depositChannel`)
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _vm._l(_vm.statusModel.paymentGateways, function (list) {
                    return _c("label", { key: list.paymentGatewayId }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("DepositTypeList." + list.paymentGatewayId)
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "slide-block",
                          class: { "slide-block-on": list.enable },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "slide",
                              class: { "slide-on": list.enable },
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  return _vm.toggleSlide.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("label", {
                                attrs: { for: list.paymentGatewayId },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.enable,
                                    expression: "list.enable",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: list.paymentGatewayId,
                                },
                                domProps: {
                                  checked: Array.isArray(list.enable)
                                    ? _vm._i(list.enable, null) > -1
                                    : list.enable,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = list.enable,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            list,
                                            "enable",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            list,
                                            "enable",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(list, "enable", $$c)
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }