var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-member" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    `${_vm.i18nPrefix}dialog.balance-adjustment.adjustmentType4`
                  )
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account,
                      expression: "account",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.account = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `${_vm.i18nPrefix}dialog.balance-adjustment.bonusReceive`
                        )
                      ) +
                      " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.redPacket.bonusReceive,
                        expression: "redPacket.bonusReceive",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.redPacket.bonusReceive },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.redPacket,
                          "bonusReceive",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `${_vm.i18nPrefix}dialog.balance-adjustment.totalBetAmountLimit`
                        )
                      ) +
                      " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.redPacket.totalBetAmountLimit,
                        expression: "redPacket.totalBetAmountLimit",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.redPacket.totalBetAmountLimit },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.redPacket,
                          "totalBetAmountLimit",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.redPacket.remark,
                        expression: "redPacket.remark",
                      },
                    ],
                    attrs: { cols: "30", rows: "6" },
                    domProps: { value: _vm.redPacket.remark },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.redPacket, "remark", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("redPacket", _vm.redPacket)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }