var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "agent-block" }, [
      _c("div", { staticClass: "content-block" }, [
        _c("div", { staticClass: "container-view" }, [
          _c(
            "button",
            {
              staticClass: "search-btn main-style-btn",
              on: {
                click: function ($event) {
                  return _vm.search()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("default.search")))]
          ),
          _c("div", { staticClass: "header" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.agentId !== _vm.getUserinfo.parentId,
                    expression: " agentId  !== getUserinfo.parentId ",
                  },
                ],
                staticClass: "normal-btn",
                on: {
                  click: function ($event) {
                    return _vm.passAgentId(_vm.backAgentId)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}back`)) + " ")]
            ),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c(
                "label",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}startTime`)) + " "
                  ),
                  _c("date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD HH",
                      "value-type": "format",
                      type: "datetime",
                    },
                    model: {
                      value: _vm.startTime,
                      callback: function ($$v) {
                        _vm.startTime = $$v
                      },
                      expression: "startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`)) + " "
                  ),
                  _c("date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD HH",
                      "value-type": "format",
                      type: "datetime",
                    },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "permission-block" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}isIgnore`)))]),
              _c("div", { staticClass: "permission-box" }, [
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreCashDeposit,
                        expression: "searchForm.options.isIgnoreCashDeposit",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreCashDeposit
                      )
                        ? _vm._i(
                            _vm.searchForm.options.isIgnoreCashDeposit,
                            null
                          ) > -1
                        : _vm.searchForm.options.isIgnoreCashDeposit,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreCashDeposit,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreCashDeposit",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreCashDeposit",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreCashDeposit",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}cashDeposit`)) + " "
                  ),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnorePaymentDeposit,
                        expression: "searchForm.options.isIgnorePaymentDeposit",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnorePaymentDeposit
                      )
                        ? _vm._i(
                            _vm.searchForm.options.isIgnorePaymentDeposit,
                            null
                          ) > -1
                        : _vm.searchForm.options.isIgnorePaymentDeposit,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnorePaymentDeposit,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnorePaymentDeposit",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnorePaymentDeposit",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnorePaymentDeposit",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}paymentDeposit`)) +
                      " "
                  ),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreCashWithdrawal,
                        expression: "searchForm.options.isIgnoreCashWithdrawal",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreCashWithdrawal
                      )
                        ? _vm._i(
                            _vm.searchForm.options.isIgnoreCashWithdrawal,
                            null
                          ) > -1
                        : _vm.searchForm.options.isIgnoreCashWithdrawal,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreCashWithdrawal,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreCashWithdrawal",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreCashWithdrawal",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreCashWithdrawal",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}cashWithdrawal`)) +
                      " "
                  ),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreAdjustmentAdd,
                        expression: "searchForm.options.isIgnoreAdjustmentAdd",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreAdjustmentAdd
                      )
                        ? _vm._i(
                            _vm.searchForm.options.isIgnoreAdjustmentAdd,
                            null
                          ) > -1
                        : _vm.searchForm.options.isIgnoreAdjustmentAdd,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreAdjustmentAdd,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreAdjustmentAdd",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreAdjustmentAdd",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreAdjustmentAdd",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}adjustmentAdd`)) + " "
                  ),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreAdjustmentReduce,
                        expression:
                          "searchForm.options.isIgnoreAdjustmentReduce",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreAdjustmentReduce
                      )
                        ? _vm._i(
                            _vm.searchForm.options.isIgnoreAdjustmentReduce,
                            null
                          ) > -1
                        : _vm.searchForm.options.isIgnoreAdjustmentReduce,
                    },
                    on: {
                      change: function ($event) {
                        var $$a =
                            _vm.searchForm.options.isIgnoreAdjustmentReduce,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreAdjustmentReduce",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreAdjustmentReduce",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreAdjustmentReduce",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}adjustmentReduce`)) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "permission-box" }, [
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreBet,
                        expression: "searchForm.options.isIgnoreBet",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.searchForm.options.isIgnoreBet)
                        ? _vm._i(_vm.searchForm.options.isIgnoreBet, null) > -1
                        : _vm.searchForm.options.isIgnoreBet,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreBet,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreBet",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreBet",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.searchForm.options, "isIgnoreBet", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}bet`)) + " "),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreWinLose,
                        expression: "searchForm.options.isIgnoreWinLose",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreWinLose
                      )
                        ? _vm._i(_vm.searchForm.options.isIgnoreWinLose, null) >
                          -1
                        : _vm.searchForm.options.isIgnoreWinLose,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreWinLose,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreWinLose",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreWinLose",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreWinLose",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}WinLose`)) + " "
                  ),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreRebate,
                        expression: "searchForm.options.isIgnoreRebate",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreRebate
                      )
                        ? _vm._i(_vm.searchForm.options.isIgnoreRebate, null) >
                          -1
                        : _vm.searchForm.options.isIgnoreRebate,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreRebate,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreRebate",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreRebate",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreRebate",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}rebate`)) + " "),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreEvent,
                        expression: "searchForm.options.isIgnoreEvent",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreEvent
                      )
                        ? _vm._i(_vm.searchForm.options.isIgnoreEvent, null) >
                          -1
                        : _vm.searchForm.options.isIgnoreEvent,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreEvent,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreEvent",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreEvent",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.searchForm.options, "isIgnoreEvent", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}event`)) + " "),
                ]),
                _c("label", { staticClass: "isIgnore" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.options.isIgnoreDiscount,
                        expression: "searchForm.options.isIgnoreDiscount",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.searchForm.options.isIgnoreDiscount
                      )
                        ? _vm._i(
                            _vm.searchForm.options.isIgnoreDiscount,
                            null
                          ) > -1
                        : _vm.searchForm.options.isIgnoreDiscount,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.options.isIgnoreDiscount,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreDiscount",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm.options,
                                "isIgnoreDiscount",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.searchForm.options,
                            "isIgnoreDiscount",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}discount`)) + " "
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "advance-block" },
              [
                _c("QuickSearch", {
                  attrs: { controlType: _vm.controlType },
                  on: { changeTime: _vm.changeTime },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container-view" },
          [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { "min-width": "50px" } }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}agent`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}cashDeposit`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}paymentDeposit`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}cashWithdrawal`))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalFeeAmount`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}adjustmentAdd`))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}adjustmentReduce`))
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}bet`)))]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}WinLose`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}rebate`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}event`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}discountRebate`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}discount`))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}totalFirstDepositCount`)
                        )
                      ),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}totalRegisterCount`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}totalLoginCount`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}accountFor`))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}accountForWinLose`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}accountForDiscount`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}commission`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}netWinLose`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betCasino`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}winLoseCasino`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionRateCasino`))
                      ),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionCasino`))
                      ),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betSlot`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}winLoseSlot`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionRateSlot`))
                      ),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}commissionSlot`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betSport`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}winLoseSport`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionRateSport`))
                      ),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionSport`))
                      ),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betOther`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}winLoseOther`))),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionRateOther`))
                      ),
                    ]),
                    _c("th", { staticClass: "brown" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}commissionOther`))
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    (!_vm.allData || !_vm.allData.length) && !_vm.total
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "37" } }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.allData, function (list) {
                      return _c("tr", { key: list.account }, [
                        list.isAgent
                          ? _c(
                              "td",
                              {
                                staticClass: "agent",
                                on: {
                                  click: function ($event) {
                                    return _vm.passAgentId(list.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(list.account) +
                                    " (" +
                                    _vm._s(list.agentName) +
                                    ")"
                                ),
                              ]
                            )
                          : _c("td", [_vm._v(_vm._s(list.account))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.cashDeposit))),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.paymentDeposit))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.cashWithdrawal))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.withdrawalFeeAmount))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.amountAdjustmentAdd))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(list.amountAdjustmentReduce)
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.betAmount))),
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.winLose >= 0,
                              "font-red": list.winLose < 0,
                            },
                          },
                          [_vm._v(_vm._s(_vm.digital.format(list.winLose)))]
                        ),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.rebateAmount))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.eventAmount))),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.discountRebate))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.discount))),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.totalFirstDepositCount))]),
                        _c("td", [_vm._v(_vm._s(list.totalRegisterCount))]),
                        _c("td", [_vm._v(_vm._s(list.totalLoginCount))]),
                        _c("td", [_vm._v(_vm._s(list.accountFor))]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.accountForWinLose >= 0,
                              "font-red": list.accountForWinLose < 0,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.digital.format(list.accountForWinLose))
                            ),
                          ]
                        ),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.accountForDiscount))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.commission))),
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.netWinLose >= 0,
                              "font-red": list.netWinLose < 0,
                            },
                          },
                          [_vm._v(_vm._s(_vm.digital.format(list.netWinLose)))]
                        ),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.betCasino))),
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.winLoseCasino >= 0,
                              "font-red": list.winLoseCasino < 0,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.digital.format(list.winLoseCasino))
                            ),
                          ]
                        ),
                        _c("td", [_vm._v(_vm._s(list.commissionRateCasino))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.commissionCasino))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.betSlot))),
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.winLoseSlot >= 0,
                              "font-red": list.winLoseSlot < 0,
                            },
                          },
                          [_vm._v(_vm._s(_vm.digital.format(list.winLoseSlot)))]
                        ),
                        _c("td", [_vm._v(_vm._s(list.commissionRateSlot))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.commissionSlot))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.betSport))),
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.winLoseSport >= 0,
                              "font-red": list.winLoseSport < 0,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.digital.format(list.winLoseSport))
                            ),
                          ]
                        ),
                        _c("td", [_vm._v(_vm._s(list.commissionRateSport))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.commissionSport))
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.betOther))),
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "font-green": list.winLoseOther >= 0,
                              "font-red": list.winLoseOther < 0,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.digital.format(list.winLoseOther))
                            ),
                          ]
                        ),
                        _c("td", [_vm._v(_vm._s(list.commissionRateOther))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.commissionOther))
                          ),
                        ]),
                      ])
                    }),
                    _vm.agentTotal
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t(`${_vm.i18nPrefix}agentTotal`))
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCashDeposit
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalPaymentDeposit
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCashWithdrawal
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalFeeAmount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalAmountAdjustmentAdd
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalAmountAdjustmentReduce
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalBetAmount
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.agentTotal.totalWinLose >= 0,
                                "font-red": _vm.agentTotal.totalWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalRebateAmount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalEventAmount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalDiscountRebate
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.agentTotal.totalDiscount)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.agentTotal.totalFirstDepositCount)
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.agentTotal.totalRegisterCount)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.agentTotal.totalLoginCount)),
                          ]),
                          _c("td"),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.agentTotal.totalAccountForWinLose >= 0,
                                "font-red":
                                  _vm.agentTotal.totalAccountForWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalAccountForWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalDiscountWinLose
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCommission
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.agentTotal.totalNetWinLose >= 0,
                                "font-red": _vm.agentTotal.totalNetWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalNetWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalBetCasino
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.agentTotal.totalWinLoseCasino >= 0,
                                "font-red":
                                  _vm.agentTotal.totalWinLoseCasino < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalWinLoseCasino
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCommissionCasino
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.agentTotal.totalBetSlot)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.agentTotal.totalWinLoseSlot >= 0,
                                "font-red": _vm.agentTotal.totalWinLoseSlot < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalWinLoseSlot
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCommissionSlot
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.agentTotal.totalBetSport)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.agentTotal.totalWinLoseSport >= 0,
                                "font-red":
                                  _vm.agentTotal.totalWinLoseSport < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalWinLoseSport
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCommissionSport
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.agentTotal.totalBetOther)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.agentTotal.totalWinLoseOther >= 0,
                                "font-red":
                                  _vm.agentTotal.totalWinLoseOther < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.agentTotal.totalWinLoseOther
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.agentTotal.totalCommissionOther
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.memberTotal
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t(`${_vm.i18nPrefix}memberTotal`))
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCashDeposit
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalPaymentDeposit
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCashWithdrawal
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalFeeAmount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalAmountAdjustmentAdd
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalAmountAdjustmentReduce
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalBetAmount
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.memberTotal.totalWinLose >= 0,
                                "font-red": _vm.memberTotal.totalWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalRebateAmount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalEventAmount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalDiscountRebate
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalDiscount
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.memberTotal.totalFirstDepositCount)
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.memberTotal.totalRegisterCount)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.memberTotal.totalLoginCount)),
                          ]),
                          _c("td"),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.memberTotal.totalDiscountWinLose >= 0,
                                "font-red":
                                  _vm.memberTotal.totalDiscountWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalAccountForWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalDiscountWinLose
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCommission
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.memberTotal.totalNetWinLose >= 0,
                                "font-red": _vm.memberTotal.totalNetWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalNetWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalBetCasino
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.memberTotal.totalWinLoseCasino >= 0,
                                "font-red":
                                  _vm.memberTotal.totalWinLoseCasino < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalWinLoseCasino
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCommissionCasino
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.memberTotal.totalBetSlot)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.memberTotal.totalWinLoseSlot >= 0,
                                "font-red":
                                  _vm.memberTotal.totalWinLoseSlot < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalWinLoseSlot
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCommissionSlot
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalBetSport
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.memberTotal.totalWinLoseSport >= 0,
                                "font-red":
                                  _vm.memberTotal.totalWinLoseSport < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalWinLoseSport
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCommissionSport
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalBetOther
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.memberTotal.totalWinLoseOther >= 0,
                                "font-red":
                                  _vm.memberTotal.totalWinLoseOther < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.memberTotal.totalWinLoseOther
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.memberTotal.totalCommissionOther
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.total
                      ? _c("tr", [
                          _c("td", [_vm._v(_vm._s(_vm.$t("default.total")))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalCashDeposit)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalPaymentDeposit
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalCashWithdrawal
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalFeeAmount)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalAmountAdjustmentAdd
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalAmountAdjustmentReduce
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalBetAmount)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.total.totalWinLose >= 0,
                                "font-red": _vm.total.totalWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(_vm.total.totalWinLose)
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(_vm._s(_vm.total.totalRebateAmount)),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalEventAmount)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalDiscountRebate
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalDiscount)
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.total.totalFirstDepositCount)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.total.totalRegisterCount)),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.total.totalLoginCount))]),
                          _c("td"),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green":
                                  _vm.total.totalAccountForWinLose >= 0,
                                "font-red":
                                  _vm.total.totalAccountForWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.total.totalAccountForWinLose
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalDiscountWinLose
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalCommission)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.total.totalNetWinLose >= 0,
                                "font-red": _vm.total.totalNetWinLose < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(_vm.total.totalNetWinLose)
                                )
                              ),
                            ]
                          ),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalBetCasino)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.total.totalWinLoseCasino >= 0,
                                "font-red": _vm.total.totalWinLoseCasino < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.total.totalWinLoseCasino
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalCommissionCasino
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.digital.format(_vm.total.totalBetSlot))
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.total.totalWinLoseSlot >= 0,
                                "font-red": _vm.total.totalWinLoseSlot < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(_vm.total.totalWinLoseSlot)
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalCommissionSlot
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalBetSport)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.total.totalWinLoseSport >= 0,
                                "font-red": _vm.total.totalWinLoseSport < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.total.totalWinLoseSport
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalCommissionSport
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(_vm.total.totalBetOther)
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              class: {
                                "font-green": _vm.total.totalWinLoseOther >= 0,
                                "font-red": _vm.total.totalWinLoseOther < 0,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.digital.format(
                                    _vm.total.totalWinLoseOther
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.digital.format(
                                  _vm.total.totalCommissionOther
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
            _vm.allData
              ? _c("serverPagination", {
                  attrs: { page: _vm.page },
                  on: {
                    ServerPageUtils: _vm.search,
                    changeTake: _vm.changeTake,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }