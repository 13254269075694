import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function betAmountLimitList(data) {
    return request({
        url: "/BetAmountLimit/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function betAmountLimitTotal(data) {
    return request({
        url: "/BetAmountLimit/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateBetAmountLimit(data) {
    return request({
        url: "/BetAmountLimit/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSelectMenu() {
    return request({
        url: "/BetAmountLimit/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}