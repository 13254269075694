<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}operateList`) }}</h4>
        </div>
        <div class="divider"></div>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th>{{ $t(`${i18nPrefix}eventTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!tableList">
                <td>{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="(list, index) of tableList" :key="index">
                <td>{{ list }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      ></Pagination>
    </div>
    <transition name="fade" mode="out-in">
      <component
        :i18nPrefix="i18nPrefix"
        :is="currDialog"
        @toggle="currDialog = dialogData = undefined"
        v-bind="dialogData"
      ></component>
    </transition>
  </div>
</template>

<script>
import { memberEventList } from "@/api/member/member";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";

export default {
  name: "OperateList",
  props: ["account", "i18nPrefix"],
  data() {
    return {
      currDialog: undefined,
      dialogData: undefined
    };
  },
  components: {
    Pagination
  },
  mixins: [handlePageUtils],
  created() {
    memberEventList({ memberAccount: this.account }).then(res => {
      this.allData = res.data.sort(
        (a, b) => new Date(b) - new Date(a)
      );
    });
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
</style>