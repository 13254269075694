import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getBlacklistWarning(data) {
    return request({
        url: "/BlacklistWarning/Query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}