export let searchModel = {
    memberAccount: "",          // 會員帳號
    gamePlatform: 0,            // 遊戲平台
    gamesCategory: 0,           // 遊戲種類
    agentId: 0,
    gameOrderNumber: "",        //注單號
    isIncludeSubAgents: true,   //是否包含下級代理
    multiples: 0, // 倍場
    page:  {
        "take": 5,
        "skip": 0
    },
    sort: [
        {
            "column": "betTime",
            "order": 2
        }
    ]
}