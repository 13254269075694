<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t(`${i18nPrefix}bankCard`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.bankType") }}
              <select v-model="createModel.bankType">
                <option v-for="list of getBankTypeList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label></label>
            <div class="multiselectDiv" v-show="createModel.bankType === 1 || createModel.bankType === 2">
              {{ $t("default.bankName") }}
              <multiselect v-model="value" label="name" track-by="id" :placeholder="$t('default.plzSelect')"
                :custom-label="nameWithLang" :options="getBankList" :allow-empty="false" :preselectFirst="true">
              </multiselect>
            </div>
            <label v-show="createModel.bankType === 1 || createModel.bankType === 2">
              {{ $t("default.bankBranchCode") }}
              <input type="text" v-model="createModel.bankBranchCode" />
            </label>
            <label v-show="createModel.bankType === 1 || createModel.bankType === 2">
              {{ $t("default.bankBranch") }}
              <input type="text" v-model="createModel.bankBranch" />
            </label>
            <label>
              {{ $t("default.bankAccountNumber") }}
              <input type="text" v-model="createModel.bankAccountNumber" />
            </label>
            <label>
              {{ $t("default.bankAccountName") }}
              <input type="text" v-model="createModel.bankAccountName" />
            </label>
            <label v-show="createModel.bankType === 1 || createModel.bankType === 2">
              {{ $t("default.province") }}
              <input type="text" v-model="createModel.province" />
            </label>
            <label v-show="createModel.bankType === 1 || createModel.bankType === 2">
              {{ $t("default.city") }}
              <input type="text" v-model="createModel.city" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "Create",
  props: ["getBankTypeList", "getBankList", "bankGroupList", "i18nPrefix"],
  computed: {
    ...mapGetters(["getStatusList"])
  },
  data() {
    return {
      createModel: {
        bankId: undefined,
        bankType: 1,
        bankBranchCode: undefined,
        bankBranch: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
        province: undefined,
        city: undefined
      },
      value: []
    };
  },
  components: { Multiselect },
  watch: {
    "createModel.bankAccountNumber"(val) {
      this.createModel.bankAccountNumber = val.trim();
    }
  },
  methods: {
    create() {
      this.createModel.bankId = this.value.id;
      this.createModel.bankAccountName = this.createModel.bankAccountName.trim();
      this.$emit("create", this.createModel);
    },
    nameWithLang({ name, code }) {
      return `${code}－${name}`;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.dialog-container {
  width: 60%;
}

.input-block {
  label {
    width: 40%;
  }

  .multiselectDiv {
    width: 40%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .multiselectDiv {
    width: 100% !important;
  }

  label {
    width: 100% !important;
  }
}
</style>