import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getGamePlatforms() {
    return request({
        url: "/SelectMenu/GamePlatforms",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGamesCategories() {
    return request({
        url: "/SelectMenu/GamesCategories",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDiscountTypes() {
    return request({
        url: "/SelectMenu/DiscountTypes",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getbankTypes() {
    return request({
        url: "/SelectMenu/bankTypes",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getAgentWithdrawalInfoList() {
    return request({
        url: "/SelectMenu/AgentWithdrawalInfoList",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCompanyDepositSettingsList() {
    return request({
        url: "/SelectMenu/CompanyDepositSettingsList",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getAnnouncementTypes() {
    return request({
        url: "/SelectMenu/AnnouncementTypes",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRebateGamePlatformList() {
    return request({
        url: "/SelectMenu/AgentRebateGamePlatformList",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getPaymentStatus() {
    return request({
        url: "/SelectMenu/PaymentStatuses",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getAuditStatus() {
    return request({
        url: "/SelectMenu/AuditStatuses",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDiscountAuditStatuses() {
    return request({
        url: "/SelectMenu/DiscountAuditStatuses",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGameCategoryAndPlatform() {
    return request({
        url: "/SelectMenu/GameCategoryAndPlatformList",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGetCvsName() {
    return request({
        url: "/SelectMenu/GetCvsName",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGetCvsCity(params) {
    return request({
        url: "/SelectMenu/GetCvsCity",
        method: "get",
        params,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGetCvsDistrict(params) {
    return request({
        url: "/SelectMenu/GetCvsDistrict",
        method: "get",
        params,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGetCvsCode(params) {
    return request({
        url: "/SelectMenu/GetCvsCode",
        method: "get",
        params,
        headers: { "Authorization": `token ${getToken()}` }
    });
}