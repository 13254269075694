<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>SEO</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="createModel.name"/>
            </label>
            <label>
              {{ $t(`${i18nPrefix}page`) }}
              <select v-model="createModel.pageType">
                <option v-for="list of gamesCategoryList" :value="list.key * 1" :key="list.key">{{ list.description }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.copyPage`) }}
              <select v-model="createModel.pageId">
                <option :value="0 * 1">{{ $t(`${i18nPrefix}dialog.notCopy`) }}</option>
                <option v-for="list of pageList" :value="list.id * 1" :key="list.id">{{ list.name }}</option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('create', createModel)">
              {{ $t("default.confirm") }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { getSEOList } from "@/api/SEO/SEO";

export default {
  name: "Create",
  props: {
    gamesCategoryList: {},
  },
  data() {
    return {
      createModel: {
        name: undefined,
        pageType: this.gamesCategoryList[0]?.key,
        pageId: 0,
      },
      pageList: [],
      i18nPrefix: "SEO-Function.SEO."
    }
  },
  created() {
    getSEOList({ id: 0 }).then((res) => {
      this.pageList = res.data.pages;
    });
  },
}
</script>

<style scoped>
.input-block label{
  flex-direction: column;
  width: 100%;
}
</style>