<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.edit") }}{{ $t(`${i18nPrefix}dialog.title`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}dialog.imagePreview`) }}
              <img
                :src="editModel.imageUrl"
                style="width: 100%; display: block; margin: auto"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.mobileImagePreview`) }}
              <img
                :src="editModel.mobileImageUrl"
                style="width: 100%; display: block; margin: auto"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}imageLink`) }}
              <input type="text" v-model="editModel.imageUrl" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}mobileImageLink`) }}
              <input type="text" v-model="editModel.mobileImageUrl" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.isMaintain">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}sort`) }}
              <input type="number" v-model="editModel.sort" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}videoLink`) }}
              <input type="text" v-model="editModel.url" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Edit",
  props: ["id", "isMaintain", "sort", "url", "imageUrl", "mobileImageUrl"],
  data() {
    return {
      editModel: {
        id: this.id,
        isMaintain: this.isMaintain ? 1 : 2,
        sort: this.sort,
        url: this.url,
        imageUrl: this.imageUrl,
        mobileImageUrl: this.mobileImageUrl,
      },
      i18nPrefix: "SEO-Function.VideoSetting."
    };
  },
  computed: {
    ...mapGetters(["getStatusList"]),
  },
  methods: {
    async submit() {
      this.editModel.isMaintain =
        this.editModel.isMaintain === 1 ? true : false;

      this.$emit("edit", this.editModel);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
</style>