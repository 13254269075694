<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}dialog.code`) }}
              <input type="text" v-model="createModel.code" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.name`) }}
              <input type="text" v-model="createModel.name" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('create', createModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props:{
    i18nPrefix: String
  },
  data() {
    return {
      createModel: {
        name: undefined,
        code: undefined
      },
    };
  }
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  .dialog-container {
    width: 40%;
    .input-block {
      flex-direction: column;
      label {
        width: 50%;
      }
    }  
  }
}
</style>