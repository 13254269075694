<template>
  <div class="confirm-mask" @mousedown.self="$emit('toggle')">
    <div class="confirm-container">
      <main>
        <div>
          <span>{{ message }}</span>
        </div>
        <div class="time" v-show="time">
          {{ $t("alert.checkTime") }} <span>{{ time }}</span> {{ $t("alert.sec") }}
        </div>
        <div class="advance-block">
          <button class="normal-btn" @click="$emit('doIt')">{{ $t(`${btn.confirm}`) }}</button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t(`${btn.cancel}`) }}</button>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

export default {
  name: "Alert",
  props:["message","setTime","btnText"],
  data() {
    return {
      timer: undefined,
      time: this.setTime ?? undefined,
      btn: {
        confirm: this.btnText?.confirm ?? "default.confirm",
        cancel: this.btnText?.cancel ?? "default.cancel",
      }
    };
  },
  watch: {
    "time"(val) {
      if(val === 0){
        clearInterval(this.timer);
        this.$emit('toggle');
      }
    }
  },
  mounted() {
    if(this.time) this.timer=setInterval(this.countdown, 1000);
  },
  methods: {
    countdown() {
      this.time --;
    },
  }
};
</script>

<style lang="scss" scoped>
.confirm-mask {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.384);
  z-index: 999;
  .confirm-container {
    max-width: 80%;
    background-color: #ffffff;
    border: 2px solid rgba(151, 151, 151, 0.562);
    border-radius: 15px;
    padding: 10px 20px;
    main {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    span {
      font-size: 30px;
    }
    .advance-block {
      display: flex;
      justify-content: center;
    }
  }
}
</style>