import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getList(data) {
    return request({
        url: "/AgentRebateV2/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTree(data) {
    return request({
        url: "/AgentRebateV2/Tree",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRebateTime(data) {
    return request({
        url: "/AgentRebateV2/RebateTime",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCount() {
    return request({
        url: "/AgentRebateV2/Count",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function audit(data) {
    return request({
        url: "/AgentRebateV2/Audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function payout(data) {
    return request({
        url: "/AgentRebateV2/Payout",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditAll() {
    return request({
        url: "/AgentRebateV2/AuditAll",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function payoutAll() {
    return request({
        url: "/AgentRebateV2/PayoutAll",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}