var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                staticStyle: { margin: "auto" },
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(20401),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleCalc()
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}handleCalc`)) + " "
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "divider" }),
        _c("main", [
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}timeFrom`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.startTime,
                    expression: "startTime",
                  },
                ],
                attrs: { type: "date", step: "1" },
                domProps: { value: _vm.startTime },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.startTime = $event.target.value
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}timeTo`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.endTime,
                    expression: "endTime",
                  },
                ],
                attrs: { type: "date", step: "1" },
                domProps: { value: _vm.endTime },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.endTime = $event.target.value
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.upRecord.remark,
                    expression: "upRecord.remark",
                  },
                ],
                domProps: { value: _vm.upRecord.remark },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.upRecord, "remark", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("main", [
            _c(
              "div",
              { staticClass: "table-block" },
              [
                _c("table", { staticClass: "mt-3" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}id`)))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}timeFrom`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}timeTo`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}approveCount`))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}untreatedCount`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}refuseCount`))),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.admin")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}approveTime`))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.allData, function (list, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "normal-btn mr-3",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(20402),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(list, "UpRecordEdit")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(list.id) + " ")]
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.timeFrom))]),
                        _c("td", [_vm._v(_vm._s(list.timeTo))]),
                        _c("td", [_vm._v(_vm._s(list.approveCount))]),
                        _c("td", [_vm._v(_vm._s(list.untreatedCount))]),
                        _c("td", [_vm._v(_vm._s(list.refuseCount))]),
                        _c("td", [_vm._v(_vm._s(list.remark))]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.GLOBAL.i18nCheckName(
                                  _vm.rebateCalcStatus.find(
                                    (data) => data.id === list.status
                                  )
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.approveAccount))]),
                        _c("td", [_vm._v(_vm._s(list.approveTime))]),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm.allData
                  ? _c("serverPagination", {
                      attrs: { page: _vm.page },
                      on: {
                        ServerPageUtils: _vm.getList,
                        changeTake: _vm.changeTake,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.upRecordCalc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  audit: _vm.audit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }