<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`default.${editData !== undefined ? 'edit' : 'create' }`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}style`) }}
              <input type="text" v-model="styleModel.name" />
            </label>
            <label>
              LuckyDrawCode
              <input type="text" v-model="styleModel.luckyDrawCode" />
            </label>
            <label>
              {{ $t('default.status') }}
              <select v-model="styleModel.status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('File')">
              <span v-if="!File">{{ $t('default.clickToUpload') }}</span>
              <img v-else :src="File" />
            </div>
          </div>
          <input type="file" ref="file" class="custom-file" @change="fileSelect" />
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createCampaignLuckyDrawStyle,
  updateCampaignLuckyDrawStyle
} from "@/api/campaign/luckydraw";
import { emptyStyleModel } from "./model";

export default {
  name: "Style",
  props: ["editData", "i18nPrefix"],
  data() {
    return {
      emptyStyleModel,
      styleModel: undefined,
      formData: new FormData(),
      File: undefined,
      chooiceImg: undefined
    };
  },
  async created() {
    this.styleModel = _.cloneDeep(this.emptyStyleModel);
    // 處理修改欄位
    this.processEditColumn();
  },
  methods: {
    async submit() {
      Object.entries(this.styleModel).forEach(([key, value]) => {
        if (value !== undefined) this.formData.append(key, value);
      });

      let result = {};
      if (this.editData === undefined) {
        await createCampaignLuckyDrawStyle(this.formData).then(res => {
          result = res;
        });
      } else {
        await updateCampaignLuckyDrawStyle(this.formData).then(res => {
          result = res;
        });
      }
      if (result) {
        this.$emit("stylebookmark");
        this.$emit("toggle");
        this.$emit("search");
      }
    },
    /**
     * 處理修改欄位
     */
    processEditColumn() {
      if (this.editData !== undefined) {
        for (const [key, value] of Object.entries(this.styleModel)) {
          if (this.editData[key] !== undefined) {
            this.styleModel[key] = this.editData[key];
          }
        }
        this.File =
          this.editData?.imageUrl +
          (this.editData?.imageUrl ? "?v=" + new Date().getTime() : "");
      }
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = "";
      this.styleModel[tag] = "";
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.dialog-container {
  width: 80%;
}
.imgDiv {
  display: flex;
  flex-wrap: wrap;
  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 400px;
    padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;
    span {
      display: block;
      font-size: 20px;
    }
    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
  }
}
</style>