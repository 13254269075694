<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="edit-group dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") }}{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}groupName`) }}
              <input type="text" v-model="editModel.name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}stage`) }}
              <select v-model="editModel.starLevelSettingId">
                <option
                  v-for="list of starLevelList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.star }} {{ $t("default.star") }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}active`) }}
              <select v-model="editModel.status">
                <option value="1">{{ $t("default.active") }}</option>
                <option value="2">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}creator`) }}
              <input type="text" v-model="editModel.createAccount" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}createDate`) }}
              <input type="text" v-model="editModel.createTime" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}editor`) }}
              <input type="text" v-model="editModel.updateAccount" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}updateDate`) }}
              <input type="text" v-model="editModel.updateTime" disabled />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('edit', editModel)">
              {{ $t("default.confirm") }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  props: [
    "id",
    "name",
    "groupType",
    "status",
    "createAccount",
    "createTime",
    "updateAccount",
    "updateTime",
    "starLevelSettingId",
    "starLevelList",
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        name: this.name,
        groupType: this.groupType,
        status: this.status,
        createAccount: this.createAccount,
        createTime: this.createTime,
        updateAccount: this.updateAccount,
        updateTime: this.updateTime,
        starLevelSettingId: this.starLevelSettingId,
      },
      i18nPrefix: "features.group.",
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-group {
  .input-block {
    label {
      width: 40%;
    }
  }
}
</style>