var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar", class: { hide: _vm.sidebar } }, [
    _c(
      "ul",
      { staticClass: "sidebar-nav" },
      [
        _vm._l(_vm.routes, function (route) {
          return _c(
            "li",
            { key: route.path, staticClass: "parent-li" },
            [
              route.children.length == 0
                ? [
                    _c("router-link", { attrs: { to: route.path } }, [
                      _vm._v(
                        _vm._s(
                          route.meta.permissionName
                            ? _vm.$t("Permission." + route.meta.permissionName)
                            : _vm.$t(`title.${route.meta.title}`)
                        ) +
                          " " +
                          _vm._s(route.meta.ver || "")
                      ),
                    ]),
                  ]
                : [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `#${route.name}`,
                          id: route.path,
                          "data-toggle": "collapse",
                        },
                        on: { click: _vm.setActive },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            route.meta.permissionName
                              ? _vm.$t(
                                  "Permission." + route.meta.permissionName
                                )
                              : _vm.$t("title." + route.meta.title)
                          ) +
                            " " +
                            _vm._s(route.meta.ver || "")
                        ),
                      ]
                    ),
                    _c("i", { staticClass: "fa fa-angle-down" }),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        staticStyle: { width: "100%", cursor: "pointer" },
                        attrs: { id: route.name },
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(route.children, function (childRoute) {
                            return _c(
                              "li",
                              { key: childRoute.path },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: `${route.path}/${childRoute.path}`,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.hideSideBarWhenClick(
                                          route.path + "/" + childRoute.path
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          childRoute.meta.permissionName
                                            ? _vm.$t(
                                                "Permission." +
                                                  childRoute.meta.permissionName
                                              )
                                            : _vm.$t(
                                                "title." + childRoute.meta.title
                                              )
                                        ) +
                                        " " +
                                        _vm._s(childRoute.meta.ver || "") +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
            ],
            2
          )
        }),
        _c("li", { staticClass: "mb-5" }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }