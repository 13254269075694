var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.name`)))]),
        _c(
          "th",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.detailsData.discountType,
                expression: "detailsData.discountType",
              },
            ],
          },
          [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.discountType`)))]
        ),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.createTime`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.bonusReceive`))),
        ]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.totalBetAmountLimit`))),
        ]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.totalValidBetAmount`))),
        ]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.discountForm.name))]),
        _c(
          "td",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.detailsData.discountType,
                expression: "detailsData.discountType",
              },
            ],
          },
          [
            _vm._v(
              _vm._s(
                _vm.GLOBAL.i18nCheckName(
                  _vm.getDiscountTypeList.find(
                    (data) => data.id === _vm.detailsData.discountType
                  )
                )
              )
            ),
          ]
        ),
        _c("td", [_vm._v(_vm._s(_vm.discountForm.memberAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.discountForm.createTime))]),
        _c("td", [_vm._v(_vm._s(_vm.discountForm.bonusReceive))]),
        _c("td", [_vm._v(_vm._s(_vm.discountForm.totalBetAmountLimit))]),
        _c("td", [_vm._v(_vm._s(_vm.discountForm.betAmountLimit))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }