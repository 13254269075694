<template>
  <div class="message-box">
    <i id="icon" class="fa"></i>
    {{ msg }}
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Message",
  props: ["msg"],
  error(value, time) {
    this.createDom(value, time, "error", "fa-times-circle");
  },
  success(value, time) {
    this.createDom(value, time, "success", "fa-check-circle");
  },
  copy(value, time) {
    this.createDom(value, time, "copy", "fa-check-circle");
  },
  /**
   * @summary 生成節點
   * @param value 要顯示的訊息
   * @param time  節點顯示時間
   * @param type  將訊息類型給去當ID
   * @param icon  指定之icon樣式
   */ 
  createDom(value, time, type, icon) {
    let ComponentClass = Vue.extend(this);
    let instance = new ComponentClass({
      propsData: { msg: value },
    });
    instance.$mount();
    instance.$el.id = type;
    if (this.getId(type) !== null) {
      return;
    }
    document.body.appendChild(instance.$el);
    let messageId = this.getId(type);
    messageId.classList.add(type);
    this.getId("icon").classList.add(icon);
    messageId.style.opacity = 0;
    window.getComputedStyle(messageId).opacity;
    messageId.style.opacity = 1;
    setTimeout(() => {
      window.getComputedStyle(messageId).opacity;
      messageId.style.opacity = 0;
      setTimeout(() => {
        messageId.classList.remove(type);
        document.body.removeChild(instance.$el);
      }, 500);
    }, time);
  },
  getId(target) {
    return document.getElementById(target);
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -50%);
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  z-index: 999;
  .fa-check-circle {
    color: #98cb6d !important;
  }
  .fa-times-circle {
    color: #e67178 !important;
  }
}
.error {
  font-weight: 900;
  color: #fff;
  background-color: #AF4C4C;
  border: 1px solid #950900;
  border-radius: 30px;
}
.success {
  font-weight: bold;
  color: #fff;
  background-color: #779C0C;
  border: 1px solid #92C601;
  border-radius: 30px;
}
.copy {
  font-weight: bold;
  // color: #b900b9 !important;
  // background-color: #ffd7ff;
  // border: 1px dotted #c700c7;
  color: #fff;
  background-color: #779C0C;
  border: 1px solid #92C601;
  border-radius: 30px;
}
</style>