import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberBetRecordList(data) {
    return request({
        url: "/MemberBetRecord/query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTotal(data) {
    return request({
        url: "/MemberBetRecord/total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function memberBetRecordListV2(data) {
    return request({
        url: "/MemberBetRecordV2/query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function memberBetRecordTotalV2(data) {
    return request({
        url: "/MemberBetRecordV2/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getExcel(data) {
    return request({
        url: "/MemberBetRecordV2/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function selectMenu() {
    return request({
        url: "/MemberBetRecordV2/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}
