export const emptyFormModel = {
    id: undefined,
    name: '',        // 標題
    // currency: undefined,
    discountCategory: 1,    //
    discountType: '',       // 優惠類型
    startTime: undefined,   // 開始時間
    endTime: undefined,     // 結束時間
    discountStatus: '',     // 狀態
    remark: '',             // 備註
    isOnce: false,          // 是否一次性優惠
    isBlockDuplicateIP: false, // 是否ip阻擋
    BlockDuplicateIPCount: 1, // 阻擋ip數量
    isMutuallyExclusivesType: false, // 是否同類型互斥
    // 輸退反水自動派發週期
    payTime: '00',
    calculationCycle: 1,
    //
    period: 0,              // 週期
    applyType: '',          // 申請方式
    dispatchType: '',       // 派發方式
    bounsAmountLimit: -1,   // 派發上限
    bounsAmountLower: 0,    // 派發下限
    bounsMultiple: 1,       // 優惠賠率
    stairBase: 1,           // 階層標準
    operand: "入金金額",     // 運算項目
    formula: '',            // 公式
    includeLevels: [],      // 限定會員等級
    includeTags: [],        // 限定會員標籤
    mutuallyExclusives: [], // 互斥優惠,
    // leastAmount: 0,      // 觸發優惠最低入金金額
    isShowFont: true,       // 是否顯示前台
    isDeleteFile: false,    // 刪除圖片
    sort: 99,               // 排序
    html: ''                // 優惠內容
}

const i18nRules = "features.discount-setup.editV2.rulesMessage.";
export const rules = {
    name: [
        { required: true, message: `${i18nRules}required.name` }
    ],
    discountStatus: [
        { required: true, message: `${i18nRules}required.discountStatus` }
    ],
    discountType: [
        { required: true, message: `${i18nRules}required.discountType` }
    ],
    startTime: [
        { required: true, message: `${i18nRules}required.startTime` }
    ],
    endTime: [
        { required: true, message: `${i18nRules}required.endTime` }
    ],
    applyType: [
        // { required: true, message: `${i18nRules}required.applyType`, skipType: ["FirstDepositV2", "PeriodFirstDeposit"] }
        { required: true, message: `${i18nRules}required.applyType` }
    ],
    dispatchType: [
        { required: true, message: `${i18nRules}required.dispatchType` }
    ],
    bounsAmountLimit: [
        { required: true, message: `${i18nRules}required.bounsAmountLimit`, skipType: ["RebateV2", "LoseRebate", "realTimeRebate"] }
    ],
    bounsAmountLower: [
        { required: true, message: `${i18nRules}required.bounsAmountLower`, skipType: ["RebateV2", "LoseRebate", "realTimeRebate"] }
    ],
    formula: [
        { required: true, message: `${i18nRules}required.formula`, skipType: ["RebateV2", "LoseRebate", "realTimeRebate"] },
        { checkJson: true, message: `${i18nRules}format.formula`, skipType: ["RebateV2", "LoseRebate", "realTimeRebate"] }
    ],
    bounsMultiple: [
        { required: true, message: `${i18nRules}required.bounsMultiple`, skipType: ["RebateV2", "LoseRebate", "realTimeRebate"] }
    ]
}

export const apiSubmitData = {
    id: undefined,
    name: undefined,
    discountCategory: undefined,
    discountType: undefined,
    discountStatus: undefined,
    applyType: undefined,
    startTime: undefined,
    endTime: undefined,
    isShowFont: undefined,
    sort: undefined,
    html: undefined,
    remark: undefined,
    isDeleteFile: undefined,
    isOnce: undefined,
    ruleSetting: {
        payTime: undefined,
        calculationCycle: undefined,
        period: undefined,
        dispatchType: undefined,
        stairBase: undefined,
        operand: undefined,
        bounsAmountLimit: undefined,
        bounsAmountLower: undefined,
        bounsMultiple: undefined,
        includeLevels: undefined,
        includeTags: undefined,
        mutuallyExclusives: undefined,
        formula: undefined,
        starList: undefined,
        isBlockDuplicateIP: undefined,
        BlockDuplicateIPCount: undefined,
        isMutuallyExclusivesType: undefined
    }
}

export const discountRule = {
    applyType: [
        {
            key: 'auto',
            value: 1,
            name: "features.discount-setup.type.applyType.auto"
        },
        {
            key: 'manual',
            value: 2,
            name: "features.discount-setup.type.applyType.manual"
        },
        {
            key: 'customer',
            value: 3,
            name: "features.discount-setup.type.applyType.customService"
        }
    ],
    dispatchType: [
        {
            key: 'auto',
            value: 1,
            name: "features.discount-setup.type.dispatchType.auto"
        },
        {
            key: 'manual',
            value: 2,
            name: "features.discount-setup.type.dispatchType.manual"
        },
        {
            key: 'payBonusByOrder',
            value: 3,
            name: "features.discount-setup.type.dispatchType.payBonusByOrder"
        }
    ],
    stairBaseType: [
        {
            key: 'amount',
            value: 1,
            name: "features.discount-setup.type.stairBaseType.amount"
        },
        // {
        //     key: 'level',
        //     value: 2,
        //     name: "features.discount-setup.type.stairBaseType.level"
        // },
        // {
        //     key: 'tag',
        //     value: 3,
        //     name: "features.discount-setup.type.stairBaseType.tag"
        // }
    ],
    "": {},
    DepositV2: {
        isOnce: false,
        isBlockDuplicateIP: false
    },
    FirstDepositV2: {},
    PeriodFirstDeposit: {
        periodType: [
            {
                key: 'node',
                value: 0,
                name: "features.discount-setup.type.periodType.node"
            },
            {
                key: 'day',
                value: 1,
                name: "features.discount-setup.type.periodType.day"
            },
            {
                key: 'week',
                value: 2,
                name: "features.discount-setup.type.periodType.week"
            },
        ]
    },
    RebateV2: {},
    LoseRebate: {},
    realTimeRebate: { isShowFont: false },
    NewRegister: {
        // 階層標準stairBase , 運算項目operand , 派發上限bounsAmountLimit , 派發下限bounsAmountLower , 存在時不顯示
        stairBase: false,
        operand: false,
        bounsAmountLimit: false,
        bounsAmountLower: false,
    },
};

export const discountTypeList = [
    {
        id: '',
        value: '',
        name: "default.plzSelect",
        component: undefined,
        discountCategory: 1
    },
    {
        id: 'DepositV2',
        value: 26,
        name: "features.discount-setup.type.discountType.deposit",
        component: "DepositEdit",
        discountCategory: 1
    },
    {
        id: 'FirstDepositV2',
        value: 25,
        name: "features.discount-setup.type.discountType.firstDeposit",
        component: "DepositEdit",
        discountCategory: 1
    },
    {
        id: 'PeriodFirstDeposit',
        value: 27,
        name: "features.discount-setup.type.discountType.periodFirstDeposit",
        component: "DepositEdit",
        discountCategory: 1
    },
    {
        id: 'RebateV2',
        value: 31,
        name: "features.discount-setup.type.discountType.RebateV2",
        component: "RebateEdit",
        discountCategory: 3
    },
    {
        id: 'LoseRebate',
        value: 33,
        name: "features.discount-setup.type.discountType.loseRebate",
        component: "RebateEdit",
        discountCategory: 3
    },
    {
        id: 'realTimeRebate',
        value: 35,
        name: "features.discount-setup.type.discountType.realTimeRebate",
        component: "RebateEdit",
        discountCategory: 3
    },
    {
        id: 'NewRegister',
        value: 12,
        name: "features.discount-setup.type.discountType.NewRegister",
        component: "DepositEdit",
        discountCategory: 2
    },
];

export const discountStatus = [
    {
        key: '',
        name: 'default.plzSelect',
    },
    {
        key: 1,
        name: 'default.active'
    },
    {
        key: 2,
        name: 'default.inactive'
    },
    {
        key: 3,
        name: 'features.discount-setup.type.discountStatus.withTime'
    },
];