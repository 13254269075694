import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu() {
    return request({
        url: "/CompanyDepositSettings/SelectMenu",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCompanyDepositSettingsList(data) {
    return request({
        url: "/CompanyDepositSettings/List",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function editCompanyDepositSettings(data) {
    return request({
        url: "/CompanyDepositSettings/Update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function getCompanyDepositSettingsArgList(data) {
    return request({
        url: "/CompanyDepositSettingsArg/List",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function createCompanyDepositSettingsArg(data) {
    return request({
        url: "/CompanyDepositSettingsArg/Create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function editCompanyDepositSettingsArg(data) {
    return request({
        url: "/CompanyDepositSettingsArg/Update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function deleteCompanyDepositSettingsArg(data) {
    return request({
        url: "/CompanyDepositSettingsArg/Delete",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}