var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}create`)))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}memberLevel`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.star,
                      expression: "createModel.star",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.star },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "star", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label"),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.fromAmount`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.fromAmount,
                      expression: "createModel.fromAmount",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.fromAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "fromAmount",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.toAmount`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.toAmount,
                      expression: "createModel.toAmount",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.toAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "toAmount", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}liveCasinoRate`)) +
                    " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.liveCasinoRate,
                      expression: "createModel.liveCasinoRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.liveCasinoRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "liveCasinoRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}slotRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.slotRate,
                      expression: "createModel.slotRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.slotRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "slotRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}fishingRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.fishingRate,
                      expression: "createModel.fishingRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.fishingRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "fishingRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}lotteryRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.lotteryRate,
                      expression: "createModel.lotteryRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.lotteryRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "lotteryRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}sportRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.sportRate,
                      expression: "createModel.sportRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.sportRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "sportRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}eSportRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.eSportRate,
                      expression: "createModel.eSportRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.eSportRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "eSportRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}cardGameRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.cardGameRate,
                      expression: "createModel.cardGameRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.cardGameRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "cardGameRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}cockFighting`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.cockFightingRate,
                      expression: "createModel.cockFightingRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.cockFightingRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "cockFightingRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}arcade`)) + " % "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.arcadeRate,
                      expression: "createModel.arcadeRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.arcadeRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "arcadeRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.promotionBonus,
                      expression: "createModel.promotionBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.promotionBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "promotionBonus",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.create } },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }