<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="btn-list main">
        <button
            v-for="list of blacklistType"
            :key="list.id"
            @click="searchForm.type = list.id"
            :class="{ active: searchForm.type === list.id }"
        >
          {{ $t(`${i18nPrefix}${list.value}`) }}
        </button>
      </div>
      <div class="advance-block">
        <label class="search">
          {{ $t(`${i18nPrefix}${blacklistType.find(data => data.id === searchForm.type).value}`) }}
          <input type="text" v-model="searchForm.value"/>
        </label>
        <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
      </div>
    </div>
    <div class="container-view">
      <label>
        {{ $t(`${i18nPrefix}${blacklistType.find(data => data.id === searchForm.type).value}`) }}
        <input type="text" v-model="createValue"/>
        <button class="confirm-btn main-style-btn ml-2" @click="create">
          {{ $t("default.new") + " " + $t(i18nPrefix + blacklistType.find(data => data.id === searchForm.type).value) }}
        </button>
      </label>
      <main class="mt-2">
        <div class="blacklist">
          <div v-show="!allData.length">
            <span>
              {{ $t("default.noData") }}
            </span>
          </div>
          <div v-for="(list,index) of allData" :key="index">
            <span>
              {{ list.value }}
            </span>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  getBlackListRules,
  createBlackListRules
} from "@/api/blacklist/blacklistRules";
import Message from "@/layout/components/Message";
import { searchModel } from "./model";

export default {
  name: "BlacklistRules",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      blacklistType: [
        {
          id: 1,
          name: "銀行卡號",
          value: "bankNumber"
        },
        {
          id: 2,
          name: "銀行卡姓名",
          value: "bankMemberName"
        },
        {
          id: 3,
          name: "登入IP",
          value: "loginIP"
        },
        {
          id: 4,
          name: "註冊IP",
          value: "registerIP"
        },
        {
          id: 5,
          name: "手機號碼",
          value: "phoneNumber"
        },
        {
          id: 6,
          name: "lineId",
          value: "lineId"
        },
        {
          id: 8,
          name: "身分證字號",
          value: "idNumber"
        },
      ],
      allData: [],
      createValue: "",
      i18nPrefix: "blackList.blacklistRules."
    };
  },
  watch: {
    "searchForm.type": function(){
      this.allData = [];
    },
  },
  methods: {
    search() {
      getBlackListRules(this.searchForm).then((res) => {
        this.allData = res.data;
      });
    },
    create() {
      if(!this.createValue) {
        Message.error("請填入資料", 1000);
        return
      }
      const obj = {
        type: this.searchForm.type,
        value: this.createValue
      };
      createBlackListRules(obj).then((res) => {
        if(res) this.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 40px;
}
.active {
  background-image: linear-gradient(180deg, #FFCC81 0%, #CD7B00 100%) !important;
  color: white;
  border: 1px solid #9F6000;
}
.btn-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1em;

  button {
    margin: auto;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #7A7A7A;
    background-color: #949494;
    color: white;
    font-size: 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}
.blacklist {
  display: grid;
  grid-template-columns: repeat(10, 9%);
  grid-gap: 1em;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #7E96A3;
    background-color: #749AAF;
    color: white;
    span {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 1280px) {
  .btn-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    font-size: 10px !important;
  }
  .blacklist {
    grid-template-columns: repeat(5, 19%);
  }
}
@media screen and (max-width: 768px) {
  .blacklist {
    grid-template-columns: repeat(2, 49%);
  }
}
</style>