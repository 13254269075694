<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.status") }}
              <select
                v-model="editModel.discountAuditStatus"
                :disabled="discountAuditStatus !== 1"
              >
                <option
                  v-for="list of getDiscountAuditStatus"
                  :key="list.id"
                  :value="list.id"
                  v-show="list.id !== 6"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.member") }}
              <input type="text" :disabled="true" v-model="memberAccount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" :disabled="true" v-model="name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}createTime`) }}
              <input
                type="datetime-local"
                :disabled="true"
                step="1"
                v-model="sTime"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}submitTime`) }}
              <input
                type="datetime-local"
                :disabled="true"
                step="1"
                v-model="eTime"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}discountType`) }}
              <input
                type="text"
                :disabled="true"
                :value="
                  GLOBAL.i18nCheckName(getDiscountTypeListAddDefault.find(
                    (data) => data.id === discountType
                  ))"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}firstDepositAmount`) }}
              <input
                type="text"
                :disabled="discountAuditStatus !== 1"
                v-model="editModel.firstDepositAmount"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bonusReceive`) }}
              <input
                type="text"
                :disabled="discountAuditStatus !== 1"
                v-model="editModel.bonusReceive"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}totalBetAmountLimit`) }}
              <input
                type="text"
                :disabled="discountAuditStatus !== 1"
                v-model="editModel.totalBetAmountLimit"
              />
            </label>
            <label class="remark">
              {{ $t("default.remark") }}
              <textarea v-model="editModel.Remark"></textarea>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Edit",
  props: [
    "id",
    "bonusReceive",
    "bonusWaiver",
    "createTime",
    "submitTime",
    "discountAuditStatus",
    "discountType",
    "firstDepositAmount",
    "remark",
    "memberAccount",
    "name",
    "totalBetAmountLimit",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        ids: [this.id],
        discountAuditStatus: this.discountAuditStatus,
        firstDepositAmount: this.firstDepositAmount,
        bonusReceive: this.bonusReceive,
        totalBetAmountLimit: this.totalBetAmountLimit,
        Remark: this.remark,
        isSingle: true
      },
      sTime: undefined,
      eTime: undefined
    };
  },
  computed: {
    ...mapGetters([
      "getDiscountTypeListAddDefault",
      "getUserinfo",
      "getDiscountAuditStatus"
    ])
  },
  created() {
    this.sTime = this.dateFormat(this.createTime);
    this.eTime = this.dateFormat(this.submitTime);
  },
  methods: {
    edit() {
      this.$emit("edit", this.editModel);
    },
    dateFormat(time) {
      let formatTime = time.split(" ");
      return formatTime[0] + "T" + formatTime[1];
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 70%;
  .input-block {
    label {
      width: 40%;
    }
    .remark {
      width: 97%;
    }
  }
  .member {
    width: 97%;
  }
}
</style>