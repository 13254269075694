<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main advance-block">
        <div class="input-block">
          <label>
            {{ $t("default.agent") }}
            <input type="text" v-model="searchForm.agentAccount" />
          </label>
          <label>
            {{ $t("default.bankAccountNumber") }}
            <input
              type="text"
              v-model="searchForm.bankAccountNumber"
            />
          </label>
        </div>
        <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.agent") }}</th>
              <th>{{ $t("default.bankName") }}</th>
              <th>{{ $t("default.bankBranch") }}</th>
              <th>{{ $t("default.bankAccountNumber") }}</th>
              <th>{{ $t("default.bankAccountName") }}</th>
              <th>{{ $t("default.province") }}</th>
              <th>{{ $t("default.city") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="9">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog({ bankData: list }, 'Edit')"
                  :disabled="!getUserinfo.permissions.includes(20301)"
                >{{ $t("default.edit") }}</button>
              </td>
              <td v-if="list.status == 3">
                <button
                  class="normal-btn"
                  @click="handleAudit(list.id)"
                  :disabled="!getUserinfo.permissions.includes(20303)"
                >{{ $t("default.audit") }}</button>
              </td>
              <td
                v-else
              >{{ GLOBAL.i18nCheckName(statusList.find( data => data.id == list.status )) }}</td>
              <td>{{ list.agentAccount }}</td>
              <td>{{ list.bankName }}</td>
              <td>{{ list.bankBranch }}</td>
              <td>{{ list.bankAccountNumber }}</td>
              <td>{{ list.bankAccountName }}</td>
              <td>{{ list.province }}</td>
              <td>{{ list.city }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <transition name="fade" mode="out-in">
        <component
          :is="currDialog"
          :i18nPrefix="i18nPrefix"
          :bankList="getBankList"
          :getBankTypeList="getBankTypeList"
          v-bind="dialogData"
          @toggle="currDialog = undefined"
          @update="updateBank"
          @delete="deleteBank"
        ></component>
      </transition>
      <!-- 確認彈出視窗 -->
      <transition name="fade" mode="out-in">
        <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg =''" @doIt="audit(true)" />
      </transition>
    </div>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getAgentBankList,
  updateBank,
  deleteBank,
  auditBank
} from "@/api/agent-management/agent-bankCard-inquiry";
import { mapGetters } from "vuex";
import Alert from "@/layout/components/Alert";
import Edit from "./dialog/edit";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "BgentBankCardInquiry",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      statusList: [
        {
          id: 1,
          name: "default.active"
        },
        {
          id: 2,
          name: "default.inactive"
        },
        {
          id: 3,
          name: "default.audit"
        },
        {
          id: 4,
          name: "default.canceled"
        }
      ],
      auditModel: {
        id: undefined,
        isApprove: undefined
      },
      alertMsg: undefined,
      i18nPrefix: "member.agent-bankCard-inquiry."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getBankList", "getUserinfo", "getBankTypeList"])
  },
  components: {
    Edit,
    Alert
  },
  created() {
    if (this.$route.query?.permissionId) {
      this.searchForm.status = 3;
      this.search(true);
    }
  },
  methods: {
    async search(all = false) {
      this.closeDialog();
      if (!all) this.searchForm.status = 0;
      await getAgentBankList(this.searchForm).then(res => {
        this.allData = res.data;
      });
    },
    updateBank(data) {
      updateBank(data).then(res => {
        if (res) this.search();
      });
    },
    deleteBank(data) {
      deleteBank(data).then(res => {
        if (res) this.search();
      });
    },
    handleAudit(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.auditModel.id = id;
    },
    audit(isApprove) {
      this.alertMsg = undefined;
      this.auditModel.isApprove = isApprove;
      auditBank(this.auditModel).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>