<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + $t("default.store") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="cvs-info">
              <label>
                {{ $t("default.store") }}
                <select v-model="Cvs">
                  <option v-for="(list, index) of CvsNameList" :key="index" :value="list">{{ list }}</option>
                </select>
              </label>
              <label></label>
              <div class="multiselectDiv">
                {{ $t("default.city") }}
                <multiselect v-model="city" :options="cityList">
                </multiselect>
              </div>
              <div class="multiselectDiv">
                {{ $t("default.district") }}
                <multiselect v-model="district" :options="districtList">
                </multiselect>
              </div>
              <div class="multiselectDiv store">
                {{ $t("default.store") }}
                <multiselect v-model="store" :options="storeList" label="storeName" track-by="code"
                  :custom-label="nameWithLang">
                </multiselect>
              </div>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
            <button class="delete-btn main-style-btn" @click="$emit('deleteCvs', CvsDetail.id)"
              :disabled="!getUserinfo.permissions.includes(10121)">{{ $t("default.delete")
              }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getGetCvsName,
  getGetCvsCity,
  getGetCvsDistrict,
  getGetCvsCode,
} from "@/api/selectMenu";
import { mapGetters } from "vuex";
import Message from "@/layout/components/Message";
import Multiselect from "vue-multiselect";

export default {
  name: "editCvs",
  props: ["CvsDetail"],
  data() {
    return {
      editModel: {
        id: this.CvsDetail.id,
        cvsId: this.CvsDetail.cvsId
      },
      CvsNameList: false,
      Cvs: this.CvsDetail.name,
      cityList: [],
      city: this.CvsDetail.city,
      districtList: [],
      district: this.CvsDetail.district,
      storeList: [],
      store: {
        csvId: this.CvsDetail.csvId,
        code: this.CvsDetail.code,
        storeName: this.CvsDetail.storeName,
      },
      firstLoad: true,
    };
  },
  components: { Multiselect },
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
  created() {
    getGetCvsName().then(res => {
      this.CvsNameList = res.data;
    })
    getGetCvsCity({ cvsName: this.Cvs }).then(res => {
        this.cityList = res.data;
      })
      getGetCvsDistrict({ cvsName: this.Cvs, cvsCity: this.city }).then(res => {
        this.districtList = res.data;
      })
      getGetCvsCode({ cvsName: this.Cvs, cvsCity: this.city, cvsDistrict: this.district }).then(res => {
        this.storeList = res.data;
      })
  },
  watch: {
    "Cvs"(v) {
      getGetCvsCity({ cvsName: v }).then(res => {
        this.cityList = res.data;
        // if (this.firstLoad) return
        this.city = res.data[0];
      })
    },
    "city"(v) {
      getGetCvsDistrict({ cvsName: this.Cvs, cvsCity: v }).then(res => {
        this.districtList = res.data;
        // if (this.firstLoad) return
        this.district = res.data[0];
      })
    },
    "district"(v) {
      getGetCvsCode({ cvsName: this.Cvs, cvsCity: this.city, cvsDistrict: v }).then(res => {
        this.storeList = res.data;
        // if (this.firstLoad) {
        //   this.firstLoad = false;
        //   return
        // }
        this.store = res.data[0];
      })
    },
  },
  methods: {
    edit() {
      this.editModel.cvsId = this.store?.id;
      this.$emit("editCvs", this.editModel);
    },
    nameWithLang({ storeName, code }) {
      return `(${code})${storeName}`
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.cvs-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  label {
    width: 45%;

    .disabled {
      background-color: gray;
    }
  }

  .multiselectDiv {
    width: 45%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .store {
    width: 95% !important;
  }
}
</style>