var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(140301),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.create")))]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _vm._m(0),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gameCategory`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}status`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}preview`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.tableList || !_vm.tableList.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "14" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", { staticClass: "small-td" }, [
                        _c("div", { staticClass: "btn-td" }, [
                          _c(
                            "button",
                            {
                              staticClass: "normal-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(140302),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    { list: list },
                                    "Edit"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.edit")))]
                          ),
                        ]),
                      ]),
                      _c("td", { staticClass: "small-td" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.gamesCategorys.find(
                                  (data) => list.gamesCategory === data.id
                                )
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { staticClass: "small-td" }, [
                        _vm._v(
                          _vm._s(
                            list.status
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "url-td" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: list.imageUrl,
                                expression: "list.imageUrl",
                              },
                            ],
                            attrs: { title: list.imageUrl },
                            on: {
                              click: function ($event) {
                                _vm.handleDialog(
                                  {
                                    image:
                                      list.imageUrl +
                                      "?v=" +
                                      new Date().getTime(),
                                  },
                                  "Photo"
                                )
                              },
                            },
                          },
                          [_vm._v("image")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: list.mobileImageUrl,
                                expression: "list.mobileImageUrl",
                              },
                            ],
                            attrs: { title: list.mobileImageUrl },
                            on: {
                              click: function ($event) {
                                _vm.handleDialog(
                                  {
                                    image:
                                      list.mobileImageUrl +
                                      "?v=" +
                                      new Date().getTime(),
                                  },
                                  "Photo"
                                )
                              },
                            },
                          },
                          [_vm._v("mobileImage")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: list.appImageUrl,
                                expression: "list.appImageUrl",
                              },
                            ],
                            attrs: { title: list.imageUrl },
                            on: {
                              click: function ($event) {
                                _vm.handleDialog(
                                  {
                                    image:
                                      list.appImageUrl +
                                      "?v=" +
                                      new Date().getTime(),
                                  },
                                  "Photo"
                                )
                              },
                            },
                          },
                          [_vm._v("appImage")]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  gamesCategorys: _vm.gamesCategorys,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                  create: _vm.create,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.deleteGamesCategoryInfo,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_c("div", { staticClass: "btn-td" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }