export let searchModel = {
    id: undefined,                 // 獎券期別
    signInType: undefined,         // 簽到類型
    accumulatedSignInDay: 0,            // 累積簽到日
    memberAccount: "", // 會員
    isFuzzySearch: false, // 模糊搜尋
    status: 0,  // 查詢狀態
    page:  {
        "take": 10,
        "skip": 0
    }
}