<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              <strong>{{ $t(`${i18nPrefix}deviceType`) }}</strong>
              <select v-model="editModel.deviceType" disabled>
                <option v-for="list of deviceType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label v-show="editModel.deviceType !== 2">
              <div>
                <strong>{{ $t(`${i18nPrefix}version`) }}</strong>
                {{ '(' + $t(`${i18nPrefix}msg`) + ')' }}
              </div>
              <input type="text" v-model="editModel.version" />
            </label>
            <strong>{{ $t(`${i18nPrefix}auto`) }}</strong>
            <div class="input-block-multiselect">
              {{ $t(`${i18nPrefix}country`) }}
              <multiselect v-model="country" label="name" track-by="id" :options="countrys" :custom-label="nameWithLang"
                :multiple="true"></multiselect>
            </div>
            <div class="input-block-multiselect" v-show="editModel.deviceType !== 2">
              {{ $t(`${i18nPrefix}lang`) }}
              <multiselect v-model="lang" label="name" track-by="id" :options="langs" :multiple="true"></multiselect>
            </div>
            <label v-show="editModel.deviceType !== 2">
              <strong>{{ $t(`${i18nPrefix}pattern`) }}</strong>
              <select v-model="editModel.pattern">
                <option v-for="list of pattern" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label v-show="editModel.pattern === 1">
              {{ $t(`${i18nPrefix}checkTime`) }}
              <date-picker v-model="firstInstallTime" format="YYYY-MM-DD HH:mm:ss" value-type="format"
                type="datetime"></date-picker>
            </label>
            <label v-show="editModel.pattern === 2">
              {{ $t(`${i18nPrefix}checkTime`) }}
              <date-picker v-model="lastInstallTime" format="YYYY-MM-DD HH:mm:ss" value-type="format"
                type="datetime"></date-picker>
            </label>
            <label v-show="editModel.deviceType === 2">
              <strong>{{ $t(`${i18nPrefix}redirectUrl`) }}</strong>
              <input type="text" v-model="editModel.redirectUrl" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create" :disabled="checkBtn">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { transToday, joinT } from "@/utils/transDateUtils";

export default {
  name: "Create",
  props: ["list", "langs", "countrys", "pattern", "deviceType", "i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.list.id,
        version: this.list.version,
        deviceType: this.list.deviceType,
        pattern: this.list.pattern,
        lang: undefined,
        country: undefined,
        firstInstallTime: undefined,
        lastInstallTime: undefined,
        redirectUrl: this.list.redirectUrl
      },
      lang: this.langs?.filter(el =>
        JSON.parse(this.list.lang)
          .langs.map(data => {
            return data;
          })
          .includes(el.name)
      ),
      country: this.countrys?.filter(el =>
        JSON.parse(this.list.country)
          .countrys.map(data => {
            return data;
          })
          .includes(el.name)
      ),
      firstInstallTime: this.list.firstInstallTime,
      lastInstallTime: this.list.lastInstallTime,
      checkBtn: false
    };
  },
  components: {
    Multiselect
  },
  watch: {
    "editModel.version"(val) {
      this.editModel.version = val.trim()
      this.checkBtn = val.trim() === '' ? true : false
    },
    "editModel.deviceType"(val) {
      if (val == 2) {
        this.editModel.version = "";
        this.editModel.pattern = 0;
        this.lang = [];
      }
    }
  },
  methods: {
    create() {
      this.editModel.firstInstallTime = this.firstInstallTime
        ? joinT(this.firstInstallTime + this.GLOBAL.timeZone)
        : undefined;
      this.editModel.lastInstallTime = this.lastInstallTime
        ? joinT(this.lastInstallTime + this.GLOBAL.timeZone)
        : undefined;
      this.editModel.lang = this.lang.map(data => data.name);
      this.editModel.country = this.country.map(data => data.name);
      this.$emit("edit", this.editModel);
    },
    nameWithLang({ name, title }) {
      return `${name} (${title})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 30%;

  .input-block {

    .input-block-multiselect,
    input,
    label {
      width: 100%;
    }
  }

  .advance-block {
    margin-top: 100px;
  }
}
</style>