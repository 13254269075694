export let searchModel = {
    startCreateTime: undefined,
    endCreateTime: undefined,
    startAuditTime: undefined,
    endAuditTime: undefined,
    memberAccount: undefined,
    memberName: undefined,
    katakana: undefined,
    status: 1,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}