var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-box" }, [
    _c("i", { staticClass: "fa", attrs: { id: "icon" } }),
    _vm._v(" " + _vm._s(_vm.msg) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }