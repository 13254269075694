var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoadingBar"),
      _c("navbar"),
      _vm.windoWidth > 768 ? _c("SidebarV2") : _c("Sidebar"),
      _c("app-main"),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c(_vm.agentInfo, { tag: "component", on: { toggle: _vm.close } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }