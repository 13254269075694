<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.create") }}{{ $t(`title.gamesCategoryInfo`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}dialog.frontend`) }}
              <select v-model="createModel.PlatformVersion">
                <option
                  v-for="list of platformVersions"
                  :key="list.id"
                  :value="list.id * 1"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}gameCategory`) }}
              <select v-model="createModel.GamesCategory">
                <option
                  v-for="list of gamesCategorys"
                  :key="list.id"
                  :value="list.id * 1"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}gamePlatform`) }}
              <select v-model="createModel.GamePlatform">
                <option
                  v-for="list of gamePlatforms"
                  :key="list.id"
                  :value="list.id * 1"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}sort`) }}
              <input type="text" v-model="createModel.SortId" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="createModel.Status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.haveLobby`) }}
              <select v-model="createModel.HaveLobby">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isImageFullpage`
                )
              }}
              <select v-model="createModel.IsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isMobileImageFullpage`
                )
              }}
              <select v-model="createModel.MobileIsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isMainPageImageFullpage`
                )
              }}
              <select v-model="createModel.MainPageIsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}dialog.isMobileMainPageImageIsFullpage`
                )
              }}
              <select v-model="createModel.MobileMainPageIsFull">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}isHot`
                )
              }}
              <select v-model="createModel.isHot">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{
                $t(
                  `${i18nPrefix}isNew`
                )
              }}
              <select v-model="createModel.isNew">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.multiples`) }}
              <input type="text" v-model="createModel.Multiples" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}depositLimit`) }}
              <input type="text" v-model="createModel.depositLimit" />
            </label>
            <label class="title">
              {{ $t(`${i18nPrefix}dialog.title`) }}
              <input type="text" v-model="createModel.Title" />
            </label>
          </div>
          <div class="textarea-div">
            {{ $t(`${i18nPrefix}dialog.copywriting`) }}
            <textarea
              v-model="createModel.Content"
              rows="5"
            ></textarea>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('FaviconUp')">
              <div v-if="!FaviconUp" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}FaviconUp</span>
              </div>
              <img v-else :src="FaviconUp" />
            </div>
            <div class="previewDiv" @click="upload('FaviconDown')">
              <div v-if="!FaviconDown" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}FaviconDown</span>
              </div>
              <img v-else :src="FaviconDown" />
            </div>
            <div class="previewDiv" @click="upload('Image')">
              <div v-if="!Image" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}Image</span>
              </div>
              <img v-else :src="Image" />
            </div>
            <div class="previewDiv" @click="upload('MobileImage')">
              <div v-if="!MobileImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MobileImage</span>
              </div>
              <img v-else :src="MobileImage" />
            </div>
            <div class="previewDiv" @click="upload('MainPageImage')">
              <div v-if="!MainPageImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MainPageImage</span>
              </div>
              <img v-else :src="MainPageImage" />
            </div>
            <div class="previewDiv" @click="upload('MobileMainPageImage')">
              <div v-if="!MobileMainPageImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MobileMainPageImage</span>
              </div>
              <img v-else :src="MobileMainPageImage" />
            </div>
            <div class="previewDiv" @click="upload('BackgroundImage')">
              <div v-if="!BackgroundImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}BackgroundImage</span>
              </div>
              <img v-else :src="BackgroundImage" />
            </div>
            <div class="previewDiv" @click="upload('BackgroundImgMobile')">
              <div v-if="!BackgroundImgMobile" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}BackgroundImgMobile</span>
              </div>
              <img v-else :src="BackgroundImgMobile" />
            </div>
            <div class="previewDiv" @click="upload('AppImage')">
              <div v-if="!AppImage" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}AppImage</span>
              </div>
              <img v-else :src="AppImage" />
            </div>
            <div class="previewDiv" @click="upload('AppFaviconUp')">
              <div v-if="!AppFaviconUp" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}AppFaviconUp</span>
              </div>
              <img v-else :src="AppFaviconUp" />
            </div>
            <div class="previewDiv" @click="upload('HomePageImg')">
              <div v-if="!HomePageImg" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}HomePageImg</span>
              </div>
              <img v-else :src="HomePageImg" />
            </div>
            <div class="previewDiv" @click="upload('HomePageImgMobile')">
              <div v-if="!HomePageImgMobile" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}HomePageImgMobile</span>
              </div>
              <img v-else :src="HomePageImgMobile" />
            </div>
          </div>
          <input
            type="file"
            ref="file"
            class="custom-file"
            @change="fileSelect"
          />
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ["gamePlatforms", "gamesCategorys", "platformVersions"],
  data() {
    return {
      createModel: {
        Status: 1,
        PlatformVersion: undefined,
        GamesCategory: undefined,
        GamePlatform: undefined,
        SortId: 1,
        HaveLobby: true,
        isHot: false,
        isNew: false,
        Title: undefined,
        Content: undefined,
        BackgroundImage: undefined,
        BackgroundImgMobile: undefined,
        FaviconUp: undefined,
        FaviconDown: undefined,
        IsFull: true,
        Image: undefined,
        MobileIsFull: true,
        MobileImage: undefined,
        MainPageIsFull: true,
        MainPageImage: undefined,
        MobileMainPageIsFull: true,
        MobileMainPageImage: undefined,
        AppImage: undefined,
        AppFaviconUp: undefined,
        HomePageImg:undefined,
        HomePageImgMobile:undefined,
        Multiples: 1,
        depositLimit: 0,
      },
      formData: new FormData(),
      FaviconUp: undefined,
      FaviconDown: undefined,
      Image: undefined,
      MobileImage: undefined,
      MainPageImage: undefined,
      MobileMainPageImage: undefined,
      BackgroundImage: undefined,
      BackgroundImgMobile: undefined,
      AppImage: undefined,
      AppFaviconUp: undefined,
      HomePageImg: undefined,
      HomePageImgMobile: undefined,
      chooiceImg: undefined,
      i18nPrefix: "operationManagement.gamesCategoryInfo."
    };
  },
  created() {
    this.createModel.PlatformVersion = this.platformVersions[0].id;
    this.createModel.GamesCategory = this.gamesCategorys[0].id;
    this.createModel.GamePlatform = this.gamePlatforms[0].id;
  },
  methods: {
    create() {
      Object.entries(this.createModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });
      this.$emit("create", this.formData);
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.textarea-div {
  display: flex;
  flex-direction: column;
  padding: 0px 3px;
}
.imgDiv {
  display: flex;
  flex-wrap: wrap;
  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 200px;
    // padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;
    span {
      display: block;
      font-size: 20px;
    }
    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
    .upload {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #749AAF;
      border: 1px solid #537081;
      border-radius: 10px;
      color: #fff;
      .icon {
        width: 60px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .input-block {
    label {
      width: 50% !important;
    }
    .title {
      width: 100% !important;
    }
  }
  .imgDiv {
    .previewDiv {
      width: 100%;
      margin: 5px auto;
    }
  }
}
</style>