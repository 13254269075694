<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}preBalance`) }}</th>
        <th>{{ $t("default.amount") }}</th>
        <th>{{ $t(`${i18nPrefix}afterBalance`) }}</th>
        <th>{{ $t("default.remark") }}</th>
        <th>{{ $t(`${i18nPrefix}table.createTime`) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ fixeddepositForm.account }}</td>
        <td>{{ fixeddepositForm.preBalance }}</td>
        <td :class="fontColor(detailsData.amount)">{{ fixeddepositForm.amount }}</td>
        <td>{{ fixeddepositForm.afterBalance }}</td>
        <td>{{ fixeddepositForm.remark }}</td>
        <td>{{ fixeddepositForm.createTime }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { fixeddepositModel } from "../model";

export default {
  name: "fixeddepositForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      fixeddepositForm: _.cloneDeep(fixeddepositModel),
    };
  },
  watch: {
    detailsData() {
      this.fixeddepositForm = {
        account: this.detailsData.account,
        preBalance: this.digital.format(this.detailsData.preBalance),
        amount: this.digital.format(this.detailsData.amount),
        afterBalance: this.digital.format(
          this.detailsData.afterBalance
        ),
        remark: this.detailsData.remark,
        createTime: this.detailsData.createTime,
      };
    },
  },
  methods: {
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  },
};
</script>
<style lang="scss" scoped>
</style>