import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getDataOverviewTotal(data) {
    return request({
        url: "/DataOverview/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getList(data) {
    return request({
        url: "/DataOverview/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getOnlineMemberList() {
    return request({
        url: "/DataOverview/OnlineMemberList",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}