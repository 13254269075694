<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}walletName`) }}
              <select v-model="createModel.walletId">
                <option v-for="list of wallet" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.status">
                <option v-for="list of getStatusList" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.currencyName") }}
              <select v-model="createModel.currencyId">
                <option v-for="list of currency" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              <span>{{ $t(`${i18nPrefix}exchangeRate`) }} <span class="red">{{ $t(`${i18nPrefix}ex`) }}</span></span>
              <input type="number" v-model.number="createModel.exchangeRate" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('create', createModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Create",
  props: ["data", "currency", "wallet", "i18nPrefix"],
  data() {
    return {
      createModel: {
        walletId: undefined,
        currencyId: undefined,
        status: 1,
        exchangeRate: 1,
      },
    };
  },
  created() {
    console.log(this.currency);
    console.log(this.wallet);
    this.createModel.walletId = this.wallet[0]?.id;
    this.createModel.currencyId = this.currency[0]?.id;
  },
  computed: {
    ...mapGetters(["getStatusList"])
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 70%;

  label {
    width: 40% !important;
  }
}
</style>