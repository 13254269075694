export const emptyFormModel = {
    id: undefined,
    name: '',        // 標題
    discountCategory: 7,
    discountType: 38,       // 優惠類型
    startTime: undefined,   // 開始時間
    endTime: undefined,     // 結束時間
}

export const ruleSetting = {
    participateStatus: {
        includeLevels: [],
        includeAgents: [],
        includeTags: []
    },
    amount: 100,
    count: 1,
    wageringRate: 1,
    redeemCode: '',
    dailyUsageLimit: {
        isEnabled: false,
        count: 1
    },
    ipLimit: {
        isEnabled: false,
        count: 1
    }
}