var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.submitTime`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.bankName`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.bankAccountName`))),
        ]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.bankAccountNumber`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.admin")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.auditTime`)))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.submitTime))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.memberAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.bankName))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.bankAccountName))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.bankAccountNumber))]),
        _c("td", { class: _vm.fontColor(_vm.withdrawalForm.amount) }, [
          _vm._v(_vm._s(_vm.withdrawalForm.amount)),
        ]),
        _c("td", [_vm._v(_vm._s(_vm.$t(_vm.withdrawalForm.auditStatus)))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.remark))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.auditAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.withdrawalForm.auditTime))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }