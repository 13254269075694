var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drop-down-block" }, [
    _c("ul", [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/userInfo/reset-password" } }, [
            _vm._v(_vm._s(_vm.$t("title.reset-password"))),
          ]),
        ],
        1
      ),
      _vm.getUserinfo.permissions.includes(150000)
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/userInfo/agent-wallet" } }, [
                _vm._v(_vm._s(_vm.$t("title.agent-wallet"))),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.getUserinfo.permissions.includes(150300)
        ? _c("li", [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.AgentInfo()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("title.agent-info")))]
            ),
          ])
        : _vm._e(),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "javascript:void(0)" }, on: { click: _vm.logout } },
          [_vm._v(_vm._s(_vm.$t("default.logout")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }