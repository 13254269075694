import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu() {
    return request({
        url: "/CampaignMemberReferral/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getMemberReferralList(data) {
    return request({
        url: "/CampaignMemberReferral/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMemberReferral(data) {
    return request({
        url: "/CampaignMemberReferral/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMemberReferral(data) {
    return request({
        url: "/CampaignMemberReferral/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRebateList(data) {
    return request({
        url: "/CampaignMemberReferral/RebateList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function getRebateDetail(data) {
    return request({
        url: "/CampaignMemberReferral/RebateDetail",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditRebateList(data) {
    return request({
        url: "/CampaignMemberReferral/AuditRebateList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}