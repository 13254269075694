<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.member") }}
              <input
                type="text"
                v-model="editModel.memberAccount"
                disabled
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}submitTime`) }}
              <input
                type="text"
                v-model="editModel.submitTime"
                disabled
              />
            </label>
            <label>
              {{ $t("default.amount") }}
              <input type="text" v-model="editModel.amount" disabled />
            </label>
            <label>
              {{ $t("default.remark") }}
              <textarea v-model="editModel.remark" placeholder></textarea>
            </label>
          </div>
          <div class="advance-block">
            <template v-if="auditStatus !== 1 && auditStatus !== 5">
              <button
                class="normal-btn"
                @click.self="editRemark"
              >{{ $t(`${i18nPrefix}editRemark`) }}</button>
            </template>
            <template v-else>
              <button
                class="normal-btn"
                @click.self="accept"
              >{{ $t(`${i18nPrefix}dialog.accept`) }}</button>
              <button
                class="delete-btn"
                @click.self="reject"
              >{{ $t(`${i18nPrefix}dialog.reject`) }}</button>
            </template>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Audit",
  props: [
    "id",
    "memberAccount",
    "submitTime",
    "amount",
    "auditStatus",
    "remark",
    "applyAmount",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        memberAccount: this.memberAccount,
        submitTime: this.submitTime,
        amount: this.amount ?? this.applyAmount,
        remark: this.remark
      }
    };
  },
  methods: {
    accept() {
      this.editModel.auditStatus = 2;
      this.$emit("audit", this.editModel);
    },
    reject() {
      this.editModel.auditStatus = 3;
      this.$emit("audit", this.editModel);
    },
    editRemark() {
      this.editModel.auditStatus = this.auditStatus;
      this.$emit("audit", this.editModel);
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-block {
  .input-block {
    label {
      width: 60%;
      margin: auto;
    }
    textarea {
      height: 100px;
    }
  }
}
</style>