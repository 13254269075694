<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t('Permission.' + $route.meta.permissionName) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.member") }}
              <input type="text" v-model="editModel.memberAccount" disabled />
            </label>
            <label>
              {{ $t("default.remark") }}
              <textarea v-model="editModel.remark" rows="5"></textarea>
            </label>
          </div>
          <div class="advance-block"><template>
              <button class="normal-btn" @click.self="handleCancel(2)">{{ $t("default.accept") }}</button>
              <button class="delete-btn" @click.self="handleCancel(3)">{{ $t("default.reject") }}</button>
            </template>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="edit" />
    </transition>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";

export default {
  name: "Audit",
  props: [
    "id",
    "memberAccount",
    "remark",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        memberAccount: this.memberAccount,
        remark: this.remark,
        status: 2,
      },
      alertMsg: undefined,
      alertData: 2,
    }
  },
  components: {
    Alert,
  },
  methods: {
    edit() {
      this.alertMsg = undefined;
      this.$emit("audit", this.editModel);
    },
    handleCancel(status) {
      this.editModel.status = status;
      this.alertMsg = this.$t("default.auditMsg", { input: status == 2 ? this.$t("default.accept") : this.$t("default.reject") });
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 50%;
  .dialog-block {
    .input-block {
      label {
        width: 60%;
        margin: 15px auto;
      }
    }
  }
}
</style>