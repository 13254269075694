var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(62201),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.create")))]
            ),
          ]),
        ]),
        _vm._m(0),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gateway`))),
                  ]),
                  _c("th", [_vm._v("Icon")]),
                  _c("th", [_vm._v("RedirectType")]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.tableList, function (list) {
                  return _c("tr", { key: list.id }, [
                    _c("td", { staticClass: "small-td" }, [
                      _c("div", { staticClass: "btn-td" }, [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(62202),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog({ list: list }, "Edit")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                      ]),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.name))]),
                    _c("td", { staticClass: "url-td" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: list.iconWebUrl,
                              expression: "list.iconWebUrl",
                            },
                          ],
                          attrs: { title: list.imageUrl },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(
                                { image: list.iconWebUrl + _vm.v },
                                "Photo"
                              )
                            },
                          },
                        },
                        [_vm._v("Web")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: list.iconAppUrl,
                              expression: "list.iconAppUrl",
                            },
                          ],
                          attrs: { title: list.imageUrl },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(
                                { image: list.iconAppUrl + _vm.v },
                                "Photo"
                              )
                            },
                          },
                        },
                        [_vm._v("App")]
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.GLOBAL.i18nCheckName(
                            _vm.redirectType.find(
                              (data) => list.redirectType === data.id
                            )
                          )
                        )
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  languageType: _vm.languageType,
                  redirectType: _vm.redirectType,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                  create: _vm.create,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "input-block" }, [_c("label")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }