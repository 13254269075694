<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button 
            class="create-btn main-style-btn" 
            :disabled="!getUserinfo.permissions.includes(11001)"
            @click="handleDialog(undefined, 'Create')"
          >
            {{ $t(`${i18nPrefix}create`) }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}name`) }}</th>
                <th>{{ $t(`${i18nPrefix}memberLevel`) }}</th>
                <th>{{ $t(`${i18nPrefix}starTotal`) }}</th>
                <th>{{ $t(`${i18nPrefix}depositRequired`) }}</th>
                <th>{{ $t(`${i18nPrefix}betRequired`) }}</th>
                <th>{{ $t(`${i18nPrefix}bounsMultiple`) }}</th>
                <th>{{ $t(`${i18nPrefix}withdrawalRequired`) }}</th>
                <th>{{ $t(`${i18nPrefix}withdrawalLimit`) }}</th>
                <th>{{ $t(`${i18nPrefix}dayWithdrawalLimit`) }}</th>
                <th>{{ $t(`${i18nPrefix}promotionBonus`) }}</th>
                <th>{{ $t(`${i18nPrefix}weekBonus`) }}</th>
                <th>{{ $t(`${i18nPrefix}monthBonus`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!starLevelList || !starLevelList.length">
                <td colspan="12">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="(list, index) of starLevelList" :key="index">
                <td>
                  <button
                    class="table-btn main-style-btn"
                    :disabled="!getUserinfo.permissions.includes(11002)"
                    @click="handleDialog({ list }, 'Edit')"
                  >
                    {{ $t("default.edit") }}
                  </button>
                </td>
                <td>{{ list.name }}</td>
                <td>{{ list.star + $t("default.star") }}</td>
                <td>{{ list.starTotal }}</td>
                <td>{{ digital.format(list.depositRequired) }}</td>
                <td>{{ digital.format(list.betRequired) }}</td>
                <td>{{ list.bounsMultiple }}</td>
                <td>{{ list.withdrawalRequired }}</td>
                <td>{{ list.withdrawalLimit }}</td>
                <td>{{ list.dayWithdrawalLimit }}</td>
                <td>{{ list.promotionBonus }}</td>
                <td>{{ list.weekBonus }}</td>
                <td>{{ list.monthBonus }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :agentList="getAgentList"
        :i18nPrefix="i18nPrefix"
        :getUserinfo="getUserinfo"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
        @deleteStarLevel="deleteStarLevel"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  starLevelListV2,
  createStarLevelV2,
  updateStarLevelV2,
  deleteStarLevelV2
} from "@/api/member/member-level";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MemberLevelIndex",
  data() {
    return {
      starLevelList: undefined,
      i18nPrefix: "member.member-level.",
    };
  },
  components: {
    Create,
    Edit,
  },
  computed: {
    ...mapGetters(["getUserinfo","getAgentList"]),
  },
  mixins: [handlePageUtils, handleDialog],
  created() {
    this.getList();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    getList() {
      this.closeDialog();
      starLevelListV2().then((res) => {
        this.starLevelList = res.data;
      });
    },
    create(data) {
      createStarLevelV2(data).then((res) => {
        if(res) this.getList();
      });
    },
    edit(data) {
      updateStarLevelV2(data).then((res) => {
        if(res) this.getList();
      });
    },
    deleteStarLevel(data) {
      deleteStarLevelV2(data).then((res) => {
        if(res) this.getList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  label {
    justify-content: flex-start;
    width: 20%;
  }
}
@media screen and (max-width: 1280px) {
  .input-block {
    label {
      width: 45% !important;
      margin: 5px auto;
    }
    .deleteBox {
      width: 10%;
    }
  }
}
.deleteBox {
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 10%;
}
</style>