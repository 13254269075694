<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}gameOrderNumber`) }}
            <input
              type="text"
              v-model="searchForm.gameOrderNumber"
            />
          </label>
          <label>
            {{ $t(`${i18nPrefix}gamePlatform`) }}
            <select v-model="searchForm.gamePlatform">
              <option v-for="list of getGamePlatformsAddDefault" :value="list.id">{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t("default.agent") }}
            <select v-model="searchForm.agentId">
              <option
                v-for="list of getAgentListAddDefault"
                :key="list.id"
                :value="list.id"
              >{{ $t(list.account) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD HH:mm:ss"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD HH:mm:ss"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th v-if="allPlus && allData">
                  <font-awesome-icon
                    class="plus"
                    :icon="['fas', 'plus-circle']"
                    @click="allPlusBoolean(false)"
                  ></font-awesome-icon>
                </th>
                <th v-if="!allPlus">
                  <font-awesome-icon
                    class="plus"
                    :icon="['fas', 'minus-circle']"
                    @click="allPlusBoolean(true)"
                  ></font-awesome-icon>
                </th>
                <th>{{ $t("default.member") }}</th>
                <th
                  class="sort"
                  @click="dataSort('betTime', sort)"
                >{{ $t(`${i18nPrefix}betTime`) }} {{ direction("betTime") }}</th>
                <th>{{ $t(`${i18nPrefix}gamePlatform`) }}</th>
                <th>{{ $t(`${i18nPrefix}gamesCategory`) }}</th>
                <th>{{ $t(`${i18nPrefix}gameOrderNumber`) }}</th>
                <th>{{ $t(`${i18nPrefix}remark`) }}</th>
                <th>{{ $t(`${i18nPrefix}betAmount`) }}</th>
                <th>{{ $t(`${i18nPrefix}validBetAmount`) }}</th>
                <th
                  class="sort"
                  @click="dataSort('winLose', sort)"
                >{{ $t(`${i18nPrefix}winLose`) }} {{ direction("winLose") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!allData || allData.length === 0">
                <td colspan="9">{{ $t("default.noData") }}</td>
              </tr>
              <template v-for="(list, index) of allData">
                <tr :key="list.betTime + index" class="top">
                  <td v-if="plus[index]">
                    <font-awesome-icon
                      class="plus"
                      :icon="['fas', 'plus-circle']"
                      data-toggle="collapse"
                      :data-target="`#a${list.memberAccount}${index}`"
                      @click="plusBoolean(index)"
                    ></font-awesome-icon>
                  </td>
                  <td v-if="!plus[index]">
                    <font-awesome-icon
                      class="plus"
                      :icon="['fas', 'minus-circle']"
                      data-toggle="collapse"
                      :data-target="`#a${list.memberAccount}${index}`"
                      @click="plusBoolean(index)"
                    ></font-awesome-icon>
                  </td>
                  <td>{{ list.memberAccount }}</td>
                  <td>{{ list.betTime }}</td>
                  <td>
                    {{
                    GLOBAL.i18nCheckName(getGamePlatformsAddDefault.find((data) => data.id === list.gamePlatform))
                    }}
                  </td>
                  <td>
                    {{
                    GLOBAL.i18nCheckName(getGamesCategorys.find(
                    (data) => list.gamesCategory === data.id
                    ))
                    }}
                  </td>
                  <td>{{ list.gameOrderNumber }}</td>
                  <td>{{ list.remark }}</td>
                  <td>{{ digital.format(list.betAmount) }}</td>
                  <td>{{ digital.format(list.validBetAmount) }}</td>
                  <td :class="fontColor(list.winLose)">{{ digital.format(list.winLose) }}</td>
                </tr>
                <tr
                  :key="index"
                  class="detail collapse collapseAll"
                  :id="`a${list.memberAccount}${index}`"
                >
                  <td
                    colspan="2"
                  >{{ $t(`${i18nPrefix}betInfo`) }}{{ InfoCount(list.betInfo) }}{{ $t(`${i18nPrefix}source`) }}</td>
                  <td colspan="8">
                    {{ InfoData(list.betInfo, list.gameResult) }}
                    {{ list.gameResult }}
                  </td>
                </tr>
              </template>
              <tr v-if="allData">
                <td colspan="6"></td>
                <td>小計</td>
                <td>{{ digital.format(subTotal.totalBetAmount) }}</td>
                <td>{{ digital.format(subTotal.totalValidBetAmount) }}</td>
                <td
                  :class="fontColor(subTotal.totalWinLose)"
                >{{ digital.format(subTotal.totalWinLose) }}</td>
              </tr>
              <tr v-if="allData">
                <td colspan="6"></td>
                <td>{{ $t("default.total") }}</td>
                <td>{{ digital.format(totalData.totalBetAmount) }}</td>
                <td>{{ digital.format(totalData.totalValidBetAmount) }}</td>
                <td
                  :class="fontColor(totalData.totalWinLose)"
                >{{ digital.format(totalData.totalWinLose) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <serverPagination
        v-if="allData"
        :page="page"
        @ServerPageUtils="search"
        @changeTake="changeTake"
      ></serverPagination>
    </div>
  </div>
</template>

<script>
import {
  memberBetRecordList,
  getTotal
} from "@/api/report-bet-count/bet-record";
import { searchModel } from "./model";
import { transToday, joinT } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import Message from "@/layout/components/Message";

export default {
  name: "BetRecord",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from") + this.GLOBAL.startSecond,
      endTime: transToday("to") + this.GLOBAL.endSecond,
      subTotal: undefined,
      totalData: undefined,
      allPlus: true,
      plus: [],
      arr: [],
      i18nPrefix: "report-bet-count.bet-record."
    };
  },
  mixins: [handleServerPageUtils],
  components: {
    serverPagination,
    QuickSearch
  },
  computed: {
    ...mapGetters(["getAgentListAddDefault" , "getGamePlatformsAddDefault" , "getGamesCategorys"])
  },
  created() {
    this.searchForm.memberAccount =
      this.$route.query?.memberAccount ?? undefined;
    if (this.$route.query?.startTime) {
      this.startTime = this.$route.query?.startTime;
      this.endTime = undefined;
      this.search();
    }
  },
  methods: {
    async search(page = false) {
      if (
        (this.searchForm.memberAccount || "").trim().length === 0 &&
        (this.searchForm.gameOrderNumber || "").trim().length === 0
      ) {
        Message.error(
          this.$t("default.checkInput", {
            input:
              this.$t("default.member") +
              " or " +
              this.$t(`${this.i18nPrefix}gameOrderNumber`)
          }),
          1000
        );
        return;
      }
      this.searchForm.gamePlatform =
        this.searchForm.gamePlatform === "0"
          ? 0 * 1
          : this.searchForm.gamePlatform;
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      if (!page) {
        await getTotal(this.searchForm).then(res => {
          this.totalData = res.data;
        });
      }
      memberBetRecordList(this.searchForm).then(res => {
        this.allData = res.data?.records;
        this.subTotal = res.data?.total;
        this.page = res.data.page;
        for (let index = 0; index < this.allData?.length; index++) {
          this.plus[index] = true;
        }
      });
    },
    changeTime(time) {
      this.startTime = time.startTime + this.GLOBAL.startSecond;
      this.endTime = time.endTime + this.GLOBAL.endSecond;
      this.search();
    },
    InfoData(data, gameResult) {
      let InfoData = data.split(" ");
      let returnData = "";
      for (let i = 0; i < InfoData.length; i++)
        returnData += InfoData[i] + "\n";
      returnData += gameResult.length ? "\n" : "\n\n";
      return returnData;
    },
    InfoCount(data) {
      let InfoData = data.split(" ");
      let returnData = "";
      for (let i = 0; i < InfoData.length + 1; i++) returnData += "\n";
      return returnData;
    },
    plusBoolean(index) {
      this.$set(this.plus, index, !this.plus[index]);
    },
    allPlusBoolean(boolean) {
      for (let index = 0; index < this.plus.length; index++) {
        this.plus[index] = boolean;
      }
      this.allPlus = boolean;
      let allClass = document.getElementsByClassName("collapseAll");
      switch (boolean) {
        case true:
          for (let index = 0; index < allClass.length; index++) {
            allClass[index].classList.remove("show");
          }
          break;
        default:
          for (let index = 0; index < allClass.length; index++) {
            allClass[index].classList.add("show");
          }
          break;
      }
    },
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  text-align: left;
  td {
    padding-left: 4%;
  }
}
.collapsing {
  transition: none !important;
}
.collapse {
  white-space: pre-line;
  background-color: #c2c2ce;
}

.plus {
  font-size: 0.9rem !important;
}

.negative {
  color: red;
}

.pagination-block {
  .count {
    left: 2%;
    position: absolute;
    width: 100px;
  }
  ul {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-right: 20px;
    li {
      cursor: pointer;
      padding: 0.5em 1em;
      font-weight: 600;
      font-size: 0.8rem;
      transition: 0.2s;
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.active {
  background-color: #3c9ed6;
  color: #fff !important;
  border-radius: 5px;
}
.pagination-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .count {
    left: 2%;
    width: 100px;
  }
  ul {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-right: 20px;
    li {
      cursor: pointer;
      padding: 0.5em 1em;
      font-weight: 600;
      font-size: 0.8rem;
      transition: 0.2s;
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.active {
  background-color: #3c9ed6;
  color: #fff !important;
  border-radius: 5px;
}

.displayNone {
  visibility: hidden;
}

@media screen and (max-width: 500px) {
  .pagination-block {
    flex-wrap: wrap;
    align-items: unset;
    justify-content: center;
    ul {
      flex-wrap: wrap;
      margin-right: 0;
      width: 100%;
    }
  }
}
</style>