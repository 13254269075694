var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "confirm-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "confirm-container" }, [
        _c("main", [
          _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("default.delMsg")))])]),
          _c("div", { staticClass: "advance-block" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("delete")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.confirm")))]
            ),
            _c(
              "button",
              {
                staticClass: "delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggle")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.cancel")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }