export let searchModel = {
    startTime: undefined,
    endTime: undefined,
    MemberAccount: undefined,
    fromPlatform: 0,
    toPlatform: 0,
    filterZeroValue: true,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}