import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu() {
    return request({
        url: "/CompanyDepositInfoSettings/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCompanyDepositInfoSettingsList(data) {
    return request({
        url: "/CompanyDepositInfoSettings/List",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function createCompanyDepositInfoSettings(data) {
    return request({
        url: "/CompanyDepositInfoSettings/Create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function editCompanyDepositInfoSettings(data) {
    return request({
        url: "/CompanyDepositInfoSettings/Update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}