const state = {
    sidebar: false,
    page: 1,
    loading: false,
    showCount: 10,
    agentInfo: false
}
const mutations = {
    TOGGLE_SIDEBAR(state, payload) {
        if (payload === undefined) {
            state.sidebar = !state.sidebar;
        } else {
            state.sidebar = payload;
        }
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_SHOWCOUNT(state, payload) {
        state.showCount = payload;
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_AGENTINFO(state, payload) {
        state.agentInfo = payload;
    }
}
const actions = {
    toggleSidebar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}