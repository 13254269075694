<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}currentTicket`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}date`) }}
              <select v-model="ticketModel.id">
                <option v-for="d of periodList" :value="d.id" :key="'data' + d.id">{{ d.name }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.addOption`) }}
              <select v-model="option">
                <option :value="1">{{ $t(`${i18nPrefix}dialog.memberAccount`) }}</option>
                <option :value="2">{{ $t(`${i18nPrefix}dialog.addOption`) }}</option>
              </select>
            </label>
            <label v-if="option == 1">
              {{ $t(`${i18nPrefix}dialog.memberAccount`) }}
              <input type="text" v-model="ticketModel.memberAccount" />
            </label>
            <label v-if="option == 1">
              {{ $t(`${i18nPrefix}currentTicketCount`) }}
              <input type="number" min="1" v-model.number="ticketModel.ticketCount" />
            </label>
            <div class="textarea-div" v-if="option == 1">
              {{ $t("default.remark") }}
              <textarea v-model="ticketModel.remark" rows="5"></textarea>
            </div>
          </div>
          <span v-show="option == 2" class="sample link" @click="getSample">{{ $t(`${i18nPrefix}dialog.example`) }}</span>
          <Excel v-show="option == 2" @UpdateFile="UpdateFile"></Excel>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createCampaignLuckyDrawTicket,
  getTicketSample
} from "@/api/campaign/luckydraw";
import { emptyTicketModel } from "./model";
import Excel from "@/layout/components/Excel.vue";
import { download } from "@/utils/download";

export default {
  name: "Ticket",
  props: ["periodList", "i18nPrefix"],
  data() {
    return {
      emptyTicketModel,
      ticketModel: undefined,
      formData: new FormData(),
      option: 1
    };
  },
  components: {
    Excel
  },
  async created() {
    this.ticketModel = _.cloneDeep(this.emptyTicketModel);
    this.ticketModel.id = this.periodList[0]?.id;
  },
  watch: {
    option(val) {
      if (val == 1) {
        this.formData.delete("File");
      }
    },
    "ticketModel.ticketCount": function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false || val == 0) {
        this.ticketModel.ticketCount = (oldVal ?? '');
      }
    }
  },
  methods: {
    async submit() {
      if (this.option == 1 && !!!this.ticketModel.remark) {
        Message.error(
          this.$t("default.checkInput", {
            input: this.$t("default.remark")
          }),
          1000
        );
        return;
      }

      Object.entries(this.ticketModel).forEach(([key, value]) => {
        if (key !== 'File') this.formData.delete(key, value);
        if (value !== undefined) this.formData.append(key, value);
      });

      let result = {};
      await createCampaignLuckyDrawTicket(this.formData).then(res => {
        result = res;
      });
      if (result) {
        this.$emit("toggle");
      }
    },
    UpdateFile(File) {
      this.formData.delete("File");
      this.formData.append("File", File);
    },
    getSample() {
      getTicketSample().then(res => download(res, "TicketSample.xlsx"));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";

.dialog-container {
  width: 80%;

  .input-block {
    label {
      width: 45% !important;
    }

    .textarea-div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .sample {
    display: flex;
    /* 水平置中 */
    justify-content: center;
    margin-top: 5px;
  }
}</style>