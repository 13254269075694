var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.title`)))]),
            _c(
              "button",
              {
                staticClass: "normal-btn float-right",
                on: {
                  click: function ($event) {
                    return _vm.$emit("update", _vm.editModel)
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.title`)) + " "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editModel.name,
                    expression: "editModel.name",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.editModel.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.editModel, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}fontColor`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editModel.fontColor,
                    expression: "editModel.fontColor",
                  },
                ],
                staticClass: "color",
                attrs: { type: "color" },
                domProps: { value: _vm.editModel.fontColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.editModel, "fontColor", $event.target.value)
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}backgroundColor`)) + " "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editModel.backgroundColor,
                    expression: "editModel.backgroundColor",
                  },
                ],
                staticClass: "color",
                attrs: { type: "color" },
                domProps: { value: _vm.editModel.backgroundColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.editModel,
                      "backgroundColor",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}isBoldFont`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editModel.isBoldFont,
                    expression: "editModel.isBoldFont",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.editModel.isBoldFont)
                    ? _vm._i(_vm.editModel.isBoldFont, null) > -1
                    : _vm.editModel.isBoldFont,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.editModel.isBoldFont,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.editModel,
                            "isBoldFont",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.editModel,
                            "isBoldFont",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.editModel, "isBoldFont", $$c)
                    }
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }