import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getWalletBankList() {
    return request({
        url: "/AgentWalletBank/List",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createWalletBank(data) {
    return request({
        url: "/AgentWalletBank/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateWalletBank(data) {
    return request({
        url: "/AgentWalletBank/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteWalletBank(data) {
    return request({
        url: "/AgentWalletBank/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function withdrawal(data) {
    return request({
        url: "/AgentWalletWithdrawal/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getList(data) {
    return request({
        url: "/AgentWalletWithdrawal/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getWalletBalance() {
    return request({
        url: "/AgentWalletWithdrawal/WalletBalance",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTotal(data) {
    return request({
        url: "/AgentWalletWithdrawal/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTransactionType() {
    return request({
        url: "/AgentWalletWithdrawal/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}