var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.member")))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c(
            "main",
            [
              _c("div", { staticClass: "table-block" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.gamePlatform`))
                        ),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.referralCode`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.createTime`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("member.member-management.lastEventTime")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.allData || _vm.allData.length === 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "6" } }, [
                              _vm._v(_vm._s(_vm.$t("default.noData"))),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.tableList, function (list, index) {
                        return _c(
                          "tr",
                          {
                            key: `${list.createTime}_${list.balance}_${index}`,
                          },
                          [
                            _c("td", [_vm._v(_vm._s(list.account))]),
                            _c("td", [_vm._v(_vm._s(list.balance))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  list.status === 1
                                    ? _vm.$t("default.active")
                                    : _vm.$t("default.inactive")
                                )
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(list.referralCode))]),
                            _c("td", [_vm._v(_vm._s(list.createTime))]),
                            _c("td", [_vm._v(_vm._s(list.eventTime))]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm.totalCount > 0
                ? _c("Pagination", {
                    attrs: { totalCount: _vm.totalCount },
                    on: { handlePage: _vm.handlePage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }