export let searchModel = {
    discountStatus: [
        {
            id: 1,
            name: "default.active"
        },
        {
            id: 2,
            name: "default.inactive"
        },
        {
            id: 3,
            name: "default.openByTime"
        }
    ],
    page: {
        "take": 5,
        "skip": 0
    },
}