<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }}{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}dialog.imagePreview`) }}
              <img :src="createModel.imageUrl" style="width: 100%; display: block; margin: auto" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.mobileImagePreview`) }}
              <img :src="createModel.mobileImageUrl" style="width: 100%; display: block; margin: auto" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}imageLink`) }}
              <input type="text" v-model="createModel.imageUrl" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}mobileImageLink`) }}
              <input type="text" v-model="createModel.mobileImageUrl" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.isMaintain">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}sort`) }}
              <input type="number" v-model="createModel.sort" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}videoLink`) }}
              <input type="text" v-model="createModel.url" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Create",
  data() {
    return {
      createModel: {
        isMaintain: 1,
        sort: 0,
        url: undefined,
        imageUrl: undefined,
        mobileImageUrl: undefined,
      },
      i18nPrefix: "SEO-Function.VideoSetting."
    };
  },
  computed: {
    ...mapGetters(["getStatusList"]),
  },
  methods: {
    submit() {
      this.createModel.isMaintain = (this.createModel.isMaintain === 1 ? true : false);
      this.$emit("create", this.createModel);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";

</style>