var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.agent")))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("div", { staticClass: "member-info" }, [
                _c("label", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memberName,
                        expression: "memberName",
                      },
                    ],
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.memberName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.memberName = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
                  _c("div", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.agent.agentFrom`)
                        ) + " >"
                      ),
                    ]),
                    _c("br"),
                    _c("input", {
                      attrs: { type: "text", disabled: "" },
                      domProps: {
                        value:
                          _vm.agentAccount +
                          "(" +
                          _vm.getAgentList.find(
                            (data) => data.account == _vm.agentAccount
                          ).name +
                          ")",
                      },
                    }),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.agent.agentTo`)
                        ) + " >"
                      ),
                    ]),
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "input-block-multiselect" },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
                        _c("multiselect", {
                          attrs: {
                            label: "name",
                            "track-by": "id",
                            placeholder: _vm.$t(
                              `${_vm.i18nPrefix}dialog.bank.choose`
                            ),
                            options: _vm.getAgentList,
                            "allow-empty": false,
                            preselectFirst: true,
                            "clear-on-select": false,
                            "preserve-search": true,
                            "custom-label": _vm.nameWithLang,
                          },
                          model: {
                            value: _vm.agentData,
                            callback: function ($$v) {
                              _vm.agentData = $$v
                            },
                            expression: "agentData",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  attrs: { disabled: !_vm.agentData },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }