import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberSelectMenu() {
    return request({
        url: "/member/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteBank(data) {
    return request({
        url: "/member/bank/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function memberList(data) {
    return request({
        url: "/member/query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMember(data) {
    return request({
        url: "/member/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editMember(data) {
    return request({
        url: "/member/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function statusMember(data) {
    return request({
        url: "/member/status/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createBank(data) {
    return request({
        url: "/member/bank/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editBank(data) {
    return request({
        url: "/member/bank/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editAgent(data) {
    return request({
        url: "/member/agent/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function kickMember(data) {
    return request({
        url: "/member/kick",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function memberGameWalletList(data) {
    return request({
        url: '/memberGameWallet/list',
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function transfer(data) {
    return request({
        url: "/memberGameWallet/transfer",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function companyBankList(data) {
    return request({
        url: "/member/companyBankList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function listPhoto(data) {
    return request({
        url: "/member/bank/listPhotos",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function uploadPhotos(data) {
    return request({
        url: "/member/bank/uploadPhotos",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deletePhotos(data) {
    return request({
        url: "/member/bank/deletePhotos",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function addTag(data) {
    return request({
        url: "/MemberTag/Add",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteTag(data) {
    return request({
        url: "/MemberTag/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateRemark(data) {
    return request({
        url: "/Member/remark/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function sendRedPacket(data) {
    return request({
        url: "/Member/redPacket",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function winLoseToday(data) {
    return request({
        url: "/Member/winLoseToday",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getMemberExcel(data) {
    return request({
        url: "/Member/export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function updateLevel(data) {
    return request({
        url: "/Member/level/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function updateMemberWallet(data) {
    return request({
        url: "/MemberGameWallet/MemberWallet",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function memberEventList(data) {
    return request({
        url: "/MemberLoginRecord/ListMemberEvent",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function memberDeviceList(data) {
    return request({
        url: "/MemberLoginRecord/DeviceList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCvs(data) {
    return request({
        url: "/Member/cvs/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editCvs(data) {
    return request({
        url: "/Member/cvs/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteCvs(data) {
    return request({
        url: "/Member/cvs/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}