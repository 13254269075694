<template>
  <div :class="isColumn ? 'tag-column' : ''">
    <span  v-for="tag of tags"
           :style="{ 'color': tagList.find(data => data.id === tag).fontColor ,
              'background-color': tagList.find(data => data.id === tag).backgroundColor,
              'font-weight': tagList.find(data => data.id === tag).isBoldFont ? 'bolder' : 'normal'
            }">
      {{ tagList.find(data => data.id === tag).name }}
    </span>
  </div>
</template>

<script>

export default {
  name: "Tags",
  props: ["tags","tagList","isColumn"],
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  border: 1px solid #999;
  border-radius: 4px;
  min-width: 50px;
  margin: 5px 2px;
  padding: 0 10px;
}
.tag-column {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>