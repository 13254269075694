<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Edit')"
            :disabled="
              !getUserinfo.permissions.includes(99999) &&
              !getUserinfo.permissions.includes(62101)
            "
          >{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <!-- <label>
            {{ $t(`${i18nPrefix}name`) }}
            <input type="text" v-model="searchForm.name" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}discountType`) }}
            <select v-model="searchForm.discountType">
              <option
                  v-for="list of getDiscountTypeListAddDefault"
                  :key="list.id"
                  :value="list.id"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>-->
          <!-- <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.discountStatus">
              <option
                v-for="list of statusList"
                :key="list.id"
                :value="list.id * 1"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>-->
          <!-- <div class="condition-block">
            {{ $t("default.status") }}
            <multiselect
              v-model="status"
              label="name"
              track-by="id"
              :placeholder="$t('default.plzSelect')"
              :options="statusList"
              :custom-label="GLOBAL.i18nCheckName"
              :multiple="true"
              :searchable="false"
            ></multiselect>
          </div> -->
        </div>
        <MultiSelectBox :selectData.sync="status" :list="statusList" :multiple="true" />
        <div class="advance-block">
          <!-- <QuickSearch @changeTime="changeTime"></QuickSearch> -->
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <TreeTable
          ref="treeTable"
          :showColumnCheckbox="true"
          :list="allData"
          :column="tableColumn"
          @sort="dataSort"
        >
          <template v-slot:column_action="props">
            <button
              class="table-btn main-style-btn"
              @click="handleDialog({ editData: props }, 'Edit')"
              :disabled="
                !getUserinfo.permissions.includes(99999) &&
                !getUserinfo.permissions.includes(62102)
              "
            >{{ $t("default.edit") }}</button>
          </template>
          <template
            v-slot:column_discountType="props"
          >{{ $t(getDiscountTypeName(props.discountType)) }}</template>
          <template
            v-slot:column_discountStatus="props"
          >{{ $t(getDiscountStatusName(props.discountStatus)) }}</template>
          <template v-slot:column_imageUrl="props">
            <img :src="props.imageUrl" style="width: 100%;" />
          </template>
          <template
            v-slot:column_startTime="props"
          >{{ props.discountStatus === 3 ? props.startTime : '' }}</template>
          <template
            v-slot:column_endTime="props"
          >{{ props.discountStatus === 3 ? props.endTime : '' }}</template>
        </TreeTable>
      </div>
      <serverPagination
        v-if="allData"
        :page="page"
        @ServerPageUtils="searchPage"
        @changeTake="changeTake"
      ></serverPagination>
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @search="search"
      ></component>
    </transition>
  </div>
</template>

<script>
import Edit from "./dialog/edit";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from "vuex";
import { searchModel } from "./model";
import { discountTypeList, discountStatus } from "./dialog/model";
import { discountList } from "@/api/features/discountV2";

import { joinT } from "@/utils/transDateUtils";
import TreeTable from "@/layout/components/TreeTable";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import Multiselect from "vue-multiselect";

export default {
  name: "DiscountSetup",
  components: {
    Edit,
    QuickSearch,
    serverPagination,
    TreeTable,
    Multiselect,
    MultiSelectBox
  },
  data() {
    return {
      discountTypeList,
      discountStatus,
      statusList: [
        {
          id: 1,
          name: "default.active"
        },
        {
          id: 2,
          name: "default.inactive"
        },
        {
          id: 3,
          name: "default.openByTime"
        }
      ],
      status: [
        {
          id: 1,
          name: "default.active"
        },
        {
          id: 2,
          name: "default.inactive"
        },
        {
          id: 3,
          name: "default.openByTime"
        }
      ],
      searchForm: _.cloneDeep(searchModel),
      i18nPrefix: "features.discount-setup."
    };
  },
  mixins: [handleDialog, handleServerPageUtils],
  computed: {
    ...mapGetters(["getDiscountTypeListAddDefault", "getUserinfo"]),
    tableColumn() {
      return [
        {
          key: "action",
          name: "",
          width: 80
        },
        {
          key: "name",
          name: this.$t(`${this.i18nPrefix}name`)
        },
        {
          key: "discountType",
          name: this.$t(`${this.i18nPrefix}discountType`)
        },
        {
          key: "imageUrl",
          name: this.$t("default.image"),
          width: 200
        },
        {
          key: "sort",
          name: this.$t("default.sort")
        },
        {
          key: "startTime",
          name: this.$t(`${this.i18nPrefix}startTime`)
        },
        {
          key: "endTime",
          name: this.$t(`${this.i18nPrefix}endTime`)
        },
        {
          key: "discountStatus",
          name: this.$t("default.status")
        },
        {
          key: "createUser",
          name: this.$t(`${this.i18nPrefix}createAccount`)
        },
        {
          key: "createTime",
          name: this.$t(`${this.i18nPrefix}createTime`)
        },
        {
          key: "updateUser",
          name: this.$t(`${this.i18nPrefix}updateAccount`)
        },
        {
          key: "updateTime",
          name: this.$t(`${this.i18nPrefix}updateTime`)
        },
        {
          key: "remark",
          name: this.$t("default.remark")
        }
      ];
    }
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      this.RESET_DIALOG();
      this.searchForm.discountStatus = this.status.map(
        data => data.id * 1
      );
      
      this.searchPage();
    },
    searchPage(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      discountList(this.searchForm).then(res => {
        this.allData = res.data?.list;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    getDiscountTypeName(value) {
      const index = this.discountTypeList.findIndex(e => e.value === value);
      return this.discountTypeList[index].name;
    },
    getDiscountStatusName(key) {
      const index = this.discountStatus.findIndex(e => e.key === key);
      return this.discountStatus[index].name;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>