<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${this.i18nPrefix}totalRebateAmount`) }}</h4>
        </div>
        <div class="divider"></div>
        <a
          href="javascript:;"
          :class="{ 'check': nowId === list.id}"
          @click="showRebate(list.id)"
          v-for="list of getRebateGamePlatformList"
          :key="list.id"
        >{{ $t(list.name) }}</a>
        <div class="table-block">
          <TreeTable ref="treeTable" :list="allData" :column="tableColumn" :notShowChildren="true"></TreeTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRebate } from "@/api/statistical-report/agentStatisticalAnalysis";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";

export default {
  name: "Rebate",
  props: ["rebateForm", "i18nPrefix"],
  data() {
    return {
      nowId: undefined
    };
  },
  components: {
    TreeTable
  },
  async created() {
    this.showRebate(this.getRebateGamePlatformList[0]?.id);
  },
  computed: {
    ...mapGetters(["getRebateGamePlatformList"]),
    tableColumn() {
      return [
        {
          key: "name",
          name: this.$t("default.agent"),
          tableHeadAlign: true,
          align: "left",
          width: 300
        },
        {
          key: "totalAgentRebateAmount",
          name: this.$t(`${this.i18nPrefix}totalAgentRebateAmount`)
        },
        {
          key: "agentRebateAmount",
          name: this.$t(`${this.i18nPrefix}totalRebateAmount`)
        }
      ];
    }
  },
  mixins: [handleServerPageUtils],
  methods: {
    search(data) {
      getRebate(data).then(res => {
        this.allData = res.data?.list;
      });
    },
    showRebate(id) {
      this.nowId = id;
      this.rebateForm.gamePlatform = [id];
      this.search(this.rebateForm);
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 70% !important;
  a {
    margin: 0 5px;
    font-size: 20px;
  }
  a:link {
    //設定還沒有瀏覽過的連結
    color: rgb(21, 113, 205);
  }
  a:hover {
    //設定滑鼠移經的連結
    text-decoration: underline;
    background-color: #fafafa;
    color: gray;
  }
  a:active {
    //設定正在點選的連結
    text-decoration: none;
    background-color: gray;
    color: #fafafa;
  }
  .check {
    color: #000 !important;
    font-weight: bold;
  }
}
</style>