var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-member" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t("default.edit") +
                    " " +
                    _vm.$t("title." + _vm.$route.meta.title)
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.memberAccount,
                      expression: "data.memberAccount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.data.memberAccount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "memberAccount", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.calculationRate`) + "(%)"
                    ) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.calculationRate,
                      expression: "data.calculationRate",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.data.calculationRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "calculationRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.levelBonusRate`) + "(%)"
                    ) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.levelBonusRate,
                      expression: "data.levelBonusRate",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.data.levelBonusRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "levelBonusRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.amount")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.bonusAmount,
                      expression: "data.bonusAmount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.data.bonusAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "bonusAmount", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}systemRecoveryAmount`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.systemRecoveryAmount,
                      expression: "data.systemRecoveryAmount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.data.systemRecoveryAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.data,
                        "systemRecoveryAmount",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "remark" }, [
                _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.auditModel.remark,
                      expression: "auditModel.remark",
                    },
                  ],
                  domProps: { value: _vm.auditModel.remark },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.auditModel, "remark", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  on: {
                    click: function ($event) {
                      return _vm.edit(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.accept")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.edit(5)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.reject")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }