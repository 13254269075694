import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function CvsList(data) {
    return request({
        url: "/Cvs/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function createCvs(data) {
    return request({
        url: "/Cvs/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function editCvs(data) {
    return request({
        url: "/Cvs/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function deleteCvs(data) {
    return request({
        url: "/Cvs/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}