<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}create`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}memberLevel`) }}
              <input type="number" v-model="createModel.star" />
            </label>
            <label> </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.fromAmount`) }}
              <input type="number" v-model="createModel.fromAmount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.toAmount`) }}
              <input type="number" v-model="createModel.toAmount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}liveCasinoRate`) }} %
              <input type="number" v-model="createModel.liveCasinoRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}slotRate`) }} %
              <input type="number" v-model="createModel.slotRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}fishingRate`) }} %
              <input type="number" v-model="createModel.fishingRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}lotteryRate`) }} %
              <input type="number" v-model="createModel.lotteryRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}sportRate`) }} %
              <input type="number" v-model="createModel.sportRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}eSportRate`) }} %
              <input type="number" v-model="createModel.eSportRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}cardGameRate`) }} %
              <input type="number" v-model="createModel.cardGameRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}cockFighting`) }} %
              <input type="number" v-model="createModel.cockFightingRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}arcade`) }} %
              <input type="number" v-model="createModel.arcadeRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}promotionBonus`) }}
              <input type="number" v-model="createModel.promotionBonus" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ["i18nPrefix"],
  data() {
    return {
      createModel: {
        star: undefined,
        fromAmount: undefined,
        toAmount: undefined,
        liveCasinoRate: undefined,
        slotRate: undefined,
        fishingRate: undefined,
        lotteryRate: undefined,
        sportRate: undefined,
        eSportRate: undefined,
        cardGameRate: undefined,
        cockFightingRate: undefined,
        arcadeRate: undefined,
        promotionBonus: undefined,
      },
    };
  },
  methods: {
    create() {
      if ((~~this.createModel.fromAmount > ~~this.createModel.toAmount)) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.alertMsg`), 1000)
        return
      }
      for (const [key, value] of Object.entries(this.createModel)) {
        this.createModel[key] = (!this.createModel[key] || this.createModel[key].lengtn === 0) ? 0 : value * 1;
      }
      this.$emit('create', this.createModel );
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 60%;
  .input-block {
    label {
      width: 40%;
    }
  }
}
</style>