<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(140201)">{{ $t("default.create") }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block mb-3">
          <label>
            {{ $t(`${i18nPrefix}gamePlatform`) }}
            <select v-model="searchForm.gamePlatformId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of gamePlatforms" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}gameName`) }}
            <input type="text" v-model="searchForm.name" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}hotGame`) }}
            <select v-model="searchForm.isHot">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}isNew`) }}
            <select v-model="searchForm.isNew">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option :value="true">{{ $t("default.active") }}</option>
              <option :value="false">{{ $t("default.inactive") }}</option>
            </select>
          </label>
        </div>
        <div class="advance-block pc-only">
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" class="pc-only">
      </serverPagination>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>
                <div class="btn-td" v-show="allData && allData.length">
                  <input type="checkbox" @click="checkAll()" />
                  <button class="delete-btn" @click="handleDelete(ids)"
                    :disabled="!getUserinfo.permissions.includes(140203)">{{ $t("default.delete") }}</button>
                </div>
              </th>
              <th>{{ $t(`${i18nPrefix}gamePlatform`) }}</th>
              <th>{{ $t(`${i18nPrefix}gameCategoryName`) }}</th>
              <th>{{ $t(`${i18nPrefix}gameName`) }}</th>
              <th>{{ $t(`${i18nPrefix}hotGame`) }}</th>
              <th>{{ $t(`${i18nPrefix}isNew`) }}</th>
              <th>gameCode</th>
              <th>SecondGameCode</th>
              <th>{{ $t(`${i18nPrefix}sort`) }}</th>
              <th>{{ $t(`${i18nPrefix}status`) }}</th>
              <th>{{ $t(`${i18nPrefix}preview`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="14">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :key="list.id">
              <td>
                <div class="btn-td">
                  <input type="checkbox" v-model="ids" :value="list.id" />
                  <button class="table-btn main-style-btn" @click="handleDialog({ list: list }, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(140202)">{{ $t("default.edit") }}</button>
                </div>
              </td>
              <td>
                {{
                  GLOBAL.i18nCheckName(gamePlatforms.find(
                    (data) => list.gamePlatformId === data.id
                  ))
                }}
              </td>
              <td>
                {{
                  GLOBAL.i18nCheckName(gamesCategorys.find(
                    (data) => list.category === data.id
                  ))
                }}
              </td>
              <td>{{ list.name }}</td>
              <td>
                <label>
                  <div class="slide-block" style="margin: auto" :class="{ 'slide-block-on': list.isHot }">
                    <div class="slide" @click="toggleSlide" :class="{ 'slide-on': list.isHot }">
                      <label for="isHot" style="display: none"></label>
                      <input type="checkbox" id="isHot" v-model="list.isHot"
                        @click="updateIsHot({ id: list.id, isHot: !list.isHot })" style="display: none" />
                    </div>
                  </div>
                </label>
              </td>
              <td class="small-td">
                <label>
                  <div class="slide-block" style="margin: auto" :class="{ 'slide-block-on': list.isNew }">
                    <div class="slide" @click="toggleSlide" :class="{ 'slide-on': list.isNew }">
                      <label for="isNew" style="display: none"></label>
                      <input type="checkbox" id="isNew" v-model="list.isNew"
                        @click="updateIsNew({ id: list.id, isNew: !list.isNew })" style="display: none" />
                    </div>
                  </div>
                </label>
              </td>
              <td>{{ list.gameCode }}</td>
              <td>{{ list.secondGameCode }}</td>
              <td>{{ list.sortId }}</td>
              <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
              <td class="url-td">
                <span v-show="list.imageUrl" @click="handleDialog({ image: list.imageUrl + v }, 'Photo')"
                  :title="list.imageUrl">image</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake"></serverPagination>
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" v-bind="dialogData" :gamePlatforms="gamePlatforms" :gamesCategorys="gamesCategorys"
        :platformVersions="platformVersions" @toggle="currDialog = undefined" @edit="edit" @create="create"></component>
    </transition>

    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="deleteGameInfo" />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  SelectMenu,
  getGameInfoList,
  createGameInfo,
  deleteGameInfo,
  updateGameInfo,
  updateIsHot,
  updateIsNew
} from "@/api/operationManagement/GameInfo";
import { mapGetters } from "vuex";
import Edit from "./dialog/edit";
import Create from "./dialog/create";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { toggleStyle } from "@/utils/slideUtils";

export default {
  name: "GameInfo",
  components: {
    Edit,
    Photo,
    Alert,
    Create,
    serverPagination
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      checkAllBtn: false,
      alertMsg: undefined,
      ids: [],
      gamePlatforms: [],
      gamesCategorys: [],
      platformVersions: [],
      v: "?v=" + new Date().getTime(),
      i18nPrefix: "operationManagement.gameInfo."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getAuditStatusList", "getUserinfo"])
  },
  created() {
    SelectMenu({}).then(res => {
      this.gamePlatforms = res.data.gamePlatforms.map(data => {
        return { id: data.id, name: "GamePlatform." + data.name };
      });
      this.gamesCategorys = res.data.gamesCategorys.map(data => {
        return { id: data.id, name: "GameCategory." + data.name };
      });
      this.platformVersions = res.data.platformVersions;
    });
  },
  watch: {
    ids() {
      this.checkAllBtn = this.ids.length === this.allData.length ? true : false;
    }
  },
  methods: {
    toggleSlide: toggleStyle,
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      getGameInfoList(this.searchForm).then(res => {
        this.allData = res.data.gameInfos;
        this.page = res.data.page;
        this.currDialog = undefined;
      });
    },
    create(data) {
      createGameInfo(data).then(res => {
        if (res) this.search(this.page.current);
      });
    },
    edit(data) {
      updateGameInfo(data).then(res => {
        if (res) this.search(this.page.current);
      });
    },
    updateIsHot(data) {
      updateIsHot(data).then(res => {
        if (res) this.search();
      });
    },
    updateIsNew(data) {
      updateIsNew(data).then(res => {
        if (res) this.search();
      });
    },
    checkAll() {
      this.ids = this.checkAllBtn ? [] : this.allData.map(data => data.id);
    },
    handleDelete() {
      this.alertMsg = "確定刪除 ?";
    },
    deleteGameInfo() {
      this.alertMsg = "";
      deleteGameInfo({ ids: this.ids }).then(res => {
        if (res) this.search();
      });
      this.ids = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-td {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>