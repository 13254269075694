import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function bankList() {
    return request({
        url: "/bank/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function createBank(data) {
    return request({
        url: "/bank/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function editBank(data) {
    return request({
        url: "/bank/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function deleteBank(data) {
    return request({
        url: "/bank/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}