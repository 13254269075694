<template>
  <div class="dialog-mask" @mousedown.self="$emit('updateWallet')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}gameWallet`) }}</h4>
          <button class="table-btn main-style-btn transferOut" :disabled="!getUserinfo.permissions.includes(10116)"
            @click="handleCalc()">{{ $t(`${i18nPrefix}dialog.game-wallet.allTurnBack`) }}</button>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="member">
              <label>
                {{ $t(`${i18nPrefix}dialog.game-wallet.account`) }}
                <input type="text" v-model="account" :disabled="true" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.game-wallet.wallet`) }}
                <input type="text" :value="new Intl.NumberFormat().format(mainBlance)" :disabled="true" />
              </label>
            </div>
          </div>
        </main>
        <main>
          <div class="table-block-white">
            <table class="mt-3">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t(`${i18nPrefix}dialog.game-wallet.gamePlatform`) }}</th>
                  <th>{{ $t("default.multiples") }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.game-wallet.customGameAccount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.game-wallet.balance`) }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!tableList || !tableList.length">
                  <td colspan="4">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>
                    <label style="text-align: center">
                      <div
                        class="slide-block"
                        style="margin: auto"
                        :class="{ 'slide-block-on': list.isAccessible }"
                      >
                        <div
                          class="slide"
                          @click="getUserinfo.permissions.includes(10116) ? toggleSlide : ''"
                          :class="{ 'slide-on': list.isAccessible }"
                        >
                          <label for="isAccessible" style="display: none"></label>
                          <input
                            type="checkbox"
                            id="isAccessible"
                            v-model="list.isAccessible"
                            @click="updateMemberWallet({ memberId: id, gamePlatform: list.gamePlatformId, isAccessible: !list.isAccessible })"
                            style="display: none"
                            :disabled="!getUserinfo.permissions.includes(10116)"
                          />
                        </div>
                      </div>
                    </label>
                  </td>
                  <td>
                    {{
                      GLOBAL.i18nCheckName(gamePlatforms.find(
                        (data) => data.id === list.gamePlatformId
                      ))
                    }}
                  </td>
                  <td>{{ list.multiples }}</td>
                  <td>{{ list.customGameAccount }}</td>
                  <td>{{ list.status !== 1 ? 'X' : new Intl.NumberFormat().format(list.balance) }}</td>
                  <td>
                    <button class="table-btn main-style-btn" @click="transferDialog(list)"
                      :disabled="!getUserinfo.permissions.includes(10116)" v-if="list.status === 1">{{
                        $t(`${i18nPrefix}dialog.game-wallet.transfer`) }}</button>
                    <div v-else>{{ statusList.find(data => data.id === list.status).name }}</div>
                  </td>
                </tr>
                <tr v-show="tableList.length">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ $t("default.total") }}</td>
                  <td>{{ new Intl.NumberFormat().format(totalGameBalance) }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :is="currDialog" @toggle="currDialog = dialogData = undefined"
        v-bind="dialogData" @transfer="transfer"></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="transferAll" />
    </transition>
  </div>
</template>

<script>
import {
  memberGameWalletList,
  transfer,
  updateMemberWallet
} from "@/api/member/member";
import WalletTransfer from "./wallet-transfer.vue";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";

export default {
  name: "GameWallet",
  props: ["id", "account", "gamePlatforms", "getUserinfo", "i18nPrefix"],
  data() {
    return {
      mainBlance: 0,
      totalGameBalance: 0,
      currDialog: undefined,
      dialogData: undefined,
      tableList: [],
      statusList: [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}dialog.game-wallet.normal`)
        },
        {
          id: 2,
          name: this.$t(`${this.i18nPrefix}dialog.game-wallet.maintain`)
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}dialog.game-wallet.abnormal`)
        }
      ],
      alertMsg: undefined
    };
  },
  components: {
    Alert,
    WalletTransfer
  },
  async created() {
    await this.GetMemberGameWalletList();
    console.log(this.getUserinfo.permissions.includes(10116));
  },
  methods: {
    async GetMemberGameWalletList() {
      this.currDialog = "";
      await memberGameWalletList({ MemberId: this.id }).then(res => {
        this.mainBlance = res.data.mainBlance;
        this.totalGameBalance = res.data.totalGameBalance;
        this.tableList = res.data.platformBalance;
      });
    },
    handleCalc() {
      this.alertMsg = this.$t(`${this.i18nPrefix}dialog.game-wallet.alertMsg`);
    },
    transfer(transferData) {
      transferData.MemberId = this.id;
      transfer(transferData).then(res => {
        if (res) this.GetMemberGameWalletList();
      });
    },
    transferAll() {
      this.alertMsg = undefined;
      let transferData = this.tableList
        .filter(data => data.balance !== 0 && data.status === 1)
        .map(data => ({
          amount: data.balance * 1,
          multiples: data.multiples * 1,
          gamePlatformId: data.gamePlatformId
        }));
      transferData.length === 0
        ? Message.error(
          this.$t(`${this.i18nPrefix}dialog.game-wallet.errorMsg`),
          1000
        )
        : this.transfer({
          gameTransferList: transferData,
          isTransferIn: false
        });
    },
    updateMemberWallet(data) {
      console.log(data);
      updateMemberWallet(data).then(res => {
        if (res) this.GetMemberGameWalletList();
      });
    },
    transferDialog(list) {
      this.currDialog = "WalletTransfer";
      list.mainBlance = this.mainBlance;
      list.gamePlatform = this.gamePlatforms.find(
        data => data.id === list.gamePlatformId
      ).name;
      this.dialogData = list;
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: auto !important;

  .input-block {
    .member {
      width: 100%;
      display: flex;

      label {
        width: 100%;
        align-items: flex-start;
        font-size: 14px;
        // flex-direction: row;

        input {
          width: 90%;
          text-align: center;
        }
      }
    }
  }
}

// .transferOut {
//   height: 10%;
//   margin: auto;
// }
</style>