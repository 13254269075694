import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberWithdrawalReportList(data) {
    return request({
        url: "/MemberWithdrawalReport/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function selectMenu() {
    return request({
        url: "/MemberWithdrawalReport/select_menu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getExcel(data) {
    return request({
        url: "/MemberWithdrawalReport/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}


export function getMemberWithdrawalReportTotal(data) {
    return request({
        url: "/MemberWithdrawalReport/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}