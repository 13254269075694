var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.setting")))]),
          ]),
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.name`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.code`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.value`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "create-btn main-style-btn",
                          on: {
                            click: function ($event) {
                              return _vm.create()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.create")))]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "slide-block",
                          class: { "slide-block-on": _vm.createModel.status },
                          staticStyle: { margin: "auto" },
                          on: {
                            click: function ($event) {
                              _vm.createModel.status = !_vm.createModel.status
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "slide",
                              class: { "slide-on": _vm.createModel.status },
                            },
                            [
                              _c("label", {
                                staticStyle: { display: "none" },
                                attrs: { for: "status" },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.createModel.status,
                                    expression: "createModel.status",
                                  },
                                ],
                                staticStyle: { display: "none" },
                                attrs: { type: "checkbox", id: "status" },
                                domProps: {
                                  checked: Array.isArray(_vm.createModel.status)
                                    ? _vm._i(_vm.createModel.status, null) > -1
                                    : _vm.createModel.status,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.createModel.status,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.createModel,
                                            "status",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.createModel,
                                            "status",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.createModel, "status", $$c)
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createModel.name,
                            expression: "createModel.name",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.createModel.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.createModel,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createModel.code,
                            expression: "createModel.code",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.createModel.code },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.createModel,
                              "code",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createModel.value,
                            expression: "createModel.value",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.createModel.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.createModel,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._l(_vm.tableList, function (list, index) {
                    return _c("tr", { key: index }, [
                      _vm.editStatus === list.id
                        ? _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "normal-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(list)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.edit")))]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(list.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.delete")))]
                            ),
                          ])
                        : _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn-blue",
                                on: {
                                  click: function ($event) {
                                    _vm.editStatus = list.id
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.setting")))]
                            ),
                          ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "slide-block",
                            class: { "slide-block-on": list.status },
                            staticStyle: { margin: "auto" },
                            attrs: { disabled: _vm.editStatus !== list.id },
                            on: {
                              click: function ($event) {
                                list.status =
                                  _vm.editStatus !== list.id
                                    ? list.status
                                    : !list.status
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "slide",
                                class: { "slide-on": list.status },
                              },
                              [
                                _c("label", {
                                  staticStyle: { display: "none" },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.status,
                                      expression: "list.status",
                                    },
                                  ],
                                  staticStyle: { display: "none" },
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(list.status)
                                      ? _vm._i(list.status, null) > -1
                                      : list.status,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = list.status,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              list,
                                              "status",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              list,
                                              "status",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(list, "status", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.name,
                              expression: "list.name",
                            },
                          ],
                          attrs: {
                            type: "text",
                            disabled: _vm.editStatus !== list.id,
                          },
                          domProps: { value: list.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(list, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.code,
                              expression: "list.code",
                            },
                          ],
                          attrs: {
                            type: "text",
                            disabled: _vm.editStatus !== list.id,
                          },
                          domProps: { value: list.code },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(list, "code", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.value,
                              expression: "list.value",
                            },
                          ],
                          attrs: {
                            type: "text",
                            disabled: _vm.editStatus !== list.id,
                          },
                          domProps: { value: list.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(list, "value", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.deleteCompanyDepositSettings,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }