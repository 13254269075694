<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.agent") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="member-info">
              <label>
                {{ $t(`${i18nPrefix}account`) }}
                <input type="text" v-model="memberName" disabled />
              </label>
              <label>
                {{ $t("default.agent") }}
                <div>
                  <span>{{ $t(`${i18nPrefix}dialog.agent.agentFrom`) }} ></span>
                  <br />
                  <input
                    type="text"
                    :value="agentAccount + '(' + getAgentList.find( data => data.account == agentAccount).name + ')'"
                    disabled
                  />
                  <br />
                  <span>{{ $t(`${i18nPrefix}dialog.agent.agentTo`) }} ></span>
                  <br />
                  <div class="input-block-multiselect">
                    {{ $t("default.agent") }}
                    <multiselect
                      v-model="agentData"
                      label="name"
                      track-by="id"
                      :placeholder="$t(`${i18nPrefix}dialog.bank.choose`)"
                      :options="getAgentList"
                      :allow-empty="false"
                      :preselectFirst="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :custom-label="nameWithLang"
                    ></multiselect>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button
              class="confirm-btn main-style-btn"
              @click="submit"
              :disabled="!agentData"
            >{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
export default {
  name: "Agent",
  props: ["id", "memberName", "agentId", "agentAccount", "i18nPrefix"],
  components: {
    Multiselect
  },
  data() {
    return {
      agentModel: {
        id: this.id,
        agentId: this.agentId
      },
      agentData: []
    };
  },
  computed: {
    ...mapGetters(["getAgentList"])
  },
  methods: {
    submit() {
      this.agentModel.agentId = this.agentData.id;
      this.$emit("agent", this.agentModel);
    },
    nameWithLang({ name, account }) {
      return `${account}(${name})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.member-info {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  label {
    width: 100%;
    div {
      input,
      select {
        width: 100%;
      }
    }
    // div {
    //   position: relative;
    //   width: 100%;
    //   input:not([type="checkbox"]),
    //   select {
    //     margin: 5px;
    //     width: 100%;
    //   }
    //   input[type="checkbox"] {
    //     position: absolute;
    //     top: -8px;
    //   }
    //   label {
    //     display: inline-block;
    //     margin: 0 0 0 20px !important;
    //   }
    // }
  }
}
@media screen and (max-width: 768px) {
  .member-info {
    flex-direction: column;
    width: 90%;
    label {
      width: 100% !important;
    }
  }
}
</style>