<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(99999) &&
              !getUserinfo.permissions.includes(60401)
            "
          >
            {{ $t("default.create") + " " + $t("title." + $route.meta.title) }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}bankCode`) }}</th>
                <th>{{ $t(`${i18nPrefix}bankName`) }}</th>
                <th>{{ $t(`${i18nPrefix}bankAccountName`) }}</th>
                <th>{{ $t(`${i18nPrefix}bankAccountNumber`) }}</th>
                <th>{{ $t("default.status") }}</th>
                <th>{{ $t(`${i18nPrefix}bankGroupName`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateAccount`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button
                    class="table-btn main-style-btn"
                    @click="handleDialog(list, 'Edit')"
                    :disabled="
                      !getUserinfo.permissions.includes(99999) &&
                      !getUserinfo.permissions.includes(60402)
                    "
                  >
                    {{ $t("default.edit") }}
                  </button>
                </td>
                <td>{{ list.bankCode }}</td>
                <td>{{ list.bankName }}</td>
                <td>{{ list.bankAccountName }}</td>
                <td>{{ list.bankAccountNumber }}</td>
                <td>{{ list.status === 1 ? "是" : "否" }}</td>
                <td>{{ list.bankGroupName }}</td>
                <td>{{ list.updateAccount }}</td>
                <td>{{ list.updateTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :bankList="bankList"
        :bankGroupList="bankGroupList"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  bankgroupList,
  createBankGroup,
  editBankGroup,
  bankGroupSelect 
} from "@/api/features/bank-group";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Message from "@/layout/components/Message";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from 'vuex';

export default {
  name: "BankGroup",
  data() {
    return {
      bankGroupList: [],
      bankList: [],
      i18nPrefix: "features.bank-group."
    }
  },
  components: {
    Create,
    Edit,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  created() {
    this.getList();
    bankGroupSelect().then( res => {
      this.bankList = res.data.banks;
      this.bankGroupList = res.data.bankGroups;
    })
  },
  methods: {
    getList() {
      bankgroupList().then((res) => {
        this.allData = res.data;
        this.RESET_DIALOG();
        this.dialogData = undefined;
      });
    },
    create(data) {
      createBankGroup(data).then((res) => {
        if(res) this.getList();
      });
    },
    edit(data) {
      editBankGroup(data).then((res) => {
        if(res) this.getList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>