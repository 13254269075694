var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-mask2" }, [
    _c("div", { ref: "dialog", staticClass: "dialog-container" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t(
                `default.${_vm.editData !== undefined ? "edit" : "create"}`
              )
            )
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "dialog-close",
            on: {
              click: function ($event) {
                return _vm.$emit("toggle")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
      _c("div", { staticClass: "dialog-block" }, [
        _c("main", [
          _c("div", { staticClass: "input-block" }, [
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.basicSettings`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}campaignName`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.name,
                        expression: "formModel.name",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}campaignName`),
                      disabled: _vm.isCampaign && !_vm.padding,
                    },
                    domProps: { value: _vm.formModel.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formModel, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.amount,
                        expression: "ruleSetting.amount",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: "1",
                      placeholder: _vm.$t("default.amount"),
                    },
                    domProps: { value: _vm.ruleSetting.amount },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting,
                          "amount",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}wageringRate`))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.wageringRate,
                        expression: "ruleSetting.wageringRate",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: "1",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}wageringRate`),
                    },
                    domProps: { value: _vm.ruleSetting.wageringRate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting,
                          "wageringRate",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}redeemCode`))),
                    ]),
                    _c("span", { staticClass: "red" }, [
                      _vm._v(
                        "  " +
                          _vm._s(
                            "※" +
                              _vm.$t(`${_vm.i18nPrefix}dialog.redeemCodeMsg`)
                          )
                      ),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ruleSetting.redeemCode,
                        expression: "ruleSetting.redeemCode",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}redeemCode`),
                      disabled: _vm.isCampaign && !_vm.padding,
                    },
                    domProps: { value: _vm.ruleSetting.redeemCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting,
                          "redeemCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("default.startTime")))]),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD HH:mm",
                        "value-type": "format",
                        type: "datetime",
                        "disabled-date": _vm.disabledStart,
                      },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                    _c("span", {
                      ref: "rulesstartTime",
                      staticClass: "form-rules",
                    }),
                  ],
                  1
                ),
                _c(
                  "label",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("default.endTime")))]),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD HH:mm",
                        "value-type": "format",
                        type: "datetime",
                        "disabled-date": _vm.disabledEnd,
                      },
                      model: {
                        value: _vm.endTime,
                        callback: function ($$v) {
                          _vm.endTime = $$v
                        },
                        expression: "endTime",
                      },
                    }),
                    _c("span", {
                      ref: "rulesendTime",
                      staticClass: "form-rules",
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}count`))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.count,
                        expression: "ruleSetting.count",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: "1",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}count`),
                    },
                    domProps: { value: _vm.ruleSetting.count },
                    on: {
                      keyup: function ($event) {
                        _vm.ruleSetting.count = _vm.GLOBAL.checkValue(
                          _vm.ruleSetting.count,
                          0,
                          1,
                          9999999
                        )
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting,
                          "count",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _c("span", { staticClass: "span-row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.dailyUsageLimit.isEnabled,
                          expression: "ruleSetting.dailyUsageLimit.isEnabled",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.ruleSetting.dailyUsageLimit.isEnabled
                        )
                          ? _vm._i(
                              _vm.ruleSetting.dailyUsageLimit.isEnabled,
                              null
                            ) > -1
                          : _vm.ruleSetting.dailyUsageLimit.isEnabled,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.ruleSetting.dailyUsageLimit.isEnabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.ruleSetting.dailyUsageLimit,
                                  "isEnabled",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.ruleSetting.dailyUsageLimit,
                                  "isEnabled",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.ruleSetting.dailyUsageLimit,
                              "isEnabled",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dailyUsageLimit`)) +
                        " "
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.dailyUsageLimit.count,
                        expression: "ruleSetting.dailyUsageLimit.count",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}dailyUsageLimit`),
                      disabled: !_vm.ruleSetting.dailyUsageLimit.isEnabled,
                    },
                    domProps: { value: _vm.ruleSetting.dailyUsageLimit.count },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting.dailyUsageLimit,
                          "count",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _c("span", { staticClass: "span-row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.ipLimit.isEnabled,
                          expression: "ruleSetting.ipLimit.isEnabled",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.ruleSetting.ipLimit.isEnabled
                        )
                          ? _vm._i(_vm.ruleSetting.ipLimit.isEnabled, null) > -1
                          : _vm.ruleSetting.ipLimit.isEnabled,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.ruleSetting.ipLimit.isEnabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.ruleSetting.ipLimit,
                                  "isEnabled",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.ruleSetting.ipLimit,
                                  "isEnabled",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.ruleSetting.ipLimit, "isEnabled", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.ipLimit`)) +
                        " "
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.ipLimit.count,
                        expression: "ruleSetting.ipLimit.count",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}dialog.ipLimit`),
                      disabled: !_vm.ruleSetting.ipLimit.isEnabled,
                    },
                    domProps: { value: _vm.ruleSetting.ipLimit.count },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting.ipLimit,
                          "count",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.eligibility`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.includeAgents`)
                        ) +
                        " "
                    ),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "id",
                          placeholder: "",
                          options: _vm.agentList,
                          multiple: true,
                          "close-on-select": false,
                          disabled: _vm.isCampaign && !_vm.padding,
                        },
                        model: {
                          value: _vm.agentData,
                          callback: function ($$v) {
                            _vm.agentData = $$v
                          },
                          expression: "agentData",
                        },
                      },
                      [
                        _c("template", { slot: "noOptions" }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberTag`)
                        ) +
                        " "
                    ),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "id",
                          placeholder: "",
                          options: _vm.tagList,
                          multiple: true,
                          "close-on-select": false,
                          disabled: _vm.isCampaign && !_vm.padding,
                        },
                        model: {
                          value: _vm.memberTag,
                          callback: function ($$v) {
                            _vm.memberTag = $$v
                          },
                          expression: "memberTag",
                        },
                      },
                      [
                        _c("template", { slot: "noOptions" }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-box" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberLevel`)
                        ) +
                        " "
                    ),
                    _c("multiselect", {
                      attrs: {
                        label: "name",
                        "track-by": "star",
                        placeholder: "",
                        options: _vm.starList,
                        multiple: true,
                        "close-on-select": false,
                        disabled: _vm.isCampaign && !_vm.padding,
                      },
                      model: {
                        value: _vm.memberStar,
                        callback: function ($$v) {
                          _vm.memberStar = $$v
                        },
                        expression: "memberStar",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detail == undefined,
              expression: "detail == undefined",
            },
          ],
          staticClass: "advance-block-release",
        },
        [
          _c("div", { staticClass: "m-auto" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                attrs: {
                  disabled:
                    _vm.ruleSetting.redeemCode.length > 50 ||
                    _vm.ruleSetting.redeemCode.length == 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggle")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.cancel")))]
            ),
          ]),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isCampaign == undefined &&
                    _vm.editData !== undefined &&
                    _vm.getUserinfo.permissions.includes(171401),
                  expression:
                    "isCampaign == undefined && editData !== undefined && getUserinfo.permissions.includes(171401)",
                },
              ],
              staticClass: "btn-orange",
              attrs: {
                disabled:
                  _vm.ruleSetting.redeemCode.length > 50 ||
                  _vm.ruleSetting.redeemCode.length == 0,
              },
              on: {
                click: function ($event) {
                  return _vm.submit(true)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}release`)) + " ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }