<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button class="create-btn main-style-btn"
                @click="handleDialog(undefined, 'Create')"
                :disabled="createStatus && !getUserinfo.permissions.includes(100401)">
          {{ $t("default.create") }}
        </button>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}title`) }}
            <input type="text" v-model="searchForm.title" />
          </label>
          <label>
            {{ $t("default.agent") }}
            <select v-model="searchForm.agentId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option
                  v-for="list of agentList"
                  :key="list.id"
                  :value="list.id * 1"
              >
                {{ list.domain }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}dateFrom`) }}
            <date-picker v-model="publishStateTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}dateTo`) }}
            <date-picker v-model="publishEndTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.status">
              <option
                  v-for="list of getStatusListAddDefault"
                  :key="list.id"
                  :value="list.id"
              >
                {{ list.name }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
          <tr>
            <th class="d-flex">
              <input type="checkbox" v-model="checkAllBtn" @click="checkAll()">
              <button class="delete-btn" @click="handleDelete(ids)" :disabled="!getUserinfo.permissions.includes(100403)">{{ $t("default.delete") }}</button>
            </th>
            <th>{{ $t("default.edit") }}</th>
            <th>{{ $t(`${i18nPrefix}title`) }}</th>
            <th>{{ $t("default.agent") }}</th>
            <th>{{ $t(`${i18nPrefix}blogCategoryName`) }}</th>
            <th>{{ $t(`${i18nPrefix}pathName`) }}</th>
            <th>{{ $t("default.status") }}</th>
            <th class="sort" @click="dataSort('updateTime', sort)">{{ $t(`${i18nPrefix}updateDate`) }} {{ direction("updateTime") }}</th>
            <th class="sort" @click="dataSort('publishTime', sort)">{{ $t(`${i18nPrefix}postDate`) }} {{ direction("publishTime") }}</th>
            <th>{{ $t(`${i18nPrefix}page`) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!allData || allData.length === 0">
            <td colspan="10">{{ $t("default.noData") }}</td>
          </tr>
          <tr v-for="list of allData" :key="list.id">
            <td><input type="checkbox" v-model="ids" :value="list.id"></td>
            <td><button class="normal-btn" :disabled="!getUserinfo.permissions.includes(100402)" @click="handleDialog({ list:list }, 'edit')">{{ $t("default.edit") }}</button></td>
            <td :class="{ 'red': list.isTop }">{{ list.title }}</td>
            <td>{{ agentList.find(data => data.id === list.agentId).domain }}</td>
            <td>{{ SEOGameList.find(data => data.id === list.seoBlogCategoryId).name }}</td>
            <td>{{ list.subPath }}</td>
            <td>{{ getStatusListAddDefault.find(data => data.id === list.status).name }}</td>
            <td>{{ list.updateTime }}</td>
            <td>{{ list.publishTime }}</td>
            <td><a :href="list.pageUrl" target="_blank">{{ $t(`${i18nPrefix}to`) }}</a></td>
          </tr>
          </tbody>
        </table>
      </div>
      <serverPagination
        v-if="allData"
        :page="page"
        @ServerPageUtils="search"
        @changeTake="changeTake"
      ></serverPagination>
    </div>
    <transition name="fade" mode="out-in">
      <component
          :agentList = "agentList"
          :SEOGameList = "SEOGameList"
          :is="currDialog"
          v-bind="dialogData"
          @toggle="currDialog = undefined"
          @create="create"
          @update="update"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Confirm
          v-if="getShowConfirm"
          @toggle="TOGGLE_CONFIRM"
          @delete="deleteSEOBlog"
      />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import { getAgentList } from "@/api/SEO/SEO";
import {
  getSEOBlogCategoryList,
  getSEOBlogList,
  createSEOBlog,
  editSEOBlog,
  deleteSEOBlog,
} from "@/api/SEO/SEO-blog";
import { SEOSort } from "@/utils/SEOFunction";
import { toggleStyle } from "@/utils/slideUtils";
import Message from "@/layout/components/Message";
import Confirm from "@/layout/components/Confirm";
import Create from "./dialog/create.vue";
import Edit from "./dialog/edit.vue";
import { mapGetters, mapMutations } from "vuex";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { joinT } from "@/utils/transDateUtils";

export default {
  name: "SEOBlogActivity",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      deleteData: undefined,
      agentList: [],
      SEOGameList: [],
      ids: [],
      checkAllBtn: false,
      createStatus: true,
      publishStateTime: undefined,
      publishEndTime: undefined,
      i18nPrefix: "SEO-Blog-Function.SEO-blogActivity."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    serverPagination,
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo","getStatusListAddDefault","getUserinfo"]),
  },
  watch: {
    ids() {
      this.checkAllBtn = (this.ids.length === this.allData.length) ? true : false;
    },
  },
  async created() {
    this.searchForm.type = this.GLOBAL.SEOBlogCategoryType.blogActivity;
    await getAgentList().then((res) => {
      this.agentList = res.data.agents;
    });
    await getSEOBlogCategoryList({ type: this.GLOBAL.SEOBlogCategoryType.blogActivity }).then(res => this.SEOGameList = res.data.categories);
    this.chooice = 'publishTime';
    this.search();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    toggleSlide: toggleStyle,
    search(page = false) {
      this.deleteData = undefined;
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
      this.searchForm.publishStateTime = this.publishStateTime ? joinT(this.publishStateTime + this.GLOBAL.startSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.publishEndTime = this.publishEndTime ? joinT(this.publishStateTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? (page - 1) : 0);
      getSEOBlogList(this.searchForm).then((res) => {
        this.allData = res.data.blogs;
        this.page = res.data.page;
        this.createStatus = false;
      });
    },
    create(data) {
      data.type = this.GLOBAL.SEOBlogCategoryType.blogActivity;
      createSEOBlog(data).then((res) => {
        if(res) this.search();
      });
    },
    update(data) {
      data.type = this.GLOBAL.SEOBlogCategoryType.blogActivity;
      editSEOBlog(data).then((res) => {
        if(res) this.search();
      });
    },
    handleDelete(data) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
    },
    deleteSEOBlog() {
      deleteSEOBlog({ ids: this.deleteData }).then(res => {
        if(res) this.search();
      })
    },
    checkAll() {
      this.ids = this.checkAllBtn ? [] : this.allData.map(data => data.id);
    }
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  margin-top: 5px;
}
.delete-btn {
  margin-top: 10px;
}
</style>