import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function discountList(data) {
    return request({
        url: "/DiscountSettingV2/List",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function getPlatformList(id = 0) {
    return request({
        url: `/DiscountSettingV2/SelectMenu?id=${id}`,
        method: "get",
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function discountCreate(data) {
    return request({
        url: "/DiscountSettingV2/Create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function discountUpdate(data) {
    return request({
        url: "/DiscountSettingV2/Update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}