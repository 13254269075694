var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "photo-wrap" }, [
              _c(
                "div",
                { staticClass: "label-wrap" },
                _vm._l(_vm.imageData, function (image) {
                  return _c(
                    "label",
                    {
                      staticClass: "photoDiv",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("choiceImage", image.url)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "photo",
                        attrs: { src: image.url, alt: image.name },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("h4", [_vm._v("選擇圖片")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }