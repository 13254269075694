<template>
  <div class="confirm-mask" @mousedown.self="$emit('toggle')">
    <div class="confirm-container">
      <main>
        <div>
          <span>{{ $t("default.delMsg") }}</span>
        </div>
        <div class="advance-block">
          <button class="normal-btn" @click="$emit('delete')">{{ $t("default.confirm") }}</button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
};
</script>

<style lang="scss" scoped>
.confirm-mask {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.384);
  z-index: 999;
  .confirm-container {
    background-color: #ffffff;
    border: 2px solid rgba(151, 151, 151, 0.562);
    border-radius: 15px;
    padding: 10px 20px;
    span {
      font-size: 30px;
    }
  }
}
</style>