<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}table.submitTime`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.orderNumber`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.paymentName`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.gateway`) }}</th>
        <th>{{ $t("default.amount") }}</th>
        <th>{{ $t("default.status") }}</th>
        <th>{{ $t("default.remark") }}</th>
        <th>{{ $t("default.admin") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ storeForm.memberAccount }}</td>
        <td>{{ storeForm.takeNumberTime }}</td>
        <td>{{ storeForm.orderNumber }}</td>
        <td>{{ storeForm.paymentName }}</td>
        <td>{{ storeForm.gateway }}</td>
        <td>{{ storeForm.amount }}</td>
        <td>{{ $t(storeForm.paymentStatus) }}</td>
        <td>{{ storeForm.remark }}</td>
        <td>{{ storeForm.auditAccount }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { storeModel } from "../model";
import { mapGetters } from "vuex";

export default {
  name: "storeForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      storeForm: _.cloneDeep(storeModel),
    };
  },
  watch: {
    detailsData() {
      this.storeForm = {
        id: this.detailsData.id,
        memberAccount: this.detailsData.memberAccount,
        takeNumberTime: this.detailsData.takeNumberTime,
        paymentTime: this.detailsData.paymentTime,
        paymentName: this.detailsData.paymentName,
        orderNumber: this.detailsData.orderNumber,
        gateway: this.$t("DepositTypeList." + this.detailsData.gateway),
        amount: this.digital.format(this.detailsData.amount),
        paymentStatus: this.getPaymentStatusList.find(
          (data) => data.id === this.detailsData.paymentStatus
        )?.name,
        remark: this.detailsData.remark,
        auditAccount: this.detailsData.auditAccount,
      };
    },
  },
  computed: {
    ...mapGetters(["getPaymentStatusList"]),
  },
};
</script>
