<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.edit") }}{{ $t(`title.gameInfo`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}gateway`) }}
              <input type="text" v-model="createModel.Name" />
            </label>
            <label>
              RedirectType
              <select v-model="createModel.RedirectType">
                <option v-for="list of redirectType" :key="list.id" :value="list.id * 1">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label class="w-100">
              <h5>Web Notice</h5>
              <template v-for="list of web">
                <label class="textarea-div">
                  {{ $t("LanguageType." + list.lang) }}
                  <textarea v-model="list.value"></textarea>
                </label>
              </template>
            </label>
            <label class="w-100">
              <h5>App Notice</h5>
              <template v-for="list of app">
                <label class="textarea-div">
                  {{ $t("LanguageType." + list.lang) }}
                  <textarea v-model="list.value"></textarea>
                </label>
              </template>
            </label>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('File')">
              <span v-if="!File">{{ $$t("default.clickToUpload") }}Image</span>
              <img v-else :src="File" />
            </div>
          </div>
          <input type="file" ref="file" class="custom-file" @change="fileSelect" />
          <div class="advance-block">
            <button class="normal-btn" @click="create">
              {{ $t("default.confirm") }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">
              {{ $t("default.cancel") }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  props: [
    "languageType",
    "redirectType",
    "i18nPrefix"
  ],
  data() {
    return {
      createModel: {
        Name: undefined,
        Notice: {},
        RedirectType: undefined,
        File: undefined
      },
      formData: new FormData(),
      File: undefined,
      chooiceImg: undefined,
      Notice: {},
      web: {},
      app: {},
    };
  },
  created() {
    this.createModel.RedirectType = this.redirectType[0]?.id;
    this.web = this.languageType.map(item => ({ lang: item.name, value: "" }));
    this.app = this.languageType.map(item => ({ lang: item.name, value: "" }));
  },
  methods: {
    create() {
      this.Notice.web = this.web.reduce((acc, current) => {
        acc[current.lang] = current.value;
        return acc;
      }, {});
      this.Notice.app = this.app.reduce((acc, current) => {
        acc[current.lang] = current.value;
        return acc;
      }, {});
      this.createModel.Notice = JSON.stringify(this.Notice);
      Object.entries(this.createModel).forEach(([key, value]) => {
        if (value !== "") this.formData.append(key, value);
      });
      this.$emit("create", this.formData);
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      this.formData.append(this.chooiceImg, e.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";

.dialog-container {
  width: 80% !important;

  .textarea-div {
    width: 90% !important;
    display: flex;
    flex-direction: column;
  }

  .imgDiv {
    display: flex;
    flex-wrap: wrap;

    .previewDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      height: 200px;
      padding: 0.5em;
      background-color: gray;
      border-radius: 10px;
      margin: 20px auto;

      span {
        display: block;
        font-size: 20px;
      }

      img {
        display: block;
        height: auto;
        width: auto;
        max-height: 95%;
        max-width: 95%;
      }
    }
  }
}
</style>