var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-container edit-member" },
        [
          _c("div", { staticClass: "dialog-block" }, [
            _c("div", { staticClass: "header" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("default.onlineMemberAmount")))]),
            ]),
            _c("div", { staticClass: "divider" }),
            _c("div", { staticClass: "container-view" }, [
              _c("div", { staticClass: "table-block" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.agent")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.star")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.tag")))]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.tableList
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "4" } }, [
                              _vm._v(_vm._s(_vm.$t("default.noData"))),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.tableList, function (list, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(list.memberAccount))]),
                          _c("td", [_vm._v(_vm._s(list.agentAccount))]),
                          _c("td", [
                            _vm._v(_vm._s(list.star + _vm.$t("default.star"))),
                          ]),
                          _c(
                            "td",
                            { class: { "tags-td": Array.isArray(list.tags) } },
                            [
                              list.tags
                                ? _c("Tags", {
                                    attrs: {
                                      tags: list.tags,
                                      tagList: _vm.tagList,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }