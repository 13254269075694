<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>選擇圖片</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="photo-wrap">
            <div class="label-wrap">
              <label class="photoDiv" v-for="image of imageData" @click="$emit('choiceImage', image.url)">
                <img class="photo" :src="image.url" :alt="image.name">
              </label>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

import {
  getMediaLibraryList,
} from "@/api/mediaLibrary/mediaLibraryImage";

export default {
  name: "imagePage",
  data() {
    return {
      imageData: [],
      topList: [],
    }
  },
  created() {
    getMediaLibraryList({}).then((res) => {
      this.imageData = res.data;
      this.topList = res.data.filter(data => data.isTop);
      this.imageData.sort((a,b) => new Date(b['updateTime']) - new Date(a['updateTime']));
      this.imageData = this.topList.concat(this.imageData);
    });
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 90% !important;
  .photo-wrap {
    border-radius: 10px;
    border-color: black;
    border-style: solid;
    margin-bottom: 10px;
    min-height: 80px;
    background-color: gray;

    .label-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .photoDiv {
        height: 200px;
        margin: 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
        position: relative;
        background-color: black;

        .photo {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>