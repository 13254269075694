const state = {
    showConfirm: false
}
const mutations = {
    TOGGLE_CONFIRM(state) {
        state.showConfirm = !state.showConfirm;
    },
    RESET_CONFIRM(state) {
        state.showConfirm = false;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
}