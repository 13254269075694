import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getKycList(data) {
    return request({
        url: "/Kyc/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditKyc(data) {
    return request({
        url: "/Kyc/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function getKycExcel(data) {
    return request({
        url: "/Kyc/export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function getKycProcess(data) {
    return request({
        url: "/Kyc/Process",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}