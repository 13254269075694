<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.agent") }}
            <select v-model="searchForm.agentId">
              <option v-for="list of getAgentListAddDefault" :key="list.id" :value="list.id">
                {{ $t(list.account) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="condition-block">
          <Multiselect v-model="transactionTypes" label="name" track-by="id" :placeholder="$t('default.plzSelect')"
            :custom-label="GLOBAL.i18nCheckName" :options="transactionTypeList" :multiple="true" :searchable="false">
          </multiselect>
          <div class="button-block">
            <button class="normal-btn-v2 main-style-btn" @click="transactionTypes = [...transactionTypeList]">{{ $t("default.selectAll") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="transactionTypes = []">{{ $t("default.clear") }}</button>
          </div>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}transactionType`) }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}preBalance`) }}</th>
              <th>{{ $t(`${i18nPrefix}afterBalance`) }}</th>
              <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
              <th>{{ $t("default.remark") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || allData.length === 0">
              <td colspan="8">{{ $t("default.noData") }}</td>
            </tr>
            <template v-for="(list, index) of allData">
              <tr :key="'tr' + index">
                <td class="plus" @click="
                  getDetail({
                    transactionType: list.transactionType,
                    id: list.actionDetailId,
                  }, index)
                  ">
                  {{ plus[index] ? "▼" : "▶" }}
                </td>
                <td>
                  {{
                    GLOBAL.i18nCheckName(transactionTypeList.find(
                      (data) => data.id === list.transactionType
                    ))
                  }}
                </td>
                <td>{{ list.memberAccount }}</td>
                <td :class="fontColor(list.amount)">{{ digital.format(list.amount) }}</td>
                <td>{{ digital.format(list.preBalance) }}</td>
                <td>{{ digital.format(list.afterBalance) }}</td>
                <td>
                  <router-link :to="{
                    path: '/report-bet-count/bet-record',
                    query: { startTime: list.submitTime, memberAccount: list.memberAccount }
                  }">
                    {{ list.submitTime }}
                  </router-link>
                </td>
                <td>{{ list.note }}</td>
              </tr>
              <tr :key="index" v-show="plus[index]">
                <td></td>
                <td colspan="7">
                  <component :is="
                        list.transactionType === 1 || 
                        list.transactionType === 2 ||
                        list.transactionType === 12 ||
                        list.transactionType === 18
                        ? 'withdrawalForm'
                        : list.transactionType === 16 ||
                          list.transactionType === 19
                        ? 'storeForm'
                        // : list.transactionType === 4 ||
                        //   list.transactionType === 5
                        // ? 'atmForm'
                        : list.transactionType === 6 ||
                          list.transactionType === 7 ||
                          list.transactionType === 11
                        ? 'quotaForm'
                        : list.transactionType === 8 ||
                          discountArray.includes(list.transactionType)
                        ? 'discountForm'
                        : list.transactionType === 9 ||
                          list.transactionType === 10
                        ? 'transferForm'
                        : list.transactionType === 13 ||
                          list.transactionType === 14
                        ? 'fixeddepositForm'
                        : ''
                    " :i18nPrefix="i18nPrefix" :digital="digital" :detailsData="detailsData"></component>
                </td>
              </tr>
            </template>
            <tr v-show="allData">
              <td colspan="2"></td>
              <td>{{ $t("default.total") }}</td>
              <td :class="fontColor(TotalAmount)">{{ digital.format(TotalAmount) }}</td>
              <td colspan="4"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
  </div>
</template>

<script>
import {
  getDetail,
  quotaHistoryList,
  getTransactionType,
} from "@/api/report/quota-history";
import { searchModel } from "./model";
import { transToday, joinT } from "@/utils/transDateUtils";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import { mapGetters } from "vuex";
import WithdrawalForm from "./table/WithdrawalForm";
import TransferForm from "./table/TransferForm";
import DiscountForm from "./table/DiscountForm";
import QuotaForm from "./table/QuotaForm";
import AtmForm from "./table/AtmForm";
import StoreForm from "./table/StoreForm";
import fixeddepositForm from "./table/FixedDepositForm";
import Multiselect from "vue-multiselect";


export default {
  name: "QuotaHistory",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      TotalAmount: 0,
      detailsData: [],
      discountArray: [17, 21, 22, 23, 25],
      transactionTypes: [],
      transactionTypeList: [],
      plus: [],
      i18nPrefix: "report.quota-history."
    };
  },
  watch: {
    allData() {
      this.plus = [];
    },
  },
  computed: {
    ...mapGetters(["getAgentListAddDefault"]),
  },
  mixins: [handleServerPageUtils],
  components: {
    StoreForm,
    AtmForm,
    QuotaForm,
    DiscountForm,
    TransferForm,
    WithdrawalForm,
    fixeddepositForm,
    serverPagination,
    QuickSearch,
    Multiselect,
  },
  async created() {
    await getTransactionType().then(res => {
      this.transactionTypeList = res.data.transactionType
        .map(data => { return { id: data.id, name: "TransactionType." + data.name } });
      this.transactionTypes = [...this.transactionTypeList.filter(list => list.id !== 9 && list.id !== 10)];
    });
  },
  methods: {
    search(page = false) {
      this.searchForm.submitStartTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.submitEndTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone) : undefined;
      this.searchForm.transactionTypes = this.transactionTypes.map(
        (data) => data.id * 1
      );

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      quotaHistoryList(this.searchForm).then((res) => {
        this.allData = res.data.records.list;
        this.TotalAmount = res.data.totalAmount;
        this.page = res.data.records.page;
      });
    },
    getDetail(getData, index) {
      let checkBool = this.plus[index];
      this.plus = [];
      this.detailsData = [];
      if (checkBool) return
      this.plus[index] = !this.plus[index];
      getDetail(getData).then((res) => {
        this.detailsData = getData.transactionType === 8 ? _.cloneDeep(res.data.list[0]) :
          this.discountArray.includes(getData.transactionType) ? _.cloneDeep(res.data) : _.cloneDeep(res.data[0]);
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
main .input-block label {
  width: 35% !important;
}

.detail {
  text-align: left;

  td {
    padding-left: 4%;
  }
}

.plus {
  cursor: pointer;
}
</style>