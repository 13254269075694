export let searchModel = {
    memberAccount: "",          // 會員帳號
    isFuzzySearch: false,       // 會員帳號模糊搜尋
    agentId: 0,                 // 代理
    isIncludeSubAgent: false,   // 是否包含下線代理
    dimension: 1,               // 搜尋類別 1. 遊戲種類 2. 遊戲館 3. 遊戲館 + 遊戲種類
    startTime: undefined,
    endTime: undefined
}

const i18nPrefix = 'statistical-report.gameStatisticalAnalysis.';
export let dimensionList = [
    {
        id: 1,
        name: `${i18nPrefix}dimensionList.1`
    },
    {
        id: 2,
        name: `${i18nPrefix}dimensionList.2`
    },
    {
        id: 3,
        name: `${i18nPrefix}dimensionList.3`
    }
]