import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function updateRecordList(data) {
    return request({
        url: "/MemberUpdateRecord/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function SelectMenu() {
    return request({
        url: "/MemberUpdateRecord/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}