import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getBankTypeList() {
    return request({
        url: `/BankTypeSetting/List`,
        method: "get",
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function createBankType(data) {
    return request({
        url: `/BankTypeSetting/create`,
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function deleteBankType(data) {
    return request({
        url: `/BankTypeSetting/delete`,
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
    });
}

// 無權限
export function bankTypeList() {
    return request({
        url: `/BankTypeSetting/BankList`,
        method: "get",
        headers: { "Authorization": `token ${getToken()}` },
    });
}