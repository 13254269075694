<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.account" />
          </label>
          <label>
            {{ $t("default.amount") }}
            <input type="text" v-model="searchForm.amount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="divider-h-2"></div>
        <!-- <div class="condition-block">
          <multiselect
              v-model="auditStatuses"
              track-by="id"
              label="name"
              :placeholder="$t('default.plzSelect')"
              :options="auditStatusList"
              :custom-label="GLOBAL.i18nCheckName"
              :multiple="true"
              :searchable="false"
          ></multiselect>
          <div class="button-block">
            <button @click="auditStatuses = [...getAuditStatusList]">{{ $t("default.selectAll") }}</button>
            <button @click="auditStatuses = []">{{ $t("default.clear") }}</button>
          </div>
        </div> -->
        <MultiSelectBox :selectData.sync="auditStatuses" :list="auditStatusList" :multiple="true" />
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>
                <button
                  @click="handleDialog(undefined, 'Audit')"
                  class="normal-btn"
                  :disabled="!getUserinfo.permissions.includes(20501)"
                >
                  {{ $t(`${i18nPrefix}allAudit`) }}
                </button>
              </th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t(`${i18nPrefix}submit`) }}</th>
              <th>{{ $t(`${i18nPrefix}orderNumber`) }}</th>
              <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}preTradeBalance`) }}</th>
              <th>{{ $t("default.agent") }}</th>
              <th>{{ $t("default.star") }}</th>
              <th>{{ $t("default.bankName") }}</th>
              <th>{{ $t("default.bankAccountNumber") }}</th>
              <th>{{ $t("default.bankAccountName") }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}paymentName`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="15">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list,index) of allData" :key="list.id">
              <td>
                <button
                  v-if="list.auditStatus === 1"
                  @click="handleDialog({id: list.id}, 'Audit')"
                  class="table-btn main-style-btn"
                  :disabled="!getUserinfo.permissions.includes(20501)"
                >
                  {{ $t("default.audit") }}
                </button>
                <button
                  v-if="list.auditStatus === 5"
                  @click="handleDialog({id: list.id , auditStatus: list.auditStatus}, 'Audit')"
                  class="table-btn main-style-btn"
                  :disabled="!getUserinfo.permissions.includes(20501)"
                >
                  {{ $t("default.edit") }}
                </button>
              </td>
              <td>{{ list.systemRemark }}</td>
              <td>
                {{
                  GLOBAL.i18nCheckName(
                  getAuditStatusList.find(
                    (data) => list.auditStatus === data.id
                  ))
                }}
              </td>
              <td>{{ list.orderNumber }}</td>
              <td>{{ list.createTime }}</td>
              <td>{{ digital.format(list.beforeBalance) }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  {{ list.agentAccount }}
                  <div class="table-icon mr-2" @click="copy(list.agentAccount, index)">
                    <img src="@/assets/icon/copy.svg">
                  </div>
                </div>
              </td>
              <td>{{ $t( "default." + (list.levelNumber ? `levelNumber` : "mainAgent") ,{ input: list.levelNumber } ) }}</td>
              <td>{{ list.bankName }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  {{ list.bankAccountNumber }}
                  <div class="table-icon mr-2" @click="copy(list.bankAccountNumber, index)">
                    <img src="@/assets/icon/copy.svg">
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center">
                  {{ list.bankAccount }}
                  <div class="table-icon mr-2" @click="copy(list.bankAccount, index)">
                    <img src="@/assets/icon/copy.svg">
                  </div>
                </div>
              </td>
              <td>{{ digital.format(list.amount) }}</td>
              <td>{{ list.updateUser }}</td>
              <td>{{ list.updateTime }}</td>
              <td>{{ list.paymentName }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <serverPagination
        v-if="allData"
        :page="page"
        @ServerPageUtils="search"
        @changeTake="changeTake"
      ></serverPagination>
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :agentWithdrawalInfoList="getAgentWithdrawalInfoList"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @auditDrawal="auditDrawal"
        @editDrawal="editDrawal"
      ></component>
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getList,
  getGateway,
  auditDrawal,
  editDrawal,
} from "@/api/agent-management/agent-withdraw";
import { mapGetters } from "vuex";
import Audit from "./dialog/audit";
import Message from "@/layout/components/Message";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import { joinT } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "AgentWithdraw",
  components: {
    Audit,
    serverPagination,
    QuickSearch,
    Multiselect,
    MultiSelectBox
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: undefined,
      endTime: undefined,
      controlType: true,
      copyText: "",
      auditStatuses: [],
      auditStatusList: [],
      i18nPrefix: "agent.agent-withdraw."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getAuditStatusList", "getUserinfo", "getAgentWithdrawalInfoList"]),
  },
  created() {
    this.auditStatusList = this.getAuditStatusList.filter( data => data.id !== 6)
    this.auditStatuses = [this.auditStatusList[0],this.auditStatusList[4]];
    this.search();
  },
  methods: {
    search(page = false) {
      if (this.checkOrderID()) return;
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.amount = this.searchForm.amount * 1;
      this.searchForm.auditStatuses = this.auditStatuses.map(
          (data) => data.id * 1
      );
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? (page - 1) : 0);
      getList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
        this.dataSort("submitTime", "time", true);
      });
    },
    checkOrderID() {
      if (this.$route.query?.orderId) {
        getList({ id: this.$route.query?.orderId * 1 }).then(res => {
          this.allData = res.data.list;
          this.page = res.data.page;
          this.currDialog = undefined;
        });
        return true;
      }
      return false;
    },
    auditDrawal(data) {
      auditDrawal(data).then( res => {
        if(res) this.init();
      })
    },
    editDrawal(data) {
      editDrawal(data).then( res => {
        if(res) this.init();
      })
    },
    init() {
      this.RESET_DIALOG();
      this.search();
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    async copy(data , index) {
      navigator.clipboard.writeText(data);
      this.copyText = await navigator.clipboard.readText() + 'Copy' + index;
      Message.copy(`${data} ` + this.$t("default.isCopy"), 1500);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.copyBtn {
  font-size: 1rem !important;
}
.copyBtn {
  transition: 0.5s;
  &:hover {
    color: #c700c7;
  }
}
.copyed {
  color: #c700c7;
}
</style>