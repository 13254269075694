import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getT9GamingBetLimitList(data) {
    return request({
        url: "/T9Gaming/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createT9GamingLimit(data) {
    return request({
        url: "/T9Gaming/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateT9GamingLimit(data) {
    return request({
        url: "/T9Gaming/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}