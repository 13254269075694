import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getList(data) {
    return request({
        url: "/AgentRebate/Record",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function Calculate(data) {
    return request({
        url: "/AgentRebate/Calculate",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCalcListdata(data) {
    return request({
        url: "/AgentRebate/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditRebate(data) {
    return request({
        url: "/AgentRebate/Audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}