import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function verificationCodeList() {
    return request({
        url: "/memberverificationcoderecord/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data: {}
    });
}