var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: { click: _vm.search },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c(
            "button",
            {
              staticClass: "create-btn main-style-btn",
              on: {
                click: function ($event) {
                  return _vm.handleDialog(undefined, "Create")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("default.create")))]
          ),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "advance-block" }, [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}domain`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.agentId,
                        expression: "searchForm.agentId",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchForm,
                          "agentId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 0 * 1 } }, [
                      _vm._v(_vm._s(_vm.$t("default.all"))),
                    ]),
                    _vm._l(_vm.agentList, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id * 1 } },
                        [_vm._v(" " + _vm._s(list.domain) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: { click: _vm.search },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "table-block" }, [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th"),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}name`)))]),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}page`)))]),
                _c("th", [_vm._v("SEO")]),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}domain`)))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}creator`)))]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createTime`))),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editor`)))]),
                _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editTime`)))]),
              ]),
            ]),
            _c(
              "tbody",
              [
                !_vm.tableList || _vm.tableList.length === 0
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "10" } }, [
                        _vm._v(_vm._s(_vm.$t("default.noData"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.tableList, function (list) {
                  return _c("tr", { key: list.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "delete-btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete({ id: list.id })
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.delete")))]
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.name))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.gamesCategoryList.find(
                            (data) => data.key === list.pageType
                          ).description
                        )
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(list, "EditSEO")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.edit")))]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(list, "Domain")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.edit")))]
                      ),
                    ]),
                    _c("td", [
                      _c("label", [
                        _c(
                          "div",
                          {
                            staticClass: "slide-block",
                            class: { "slide-block-on": list.status },
                            staticStyle: { margin: "auto" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "slide",
                                class: { "slide-on": list.status },
                                on: { click: _vm.toggleSlide },
                              },
                              [
                                _c("label", {
                                  staticStyle: { display: "none" },
                                  attrs: { for: "deleteStatus" },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.status,
                                      expression: "list.status",
                                    },
                                  ],
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "checkbox",
                                    id: "deleteStatus",
                                  },
                                  domProps: {
                                    checked: Array.isArray(list.status)
                                      ? _vm._i(list.status, null) > -1
                                      : list.status,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateStatus({
                                        id: list.id,
                                        status: !list.status,
                                      })
                                    },
                                    change: function ($event) {
                                      var $$a = list.status,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              list,
                                              "status",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              list,
                                              "status",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(list, "status", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.createUserAccount))]),
                    _c("td", [_vm._v(_vm._s(list.createTime))]),
                    _c("td", [_vm._v(_vm._s(list.updateUserAccount))]),
                    _c("td", [_vm._v(_vm._s(list.updateTime))]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm.totalCount > 0
        ? _c("Pagination", {
            attrs: { totalCount: _vm.totalCount },
            on: { handlePage: _vm.handlePage },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  gamesCategoryList: _vm.gamesCategoryList,
                  agentList: _vm.agentList,
                  pageList: _vm.allData,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  editSEO: _vm.updateMetaData,
                  createDomain: _vm.addDomains,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.getShowConfirm
            ? _c("Confirm", {
                on: { toggle: _vm.TOGGLE_CONFIRM, delete: _vm.deleteList },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }