<template>
  <div class="pagination-block" v-show="pageCount.length">
    <div class="count">{{ $t("pagination.total") }} {{ page.total }} {{ $t("pagination.records") }}</div>
    <ul>
      <li @click="$emit('ServerPageUtils' , 1)" :class="{ displayNone: page.current === 1 }">&laquo;&laquo;</li>
      <li @click="$emit('ServerPageUtils' , page.current - 1)" :class="{ displayNone: page.current === 1 }">&laquo;</li>
      <template>
        <li
            v-for="list of pageCount"
            :key="list"
            @click="$emit('ServerPageUtils' , list)"
            :class="{ active: page.current === list }"
        >
          {{ list }}
        </li>
      </template>
      <li @click="$emit('ServerPageUtils' , page.current + 1)" :class="{ displayNone: page.current === page.last }">&raquo;</li>
      <li @click="$emit('ServerPageUtils' , page.last)" :class="{ displayNone: page.current === page.last }">&raquo;&raquo;</li>
    </ul>
    <div class="page-down">
      <span>{{ $t("pagination.showRecordsPerPage") }}</span>
      <select v-model="take">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "serverPagination",
  props: ["page"],
  data() {
    return {
      take: 10,
      arr: [],
    };
  },
  created() {
    for (let i = 1; i <= this.count; i++) {
      this.arr.push(i);
    }
  },
  watch: {
    count() {
      this.arr = [];
      for (let i = 1; i <= this.count; i++) {
        this.arr.push(i);
      }
    },
    async take(val) {
      await this.$emit("changeTake" , val);
      this.$emit("ServerPageUtils" , 1);
    }
  },
  computed: {
    count() {
      let count = Math.ceil(this.page?.total / this.take);
      return count;
    },
    pageCount() {
      if (this.count > 11) {
        if (this.page.current <= 5) {
          return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        } else {
          let result = [];
          if (this.page.current + 5 <= this.count) {
            for (let i = 1; i <= 10; i++)  result.push(i + this.page.current - 5)
          } else if (this.page.current + 5 > this.count) {
            for (let i = this.count - 9; i <= this.count; i++)  result.push(i)
          }
          return result;
        }
      } else {
        return this.arr;
      }
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

.pagination-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .count {
    left: 2%;
    width: 110px;
    font-weight: 600;
    color: #000;
  }
  ul {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #3D4F59;
    li {
      cursor: pointer;
      padding: 0.2em 0.6em;
      margin-right: 5px;
      font-weight: 600;
      font-size: .8rem;
      transition: 0.2s;
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .page-down {
    span {
      font-weight: 600;
      color: #000;
      margin-right: 5px;
    }
    select {
      border-radius: 20px !important;
      padding: 0 5px;
      color: rgba(0, 0, 0, 0.50);
    }
  }
}

.active {
  background-color: #516875;
  color: #fff !important;
  border-radius: 8px;
}
.displayNone {
  visibility:hidden;
}

@media screen and (max-width: 500px) {
  .pagination-block {
    flex-wrap: wrap;
    align-items: unset;
    justify-content: center;
    margin-top: 10px;
    ul {
      // flex-wrap: wrap;
      margin-right: 0;
      width: 100%;
      li {
        flex-grow: 1;
      }
    }
    .count {
      width: 100%;
      text-align: end;
    }
  }
}
</style>