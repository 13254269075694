<template>
  <div class="dialog-mask2">
    <div ref="dialog" class="dialog-container">
      <div class="dialog-header">
        <h4>{{ $t(`default.${editData !== undefined ? 'edit' : 'create'}`) }}</h4>
        <div class="dialog-close" @click="$emit('toggle')">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="dialog-block">
        <main>
          <div class="input-block">
            <div class="divider-v2">
              <!-- 基本設定 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.basicSettings`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 名稱 -->
                <label>
                  {{ $t(`${i18nPrefix}campaignName`) }}
                  <input type="text" :placeholder="$t(`${i18nPrefix}campaignName`)" v-model="formModel.name" />
                  <span ref="rulesname" class="form-rules"></span>
                </label>
              </div>
              <div class="form-box">
              </div>
            </div>
            <div class="form-box-half form-column">
              <!-- 活動天數 -->
              <div class="form-box">
                <label>
                  <span>{{ $t('default.startTime') }}</span>
                  <date-picker v-model="startTime" format="YYYY-MM-DD" value-type="format" type="datetime"></date-picker>
                </label>
                <label>
                  <span>{{ $t(`${i18nPrefix}dialog.days`) }}</span>
                  <input type="number" min="1" v-model.number="days"
                    oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" />
                </label>
              </div>
              <div class="form-box">
                <button class="normal-btn calc w-100" @click="generateDateArray">{{ $t(`${i18nPrefix}dialog.generateDateArray`) }}</button>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.eligibility`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 新註冊會員 -->
                <label class="label-row" style="padding: 15px; width:50% !important;">
                  <input type="checkbox" v-model="ruleSetting.participateStatus.isNewMember" />
                  {{ $t(`${i18nPrefix}dialog.isNewMember`) }}
                </label>
                <label for=""></label>
              </div>
              <div class="form-box">
                <!-- 限定代理 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.includeAgents`) }}
                  <multiselect v-model="agentData" label="name" track-by="id" :placeholder="''" :options="agentList"
                    :multiple="true" :close-on-select="false">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 限定會員等級 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberLevel`) }}
                  <multiselect v-model="memberStar" label="name" track-by="star" :placeholder="''" :options="starList"
                    :multiple="true" :close-on-select="false"></multiselect>
                </label>
              </div>
              <div class="form-box">
                <!-- 限定會員標籤 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberTag`) }}
                  <multiselect v-model="memberTag" label="name" track-by="id" :placeholder="''" :options="tagList"
                    :multiple="true" :close-on-select="false">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
            </div>
            <!-- 獎勵設定 -->
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.rewardSetting`) }}</div>
            </div>
            <div class="form-box form-column">
              <div class="date-table w-100">
                <table>
                  <thead>
                    <tr>
                      <th rowspan="2">{{ $t(`${i18nPrefix}dialog.date`) }}</th>
                      <th colspan="4">{{ $t(`${i18nPrefix}dialog.taskSetting`) }}</th>
                      <th colspan="4">{{ $t(`${i18nPrefix}dialog.baseRewardSetting`) }}</th>
                      <th colspan="6">{{ $t(`${i18nPrefix}dialog.VIPRewardSetting`) }}</th>
                    </tr>
                    <tr>
                      <!-- 任務設定 -->
                      <th>{{ $t(`${i18nPrefix}dialog.taskType`) }}</th>
                      <th class="w-3words">{{ $t(`${i18nPrefix}dialog.taskGoal`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.gameCategory`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.gamePlatform`) }}</th>
                      <!-- 基礎獎勵設定 -->
                      <th>{{ $t(`${i18nPrefix}rewardType`) }}</th>
                      <th class="w-3words">{{ $t(`${i18nPrefix}rewardCount`) }}</th>
                      <th class="w-3words">{{ $t(`${i18nPrefix}wageringRate`) }}</th>
                      <th class="w-4words">{{ $t(`${i18nPrefix}campaignLuckyDrawListId`) }}</th>
                      <!-- VIP獎勵設定 -->
                      <th>{{ $t(`${i18nPrefix}dialog.VIP`) }}</th>
                      <th>{{ $t("default.star") }}</th>
                      <th>{{ $t(`${i18nPrefix}rewardType`) }}</th>
                      <th>{{ $t(`${i18nPrefix}rewardCount`) }}</th>
                      <th>{{ $t(`${i18nPrefix}wageringRate`) }}</th>
                      <th>{{ $t(`${i18nPrefix}campaignLuckyDrawListId`) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list of ruleSetting.rewardSetting.dates" :key="list.date">
                      <td>{{ list.date }}</td>
                      <!-- 任務設定 -->
                      <td>
                        <select v-model="list.taskSetting.taskType" :disabled="isCampaign">
                          <option v-for="list of taskType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                      <td><input type="number" min="0" v-model.number="list.taskSetting.taskGoal" class="w-3words"
                          :disabled="isCampaign || list.taskSetting.taskType == 0" /></td>
                      <td>
                        <select v-model="list.taskSetting.gameCategory"
                          :disabled="isCampaign || list.taskSetting.taskType == 0 || list.taskSetting.taskType == 1">
                          <option v-for="list of getGamesCategorysAddDefault" :key="list.id" :value="list.id">{{
                            $t(list.name) }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <select v-model="list.taskSetting.gamePlatform"
                          :disabled="isCampaign || list.taskSetting.taskType == 0 || list.taskSetting.taskType == 1 || list.taskSetting.gameCategory == 0">
                          <option v-for="list of getGamePlatformsAddDefault" :key="list.id" :value="list.id">{{
                            $t(list.name) }}
                          </option>
                        </select>
                      </td>
                      <!-- 基礎獎勵設定 -->
                      <td>
                        <select v-model="list.baseRewardSetting.rewardType">
                          <option v-for="list of rewardType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                      <td><input type="number" min="0" v-model.number="list.baseRewardSetting.rewardCount"
                          class="w-3words" /></td>
                      <td><input type="number" min="0" v-model.number="list.baseRewardSetting.wageringRate"
                          @keyup="list.baseRewardSetting.wageringRate = GLOBAL.checkValue(list.baseRewardSetting.wageringRate)"
                          :disabled="list.baseRewardSetting.rewardType == 2" class="w-3words" /></td>
                      <td>
                        <select class="w-3words" v-model="list.baseRewardSetting.campaignLuckyDrawListId"
                          :disabled="list.baseRewardSetting.rewardType == 1">
                          <option v-for="list of periodList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                      <!-- VIP獎勵設定 -->
                      <td><input type="checkbox" v-model="list.VIPRewardSetting.isEnabled" /></td>
                      <td>
                        <select v-model="list.VIPRewardSetting.memberLevel" :disabled="!list.VIPRewardSetting.isEnabled">
                          <option v-for="list of starList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                      <td>
                        <select v-model="list.VIPRewardSetting.rewardType" :disabled="!list.VIPRewardSetting.isEnabled">
                          <option v-for="list of rewardType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                      <td><input type="number" min="0" v-model.number="list.VIPRewardSetting.rewardCount"
                          :disabled="!list.VIPRewardSetting.isEnabled" class="w-3words" /></td>
                      <td><input type="number" min="0" v-model.number="list.VIPRewardSetting.wageringRate"
                          @keyup="list.VIPRewardSetting.wageringRate = GLOBAL.checkValue(list.VIPRewardSetting.wageringRate)"
                          :disabled="!list.VIPRewardSetting.isEnabled || list.VIPRewardSetting.rewardType == 2"
                          class="w-3words" /></td>
                      <td>
                        <select class="w-3words" v-model="list.VIPRewardSetting.campaignLuckyDrawListId"
                          :disabled="!list.VIPRewardSetting.isEnabled || list.VIPRewardSetting.rewardType == 1">
                          <option v-for="list of periodList" :key="list.id" :value="list.id">{{
                            $t(list.name) }}</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- 累積簽到獎勵 -->
            <div class="divider-v2-area" style="display: block">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.accumulatedSignInRewardSetting`) }}</div>
              <div class="table-block w-100">
                <div class="add-th">
                  <button class="add-btn" style="background-color: green;margin: 5px;"
                    @click="ruleSetting.accumulatedSignInRewardSetting.isEnabled ? addData('+') : ''">
                    <span>+</span>
                  </button>
                  <button class="add-btn"
                    @click="ruleSetting.accumulatedSignInRewardSetting.isEnabled ? addData('-') : ''">
                    <span>-</span>
                  </button>
                </div>
                <div class="days-row" style="padding: 15px; width:50% !important;">
                  <input type="checkbox" v-model="ruleSetting.accumulatedSignInRewardSetting.isEnabled" />
                  {{ $t(`${i18nPrefix}dialog.isEnabled`) }}
                </div>
                <!-- 獎品倍率 -->
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t(`${i18nPrefix}dialog.days`) }}</th>
                      <th>{{ $t(`${i18nPrefix}rewardType`) }}</th>
                      <th>{{ $t(`${i18nPrefix}rewardCount`) }}</th>
                      <th>{{ $t(`${i18nPrefix}wageringRate`) }}</th>
                      <th>{{ $t(`${i18nPrefix}campaignLuckyDrawListId`) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list of ruleSetting.accumulatedSignInRewardSetting.days">
                      <td>
                        <input type="number" v-model.number="list.day" @keyup="list.day = GLOBAL.checknull(list.day)"
                          :disabled="!ruleSetting.accumulatedSignInRewardSetting.isEnabled || isCampaign" />
                      </td>
                      <td>
                        <select v-model="list.rewardType"
                          :disabled="!ruleSetting.accumulatedSignInRewardSetting.isEnabled">
                          <option v-for="list of rewardType" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type="number" v-model.number="list.rewardCount"
                          @keyup="list.rewardCount = GLOBAL.checknull(list.rewardCount)"
                          :disabled="!ruleSetting.accumulatedSignInRewardSetting.isEnabled" />
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.wageringRate"
                          @keyup="list.wageringRate = GLOBAL.checknull(list.wageringRate)"
                          :disabled="!ruleSetting.accumulatedSignInRewardSetting.isEnabled" />
                      </td>
                      <td>
                        <select v-model="list.campaignLuckyDrawListId"
                          :disabled="!ruleSetting.accumulatedSignInRewardSetting.isEnabled">
                          <option v-for="list of periodList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="divider-v2">
              <!-- 前台 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.front`) }}</div>
            </div>
            <div class="imgDiv w-100">
              <div class="previewDiv" @click="upload('File')">
                <span v-if="!File">{{ $t('default.clickToUpload') }} Image</span>
                <img v-else :src="File" />
              </div>
              <div class="previewDiv" @click="upload('MobileFile')">
                <span v-if="!MobileFile">{{ $t('default.clickToUpload') }} MobileImage</span>
                <img v-else :src="MobileFile" />
              </div>
              <div class="previewDiv" @click="upload('AppFile')">
                <span v-if="!AppFile">{{ $t('default.clickToUpload') }} AppImage</span>
                <img v-else :src="AppFile" />
              </div>
            </div>
            <input type="file" ref="file" class="custom-file" @change="fileSelect" />
            <div class="form-box w-100">
              <label class="w-100">
                {{ $t(`${i18nPrefix}dialog.copywriting`) }}
                <ckeditor v-model="ruleSetting.html" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
              </label>
            </div>
          </div>
        </main>
      </div>
      <div class="advance-block-release" v-show="detail == undefined">
        <div class="m-auto">
          <button class="normal-btn" @click="submit()">{{ $t("default.confirm") }}</button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
        </div>
        <button class="btn-orange" v-show="isCampaign == undefined && editData !== undefined" @click="submit(true)">{{
          $t(`${i18nPrefix}release`) }}</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  createCampaignSetting,
  updateCampaignSetting,
  createMemberSignIn,
  updateMemberSignIn
} from "@/api/campaign/memberSignIn";
import { emptyFormModel, ruleSetting } from "./model";
import {
  transTodayDate,
  joinT
} from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "Edit",
  props: [
    "detail",
    "isCampaign",
    "editData",
    "starList",
    "tagList",
    "agentList",
    "taskType",
    "signInType",
    "rewardType",
    "periodList",
    "current",
    "i18nPrefix"
  ],
  components: {
    Multiselect
  },
  data() {
    return {
      emptyFormModel,
      days: 1,
      ruleSetting,
      isFrist: false, // 判斷是否是修改時欄位注入
      memberTag: [],
      memberStar: [],
      agentData: [],
      File: undefined,
      MobileFile: undefined,
      AppFile: undefined,
      chooiceImg: undefined,
      formModel: undefined,
      startTime: transTodayDate("from"),
      endTime: transTodayDate("to"),
      editModel: {},
      editModelTmp: {},
      formData: new FormData(),
      rulesError: []
    };
  },
  watch: {
    "formModel.name"(val) {
      this.formModel.name = val.trim();
    },
  },
  computed: {
    ...mapGetters([
      "getGamesCategorysAddDefault",
      "getGamePlatformsAddDefault",
      "getGameCategoryAndPlatform"
    ])
  },
  async created() {
    this.formModel = _.cloneDeep(this.emptyFormModel);

    this.generateDateArray();

    // 處理修改欄位
    this.processEditColumn();

    if (!this.isFrist) {
      this.ruleSetting.accumulatedSignInRewardSetting.days[0].rewardType = this.rewardType[0]?.id;
      this.ruleSetting.accumulatedSignInRewardSetting.days[0].campaignLuckyDrawListId = this.periodList[0]?.id;
    }

    setTimeout(() => {
      this.isFrist = false;
    }, 200);
  },
  methods: {
    /**
     * 處理修改欄位
     */
    processEditColumn() {
      if (this.editData !== undefined) {
        this.isFrist = true;

        const ruleSetting = JSON.parse(this.editData.ruleSetting);
        for (const [key, value] of Object.entries(this.formModel)) {
          if (this.editData[key] !== undefined) {
            this.formModel[key] = this.editData[key];
          }
        }
        this.startTime = this.timeForOrin(this.formModel.startTime);
        this.endTime = this.timeForOrin(this.formModel.endTime);
        this.days = Math.floor((new Date(this.formModel.endTime) - new Date(this.formModel.startTime)) / (1000 * 60 * 60 * 24));
        this.File = ruleSetting.imageUrl;
        this.MobileFile = ruleSetting.mobileImageUrl;
        this.AppFile = ruleSetting.appImageUrl;
        this.formModel.html = this.formModel.html ?? "";
        this.ruleSetting = ruleSetting;

        // 處理多選套件格式
        if (ruleSetting.participateStatus.includeTags.length > 0) {
          this.memberTag = this.tagList.filter(e => {
            return ruleSetting.participateStatus.includeTags.indexOf(e.id) > -1;
          });
        }
        if (ruleSetting.participateStatus.includeLevels.length > 0) {
          this.memberStar = this.starList.filter(e => {
            return (
              ruleSetting.participateStatus.includeLevels.indexOf(e.star) > -1
            );
          });
        }
        if (ruleSetting.participateStatus.includeAgents.length > 0) {
          this.agentData = this.agentList.filter(e => {
            return (
              ruleSetting.participateStatus.includeAgents.indexOf(e.id) > -1
            );
          });
        }
      }
    },
    /**
     * 送出
     */
    async submit(release = false) {
      if(this.formModel.name.trim() == "") {
        this.$refs["rulesname"].innerText = this.$t("default.checkInput", { input: this.$t(`${this.i18nPrefix}campaignName`)});
        return
      }
      this.formModel.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;

      let currentDate = new Date(this.startTime);
      currentDate.setDate(currentDate.getDate() + this.ruleSetting.rewardSetting.dates?.length);
      this.endTime = currentDate.toISOString().split('T')[0];

      this.formModel.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;

      // 填入 等級＆代理＆會員標籤
      this.ruleSetting.participateStatus.includeTags = [];
      this.ruleSetting.participateStatus.includeLevels = [];
      this.ruleSetting.participateStatus.includeAgents = [];
      if (this.memberTag.length > 0) {
        this.ruleSetting.participateStatus.includeTags = this.memberTag.map(
          e => {
            return e.id;
          }
        );
      }

      if (this.memberStar.length > 0) {
        this.ruleSetting.participateStatus.includeLevels = this.memberStar.map(
          e => {
            return e.star;
          }
        );
      }

      if (this.agentData.length > 0) {
        this.ruleSetting.participateStatus.includeAgents = this.agentData.map(
          e => {
            return e.id;
          }
        );
      }
      this.formModel.ruleSetting = JSON.stringify(this.ruleSetting);

      Object.entries(this.formModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });

      let result = {};
      if (release) {
        await createMemberSignIn(this.formData).then(res => {
          result = res;
        });
      } else if (this.editData === undefined) {
        await createCampaignSetting(this.formData).then(res => {
          result = res;
        });
      } else if (this.isCampaign) {
        await updateMemberSignIn(this.formData).then(res => {
          result = res;
        });
      } else {
        await updateCampaignSetting(this.formData).then(res => {
          result = res;
        });
      }

      if (result) {
        this.$emit("toggle");
        this.$emit("search", this.current);
      }

      return;
    },
    /**
     * 圖片處理
     */
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    },
    addData(type) {
      let getLast = this.ruleSetting.accumulatedSignInRewardSetting.days.slice(-1)[0]
      let obj = [
        {
          day: getLast.day + 1,
          rewardType: this.rewardType[0]?.id,
          rewardCount: 1,
          wageringRate: 1,
          campaignLuckyDrawListId: this.periodList[0]?.id
        },
      ];

      let rebateRatesList = [...this.ruleSetting.accumulatedSignInRewardSetting.days];
      if (
        type === "+" &&
        this.ruleSetting.accumulatedSignInRewardSetting.days.length !== 5
      ) {
        this.ruleSetting.accumulatedSignInRewardSetting.days = rebateRatesList.concat(obj);
      } else if (
        type === "-" &&
        this.ruleSetting.accumulatedSignInRewardSetting.days.length !== 1
      ) {
        this.ruleSetting.accumulatedSignInRewardSetting.days.pop();
      } else {
        return
      }
    },
    timeForOrin(date) {
      date = date.split("");
      date.splice(10);
      return date.join("");
    },
    generateDateArray() {
      // 將現有陣列轉換為以日期為 key 的物件，以方便查找
      const existingDates = {};
      this.ruleSetting.rewardSetting.dates.forEach(item => {
        existingDates[item.date] = item;
      });

      const currentDate = new Date(this.startTime);
      const endDate = new Date(this.startTime);
      endDate.setDate(endDate.getDate() + this.days - 1);

      // 新增或更新日期
      while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        if (!existingDates[formattedDate]) {
          // 新增新日期
          this.ruleSetting.rewardSetting.dates.push({
            "date": formattedDate,
            "taskSetting": {
              "taskType": this.taskType[0]?.id,
              "taskGoal": 100,
              "gameCategory": this.getGamesCategorysAddDefault[0]?.id,
              "gamePlatform": this.getGamePlatformsAddDefault[0]?.id
            },
            "baseRewardSetting": {
              "rewardType": this.rewardType[0]?.id,
              "rewardCount": 1,
              "wageringRate": 1,
              "campaignLuckyDrawListId": this.periodList[0]?.id
            },
            "VIPRewardSetting": {
              "isEnabled": false,
              "memberLevel": this.starList[0]?.id,
              "rewardType": this.rewardType[0]?.id,
              "rewardCount": 1,
              "wageringRate": 1,
              "campaignLuckyDrawListId": this.periodList[0]?.id
            }
          });
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      // 刪除不在新範圍內的資料
      this.ruleSetting.rewardSetting.dates = this.ruleSetting.rewardSetting.dates.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= new Date(this.startTime) && itemDate <= endDate;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.w-3words {
  max-width: 80px;
}

.days-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.date-table {
  overflow: auto;

  table {
    width: max-content;
    min-width: 100%;
    text-align: center;
    border: 0;

    tr {
      th {
        border: 2px solid #a0a0a0;
      }

      td {
        white-space: pre;
        border: 1px solid #a0a0a0;
      }

      th,
      td {
        padding: 5px 5px;
      }
    }

    tbody tr:hover {
      background: #eef9ff;
    }
  }
}

.imgDiv {
  display: flex;

  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 200px;
    padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;

    span {
      display: block;
      font-size: 20px;
    }

    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
  }
}

.custom-file {
  position: absolute;
  visibility: hidden;
}
</style>