<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}gameOrderNumber`) }}
            <input type="text" v-model="searchForm.gameOrderNumber" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}gamePlatform`) }}
            <select v-model="searchForm.gamePlatform">
              <option v-for="list of getGamePlatformsAddDefault" :key="'gamePlatform' + list.id" :value="list.id">{{
                $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}gamesCategory`) }}
            <select v-model="searchForm.gamesCategory">
              <option v-for="list of getGamesCategorysAddDefault" :key="'gamesCategory' + list.id" :value="list.id">{{
                $t(list.name) }}
              </option>
            </select>
          </label>
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.agent") }}</span>
              <input class="pc-block" id="isIncludeSubAgents" type="checkbox" v-model="searchForm.isIncludeSubAgents" />
              <label class="pc-block" for="isIncludeSubAgents">{{ $t(`${i18nReportPrefix}includeSubAgents`) }}</label>
            </div>
            <multiselect v-model="agentData" label="name" track-by="id" :options="getAgentListAddDefault"
              :allow-empty="false" :preselectFirst="true" :clear-on-select="false" :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"></multiselect>
          </label>
          <div class="mobile-block">
            <div class="input-block-check">
              <input id="isIncludeSubAgents" type="checkbox" v-model="searchForm.isIncludeSubAgents" />
              <label for="isIncludeSubAgents">{{ $t(`${i18nReportPrefix}includeSubAgents`) }}</label>
            </div>
          </div>
          <label>
            {{ $t(`${i18nReportPrefix}searchTime`) }}
            <select v-model="searchTime">
              <option value="bet">{{ $t(`${i18nReportPrefix}betTime`) }}</option>
              <option value="settle">{{ $t(`${i18nReportPrefix}settleTime`) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm:ss" value-type="format" type="datetime"
              :disabled-date="GLOBAL.ontSeason"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm:ss" value-type="format" type="datetime"
              :disabled-date="GLOBAL.ontSeason"></date-picker>
          </label>
          <label>
            {{ $t("default.multiples") }}
            <select v-model="searchForm.multiples">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of multiplesList" :key="'multiples' + list" :value="list">{{ list }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch ref="quickSearch" @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2 row-4">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nReportPrefix}betCount`) }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.betCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nReportPrefix}betTotalAmount`) }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.betAmount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nReportPrefix}validBetAmount`) }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.validBetAmount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nReportPrefix}playerWinLose`) }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.winLose) }}</div>
        </div>
      </div>
      <div class="table-block">
        <TreeTable ref="treeTable" :showColumnCheckbox="true" :list="allData" :column="tableColumn" @sort="dataSort">
          <template v-slot:column_gameOrderNumber="props">{{ props.gameOrderNumber }}</template>
          <template v-slot:column_agentId="props">{{ $t(getAgentListAddDefault.find((data) => data.id ===
            props.agentId).account) }}</template>
          <template v-slot:column_gamePlatform="props">{{ GLOBAL.i18nCheckName(getGamePlatformsAddDefault.find((data) =>
            data.id === props.gamePlatform)) }}</template>
          <template v-slot:column_gamesCategory="props">
            {{
              GLOBAL.i18nCheckName(
                getGamesCategorysAddDefault.find(
                  (data) => props.gamesCategory === data.id
                )
              )
            }}
          </template>
          <template v-slot:column_winLose="props">
            <span :class="props.winLose < 0 ? 'red' : 'green'">{{ digital.format(props.winLose) }}</span>
          </template>
          <template v-slot:column_betType="props">{{ props.betType === 2 ? $t('default.cancel') :
            $t(`${i18nReportPrefix}${props.betType ? 'isSettled' : 'unSettle'}`) }}</template>
          <template v-slot:column_detail="props">
            <button class="edit-btn" @click="handleDetail(props)">{{ $t('default.detail') }}</button>
          </template>
        </TreeTable>
        <serverPagination v-if="allData" :page="page" @ServerPageUtils="searchPage" @changeTake="changeTake">
        </serverPagination>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" :i18nReportPrefix="i18nReportPrefix" v-bind="dialogData"
        @toggle="currDialog = undefined"></component>
    </transition>
  </div>
</template>

<script>
import {
  memberBetRecordListV2,
  memberBetRecordTotalV2,
  getExcel,
  selectMenu
} from "@/api/report-bet-count/bet-record";
import { searchModel } from "./model";
import { transToday, joinT } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
import Message from "@/layout/components/Message";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import TreeTable from "@/layout/components/TreeTable";
import Detail from "./dialog/detail.vue";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";

export default {
  name: "BetReport",
  data() {
    return {
      searchTime: "bet",
      agentData: [],
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from") + this.GLOBAL.startSecond,
      endTime: transToday("to") + this.GLOBAL.endSecond,
      multiplesList: [],
      i18nPrefix: "report-bet-count.bet-record.",
      i18nReportPrefix: "report-bet-count.bet-report.",
      digital: new Intl.NumberFormat(),
      fakerMember: [],
      total: {
        betCount: 0,
        betAmount: 0,
        validBetAmount: 0,
        winLose: 0
      }
    };
  },
  watch: {},
  components: {
    QuickSearch,
    serverPagination,
    TreeTable,
    Detail,
    Multiselect
  },
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "getGamePlatformsAddDefault",
      "getGamesCategorysAddDefault"
    ]),
    tableColumn() {
      return [
        {
          key: "detail",
          name: "",
          width: 80
        },
        {
          key: "gameOrderNumber",
          name: this.$t(`${this.i18nPrefix}gameOrderNumber`)
        },
        {
          key: "memberAccount",
          name: this.$t("default.member"),
          isText: true
        },
        {
          key: "agentId",
          name: this.$t("default.agent")
        },
        {
          key: "gamePlatform",
          name: this.$t(`${this.i18nPrefix}gamePlatform`)
        },
        {
          key: "gamesCategory",
          name: this.$t(`${this.i18nPrefix}gamesCategory`)
        },
        {
          key: "multiples",
          name: this.$t("default.multiples")
        },
        {
          key: "betAmount",
          name: this.$t(`${this.i18nPrefix}betAmount`)
        },
        {
          key: "validBetAmount",
          name: this.$t(`${this.i18nPrefix}validBetAmount`)
        },
        {
          key: "winLose",
          name: this.$t(`${this.i18nPrefix}winLose`)
        },
        {
          key: "betType",
          name: this.$t("default.status")
        },
        {
          key: "betTime",
          name: this.$t(`${this.i18nReportPrefix}betDate`)
        },
        {
          key: "tableId",
          name: this.$t(`${this.i18nReportPrefix}tableId`),
          isShow: false
        },
        {
          key: "roundId",
          name: this.$t(`${this.i18nReportPrefix}roundId`),
          isShow: false
        },
        {
          key: "betInfo",
          name: this.$t("default.detail"),
          isShow: false
        },
        {
          key: "settleTime",
          name: this.$t(`${this.i18nReportPrefix}settleDate`)
        }
      ];
    }
  },
  mixins: [handleServerPageUtils, handleDialog],
  created() {
    // this.$nextTick(function() {
    //   setTimeout(()=> {
    //     this.$refs.quickSearch.changeDate('today');
    //   }, 200);
    // });
    selectMenu().then(res => { this.multiplesList = res.data })
  },
  methods: {
    async search() {
      if (
        (this.searchForm.memberAccount || "").trim().length === 0 &&
        (this.searchForm.gameOrderNumber || "").trim().length === 0 &&
        this.searchForm.gamesCategory === 0 &&
        this.searchForm.gamePlatform === 0
      ) {
        if (
          Math.floor(
            (new Date(this.endTime) - new Date(this.startTime)) /
            (24 * 3600 * 1000)
          ) > 6
        ) {
          Message.error(this.$t(`${this.i18nReportPrefix}errMsg`), 1000);
          return;
        }
        // if (
        //   (this.searchForm.memberAccount || "").trim().length === 0 &&
        //   (this.searchForm.gameOrderNumber || "").trim().length === 0
        // ) {
        //   Message.error(
        //     this.$t("default.checkInput", {
        //       input:
        //         this.$t("default.member") +
        //         "/" +
        //         this.$t(`${this.i18nPrefix}gameOrderNumber`)
        //     }),
        //     1000
        //   );
        //   return;
        // }
      }

      //時間參數
      if (this.searchTime === "bet") {
        delete this.searchForm.settleStartTime;
        delete this.searchForm.settleEndTime;
        this.searchForm.betStartTime = this.startTime
          ? joinT(this.startTime + this.GLOBAL.timeZone)
          : undefined;
        this.searchForm.betEndTime = this.endTime
          ? joinT(this.endTime + this.GLOBAL.timeZone)
          : undefined;
      } else if (this.searchTime === "settle") {
        delete this.searchForm.betStartTime;
        delete this.searchForm.betEndTime;
        this.searchForm.settleStartTime = this.startTime
          ? joinT(this.startTime + this.GLOBAL.timeZone)
          : undefined;
        this.searchForm.settleEndTime = this.endTime
          ? joinT(this.endTime + this.GLOBAL.timeZone)
          : undefined;
      }

      this.searchForm.gamePlatform =
        this.searchForm.gamePlatform === "0"
          ? 0 * 1
          : this.searchForm.gamePlatform;

      this.searchForm.agentId = this.agentData.id;
      await this.searchPage();

      await memberBetRecordTotalV2(this.searchForm).then(res => {
        this.total = {
          betCount: res.data.totalRounds,
          betAmount: res.data.totalBetAmount,
          validBetAmount: res.data.totalValidBetAmount,
          winLose: res.data.totalWinLose
        };
      });
    },
    searchPage(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      memberBetRecordListV2(this.searchForm).then(res => {
        this.allData = res.data?.records;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime + this.GLOBAL.startSecond;
      this.endTime = time.endTime + this.GLOBAL.endSecond;
      this.search();
    },
    handleDetail(data) {
      let tmp = _.cloneDeep(data);
      tmp.agentId = this.$t(
        this.getAgentListAddDefault.find(data => data.id === tmp.agentId)
          .account
      );
      tmp.gamePlatform = this.GLOBAL.i18nCheckName(
        this.getGamePlatformsAddDefault.find(
          data => data.id === tmp.gamePlatform
        )
      );
      tmp.gamesCategory = this.GLOBAL.i18nCheckName(
        this.getGamesCategorysAddDefault.find(
          data => tmp.gamesCategory === data.id
        )
      );
      tmp.betAmount = this.digital.format(tmp.betAmount);
      tmp.validBetAmount = this.digital.format(tmp.validBetAmount);
      tmp.winLose = this.digital.format(tmp.winLose);
      tmp.betType =
        tmp.betType === 2
          ? this.$t("default.cancel")
          : this.$t(
            `${this.i18nReportPrefix}${tmp.betType ? "isSettled" : "unSettle"
            }`
          );

      this.handleDialog({ data: tmp }, "Detail");
    },
    download() {
      getExcel(this.searchForm).then(res =>
        download(res, this.startTime + "BetReport.xlsx")
      );
    }
  }
};
</script>
