<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.agent") }}</span>
            </div>
            <multiselect
              v-model="agentData"
              label="name"
              track-by="id"
              :options="getAgentListAddDefault"
              :allow-empty="false"
              :preselectFirst="true"
              :clear-on-select="false"
              :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"
            ></multiselect>
          </label>
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}discountType`) }}
            <select v-model="searchForm.discountTypeId">
              <option
                v-for="list of getDiscountTypeListAddDefault"
                :key="list.id"
                :value="list.id"
              >{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}discountName`) }}
            <input type="text" v-model="searchForm.discountName" />
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch ref="quickSearch" @changeTime="changeTime" :controlType="true"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}discountCount`) }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.discountCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t(`${i18nPrefix}discountAmount`) }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.discountAmount) }}</div>
        </div>
      </div>
      <div class="table-block">
        <TreeTable
          ref="treeTable"
          :list="allData"
          :column="tableColumn"
          :bookmarkColumn="bookmarkColumn"
          :notShowChildren="true"
          @sort="dataSort"
          @bookmark="bookmark"
        >
          <template v-slot:column_name="props">
            {{ searchForm.type != 3 ? searchForm.type != 1 ? props.name
            : `${props.name} (${GLOBAL.i18nCheckName(getAgentListAddDefault.find( data => data.account == props.name))})`
            : GLOBAL.i18nCheckName(getDiscountTypeListAddDefault.find( data => data.id == props.name))}}
          </template>
          <template v-slot:column_action="prop">
            <button class="normal-btn" @click="bookmark(2 , prop.id)">{{ $t("default.member") }}</button>
            <button
              class="normal-btn ml-2"
              @click="bookmark(3 , prop.id)"
            >{{ $t(`${i18nPrefix}discount`) }}</button>
          </template>
        </TreeTable>
        <!-- <serverPagination
          v-if="allData"
          :page="page"
          @ServerPageUtils="searchPage"
          @changeTake="changeTake"
        ></serverPagination> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getList,
  getTotal,
  getExcel
} from "@/api/statistical-report/agentDiscountStatistical";
import { searchModel } from "./model";
import { transTodayDate, joinT, transNow } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";
// import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import TreeTable from "@/layout/components/TreeTable";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";

export default {
  name: "AgentDiscountStatistical",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate(),
      endTime: transTodayDate(),
      agentData: [],
      i18nPrefix: "statistical-report.agentDiscountStatistical.",
      total: {
        discountCount: 0,
        discountAmount: 0
      }
    };
  },
  watch: {},
  components: {
    QuickSearch,
    // serverPagination,
    TreeTable,
    Multiselect
  },
  computed: {
    ...mapGetters(["getAgentListAddDefault", "getDiscountTypeListAddDefault"]),
    tableColumn() {
      switch (this.searchForm.type) {
        case 1:
          return [
            {
              key: "name",
              name: this.$t("default.agent"),
              tableHeadAlign: true,
              align: "left"
            },
            {
              key: "totalDiscountCount",
              name: this.$t(`${this.i18nPrefix}totalDiscountCount`)
            },
            {
              key: "totalDiscountAmount",
              name: this.$t(`${this.i18nPrefix}totalDiscountAmount`)
            },
            {
              key: "selfDiscountCount",
              name: this.$t(`${this.i18nPrefix}selfDiscountCount`)
            },
            {
              key: "selfDiscountAmount",
              name: this.$t(`${this.i18nPrefix}selfDiscountAmount`)
            },
            {
              key: "action",
              name: ""
            }
          ];
        case 2:
          return [
            {
              key: "name",
              name: this.$t("default.member")
            },
            {
              key: "totalDiscountCount",
              name: this.$t(`${this.i18nPrefix}totalDiscountCount`)
            },
            {
              key: "totalDiscountAmount",
              name: this.$t(`${this.i18nPrefix}totalDiscountAmount`)
            },
            {
              key: "selfDiscountCount",
              name: this.$t(`${this.i18nPrefix}selfDiscountCount`)
            },
            {
              key: "selfDiscountAmount",
              name: this.$t(`${this.i18nPrefix}selfDiscountAmount`)
            }
          ];
        case 3:
          return [
            {
              key: "name",
              name: this.$t(`${this.i18nPrefix}discountType`)
            },
            {
              key: "selfDiscountCount",
              name: this.$t(`${this.i18nPrefix}selfDiscountCount`)
            },
            {
              key: "selfDiscountAmount",
              name: this.$t(`${this.i18nPrefix}selfDiscountAmount`)
            }
          ];
      }
    },
    bookmarkColumn() {
      return [
        {
          key: this.searchForm.type,
          name: "bookmark"
        },
        {
          key: 1,
          name: this.$t("default.agent")
        },
        {
          key: 2,
          name: this.$t("default.member")
        },
        {
          key: 3,
          name: this.$t(`${this.i18nPrefix}discount`)
        }
      ];
    }
  },
  mixins: [handleServerPageUtils],
  created() {
    // this.$nextTick(function() {
    //   setTimeout(() => {
    //     getList(this.searchForm).then(res => {
    //       console.log(res);
    //     });
    //   }, 200);
    // });
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      this.searchPage();

      getTotal(this.searchForm).then(res => {
        this.total = {
          discountCount: res.data.totalDiscountCount,
          discountAmount: res.data.totalDiscountAmount
        };
      });
    },
    searchPage(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);

      getList(this.searchForm).then(res => {
        this.allData = res.data?.list;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    bookmark(data, agentId = false) {
      if (agentId) this.searchForm.agentId = agentId;
      this.allData = undefined;
      this.searchForm.type = data;
      this.search();
    },
    download() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      let body = {...this.searchForm};
      body.page.take = 0;
      body.page.skip = 0;

      getExcel(body).then(res =>
        download(res, transNow('onlyNum') + "-AgentDiscountStatistical.xlsx")
      );
    }
  }
};
</script>
