var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: {
              click: function ($event) {
                return _vm.search()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "download-btn main-style-btn",
                on: { click: _vm.download },
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/download.svg") },
                }),
                _vm._v(" " + _vm._s(_vm.$t("default.download")) + " "),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.memberAccount,
                    expression: "searchForm.memberAccount",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchForm.memberAccount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchForm,
                      "memberAccount",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gameOrderNumber`)) + " "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.gameOrderNumber,
                    expression: "searchForm.gameOrderNumber",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchForm.gameOrderNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchForm,
                      "gameOrderNumber",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`)) + " "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.gamePlatform,
                      expression: "searchForm.gamePlatform",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "gamePlatform",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.getGamePlatformsAddDefault, function (list) {
                  return _c(
                    "option",
                    {
                      key: "gamePlatform" + list.id,
                      domProps: { value: list.id },
                    },
                    [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamesCategory`)) + " "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.gamesCategory,
                      expression: "searchForm.gamesCategory",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "gamesCategory",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.getGamesCategorysAddDefault, function (list) {
                  return _c(
                    "option",
                    {
                      key: "gamesCategory" + list.id,
                      domProps: { value: list.id },
                    },
                    [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c(
              "label",
              { staticClass: "input-block-multiselect" },
              [
                _c("div", { staticClass: "input-block-check" }, [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(_vm._s(_vm.$t("default.agent"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchForm.isIncludeSubAgents,
                        expression: "searchForm.isIncludeSubAgents",
                      },
                    ],
                    staticClass: "pc-block",
                    attrs: { id: "isIncludeSubAgents", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.searchForm.isIncludeSubAgents)
                        ? _vm._i(_vm.searchForm.isIncludeSubAgents, null) > -1
                        : _vm.searchForm.isIncludeSubAgents,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.searchForm.isIncludeSubAgents,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchForm,
                                "isIncludeSubAgents",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchForm,
                                "isIncludeSubAgents",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.searchForm, "isIncludeSubAgents", $$c)
                        }
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "pc-block",
                      attrs: { for: "isIncludeSubAgents" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(`${_vm.i18nReportPrefix}includeSubAgents`)
                        )
                      ),
                    ]
                  ),
                ]),
                _c("multiselect", {
                  attrs: {
                    label: "name",
                    "track-by": "id",
                    options: _vm.getAgentListAddDefault,
                    "allow-empty": false,
                    preselectFirst: true,
                    "clear-on-select": false,
                    "preserve-search": true,
                    "custom-label": _vm.GLOBAL.nameWithLang,
                  },
                  model: {
                    value: _vm.agentData,
                    callback: function ($$v) {
                      _vm.agentData = $$v
                    },
                    expression: "agentData",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "mobile-block" }, [
              _c("div", { staticClass: "input-block-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.isIncludeSubAgents,
                      expression: "searchForm.isIncludeSubAgents",
                    },
                  ],
                  attrs: { id: "isIncludeSubAgents", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.searchForm.isIncludeSubAgents)
                      ? _vm._i(_vm.searchForm.isIncludeSubAgents, null) > -1
                      : _vm.searchForm.isIncludeSubAgents,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.searchForm.isIncludeSubAgents,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.searchForm,
                              "isIncludeSubAgents",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.searchForm,
                              "isIncludeSubAgents",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.searchForm, "isIncludeSubAgents", $$c)
                      }
                    },
                  },
                }),
                _c("label", { attrs: { for: "isIncludeSubAgents" } }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nReportPrefix}includeSubAgents`))
                  ),
                ]),
              ]),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nReportPrefix}searchTime`)) + " "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchTime,
                      expression: "searchTime",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.searchTime = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "bet" } }, [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nReportPrefix}betTime`))),
                  ]),
                  _c("option", { attrs: { value: "settle" } }, [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nReportPrefix}settleTime`))),
                  ]),
                ]
              ),
            ]),
            _c(
              "label",
              [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}startTime`)) + " "
                ),
                _c("date-picker", {
                  attrs: {
                    format: "YYYY-MM-DD HH:mm:ss",
                    "value-type": "format",
                    type: "datetime",
                    "disabled-date": _vm.GLOBAL.ontSeason,
                  },
                  model: {
                    value: _vm.startTime,
                    callback: function ($$v) {
                      _vm.startTime = $$v
                    },
                    expression: "startTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "label",
              [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`)) + " "),
                _c("date-picker", {
                  attrs: {
                    format: "YYYY-MM-DD HH:mm:ss",
                    "value-type": "format",
                    type: "datetime",
                    "disabled-date": _vm.GLOBAL.ontSeason,
                  },
                  model: {
                    value: _vm.endTime,
                    callback: function ($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime",
                  },
                }),
              ],
              1
            ),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.multiples")) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.multiples,
                      expression: "searchForm.multiples",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "multiples",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 * 1 } }, [
                    _vm._v(_vm._s(_vm.$t("default.all"))),
                  ]),
                  _vm._l(_vm.multiplesList, function (list) {
                    return _c(
                      "option",
                      { key: "multiples" + list, domProps: { value: list } },
                      [_vm._v(_vm._s(list) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "advance-block" },
            [
              _c("QuickSearch", {
                ref: "quickSearch",
                on: { changeTime: _vm.changeTime },
              }),
              _c(
                "button",
                {
                  staticClass: "search-pc-btn main-style-btn px-5",
                  on: { click: _vm.search },
                },
                [_vm._v(_vm._s(_vm.$t("default.search")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "total-block-v2 row-4" }, [
          _c("div", { staticClass: "total-block-v2-item" }, [
            _c("div", { staticClass: "total-block-v2-item-left" }, [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nReportPrefix}betCount`)) + ":"),
            ]),
            _c("div", { staticClass: "total-block-v2-item-right" }, [
              _vm._v(_vm._s(_vm.digital.format(_vm.total.betCount))),
            ]),
          ]),
          _c("div", { staticClass: "total-block-v2-item" }, [
            _c("div", { staticClass: "total-block-v2-item-left" }, [
              _vm._v(
                _vm._s(_vm.$t(`${_vm.i18nReportPrefix}betTotalAmount`)) + ":"
              ),
            ]),
            _c("div", { staticClass: "total-block-v2-item-right" }, [
              _vm._v(_vm._s(_vm.digital.format(_vm.total.betAmount))),
            ]),
          ]),
          _c("div", { staticClass: "total-block-v2-item" }, [
            _c("div", { staticClass: "total-block-v2-item-left" }, [
              _vm._v(
                _vm._s(_vm.$t(`${_vm.i18nReportPrefix}validBetAmount`)) + ":"
              ),
            ]),
            _c("div", { staticClass: "total-block-v2-item-right" }, [
              _vm._v(_vm._s(_vm.digital.format(_vm.total.validBetAmount))),
            ]),
          ]),
          _c("div", { staticClass: "total-block-v2-item" }, [
            _c("div", { staticClass: "total-block-v2-item-left" }, [
              _vm._v(
                _vm._s(_vm.$t(`${_vm.i18nReportPrefix}playerWinLose`)) + ":"
              ),
            ]),
            _c("div", { staticClass: "total-block-v2-item-right" }, [
              _vm._v(_vm._s(_vm.digital.format(_vm.total.winLose))),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "table-block" },
          [
            _c("TreeTable", {
              ref: "treeTable",
              attrs: {
                showColumnCheckbox: true,
                list: _vm.allData,
                column: _vm.tableColumn,
              },
              on: { sort: _vm.dataSort },
              scopedSlots: _vm._u([
                {
                  key: "column_gameOrderNumber",
                  fn: function (props) {
                    return [_vm._v(_vm._s(props.gameOrderNumber))]
                  },
                },
                {
                  key: "column_agentId",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            _vm.getAgentListAddDefault.find(
                              (data) => data.id === props.agentId
                            ).account
                          )
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "column_gamePlatform",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.GLOBAL.i18nCheckName(
                            _vm.getGamePlatformsAddDefault.find(
                              (data) => data.id === props.gamePlatform
                            )
                          )
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "column_gamesCategory",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.getGamesCategorysAddDefault.find(
                                (data) => props.gamesCategory === data.id
                              )
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "column_winLose",
                  fn: function (props) {
                    return [
                      _c(
                        "span",
                        { class: props.winLose < 0 ? "red" : "green" },
                        [_vm._v(_vm._s(_vm.digital.format(props.winLose)))]
                      ),
                    ]
                  },
                },
                {
                  key: "column_betType",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          props.betType === 2
                            ? _vm.$t("default.cancel")
                            : _vm.$t(
                                `${_vm.i18nReportPrefix}${
                                  props.betType ? "isSettled" : "unSettle"
                                }`
                              )
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "column_detail",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "edit-btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleDetail(props)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.detail")))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.allData
              ? _c("serverPagination", {
                  attrs: { page: _vm.page },
                  on: {
                    ServerPageUtils: _vm.searchPage,
                    changeTake: _vm.changeTake,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  i18nPrefix: _vm.i18nPrefix,
                  i18nReportPrefix: _vm.i18nReportPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }