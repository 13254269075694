export let searchModel = {
    startCreateTime: undefined,
    endCreateTime: undefined,
    auditStartTime: undefined,
    auditEndTime: undefined,
    paymentStatuses: [],
    paymentName: '',
    storeCode: undefined,
    orderNumber: "",
    memberAccount: undefined,
    amount: 0,
    gateway: 0,
    memberType: 0,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}