<template>
  <div class="multiSelectBox">
    <div class="multiSelectBox-pub">
      <div
        class="multiSelectBox-sub"
        :class="selectData.some(s => s.id == item.id) ? 'multiSelectBox-active' : ''"
        v-for="item of list"
        :key="item.id"
        @click="select(item)"
      >
        <div class="check-box">
          <input type="checkBox" :checked="selectData.some(s => s.id == item.id)"  />
          <span class="checkmark"></span>
        </div>
        {{ GLOBAL.i18nCheckName(item) }}
      </div>
    </div>
    <div class="multiSelectBox-btn">
      <button class="normal-btn-v2 main-style-btn" @click="all">{{ $t("default.selectAll") }}</button>
      <button class="normal-btn-v2 main-style-btn" @click="clear">{{ $t("default.clear") }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiSelectBox",
  props: {
    selectData: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectData: {
      handler(v) {
        this.selectTmp = [...v];
      },
      immediate: true
    }
  },
  data() {
    return {
      selectTmp: []
    };
  },
  methods: {
    select(data) {
      if (!this.multiple) {
        this.selectTmp = []
      }

      var index = this.selectTmp.findIndex(item => item.id == data.id);
      if (index > -1) {
        this.selectTmp.splice(index, 1);
      } else {
        this.selectTmp.push(data);
      }
      
      this.$emit("update:selectData", this.selectTmp);
    },
    all() {
      this.selectTmp = [...this.list];
      this.$emit("update:selectData", this.selectTmp);
    },
    clear() {
      this.selectTmp = [];
      this.$emit("update:selectData", this.selectTmp);
    }
  }
};
</script>

<style lang="scss" scoped>
.multiSelectBox {
  width: 100%;
  display: flex;
  gap: 10px;
  &-pub {
    display: flex;
    gap: 10px;
    flex-basis: 80%;
    // flex-shrink: 1;
    // flex-grow: 2;
  }
  &-sub {
    cursor: pointer;
    background-color: #949494;
    border: 1.5px solid #7A7A7A;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    min-height: 30px;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 5fr;
    align-items: center;
    text-align: center;
    .check-box {
      position: relative;
      height: 16px;
      width: 16px;
      margin: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #fff;
        border: 1.5px solid #51690E;
        border-radius: 5px;
      }
      input:checked ~ .checkmark {
        background-color: #fff;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid #779C0C;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  &-active {
    cursor: pointer;
    background-color: #779C0C !important;
    border: 1.5px solid #51690E !important;
    color: #fff !important;
  }
  &-btn {
    width: 100%;
    display: flex;
    gap: 2px;
    flex: 1;
    button {
      // flex-shrink: 0;
      // flex-grow: 1;
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .multiSelectBox {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    &-pub {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    &-btn {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
</style>