<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmark" v-show="getUserinfo.permissions.includes(list.permissions)" :key="list.id"
          @click="chooice = list.id" :class="{ 'chooice': chooice == list.id }">{{ $t(list.name) }}</div>
      </div>
    </div>
    <component :is="chooice"></component>
  </div>
</template>

<script>
import MemberDeposit from "./member-deposit";
import MemberDepositCrypto from "./member-depositCrypto";
import MemberDepositCsv from "./member-depositCsv";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
      i18nPrefix: "member.member-deposit.",
      chooice: "member-deposit",
      bookmark: [
        {
          id: "member-deposit",
          name: "default.deposit",
          permissions: 10200
        },
        {
          id: "member-depositCrypto",
          name: "default.depositCrypto",
          permissions: 10202
        },
        {
          id: "member-depositCsv",
          name: "default.depositCsv",
          permissions: 10204
        }
      ]
    };
  },
  components: {
    MemberDeposit,
    MemberDepositCrypto,
    MemberDepositCsv
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>