<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.edit")
            }}{{ $t(`title.SEO-blogCategory`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="order">
              <span> {{ $t(`${i18nPrefix}blogCategory`) }} </span>
              <input
                type="text"
                v-model="editModel.name"
                placeholder="請輸入類別"
              />
            </div>
            <div class="order">
              <span> {{ $t(`${i18nPrefix}dialog.path`) }} </span>
              <input
                type="text"
                v-model="editModel.path"
                placeholder="請輸入path"
              />
            </div>
            <div class="order">
              <span> {{ $t(`${i18nPrefix}dialog.domain`) }} </span>
              <select v-model="editModel.agentId">
                <option
                  v-for="list of agentList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.domain }}
                </option>
              </select>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('edit', editModel)">
              {{ $t("default.confirm") }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  props: ["agentList", "name", "path", "agentId", "id"],
  data() {
    return {
      editModel: {
        id: this.id,
        name: this.name,
        path: this.path,
        agentId: this.agentId,
      },
      i18nPrefix: "SEO-Blog-Function.SEO-blogCategory.",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: auto !important;
  input {
    text-align: center;
  }
  .input-block {
    width: 100%;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;

    input {
      display: block;
      width: 100%;
      padding-left: 0 !important;
      margin-left: 10px;
    }
    .order {
      display: flex;
      width: 100%;
      margin: 5px 0 !important;
      flex-direction: row;
      align-items: center;
      span {
        width: 100px !important;
      }
      select {
        width: 100%;
      }
    }
  }
}
</style>