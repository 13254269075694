<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}create`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}memberLevel`) }}
              <input type="number" v-model="createModel.star" />
            </label>
            <label class="title">
              {{ $t("default.remark") }}
              <textarea v-model="description"></textarea>
            </label>
            <label class="title">
              <h4>{{ $t(`${i18nPrefix}dialog.upgradeConditions`) }}</h4>
            </label>
            <label>
              {{ $t(`${i18nPrefix}depositRequired`) }}
              <input type="number" v-model="createModel.depositRequired" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}betRequired`) }}
              <input type="number" v-model="createModel.betRequired" />
            </label>
            <label class="title">
              <h4>{{ $t(`${i18nPrefix}dialog.levelLimit`) }}</h4>
            </label>
            <label>
              {{ $t(`${i18nPrefix}withdrawalRequired`) }}
              <input type="number" v-model="createModel.withdrawalRequired" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}withdrawalLimit`) }}
              <input type="number" v-model="createModel.withdrawalLimit" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dayWithdrawalLimit`) }}
              <input type="number" v-model="createModel.dayWithdrawalLimit" />
            </label>
            <label></label>
            <label class="title">
              <h4>{{ $t(`${i18nPrefix}promotionBonus`) }}</h4>
            </label>
            <label>
              {{ $t(`${i18nPrefix}promotionBonus`) }}
              <input type="number" v-model="createModel.promotionBonus" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}weekBonus`) }}
              <input type="number" v-model="createModel.weekBonus" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}monthBonus`) }}
              <input type="number" v-model="createModel.monthBonus" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bounsMultiple`) }}
              <input type="number" v-model="createModel.bounsMultiple" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div> 
</template>

<script>

export default {
  name: "Create",
  props: ["i18nPrefix"],
  data() {
    return {
      createModel: {
        name: undefined,
        star: undefined,
        depositRequired: undefined,
        betRequired: undefined,
        withdrawalRequired: undefined,
        withdrawalLimit: undefined,
        dayWithdrawalLimit: undefined,
        promotionBonus: undefined,
        weekBonus: undefined,
        monthBonus: undefined,
        bounsMultiple: undefined,
        description: undefined,
      },
      name: undefined,
      description: undefined,
    };
  },
  methods: {
    create() {
      for (const [key, value] of Object.entries(this.createModel)) {
        this.createModel[key] = (!this.createModel[key] || this.createModel[key].lengtn === 0) ? 0 : value * 1;
      }
      this.createModel.name = this.name;
      this.createModel.description = this.description;
      this.$emit('create', this.createModel );
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 60%;
  .input-block {
    label {
      width: 40%;
    }
    .title {
      width: 90% !important;
      margin: 0 auto !important;
      h4 {
        margin-top: 15px;
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>