<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header tree-header">
          <h3>{{ mainAccount }}</h3>
          <div class="header-container">
            <div class="btn btn-lightBlue">{{ `${date} ${$t(`${i18nPrefix}auditStatus.${auditStatus}`)}` }}</div>
            <div class="totle">
              {{ $t(`${i18nPrefix}tree.totalAmount`) }}: <div class="tag">{{ totalAmount }}</div>
            </div>
          </div>
        </div>
        <main>
          <div class="table-block">
            <TreeTable
              ref="treeTable"
              :list="list"
              :column="treeColumn"
              :showFold="false"
            >
            <template v-slot:column_agentAccount="props">
              <div class="agebt-tag">{{ props.levelNumber === 0 ? $t('default.mainAgent') : $t('default.levelNumber', {input: props.levelNumber}) }}</div>
              <a
                :href="`#/historyReport?agentId=${props.agentId}&agentRebateGeneralId=${rebateId}&lang=${lang}`"
                target="_blank"
              >{{ props.agentAccount }}</a>
            </template>
            <template v-slot:column_remitAmount="props">
              <input
                  type="number"
                  v-model="payouts[payouts.findIndex(e => e.id === props.id)].payoutAmount"
                />
            </template>
            <template v-slot:column_remark="props">
              <input
                  type="text"
                  v-model="payouts[payouts.findIndex(e => e.id === props.id)].remark"
                />
            </template>
            </TreeTable>
          </div>
          <div class="dialog-footer">
            <div
              class="btn btn-blue"
              style="font-size: 12px; margin-right: 3px; line-height: 24px;"
              @click="processNegative"
            >
              {{ $t(`${i18nPrefix}dialog.negative`) }}
            </div>
            <button
              class="normal-btn"
              @click="alertMsg = $t(`${i18nPrefix}dialog.alertMsg3`)"
            >
              {{ $t(`${i18nPrefix}payout`) }}
            </button>
          </div>
        </main>
      </div>
    </div>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="runPayout"
      />
    </transition>
  </div>
</template>

<script>
import Alert from "@/layout/components/Alert";
import TreeTable from "@/layout/components/TreeTable";
import { getTree, payout } from "@/api/agent-management/agent-rebateCalcV2";
import Message from "@/layout/components/Message";

export default {
  name: "TreePayout",
  props: ["lang", "agentId", "mainAccount", "rebateId","i18nPrefix"],
  data() {
    return {
      treeColumn: [
        {
          key: 'agentAccount',
          name: this.$t("default.agent"),
          width: 400,
          align: 'left'
        },
        {
          key: 'amount',
          align: 'right',
          name: this.$t(`${this.i18nPrefix}tree.amount`),
        },
        {
          key: 'remitAmount',
          align: 'left',
          name: this.$t(`${this.i18nPrefix}tree.remitAmount`),
        },
        {
          key: 'remark',
          align: 'left',
          name: this.$t('default.remark'),
        },
      ],
      list: [],
      totalAmount: 0,
      auditStatus: undefined,
      date: undefined,
      alertMsg: undefined,
      payouts: []
    };
  },
  components: {
    Alert,
    TreeTable,
  },
  computed: {
    
  },
  watch: {
    list: {
      handler(e) {
        if (e.length > 0) {
          this.payouts = [];
          this.processPayouts(e);
        }
      }
    },
    auditStatus: {
      handler(e) {
        if (e !== 2) {
          Message.error(this.$t(`${this.i18nPrefix}error.notPayoutStatus`), 2000);
          this.$emit('toggle');
          this.$emit('getList');
        }
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.list = [];
      await getTree({agentId: this.agentId, agentRebateGeneralId: this.rebateId}).then((res) => {
        this.list.push(res.data.tree);
        this.totalAmount = res.data.totalAmount;
        this.date = res.data.createTime.split('T')[0];
        this.auditStatus = res.data.auditStatus;
      });
    },
    processPayouts(list) {
      let vm = this;
      list.forEach(function(item) {
        vm.payouts.push({id: item.id, payoutAmount: item.amount, remark: ''});
        if (item.children?.length > 0) {
          vm.processPayouts(item.children);
        }
      });
    },
    processNegative() {
      if (this.payouts.length > 0) {
        this.payouts.forEach(function(item) {
          if (item.payoutAmount < 0) {
            item.payoutAmount = 0;
          }
        });
      }
    },
    async runPayout() {
      this.alertMsg = undefined;
      await payout({rebateId: this.rebateId, payouts: this.payouts}).then((res) => {
        this.$emit('toggle');
        this.$emit('getList');
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/button.scss";
.dialog-container {
  width: 50%;
  .input-block {
    label {
      width: 40%;
    }
  }
}
.opacity-5 {
  opacity: 0.5;
}
.agebt-tag {
  border: 1px solid #c27cf6;
  border-radius: 3px;
  background: #faefff;
  display: inline-block;
  padding: 1px 3px;
  color: #7634c1;
  margin-right: 3px;
}
.tree-header {
  align-items: flex-start;
  flex-direction: column;
}
.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .totle {
    display: flex;
    align-items: center;
    .tag {
      border: 1px solid #52058b;
      border-radius: 3px;
      background: #52058b;
      color: #fff;
      margin-left: 5px;
      padding: 1px 6px;
    }
  }
}
</style>