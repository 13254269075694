import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function gameMaintainList() {
    return request({
        url: "gamemaintain/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createGameMaintain(data) {
    return request({
        url: "gamemaintain/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editGameMaintain(data) {
    return request({
        url: "gamemaintain/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteGameMaintain(data) {
    return request({
        url: "gamemaintain/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function gameList() {
    return request({
        url: "gamemaintain/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}