<template>
  <div style="width:100%;">
    <div class="divider-v2">
      <!-- 規則 -->
      <div class="divider-v2-text divider-v2-left">{{ $t(`default.rule`) }}</div>
    </div>
    <div class="form-box" v-if="selectDiscountType !== ''">
      <div class="form-box-half form-column">
        <div class="form-box">
          <!-- 一次性優惠 -->
          <label v-if="discountRule[selectDiscountType].isOnce !== undefined" class="label-row">
            <input type="checkbox" v-model="formModel.isOnce" /> {{ $t(`${i18nPrefix}editV2.onlyDiscount`) }}
          </label>
          <!-- 是否同類型互斥 -->
          <label v-if="formModel.applyType == 2" class="label-row">
            <input type="checkbox" v-model="formModel.isMutuallyExclusivesType" /> {{
              $t(`${i18nPrefix}editV2.isMutuallyExclusivesType`) }}
          </label>
          <!-- 重複ip阻擋 -->
          <label v-if="formModel.applyType == 1 || (formModel.applyType == 2 && formModel.dispatchType == 3)">
            <span class="span-row">
              <input type="checkbox" v-model="formModel.isBlockDuplicateIP" />
              {{ $t(`${i18nPrefix}editV2.isBlockDuplicateIP`) }}
            </span>
            <input type="number" min="1" :placeholder="$t(`${i18nPrefix}dialog.times`)"
              :disabled="!formModel.isBlockDuplicateIP"
              v-model.number="formModel.BlockDuplicateIPCount" />
          </label>
        </div>
        <div class="form-box">
          <!-- 階層標準 -->
          <label v-if="discountRule[selectDiscountType].stairBase == undefined">
            {{ $t(`${i18nPrefix}editV2.stairBase`) }}
            <select v-model="formModel.stairBase">
              <option v-for="item of discountRule.stairBaseType" :key="item.key" :value="item.value">
                {{ $t(item.name) }}
              </option>
            </select>
          </label>
          <label v-if="discountRule[selectDiscountType].operand == undefined">
            {{ $t(`${i18nPrefix}editV2.operand`) }}
            <input type="text" v-model="formModel.operand" disabled />
          </label>
        </div>
        <div class="form-box">
          <!-- 限定會員等級 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.limitMemberLevel`) }}
            <multiselect v-model="memberStar.select" label="name" track-by="star" :placeholder="''"
              :options="memberStar.list" :multiple="true" :searchable="false"></multiselect>
          </label>
          <!-- 限定會員標籤 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.limitMemberTag`) }}
            <multiselect v-model="memberTag.select" label="name" track-by="id" :placeholder="''" :options="memberTag.list"
              :multiple="true" :searchable="false">
              <template slot="noOptions">
                {{ $t("default.noData") }}
              </template>
            </multiselect>
          </label>
        </div>
        <div v-if="changeFormula" class="form-box form-column example">
          <div v-for="(item, index) of stairBaseTypeExample[formModel.stairBase]" :key="index" v-html="item.text"
            class="example-row"></div>
        </div>
        <div v-if="changeFormula" class="form-box">
          <!-- 公式 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.formula`) }}
            <textarea v-model="formModel.formula" rows="3" style="height: auto;"></textarea>
            <span ref="rulesformula" class="form-rules"></span>
          </label>
        </div>
        <div v-if="!changeFormula" class="form-box">
          <label>
            {{ $t(`${i18nPrefix}editV2.dispatchStep`) }}
            <table v-if="formModel.stairBase == 1">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t(`${i18nPrefix}editV2.dispatchStep1`) }}</th>
                  <th>{{ $t(`${i18nPrefix}editV2.dispatchStep2`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) of uiFormula" :key="index">
                  <td>
                    <span v-if="uiFormula.length < 10" @click="addUiFormula(index)"> + </span>
                    <span v-if="index > 0" @click="deleteUiFormula(index)"> - </span>
                  </td>
                  <td>
                    <input :id="`uiFormula_${index}`" type="number" v-model="item.name" />
                  </td>
                  <td>
                    <input type="number" v-model="item.value" />
                  </td>
                </tr>
              </tbody>
            </table>
            <input v-else-if="formModel.stairBase == 999" type="number" v-model="uiFormulaNewRegister" />
          </label>
        </div>
      </div>
      <div class="form-box-half form-column">
        <div class="form-box">
          <!-- 週期 -->
          <label v-if="discountRule[selectDiscountType].periodType !== undefined">
            {{ $t(`${i18nPrefix}editV2.period`) }}
            <select v-model="formModel.period">
              <option v-for="item of discountRule[selectDiscountType].periodType" :key="item.key" :value="item.value">
                {{ $t(item.name) }}
              </option>
            </select>
          </label>
          <!-- 申請方式 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.apply`) }}
            <select v-model="formModel.applyType">
              <option v-for="item of applyType" :key="item.key" :value="item.value">
                {{ $t(item.name) }}
              </option>
            </select>
            <span ref="rulesapplyType" class="form-rules"></span>
          </label>
          <!-- 派發方式 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.dispatch`) }}
            <select v-model="formModel.dispatchType">
              <option v-for="item of dispatchType" :key="item.key" :value="item.value">
                {{ $t(item.name) }}
              </option>
            </select>
            <span ref="rulesdispatchType" class="form-rules"></span>
          </label>
          <!-- 優惠出款賠率 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.bounsMultiple`) }}
            <input type="number" min="0" v-model="formModel.bounsMultiple" />
            <span ref="rulesbounsMultiple" class="form-rules"></span>
          </label>
        </div>
        <div class="form-box">
          <!-- 派發上限 -->
          <label v-if="discountRule[selectDiscountType].bounsAmountLimit == undefined">
            <span>{{ $t(`${i18nPrefix}editV2.bounsAmountLimit`) }} ({{ $t(`${i18nPrefix}editV2.bounsAmountLimitRemind`) }})</span>
            <input type="number" min="-1" v-model="formModel.bounsAmountLimit" />
            <span ref="rulesbounsAmountLimit" class="form-rules"></span>
          </label>
          <!-- 派發下限 -->
          <label v-if="discountRule[selectDiscountType].bounsAmountLower == undefined">
            {{ $t(`${i18nPrefix}editV2.bounsAmountLower`) }}
            <input type="number" min="0" v-model="formModel.bounsAmountLower" />
            <span ref="rulesbounsAmountLower" class="form-rules"></span>
          </label>
        </div>
        <div class="form-box">
          <!-- 互斥優惠 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.mutuallyExclusives`) }}
            <multiselect v-model="memberDiscount.select" label="name" track-by="id" :placeholder="''"
              :options="memberDiscount.list" :multiple="true" :searchable="false"></multiselect>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "DepositEdit",
  props: [
    "i18nPrefix",
    "selectDiscountType",
    "discountRule",
    "formModel",
    "applyType",
    "dispatchType",
    "memberTag",
    "memberDiscount",
    "memberStar",
    "editModel",
    "editModelTmp",
    "imageUrlShow",
    "rulesError",
    "formulaDisabled"
  ],
  components: { Multiselect },
  data() {
    return {
      uiFormula: [{name: '', value: ''}],
      uiFormulaNewRegister: '',
      changeFormula: false,
      stairBaseTypeExample: {
        1: [  // 額度
          {
            text: `${this.$t(`${this.i18nPrefix}editV2.standardList.amount.step1`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.example`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.amount.step2`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.formula`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.amount.step3`)}`
          },
          {
            text: `${this.$t(`${this.i18nPrefix}editV2.standardList.amount.step4`)}`
          }
        ],
        2: [  // 會員等級
          {
            text: `<span class="red">x1</span> ${this.$t(`${this.i18nPrefix}editV2.standardList.level.step1`)} <span class="red">x1 * 0.1</span>`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.step`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.level.step2`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.formula`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.level.step3`)}`
          }
        ],
        3: [  // 會員標籤
          {
            text: `<span class="red">x1</span> ${this.$t(`${this.i18nPrefix}editV2.standardList.tag.step1`)} <span class="red">x1 * 0.1</span>`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.step`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.tag.step2`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.example`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.tag.step3`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.formula`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.tag.step4`)}`
          }
        ],
        999: [  // 新註冊用公式
          {
            text: `${this.$t(`${this.i18nPrefix}editV2.standardList.newRegister.step1`)}`
          },
          {
            text: `${this.$t(`${this.i18nPrefix}editV2.standardList.newRegister.step1`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.example`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.newRegister.step2`)}`
          },
          {
            text: `<div class="example-tag">${this.$t(`${this.i18nPrefix}editV2.formula`)}</div> ${this.$t(`${this.i18nPrefix}editV2.standardList.newRegister.step3`)}`
          },
          {
            text: `${this.$t(`${this.i18nPrefix}editV2.standardList.newRegister.step1`)}`
          },
          {
            text: `${this.$t(`${this.i18nPrefix}editV2.standardList.newRegister.step1`)}`
          }
        ]
      },
    };
  },
  watch: {
    "rulesError": {
      handler(e) {
        if (e.length > 0) {
          const vm = this;
          e.forEach(function (item) {
            if (vm.$refs[`rules${item.key}`]) {
              vm.$refs[`rules${item.key}`].innerText = item.message;
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
    uiFormula: {
      handler(newValue) {
        const vm = this;
        const tmp = [...newValue];

        // 判斷金額
        vm.$emit("formulaDisabled", false);
        tmp.forEach(function(item, index) {
          const dom = document.getElementById(`uiFormula_${index}`);
          if (dom) {
            if (dom.classList.contains("red-input")) {
              dom.classList.remove('red-input');
            }

            // 不等於0
            if (parseInt(item.name) == 0) {
              vm.$emit("formulaDisabled", true);
              dom.classList.add('red-input');
            }

            // 回饋%數 是否填寫
            if (item.value == '') {
              vm.$emit("formulaDisabled", true);
            }

            // 檢查金額是否大於上一筆
            if (index > 0) {
              const prev = parseInt(tmp[index-1].name);
              if (parseInt(item.name) <= prev) {
                vm.$emit("formulaDisabled", true);
                dom.classList.add('red-input');
              }
            }
          } else {
            vm.$emit("formulaDisabled", true);
            return;
          }
        });

        const transformedData = tmp.map(item => {
          return {
            name: item.name,
            value: `x1 * ${Number(item.value) * 1000 / 100000}`
          };
        });

        const jsonObject = {};
        transformedData.forEach(item => {
            jsonObject[item.name] = item.value.toString();
        });

        this.formModel.formula = JSON.stringify(jsonObject);
      },
      deep: true
    },
    uiFormulaNewRegister: {
      handler(e) {
        this.formModel.formula = `{ "all": "${e}" }`;
      }
    },
    changeFormula: {
      handler(e) {
        if (!e) {
          this.handlerUiFormula()
        }
      }
    }
  },
  async created() {
    // this.formModel = _.cloneDeep(this.emptyFormModel);
    this.handlerUiFormula();
  },
  async beforeCreate() {

  },
  methods: {
    handlerUiFormula() {
      const formula = this.formModel.formula;
      if (formula != '' && this.formModel.stairBase == 1) {
        this.uiFormula = [];
        const jsonObject = JSON.parse(formula);
        for (const key in jsonObject) {
          if (Object.hasOwnProperty.call(jsonObject, key)) {
            const value = jsonObject[key];
            const parts = value.split('*');
            
            // 乘 100 會溢出
            var item = {name: key, value: (parseFloat(parts[1].trim()) * 1000 / 10)}
            this.uiFormula.push(item);
          }
        }
      } else if (formula != '' && this.formModel.stairBase == 999) {
        const jsonObject = JSON.parse(formula);
        for (const key in jsonObject) {
          if (Object.hasOwnProperty.call(jsonObject, key)) {
            const value = jsonObject[key];
            this.uiFormulaNewRegister = value;
          }
        }
      }
    },
    addUiFormula(index) {
      this.uiFormula.splice(index + 1, 0, {name: '', value: ''});
    },
    deleteUiFormula(index) {
      this.uiFormula.splice(index, 1);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.hideData {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-box .example {
  padding: 0 5px;

  &-row {
    margin-bottom: 3px;
    white-space: pre-line;
  }
}

.form-box {
  select {
    margin-top: 0 !important;
  }
}

table {
  td {
    padding: 3px;
    span {
      font-size: 16px;
      cursor: pointer;
    }
    input {
      width: 100%;
    }
    .red-input {
      border: 1px solid #f00;
    }
  }
}
</style>