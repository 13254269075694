<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmark"
          v-show="getUserinfo.permissions.includes(list.permissions)"
          :key="list.id" @click="chooice = list.id" :class="{ 'chooice': chooice == list.id }">{{ $t(list.name) }}</div>
      </div>
    </div>
    <component :is="chooice"></component>
  </div>
</template>

<script>
import MemberDepositStore from "./member-deposit-store";
import MemberDepositCryptoStore from "./member-depositCrypto-store";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
      i18nPrefix: "member.member-deposit.",
      chooice: "member-deposit-store",
      bookmark: [
        {
          id: "member-deposit-store",
          name: "default.deposit",
          permissions: 10300
        },
        {
          id: "member-depositCrypto-store",
          name: "default.depositCrypto",
          permissions: 10302
        }
      ]
    };
  },
  components: {
    MemberDepositStore,
    MemberDepositCryptoStore
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>