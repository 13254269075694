var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.domain.title`))),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "container-view" }, [
              _c("div", { staticClass: "input-block" }, [
                _c("div", { staticClass: "condition-block" }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.domain.createDomain`)
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "selectDiv" },
                    [
                      _c("multiselect", {
                        attrs: {
                          label: "domain",
                          "track-by": "id",
                          placeholder: _vm.$t("default.plzSelect"),
                          options: _vm.domainList,
                          multiple: true,
                          searchable: false,
                        },
                        model: {
                          value: _vm.addDomain,
                          callback: function ($$v) {
                            _vm.addDomain = $$v
                          },
                          expression: "addDomain",
                        },
                      }),
                      _c("div", { staticClass: "button-block" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.addDomain = [..._vm.domainList]
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.selectAll")))]
                        ),
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.addDomain = []
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.clear")))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "advance-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "normal-btn",
                    on: {
                      click: function ($event) {
                        _vm.$emit("createDomain", {
                          id: _vm.id,
                          agents: _vm.addDomain.map((domain) => domain.id),
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "delete-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("toggle")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.cancel")))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "container-view" }, [
              _c("div", { staticClass: "table-block" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.domain.created`)
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.domain.title`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.domain.agent`))
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.tableList || _vm.tableList.length === 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm._v(_vm._s(_vm.$t("default.noData"))),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.tableList, function (list) {
                        return _c("tr", { key: list.id }, [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete({ id: list.id })
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.delete")))]
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(list.domain))]),
                          _c("td", [_vm._v(_vm._s(list.account))]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.deleteDomain,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }