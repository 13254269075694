import axios from "axios";
import store from "@/store";
import i18n from '@/lang/lang'
import Message from "@/layout/components/Message";
import { removeToken } from "@/utils/auth";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
});
// request interceptor
service.interceptors.request.use(
    config => {
        if (config.url !== "/PrivateMessage/get" && config.url !== "/Member/count") {
            store.commit("app/SET_LOADING", true);
        }
        // do something before request is sent
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    },
    {
        synchronous: true
    }
)
// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    async response => {
        const res = response.data;
        if (res.error?.code === "InvalidToken" || res.error?.code === "RepeatLogin") {
            alert(i18n.t("ErrorCode." + res.error.code));
            removeToken()
            location.reload();
            setTimeout(() => { store.commit("app/SET_LOADING", false) }, 500);
            return false
        }

        if (response.config.url !== "/PrivateMessage/get" && response.config.url !== "/Member/count") {
            if (res) {
                if (await res?.error) {
                    setTimeout(() => { store.commit("app/SET_LOADING", false) }, 500);
                    Message.error(i18n.t("ErrorCode." + res.error.code, res.error?.args), 2000);
                    return false
                } else {
                    if (await res) {
                        setTimeout(() => { store.commit("app/SET_LOADING", false) }, 500);
                        if (response.config.method === 'post') Message.success(i18n.t("default.apiSuccess"), 1000);
                    }
                }
            }
        }
        return res
    },
    error => {
        console.log('err' + error) // for debug
        const res = error.response.data;
        Message.error(i18n.t("ErrorCode." + res.error.code, res.error?.args), 2000);
        store.commit("app/SET_LOADING", false);
        return Promise.reject(error);
    },
    {
        synchronous: true
    }
)
export default service;
