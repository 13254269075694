<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.member") }}
              <input
                type="text"
                v-model="createModel.memberAccount"
              />
            </label>
            <label for=""></label>
            <label>
              {{ $t(`${i18nPrefix}minBetAmount`) }}
              <input
                type="number"
                v-model.number="createModel.minBetAmount"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}maxBetAmount`) }}
              <input
                type="number"
                v-model.number="createModel.maxBetAmount"
              />
            </label>
            <div class="textarea-div">
              {{ $t("default.remark") }}
              <textarea v-model="createModel.remark" rows="5"></textarea>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('create', createModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: [
    "list",
    "i18nPrefix"
  ],
  data() {
    return {
      createModel: {
        memberAccount: undefined,
        minBetAmount: 10,
        maxBetAmount: 100,
        remark: undefined,
      },
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  flex-wrap: nowrap !important;
  .dialog-container {
    width: 50%;
    label {
      width: 40% !important;
    }
    .textarea-div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>