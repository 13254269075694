import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getCryptoWithdrawalSettingList(data) {
    return request({
        url: "/CryptoWithdrawalSetting/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCryptoWithdrawalSetting(data) {
    return request({
        url: "/CryptoWithdrawalSetting/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editCryptoWithdrawalSetting(data) {
    return request({
        url: "/CryptoWithdrawalSetting/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCryptoWithdrawalSettingProcess(data) {
    return request({
        url: "/CryptoWithdrawalSetting/Process",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSelectMenu() {
    return request({
        url: "/CryptoWithdrawalSetting/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}