
<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button
          class="normal-btn"
          :disabled="!$store.getters.getUserinfo.permissions.includes(70401)"
          @click="handleDialog( undefined , 'Create')"
        >{{ $t("default.create") }}</button>
      </div>
      <div class="divider"></div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th style="width: 15%"></th>
                <th>{{ $t(`${i18nPrefix}account`) }}</th>
                <th>{{ $t("default.status") }}</th>
                <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
                <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button
                    class="normal-btn"
                    @click="handleDialog({ data: list }, 'Edit')"
                    :disabled="!$store.getters.getUserinfo.permissions.includes(70402)"
                  >{{ $t("default.edit") }}</button>
                </td>
                <td>{{ list.account }}</td>
                <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                <td>{{ list.createTime }}</td>
                <td>{{ list.updateTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  RdAccountList,
  createRdAccount,
  editRdAccount
} from "@/api/features/rdAccount";
import Confirm from "@/layout/components/Confirm";
import { mapGetters, mapMutations } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "rdAccount",
  data() {
    return {
      deleteData: undefined,
      i18nPrefix: "visit-control.office-user."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    Pagination
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo"])
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM"
    }),
    async getList() {
      await RdAccountList({}).then(res => {
        this.allData = res.data;
        this.deleteData = undefined;
        this.closeDialog();
      });
    },
    create(data) {
      createRdAccount(data).then(res => {
        if (res) this.getList();
      });
    },
    edit(data) {
      editRdAccount(data).then(res => {
        if (res) this.getList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>