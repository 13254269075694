import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function rebateWeekly(data) {
    return request({
        url: "/MemberRebateV2/Calculate",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDiscountList() {
    return request({
        url: "/MemberRebateV2/DiscountSettings",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

// export function rebateList() {
//     return request({
//         url: "/MemberRebateV2/List",
//         method: "get",
//         headers: { "Authorization": `token ${getToken()}` }
//     });
// }

export function rebateList(data) {
    return request({
        url: "/MemberRebateV2/newList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}