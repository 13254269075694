<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.member") }}
              <input type="text" v-model="editModel.memberAccount" disabled />
            </label>
            <label>
              {{ $t("default.amount") }}
              <input type="text" v-model="editModel.amount" disabled />
            </label>
            <label>
              {{ $t("default.remark") }}
              <textarea v-model="editModel.remark" placeholder=""></textarea>
            </label>
          </div>
          <div class="advance-block">
            <button v-if="paymentStatus > 3"class="normal-btn" @click.self="editRemark">{{ $t(`${i18nPrefix}editRemark`) }}</button>
            <button class="normal-btn" v-if="paymentStatus <= 3" @click.self="accept">{{ $t(`${i18nPrefix}dialog.accept`) }}</button>
            <button class="delete-btn" v-if="paymentStatus <= 3" @click.self="reject">{{ $t(`${i18nPrefix}dialog.reject`) }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Audit",
  props: [
    "id",
    "memberAccount",
    "amount",
    "paymentStatus",
    "remark",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        memberAccount: this.memberAccount,
        amount: this.amount,
        remark: this.remark
      }
    }
  },
  methods: {
    accept() {
      this.editModel.paymentStatus = 5;
      this.$emit("audit", this.editModel);
    },
    reject() {
      this.editModel.paymentStatus = 6;
      this.$emit("audit", this.editModel);
    },
    editRemark() {
      this.editModel.paymentStatus = this.paymentStatus;
      this.$emit("audit", this.editModel);
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-block {
  .input-block {
    label {
      width: 60%;
      margin: 15px auto;
    }
    textarea {
      height: 100px;
    }
  }
}
</style>