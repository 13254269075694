import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberDepositPaymentReportList(data) {
    return request({
        url: "/MemberDepositPaymentReport/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getExcel(data) {
    return request({
        url: "/MemberDepositPaymentReport/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function getDepositReportPaymentTotal(data) {
    return request({
        url: "/MemberDepositPaymentReport/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function memberDepositCryptoPaymentReportList(data) {
    return request({
        url: "/MemberDepositCryptoPaymentReport/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositCryptoPaymentReportExcel(data) {
    return request({
        url: "/MemberDepositCryptoPaymentReport/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function getDepositCryptoPaymentReportTotal(data) {
    return request({
        url: "/MemberDepositCryptoPaymentReport/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}