import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function bankgroupList() {
    return request({
        url: "/companyBank/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createBankGroup(data) {
    return request({
        url: "/companyBank/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editBankGroup(data) {
    return request({
        url: "/companyBank/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function bankGroupSelect() {
    return request({
        url: "/companyBank/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}