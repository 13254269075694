<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}game`) }}
              <select v-model="editModel.gamePlatform" disabled>
                <option
                  v-for="list of gameList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}day`) }}
              <select v-model="editModel.dayOfWeek">
                <option
                  v-for="list of getWeekList"
                  :key="list.name"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}timeFrom`) }}
              <date-picker v-model="tempTimeFrom" format="HH:mm" value-type="format" type="time"></date-picker>
            </label>
            <label>
              {{ $t(`${i18nPrefix}timeTo`) }}
              <date-picker v-model="tempTimeTo" format="HH:mm" value-type="format" type="time"></date-picker>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="editModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.name"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  joinT,
  splitTimeForTimeFormat,
  transTodayDate,
} from "@/utils/transDateUtils";


export default {
  name: "Edit",
  props: [
    "id",
    "gamePlatform",
    "dayOfWeek",
    "status",
    "timeFrom",
    "timeTo",
    "gameList",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        gamePlatform: this.gamePlatform,
        dayOfWeek: this.dayOfWeek,
        status: this.status,
        timeFrom: this.timeFrom,
        timeTo: this.timeTo,
      },
      tempTimeFrom: undefined,
      tempTimeTo: undefined,
    };
  },
  created() {
    this.tempTimeFrom = splitTimeForTimeFormat(this.editModel.timeFrom);
    this.tempTimeTo = splitTimeForTimeFormat(this.editModel.timeTo);
  },
  computed: {
    ...mapGetters(["getStatusList", "getWeekList"]),
  },
  
  methods: {
    submit() {
      this.editModel.timeFrom = joinT(transTodayDate() + " " + this.tempTimeFrom + this.GLOBAL.startSecond+ this.GLOBAL.timeZone);
      this.editModel.timeTo = joinT(transTodayDate() + " " + this.tempTimeTo + this.GLOBAL.endSecond+ this.GLOBAL.timeZone);
      this.$emit("edit", this.editModel);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  .input-block {
    label {
      width: 45%;
    }
  }
}
</style>