export let searchModel = {
    startTime: undefined,
    endTime: undefined,
    auditAccountId: 0,
    auditStatus: 0,
    adjustmentType: 0,
    memberAccount: undefined,
    tagId: 0,
    page:  {
        "take": 10,
        "skip": 0
    },
}