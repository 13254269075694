var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}relationMember`))),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c(
              "div",
              { staticClass: "list" },
              [
                !_vm.relationMember.length
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("default.noData")))]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.relationMember, function (list, index) {
                  return _c("div", { key: index }, [
                    _c("span", [_vm._v(_vm._s(list))]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }