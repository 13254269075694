<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th>{{ $t(`${i18nPrefix}account`) }}</th>
                <th>{{ $t(`${i18nPrefix}phoneNumber`) }}</th>
                <th>{{ $t(`${i18nPrefix}verificationCode`) }}</th>
                <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!tableList || tableList.length === 0">
                <td colspan="4">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="list of tableList" :key="list.createTime">
                <td>{{ list.account }}</td>
                <td>{{ list.phoneNumber }}</td>
                <td>{{ list.verificationCode }}</td>
                <td>{{ list.createTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { verificationCodeList } from "@/api/features/verification-code";

export default {
  name: "VerificationCode",
  data() {
    return {
      i18nPrefix: "features.verification-code."
    }
  },
  components: {
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  created() {
    verificationCodeList().then((res) => {
      this.allData = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
</style>