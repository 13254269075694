import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu(data) {
    return request({
        url: "/CampaignRedeemCode/SelectMenu",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRedeemCodeList(data) {
    return request({
        url: "/CampaignRedeemCode/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createRedeemCode(data) {
    return request({
        url: "/CampaignRedeemCode/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateRedeemCode(data) {
    return request({
        url: "/CampaignRedeemCode/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function publishRedeemCode(data) {
    return request({
        url: "/CampaignRedeemCode/Publish",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function closeRedeemCode(data) {
    return request({
        url: "/CampaignRedeemCode/Close",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRedeemCodeProcess(data) {
    return request({
        url: "/CampaignRedeemCode/Detail",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRedeemCodeRecord(data) {
    return request({
        url: "/CampaignRedeemCode/Record",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRedeemCodeRecordTotal(data) {
    return request({
        url: "/CampaignRedeemCode/RecordTotal",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}