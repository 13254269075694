<template>
  <div>
    <div class="agent-block">
<!--      <div class="container-view tree-block">-->
<!--        <div class="tree-container" v-if="treeData">-->
<!--          <TreeNode-->
<!--            :treeData="treeData"-->
<!--            :agentId="agentId"-->
<!--            @passAgentId="passAgentId"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
      <div class="content-block">
        <div class="container-view">
          <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
          <div class="header">
            <h5>{{ $t("title." + $route.meta.title) }}</h5>
            <button
                class="normal-btn"
                v-show=" agentId  !== getUserinfo.parentId "
                @click="passAgentId(backAgentId)"
            >
              {{ $t(`${i18nPrefix}back`) }}
            </button>
          </div>
          <div class="divider"></div>
          <main>
            <div class="input-block">
              <label>
                {{ $t(`${i18nPrefix}startTime`) }}
                <date-picker v-model="startTime" format="YYYY-MM-DD HH" value-type="format" type="datetime"></date-picker>
              </label>
              <label>
                {{ $t(`${i18nPrefix}endTime`) }}
                <date-picker v-model="endTime" format="YYYY-MM-DD HH" value-type="format" type="datetime"></date-picker>
              </label>
            </div>
            <div class="permission-block">
              <p>{{ $t(`${i18nPrefix}isIgnore`) }}</p>
              <div class="permission-box">
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreCashDeposit"
                  />
                  {{ $t(`${i18nPrefix}cashDeposit`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnorePaymentDeposit"
                  />
                  {{ $t(`${i18nPrefix}paymentDeposit`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreCashWithdrawal"
                  />
                  {{ $t(`${i18nPrefix}cashWithdrawal`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreAdjustmentAdd"
                  />
                  {{ $t(`${i18nPrefix}adjustmentAdd`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreAdjustmentReduce"
                  />
                  {{ $t(`${i18nPrefix}adjustmentReduce`) }}
                </label>
              </div>
              <div class="permission-box">
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreBet"
                  />
                  {{ $t(`${i18nPrefix}bet`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreWinLose"
                  />
                  {{ $t(`${i18nPrefix}WinLose`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreRebate"
                  />
                  {{ $t(`${i18nPrefix}rebate`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreEvent"
                  />
                  {{ $t(`${i18nPrefix}event`) }}
                </label>
                <label class="isIgnore">
                  <input
                      type="checkbox"
                      v-model="searchForm.options.isIgnoreDiscount"
                  />
                  {{ $t(`${i18nPrefix}discount`) }}
                </label>
              </div>
            </div>
            <div class="advance-block">
              <QuickSearch
                @changeTime="changeTime"
                :controlType="controlType"
              ></QuickSearch>
            </div>
          </main>
        </div>
        <div class="container-view">
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th style="min-width: 50px">{{ $t(`${i18nPrefix}agent`) }}</th>
                  <th>{{ $t(`${i18nPrefix}cashDeposit`) }}</th>
                  <th>{{ $t(`${i18nPrefix}paymentDeposit`) }}</th>
                  <th>{{ $t(`${i18nPrefix}cashWithdrawal`) }}</th>
                  <th>{{ $t(`${i18nPrefix}withdrawalFeeAmount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}adjustmentAdd`) }}</th>
                  <th>{{ $t(`${i18nPrefix}adjustmentReduce`) }}</th>
                  <th>{{ $t(`${i18nPrefix}bet`) }}</th>
                  <th>{{ $t(`${i18nPrefix}WinLose`) }}</th>
                  <th>{{ $t(`${i18nPrefix}rebate`) }}</th>
                  <th>{{ $t(`${i18nPrefix}event`) }}</th>
                  <th>{{ $t(`${i18nPrefix}discountRebate`) }}</th>
                  <th>{{ $t(`${i18nPrefix}discount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}totalFirstDepositCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}totalRegisterCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}totalLoginCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}accountFor`) }}</th>
                  <th>{{ $t(`${i18nPrefix}accountForWinLose`) }}</th>
                  <th>{{ $t(`${i18nPrefix}accountForDiscount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}commission`) }}</th>
                  <th>{{ $t(`${i18nPrefix}netWinLose`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}betCasino`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}winLoseCasino`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionRateCasino`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionCasino`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}betSlot`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}winLoseSlot`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionRateSlot`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionSlot`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}betSport`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}winLoseSport`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionRateSport`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionSport`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}betOther`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}winLoseOther`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionRateOther`) }}</th>
                  <th class="brown">{{ $t(`${i18nPrefix}commissionOther`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="(!allData || !allData.length) && !total">
                  <td colspan="37">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="list of allData" :key="list.account">
                  <td v-if="list.isAgent" class="agent" @click="passAgentId(list.id)">{{ list.account }} ({{ list.agentName }})</td>
                  <td v-else>{{ list.account }}</td>
                  <td>{{ digital.format(list.cashDeposit) }}</td>
                  <td>{{ digital.format(list.paymentDeposit) }}</td>
                  <td>{{ digital.format(list.cashWithdrawal) }}</td>
                  <td>{{ digital.format(list.withdrawalFeeAmount) }}</td>
                  <td>{{ digital.format(list.amountAdjustmentAdd) }}</td>
                  <td>{{ digital.format(list.amountAdjustmentReduce) }}</td>
                  <td>{{ digital.format(list.betAmount) }}</td>
                  <td :class="{ 'font-green': list.winLose >= 0 , 'font-red': list.winLose < 0}">{{ digital.format(list.winLose) }}</td>
                  <td>{{ digital.format(list.rebateAmount) }}</td>
                  <td>{{ digital.format(list.eventAmount) }}</td>
                  <td>{{ digital.format(list.discountRebate) }}</td>
                  <td>{{ digital.format(list.discount) }}</td>
                  <td>{{ list.totalFirstDepositCount }}</td>
                  <td>{{ list.totalRegisterCount }}</td>
                  <td>{{ list.totalLoginCount }}</td>
                  <td>{{ list.accountFor }}</td>
                  <td :class="{ 'font-green': list.accountForWinLose >= 0 , 'font-red': list.accountForWinLose < 0}">{{ digital.format(list.accountForWinLose) }}</td>
                  <td>{{ digital.format(list.accountForDiscount) }}</td>
                  <td>{{ digital.format(list.commission) }}</td>
                  <td :class="{ 'font-green': list.netWinLose >= 0 , 'font-red': list.netWinLose < 0}">{{ digital.format(list.netWinLose) }}</td>
                  <td>{{ digital.format(list.betCasino) }}</td>
                  <td :class="{ 'font-green': list.winLoseCasino >= 0 , 'font-red': list.winLoseCasino < 0}">{{ digital.format(list.winLoseCasino) }}</td>
                  <td>{{ list.commissionRateCasino }}</td>
                  <td>{{ digital.format(list.commissionCasino) }}</td>
                  <td>{{ digital.format(list.betSlot) }}</td>
                  <td :class="{ 'font-green': list.winLoseSlot >= 0 , 'font-red': list.winLoseSlot < 0}">{{ digital.format(list.winLoseSlot) }}</td>
                  <td>{{ list.commissionRateSlot }}</td>
                  <td>{{ digital.format(list.commissionSlot) }}</td>
                  <td>{{ digital.format(list.betSport) }}</td>
                  <td :class="{ 'font-green': list.winLoseSport >= 0 , 'font-red': list.winLoseSport < 0}">{{ digital.format(list.winLoseSport) }}</td>
                  <td>{{ list.commissionRateSport }}</td>
                  <td>{{ digital.format(list.commissionSport) }}</td>
                  <td>{{ digital.format(list.betOther) }}</td>
                  <td :class="{ 'font-green': list.winLoseOther >= 0 , 'font-red': list.winLoseOther < 0}">{{ digital.format(list.winLoseOther) }}</td>
                  <td>{{ list.commissionRateOther }}</td>
                  <td>{{ digital.format(list.commissionOther) }}</td>
                </tr>
                <tr v-if="agentTotal">
                  <td>{{ $t(`${i18nPrefix}agentTotal`) }}</td>
                  <td>{{ digital.format(agentTotal.totalCashDeposit) }}</td>
                  <td>{{ digital.format(agentTotal.totalPaymentDeposit) }}</td>
                  <td>{{ digital.format(agentTotal.totalCashWithdrawal) }}</td>
                  <td>{{ digital.format(agentTotal.totalFeeAmount) }}</td>
                  <td>{{ digital.format(agentTotal.totalAmountAdjustmentAdd) }}</td>
                  <td>{{ digital.format(agentTotal.totalAmountAdjustmentReduce) }}</td>
                  <td>{{ digital.format(agentTotal.totalBetAmount) }}</td>
                  <td :class="{ 'font-green': agentTotal.totalWinLose >= 0 , 'font-red': agentTotal.totalWinLose < 0}">{{ digital.format(agentTotal.totalWinLose) }}</td>
                  <td>{{ digital.format(agentTotal.totalRebateAmount) }}</td>
                  <td>{{ digital.format(agentTotal.totalEventAmount) }}</td>
                  <td>{{ digital.format(agentTotal.totalDiscountRebate) }}</td>
                  <td>{{ digital.format(agentTotal.totalDiscount) }}</td>
                  <td>{{ agentTotal.totalFirstDepositCount }}</td>
                  <td>{{ agentTotal.totalRegisterCount }}</td>
                  <td>{{ agentTotal.totalLoginCount }}</td>
                  <td></td>
                  <td :class="{ 'font-green': agentTotal.totalAccountForWinLose >= 0 , 'font-red': agentTotal.totalAccountForWinLose < 0}">{{ digital.format(agentTotal.totalAccountForWinLose) }}</td>
                  <td>{{ digital.format(agentTotal.totalDiscountWinLose) }}</td>
                  <td>{{ digital.format(agentTotal.totalCommission) }}</td>
                  <td :class="{ 'font-green': agentTotal.totalNetWinLose >= 0 , 'font-red': agentTotal.totalNetWinLose < 0}">{{ digital.format(agentTotal.totalNetWinLose) }}</td>
                  <td>{{ digital.format(agentTotal.totalBetCasino) }}</td>
                  <td :class="{ 'font-green': agentTotal.totalWinLoseCasino >= 0 , 'font-red': agentTotal.totalWinLoseCasino < 0}">{{ digital.format(agentTotal.totalWinLoseCasino) }}</td>
                  <td></td>
                  <td>{{ digital.format(agentTotal.totalCommissionCasino) }}</td>
                  <td>{{ digital.format(agentTotal.totalBetSlot) }}</td>
                  <td :class="{ 'font-green': agentTotal.totalWinLoseSlot >= 0 , 'font-red': agentTotal.totalWinLoseSlot < 0}">{{ digital.format(agentTotal.totalWinLoseSlot) }}</td>
                  <td></td>
                  <td>{{ digital.format(agentTotal.totalCommissionSlot) }}</td>
                  <td>{{ digital.format(agentTotal.totalBetSport) }}</td>
                  <td :class="{ 'font-green': agentTotal.totalWinLoseSport >= 0 , 'font-red': agentTotal.totalWinLoseSport < 0}">{{ digital.format(agentTotal.totalWinLoseSport) }}</td>
                  <td></td>
                  <td>{{ digital.format(agentTotal.totalCommissionSport) }}</td>
                  <td>{{ digital.format(agentTotal.totalBetOther) }}</td>
                  <td :class="{ 'font-green': agentTotal.totalWinLoseOther >= 0 , 'font-red': agentTotal.totalWinLoseOther < 0}">{{ digital.format(agentTotal.totalWinLoseOther) }}</td>
                  <td></td>
                  <td>{{ digital.format(agentTotal.totalCommissionOther) }}</td>
                </tr>
                <tr v-if="memberTotal">
                  <td>{{ $t(`${i18nPrefix}memberTotal`) }}</td>
                  <td>{{ digital.format(memberTotal.totalCashDeposit) }}</td>
                  <td>{{ digital.format(memberTotal.totalPaymentDeposit) }}</td>
                  <td>{{ digital.format(memberTotal.totalCashWithdrawal) }}</td>
                  <td>{{ digital.format(memberTotal.totalFeeAmount) }}</td>
                  <td>{{ digital.format(memberTotal.totalAmountAdjustmentAdd) }}</td>
                  <td>{{ digital.format(memberTotal.totalAmountAdjustmentReduce) }}</td>
                  <td>{{ digital.format(memberTotal.totalBetAmount) }}</td>
                  <td :class="{ 'font-green': memberTotal.totalWinLose >= 0 , 'font-red': memberTotal.totalWinLose < 0}">{{ digital.format(memberTotal.totalWinLose) }}</td>
                  <td>{{ digital.format(memberTotal.totalRebateAmount) }}</td>
                  <td>{{ digital.format(memberTotal.totalEventAmount) }}</td>
                  <td>{{ digital.format(memberTotal.totalDiscountRebate) }}</td>
                  <td>{{ digital.format(memberTotal.totalDiscount) }}</td>
                  <td>{{ memberTotal.totalFirstDepositCount }}</td>
                  <td>{{ memberTotal.totalRegisterCount }}</td>
                  <td>{{ memberTotal.totalLoginCount }}</td>
                  <td></td>
                  <td :class="{ 'font-green': memberTotal.totalDiscountWinLose >= 0 , 'font-red': memberTotal.totalDiscountWinLose < 0}">{{ digital.format(memberTotal.totalAccountForWinLose) }}</td>
                  <td>{{ digital.format(memberTotal.totalDiscountWinLose) }}</td>
                  <td>{{ digital.format(memberTotal.totalCommission) }}</td>
                  <td :class="{ 'font-green': memberTotal.totalNetWinLose >= 0 , 'font-red': memberTotal.totalNetWinLose < 0}">{{ digital.format(memberTotal.totalNetWinLose) }}</td>
                  <td>{{ digital.format(memberTotal.totalBetCasino) }}</td>
                  <td :class="{ 'font-green': memberTotal.totalWinLoseCasino >= 0 , 'font-red': memberTotal.totalWinLoseCasino < 0}">{{ digital.format(memberTotal.totalWinLoseCasino) }}</td>
                  <td></td>
                  <td>{{ digital.format(memberTotal.totalCommissionCasino) }}</td>
                  <td>{{ digital.format(memberTotal.totalBetSlot) }}</td>
                  <td :class="{ 'font-green': memberTotal.totalWinLoseSlot >= 0 , 'font-red': memberTotal.totalWinLoseSlot < 0}">{{ digital.format(memberTotal.totalWinLoseSlot) }}</td>
                  <td></td>
                  <td>{{ digital.format(memberTotal.totalCommissionSlot) }}</td>
                  <td>{{ digital.format(memberTotal.totalBetSport) }}</td>
                  <td :class="{ 'font-green': memberTotal.totalWinLoseSport >= 0 , 'font-red': memberTotal.totalWinLoseSport < 0}">{{ digital.format(memberTotal.totalWinLoseSport) }}</td>
                  <td></td>
                  <td>{{ digital.format(memberTotal.totalCommissionSport) }}</td>
                  <td>{{ digital.format(memberTotal.totalBetOther) }}</td>
                  <td :class="{ 'font-green': memberTotal.totalWinLoseOther >= 0 , 'font-red': memberTotal.totalWinLoseOther < 0}">{{ digital.format(memberTotal.totalWinLoseOther) }}</td>
                  <td></td>
                  <td>{{ digital.format(memberTotal.totalCommissionOther) }}</td>
                </tr>
                <tr v-if="total">
                  <td>{{ $t("default.total") }}</td>
                  <td>{{ digital.format(total.totalCashDeposit) }}</td>
                  <td>{{ digital.format(total.totalPaymentDeposit) }}</td>
                  <td>{{ digital.format(total.totalCashWithdrawal) }}</td>
                  <td>{{ digital.format(total.totalFeeAmount) }}</td>
                  <td>{{ digital.format(total.totalAmountAdjustmentAdd) }}</td>
                  <td>{{ digital.format(total.totalAmountAdjustmentReduce) }}</td>
                  <td>{{ digital.format(total.totalBetAmount) }}</td>
                  <td :class="{ 'font-green': total.totalWinLose >= 0 , 'font-red': total.totalWinLose < 0}">{{ digital.format(total.totalWinLose) }}</td>
                  <td>{{ total.totalRebateAmount }}</td>
                  <td>{{ digital.format(total.totalEventAmount) }}</td>
                  <td>{{ digital.format(total.totalDiscountRebate) }}</td>
                  <td>{{ digital.format(total.totalDiscount) }}</td>
                  <td>{{ total.totalFirstDepositCount }}</td>
                  <td>{{ total.totalRegisterCount }}</td>
                  <td>{{ total.totalLoginCount }}</td>
                  <td></td>
                  <td :class="{ 'font-green': total.totalAccountForWinLose >= 0 , 'font-red': total.totalAccountForWinLose < 0}">{{ digital.format(total.totalAccountForWinLose) }}</td>
                  <td>{{ digital.format(total.totalDiscountWinLose) }}</td>
                  <td>{{ digital.format(total.totalCommission) }}</td>
                  <td :class="{ 'font-green': total.totalNetWinLose >= 0 , 'font-red': total.totalNetWinLose < 0}">{{ digital.format(total.totalNetWinLose) }}</td>
                  <td>{{ digital.format(total.totalBetCasino) }}</td>
                  <td :class="{ 'font-green': total.totalWinLoseCasino >= 0 , 'font-red': total.totalWinLoseCasino < 0}">{{ digital.format(total.totalWinLoseCasino) }}</td>
                  <td></td>
                  <td>{{ digital.format(total.totalCommissionCasino) }}</td>
                  <td>{{ digital.format(total.totalBetSlot) }}</td>
                  <td :class="{ 'font-green': total.totalWinLoseSlot >= 0 , 'font-red': total.totalWinLoseSlot < 0}">{{ digital.format(total.totalWinLoseSlot) }}</td>
                  <td></td>
                  <td>{{ digital.format(total.totalCommissionSlot) }}</td>
                  <td>{{ digital.format(total.totalBetSport) }}</td>
                  <td :class="{ 'font-green': total.totalWinLoseSport >= 0 , 'font-red': total.totalWinLoseSport < 0}">{{ digital.format(total.totalWinLoseSport) }}</td>
                  <td></td>
                  <td>{{ digital.format(total.totalCommissionSport) }}</td>
                  <td>{{ digital.format(total.totalBetOther) }}</td>
                  <td :class="{ 'font-green': total.totalWinLoseOther >= 0 , 'font-red': total.totalWinLoseOther < 0}">{{ digital.format(total.totalWinLoseOther) }}</td>
                  <td></td>
                  <td>{{ digital.format(total.totalCommissionOther) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <serverPagination
            v-if="allData"
            :page="page"
            @ServerPageUtils="search"
            @changeTake="changeTake"
          ></serverPagination>
        </div>
      </div>
    </div>
  </div>
</template>
            
<script>
import { agentDayReportList , agentDayReportTotal } from "@/api/agent-management/agent-statistics";
import { transTodayDate } from "@/utils/transDateUtils";
import { searchModel } from "./model";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import TreeNode from "@/layout/components/TreeNode";
import { mapGetters } from "vuex";
import { agentTreeList } from "@/api/agent-management/agent";
import QuickSearch from "@/layout/components/QuickSearch";

import { joinT } from "@/utils/transDateUtils";

export default {
  name: "AgentStatistics",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate("from") + " 00",
      endTime: transTodayDate("to") + " 23",
      agentTotal: undefined,
      memberTotal: undefined,
      total: undefined,
      treeData: [],
      agentId: undefined,
      controlType: true,
      backAgentId: undefined,
      i18nPrefix: "agent.agent-statistics."
    };
  },
  mixins: [handleServerPageUtils],
  components: {
    serverPagination,
    TreeNode,
    QuickSearch,
    
  },
  computed: {
    ...mapGetters(["getUserinfo", "getAgentListAddDefault"]),
  },
  async created() {
    this.agentId = this.getUserinfo.parentId;
    // await this.getAgentTree();
  },
  methods: {
    async search(page = false) {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startMinute + this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endMinute + this.GLOBAL.timeZone) : undefined;
      this.searchForm.agentId = this.agentId;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? (page - 1) : 0);
      if(!page) {
        await agentDayReportTotal(this.searchForm).then((res) => {
          this.total = res.data;
        });
      }
      agentDayReportList(this.searchForm).then((res) => {
        this.allData = res.data.reports;
        this.agentTotal = res.data.agentTotal;
        this.memberTotal = res.data.memberTotal;
        this.page = res.data.page;
      });
    },
    // async getAgentTree() {
    //   await agentTreeList({ agentId: 0 }).then((res) => {
    //     this.treeData.push(res.data);
    //   });
    // },
    passAgentId(go) {
      this.backAgentId = this.getAgentListAddDefault.find(data => data.id === go)?.parentId;
      this.agentId = go;
      this.search();
    },
    changeTime(time) {
      this.startTime = time.startTime+" 00";
      this.endTime = time.endTime+" 23";
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>

main {
  .input-block {
    label {
      width: 40%;
    }
  }
  .permission-block {
    width: 100%;
    margin-top: 10px;
    margin-left: 20px;
    display: flex;
    justify-content: left;
    flex-direction: column;
    p {
      margin-bottom: 0;
    }
    .permission-box {
      label {
        margin-left: 15px;
        display: initial;
      }
    }
    input {
      height: inherit;
      padding: initial;
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
@media screen and (max-width: 1280px) {
  .agent-block {
    flex-direction: column;
    //.tree-block {
    //  width: 95%;
    //}
    .content-block {
      width: 100%;
    }
  }
  .isIgnore {
    display: inline-block !important;
  }
}

.agent {
  color: blue;
  text-decoration:underline !important;
  font-weight: bold;
  cursor: pointer;
}

.brown {
  color: #ffffff;
  background-color: #6f0000;
}

.lightBrown {
  color: #ffffff;
  background-color: #8c4600;
}

.blue {
  color: #ffffff;
  background-color: #000080;
}

.lightGreen {
  color: #ffffff;
  background-color: #848400;
}

.font-green {
  color: green;
}

.font-red {
  color: red;
}
</style>