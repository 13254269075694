import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getTemplateList() {
    return request({
        url: "/memberPrivateMessage/templateList",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function getMessageList(data) {
    return request({
        url: "/memberPrivateMessage/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMemberMessage(data) {
    return request({
        url: "/memberPrivateMessage/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createSmsMessage(data) {
    return request({
        url: "/memberPrivateMessage/smscreate",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getExcel(data) {
    return request({
        url: "/MemberPrivateMessage/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}