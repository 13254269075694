<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.onlineMemberAmount") }}</h4>
        </div>
        <div class="divider"></div>
        <div class="container-view">
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("default.member") }}</th>
                  <th>{{ $t("default.agent") }}</th>
                  <th>{{ $t("default.star") }}</th>
                  <th>{{ $t("default.tag") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!tableList">
                  <td colspan="4">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>{{ list.memberAccount }}</td>
                  <td>{{ list.agentAccount }}</td>
                  <td>{{ list.star + $t("default.star") }}</td>
                  <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                    <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage"></Pagination>
    </div>
  </div>
</template>

<script>
import { getOnlineMemberList } from "@/api/statistical-report/overview";
import Tags from "@/layout/components/tags";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";

export default {
  name: "OnlineMemberList",
  props: ["tagList", "i18nPrefix"],
  data() {
    return {
    };
  },
  components: {
    Tags,
    Pagination
  },
  mixins: [handlePageUtils],
  created() {
    getOnlineMemberList().then(res => {
      this.allData = res.data
    });
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>