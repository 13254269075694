var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.title`)))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.memberAccount,
                      expression: "editModel.memberAccount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.editModel.memberAccount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "memberAccount",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}submitTime`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.submitTime,
                      expression: "editModel.submitTime",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.editModel.submitTime },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "submitTime", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.amount")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.amount,
                      expression: "editModel.amount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.editModel.amount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "amount", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.remark,
                      expression: "editModel.remark",
                    },
                  ],
                  attrs: { placeholder: "" },
                  domProps: { value: _vm.editModel.remark },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "remark", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "advance-block" },
              [
                _vm.auditStatus !== 1 && _vm.auditStatus !== 5
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn",
                          on: {
                            click: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.editRemark.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editRemark`)))]
                      ),
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn",
                          on: {
                            click: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.accept.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.accept`))
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "delete-btn",
                          on: {
                            click: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.reject.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.reject`))
                          ),
                        ]
                      ),
                    ],
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }