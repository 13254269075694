<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(99999) &&
              !getUserinfo.permissions.includes(10401)
            "
          >{{ $t(`${i18nPrefix}create`) }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.amount") }}
            <input type="text" v-model="searchForm.amount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker
              v-model="submitStartTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker
              v-model="submitEndTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeFrom") }}
            <date-picker
              v-model="auditStartTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeTo") }}
            <date-picker
              v-model="auditEndTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}paymentName`) }}
            <select v-model="searchForm.paymentName">
              <option value>{{ $t("default.all") }}</option>
              <option
                v-for="payment of getPaymentList"
                :key="payment.name"
                :value="payment.name"
              >{{ payment.name }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}paymentOrderNumber`) }}
            <input
              type="text"
              v-model="searchForm.paymentOrderNumber"
            />
          </label>
        </div>
        <div class="divider-h-2"></div>
        <!-- <div class="condition-block">
          <multiselect
            v-model="auditStatuses"
            track-by="id"
            label="name"
            :placeholder="$t('default.plzSelect')"
            :options="getAuditStatusList"
            :custom-label="GLOBAL.i18nCheckName"
            :multiple="true"
            :searchable="false"
          ></multiselect>
          <div class="button-block">
            <button @click="auditStatuses = [...getAuditStatusList]">{{ $t("default.selectAll") }}</button>
            <button @click="auditStatuses = []">{{ $t("default.clear") }}</button>
          </div>
        </div> -->
        <MultiSelectBox :selectData.sync="auditStatuses" :list="getAuditStatusList" :multiple="true" />
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2 row-4">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalDataCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalMemberCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalMemberCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalAmount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalReceiveAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalReceivableAmount) }}</div>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}submit`) }}</th>
              <th>{{ $t("default.tag") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
              <th>{{ $t("default.agent") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}group`) }}</th>
              <th>{{ $t(`${i18nPrefix}preTradeBalance`) }}</th>
              <th>{{ $t(`${i18nPrefix}wallet`) }}</th>
              <th>{{ $t(`${i18nPrefix}bankName`) }}</th>
              <th>{{ $t(`${i18nPrefix}bankAccountNumber`) }}</th>
              <th>{{ $t(`${i18nPrefix}bankAccountName`) }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}amount`) }}</th>
              <th>{{ $t(`${i18nPrefix}feePercent`) }}</th>
              <th>{{ $t("default.currencyName") }}</th>
              <th>{{ $t("default.exchangeRate") }}</th>
              <th>{{ $t("default.receiveAmount") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
              <th>{{ $t("default.paymentName") }}</th>
              <th>{{ $t("default.paymentOrderNumber") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="20">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list,index) of allData" :key="list.id">
              <td>
                <button
                  v-if="list.auditStatus === 1"
                  @click="list.isThirdParty ? handleDialog(list, 'audit') : processing(list)"
                  class="table-btn main-style-btn"
                  :disabled="
                    !getUserinfo.permissions.includes(99999) &&
                    !getUserinfo.permissions.includes(10401)
                  "
                >{{ $t(`${i18nPrefix}processing`) }}</button>
                <button
                  v-else
                  @click="handleDialog(list, 'audit')"
                  class="table-btn main-style-btn"
                  :disabled="
                    !getUserinfo.permissions.includes(99999) &&
                    !getUserinfo.permissions.includes(10401)
                  "
                >{{ list.auditStatus === 5 ? $t(`${i18nPrefix}audit`) : $t(`${i18nPrefix}editRemark`) }}</button>
              </td>
              <td>
                {{
                GLOBAL.i18nCheckName(
                getAuditStatusList.find(
                (data) => list.auditStatus === data.id
                ))
                }}
              </td>
              <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
              </td>
              <td>{{ list.remark }}</td>
              <td>{{ list.submitTime }}</td>
              <td>{{ list.agentAccount + ' / ' + list.agentName }}</td>
              <td>
                <font-awesome-icon
                  class="copyBtn"
                  :class="{ copyed: copyText === list.memberAccount + 'Copy' + index }"
                  :icon="['fas', 'copy']"
                  @click="copy(list.memberAccount, index)"
                ></font-awesome-icon>
                {{ list.memberAccount }}
              </td>
              <td>{{ list.bankGroupName }}</td>
              <td>{{ list.preTradeBalance }}</td>
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog(list, 'Wallet')"
                  :disabled="
                      !getUserinfo.permissions.includes(99999) &&
                      !getUserinfo.permissions.includes(10115)
                    "
                >{{ $t(`${i18nPrefix}wallet`) }}</button>
              </td>
              <td>{{ `(${list.bankCode})` +list.bankName }}</td>
              <td>
                <font-awesome-icon
                  class="copyBtn"
                  :class="{ copyed: copyText === list.bankAccountNumber + 'Copy' + index }"
                  :icon="['fas', 'copy']"
                  @click="copy(list.bankAccountNumber, index)"
                ></font-awesome-icon>
                {{ list.bankAccountNumber }}
              </td>
              <td>
                <font-awesome-icon
                  class="copyBtn"
                  :class="{ copyed: copyText === list.bankAccountName + 'Copy' + index }"
                  :icon="['fas', 'copy']"
                  @click="copy(list.bankAccountName, index)"
                ></font-awesome-icon>
                {{ list.bankAccountName }}
              </td>
              <td>{{ new Intl.NumberFormat().format(list.amount) }}</td>
              <td class="red">{{ new Intl.NumberFormat().format(list.amount - list.feeAmount) }}</td>
              <td>{{ list.feePercent }}</td>
              <td>{{ list.currencyName }}</td>
              <td>{{ list.exchangeRate }}</td>
              <td>{{ list.receivableAmount }}</td>
              <td>
                <button
                  @click="getProcess(list.id)"
                  class="table-btn main-style-btn"
                >{{ $t("default.processAccount") }}</button>
              </td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.auditTime }}</td>
              <td>{{ list.paymentName }}</td>
              <td>{{ list.paymentOrderNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :thirdPartyList="thirdPartyList"
        :getGamePlatforms="getGamePlatforms"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @audit="audit"
        @editRemark="editRemark"
        @create="create"
      ></component>
    </transition>
    <Process
      v-if="processData"
      :processTitle="processTitle"
      :processData="processData"
      @toggle="processData = undefined"
    >
      <template v-slot:oldValue_AuditStatus="props">
        {{ GLOBAL.i18nCheckName(
        getAuditStatusList.find(
        (data) => props.AuditStatus === data.id
        )) }}
      </template>
      <template v-slot:newValue_AuditStatus="props">
        {{ GLOBAL.i18nCheckName(
        getAuditStatusList.find(
        (data) => props.AuditStatus === data.id
        )) }}
      </template>
    </Process>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  selectMenu,
  withdrawList,
  auditWithdraw,
  auditPayment,
  createWithdraw,
  getWithdrawTotal,
  getWithdrawalProcess
} from "@/api/member/withdraw";
import { mapGetters } from "vuex";
import Audit from "./dialog/audit";
import Process from "@/layout/components/Process";
import Create from "./dialog/create";
import Wallet from "./dialog/wallet";
import Tags from "@/layout/components/tags";
import Message from "@/layout/components/Message";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import { joinT } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "MemberWithdraw",
  components: {
    Tags,
    Audit,
    Process,
    Create,
    serverPagination,
    QuickSearch,
    Wallet,
    Multiselect,
    MultiSelectBox
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      thirdPartyList: [],
      submitStartTime: undefined,
      submitEndTime: undefined,
      auditStartTime: undefined,
      auditEndTime: undefined,
      controlType: true,
      copyText: "",
      auditStatuses: [],
      total: {
        totalDataCount: 0,
        totalMemberCount: 0,
        totalAmount: 0,
        totalReceivableAmount: 0
      },
      processData: undefined,
      i18nPrefix: "member.member-withdraw."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getAuditStatusList",
      "getUserinfo",
      "tagList",
      "getPaymentList",
      "getGamePlatforms"
    ]),
    processTitle() {
      return [
        {
          key: "AuditStatus",
          name: this.$t(`${this.i18nPrefix}submit`)
        },
        {
          key: "Remark",
          name: this.$t("default.remark")
        }
      ];
    }
  },
  created() {
    selectMenu().then(res => (this.thirdPartyList = res.data));
    this.auditStatuses = [
      this.getAuditStatusList[0],
      this.getAuditStatusList[4]
    ];
    this.search();
  },
  methods: {
    async search(page = false) {
      this.searchForm.submitStartTime = this.submitStartTime
        ? joinT(
            this.submitStartTime +
              this.GLOBAL.startSecond +
              this.GLOBAL.timeZone
          )
        : undefined;
      this.searchForm.submitEndTime = this.submitEndTime
        ? joinT(
            this.submitEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
          )
        : undefined;
      this.searchForm.auditStartTime = this.auditStartTime
        ? joinT(
            this.auditStartTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone
          )
        : undefined;
      this.searchForm.auditEndTime = this.auditEndTime
        ? joinT(
            this.auditEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
          )
        : undefined;
      this.searchForm.amount = this.searchForm.amount * 1;
      this.searchForm.auditStatuses = this.auditStatuses.map(
        data => data.id * 1
      );

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      await withdrawList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page
        this.closeDialog();
      });
      await getWithdrawTotal(this.searchForm).then(
        res => (this.total = res.data)
      );
    },
    create(data) {
      createWithdraw(data).then(res => {
        if (res) this.init();
      });
    },
    audit(data) {
      if (data.isThirdParty && data.oldStatus === 1) {
        const obj = {
          id: data.id,
          auditStatus: data.auditStatus,
          payment: data.payment,
          feePercent: data.feePercent,
          remark: data.remark
        };
        auditPayment(obj).then(res => {
          if (res) this.init();
        });
        return;
      }
      const obj = {
        id: data.id,
        auditStatus: data.auditStatus,
        feePercent: data.feePercent * 1,
        remark: data.remark
      };
      auditWithdraw(obj).then(res => {
        if (res) this.init();
      });
    },
    editRemark(data) {
      const obj = {
        id: data.id,
        auditStatus: data.auditStatus,
        remark: data.remark
      };
      auditWithdraw(obj).then(res => {
        if (res) this.init();
      });
    },
    processing(list) {
      let auditData = _.cloneDeep(list);
      auditData.auditStatus = 5;
      this.audit(auditData);
    },
    getProcess(id) {
      getWithdrawalProcess({ id }).then(res => {
        this.processData = res.data;
      });
    },
    init() {
      this.RESET_DIALOG();
      this.search();
    },
    changeTime(time) {
      this.submitStartTime = time.startTime;
      this.submitEndTime = time.endTime;
      this.search();
    },
    async copy(data, index) {
      navigator.clipboard.writeText(data);
      this.copyText = (await navigator.clipboard.readText()) + "Copy" + index;
      Message.copy(`${data} ` + this.$t("default.isCopy"), 1500);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.copyBtn {
  font-size: 1rem !important;
}
.copyBtn {
  transition: 0.5s;
  &:hover {
    color: #c700c7;
  }
}
.copyed {
  color: #c700c7;
}
</style>