<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") }}</h4>
          <button
            class="delete-btn float-right"
            :disabled="!getUserinfo.permissions.includes(20302)"
            @click="$emit('delete', { id: bankData.id })"
          >{{ $t("default.delete") }}</button>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.bankType") }}
              <select v-model="editModel.bankType" disabled>
                <option
                  v-for="list of getBankTypeList"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label></label>
            <div
              class="multiselectDiv"
              v-show="editModel.bankType === 1 || editModel.bankType === 2"
            >
              {{ $t("default.bankName") }}
              <multiselect
                disabled
                v-model="value"
                label="name"
                track-by="id"
                :placeholder="$t('default.plzSelect')"
                :custom-label="nameWithLang"
                :options="bankList"
                :allow-empty="false"
                :preselectFirst="true"
              ></multiselect>
            </div>
            <label v-show="editModel.bankType === 1 || editModel.bankType === 2">
              {{ $t("default.bankBranch") }}
              <input type="text" v-model="editModel.bankBranch" />
            </label>
            <label>
              {{ $t("default.bankAccountName") }}
              <input
                type="text"
                v-model="editModel.bankAccountName"
              />
            </label>
            <label>
              {{ $t("default.bankAccountNumber") }}
              <input
                type="text"
                v-model="editModel.bankAccountNumber"
              />
            </label>
            <label v-show="editModel.bankType === 1 || editModel.bankType === 2">
              {{ $t("default.province") }}
              <input type="text" v-model="editModel.province" />
            </label>
            <label v-show="editModel.bankType === 1 || editModel.bankType === 2">
              {{ $t("default.city") }}
              <input type="text" v-model="editModel.city" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="update">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "Edit",
  props: ["bankList", "bankData", "i18nPrefix"],
  computed: {
    ...mapGetters(["getBankTypeList", "getStatusList", "getUserinfo"])
  },
  data() {
    return {
      editModel: {
        id: this.bankData.id,
        bankBranch: this.bankData.bankBranch,
        bankAccountName: this.bankData.bankAccountName,
        bankAccountNumber: this.bankData.bankAccountNumber,
        province: this.bankData.province,
        bankType: this.bankData.bankType,
        city: this.bankData.city
      },
      value: {
        id: this.bankData.bankId,
        code: this.bankData.bankCode,
        name: this.bankData.bankName
      }
      // banksList: [],
    };
  },
  components: {
    Multiselect
  },
  methods: {
    update() {
      this.editModel.bankId = this.value.id;
      this.$emit("update", this.editModel);
    },
    nameWithLang({ name, code }) {
      return `${code}－${name}`;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.input-block {
  label {
    width: 40%;
  }
  .multiselectDiv {
    width: 40%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>