var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multiSelectBox" }, [
    _c(
      "div",
      { staticClass: "multiSelectBox-pub" },
      _vm._l(_vm.list, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "multiSelectBox-sub",
            class: _vm.selectData.some((s) => s.id == item.id)
              ? "multiSelectBox-active"
              : "",
            on: {
              click: function ($event) {
                return _vm.select(item)
              },
            },
          },
          [
            _c("div", { staticClass: "check-box" }, [
              _c("input", {
                attrs: { type: "checkBox" },
                domProps: {
                  checked: _vm.selectData.some((s) => s.id == item.id),
                },
              }),
              _c("span", { staticClass: "checkmark" }),
            ]),
            _vm._v(" " + _vm._s(_vm.GLOBAL.i18nCheckName(item)) + " "),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "multiSelectBox-btn" }, [
      _c(
        "button",
        { staticClass: "normal-btn-v2 main-style-btn", on: { click: _vm.all } },
        [_vm._v(_vm._s(_vm.$t("default.selectAll")))]
      ),
      _c(
        "button",
        {
          staticClass: "normal-btn-v2 main-style-btn",
          on: { click: _vm.clear },
        },
        [_vm._v(_vm._s(_vm.$t("default.clear")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }