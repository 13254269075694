<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <h3>{{ $t(`${i18nPrefix}id`) }}：{{ id }}</h3>
        <div v-show="checkShow">
          <button class="normal-btn mr-3" @click="handleEdit(2)">{{ $t(`${i18nPrefix}dialog.accept`) }}</button>
          <button class="delete-btn" @click="handleEdit(3)">{{ $t(`${i18nPrefix}dialog.reject`) }}</button>
        </div>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>
                  </th>
                  <th>{{ $t("default.star") }}</th>
                  <th>{{ $t("default.agent") }}</th>
                  <th>{{ $t(`${i18nPrefix}timeFrom`) }}</th>
                  <th>{{ $t(`${i18nPrefix}timeTo`) }}</th>
                  <th>{{ $t("default.amount") }}</th>
                  <th>{{ $t("default.status") }}</th>
                  <th>{{ $t(`${i18nPrefix}approveTime`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) of allData" :key="index">
                  <td>
                    <button v-show="list.auditStatus === 1" class="normal-btn" @click="handleEdit(2 , list.id)">{{ $t("default.audit") }}</button>
                  </td>
                  <td>{{ $t( "default." + (list.levelNumber ? `levelNumber` : "mainAgent") ,{ input: list.levelNumber } ) }}</td>
                  <td>{{ list.agentAccount }}</td>
                  <td>{{ list.timeFrom }}</td>
                  <td>{{ list.timeTo }}</td>
                  <td>{{ list.amount }}</td>
                  <td>{{ GLOBAL.i18nCheckName(statusList.find(data => data.id === list.auditStatus)) }}</td>
                  <td>{{ list.auditTime }}</td>
                </tr>
              </tbody>
            </table>
            <serverPagination
              v-if="allData"
              :page="page"
              @ServerPageUtils="search"
              @changeTake="changeTake"
            ></serverPagination>
          </div>
        </main>
      </div>
    </div>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
          v-if="alertMsg"
          :message="alertMsg"
          :btnText="btnText"
          @toggle="cancelEdit"
          @doIt="edit"
      />
    </transition>
  </div>
</template>

<script>
import { getCalcListdata } from "@/api/agent-management/agent-rebateCalc";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import Alert from "@/layout/components/Alert";

export default {
  name: "UpRecordEdit",
  props: ["id","i18nPrefix"],
  data() {
    return {
      defaultData: undefined,
      editModel: {
        agentRebateRecordId: this.id,
        auditStatus: undefined,
        id: undefined,
      },
      searchForm: {
        agentRebateRecordId: this.id,
        page:  {
          "take": 10,
          "skip": 0
        },
      },
      statusList: [
        {
          id: "0",
          name: "",
        },
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}dialog.pending`),
        },
        {
          id: 2,
          name: this.$t("auditStatus.approve"),
        },
        {
          id: 3,
          name: this.$t("auditStatus.reject"),
        },
      ],
      alertMsg: undefined,
      btnText: undefined,
      checkShow: 0
    };
  },
  components: {
    Alert,
    serverPagination,
  },
  mixins: [handleServerPageUtils],
  created() {
    this.search()
  },
  methods: {
    search(page = false) {
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? (page - 1) : 0);
      getCalcListdata(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
        this.checkShow = this.allData.filter(data => data.auditStatus === 1)?.length
      });
    },
    handleEdit(auditStatus, id = false) {
      this.btnText = {}
      this.editModel.id = undefined
      if(id) {
        this.editModel.id = id
        this.btnText = {
          confirm: "auditStatus.approve",
          cancel: "auditStatus.reject",
        }
      }
      this.editModel.auditStatus = auditStatus
      this.alertMsg = this.$t(`${this.i18nPrefix}dialog.alertMsg2`);
    },
    edit() {
      this.alertMsg = undefined;
      this.$emit("audit", this.editModel);
    },
    cancelEdit() {
      this.alertMsg = undefined;
      if(this.editModel.id) {
        this.editModel.auditStatus = 3
      }else {
        return
      }
      this.$emit("audit", this.editModel);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  .input-block {
    label {
      width: 40%;
    }
  }
}
</style>