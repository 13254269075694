<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.create") }}{{ $t(`title.gameInfo`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}gameName`) }}
              <input type="text" v-model="createModel.Name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}sort`) }}
              <input type="text" v-model="createModel.SortId" />
            </label>
            <label>
              GameCode
              <input type="text" v-model="createModel.GameCode" />
            </label>
            <label>
              SecondGameCode
              <input type="text" v-model="createModel.SecondGameCode" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.frontend`) }}
              <select v-model="createModel.PlatformVersion">
                <option v-for="list of platformVersions" :key="list.id" :value="list.id * 1">
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}gameCategoryName`) }}
              <select v-model="createModel.GamesCategory">
                <option v-for="list of gamesCategorys" :key="list.id" :value="list.id * 1">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}gamePlatform`) }}
              <select v-model="createModel.GamePlatform">
                <option v-for="list of gamePlatforms" :key="list.id" :value="list.id * 1">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="createModel.Status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}hotGame`) }}
              <select v-model="createModel.isHot">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}isNew`) }}
              <select v-model="createModel.isNew">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              OpenGameAction
              <input type="text" v-model="createModel.OpenGameAction" />
            </label>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('File')">
              <div v-if="!File" class="upload">
                <img class="icon" src="@/assets/icon/upload.svg" />
                <span>{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}Image</span>
              </div>
              <img v-else :src="File" />
            </div>
          </div>
          <input type="file" ref="file" class="custom-file" @change="fileSelect" />
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["companys", "gamePlatforms", "gamesCategorys", "platformVersions"],
  data() {
    return {
      createModel: {
        Name: undefined,
        GameCode: undefined,
        SecondGameCode: undefined,
        Status: 1,
        isHot: false,
        isNew: false,
        OpenGameAction: 0,
        PlatformVersion: undefined,
        GamesCategory: undefined,
        GamePlatform: undefined,
        SortId: undefined,
        Image: undefined,
      },
      formData: new FormData(),
      File: undefined,
      chooiceImg: undefined,
      i18nPrefix: "operationManagement.gameInfo.",
    };
  },
  created() {
    this.createModel.PlatformVersion = this.platformVersions[0].id;
    this.createModel.GamesCategory = this.gamesCategorys[0].id;
    this.createModel.GamePlatform = this.gamePlatforms[0].id;
  },
  methods: {
    create() {
      Object.entries(this.createModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });
      this.$emit("create", this.formData);
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      this.formData.append(this.chooiceImg, e.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";

.textarea-div {
  display: flex;
  flex-direction: column;
}

.imgDiv {
  display: flex;
  flex-wrap: wrap;

  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 200px;
    // padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;
    span {
      display: block;
      font-size: 20px;
    }

    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }

    .upload {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #749AAF;
      border: 1px solid #537081;
      border-radius: 10px;
      color: #fff;

      .icon {
        width: 60px;
      }
    }
  }
}
</style>