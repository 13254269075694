import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberBalanceAdjustmentList(data) {
    return request({
        url: "/MemberBalanceAdjustment/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMemberBalanceAdjustment(data) {
    return request({
        url: "/memberbalanceadjustment/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editMemberBalanceAdjustment(data) {
    return request({
        url: "/MemberBalanceAdjustment/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
