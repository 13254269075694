var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("default.edit") + " " + _vm.$t("default.agent"))
              ),
            ]),
            _c("div", { staticClass: "header-btn" }, [
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  attrs: { disabled: !!!_vm.subDomain || !!_vm.promotionLink },
                  on: { click: _vm.createPromotionLink },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("default.create") +
                        " " +
                        _vm.$t(`${_vm.i18nPrefix}promotionLink`)
                    )
                  ),
                ]
              ),
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.handleRemove } },
                [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.remove`)))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block up-input" }, [
              _c(
                "div",
                { staticClass: "domain" },
                [
                  _c("label", { staticClass: "checkbox-row m-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editModel.isSelfDomain,
                          expression: "editModel.isSelfDomain",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.editModel.isSelfDomain)
                          ? _vm._i(_vm.editModel.isSelfDomain, null) > -1
                          : _vm.editModel.isSelfDomain,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.editModel.isSelfDomain,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.editModel,
                                  "isSelfDomain",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.editModel,
                                  "isSelfDomain",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.editModel, "isSelfDomain", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isSelfDomain`)) +
                        " "
                    ),
                  ]),
                  _vm.isGeneralAgent
                    ? [
                        _c("span", [
                          _vm._v("https://" + _vm._s(_vm.agentDamain)),
                        ]),
                      ]
                    : [
                        _c("span", [
                          _vm._v(" https:// "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editModel.subDomain,
                                expression: "editModel.subDomain",
                              },
                            ],
                            class: { selfDomain: _vm.editModel.isSelfDomain },
                            attrs: { type: "text" },
                            domProps: { value: _vm.editModel.subDomain },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.editModel,
                                  "subDomain",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editModel.isSelfDomain
                                  ? ""
                                  : "." + _vm.agentDamain
                              ) +
                              " "
                          ),
                        ]),
                      ],
                ],
                2
              ),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.name,
                      expression: "editModel.name",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.editModel.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.account,
                      expression: "editModel.account",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.editModel.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "account", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.password`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.password,
                      expression: "editModel.password",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.editModel.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "password", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.CommissionCalculationCycle`
                      )
                    ) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.commissionCalculationCycle,
                        expression: "editModel.commissionCalculationCycle",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "commissionCalculationCycle",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.GLOBAL.settlement, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}status`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.status,
                        expression: "editModel.status",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.getStatusList, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}phoneNumber`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.phoneNumber,
                      expression: "editModel.phoneNumber",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.editModel.phoneNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "phoneNumber",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionLink`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.promotionLink,
                      expression: "promotionLink",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.promotionLink },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.promotionLink = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}discountCommissionRate`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.discountCommissionRate,
                      expression: "editModel.discountCommissionRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.discountCommissionRate },
                  on: {
                    keyup: function ($event) {
                      _vm.editModel.discountCommissionRate =
                        _vm.GLOBAL.checkValue(
                          _vm.editModel.discountCommissionRate,
                          2
                        )
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "discountCommissionRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}proxyAgent`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.proxyAgentId,
                        expression: "editModel.proxyAgentId",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "proxyAgentId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null * 1 } }, [
                      _vm._v(_vm._s(_vm.$t("default.none"))),
                    ]),
                    _vm._l(_vm.proxyAgentList, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id } },
                        [_vm._v(_vm._s(_vm.$t(list.account)))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("div", { staticClass: "divider-h-2" }),
            _c("div", { staticClass: "permission-block" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.permissions`))),
              ]),
              _c(
                "div",
                { staticClass: "permission-box" },
                _vm._l(_vm.getPermissionsTitle, function (list, index) {
                  return _c(
                    "div",
                    { key: list.id, staticClass: "permission-list" },
                    [
                      _c("ul", [
                        _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editModel.permissions,
                                  expression: "editModel.permissions",
                                },
                              ],
                              attrs: {
                                name: "checkBox",
                                id: "main" + list.name,
                                type: "checkbox",
                              },
                              domProps: {
                                value: list.id,
                                checked: Array.isArray(
                                  _vm.editModel.permissions
                                )
                                  ? _vm._i(_vm.editModel.permissions, list.id) >
                                    -1
                                  : _vm.editModel.permissions,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkAll("main" + list.name)
                                },
                                change: function ($event) {
                                  var $$a = _vm.editModel.permissions,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = list.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.editModel,
                                          "permissions",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.editModel,
                                          "permissions",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.editModel, "permissions", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Permission." + list.name)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.firstData[index],
                              function (firstTree, firstIndex) {
                                return [
                                  _c("li", { key: firstTree.id }, [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.editModel.permissions,
                                            expression: "editModel.permissions",
                                          },
                                        ],
                                        class: [
                                          "main" + list.name,
                                          "parent" + list.name,
                                        ],
                                        attrs: {
                                          name: "checkBox",
                                          id: firstTree.name,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          value: firstTree.id,
                                          checked: Array.isArray(
                                            _vm.editModel.permissions
                                          )
                                            ? _vm._i(
                                                _vm.editModel.permissions,
                                                firstTree.id
                                              ) > -1
                                            : _vm.editModel.permissions,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkAll(
                                              firstTree.name,
                                              "main" + list.name
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.editModel.permissions,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = firstTree.id,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.editModel,
                                                    "permissions",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.editModel,
                                                    "permissions",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.editModel,
                                                "permissions",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "Permission." + firstTree.name
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.secData[index][firstIndex],
                                      function (secTree, secIndex) {
                                        return _c(
                                          "li",
                                          { key: _vm.secData.id },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    secIndex ===
                                                      _vm.secData[index][
                                                        firstIndex
                                                      ].length -
                                                        1
                                                      ? "└"
                                                      : "├"
                                                  ) +
                                                  " "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.editModel.permissions,
                                                    expression:
                                                      "editModel.permissions",
                                                  },
                                                ],
                                                class: [
                                                  "main" + list.name,
                                                  firstTree.name,
                                                  "parent" + firstTree.name,
                                                ],
                                                attrs: {
                                                  name: "checkBox",
                                                  type: "checkbox",
                                                },
                                                domProps: {
                                                  value: secTree.id,
                                                  checked: Array.isArray(
                                                    _vm.editModel.permissions
                                                  )
                                                    ? _vm._i(
                                                        _vm.editModel
                                                          .permissions,
                                                        secTree.id
                                                      ) > -1
                                                    : _vm.editModel.permissions,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.checkAll(
                                                      false,
                                                      firstTree.name,
                                                      "main" + list.name
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.editModel
                                                          .permissions,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = secTree.id,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.editModel,
                                                            "permissions",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.editModel,
                                                            "permissions",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.editModel,
                                                        "permissions",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Permission." +
                                                        secTree.name
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "input-block rebate-input" }, [
              _c(
                "div",
                { staticClass: "settingList" },
                [
                  _c("label", { staticClass: "isUpdateChildren" }, [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.rebate`))),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.isUpdateChildren`)
                        ) +
                        " "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "slide-block",
                        class: {
                          "slide-block-on": _vm.editModel.isUpdateRebate,
                        },
                        staticStyle: { margin: "auto" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "slide",
                            class: { "slide-on": _vm.editModel.isUpdateRebate },
                            on: { click: _vm.toggleSlide },
                          },
                          [
                            _c("label", {
                              staticStyle: { display: "none" },
                              attrs: { for: "isUpdateRebate" },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editModel.isUpdateRebate,
                                  expression: "editModel.isUpdateRebate",
                                },
                              ],
                              staticStyle: { display: "none" },
                              attrs: { type: "checkbox", id: "isUpdateRebate" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.editModel.isUpdateRebate
                                )
                                  ? _vm._i(_vm.editModel.isUpdateRebate, null) >
                                    -1
                                  : _vm.editModel.isUpdateRebate,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.editModel.isUpdateRebate,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.editModel,
                                          "isUpdateRebate",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.editModel,
                                          "isUpdateRebate",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.editModel,
                                      "isUpdateRebate",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._l(_vm.getGamesCategorys, function (list) {
                    return _c("label", { key: list.id }, [
                      _vm._v(" " + _vm._s(_vm.$t(list.name)) + " % "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.editModel.rebateSettingList.find(
                              (data) => data.gamesCategoryId === list.id
                            ).rate,
                            expression:
                              "editModel.rebateSettingList.find(data => data.gamesCategoryId === list.id).rate",
                            modifiers: { number: true },
                          },
                        ],
                        attrs: { type: "number" },
                        domProps: {
                          value: _vm.editModel.rebateSettingList.find(
                            (data) => data.gamesCategoryId === list.id
                          ).rate,
                        },
                        on: {
                          keyup: function ($event) {
                            _vm.editModel.rebateSettingList.find(
                              (data) => data.gamesCategoryId === list.id
                            ).rate = _vm.GLOBAL.checkValue(
                              _vm.editModel.rebateSettingList.find(
                                (data) => data.gamesCategoryId === list.id
                              ).rate,
                              2
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.editModel.rebateSettingList.find(
                                (data) => data.gamesCategoryId === list.id
                              ),
                              "rate",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "settingList" },
                [
                  _c("label", { staticClass: "isUpdateChildren" }, [
                    _c("h2", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.commission`))
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.isUpdateChildren`)
                        ) +
                        " "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "slide-block",
                        class: {
                          "slide-block-on": _vm.editModel.isUpdateCommission,
                        },
                        staticStyle: { margin: "auto" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "slide",
                            class: {
                              "slide-on": _vm.editModel.isUpdateCommission,
                            },
                            on: { click: _vm.toggleSlide },
                          },
                          [
                            _c("label", {
                              staticStyle: { display: "none" },
                              attrs: { for: "isUpdateCommission" },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editModel.isUpdateCommission,
                                  expression: "editModel.isUpdateCommission",
                                },
                              ],
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "checkbox",
                                id: "isUpdateCommission",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.editModel.isUpdateCommission
                                )
                                  ? _vm._i(
                                      _vm.editModel.isUpdateCommission,
                                      null
                                    ) > -1
                                  : _vm.editModel.isUpdateCommission,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.editModel.isUpdateCommission,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.editModel,
                                          "isUpdateCommission",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.editModel,
                                          "isUpdateCommission",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.editModel,
                                      "isUpdateCommission",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._l(_vm.getGamesCategorys, function (list) {
                    return _c("label", { key: list.id }, [
                      _vm._v(" " + _vm._s(_vm.$t(list.name)) + " % "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.editModel.commissionSettingList.find(
                              (data) => data.gamesCategoryId === list.id
                            ).rate,
                            expression:
                              "editModel.commissionSettingList.find(data => data.gamesCategoryId === list.id).rate",
                            modifiers: { number: true },
                          },
                        ],
                        attrs: { type: "number" },
                        domProps: {
                          value: _vm.editModel.commissionSettingList.find(
                            (data) => data.gamesCategoryId === list.id
                          ).rate,
                        },
                        on: {
                          keyup: function ($event) {
                            _vm.editModel.commissionSettingList.find(
                              (data) => data.gamesCategoryId === list.id
                            ).rate = _vm.GLOBAL.checkValue(
                              _vm.editModel.commissionSettingList.find(
                                (data) => data.gamesCategoryId === list.id
                              ).rate,
                              2
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.editModel.commissionSettingList.find(
                                (data) => data.gamesCategoryId === list.id
                              ),
                              "rate",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    mousedown: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.remove,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }