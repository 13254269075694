<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
          >{{ $t("default.create") }}</button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}version`) }}</th>
                <th>{{ $t(`${i18nPrefix}country`) }}</th>
                <th>{{ $t(`${i18nPrefix}lang`) }}</th>
                <th>{{ $t(`${i18nPrefix}pattern`) }}</th>
                <th>{{ $t(`${i18nPrefix}checkTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" list of tableList " :key="list.id">
                <td>
                  <button
                    class="table-btn main-style-btn"
                    @click="handleDialog({ list: list }, 'Edit')"
                  >{{ $t("default.edit") }}</button>
                </td>
                <td>{{ (list.version || '')  + " " + GLOBAL.i18nCheckName(deviceType.find(data => data.id === list.deviceType)) }}</td>
                <td>{{ JSON.parse(list.country).countrys }}</td>
                <td>{{ JSON.parse(list.lang).langs }}</td>
                <td>{{ GLOBAL.i18nCheckName(pattern.find( data => data.id === list.pattern)) }}</td>
                <td>{{ list.pattern === 1 ? list.firstInstallTime : list.pattern === 2 ? list.lastInstallTime : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :langs="langs"
        :countrys="countrys"
        :pattern="pattern"
        :deviceType="deviceType"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  appVersionSettingSelectMenu,
  getAppVersionSettingList,
  createAppVersionSetting,
  editAppVersionSetting
} from "@/api/features/appVersionSetting";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "AppVersionSetting",
  data() {
    return {
      countrys: [],
      langs: [],
      i18nPrefix: "features.appVersionSetting."
    };
  },
  components: {
    Create,
    Edit
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    pattern() {
      return [
        {
          id: 0,
          name: `${this.i18nPrefix}type1`
        },
        {
          id: 1,
          name: `${this.i18nPrefix}type2`
        },
        {
          id: 2,
          name: `${this.i18nPrefix}type3`
        }
      ];
    },
    deviceType() {
      return [
        {
          id: 1,
          name: "Mobile"
        },
        {
          id: 2,
          name: "Web"
        },
        {
          id: 3,
          name: "IOS"
        },
        {
          id: 4,
          name: "Android"
        },
        {
          id: 5,
          name: "Oppo"
        },
        {
          id: 6,
          name: "Vivo"
        },
        {
          id: 7,
          name: "Apkpure"
        }
      ];
    }
  },
  created() {
    appVersionSettingSelectMenu().then(res => {
      this.langs = res.data.lang;
      this.countrys = res.data.country;
    });
    this.getList();
  },
  methods: {
    getList() {
      getAppVersionSettingList({}).then(res => {
        this.allData = res.data;
        this.RESET_DIALOG();
      });
    },
    create(data) {
      createAppVersionSetting(data).then(res => {
        if (res) this.getList();
      });
    },
    edit(data) {
      editAppVersionSetting(data).then(res => {
        if (res) this.getList();
      });
    }
    // dataParse(data) {
    //   let newData = JSON.parse(data);
    //   Object.values(JSON.parse(data)).forEach(item => {
    //     Object.values(item).forEach(data => {
    //       newData += data
    //     });
    //   });
    //   return newData;
    // }
  }
};
</script>

<style lang="scss" scoped>
.advance-block {
  select {
    width: 200px;
    margin-left: 15px;
  }
}
</style>