import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function paymentList() {
    return request({
        url: "/member/deposit_payment/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function depositStoreList(data) {
    return request({
        url: "/member/deposit_payment/QueryPage",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditDepositStore(data) {
    return request({
        url: "/member/deposit_payment/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositPaymentTotal(data) {
    return request({
        url: "/member/deposit_payment/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function depositCryptoList(data) {
    return request({
        url: "/MemberDepositCryptoPayment/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditDepositCrypto(data) {
    return request({
        url: "/MemberDepositCryptoPayment/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositCryptoTotal(data) {
    return request({
        url: "/MemberDepositCryptoPayment/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}