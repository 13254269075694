var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`default.create`)) + " ")]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}maintain`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}sort`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}imageLink`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}mobileImageLink`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}videoLink`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createDate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateDate`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.tableList || _vm.tableList.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "normal-btn",
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(list, "Edit")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(list.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.delete")) + " ")]
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            list.isMaintain
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.sort))]),
                      _c(
                        "td",
                        {
                          staticClass: "url",
                          attrs: { title: list.imageUrl },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(
                                { image: list.imageUrl },
                                "Photo"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.preview")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "url",
                          attrs: { title: list.mobileImageUrl },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(
                                { image: list.mobileImageUrl },
                                "Photo"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.preview")))]
                      ),
                      _c("td", [_vm._v(_vm._s(list.url))]),
                      _c("td", [_vm._v(_vm._s(list.createTime))]),
                      _c("td", [_vm._v(_vm._s(list.updateTime))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.deleteVideoSetting,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }