<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" style="margin: auto" @click="handleCalc()"
            :disabled="!rebate.discountSettingId || !$store.getters.getUserinfo.permissions.includes(10701)">{{
              $t(`${i18nPrefix}handleCalc`) }}</button>
        </div>
      </div>
      <main>
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}betTimeFrom`) }}
            <input type="date" step="1" v-model="startTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}betTimeTo`) }}
            <input type="date" step="1" v-model="endTime" />
          </label>
          <label>
            <span>{{ $t(`${i18nPrefix}discountSetting`) }}</span>
            <select v-model="rebate.discountSettingId">
              <option v-show="!rebate.discountSettingId" :value="undefined">{{ $t(`${i18nPrefix}discountUnSetting`) }}
              </option>
              <option v-for="list of discountList" :key="list.id" :value="list.id">{{ list.name }}</option>
            </select>
          </label>
          <label>
            <span>{{ $t("default.member") }}</span>
            <select v-model="rebate.isMember">
              <option :value="false">{{ $t("default.agent") }}</option>
              <option :value="true">{{ $t("member.member-discount.dialog.selfFill") }}</option>
            </select>
          </label>
          <div class="condition-block">
            {{ $t("default.remark") }}
            <textarea v-model="rebate.remark"></textarea>
          </div>
          <div v-show="rebate.isMember" class="condition-block">
            <textarea v-model="members"
              :placeholder="$t('member.member-discount.dialog.placeholder')"></textarea>
          </div>
          <div class="condition-block" v-show="!rebate.isMember">
            {{ $t(`${i18nPrefix}ignoreAgentIds`) }}
            <multiselect v-model="ignoreAgentIds" :placeholder="$t('default.plzSelect')" :options="options"
              group-values="agentList" group-label="all" :group-select="true" label="name" track-by="id"
              :multiple="true" :preserve-search="true" :close-on-select="false" @search-change="mulSearch">
            </multiselect>
            <div class="button-block">
              <button class="normal-btn-v2 main-style-btn" @click="ignoreAgentIds = [...getAgentList]">{{ $t("default.selectAll") }}</button>
              <button class="normal-btn-v2 main-style-btn" @click="ignoreAgentIds = []">{{ $t("default.clear") }}</button>
            </div>
          </div>
        </div>
        <div class="table-block">
          <table class="mt-3">
            <thead>
              <tr>
                <th>{{ $t(`${i18nPrefix}discountType`) }}</th>
                <th>{{ $t(`${i18nPrefix}betTimeFrom`) }}</th>
                <th>{{ $t(`${i18nPrefix}betTimeTo`) }}</th>
                <th>{{ $t("default.admin") }}</th>
                <th>{{ $t(`${i18nPrefix}approveTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(list, index) of allData" :key="index">
                <td>{{ list.discountSettingName }}</td>
                <td>{{ list.timeFrom }}</td>
                <td>{{ list.timeTo }}</td>
                <td>{{ list.createAccount }}</td>
                <td>{{ list.createTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <serverPagination v-if="allData" :page="page" @ServerPageUtils="getRebateList" @changeTake="changeTake" />
      </main>
    </div>
    <!-- <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @rebateWeeklyCalc="rebateWeekly"
      ></component>
    </transition>-->
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" @toggle="alertMsg = undefined" @doIt="rebateWeekly" />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import { rebateWeekly, getDiscountList, rebateList } from "@/api/member/rebate";
// import rebateWeeklyCalc from "./dialog/rebateWeeklyCalc";
import handleDialog from "@/layout/mixins/handleDialog";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import Alert from "@/layout/components/Alert";
import Message from "@/layout/components/Message";
import { transYestetday } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import { checkValue } from "@/utils/checkData";
import Multiselect from "vue-multiselect";

export default {
  name: "Rebate",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transYestetday(),
      endTime: transYestetday(),
      starLevelList: undefined,
      rebate: {
        startTime: undefined,
        endTime: undefined,
        discountSettingId: undefined,
        ignoreAgentIds: [],
        isMember: false,
        remark: undefined
      },
      members: undefined,
      ignoreAgentIds: [],
      calaData: [],
      discountList: [],
      alertMsg: undefined,
      options: [
        {
          all: "Select All",
          agentList: []
        }
      ],
      i18nPrefix: "member.rebate."
    };
  },
  components: {
    Alert,
    Multiselect,
    serverPagination
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getAgentList"])
  },
  created() {
    this.getRebateList();
    this.options[0].agentList = this.getAgentList;
    getDiscountList().then(res => {
      this.discountList = res.data;
      this.rebate.discountSettingId = this.discountList[0]?.id;
    });
  },
  methods: {
    mulSearch(data) {
      this.options[0].agentList = this.getAgentList.filter(agent =>
        agent.name.match(data)
      );
    },
    getRebateList(page = false) {
      this.dialogData = undefined;
      this.RESET_DIALOG();
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      rebateList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    handleCalc() {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
    },
    rebateWeekly() {
      this.alertMsg = undefined;
      if (this.startTime === undefined || this.endTime === undefined) {
        Message.error(this.$t(`${this.i18nPrefix}alertMsg2`), 1000);
        return;
      }
      this.rebate.startTime =
        this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone;
      this.rebate.endTime =
        this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone;

      if (!this.rebate.isMember) {
        this.rebate.ignoreAgentIds = this.ignoreAgentIds.map(
          data => data.id * 1
        );
        this.rebate.memberAccounts = undefined;
      } else {
        if (!checkValue(this.members, this.$t("default.member"))) return;
        this.rebate.ignoreAgentIds = undefined;
        this.rebate.memberAccounts = this.members.split("#");
      }

      rebateWeekly(this.rebate).then(res => {
        if (res) this.getRebateList();
      });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
main {
  label {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .input-block {
    label,
    div {
      width: 100% !important;
      margin: 5px auto;
    }
  }
  .condition-block {
    .button-block {
      justify-content: center;
    }
  }
}
</style>