<template>
  <div>
    <div class="container-view">
      <div class="head-box">
        <div class="header justify-content-start m-0">
          <h5 class="mr-3">{{ $t("title." + $route.meta.title) }}</h5>
          <div
            v-show="total.totalOnlineCount"
            :disabled="!getUserinfo.permissions.includes(160300)"
            @click="handleDialog(undefined, 'onlineMemberList')"
            class="onlinePeople"
          >{{ $t("default.onlineMemberAmount") + ':' + total.totalOnlineCount + $t("default.people") }}</div>
        </div>
        <div class="advance-block-v2">
          <QuickSearch ref="quickSearch" @changeTime="changeTime"></QuickSearch>
        </div>
      </div>
      <div class="main">
        <div class="form-box mt-3 gap-20">
          <div class="total totalTopBox form-box-3x1 bg-white">
            <div class="form-box-half form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalRegistryCount`) }}</div>
                <div class="total-top-num">{{ digital.format(total.totalRegistryCount) }}</div>
              </div>
            </div>
            <div class="form-box-half form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalActiveCount`) }}</div>
                <div class="total-top-num">{{ digital.format(total.totalActiveCount) }}</div>
              </div>
            </div>
          </div>
          <div class="total totalTopBox form-box-max bg-white">
            <div class="form-box-3x1 form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalFirstDepositAmount`) }}</div>
                <div class="total-top-num">{{ digital.format(total.totalFirstDepositAmount) }}</div>
              </div>
            </div>
            <div class="form-box-3x1 form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalFirstDepositCount`) }}</div>
                <div class="total-top-num">{{ digital.format(total.totalFirstDepositMemberCount) }}</div>
              </div>
            </div>
            <div class="form-box-3x1 form-column total-top">
              <div class="total-center">
                <div class="total-top-title">{{ $t(`${i18nPrefix}totalRepeatDepositCount`) }}</div>
                <div class="total-top-num">{{ digital.format(total.totalRepeatedDepositMemberCount) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="main">
        <div class="form-box gap-10" style="align-items: stretch;">
          <div class="total form-box-max form-column gap-10">
            <div class="form-box gap-10">
              <div class="card-blue form-box-3x1 px-3">
                <div class="total-card">
                  <div class="total-card-title">{{ $t(`${i18nPrefix}totalDepositAmount`) }}</div>
                  <div class="total-card-num">
                    <span>{{ total.totalDepositMemberCount + $t("default.people") + "｜" + total.totalDepositCount + $t("default.records") }}</span>
                    {{ digital.format(total.totalDepositAmount) }}
                  </div>
                </div>
              </div>
              <div class="card-purple form-box-3x1 px-3">
                <div class="total-card">
                  <div class="total-card-title">{{ $t(`${i18nPrefix}totalDiscountAmount`) }}</div>
                  <div class="total-card-num">
                    <span>{{ total.totalDiscountMemberCount + $t("default.people") + "｜" + total.totalDiscountCount + $t("default.records") }}</span>
                    {{ digital.format(total.totalDiscountAmount) }}
                  </div>
                </div>
              </div>
              <div class="card-green form-box-3x1 px-3">
                <div class="total-card">
                  <div class="total-card-title">{{ $t(`${i18nPrefix}totalAdjustmentAddAmount`) }}</div>
                  <div class="total-card-num">
                    <span>{{ total.totalAdjustmentAddMemberCount + $t("default.people") + "｜" + total.totalAdjustmentAddCount + $t("default.records") }}</span>
                    {{ digital.format(total.totalAdjustmentAddAmount) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-box gap-10">
              <div class="card-red form-box-3x1 px-3">
                <div class="total-card">
                  <div class="total-card-title">{{ $t(`${i18nPrefix}totalWithdrawalAmount`) }}</div>
                  <div class="total-card-num">
                    <span>{{ total.totalWithdrawalMemberCount + $t("default.people") + "｜" + total.totalWithdrawalCount + $t("default.records") }}</span>
                    {{ digital.format(total.totalWithdrawalAmount) }}
                  </div>
                </div>
              </div>
              <div class="card-grey form-box-3x1 px-3">
                <div class="total-card">
                  <div class="total-card-title">{{ $t(`${i18nPrefix}totalFeeAmount`) }}</div>
                  <div class="total-card-num">
                    <span>{{ total.totalFeeCount + $t("default.records") }}</span>
                    {{ digital.format(total.totalFeeAmount) }}
                  </div>
                </div>
              </div>
              <div class="card-pink form-box-3x1 px-3">
                <div class="total-card">
                  <div class="total-card-title">{{ $t(`${i18nPrefix}totalAdjustmentReduceAmount`) }}</div>
                  <div class="total-card-num">
                    <span>{{ total.totalAdjustmentReduceMemberCount + $t("default.people") + "｜" + total.totalAdjustmentReduceCount + $t("default.records") }}</span>
                    {{ digital.format(total.totalAdjustmentReduceAmount) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-box-3x1">
            <div class="total form-box form-column total-game-box">
              <div class="total-game total-game-head">
                <div class="total-game-title">{{ $t(`${i18nPrefix}totalWinLose`) }}</div>
                <div class="total-game-num">{{ digital.format(total.totalWinLose) }}</div>
              </div>
              <div class="total-game-divider"></div>
              <div class="total-game total-game-row">
                <div class="total-game-title">{{ $t(`${i18nPrefix}memberBetCount`) }}</div>
                <div class="total-game-num">{{ digital.format(total.memberBetCount) }}</div>
              </div>
              <div class="total-game total-game-row">
                <div class="total-game-title">{{ $t(`${i18nPrefix}totalBetCount`) }}</div>
                <div class="total-game-num">{{ digital.format(total.totalBetCount) }}</div>
              </div>
              <div class="total-game total-game-row">
                <div class="total-game-title">{{ $t(`${i18nPrefix}totalBetAmount`) }}</div>
                <div class="total-game-num">{{ digital.format(total.totalBetAmount) }}</div>
              </div>
              <div class="total-game total-game-row">
                <div class="total-game-title">{{ $t(`${i18nPrefix}totalValidBetAmount`) }}</div>
                <div class="total-game-num">{{ digital.format(total.totalValidBetAmount) }}</div>
              </div>
              <div class="total-game total-game-row">
                <div class="total-game-title">{{ $t(`${i18nPrefix}totalPayoutAmount`) }}</div>
                <div class="total-game-num">{{ digital.format(total.totalPayoutAmount) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="overview-table mt-4 gap-10">
          <div class="profitGames overview-table-border">
            <ProfitGames :list="list.profitGames" :i18nPrefix="i18nPrefix"></ProfitGames>
          </div>
          <div class="lossGames overview-table-border">
            <LossGames :list="list.lossGames" :i18nPrefix="i18nPrefix"></LossGames>
          </div>
          <div class="profitMembers overview-table-border">
            <ProfitMembers :list="list.profitMembers" :i18nPrefix="i18nPrefix"></ProfitMembers>
          </div>
          <div class="lossMembers overview-table-border">
            <LossMembers :list="list.lossMembers" :i18nPrefix="i18nPrefix"></LossMembers>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :tagList="tagList" :is="currDialog" v-bind="dialogData"
        @toggle="closeDialog"></component>
    </transition>
  </div>
</template>

<script>
import {
  getDataOverviewTotal,
  getList
} from "@/api/statistical-report/overview";
import { transToday, joinT } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import onlineMemberList from "./dialog/onlineMemberList";
import LossGames from "./list/lossGames";
import LossMembers from "./list/lossMembers";
import ProfitGames from "./list/profitGames";
import ProfitMembers from "./list/profitMembers";
import { mapGetters } from "vuex";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "Overview",
  data() {
    return {
      total: {},
      list: {},
      searchForm: {
        startTime: undefined,
        endTime: undefined
      },
      startTime: transToday("from") + this.GLOBAL.startSecond,
      endTime: transToday("to") + this.GLOBAL.endSecond,
      i18nPrefix: "statistical-report.overview.",
      digital: new Intl.NumberFormat()
    };
  },
  mixins: [handleDialog],
  watch: {},
  components: {
    QuickSearch,
    onlineMemberList,
    LossGames,
    LossMembers,
    ProfitGames,
    ProfitMembers
  },
  computed: {
    ...mapGetters([
      "tagList",
      "getUserinfo"
    ])
  },
  created() {
    this.$nextTick(function () {
      setTimeout(() => {
        this.$refs.quickSearch.changeDate("month");
      }, 200);
    });
  },
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.timeZone)
        : undefined;

      await getDataOverviewTotal(this.searchForm).then(res => {
        this.total = res.data;
      });

      await getList(this.searchForm).then(res => {
        this.list = res.data;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime + this.GLOBAL.startSecond;
      this.endTime = time.endTime + this.GLOBAL.endSecond;
      this.search();
    }
  }
};
</script>

<style lang="scss">
.head-box {
  display: flex;
  justify-content: space-between;
}

.overview-table {
  width: 100%;

  .table-block {
    width: 100%;

    table {
      thead {
        th {
          border: 0;
          // background: #ebedee;
        }
      }

      tbody {
        tr:nth-child(odd) {
          background: #f6fdff;
        }
      }
    }
  }
}

.overview-list-rwd {
  display: flex;
}

@media screen and (max-width: 1440px) {
  .overview-list-rwd {
    flex-direction: column;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

.gap-10 {
  gap: 10px;
}
.gap-20 {
  gap: 20px;
}

.total {
  .total-top-title {
    color: rgb(0 0 0 / 50%);
    font-size: 14px;
    font-weight: 700;
  }

  .total-top-num {
    color: rgb(0 0 0 / 50%);
    font-size: 20px;
  }

  .total-top {
    border-right: 1.5px solid #7E96A3;
  }

  .total-top:last-child {
    border-right: 0;
  }

  .total-card {
    color: #fff;
    font-size: 16px;
    display: flex;
    padding: 20px 0px;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    .total-card-num {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      span {
        font-size: 10px;
      }
    }
  }

  .total-game-head {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: right;
    .total-game-title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }

    .total-game-num {
      font-size: 26px;
      font-weight: bold;
    }
  }
  .total-game-divider {
    background-color: #CD7B00;
    position: relative;
    height: 1px;
    width: 100%;
    margin: 5px 0 10px 0;
  }

  .total-game-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .total-game-title {
      font-size: 14px;
    }

    .total-game-num {
      font-size: 16px;
    }
  }

  .total-center {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-blue {
    background: #749AAF;
    border: 1.5px solid #516875;
    border-radius: 8px;
  }

  .card-purple {
    background: #CD7B00;
    border: 1.5px solid #8C5400;
    border-radius: 8px;
  }

  .card-green {
    background: #3D4F59;
    border: 1.5px solid #1B2428;
    border-radius: 8px;
  }

  .card-red {
    background: #AF4C4C;
    border: 1.5px solid #950900;
    border-radius: 8px;
  }

  .card-grey {
    background: #779C0C;
    border: 1.5px solid #51690E;
    border-radius: 8px;
  }

  .card-pink {
    background: #7A7A7A;
    border: 1.5px solid #3A3A3A;
    border-radius: 8px;
  }
}

.totalTopBox {
  border: 1.5px solid #7E96A3;
  border-radius: 8px;
}

.total-game-box {
  background-color: #fff;
  border-radius: 8px;
  border: 1.5px solid #CD7B00;
  padding: 8px 15px 2px 15px;
}

.time-block {
  margin-top: 0 !important;
}

.onlinePeople {
  background-color: #FFCC81;
  border-radius: 50px;
  color: #000;
  padding: 5px 15px;
  font-size: 13px;
  font-weight: bold;
}

.overview-table {
  display: flex;
  &-border {
    border-radius: 8px;
    border: 1.5px solid #7E96A3;
    background: #FFF;
    padding: 15px 20px;
    overflow: hidden;
    height: 250px;
  }
  .profitGames {
    flex-basis: 20%;
  }
  .lossGames {
    flex-basis: 20%;
  }
  .profitMembers {
    flex-basis: 30%;
  }
  .lossMembers {
    flex-basis: 30%;
  }
}

@media screen and (max-width: 768px) {
  .head-box {
    flex-direction: column;
    gap: 5px;
  }

  .total-center {
    margin-bottom: 10px;
  }

  .total-top {
    border-right: 0 !important;
    border-bottom: 1.5px solid #7E96A3;
  }

  .total-top:last-child {
    border-bottom: 0;
  }

  .overview-table {
    display: flex;
    flex-direction: column;
    &-border {
      border-radius: 8px;
      border: 1.5px solid #7E96A3;
      background: #FFF;
      padding: 15px 20px;
      overflow: hidden;
      height: 250px;
    }
    .profitGames {
      flex-basis: 100%;
    }
    .lossGames {
      flex-basis: 100%;
    }
    .profitMembers {
      flex-basis: 100%;
    }
    .lossMembers {
      flex-basis: 100%;
    }
  }
}
</style>