<template>
  <div>
    <div v-if="showColumnCheckbox" class="table-column">
      <div class="table-column-icon" @click="clickColumnBox">
        <img src="@/assets/icon/column.svg" />
        <img src="@/assets/icon/down.svg" />
      </div>
      <div ref="tableColumnBox" class="table-column-box">
        <div v-for="c of columnTmp" class="table-column-box-item" v-if="c.name !== ''">
          <!-- <input :id="c.key" type="checkbox" v-model="c.isShow" @click="changeColumnTmp(c)" />
          <label :for="c.key">{{ c.name }}</label> -->
          <label class="checkBox-label">
            <input
              name="checkBox"
              :id="c.key"
              type="checkbox"
              v-model="c.isShow"
              @click="changeColumnTmp(c)"
            />
            <span class="checkmark"></span>
            {{ c.name }}
          </label>
        </div>
      </div>
    </div>
    <div class="bookmark mt-2">
      <span
        v-for="list of bookmarkColumn"
        :class="{ chooice: (bookmark === list.key) }"
        @click="changeBookmark(list.key)"
        v-show="list.name !== 'bookmark'"
      >{{ $t(list.name) }}</span>
    </div>
    <div class="table-sticky-wrapper">
      <table class="tree-table" :class="{ chooice: bookmark }">
        <thead>
          <tr>
            <th v-if="showCheckbox" width="20">
              <input type="checkbox" class="checkbox" v-model="allCheck" />
            </th>
            <th
              v-for="c of columnArray"
              :key="c.key"
              :width="c.width"
              :style="`text-align: ${c.tableHeadAlign ? '' : c.align}; cursor: ${c.sort ? 'pointer' : 'text'};`"
              @click="c.sort !== undefined ? sortFn(c.key , c.sortType) : ''"
            >
              {{ c.name }}
              <span
                v-if="c.sort !== undefined && sort.column === c.key"
                class="tree-table-sort"
                :class="c.key === sort.column ? ['tree-table-sort-active'] : ['tree-table-sort-inactive']"
              >
                <font-awesome-icon
                  :icon="sort.order
                  ? ['fas', 'sort-amount-up']
                  : ['fas', 'sort-amount-down']
                "
                ></font-awesome-icon>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="treeList.length > 0 && header.length > 0"
            v-for="data of header"
            :key="data.key"
          >
            <td v-if="data.colspan" :colspan="data.colspan"></td>
            <td
              v-for="c of data.column"
              :key="c.key"
              :style="c.align ? `text-align: ${c.align};` : ''"
            >
              <slot
                :name="`header_${data.key}_column_${c.key}`"
                v-bind="c"
              >{{ !isNaN(c.value) && c.value ? digital.format(c.value) : c.value }}</slot>
            </td>
          </tr>
          <tr
            v-if="treeList.length > 0"
            v-for="(data, index) of treeList"
            :key="index"
            v-show="data.show"
          >
            <td v-if="showCheckbox">
              <input type="checkbox" class="checkbox" v-model="data.checkbox" />
            </td>
            <td
              v-for="(c, i) of columnArray"
              :key="c.key"
              :class="i === 0 ? '' : ''"
              :style="c.align ? `text-align: ${c.align};` : ''"
            >
              <div v-if="i === 0" class="first-column">
                <div v-if="isHaveChildren" class="first-column-box">
                  <div class="level-box" :style="`width: ${levelBoxWidth * data.level}px`"></div>
                  <i
                    v-if="data.isChildren && !data.showChildren && showFold"
                    class="fas fa-angle-right tree-icon"
                    @click="stretch(index)"
                  ></i>
                  <i
                    v-else-if="data.isChildren && data.showChildren && showFold"
                    class="fas fa-minus tree-icon"
                    @click="shrink(index)"
                  ></i>
                  <div v-else-if="showFold" class="tree-icon tree-icon-not"></div>
                  <div
                    v-if="showStar"
                    class="tree-tag"
                    :class="data.level === 0 ? 'tree-tag-main' : 'tree-tag-children'"
                  >{{ (data.level === 0) ? $t('default.mainAgent') : data.level + $t('default.star') }}</div>
                </div>
              </div>
              <div
                class="tree-table-content"
                :style="i !== 0 || !isHaveChildren ? 'width: 100%;' : ''"
              >
                <slot
                  :name="`column_${c.key}`"
                  v-bind="c.return === 'index' ? {index: index} : data"
                >{{ !isNaN(data[c.key]) && data[c.key] && data[c.key][0] !== '0' && !c.isText ? digital.format(data[c.key]) : data[c.key] }}</slot>
              </div>
            </td>
          </tr>
          <tr
            v-if="treeList.length > 0 && footer.length > 0"
            v-for="data of footer"
            :key="data.key"
          >
            <td
              v-for="(c, i) of columnArray"
              :key="c.key"
              :class="i === 0 ? '' : ''"
              :style="c.align ? `text-align: ${c.align};` : ''"
            >
              <slot
                :name="`footer_${data.key}_column_${c.key}`"
                v-bind="c.return === 'index' ? {index: index} : data"
              >{{ checkFooterKey(data.data, data.column[c.key]) }}</slot>
            </td>
          </tr>
          <tr v-if="treeList.length <= 0">
            <td :colspan="column.length">{{ $t("default.noData") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeTable",
  data() {
    return {
      columnArray: [],
      tmpList: [],
      treeList: [],
      treeIndexNum: undefined,
      isHaveChildren: false,
      allCheck: false,
      tmpNotShowChildren: false,
      digital: new Intl.NumberFormat(),
      sort: {
        column: "",
        order: false
      }
    };
  },
  props: {
    // 資料列表 欄位有children自動畫成樹狀
    list: {
      type: Array,
      default: () => []
    },
    // 分頁
    // 必填 第一組name為bookmark，作為控制分頁使用
    bookmarkColumn: {
      type: Array
    },
    // table 欄位
    // 必要 key & name
    // 選填 sort: 排序 ex sort: 'betTime' 選填 sortType: 如果為時間格式填time , 其餘格式免填
    // 選填 isShow: 是否顯示 boolean
    // 選填 width: 寬度
    // 選填 align: 位置 css text-align
    // 選填 tableHeadAlign: 填 true 使 align 在th不生效 , 預設false
    // 選填 isText: 填 true 使 欄位判斷為文字避免數字帳號被format , 預設false
    column: {
      type: Array
    },
    // 子樹狀 離左邊的寬度
    levelBoxWidth: {
      type: Number,
      default: 20
    },
    // 是否顯示 前綴 總代 一階 二階等
    showStar: {
      type: Boolean,
      default: false
    },
    // table 底列表
    // {
    //   key: "total",
    //   data: this.total,    // 引用的資料
    //
    //   有value 會顯示value的值
    //
    //   column: {
    //    date: { value: this.$t("default.total") },   //  前方date為table欄位的key 後方key為this.total中對應的key 沒key則秀value
    //    totalDepositAmount: {
    //      key: "totalDepositAmount"
    //    },
    //    totalWithdrawalAmount: {
    //      key: "totalWithdrawalAmount"
    //    },
    //    totalValidBetAmount: { key: "totalValidBetAmount" },
    //    totalWinLose: { key: "totalWinLose" },
    //    totalDiscountAmount: { key: "totalDiscountAmount" }
    //   }
    // },
    // {
    //   key: 'total2',
    //   data: this.total2,
    //   column: {
    //    date: { value: this.$t("default.total") },
    //    totalDepositAmount: {
    //      key: "totalDepositAmount"
    //    },
    //    totalWithdrawalAmount: {
    //      key: "totalWithdrawalAmount"
    //    },
    //    totalValidBetAmount: { key: "totalValidBetAmount" },
    //    totalWinLose: { key: "totalWinLose" },
    //    totalDiscountAmount: { key: "totalDiscountAmount" }
    //   }
    // }
    footer: {
      type: Array,
      default: () => []
    },
    header: {
      type: Array,
      default: () => []
    },
    // 是否顯示收縮圖示 boolean
    showFold: {
      type: Boolean,
      default: true
    },
    // 是否使用勾選功能 boolean，使用 :checkdata.sync="參數" 接收勾選資料
    showCheckbox: {
      type: Boolean,
      default: false
    },
    // 是否顯示欄位顯示功能 boolean
    showColumnCheckbox: {
      type: Boolean,
      default: false
    },
    // 子類全部收起來
    notShowChildren: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    columnTmp() {
      const tmp = this.column;
      return _.cloneDeep(tmp);
    },
    bookmark() {
      return this.bookmarkColumn ? this.bookmarkColumn[0]?.key : undefined;
    }
  },
  watch: {
    columnTmp: {
      handler() {
        this.processColumn();
      },
      deep: true,
      immediate: true
    },
    list: {
      handler() {
        this.processList();
        this.tmpNotShowChildren = this.notShowChildren;
      },
      deep: true,
      immediate: true
    },
    treeList: {
      handler() {
        if (this.showCheckbox) this.processCheckbox();
      },
      deep: true,
      immediate: true
    },
    allCheck: {
      handler(e) {
        this.treeList.map(item => {
          item.checkbox = e;
        });
      }
    }
  },
  mounted() {
    //this.processList();
  },
  methods: {
    processColumn() {
      const vm = this;
      vm.columnArray = [];
      vm.columnTmp.forEach(function(item) {
        item.isShow = item.isShow ?? true;
        if (item.isShow) {
          vm.columnArray.push(item);
        }
      });
    },
    processList() {
      this.treeList = [];
      this.tmpList = _.cloneDeep(this.list);
      this.treeIndexNum = undefined;
      if (this.list.length > 0) {
        this.processChildren(this.tmpList, 0, true, []);
        if (this.tmpNotShowChildren) {
          const mains = this.treeList
            .filter(e => e.mains.length === 1)
            .map(e => {
              return e.key;
            });
          const vm = this;
          mains.forEach(function(m) {
            vm.shrink(m);
          });
          this.tmpNotShowChildren = false;
        }
      }
    },
    processChildren(list, level, isMain, mains) {
      let vm = this;
      list.forEach(function(item) {
        item.level = level;
        item.isChildren = item.children?.length > 0 ? true : false;
        item.showChildren = true;
        item.show = true;
        item.checkbox = false;

        if (isMain && item.children !== undefined) {
          vm.isHaveChildren = true;
        }

        vm.treeIndexNum =
          vm.treeIndexNum === undefined ? 0 : vm.treeIndexNum + 1;
        if (isMain) mains = [];
        let tmpMains = [...mains];
        tmpMains.push(vm.treeIndexNum);
        item.key = vm.treeIndexNum;

        item.mains = tmpMains;
        vm.treeList.push(_.omit(item, "children"));
        if (item.children?.length > 0) {
          vm.processChildren(item.children, level + 1, false, tmpMains);
        }
      });
    },
    // 縮
    shrink(index) {
      this.treeList.forEach(function(item, i) {
        if (item.mains.indexOf(index) > -1 && i !== index) {
          item.show = false;
          item.showChildren = false;
        }

        if (i === index) {
          item.showChildren = false;
        }
      });
    },
    // 放
    stretch(index) {
      this.treeList.forEach(function(item, i) {
        if (i === index) {
          item.showChildren = true;
        }

        if (item.mains[item.mains.length - 2] == index) {
          item.show = true;
        }
      });
    },
    processCheckbox() {
      let checkboxList = [];
      this.treeList.forEach(function(item) {
        if (item.checkbox) {
          checkboxList.push(item);
        }
      });

      this.$emit("update:checkdata", checkboxList);
    },
    sortFn(column, sortType = false) {
      const sort = (this.sort.order =
        this.sort.column !== column ? false : !this.sort.order);
      const sortName = (this.sort.column = column);
      if (sortType === "time") {
        this.list = this.list.sort((a, b) =>
          sort
            ? new Date(a[sortName]) - new Date(b[sortName])
            : new Date(b[sortName]) - new Date(a[sortName])
        );
      } else {
        this.list = this.list.sort((a, b) =>
          sort ? a[sortName] - b[sortName] : b[sortName] - a[sortName]
        );
      }
      // this.$emit("sort", column, this.sort.order);
    },
    changeColumnTmp(data) {
      data.isShow = !data.isShow;
      this.processColumn();
    },
    clickColumnBox() {
      const getClass = this.$refs.tableColumnBox.className;
      if (getClass.includes("box_show")) {
        this.$refs.tableColumnBox.classList.remove("table-column-box_show");
      } else {
        this.$refs.tableColumnBox.classList.add("table-column-box_show");
      }
    },
    changeBookmark(tag) {
      this.$emit("bookmark", tag);
    },
    checkFooterKey(data, key) {
      return !key?.key ? key?.value : this.digital.format(data[key?.key]);
    }
  }
};
</script>

<style lang="scss" scoped>
.bookmark {
  display: grid;
  grid-template-columns: repeat(10, 10%);
  span {
    padding: 5px 0;
    margin-right: 3px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px 5px 0 0;
    background-color: #516875;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.50);
  }
}
.table-sticky-wrapper {
  width: 100%;
  height: auto; /*外框的高度*/
  max-height: 60vh;
  overflow: auto;
}
.tree-table {
  // border-collapse: collapse !important;
  border: 0;
  overflow: unset !important;
  position: relative;
  thead {
    position: sticky;
    top: 0;
    z-index: 5;
  }
  th,
  td {
    // border: 0;
    // border-bottom: 1px solid #ddd;
    vertical-align: middle;
    // position: sticky;
  }
  // td {
  //   text-align: start;
  // }
  thead tr th:first-of-type {
    position: sticky;
    left: 0; /*sticky要用 上下左右定位*/
    z-index: 3;
    border-right: 0;
    background-color: #749AAF;
    // background-color: #fff;
  }
  tbody tr td:first-of-type {
    position: sticky;
    left: 0; /*sticky要用 上下左右定位*/
    z-index: 2;
    background-color: #fff;
    border-right: 0;
  }
  thead tr th {
    top: 0; /*sticky要用 上下左右定位*/
    z-index: 2;
    // background-color: #fff;
    border: 0;
    // box-shadow: 0px -2px 0px inset #dcdcdc; /*內框線*/
  }
  &-sort {
    &-active {
      opacity: 1;
    }
    &-inactive {
      opacity: 0.5;
    }
  }
  &-sort:hover {
    opacity: 1;
  }
  .tree-table-content {
    display: inline-block;
    vertical-align: sub;
  }
  .tree-table-first {
    display: flex;
    align-items: center;
  }
  .first-column {
    display: inline-block;
    .first-column-box {
      display: flex;
      align-items: center;
      .checkbox {
        margin-right: 3px;
      }
    }
  }
  .tree-tag {
    display: inline-block;
    padding: 2px;
    margin-right: 3px;
    color: #fff;
  }
  .tree-tag {
    display: inline-block;
    padding: 2px;
    margin-right: 3px;
    color: #fff;
  }
  .tree-tag-main {
    border: 1px solid #ff7474;
    background: #ff7474;
  }
  .tree-tag-children {
    border: 1px solid #5d9eed;
    background: #5d9eed;
  }
  .tree-icon {
    width: 13px;
    margin-right: 3px;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
  }
  .tree-icon-not {
    display: inline-block;
    width: 13px;
  }
  .level {
    display: inline-block;
  }
  .level-box {
    display: inline-block;
  }
  input {
    width: 100%;
  }
}
.table-column {
  display: flex;
  justify-content: flex-end;
  .checkBox-label {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 4px;
      left: 4px;
      height: 16px;
      width: 16px;
      background-color: #f0f0f0;
      border: 1.5px solid #3D4F59;
      border-radius: 5px;
    }
    input:checked ~ .checkmark {
      background-color: #779C0C;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 4px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &-icon {
    width: 50px;
    text-align: center;
    border-radius: 8px;
    border: 1.5px solid #7E96A3;
    background: #FFF;
    cursor: pointer;
    display: flex;
    gap: 5px;
    padding: 8px 10px;
    img {
      width: 15px;
    }
  }
  &-box {
    position: absolute;
    z-index: 10;
    transition-duration: 200ms;
    transform: translateX(-55px);
    max-height: 0px;
    overflow: hidden;
    display: none;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border-radius: 8px;
    border: 1.5px solid #7E96A3;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      label {
        margin-left: 2px;
        margin-bottom: 0;
      }
    }
  }
  &-box_show {
    display: grid;
    max-height: 9999px;
  }
  &::before {
    content: "";
    height: 25px;
  }
}
.table-row {
  margin-bottom: 3px;
  div {
    margin-left: 3px;
  }
}
.table-row:last-child {
  margin-bottom: 0;
}
.chooice {
  th {
    background-color: #97B4C3 !important;
  }
  background-color: #97B4C3 !important;
  color: #000 !important;
}

@media screen and (max-width: 768px) {
  .bookmark {
    grid-template-columns: repeat(5, 20%);
  }
}
</style>