var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}submitTime`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.adjustmentType`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}preBalance`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}afterBalance`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.admin")))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.quotaForm.submitTime))]),
        _c("td", [_vm._v(_vm._s(_vm.quotaForm.memberAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.$t(_vm.quotaForm.adjustmentType)))]),
        _c("td", [_vm._v(_vm._s(_vm.quotaForm.preTradeBalance))]),
        _c("td", { class: _vm.fontColor(_vm.quotaForm.amount) }, [
          _vm._v(_vm._s(_vm.quotaForm.amount)),
        ]),
        _c("td", [_vm._v(_vm._s(_vm.quotaForm.afterTradeBalance))]),
        _c("td", [_vm._v(_vm._s(_vm.quotaForm.remark))]),
        _c("td", [_vm._v(_vm._s(_vm.quotaForm.auditAccount))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }