<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div>
          <button class="delete-btn" @click="handleDelete(ids)">{{ $t("default.delete") }}</button>
          <button class="normal-btn"
                  @click="handleDialog(undefined, 'Create')"
                  :disabled="createStatus">
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
          <tr>
            <th><input type="checkbox" v-model="checkAllBtn" @click="checkAll()"></th>
            <th>{{ $t("default.edit") }}</th>
            <th>{{ $t(`${i18nPrefix}name`) }}</th>
            <th class="image">{{ $t(`${i18nPrefix}imageCopyLink`) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!tableList || tableList.length === 0">
            <td colspan="4">{{ $t("default.noData") }}</td>
          </tr>
          <tr v-for="list of tableList" :key="list.id">
            <td><input type="checkbox" v-model="ids" :value="list.id"></td>
            <td><button class="normal-btn" @click="handleDialog({ list:list }, 'edit')">{{ $t("default.edit") }}</button></td>
            <td :class="{ red: list.isTop }">{{ list.name }}</td>
            <td><img :src="list.url" @click="url(list.url,list.name)"></td>
          </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
    <transition name="fade" mode="out-in">
      <component
          :is="currDialog"
          v-bind="dialogData"
          @toggle="currDialog = undefined"
          @create="create"
          @update="update"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Confirm
          v-if="getShowConfirm"
          @toggle="TOGGLE_CONFIRM"
          @delete="deleteImage"
      />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  createMediaLibrary,
  getMediaLibraryList,
  updateMediaLibrary,
  deleteMediaLibrary,
} from "@/api/mediaLibrary/mediaLibraryImage";
import { toggleStyle } from "@/utils/slideUtils";
import Message from "@/layout/components/Message";
import Confirm from "@/layout/components/Confirm";
import Create from "./dialog/create.vue";
import Edit from "./dialog/edit.vue";
import {mapGetters, mapMutations} from "vuex";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "MediaLibraryImage",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      ids: [],
      checkAllBtn: false,
      createStatus: true,
      deleteData: undefined,
      topList: [],
      normalList: [],
      i18nPrefix: "mediaLibrary.MediaLibraryImage."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm"]),
  },
  watch: {
    ids() {
      this.checkAllBtn = (this.ids.length === this.allData.length) ? true : false;
    },
  },
  async created() {
    this.search();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    toggleSlide: toggleStyle,
    search() {
      this.currDialog = undefined;
      this.dialogData = undefined;
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
      getMediaLibraryList(this.searchForm).then((res) => {
        this.allData = res.data.filter(data => !data.isTop);
        this.topList = res.data.filter(data => data.isTop);
        this.dataSort('updateTime','time', true);
        this.allData = this.topList.concat(this.allData);
        this.createStatus = false;
      });
    },
    create(data) {
      createMediaLibrary(data).then((res) => {
        if(res) this.search();
      });
    },
    update(data) {
      updateMediaLibrary(data).then((res) => {
        if(res) this.search();
      });
    },
    handleDelete(data) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
    },
    deleteImage() {
      deleteMediaLibrary({ ids: this.deleteData }).then(res => {
        if(res) this.search();
      })
    },
    checkAll() {
      this.ids = this.checkAllBtn ? [] : this.allData.map(data => data.id);
    },
    url(url,text) {
      navigator.clipboard.writeText(url);
      Message.copy(`${text} 連結已複製`, 1500);
    }
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  margin-top: 5px;
}
.image {
  width: 1000px;
}
img {
  max-width: 30%;
  max-height: 30%;
}
@media screen and (max-width: 1000px) {
  .image {
    width: 500px!important;
  }
}
</style>