<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}gateway`) }}</th>
                <th>{{ $t(`${i18nPrefix}paymentCompanyInfo`) }}</th>
                <th>{{ $t("default.remark") }}</th>
                <th>{{ $t("default.sort") }}</th>
                <th>{{ $t("default.status") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button
                    class="table-btn main-style-btn"
                    @click="handleDialog({ dialogData: list }, 'Edit')"
                    :disabled="
                    !getUserinfo.permissions.includes(99999) &&
                    !getUserinfo.permissions.includes(61402)
                  "
                  >{{ $t("default.edit") }}</button>
                </td>
                <td>{{ list.name }}</td>
                <td>{{ list.nickName }}</td>
                <td>{{ list.remark }}</td>
                <td>{{ list.sortNumber }}</td>
                <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
      </main>
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        v-bind="dialogData"
        :i18nPrefix="i18nPrefix"
        @toggle="currDialog = undefined"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  paymentgatewayList,
  editPaymentgateway
} from "@/api/features/payment-gateway";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Edit from "./dialog/edit";
import { mapGetters } from "vuex";

export default {
  name: "PaymentGateway",
  data() {
    return {
      i18nPrefix: "features.payment-gateway."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  components: {
    Edit,
    Pagination
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      paymentgatewayList().then(res => {
        this.allData = res.data.paymentGateways;
        this.RESET_DIALOG();
      });
    },
    edit(data) {
      delete data.name;
      editPaymentgateway(data).then(res => {
        if (res) this.getList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>