<template>
  <div class="chart" ref="chart" :style="`width: ${width}; height: ${height}`"></div>
</template>

<script>
import { mapGetters } from "vuex";

import * as echarts from 'echarts';
import { option } from './model';

export default {
  name: "Chart",
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    /**
     * 非必要
     * {
     *  text: '',
     *  left: 'center' 默認左測 可不帶
     * }
     */
    title: {
      type: Object,
      default: () => {}
    },
    /**
     * legend = {
     *  orient: vertical 垂直 可不帶
     *  align: 'left or right or bottom' 位待預設中間 可不帶
     *  type: 'scroll' 滾動 未輸入默認一般, 可不帶
     *  data: [] 自訂順序 依照 series.name命名 可不帶
     * }
     */
    legend: {
      type: Object,
      default: () => {}
    },
    /**
     * 圓餅圖不須帶此值
     * xAxis = {
     *  name: '座標名稱' 可空,
     *  data: [] X軸節點
     * }
     */
    xAxis: {
      type: Object,
      default: () => {}
    },
    /**
     * yAxis = [{ // 混和圖 2個物件陣列 為左右兩側都有刻度
     *  name: '座標名稱' 可空,
     *  type: 'value'  目前固定此值,
     *  unit: 'ml' 會顯示 100 ml 可空,
     * }]
     */
    yAxis: {
      type: Array,
      default: () => []
    },
    /**
     * series = {
     *  name: '名稱',
     *  type: '' 類型 bar: 柱狀, line: 折線, pie: 圓餅,
     *  unit: 'ml' 會顯示 100 ml 可空,
     *  yAxisIndex: 1, 混和圖才需輸入, 右側Y軸的數字
     *  // 柱狀&折線
     *  data: [] 顯示的數字基本與xAxis要對得上,
     *  // 圓餅
     *  data: [{ value: 100, name: 'aaa' }, { value: 200, name: 'bbb' }] 須為物件value&name組合
     * }
     */
    series: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: undefined
    };
  },
  computed: {
    ...mapGetters(["sidebar", "lang"]),
  },
  watch: {
    'sidebar': {
      handler() {
        this.resize();
      }
    },
    'lang': {
      handler(v) {
        this.resize();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
      this.chart.showLoading();
    },
    async set() {
      var optionModel = await option(this.title, this.legend, this.xAxis, this.yAxis, this.series);
      this.chart.setOption(optionModel);
      this.chart.hideLoading();
    },
    setDynamic(option) {
      this.chart.setOption(option);
    },
    async resize() {
      await this.sleep(100);
      // this.chart.resize();
      this.chart.dispose();
      await this.sleep(300);
      this.init();
      this.set();
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
  }
}
</script>

<style lang="scss" scoped>

</style>