<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.store") }}
              <select v-model="editModel.name">
                <option v-for="(list, index) of CvsNameList" :key="index" :value="list">{{ $t(list) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t("default.city") }}
              <input type="text" v-model="editModel.city" />
            </label>
            <label>
              {{ $t("default.district") }}
              <input type="text" v-model="editModel.district" />
            </label>
            <label>
              {{ $t("default.code") }}
              <input type="text" v-model="editModel.code" />
            </label>
            <label>
              {{ $t("default.storeName") }}
              <input type="text" v-model="editModel.storeName" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('edit', editModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: {
    list: Object,
    CvsNameList: Array,
    i18nPrefix: String
  },
  data() {
    return {
      editModel: {
        id: this.list.id,
        name: this.list.name,
        city: this.list.city,
        district: this.list.district,
        code: this.list.code,
        storeName: this.list.storeName,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  .dialog-container {
    width: 40%;

    .input-block {
      flex-direction: column;

      label {
        width: 50%;
      }
    }
  }
}
</style>