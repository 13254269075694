var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.verifyData.qrCodeImageUrl,
                    expression: "verifyData.qrCodeImageUrl",
                  },
                ],
                attrs: { src: _vm.verifyData.qrCodeImageUrl },
              }),
              _c("div", { staticClass: "verifyData" }, [
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.verifyData.qrCodeImageUrl,
                        expression: "verifyData.qrCodeImageUrl",
                      },
                    ],
                  },
                  [
                    _vm._v(" 手動輸入金鑰 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.verifyData.manualEntrySetupCode,
                          expression: "verifyData.manualEntrySetupCode",
                        },
                      ],
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.verifyData.manualEntrySetupCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.verifyData,
                            "manualEntrySetupCode",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c("label", [
                  _vm._v(" 驗證碼 "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.code,
                        expression: "code",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.code = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  attrs: { disabled: _vm.check },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("twoFactorAutn", {
                        code: _vm.code,
                        verificationId: _vm.verificationId,
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("h4", [_vm._v("二次驗證")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }