var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("h3", [
            _vm._v(
              _vm._s(_vm.$t(`${_vm.i18nPrefix}id`)) + "：" + _vm._s(_vm.id)
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkShow,
                  expression: "checkShow",
                },
              ],
            },
            [
              _c(
                "button",
                {
                  staticClass: "normal-btn mr-3",
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.accept`)))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit(3)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.reject`)))]
              ),
            ]
          ),
          _c("main", [
            _c(
              "div",
              { staticClass: "table-block" },
              [
                _c("table", { staticClass: "mt-3" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th"),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.star")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.agent")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}timeFrom`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}timeTo`))),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}approveTime`))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.allData, function (list, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: list.auditStatus === 1,
                                  expression: "list.auditStatus === 1",
                                },
                              ],
                              staticClass: "normal-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(2, list.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.audit")))]
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "default." +
                                  (list.levelNumber
                                    ? `levelNumber`
                                    : "mainAgent"),
                                { input: list.levelNumber }
                              )
                            )
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.agentAccount))]),
                        _c("td", [_vm._v(_vm._s(list.timeFrom))]),
                        _c("td", [_vm._v(_vm._s(list.timeTo))]),
                        _c("td", [_vm._v(_vm._s(list.amount))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.statusList.find(
                                  (data) => data.id === list.auditStatus
                                )
                              )
                            )
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.auditTime))]),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm.allData
                  ? _c("serverPagination", {
                      attrs: { page: _vm.page },
                      on: {
                        ServerPageUtils: _vm.search,
                        changeTake: _vm.changeTake,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg, btnText: _vm.btnText },
                on: { toggle: _vm.cancelEdit, doIt: _vm.edit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }