import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function listByGamesCategory(data) {
    return request({
        url: "/AgentRebateV2/ListByGamesCategory",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getRebateTime(data) {
    return request({
        url: "/AgentRebateV2/RebateTimeGamesCategory",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}