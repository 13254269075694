<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.domain.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="container-view">
            <div class="input-block">
              <div class="condition-block">
                <label>
                  {{ $t(`${i18nPrefix}dialog.domain.createDomain`) }}
                </label>
                <div class="selectDiv">
                  <multiselect
                      v-model="addDomain"
                      label="domain"
                      track-by="id"
                      :placeholder="$t('default.plzSelect')"
                      :options="domainList"
                      :multiple="true"
                      :searchable="false"
                  ></multiselect>
                  <div class="button-block">
                    <button @click="addDomain = [...domainList]">{{ $t("default.selectAll") }}</button>
                    <button @click="addDomain = []">{{ $t("default.clear") }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="advance-block">
              <button class="normal-btn" @click="$emit('createDomain', { id, agents: addDomain.map(domain => domain.id) })">
                {{ $t("default.confirm") }}
              </button>
              <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
            </div>
          </div>
          <div class="container-view">
            <div class="table-block">
              <table>
                <thead>
                <tr>
                  <th>{{ $t(`${i18nPrefix}dialog.domain.created`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.domain.title`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.domain.agent`) }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!tableList || tableList.length === 0">
                  <td colspan="3">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-for="list of tableList" :key="list.id">
                  <td><button class="delete-btn" @click="handleDelete({ id: list.id })">{{ $t("default.delete") }}</button></td>
                  <td>{{ list.domain }}</td>
                  <td>{{ list.account }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert
          v-if="alertMsg"
          :message="alertMsg"
          @toggle="alertMsg = undefined"
          @doIt="deleteDomain"
      />
    </transition>
  </div>
</template>

<script>
import {
  getDomains,
  deleteDomain,
} from "@/api/SEO/SEO";
import Alert from "@/layout/components/Alert";
import Message from "@/layout/components/Message";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import Multiselect from "vue-multiselect";

export default {
  props: {
    id: undefined,
    agentList: {},
  },
  name: "Domain",
  data(){
    return {
      domainList: [],
      addDomain: [],
      alertMsg: undefined,
      i18nPrefix: "SEO-Function.SEO."
    }
  },
  components: {
    Alert,
    Multiselect,
  },
  mixins: [handlePageUtils],
  created() {
    this.getDomainsList();
  },
  methods: {
    getDomainsList() {
      this.domainList = [...this.agentList];
      getDomains({ id: this.id }).then((res) => {
        this.allData = res.data.domains;
        for(let i = 0; i < this.allData.length; i++) {
          for (let j = 0; j < this.domainList.length; j++) {
            if (this.domainList[j].id === this.allData[i].agentId) {
              this.domainList.splice(j, 1);
            }
          }
        }
      });
    },
    handleDelete(data) {
      this.alertMsg = "確定要刪除 ?";
      this.deleteData = data;
    },
    deleteDomain() {
      this.alertMsg = "";
      deleteDomain(this.deleteData).then((res) => {
        if(res) this.getDomainsList();
      });
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>

.dialog-container {
  width: 60%;
  label {
    align-items: center;
  }
  .selectDiv {
    display: flex;
    align-items: center;
    .multiselect {
      width: 80%;
    }
    .button-block {
      width: 20%;
      margin-left: 5px;
      button {
        padding: 10px 10px;
        border-radius: 5px;
        background-color: transparent;
        border: 2px solid #c7c7c7;
      }
    }
  }
  .advance-block {
    bottom: 0%;
  }
}
</style>