var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "divider-v2" }, [
      _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
        _vm._v(_vm._s(_vm.$t(`default.rule`))),
      ]),
    ]),
    _vm.selectDiscountType !== ""
      ? _c("div", { staticClass: "form-box" }, [
          _c("div", { staticClass: "form-box-half form-column" }, [
            _c("div", { staticClass: "form-box" }, [
              _vm.discountRule[_vm.selectDiscountType].isOnce !== undefined
                ? _c("label", { staticClass: "label-row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.isOnce,
                          expression: "formModel.isOnce",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.formModel.isOnce)
                          ? _vm._i(_vm.formModel.isOnce, null) > -1
                          : _vm.formModel.isOnce,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.formModel.isOnce,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.formModel,
                                  "isOnce",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.formModel,
                                  "isOnce",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.formModel, "isOnce", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.onlyDiscount`)) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.formModel.applyType == 2
                ? _c("label", { staticClass: "label-row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.isMutuallyExclusivesType,
                          expression: "formModel.isMutuallyExclusivesType",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.formModel.isMutuallyExclusivesType
                        )
                          ? _vm._i(
                              _vm.formModel.isMutuallyExclusivesType,
                              null
                            ) > -1
                          : _vm.formModel.isMutuallyExclusivesType,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.formModel.isMutuallyExclusivesType,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.formModel,
                                  "isMutuallyExclusivesType",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.formModel,
                                  "isMutuallyExclusivesType",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.formModel,
                              "isMutuallyExclusivesType",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `${_vm.i18nPrefix}editV2.isMutuallyExclusivesType`
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.formModel.applyType == 1 ||
              (_vm.formModel.applyType == 2 && _vm.formModel.dispatchType == 3)
                ? _c("label", [
                    _c("span", { staticClass: "span-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.isBlockDuplicateIP,
                            expression: "formModel.isBlockDuplicateIP",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.formModel.isBlockDuplicateIP
                          )
                            ? _vm._i(_vm.formModel.isBlockDuplicateIP, null) >
                              -1
                            : _vm.formModel.isBlockDuplicateIP,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.formModel.isBlockDuplicateIP,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.formModel,
                                    "isBlockDuplicateIP",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.formModel,
                                    "isBlockDuplicateIP",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.formModel, "isBlockDuplicateIP", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}editV2.isBlockDuplicateIP`)
                          ) +
                          " "
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.formModel.BlockDuplicateIPCount,
                          expression: "formModel.BlockDuplicateIPCount",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: {
                        type: "number",
                        min: "1",
                        placeholder: _vm.$t(`${_vm.i18nPrefix}dialog.times`),
                        disabled: !_vm.formModel.isBlockDuplicateIP,
                      },
                      domProps: { value: _vm.formModel.BlockDuplicateIPCount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formModel,
                            "BlockDuplicateIPCount",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "form-box" }, [
              _vm.discountRule[_vm.selectDiscountType].stairBase == undefined
                ? _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.stairBase`)) +
                        " "
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.stairBase,
                            expression: "formModel.stairBase",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formModel,
                              "stairBase",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.discountRule.stairBaseType, function (item) {
                        return _c(
                          "option",
                          { key: item.key, domProps: { value: item.value } },
                          [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.discountRule[_vm.selectDiscountType].operand == undefined
                ? _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.operand`)) +
                        " "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.operand,
                          expression: "formModel.operand",
                        },
                      ],
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.formModel.operand },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formModel,
                            "operand",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "form-box" }, [
              _c(
                "label",
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}editV2.limitMemberLevel`)
                      ) +
                      " "
                  ),
                  _c("multiselect", {
                    attrs: {
                      label: "name",
                      "track-by": "star",
                      placeholder: "",
                      options: _vm.memberStar.list,
                      multiple: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.memberStar.select,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberStar, "select", $$v)
                      },
                      expression: "memberStar.select",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.limitMemberTag`)) +
                      " "
                  ),
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        label: "name",
                        "track-by": "id",
                        placeholder: "",
                        options: _vm.memberTag.list,
                        multiple: true,
                        searchable: false,
                      },
                      model: {
                        value: _vm.memberTag.select,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberTag, "select", $$v)
                        },
                        expression: "memberTag.select",
                      },
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(" " + _vm._s(_vm.$t("default.noData")) + " "),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _vm.changeFormula
              ? _c(
                  "div",
                  { staticClass: "form-box form-column example" },
                  _vm._l(
                    _vm.stairBaseTypeExample[_vm.formModel.stairBase],
                    function (item, index) {
                      return _c("div", {
                        key: index,
                        staticClass: "example-row",
                        domProps: { innerHTML: _vm._s(item.text) },
                      })
                    }
                  ),
                  0
                )
              : _vm._e(),
            _vm.changeFormula
              ? _c("div", { staticClass: "form-box" }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.formula`)) +
                        " "
                    ),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.formula,
                          expression: "formModel.formula",
                        },
                      ],
                      staticStyle: { height: "auto" },
                      attrs: { rows: "3" },
                      domProps: { value: _vm.formModel.formula },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formModel,
                            "formula",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("span", {
                      ref: "rulesformula",
                      staticClass: "form-rules",
                    }),
                  ]),
                ])
              : _vm._e(),
            !_vm.changeFormula
              ? _c("div", { staticClass: "form-box" }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.dispatchStep`)) +
                        " "
                    ),
                    _vm.formModel.stairBase == 1
                      ? _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th"),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `${_vm.i18nPrefix}editV2.dispatchStep1`
                                    )
                                  )
                                ),
                              ]),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `${_vm.i18nPrefix}editV2.dispatchStep2`
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.uiFormula, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm.uiFormula.length < 10
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.addUiFormula(index)
                                            },
                                          },
                                        },
                                        [_vm._v(" + ")]
                                      )
                                    : _vm._e(),
                                  index > 0
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteUiFormula(index)
                                            },
                                          },
                                        },
                                        [_vm._v(" - ")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.name,
                                        expression: "item.name",
                                      },
                                    ],
                                    attrs: {
                                      id: `uiFormula_${index}`,
                                      type: "number",
                                    },
                                    domProps: { value: item.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.value,
                                        expression: "item.value",
                                      },
                                    ],
                                    attrs: { type: "number" },
                                    domProps: { value: item.value },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm.formModel.stairBase == 999
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.uiFormulaNewRegister,
                              expression: "uiFormulaNewRegister",
                            },
                          ],
                          attrs: { type: "number" },
                          domProps: { value: _vm.uiFormulaNewRegister },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.uiFormulaNewRegister = $event.target.value
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "form-box-half form-column" }, [
            _c("div", { staticClass: "form-box" }, [
              _vm.discountRule[_vm.selectDiscountType].periodType !== undefined
                ? _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.period`)) +
                        " "
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.period,
                            expression: "formModel.period",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formModel,
                              "period",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.discountRule[_vm.selectDiscountType].periodType,
                        function (item) {
                          return _c(
                            "option",
                            { key: item.key, domProps: { value: item.value } },
                            [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.apply`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.applyType,
                        expression: "formModel.applyType",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formModel,
                          "applyType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.applyType, function (item) {
                    return _c(
                      "option",
                      { key: item.key, domProps: { value: item.value } },
                      [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]
                    )
                  }),
                  0
                ),
                _c("span", {
                  ref: "rulesapplyType",
                  staticClass: "form-rules",
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.dispatch`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.dispatchType,
                        expression: "formModel.dispatchType",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formModel,
                          "dispatchType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.dispatchType, function (item) {
                    return _c(
                      "option",
                      { key: item.key, domProps: { value: item.value } },
                      [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]
                    )
                  }),
                  0
                ),
                _c("span", {
                  ref: "rulesdispatchType",
                  staticClass: "form-rules",
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.bounsMultiple`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formModel.bounsMultiple,
                      expression: "formModel.bounsMultiple",
                    },
                  ],
                  attrs: { type: "number", min: "0" },
                  domProps: { value: _vm.formModel.bounsMultiple },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formModel,
                        "bounsMultiple",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("span", {
                  ref: "rulesbounsMultiple",
                  staticClass: "form-rules",
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-box" }, [
              _vm.discountRule[_vm.selectDiscountType].bounsAmountLimit ==
              undefined
                ? _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}editV2.bounsAmountLimit`)
                        ) +
                          " (" +
                          _vm._s(
                            _vm.$t(
                              `${_vm.i18nPrefix}editV2.bounsAmountLimitRemind`
                            )
                          ) +
                          ")"
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.bounsAmountLimit,
                          expression: "formModel.bounsAmountLimit",
                        },
                      ],
                      attrs: { type: "number", min: "-1" },
                      domProps: { value: _vm.formModel.bounsAmountLimit },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formModel,
                            "bounsAmountLimit",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("span", {
                      ref: "rulesbounsAmountLimit",
                      staticClass: "form-rules",
                    }),
                  ])
                : _vm._e(),
              _vm.discountRule[_vm.selectDiscountType].bounsAmountLower ==
              undefined
                ? _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}editV2.bounsAmountLower`)
                        ) +
                        " "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.bounsAmountLower,
                          expression: "formModel.bounsAmountLower",
                        },
                      ],
                      attrs: { type: "number", min: "0" },
                      domProps: { value: _vm.formModel.bounsAmountLower },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formModel,
                            "bounsAmountLower",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("span", {
                      ref: "rulesbounsAmountLower",
                      staticClass: "form-rules",
                    }),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "form-box" }, [
              _c(
                "label",
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}editV2.mutuallyExclusives`)
                      ) +
                      " "
                  ),
                  _c("multiselect", {
                    attrs: {
                      label: "name",
                      "track-by": "id",
                      placeholder: "",
                      options: _vm.memberDiscount.list,
                      multiple: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.memberDiscount.select,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDiscount, "select", $$v)
                      },
                      expression: "memberDiscount.select",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }