<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.process") }}</h4>
        </div>
        <div class="divider"></div>
        <a
          href="javascript:;"
          :class="{ 'red': nowId === list.id}"
          @click="showProcess(processData[index])"
          v-for="(list , index) of processData"
          :key="list.id"
        >{{ index + 1 }}</a>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th class="w-25">
                    {{ $t("default.processAccount") + ': ' + auditAccount }}
                    <br />
                    {{ $t("default.processTime") + ': ' + processTime }}
                  </th>
                  <th v-for="title of processTitle" :key="title.key">{{ title.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!oldValue && !newValue">
                  <td :colspan="processTitle.length +1">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-if="oldValue">
                  <td>{{ $t("default.oldValue") }}</td>
                  <td v-for="data of processTitle" :key="'old' + data.key">
                    <slot :name="`oldValue_${data.key}`" v-bind="oldValue">{{ oldValue[data.key] }}</slot>
                  </td>
                </tr>
                <tr v-if="newValue">
                  <td>{{ $t("default.newValue") }}</td>
                  <td v-for="data of processTitle" :key="'new' + data.key">
                    <slot :name="`newValue_${data.key}`" v-bind="newValue">{{ newValue[data.key] }}</slot>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Process",
  props: ["processData", "processTitle", "i18nPrefix"],
  data() {
    return {
      nowId: undefined,
      newValue: undefined,
      oldValue: undefined,
      processTime: '',
      auditAccount: ''
    };
  },
  async created() {
    if (this.processData) this.showProcess(this.processData[0]);
  },
  methods: {
    showProcess(data) {
      this.nowId = data?.id;
      this.newValue = data?.newValue ? JSON.parse(data.newValue) : {};
      this.oldValue = data?.oldValue ? JSON.parse(data.oldValue) : {};
      this.processTime = data?.createTime;
      this.auditAccount = data?.auditAccount;
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 70% !important;
  a {
    margin: 0 5px;
    font-size: 20px;
  }
  a:link {
    //設定還沒有瀏覽過的連結
    color: #000;
  }
  a:hover {
    //設定滑鼠移經的連結
    text-decoration: underline;
    background-color: #fafafa;
    color: gray;
  }
  a:active {
    //設定正在點選的連結
    text-decoration: none;
    background-color: gray;
    color: #fafafa;
  }
}
</style>