<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }}{{ $t(`title.SEO-blogActivity`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="inputTop">
              <div class="title">
                {{ $t(`${i18nPrefix}dialog.articleStatus`) }}
                <label
                    class="slide-block"
                    style="margin: auto"
                    :class="{ 'slide-block-on': status }"
                >
                  <div
                      class="slide"
                      @click="toggleSlide"
                      :class="{ 'slide-on': status }"
                  >
                    <label for="deleteStatus" style="display: none"></label>
                    <input
                        type="checkbox"
                        id="deleteStatus"
                        v-model="status"
                        style="display: none"
                    />
                  </div>
                </label>
              </div>
              <div class="title">
                {{ $t(`${i18nPrefix}dialog.top`) }}
                <label
                    class="slide-block"
                    style="margin: auto"
                    :class="{ 'slide-block-on': createModel.isTop }"
                >
                  <div
                      class="slide"
                      @click="toggleSlide"
                      :class="{ 'slide-on': createModel.isTop }"
                  >
                    <label for="isTop" style="display: none"></label>
                    <input
                        type="checkbox"
                        id="isTop"
                        v-model="createModel.isTop"
                        style="display: none"
                    />
                  </div>
                </label>
              </div>
              <div class="title">
                {{ $t(`${i18nPrefix}dialog.previewStatus`) }}
                <label
                    class="slide-block"
                    style="margin: auto"
                    :class="{ 'slide-block-on': createModel.previewTextStatus }"
                >
                  <div
                      class="slide"
                      @click="toggleSlide"
                      :class="{ 'slide-on': createModel.previewTextStatus }"
                  >
                    <label for="previewTextStatus" style="display: none"></label>
                    <input
                        type="checkbox"
                        id="previewTextStatus"
                        v-model="createModel.previewTextStatus"
                        style="display: none"
                    />
                  </div>
                </label>
              </div>
              <div class="select-div">
                {{ $t(`${i18nPrefix}dialog.domain`) }}
                <select v-model="createModel.agentId">
                  <option v-for="list of agentList" :value="list.id" :key="list.id">{{ list.domain }}</option>
                </select>
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}dialog.path`) }}
                </span>
                <input type="text" v-model="createModel.subPath" :placeholder="$t(`${i18nPrefix}dialog.enterPath`)">
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}dialog.articleTitle`) }}
                </span>
                <input type="text" v-model="createModel.title" :placeholder="$t(`${i18nPrefix}dialog.enterTitlePlaceholder`)">
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}dialog.previewText`) }}
                </span>
                <input type="text" v-model="createModel.previewText" oninput="if(value.length>42)value=value.slice(0,42)" :placeholder="$t(`${i18nPrefix}dialog.enterContent`)">
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}postDate`) }}
                </span>
                <date-picker v-model="createModel.publishTime" format="YYYY-MM-DD HH:mm:ss" value-type="format" type="datetime"></date-picker>
              </div>
            </div>
            <div class="previewDiv" @click="imageDialog()">
              <span v-if="!createModel.previewImageUrl">{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}{{ $t("default.previewImage") }}</span>
              <img :src="createModel.previewImageUrl" style="display: block;" />
            </div>
            <div class="metaTitle">
                <span>
                  title
                </span>
              <input type="text" v-model="metadata.title" :placeholder="$t(`${i18nPrefix}dialog.enterTitle`)">
            </div>
            <div class="add-th">
              meta
              <button
                  class="add-btn"
                  style="
                                  background-color: green;
                                  margin: 5px;
                                "
                  @click="addData('+','meta')"
              >
                <span>+</span>
              </button>
              <button
                  class="add-btn"
                  @click="checkDelete('meta')"
              >
                <span>-</span>
            </button>
            </div>
            <div class="meta">
              <div v-for="(list,index) of metadata.meta" :key="'meta'+index" class="metaForDiv">
                <div class="metaDiv">
                  <input type="text" :placeholder="$t(`${i18nPrefix}dialog.enterAttr`)" v-model="list.tag">
                  <input type="text" :placeholder="$t(`${i18nPrefix}dialog.enterAttrValue`)" v-model="list.tagValue">
                  <textarea v-model="list.content" :placeholder="$t(`${i18nPrefix}dialog.enterContent`)" rows="5"></textarea>
                </div>
              </div>
            </div>
            <div class="add-th">
              link
              <button
                  class="add-btn"
                  style="
                                  background-color: green;
                                  margin: 5px;
                                "
                  @click="addData('+','link')"
              >
                <span>+</span>
              </button>
              <button
                  class="add-btn"
                  @click="checkDelete('link')"
              >
                <span>-</span>
              </button>
            </div>
            <div class="meta">
              <div v-for="(link,index) of metadata.link" :key="'link'+index" class="metaForDiv linkForDiv">
                <div class="linkDiv">
                  <div class="add-th">
                    <button
                        class="add-btn"
                        style="
                                  background-color: green;
                                  margin: 5px;
                                "
                        @click="addData('+',index)"
                    >
                      <span>+</span>
                    </button>
                    <button
                        class="add-btn"
                        @click="checkDelete(index)"
                    >
                      <span>-</span>
                    </button>
                  </div>
                  <div class="inputDiv link" v-for="(list,linkIndex) of link" :key="'link'+index+linkIndex">
                    <input type="text" :placeholder="$t(`${i18nPrefix}dialog.enterAttr`)" v-model="list.tag">
                    <input type="text" :placeholder="$t(`${i18nPrefix}dialog.enterAttrValue`)" v-model="list.value">
                  </div>
                </div>
              </div>
            </div>
            <div class="add-th">
              script
              <button
                  class="add-btn"
                  style="
                                  background-color: green;
                                  margin: 5px;
                                "
                  @click="addData('+','script')"
              >
                <span>+</span>
              </button>
              <button
                  class="add-btn"
                  @click="checkDelete('script')"
              >
                <span>-</span>
              </button>
            </div>
            <div class="script">
              <div class="scriptDiv" v-for="(list, index) of metadata.script" :key="index">
                <div class="box" v-show="!list.innerHtml && !list.type">
                  <input type="checkbox" class="checkbox" v-model="list.async" :disabled="list.innerHtml !== '' || list.type !== ''">async
                  <div class="inputDiv">
                    src<input type="text" :placeholder="$t(`${i18nPrefix}dialog.enterSrc`)" v-model="list.src" :disabled="list.innerHtml !== '' || list.type !== ''">
                  </div>
                </div>
                <div v-show="!list.src && !list.async">
                  <div class="link">
                    type<input type="text" :placeholder="$t(`${i18nPrefix}dialog.enterType`)" v-model="list.type" :disabled="list.src || list.async">
                    {{ $t(`${i18nPrefix}dialog.enterScriptContent`) }}
                    <textarea v-model="list.innerHtml" :placeholder="$t(`${i18nPrefix}dialog.enterScript`)" rows="5" :disabled="list.src || list.async"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {{ $t(`${i18nPrefix}dialog.preview`) }}
          <button class="normal-btn" @click="parseToSeo(preview)">
            {{ $t(`${i18nPrefix}dialog.reverseInput`) }}
          </button>
          <div class="textarea">
            <textarea v-model="preview" rows="20"></textarea>
          </div>
          <div class="editor-block">
            <ckeditor v-model="createModel.html" :editor-url="this.GLOBAL.CKEditorUrl" :config="editorConfig"></ckeditor>
          </div>
            <div class="advance-block">
              <button class="normal-btn" @click="create">
                {{ $t("default.confirm") }}
              </button>
              <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
            </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component
          :is="currDialog"
          @toggle="currDialog = undefined"
          @choiceImage="choiceImage"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Alert
          v-if="alertMsg"
          :message="alertMsg"
          @toggle="alertMsg = undefined"
          @doIt="deleteMetaData"
      />
    </transition>
  </div>
</template>

<script>
import imagePage from "@/layout/components/mediaLibraryImage.vue";
import { toggleStyle } from "@/utils/slideUtils";
import { joinT } from "@/utils/transDateUtils";
import { parseToSeoHeader,metaData,parseToSeoArray,addSeoArray } from "@/utils/SEOFunction";
import Alert from "@/layout/components/Alert";

export default {
  name: "Create",
  props: ["agentList","SEOGameList"],
  data() {
    return {
      editorConfig: {
        allowedContent: true,
        height: '900',
      },
      createModel: {
        status: 2,
        agentId: undefined,
        isTop: false,
        seoBlogCategoryId: this.SEOGameList[0].id,
        subPath: undefined,
        title: undefined,
        metadata: undefined,
        previewImageUrl: undefined,
        previewText: undefined,
        previewTextStatus : false,
        publishTime: undefined,
        html: undefined
      },
      metadata: _.cloneDeep(metaData),
      preview: undefined,
      status: true,
      currDialog: undefined,
      alertMsg: undefined,
      deleteData: undefined,
      i18nPrefix: "SEO-Blog-Function.SEO-blogActivity."
    }
  },
  components: {
    imagePage,
    Alert
  },
  watch: {
    "metadata": {
      handler() {
        this.preview = parseToSeoArray(this.metadata);
      },
      deep: true,
    },
  },
  created() {
    this.createModel.agentId = this.agentList[0].id;
  },
  methods: {
    toggleSlide: toggleStyle,
    addData(type , dataType) {
      addSeoArray(type, dataType, this.metadata);
    },
    create() {
      this.createModel.seoBlogCategoryId = this.SEOGameList.find(data => data.agentId === this.createModel.agentId)?.id;
      this.createModel.publishTime = (this.createModel.publishTime !== undefined && this.createModel.publishTime?.length !== 0)
          ? joinT(this.createModel.publishTime + this.GLOBAL.timeZone)
          : undefined;
      this.createModel.metadata = this.metadata;
      this.createModel.status = this.status ? 1 : 2;

      this.$emit("create", this.createModel);
    },
    imageDialog() {
      this.currDialog = "imagePage";
    },
    choiceImage(url) {
      this.createModel.previewImageUrl = url;
      this.currDialog = "";
    },
    parseToSeo(previewData) {
      this.metadata = parseToSeoHeader(previewData);
    },
    checkDelete(data) {
      this.alertMsg = "確認刪除" + (isNaN(Number(data)) ? data : 'Link子') + "標籤 ?";
      this.deleteData = data;
    },
    deleteMetaData(){
      this.alertMsg = undefined;
      this.addData('-', this.deleteData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/SEOPage.scss";
</style>