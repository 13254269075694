import Vue from 'vue'
import VueI18n from 'vue-i18n'

import tw from './tw.json'
import en from './en.json'
import th from './th.json'
import kr from './kr.json'
import jp from './jp.json'

Vue.use(VueI18n)

const locale = 'localeLang'
let localeLang = en;
const messages = {
    localeLang
}
const i18n = new VueI18n({
    locale,
    silentTranslationWarn: true,
    messages
})

export default i18n