<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmark" :key="list.id" @click="chooice = list.id"
          :class="{ 'chooice': chooice == list.id }">{{ $t(list.name) }}</div>
      </div>
    </div>
    <component :i18nPrefix="i18nPrefix" :is="chooice"></component>
  </div>
</template>

<script>
import MemberDeposit from "./member-deposit";
import MemberDepositCrypto from "./member-depositCrypto";
import MemberDepositCsv from "./member-depositCsv";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
      i18nPrefix: "report.member-deposit.",
      chooice: "member-deposit",
      bookmark: [
        {
          id: "member-deposit",
          name: "default.deposit"
        },
        {
          id: "member-depositCrypto",
          name: "default.depositCrypto"
        },
        {
          id: "member-depositCsv",
          name: "default.depositCsv"
        }
      ]
    };
  },
  components: {
    MemberDeposit,
    MemberDepositCrypto,
    MemberDepositCsv
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>