import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function riskControlList(data) {
    return request({
        url: "/riskControl/query",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}