import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function paymentCVSList(data) {
    return request({
        url: "/PaymentCVS/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createPaymentCVS(data) {
    return request({
        url: "/PaymentCVS/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editPaymentCVS(data) {
    return request({
        url: "/PaymentCVS/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}