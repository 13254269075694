<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{  $t(`${i18nPrefix}dialog.game-wallet.gameTransfer`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="member-info">
              <label class="member">
                {{  $t(`${i18nPrefix}account`) }}
                <input type="text" v-model="customGameAccount" :disabled="true" />
              </label>
              <label>
                {{ $t("default.amount") }}
                <input type="number" v-model.number="amount" :min="multiples * 1" />
              </label>
              <div class="transfer">
                <label>
                  {{  $t(`${i18nPrefix}dialog.game-wallet.from`) }}
                  <input
                    type="text"
                    v-model="gamePlatformFrom"
                    :disabled="true"
                  />
                  {{  $t(`${i18nPrefix}dialog.game-wallet.balance`) }}
                  <input type="text" :value="new Intl.NumberFormat().format(amountFrom)" :disabled="true" />
                </label>
                <font @click="change">⇌</font>
                <label>
                  {{  $t(`${i18nPrefix}dialog.game-wallet.to`) }}
                  <input
                    type="text"
                    v-model="gamePlatformTo"
                    :disabled="true"
                  />
                  {{  $t(`${i18nPrefix}dialog.game-wallet.balance`) }}
                  <input type="text" :value="new Intl.NumberFormat().format(amountTo)" :disabled="true" />
                </label>
              </div>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="alert">{{  $t(`${i18nPrefix}dialog.game-wallet.transfer`) }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="transfer"
      />
    </transition>
  </div>
</template>

<script>
import Alert from "@/layout/components/Alert";
import Message from "@/layout/components/Message";

export default {
  name: "WalletTransfer",
  props: [
    "mainBlance",
    "gamePlatformId",
    "multiples",
    "customGameAccount",
    "balance",
    "gamePlatform",
    "i18nPrefix"
  ],
  data() {
    return {
      transferModel: {
        gameTransferList: [
          {
            gamePlatformId: this.gamePlatformId,
            multiples: this.multiples * 1,
            amount: 0,
          },
        ],
        isTransferIn: false,
      },
      amount: 1 * this.multiples,
      gamePlatformFrom: this.$t(`${this.i18nPrefix}wallet`),
      gamePlatformTo: this.$t(this.gamePlatform),
      amountFrom: this.mainBlance,
      amountTo: this.balance,
      alertMsg: undefined,
    };
  },
  components: {
    Alert,
  },
  methods: {
    transfer() {
      this.alertMsg = undefined;
      if (this.amount * 1 > this.amountFrom) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.game-wallet.errorMsg2`), 1000);
      } else {
        this.transferModel.gameTransferList[0].amount = this.amount * 1;
        this.transferModel.isTransferIn =
          this.gamePlatformFrom === this.$t(`${this.i18nPrefix}wallet`);
        this.$emit("transfer", this.transferModel);
      }
    },
    change() {
      [this.gamePlatformFrom, this.gamePlatformTo] = [
        this.gamePlatformTo,
        this.gamePlatformFrom,
      ];
      [this.amountFrom, this.amountTo] = [this.amountTo, this.amountFrom];
    },
    alert() {
      this.alertMsg = this.$t(`${this.i18nPrefix}dialog.game-wallet.alertMsg2`);
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  .input-block {
    flex-direction: column;
    .member-info {
      display: flex;
      flex-wrap: wrap;
      label {
        width: 30%;
      }
      .transfer {
        display: flex;
        margin: 5%;
        width: 100%;
        label {
          margin: 0% 5%;
          width: 40%;
        }
        font {
          cursor: pointer;
          margin: 15px 5% 0px;
          font-size: 10em;
        }
      }
    }
  }
}
</style>