import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getAgentBankList(data) {
    return request({
        url: "/agent/bank/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateBank(data) {
    return request({
        url: "/agent/bank/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteBank(data) {
    return request({
        url: "/agent/bank/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditBank(data) {
    return request({
        url: "/agent/bank/Audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}