<template>
  <div class="select-style-v1">
    <div class="select-style-v1-now" @click="change">
      {{ value }} <img class="icon" src="@/assets/navbar/down.svg" />
    </div>
    <ul v-show="showOption" class="select-style-v1-option">
      <li
        v-for="item of list"
        :key="item.key"
        class="select-style-v1-option-item"
        @click="select(item)"
      >
        {{ item.value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SelectStyleV1",
  props: {
    'list': {
      type: Array,
      default: () => []
    },
    'default': {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: '',
      showOption: false,
    };
  },
  watch: {
    
  },
  mounted() {
    if (this.default !== '') {
      this.value = this.list.filter(e => e.key === this.default)[0].value;
    }
  },
  methods: {
    change() {
      this.showOption = !this.showOption;
    },
    select(data) {
      this.value = data.value;
      this.showOption = false;
      this.$emit('update:now', data.key);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-style-v1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  padding: 5px 15px;
  &-now {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-wrap: nowrap;
    .icon {
      margin-left: 10px;
      width: 20px;
    }
  }
  &-option {
    position: absolute;
    top: 30px;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    color: #000;
    &-item {
      display: flex;
      justify-content: center;
      padding: 2px 15px;
      cursor: pointer;
    }
    &-item:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
  }
}
</style>