var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c(
          "div",
          { staticClass: "dialog-block" },
          [
            _c("div", { staticClass: "header" }, [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t(`${this.i18nPrefix}totalRebateAmount`))),
              ]),
            ]),
            _c("div", { staticClass: "divider" }),
            _vm._l(_vm.getRebateGamePlatformList, function (list) {
              return _c(
                "a",
                {
                  key: list.id,
                  class: { check: _vm.nowId === list.id },
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.showRebate(list.id)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(list.name)))]
              )
            }),
            _c(
              "div",
              { staticClass: "table-block" },
              [
                _c("TreeTable", {
                  ref: "treeTable",
                  attrs: {
                    list: _vm.allData,
                    column: _vm.tableColumn,
                    notShowChildren: true,
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }