var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask create-member",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("default.create")) +
                  _vm._s(_vm.$t(`title.gamesCategoryInfo`)) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.frontend`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.PlatformVersion,
                        expression: "createModel.PlatformVersion",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "PlatformVersion",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.platformVersions, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(" " + _vm._s(list.name) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gameCategory`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.GamesCategory,
                        expression: "createModel.GamesCategory",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "GamesCategory",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.gamesCategorys, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.GamePlatform,
                        expression: "createModel.GamePlatform",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "GamePlatform",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.gamePlatforms, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}sort`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.SortId,
                      expression: "createModel.SortId",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.SortId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "SortId", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}status`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.Status,
                        expression: "createModel.Status",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "Status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 1 * 1 } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: 2 * 1 } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.haveLobby`)) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.HaveLobby,
                        expression: "createModel.HaveLobby",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "HaveLobby",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isImageFullpage`)) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.IsFull,
                        expression: "createModel.IsFull",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "IsFull",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.isMobileImageFullpage`)
                    ) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.MobileIsFull,
                        expression: "createModel.MobileIsFull",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "MobileIsFull",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.isMainPageImageFullpage`)
                    ) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.MainPageIsFull,
                        expression: "createModel.MainPageIsFull",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "MainPageIsFull",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.isMobileMainPageImageIsFullpage`
                      )
                    ) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.MobileMainPageIsFull,
                        expression: "createModel.MobileMainPageIsFull",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "MobileMainPageIsFull",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}isHot`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.isHot,
                        expression: "createModel.isHot",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "isHot",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}isNew`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.isNew,
                        expression: "createModel.isNew",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "isNew",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.multiples`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.Multiples,
                      expression: "createModel.Multiples",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.Multiples },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "Multiples",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}depositLimit`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.depositLimit,
                      expression: "createModel.depositLimit",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.depositLimit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "depositLimit",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.title`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.Title,
                      expression: "createModel.Title",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.Title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "Title", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "textarea-div" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.copywriting`)) +
                  " "
              ),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createModel.Content,
                    expression: "createModel.Content",
                  },
                ],
                attrs: { rows: "5" },
                domProps: { value: _vm.createModel.Content },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.createModel, "Content", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "imgDiv" }, [
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("FaviconUp")
                    },
                  },
                },
                [
                  !_vm.FaviconUp
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "FaviconUp"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.FaviconUp } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("FaviconDown")
                    },
                  },
                },
                [
                  !_vm.FaviconDown
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "FaviconDown"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.FaviconDown } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("Image")
                    },
                  },
                },
                [
                  !_vm.Image
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "Image"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.Image } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("MobileImage")
                    },
                  },
                },
                [
                  !_vm.MobileImage
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "MobileImage"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.MobileImage } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("MainPageImage")
                    },
                  },
                },
                [
                  !_vm.MainPageImage
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "MainPageImage"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.MainPageImage } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("MobileMainPageImage")
                    },
                  },
                },
                [
                  !_vm.MobileMainPageImage
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "MobileMainPageImage"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.MobileMainPageImage } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("BackgroundImage")
                    },
                  },
                },
                [
                  !_vm.BackgroundImage
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "BackgroundImage"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.BackgroundImage } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("BackgroundImgMobile")
                    },
                  },
                },
                [
                  !_vm.BackgroundImgMobile
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "BackgroundImgMobile"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.BackgroundImgMobile } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("AppImage")
                    },
                  },
                },
                [
                  !_vm.AppImage
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "AppImage"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.AppImage } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("AppFaviconUp")
                    },
                  },
                },
                [
                  !_vm.AppFaviconUp
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "AppFaviconUp"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.AppFaviconUp } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("HomePageImg")
                    },
                  },
                },
                [
                  !_vm.HomePageImg
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "HomePageImg"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.HomePageImg } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("HomePageImgMobile")
                    },
                  },
                },
                [
                  !_vm.HomePageImgMobile
                    ? _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("@/assets/icon/upload.svg") },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.clickToUpload`)
                            ) + "HomePageImgMobile"
                          ),
                        ]),
                      ])
                    : _c("img", { attrs: { src: _vm.HomePageImgMobile } }),
                ]
              ),
            ]),
            _c("input", {
              ref: "file",
              staticClass: "custom-file",
              attrs: { type: "file" },
              on: { change: _vm.fileSelect },
            }),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: { click: _vm.create },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }