<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}cvsName`) }}
              <input type="text" v-model="createModel.CVSName" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <div class="textarea-div">
              {{ $t("default.remark") }}
              <textarea v-model="createModel.Description" rows="5"></textarea>
            </div>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('File')">
              <span v-if="!File">{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}Image</span>
              <img v-else :src="File" />
            </div>
          </div>
          <input type="file" ref="file" class="custom-file" @change="fileSelect" />
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validAllInput } from "@/utils/validate";
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  computed: {
    ...mapGetters(["getStatusList"])
  },
  props: ["i18nPrefix"],
  data() {
    return {
      createModel: {
        status: undefined,
        CVSName: undefined,
        Description: undefined
      },
      formData: new FormData(),
      File: undefined,
      chooiceImg: undefined,
    };
  },
  created() {
    this.createModel.status = this.getStatusList[0].id;
  },
  methods: {
    create() {
      this.createModel.status = this.createModel.status == 1 ? true : false;
      Object.entries(this.createModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });
      return validAllInput(this.createModel)
        ? Message.error(this.$t("default.fieldsNotEntered"), 1000)
        : this.$emit("create", this.formData);
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.input-block {
  label {
    width: 45% !important;
  }
  .textarea-div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.imgDiv {
  display: flex;
  flex-wrap: wrap;
  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;
    span {
      display: block;
      font-size: 20px;
    }
    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
  }
}
</style>