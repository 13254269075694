<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`title.SEO`) }}{{ $t(`default.edit`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
            {{ $t(`${i18nPrefix}dialog.edit-SEO.enterSEO`) }}
            <div class="textarea">
              <textarea v-model="preview" rows="39"></textarea>
            </div>
            <div class="advance-block">
              <button class="normal-btn" @click="$emit('editSEO',{ id , metadata: parseToSeo(preview) })">
                {{ $t("default.confirm") }}
              </button>
              <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
            </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMetaData,
} from "@/api/SEO/SEO";
import { parseToSeoHeader,parseToSeoArray } from "@/utils/SEOFunction";

export default {
  name: "EditSEO",
  props: ["id"],
  data() {
    return {
      editModel:{},
      preview: undefined,
      i18nPrefix: "SEO-Function.SEO."
    }
  },
  created() {
    getMetaData({ id: this.id }).then((res) => {
      this.editModel = res.data.metadata;
      this.preview = parseToSeoArray(this.editModel);
    });
  },
  methods: {
    parseToSeo(previewData) {
      return parseToSeoHeader(previewData);
    }
  }
}
</script>

<style lang="scss" scoped>

.dialog-container {
  width: 80%;
  max-height: 95%!important;
  .textarea {
    display: flex;
    flex-direction: column;
  }
}
</style>