<template>
  <div>
    <div ref="dashboard" class="container-view">
      <div class="d-flex mb-3">
        <QuickSearch ref="quickSearch" @changeTime="changeTime"></QuickSearch>
      </div>
      <div class="chart">
        <div class="d-flex">
          <Chart ref="chartRegister" :width="chartWidth" :title="chartRegister.title" :xAxis="chartRegister.xAxis" :yAxis="chartRegister.yAxis" :series="chartRegister.series" />
          <Chart ref="chartGame" :width="chartWidth" :title="chartGame.title" :legend="chartGame.legend" :series="chartGame.series" />
          <!-- <Chart ref="dynamicChart" :width="chartWidth" :title="dynamicChart.title" :xAxis="dynamicChart.xAxis" :series="dynamicChart.series" /> -->
          <!-- <Chart ref="chart2" :width="chartWidth" :title="chart2.title" :legend="chart2.legend" :xAxis="chart2.xAxis" :series="chart2.series" /> -->
        </div>
        <div class="d-flex">
          <Chart ref="chartPayment" :width="chartWidth" :title="chartPayment.title" :legend="chartPayment.legend" :xAxis="chartPayment.xAxis" :yAxis="chartPayment.yAxis" :series="chartPayment.series" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "@/layout/components/echart/index";
import QuickSearch from "@/layout/components/QuickSearch";
import { getPayment, getRegister, getGame } from "@/api/dataAnalysis/dashboard";
import { joinT } from "@/utils/transDateUtils";

export default {
  name: "Dashboard",
  components: { Chart, QuickSearch },
  data() {
    return {
      chartWidth: '100%',
      startTime: null,
      endTime: null,
      dateExtent: [],
      dynamicX: [],
      gameList: [],
      newMember: [],
      depositList: [],
      withdrawalList: [],
      depositRateList: [],
      newMemberDateExtent: [],
      depositAndWithdrawalDateExtent: [],
      // 動態假資料
      dynamicSeriesData: [],
      dynamicSeriesData2: [],
      dynamicInterval: undefined,
      chart2: {
        title: {
          text: '折線堆疊圖'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          left: 'center',
          top: '20'
        },
        xAxis: {
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            isStack: true,
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            isStack: true,
            data: [100, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ads',
            type: 'line',
            isStack: true,
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: 'Direct',
            type: 'line',
            isStack: true,
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: 'Search Engine',
            type: 'line',
            isStack: true,
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      },
      dynamicChart: {
        title: {
          text: '動態出入金'
        },
        xAxis: {
          data: []
        },
        series: [
          {
            name: '入金',
            type: 'bar',
            // unit: 'ml',
            data: []
          },
          {
            name: '出金',
            type: 'bar',
            // unit: 'ml',
            data: []
          }
        ]
      },
    };
  },
  computed: {
    ...mapGetters(["sidebar", "getGamePlatforms", "getGamesCategorys"]),
    chartPayment() {
      return {
        title: {
          text: this.$t("chart.title.depositAndWithdrawal"),
          subtext: `${this.$t("chart.title.unit")}: ${this.$t("chart.unit.dollars")}`
        },
        legend: {
          data: [
            this.$t("chart.yAxis.deposit"), 
            this.$t("chart.yAxis.withdrawal"), 
            this.$t("chart.yAxis.payRatio")
          ],
          left: 'center',
          top: '20'
        },
        xAxis: {
          data: this.depositAndWithdrawalDateExtent
        },
        yAxis: [
          {
            // name: 'Precipitation',
            // unit: this.$t("chart.unit.dollars")
          },
          {
            // name: 'Temperature',
            unit: '%'
          }
        ],
        series: [
          {
            name: this.$t("chart.yAxis.deposit"),
            type: 'bar',
            unit: this.$t("chart.unit.dollars"),
            data: this.depositList
          },
          {
            name: this.$t("chart.yAxis.withdrawal"),
            type: 'bar',
            unit: this.$t("chart.unit.dollars"),
            data: this.withdrawalList
          },
          {
            name: this.$t("chart.yAxis.payRatio"),
            type: 'line',
            yAxisIndex: 1,
            unit: '%',
            data: this.depositRateList
          }
        ]
      }
    },
    chartGame() {
      return {
        title: {
          text: this.$t("chart.title.gameStats"),
          subtext: `${this.$t("chart.title.unit")}: ${this.$t("chart.unit.totalBetCount")}`
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left',
        //   top: '30'
        // },
        series: [
          {
            name: '',
            type: 'pie',
            data: this.gameList
          }
        ]
      }
    },
    chartRegister() {
      return {
        title: {
          text: this.$t("chart.title.newMember"),
          subtext: `${this.$t("chart.title.unit")}: ${this.$t("chart.unit.person")}`
        },
        xAxis: {
          data: this.newMemberDateExtent
        },
        yAxis: [{
          // unit: this.$t("chart.unit.person")
        }],
        series: [
          {
            data: this.newMember,
            type: 'line',
            unit: this.$t("chart.unit.person"),
          }
        ]
      }
    },
  },
  watch: {
    getGamePlatforms: {
      handler() {
        this.$nextTick(() => {
          this.$refs.quickSearch.changeDate('week');
        });
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      // this.$refs.chart2.set();
      // this.$refs.dynamicChart.set();
      // this.dynamicInterval = setInterval(() => { this.dynamicSet(10) }, 2000);
    });
  },
  beforeDestroy() {
    if (this.dynamicInterval != undefined) {
      clearInterval(this.dynamicInterval);
    }
  },
  methods: {
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    async getGameList() {
      var searchModel = {
        memberAccount: "",          // 會員帳號
        isFuzzySearch: false,       // 會員帳號模糊搜尋
        agentId: 0,                 // 代理
        isIncludeSubAgent: false,   // 是否包含下線代理
        dimension: 2,               // 搜尋類別 1. 遊戲種類 2. 遊戲館 3. 遊戲館 + 遊戲種類
        startTime: joinT(this.startTime + this.GLOBAL.timeZone),
        endTime: joinT(this.endTime + this.GLOBAL.timeZone),
      }

      await getGame(searchModel).then(res => {
        this.gameList = res.data?.list.map(item => {
          return {
            value: item.totalBetCount,
            name: this.getGameName(item)  // 将 gamePlatform 转为字符串
          };
        });
      });

      this.$refs.chartGame.set();
    },
    async getNewMember() {
      var searchModel = {
        start: joinT(this.startTime + this.GLOBAL.timeZone),
        end: joinT(this.endTime + this.GLOBAL.timeZone),
      }

      await getRegister(searchModel).then(res => {
        this.newMember = res.data.map(item => {
          return item.count;
        });
        this.newMemberDateExtent = this.getDateExtent(res.data);
      });

      this.$refs.chartRegister.set();
    },
    async getDeposit() {
      var searchModel = {
        start: joinT(this.startTime + this.GLOBAL.timeZone),
        end: joinT(this.endTime + this.GLOBAL.timeZone),
      }

      await getPayment(searchModel).then(res => {
        this.depositList = res.data.map(item => {
          return item.depositAmount;
        });
        this.withdrawalList = res.data.map(item => {
          return item.withdrawalAmount;
        });
        this.depositRateList = res.data.map(item => {
          return Math.round(item.depositRate * 100); 
        });
        this.depositAndWithdrawalDateExtent = this.getDateExtent(res.data);
      });

      this.$refs.chartPayment.set();
    },
    changeTime(time) {
      this.startTime = time.startTime + this.GLOBAL.startSecond;
      this.endTime = time.endTime + this.GLOBAL.endSecond;

      this.getGameList();
      this.getNewMember();
      this.getDeposit();
    },
    getDateExtent(list) {
      var dateExtent = list.map(item => {
        var formattedDate = item.date.split('T')[0];
        return formattedDate;
      });
      return dateExtent;
    },
    getSevenDays() {
      // 获取今天的日期
      const today = new Date();

      // 创建一个数组来存储日期
      const sevenDays = [];

      // 循环七次，每次都将日期推前一天
      for (let i = 6; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);

        // 格式化日期为 YYYY-mm-dd
        const formattedDate = this.formatDate(date);

        // 将格式化后的日期添加到数组
        sevenDays.push(formattedDate);
      }

      return sevenDays;
    },
    dynamicSet(sec) {
      if (this.dynamicX.length >= 8) {
        this.dynamicX.shift();
        this.dynamicSeriesData.shift();
        this.dynamicSeriesData2.shift();
      }

      this.dynamicX.push(this.dynamicTime());
      this.dynamicSeriesData.push(this.getRandomNumber(0, 1000));
      this.dynamicSeriesData2.push(this.getRandomNumber(0, 500));

      var option = {
        xAxis: {
          data: this.dynamicX
        },
        series: [
          {
            data: this.dynamicSeriesData
          },
          {
            data: this.dynamicSeriesData2
          }
        ]
      };

      this.$refs.dynamicChart.setDynamic(option);
    },
    dynamicTime() {
      // 获取当前时间
      const currentTime = new Date();

      // 加上 10 秒
      currentTime.setSeconds(currentTime.getSeconds());

      // 格式化时间为 HH:mm:ss
      const formattedTime = this.formatTime(currentTime);

      return formattedTime;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份是从 0 开始计数的，需要加 1
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    formatTime(date) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    },
    getRandomNumber(min, max) {
      // 生成介于 min 到 max 之间的随机整数
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getGameName(data) {
      let name =
        (data.gamePlatform ? this.getGamePlatformName(data.gamePlatform) : "") +
        (data.gamesCategory && data.gamePlatform ? " + " : "") +
        (data.gamesCategory
          ? this.getGamesCategoryName(data.gamesCategory)
          : "");

      return name;
    },
    getGamePlatformName(id) {
      const index = this.getGamePlatforms.findIndex(e => e.id === id);
      return this.$t(this.getGamePlatforms[index].name);
    },
    getGamesCategoryName(id) {
      const index = this.getGamesCategorys.findIndex(e => e.id === id);
      return this.$t(this.getGamesCategorys[index].name);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
}
</style>
