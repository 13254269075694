export let searchModel = {
    agentId: undefined,
    startTime: undefined,
    endTime: undefined,
    "options": {
        "isIgnoreAdjustmentAdd": false,
        "isIgnoreAdjustmentReduce": false,
        "isIgnoreBet": true,
        "isIgnoreCashDeposit": true,
        "isIgnoreCashWithdrawal": false,
        "isIgnorePaymentDeposit": false,
        "isIgnoreDiscount": true,
        "isIgnoreEvent": false,
        "isIgnoreRebate": false,
        "isIgnoreWinLose": true
    },
    page:  {
        "take": 10,
        "skip": 0
    },
}