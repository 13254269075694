import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function memberDepositReportATMList(data) {
    return request({
        url: "/MemberDepositAtmReport/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositReportTotal(data) {
    return request({
        url: "/MemberDepositAtmReport/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function MemberDepositCryptoReportList(data) {
    return request({
        url: "/MemberDepositCryptoReport/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositCryptoTotal(data) {
    return request({
        url: "/MemberDepositCryptoReport/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function SelectMenu() {
    return request({
        url: "/MemberDepositCryptoReport/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function MemberDepositCsvReportList(data) {
    return request({
        url: "/MemberDepositCvsReport/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositCsvTotal(data) {
    return request({
        url: "/MemberDepositCvsReport/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function csvSelectMenu() {
    return request({
        url: "/MemberDepositCvsReport/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}