<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.balance-adjustment.adjustmentType4`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="account" disabled />
            </label>
            <div>
              <label>
                {{  $t(`${i18nPrefix}dialog.balance-adjustment.bonusReceive`) }}
                <input type="text" v-model="redPacket.bonusReceive" />
              </label>
              <label>
                {{  $t(`${i18nPrefix}dialog.balance-adjustment.totalBetAmountLimit`) }}
                <input type="text" v-model="redPacket.totalBetAmountLimit" />
              </label>
              <label>
                {{ $t("default.remark") }}
                <textarea cols="30" rows="6" v-model="redPacket.remark"></textarea>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="$emit('redPacket', redPacket);">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RedPacket",
  props: ["id", "account", "i18nPrefix"],
  data() {
    return {
      redPacket: {
        id: this.id,
        bonusReceive: 0,
        totalBetAmountLimit: 0,
        remark: undefined
      }
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 20%;

  .input-block {
    div {
      width: 100%;
    }

    label {
      width: 100%;
    }
  }
}
</style>