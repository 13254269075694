import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getVideoSettingList() {
    return request({
        url: "/VideoSetting/List",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createVideoSetting(data) {
    return request({
        url: "/VideoSetting/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateVideoSetting(data) {
    return request({
        url: "/VideoSetting/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteVideoSetting(data) {
    return request({
        url: "/VideoSetting/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}