<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("title.agent-info") }}</h4>
          <button class="normal-btn" @click="createPromotionLink"
            :disabled="!agentInfo.subDomain || !!agentInfo.promotionLink">{{ $t("default.create") + " " +
              $t(`${i18nPrefix}promotionLink`) }}</button>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="domain">
              <div class="ml-2">
                <font-awesome-icon v-show="agentInfo.subDomain" class="copyBtn" :icon="['fas', 'copy']"
                  @click="copyDomain('https://' + agentInfo.domain)" />
              </div>
              {{ 'https://' + agentInfo.domain }}
              <font-awesome-icon :icon="['fas', 'download']" @click="downloadQRCode(agentInfo.account)" />
              <qrcode v-show="false" background="#fff" :cls="'qrcode' + agentInfo.account"
                :value="'https://' + agentInfo.domain"></qrcode>
            </div>
            <div class="domain mt-2">
              <div class="ml-2">
                <font-awesome-icon v-show="agentInfo.subDomain" class="copyBtn" :icon="['fas', 'copy']"
                  @click="copyDomain('https://' + agentDamain + '?code=' + agentInfo.subDomain)" />
              </div>
              {{ 'https://' + agentDamain + '?code=' + agentInfo.subDomain }}
              <font-awesome-icon :icon="['fas', 'download']" @click="downloadQRCode(agentInfo.subDomain)" />
              <qrcode v-show="false" background="#fff" :cls="'qrcode' + agentInfo.subDomain"
                :value="'https://' + agentDamain + '?code=' + agentInfo.subDomain"></qrcode>
            </div>
            <div class="domain mt-2">
              {{ '?code=' + agentInfo.subDomain }}
              <div class="ml-2">
                <font-awesome-icon v-show="agentInfo.subDomain" class="copyBtn" :icon="['fas', 'copy']"
                  @click="copyDomain('?code=' + agentInfo.subDomain)"></font-awesome-icon>
              </div>
            </div>
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="agentInfo.name" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="agentInfo.account" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.CommissionCalculationCycle`) }}
              <select v-model="agentInfo.commissionCalculationCycle" disabled>
                <option v-for="list of GLOBAL.settlement" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="agentInfo.status" disabled>
                <option v-for="list of getStatusList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}promotionLink`) }}
              <input type="text" v-model="agentInfo.promotionLink" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}discountCommissionRate`) }}
              <input type="text" v-model="agentInfo.discountCommissionRate" disabled />
            </label>
            <div class="input-block">
              <div class="settingList">
                <label>
                  <h2>{{ $t(`${i18nPrefix}dialog.rebate`) }}</h2>
                </label>
                <label v-for="list of getGamesCategorys" :key="list.id">
                  {{ $t(list.name) }} %
                  <input type="text"
                    v-model="agentInfo.rebateSettingList.find(data => data.gamesCategoryId === list.id).rate" disabled />
                </label>
              </div>
              <div class="settingList">
                <label>
                  <h2>{{ $t(`${i18nPrefix}dialog.commission`) }}</h2>
                </label>
                <label v-for="list of getGamesCategorys" :key="list.id">
                  {{ $t(list.name) }} %
                  <input type="text"
                    v-model="agentInfo.commissionSettingList.find(data => data.gamesCategoryId === list.id).rate"
                    disabled />
                </label>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { createAgentPromotionLink } from "@/api/agent-management/agent";
import { agentInfo, getSelectMenu } from "@/api/agent-management/agent";
import Message from "@/layout/components/Message";
import Qrcode from "v-qrcode/src/index";
import { downloadCanvas } from "@/utils/download";

export default {
  name: "AgentInfo",
  data() {
    return {
      agentInfo: [],
      agentDamain: undefined,
      i18nPrefix: "agent.agent-management.",
    };
  },
  computed: {
    ...mapGetters(["getUserinfo", "getStatusList", "getGamesCategorys"])
  },
  components: {
    Qrcode
  },
  async created() {
    await getSelectMenu().then(res => (this.agentDamain = res.data.domain));
    agentInfo({ id: this.getUserinfo.parentId }).then(
      res => (this.agentInfo = res.data)
    );
  },
  methods: {
    createPromotionLink() {
      createAgentPromotionLink({ agentId: this.getUserinfo.parentId }).then(
        res => {
          if (res) this.$emit("toggle");
        }
      );
    },
    async copyDomain(domain) {
      navigator.clipboard.writeText(domain);
      Message.copy(`${domain} ` + this.$t("default.isCopy"), 1500);
    },
    downloadQRCode(d) {
      let myImg = document.querySelector(".qrcode" + d + " canvas");
      downloadCanvas(myImg.toDataURL(), "QRCode_" + d + ".jpg");
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 50%;

  .dialog-block {
    .header {
      display: flex;
      justify-content: space-between;
    }

    .input-block {
      .domain {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        input {
          text-align: center;
          font-size: 15px;
          padding: 0;
        }
      }

      label {
        width: 30%;
        margin: 15px auto;
      }

      .settingList {
        // width: v-bind(unleashWidth);
        width:50%;

        label {
          width: 30%;
        }
      }
    }
  }
}
</style>