<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label v-show="gateway">
              {{ $t(`${i18nPrefix}paymentCompanyInfo`) }}
              <select
                v-model="editModel.paymentCompanyInfoId"
              >
                <option
                  v-show="list.gateway === gateway"
                  v-for="list of paymentCompanyInfo"
                  :key="list.id"
                  :value="list.id"
                >{{ list.name }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}oldMemberMinDeposit`) }}
              <input
                type="text"
                v-model="editModel.oldMemberMinDeposit"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}oldMemberMaxDeposit`) }}
              <input
                type="text"
                v-model="editModel.oldMemberMaxDeposit"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}newMemberMinDeposit`) }}
              <input
                type="text"
                v-model="editModel.newMemberMinDeposit"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}newMemberMaxDeposit`) }}
              <input
                type="text"
                v-model="editModel.newMemberMaxDeposit"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}subName`) }}
              <select v-model.number="editModel.subId">
                <option value="0">{{ $t("default.none") }}</option>
                <option
                  v-for="list of companyDepositSettingsSub"
                  :key="list.id"
                  :value="list.id"
                >{{ list.name }}</option>
              </select>
            </label>
            <label v-show="isCrypto">
              <span>{{ $t(`${i18nPrefix}exchangeRate`) }} <span class="red">{{ $t(`${i18nPrefix}ex`) }}</span></span>
              <input
                type="text"
                v-model="editModel.exchangeRate"
              />
            </label>
            <label></label>
          </div>
          <h5 class="mt-3">{{ $t(`${i18nPrefix}option`) }}</h5>
          <div class="input-block-multiselect">
            {{ $t(`${i18nPrefix}memberLevel`) }}
            <multiselect
              v-model="memberLevelList"
              :placeholder="$t('default.plzSelect')"
              :options="getStars"
              label="star"
              track-by="star"
              :multiple="true"
              :preserve-search="true"
              :close-on-select="false"
              :custom-label="nameWithLang"
            ></multiselect>
          </div>
          <div class="input-block-multiselect">
            {{ $t(`${i18nPrefix}whiteList`) }}
            <multiselect
              v-model="memberWhiteListTags"
              :placeholder="$t('default.plzSelect')"
              :options="tagList"
              label="name"
              track-by="id"
              :multiple="true"
              :preserve-search="true"
              :close-on-select="false"
            ></multiselect>
          </div>
          <span class="red ml-5">{{ $t(`${i18nPrefix}remark`) }}</span>
          <div class="input-block-multiselect">
            {{ $t(`${i18nPrefix}blackList`) }}
            <multiselect
              v-model="memberBlackListTags"
              :placeholder="$t('default.plzSelect')"
              :options="tagList"
              label="name"
              track-by="id"
              :multiple="true"
              :preserve-search="true"
              :close-on-select="false"
            ></multiselect>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "Edit",
  props: [
    "id",
    "status",
    "gateway",
    "paymentCompanyInfoId",
    "paymentCompanyInfo",
    "oldMemberMinDeposit",
    "oldMemberMaxDeposit",
    "newMemberMinDeposit",
    "newMemberMaxDeposit",
    "exchangeRate",
    "isCrypto",
    "subId",
    "companyDepositSettingsSub",
    "conditionTags",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        paymentCompanyInfoId: this.paymentCompanyInfoId
          ? this.paymentCompanyInfoId
          : this.paymentCompanyInfo.find(data => data.gateway === this.gateway)
              ?.id,
        status: this.status,
        oldMemberMinDeposit: this.oldMemberMinDeposit,
        oldMemberMaxDeposit: this.oldMemberMaxDeposit,
        newMemberMinDeposit: this.newMemberMinDeposit,
        newMemberMaxDeposit: this.newMemberMaxDeposit,
        exchangeRate: this.exchangeRate,
        memberLevelList: [],
        memberWhiteListTags: [],
        memberBlackListTags: [],
        subId: this.subId
      },
      memberLevelList: [],
      memberWhiteListTags: [],
      memberBlackListTags: []
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(["getStatusList", "tagList", "getStars"])
  },
  mounted() {
    this.memberLevelList = this.getStars.filter(el =>
      this.conditionTags.memberLevelList
        .map(data => {
          return data;
        })
        .includes(el.id)
    );
    this.memberWhiteListTags = this.tagList.filter(el =>
      this.conditionTags.memberWhiteListTags
        .map(data => {
          return data;
        })
        .includes(el.id)
    );
    this.memberBlackListTags = this.tagList.filter(el =>
      this.conditionTags.memberBlackListTags
        .map(data => {
          return data;
        })
        .includes(el.id)
    );
  },
  methods: {
    edit() {
      this.editModel.memberLevelList = this.memberLevelList.map(
        data => data.id * 1
      );
      this.editModel.memberWhiteListTags = this.memberWhiteListTags.map(
        data => data.id * 1
      );
      this.editModel.memberBlackListTags = this.memberBlackListTags.map(
        data => data.id * 1
      );
      return this.$emit("edit", this.editModel);
    },
    nameWithLang({ star }) {
      return star + this.$t("default.star");
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  flex-wrap: nowrap !important;
  .dialog-container {
    width: 50%;
    label {
      width: 40% !important;
    }
    .input-block-multiselect {
      width: 90% !important;
      margin: auto;
    }
  }
}
</style>