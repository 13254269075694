import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu() {
    return request({
        url: "/CampaignLuckyDraw/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignLuckyDrawList(data) {
    return request({
        url: "/CampaignLuckyDraw/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignLuckyDrawProcess(data) {
    return request({
        url: "/CampaignLuckyDraw/Process",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignLuckyDraw(data) {
    return request({
        url: "/CampaignLuckyDraw/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCampaignLuckyDraw(data) {
    return request({
        url: "/CampaignLuckyDraw/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignLuckyDrawStyle() {
    return request({
        url: "/CampaignLuckyDraw/Style/List",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignLuckyDrawStyle(data) {
    return request({
        url: "/CampaignLuckyDraw/Style/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCampaignLuckyDrawStyle(data) {
    return request({
        url: "/CampaignLuckyDraw/Style/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignLuckyDrawTicket(data) {
    return request({
        url: "/CampaignLuckyDraw/ticket/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignLuckyDrawTicket(data) {
    return request({
        url: "/CampaignLuckyDraw/ticket/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTicketSample() {
    return request({
        url: "/CampaignLuckyDraw/ticket/Sample",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function getReport(data) {
    return request({
        url: "/CampaignLuckyDraw/Report",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function reportTotal(data) {
    return request({
        url: "/CampaignLuckyDraw/Report/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}