var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-status" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.star")))]),
            _c(
              "button",
              {
                staticClass: "confirm-btn main-style-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("updateLevel", _vm.member)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.confirm")))]
            ),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("label", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member.star,
                    expression: "member.star",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.member,
                      "star",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.stars, function (list) {
                return _c(
                  "option",
                  { key: list.star, domProps: { value: list.star } },
                  [_vm._v(_vm._s(list.star + _vm.$t("default.star")))]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }