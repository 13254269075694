var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
      ]),
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "control" }, [
        _c(
          "button",
          {
            class: { active: _vm.controlType == 61000 },
            on: {
              click: function ($event) {
                return _vm.control(61000)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("mail-box.riskControl")))]
        ),
        _c(
          "button",
          {
            class: { active: _vm.controlType == 10109 },
            on: {
              click: function ($event) {
                return _vm.control(10109)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("mail-box.bankPic")))]
        ),
        _c(
          "button",
          {
            class: { active: _vm.controlType == 120200 },
            on: {
              click: function ($event) {
                return _vm.control(120200)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("mail-box.blackList")))]
        ),
      ]),
    ]),
    _c("div", { staticClass: "container-view" }, [
      _c("main", [
        _c("div", { staticClass: "table-block" }, [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticStyle: { width: "20%" } }),
                _c("th", [_vm._v(_vm._s(_vm.$t("mail-box.type")))]),
                _c("th", { staticStyle: { width: "20%" } }, [
                  _vm._v(_vm._s(_vm.$t("mail-box.number"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              [
                !_vm.mailList || !_vm.mailList.length
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(_vm.$t("default.noData"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.mailList, function (mail) {
                  return _c("tr", { key: mail.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn",
                          on: {
                            click: function ($event) {
                              return _vm.readMail(mail)
                            },
                          },
                        },
                        [_vm._v("Go")]
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.getMailTypeList.find(
                                (data) => data.id === mail.permissionId
                              )
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [_vm._v("No. " + _vm._s(mail.orderId))]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }