import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function SelectMenu() {
    return request({
        url: "/GamesCategorySort/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function GamesCategorySortList(data) {
    return request({
        url: "/GamesCategorySort/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createGamesCategorySort(data) {
    return request({
        url: "/GamesCategorySort/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateGamesCategorySort(data) {
    return request({
        url: "/GamesCategorySort/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
