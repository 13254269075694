<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("default.agent") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block up-input">
            <div class="domain">
              <label class="checkbox-row m-0">
                <input type="checkbox" v-model="createModel.isSelfDomain">{{ $t(`${i18nPrefix}dialog.isSelfDomain`) }}
              </label>
              <span>
                https://
                <input :class="{ 'selfDomain': createModel.isSelfDomain }" type="text" v-model="subDomain" />
                {{ createModel.isSelfDomain ? '' : '.' + agentDamain }}
              </span>
            </div>
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="createModel.name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="createModel.account" />
              <span v-if="accountError" class="input-error">{{ $t(`${i18nPrefix}dialog.accountCheck`) }}</span>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.password`) }}
              <input type="text" v-model="createModel.password" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.CommissionCalculationCycle`) }}
              <select v-model="createModel.commissionCalculationCycle">
                <option v-for="list of GLOBAL.settlement" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="createModel.status">
                <option v-for="list of getStatusList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}phoneNumber`) }}
              <input type="text" v-model="phoneNumber" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}discountCommissionRate`) }}
              <input type="number" v-model.number="createModel.discountCommissionRate"
                @keyup="createModel.discountCommissionRate = GLOBAL.checkValue(createModel.discountCommissionRate, 2)" />
            </label>
            <label></label>
            <label v-show="!getUserinfo.permissions.includes(20103)"></label>
            <label v-show="getUserinfo.permissions.includes(20103)">
              {{ $t(`${i18nPrefix}proxyAgent`) }}
              <select v-model="createModel.proxyAgentId">
                <option :value="null * 1">{{ $t("default.none") }}</option>
                <option v-for="list of proxyAgentList" :key="list.id" :value="list.id">{{ $t(list.account) }}</option>
              </select>
            </label>
          </div>
          <div class="divider-h-2"></div>
          <div class="permission-block">
            <p>{{ $t(`${i18nPrefix}dialog.permissions`) }}</p>
            <div class="permission-box">
              <div class="permission-list" v-for="(list, index) of getPermissionsTitle" :key="list.id">
                <ul>
                  <li>
                    <label>
                      <input name="checkBox" :id="'main' + list.name" type="checkbox" :value="list.id"
                        v-model="createModel.permissions" @click="checkAll('main' + list.name)" />
                      {{ $t("Permission." + list.name) }}
                    </label>
                  </li>
                  <ul>
                    <template v-for="(firstTree, firstIndex) of firstData[index]">
                      <li :key="firstTree.id">
                        <label>
                          <input name="checkBox" :id="firstTree.name"
                            :class="['main' + list.name, 'parent' + list.name]" type="checkbox"
                            v-model="createModel.permissions" :value="firstTree.id"
                            @click="checkAll(firstTree.name, 'main' + list.name)" />
                          {{ $t("Permission." + firstTree.name) }}
                        </label>
                      </li>
                      <ul>
                        <li v-for="(secTree, secIndex) of secData[index][firstIndex]" :key="secData.id">
                          <label>
                            {{
    secIndex === secData[index][firstIndex].length - 1
      ? "└"
      : "├"
  }}
                            <input name="checkBox"
                              :class="['main' + list.name, firstTree.name, 'parent' + firstTree.name]" type="checkbox"
                              v-model="createModel.permissions" :value="secTree.id"
                              @click="checkAll(false, firstTree.name, 'main' + list.name)" />
                            {{ $t("Permission." + secTree.name) }}
                          </label>
                        </li>
                      </ul>
                    </template>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click.self="submit">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @mousedown.self="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ["agentId", "agentDamain", "permissionsData", "i18nPrefix"],
  data() {
    return {
      createModel: {
        agentId: this.agentId,
        account: undefined,
        name: undefined,
        password: undefined,
        isSelfDomain: false,
        accountFor: 1,
        commissionRateCasino: 1,
        commissionRateSlot: 1,
        commissionRateSport: 1,
        commissionRateOther: 1,
        status: 1,
        permissions: [],
        proxyAgentId: null * 1,
        commissionCalculationCycle: 1,
        discountCommissionRate: 0
      },
      accountError: false,
      subDomain: "",
      phoneNumber: undefined,
      accountForList: [],
      getPermissionsTitle: this.permissionsData.permissionsTitle,
      firstData: this.permissionsData.firstData,
      secData: this.permissionsData.secData,
      proxyAgentList: []
    };
  },
  computed: {
    ...mapGetters([
      "getUserinfo",
      "getStatusList",
      "getAgentList"
    ])
  },
  created() {
    this.calcAccountFor();
    this.proxyAgentList = this.getAgentList.filter(
      data => data.id !== this.agentId
    );
  },
  watch: {
    "createModel.account"(val) {
      var reg = /^[A-Za-z0-9]+$/;
      if (reg.test(val)) {
        this.accountError = false;
      } else {
        this.accountError = true
      }
    },
    'subDomain'(val, oldVal) {
       this.subDomain = (val.trim().length > 25) ? oldVal.trim() : val.trim()
    },
  },
  methods: {
    submit() {
      // if (validAllInput(this.createModel))
      //   return Message.error(this.$t("default.fieldsNotEntered"), 700);
      this.createModel.phoneNumber = this.phoneNumber;
      this.createModel.subDomain = this.subDomain;

      if (this.createModel.account === undefined) {
        Message.error(
          this.$t("default.checkInput", { input: this.$t(`${this.i18nPrefix}account`) }),
          1000
        );
        return;
      } else if (this.accountError) {
        Message.error(
          this.$t(`${this.i18nPrefix}dialog.accountCheck`),
          1000
        );
        return;
      }
      if (this.createModel.password === undefined) {
        Message.error(
          this.$t("default.checkInput", {
            input: this.$t(`${this.i18nPrefix}dialog.password`)
          }),
          1000
        );
        return;
      }

      this.createModel.permissions = [];
      document
        .querySelectorAll('input[name="checkBox"]:checked')
        .forEach(checkbox => {
          this.createModel.permissions.push(checkbox.value * 1);
        });
      if (this.createModel.permissions.length === 0) {
        Message.error(
          this.$t("default.checkInput", {
            input: this.$t(`${this.i18nPrefix}dialog.permissions`)
          }),
          1000
        );
        return;
      }
      this.$emit("create", this.createModel);
    },
    calcAccountFor() {
      for (let i = 0; i <= 100; i++) {
        this.accountForList.push(i);
      }
      return this.accountForList;
    },
    checkAll(type = false, parentType = false, main = false) {
      if (type) {
        let checkElements = document.getElementsByClassName(type);
        for (var i = 0; i < checkElements.length; i++)
          checkElements[i].checked = document.getElementById(type).checked
            ? true
            : false;
      }
      if (parentType) {
        document.getElementById(parentType).checked = true;
        if (type) {
          let checkMainElements = Object.values(
            document.getElementsByClassName(parentType)
          );
          document.getElementById(parentType).checked = checkMainElements.find(
            x => x.checked
          )
            ? true
            : false;
        }
        if (main) document.getElementById(main).checked = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.dialog-container {
  width: 50%;

  .dialog-block {
    .input-block {
      .domain {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        input {
          text-align: center;
          font-size: 15px;
          padding: 0;
        }

        .selfDomain {
          width: 70%;
        }
      }

      label {
        width: 30%;
        margin: 15px auto;
        position: relative;

        .input-error {
          position: absolute;
          bottom: -20px;
          color: #f00;
        }
      }
    }
  }
}

.permission-list {
  input {
    height: inherit;
    padding: initial;
  }

  ul {
    margin-top: 5px;
    margin-left: 20px;
  }
}

.permission-box {
  justify-content: left !important;
}

@media screen and (max-width: 768px) {
  .header {
    .header-btn {
      display: flex;
      button {
        flex-shrink: 0;
      }
    }
  }
  .up-input {
    label {
      width: 50% !important;
    }
  }
  .rebate-input {
    label {
      width: 100% !important;
    }
  }
  .domain {
    font-size: 14px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    label {
      width: 100%;
    }
    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
      width: 100%;
      input {
        width: 30%;
      }
    }
  }
}
</style>