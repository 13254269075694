var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}preBalance`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}afterBalance`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.createTime`)))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.fixeddepositForm.account))]),
        _c("td", [_vm._v(_vm._s(_vm.fixeddepositForm.preBalance))]),
        _c("td", { class: _vm.fontColor(_vm.detailsData.amount) }, [
          _vm._v(_vm._s(_vm.fixeddepositForm.amount)),
        ]),
        _c("td", [_vm._v(_vm._s(_vm.fixeddepositForm.afterBalance))]),
        _c("td", [_vm._v(_vm._s(_vm.fixeddepositForm.remark))]),
        _c("td", [_vm._v(_vm._s(_vm.fixeddepositForm.createTime))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }