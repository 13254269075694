import store from "@/store";

let date = new Date();
const getYear = date.getFullYear();
const getMonth = date.getMonth() + 1;
const getDate = date.getDate();
const month = getMonth.toString().length > 1 ? getMonth : `0${getMonth}`;
const day = getDate.toString().length > 1 ? getDate : `0${getDate}`;

// export function getUtcTime() {
//     const utc = parseInt(store.getters.timeZone.match(/([-+]?\d+)/)[0], 10); // 取得時區時間
//     const date = new Date();
//     // 取得本地時區偏移值（分鐘為單位）
//     const timezoneOffset = date.getTimezoneOffset();
//     // 設定目標時區偏移值（分鐘為單位）
//     const targetTimezoneOffset = -(60 * utc);
//     // 計算本地時區與目標時區的差值（毫秒為單位）
//     const timezoneDifference = (targetTimezoneOffset - timezoneOffset) * 60 * 1000;
//     const utcDate = new Date(date.getTime() - timezoneDifference);
//     return utcDate;
// }

export function transNow(type = "default") {
    let date = new Date();
    const getYear = date.getFullYear();
    const getMonth = date.getMonth() + 1;
    const getDate = date.getDate();
    const getHours = date.getHours();
    const getMinutes = date.getMinutes();
    const getSeconds = date.getSeconds();
    const month = getMonth.toString().length > 1 ? getMonth : `0${getMonth}`;
    const day = getDate.toString().length > 1 ? getDate : `0${getDate}`;
    const hour = getHours.toString().length > 1 ? getHours : `0${getHours}`;
    const min = getMinutes.toString().length > 1 ? getMinutes : `0${getMinutes}`;
    const sec = getSeconds.toString().length > 1 ? getSeconds : `0${getSeconds}`;

    var result;
    switch (type) {
        case "onlyNum":
            result = `${getYear}${month}${day}${hour}${min}${sec}`;
            break;
        default:
            result = `${getYear}-${month}-${day} ${hour}:${min}:${sec}`;
    }
    return result;
}

export function transYestetday(origin) {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    const getYear = date.getFullYear();
    const getMonth = date.getMonth() + 1;
    const getDate = date.getDate();
    const month = getMonth.toString().length > 1 ? getMonth : `0${getMonth}`;
    const day = getDate.toString().length > 1 ? getDate : `0${getDate}`;
    return `${getYear}-${month}-${day}`;
}

export function transToday(origin) {
    let time = "";
    origin === "from" ? time = "00:00" : time = "23:59";
    return `${getYear}-${month}-${day} ${time}`;
}

export function transTodayDate() {
    return `${getYear}-${month}-${day}`;
}

export function transTodayHour(origin) {
    let time = "";
    origin === "from" ? time = "00" : time = "23";
    return `${getYear}-${month}-${day} ${time}`;
}

export function joinTimeForDateFormat(time) {
    return `${getYear}-${month}-${day}T${time}:00+08:00`;
}

// input type="date"
export function splitTimeForDateFormat(time) {
    const index = time.indexOf("T") + 1;
    let result = time.split("");
    result.splice(0, index);
    result.splice(-3, 3);
    return result.join("");
}

// input type="time"
export function splitTimeForTimeFormat(time) {
    const index = time.indexOf(" ") + 1;
    let result = time.split("");
    result.splice(0, index);
    return result.join("");
}

export function transDayOfWeek(data) {
    switch (data) {
        case 0: return "星期一";
        case 1: return "星期二";
        case 2: return "星期三";
        case 3: return "星期四";
        case 4: return "星期五";
        case 5: return "星期六";
        case 6: return "星期日";
    }
}

export function transDateForOrin(date) {
    date = date.split("");
    date.splice(16);
    return date.join("");
}

export function spliceTForDate(date) {
    const index = date.indexOf("T");
    date = date.split("");
    date.splice(index, 1, " ");
    date = date.join("");
    return date;
}

export function joinT(date) {
    return date.replace(" ", "T");
}