<template>
  <div>
    <div class="container-view overview-block">
      <div class="title">
        {{ $t(`${i18nPrefix}rebateGame.commission`) }}
        <select
          v-model.number="searchForm.agentRebateGeneralId"
        >
          <option v-for="item of dateList" :key="item.id" :value="item.id">{{ item.createTime }}</option>
        </select>
      </div>
      <div class="main">
        <div class>
          <Commission
            v-if="agentCommission.list.length"
            :agentCommission="agentCommission"
            :i18nPrefix="i18nPrefix"
          ></Commission>
        </div>
      </div>
    </div>
    <div class="container-view overview-block">
      <div class="title">{{ $t(`${i18nPrefix}rebateGame.rebate`) }}</div>
      <div class="main">
        <div class>
          <Rebate
            v-if="agentRebate.list.length"
            :agentRebate="agentRebate"
            :i18nPrefix="i18nPrefix"
          ></Rebate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listByGamesCategory, getRebateTime } from "@/api/historyReport";
import Commission from "./list/Commission";
import Rebate from "./list/Rebate";
import { getServerLang } from "@/api/user";

export default {
  name: "HistoryReport",
  data() {
    return {
      agentCommission: {
        agentData: [],
        userData: [],
        total: [],
        list: []
      },
      agentRebate: {
        agentData: [],
        userData: [],
        total: [],
        list: []
      },
      searchForm: {
        agentId: this.$route.query.agentId * 1,
        agentRebateGeneralId: this.$route.query.agentRebateGeneralId * 1
      },
      dateList: [],
      i18nPrefix: "historyReport."
    };
  },
  components: {
    Commission,
    Rebate
  },
  async created() {
    await getServerLang(this.$route.query.lang).then(res => {
      this.$i18n.setLocaleMessage(
        "localeLang",
        Object.assign([], this.GLOBAL.langData[this.$route.query.lang], res)
      );
    });
    await this.$store.dispatch("select_menu/getAllSelect", this.getUserinfo);

    this.agentRebateGeneralId = this.$route.query.agentRebateGeneralId * 1;
    await this.getRebateList();
  },
  watch: {
    "searchForm.agentRebateGeneralId": {
      handler() {
        this.getList();
      },
      immediate: true
    }
  },
  methods: {
    async getList() {
      await listByGamesCategory(this.searchForm).then(res => {
        // 佔成報表
        this.agentCommission.agentData = res.data.agentCommission.agents;
        this.agentCommission.userData = res.data.agentCommission.users;
        this.agentCommission.total = res.data.agentCommission.total;

        this.agentCommission.agentData.agentAccount = this.agentCommission
          .agentData.agentAccount
          ? this.agentCommission.agentData.agentAccount + " (agents)"
          : "";
        this.agentCommission.userData.agentAccount = this.agentCommission
          .userData.agentAccount
          ? this.agentCommission.userData.agentAccount + " (users)"
          : "";
        this.agentCommission.total.agentAccount = this.$t("default.total");
        this.agentCommission.total.gamesCategory = 99999;

        this.agentCommission.list = [
          this.agentCommission.userData,
          this.agentCommission.agentData,
          this.agentCommission.total
        ];

        // 返水報表
        this.agentRebate.agentData = res.data.agentRebate.agents;
        this.agentRebate.userData = res.data.agentRebate.users;
        this.agentRebate.total = res.data.agentRebate.total;

        this.agentRebate.agentData.agentAccount = this.agentRebate.agentData
          .agentAccount
          ? this.agentRebate.agentData.agentAccount + " (agents)"
          : "";
        this.agentRebate.userData.agentAccount = this.agentRebate.userData
          .agentAccount
          ? this.agentRebate.userData.agentAccount + " (users)"
          : "";
        this.agentRebate.total.agentAccount = this.$t("default.total");
        this.agentRebate.total.gamesCategory = 99999;

        this.agentRebate.list = [
          this.agentRebate.userData,
          this.agentRebate.agentData,
          this.agentRebate.total
        ];
      });
    },
    async getRebateList() {
      await getRebateTime({ agentId: this.$route.query.agentId * 1 }).then(
        res => {
          this.dateList = res.data;
        }
      );
    }
  }
};
</script>

<style lang="scss">
.overview-table {
  width: 100%;
  .table-block {
    width: 100%;
    table {
      thead {
        th {
          border: 0;
          // background: #ebedee;
        }
      }
      tbody {
        tr:nth-child(odd) {
          background: #f6fdff;
        }
      }
    }
  }
}

.overview-list-rwd {
  display: flex;
}

@media screen and (max-width: 1440px) {
  .overview-list-rwd {
    flex-direction: column;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
</style>