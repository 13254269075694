<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}walletName`) }}
              <input type="text" v-model="editModel.walletName" disabled />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.status">
                <option v-for="list of getStatusList" :key="list.id" :value="list.id * 1">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.currencyName") }}
              <input type="text" v-model="editModel.currencyName" disabled />
            </label>
            <label>
              <span>{{ $t(`${i18nPrefix}exchangeRate`) }} <span class="red">{{ $t(`${i18nPrefix}ex`) }}</span></span>
              <input type="number" v-model.number="editModel.exchangeRate" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('edit', editModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Edit",
  props: ["data", "i18nPrefix"],
  data() {
    return {
      editModel: _.cloneDeep(this.data)
    };
  },
  computed: {
    ...mapGetters(["getStatusList"])
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 70%;
  label {
    width: 40% !important;
  }
}
</style>