<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input
                id="isFuzzySearch"
                type="checkbox"
                v-model="searchForm.isFuzzySearch"
              />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}transactionType`) }}
            <select v-model="searchForm.transactionType">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option
                v-for="list of transactionTypeList"
                :key="list.id"
                :value="list.id"
              >{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t(`${i18nPrefix}transactionType`) }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}preTradeBalance`) }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}afterTradeBalance`) }}</th>
              <th>{{ $t("default.remark") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || allData.length === 0">
              <td colspan="7">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="list.createTime + '_' + index">
              <td>
                {{
                GLOBAL.i18nCheckName(
                transactionTypeList.find((data) =>
                data.id === list.transactionType))
                }}
              </td>
              <td>{{ list.memberAccount }}</td>
              <td>
                <router-link
                  :to="{
                          path: '/report-bet-count/bet-record',
                          query: { startTime: list.createTime, memberAccount: list.memberAccount }
                       }"
                >{{ list.createTime }}</router-link>
              </td>
              <td>{{ new Intl.NumberFormat().format(list.preTradeBalance) }}</td>
              <td
                :class="Subtract(list.preTradeBalance,list.afterTradeBalance)"
              >{{ new Intl.NumberFormat().format(list.afterTradeBalance - list.preTradeBalance) }}</td>
              <td>{{ new Intl.NumberFormat().format(list.afterTradeBalance) }}</td>
              <td>{{ list.remark }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
  </div>
</template>

<script>
import { memberWalletRecordList } from "@/api/report/wallet-history";
import { getTransactionType } from "@/api/report/quota-history";
import { searchModel } from "./model";
import { transToday, joinT } from "@/utils/transDateUtils";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import Message from "@/layout/components/Message";

export default {
  name: "WalletHistory",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      transactionTypeList: [],
      i18nPrefix: "report.wallet-history."
    };
  },
  mixins: [handleServerPageUtils],
  components: {
    serverPagination,
    QuickSearch
  },
  async created() {
    await getTransactionType(this.searchForm).then(
      res =>
        (this.transactionTypeList = res.data.transactionType.map(data => {
          return { id: data.id, name: "TransactionType." + data.name };
        }))
    );
  },
  methods: {
    search(page = false) {
      if ((this.searchForm.memberAccount || "").trim().length === 0) {
        Message.error(
          this.$t("default.checkInput", { input: this.$t("default.member") }),
          1000
        );
        return;
      }
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone)
        : undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      memberWalletRecordList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    Subtract(preTradeBalance, afterTradeBalance) {
      return preTradeBalance - afterTradeBalance > 0 ? "red" : "green";
    }
  }
};
</script>

<style lang="scss" scoped>
</style>