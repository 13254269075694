export let searchModel = {
    createStartTime: undefined,
    createEndTime: undefined,
    auditStartTime: undefined,
    auditEndTime: undefined,
    paymentStatuses: [],
    agentId: 0,
    paymentName: "",
    memberAccount: "",
    storeCode: "",
    orderNumber: "",
    gateway: 0,
    tagId: 0,
    memberType: 0,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}