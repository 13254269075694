<template>
  <div class="dialog-mask2">
    <div ref="dialog" class="dialog-container">
      <div class="dialog-header">
        <h4>{{ $t(`default.${editData !== undefined ? 'edit' : 'create'}`) }}</h4>
        <div class="dialog-close" @click="$emit('toggle')">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="dialog-block">
        <main>
          <div class="input-block">
            <div class="divider-v2">
              <!-- 基本設定 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.basicSettings`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 名稱 -->
                <label>
                  {{ $t(`${i18nPrefix}campaignName`) }}
                  <input type="text" :placeholder="$t(`${i18nPrefix}campaignName`)" v-model="formModel.name"
                    :disabled="isCampaign && !padding" />
                </label>
              </div>
              <div class="form-box">
                <!-- 金額 -->
                <label>
                  <span>{{ $t('default.amount') }}</span>
                  <input type="number" min="1" :placeholder="$t('default.amount')" v-model.number="ruleSetting.amount" />
                </label>
                <!-- 流水倍率 -->
                <label>
                  <span>{{ $t(`${i18nPrefix}wageringRate`) }}</span>
                  <input type="number" min="1" :placeholder="$t(`${i18nPrefix}wageringRate`)"
                    v-model.number="ruleSetting.wageringRate" />
                </label>
              </div>
              <div class="form-box">
                <!-- 兌換碼 -->
                <label>
                  <div>
                    <span>{{ $t(`${i18nPrefix}redeemCode`) }}</span>
                    <span class="red">&nbsp;&nbsp;{{ "※" + $t(`${i18nPrefix}dialog.redeemCodeMsg`) }}</span>
                  </div>
                  <input type="text" :placeholder="$t(`${i18nPrefix}redeemCode`)" v-model="ruleSetting.redeemCode"
                    :disabled="isCampaign && !padding" />
                </label>
              </div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- startTime -->
                <label>
                  <span>{{ $t('default.startTime') }}</span>
                  <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"
                    :disabled-date="disabledStart"></date-picker>
                  <span ref="rulesstartTime" class="form-rules"></span>
                </label>
                <!-- endTime -->
                <label>
                  <span>{{ $t('default.endTime') }}</span>
                  <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"
                    :disabled-date="disabledEnd"></date-picker>
                  <span ref="rulesendTime" class="form-rules"></span>
                </label>
              </div>
              <div class="form-box">
                <!-- 使用次數 -->
                <label>
                  <span>{{ $t(`${i18nPrefix}count`) }}</span>
                  <input type="number" min="1" :placeholder="$t(`${i18nPrefix}count`)" v-model.number="ruleSetting.count"
                    @keyup="ruleSetting.count = GLOBAL.checkValue(ruleSetting.count, 0, 1, 9999999)" />
                </label>
              </div>
              <div class="form-box">
                <!-- 同會員每日使用次數 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox" v-model="ruleSetting.dailyUsageLimit.isEnabled" />
                    {{ $t(`${i18nPrefix}dailyUsageLimit`) }}
                  </span>
                  <input type="number" :placeholder="$t(`${i18nPrefix}dailyUsageLimit`)"
                    :disabled="!ruleSetting.dailyUsageLimit.isEnabled"
                    v-model.number="ruleSetting.dailyUsageLimit.count" />
                </label>
                <!-- ip次數限制 -->
                <label>
                  <span class="span-row">
                    <input type="checkbox" v-model="ruleSetting.ipLimit.isEnabled" />
                    {{ $t(`${i18nPrefix}dialog.ipLimit`) }}
                  </span>
                  <input type="number" :placeholder="$t(`${i18nPrefix}dialog.ipLimit`)"
                    :disabled="!ruleSetting.ipLimit.isEnabled" v-model.number="ruleSetting.ipLimit.count" />
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.eligibility`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 限定代理 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.includeAgents`) }}
                  <multiselect v-model="agentData" label="name" track-by="id" :placeholder="''" :options="agentList"
                    :multiple="true" :close-on-select="false" :disabled="isCampaign && !padding">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
              <div class="form-box">
                <!-- 限定會員標籤 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberTag`) }}
                  <multiselect v-model="memberTag" label="name" track-by="id" :placeholder="''" :options="tagList"
                    :multiple="true" :close-on-select="false" :disabled="isCampaign && !padding">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
            </div>
            <div class="form-box">
              <div class="form-box">
                <!-- 限定會員等級 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberLevel`) }}
                  <multiselect v-model="memberStar" label="name" track-by="star" :placeholder="''" :options="starList"
                    :multiple="true" :close-on-select="false" :disabled="isCampaign && !padding"></multiselect>
                </label>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div class="advance-block-release" v-show="detail == undefined">
        <div class="m-auto">
          <button class="normal-btn" @click="submit()"
            :disabled="ruleSetting.redeemCode.length > 50 || ruleSetting.redeemCode.length == 0">
            {{ $t("default.confirm") }}
          </button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
        </div>
        <button class="btn-orange"
          v-show="isCampaign == undefined && editData !== undefined && getUserinfo.permissions.includes(171401)"
          @click="submit(true)" :disabled="ruleSetting.redeemCode.length > 50 || ruleSetting.redeemCode.length == 0">{{
            $t(`${i18nPrefix}release`) }}
        </button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import {
  createCampaignSetting,
  updateCampaignSetting,
  createRedeemCode,
  updateRedeemCode
} from "@/api/campaign/redeemCode";
import { emptyFormModel, ruleSetting } from "./model";
import {
  transToday,
  joinT,
  transDateForOrin
} from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "Edit",
  props: [
    "detail",
    "padding",
    "isCampaign",
    "editData",
    "starList",
    "tagList",
    "agentList",
    "i18nPrefix"
  ],
  components: {
    Multiselect
  },
  data() {
    return {
      emptyFormModel,
      ruleSetting,
      isFrist: false, // 判斷是否是修改時欄位注入
      memberTag: [],
      memberStar: [],
      agentData: [],
      formModel: undefined,
      startTime: transToday("from"),
      endTime: transToday("to"),
      editModel: {},
      editModelTmp: {},
    };
  },
  computed: {
    ...mapGetters([
      "getUserinfo"
    ]),
  },
  watch: {
    "formModel.name"(val) {
      this.formModel.name = val.trim();
    },
    "ruleSetting.redeemCode"(val, oldVal) {
      if (val.length > 50 || (val !== '' && !/^[a-zA-Z0-9]+$/.test(val))) {
        this.ruleSetting.redeemCode = (oldVal.trim() ?? undefined);
        return
      }
      this.ruleSetting.redeemCode = val.trim();
    },
  },
  async created() {
    this.formModel = _.cloneDeep(this.emptyFormModel);

    // 處理修改欄位
    this.processEditColumn();

    setTimeout(() => {
      this.isFrist = false;
    }, 200);
  },
  async beforeCreate() { },
  methods: {
    /**
     * 處理修改欄位
     */
    processEditColumn() {
      if (this.editData !== undefined) {
        this.isFrist = true;

        const ruleSetting = JSON.parse(this.editData.ruleSetting);
        for (const [key, value] of Object.entries(this.formModel)) {
          if (this.editData[key] !== undefined) {
            this.formModel[key] = this.editData[key];
          }
        }
        this.startTime = transDateForOrin(this.formModel.startTime);
        this.endTime = transDateForOrin(this.formModel.endTime);
        this.ruleSetting = ruleSetting;

        // 處理多選套件格式
        if (ruleSetting.participateStatus.includeTags.length > 0) {
          this.memberTag = this.tagList.filter(e => {
            return ruleSetting.participateStatus.includeTags.indexOf(e.id) > -1;
          });
        }
        if (ruleSetting.participateStatus.includeLevels.length > 0) {
          this.memberStar = this.starList.filter(e => {
            return (
              ruleSetting.participateStatus.includeLevels.indexOf(e.star) > -1
            );
          });
        }
        if (ruleSetting.participateStatus.includeAgents.length > 0) {
          this.agentData = this.agentList.filter(e => {
            return (
              ruleSetting.participateStatus.includeAgents.indexOf(e.id) > -1
            );
          });
        }
      }
    },
    /**
     * 送出
     */
    async submit(release = false) {
      this.formModel.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.formModel.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone)
        : undefined;

      // 填入 等級＆代理＆會員標籤
      this.ruleSetting.participateStatus.includeTags = [];
      this.ruleSetting.participateStatus.includeLevels = [];
      this.ruleSetting.participateStatus.includeAgents = [];
      if (this.memberTag.length > 0) {
        this.ruleSetting.participateStatus.includeTags = this.memberTag.map(
          e => {
            return e.id;
          }
        );
      }

      if (this.memberStar.length > 0) {
        this.ruleSetting.participateStatus.includeLevels = this.memberStar.map(
          e => {
            return e.star;
          }
        );
      }

      if (this.agentData.length > 0) {
        this.ruleSetting.participateStatus.includeAgents = this.agentData.map(
          e => {
            return e.id;
          }
        );
      }
      this.formModel.ruleSetting = JSON.stringify(this.ruleSetting);

      let formData = new FormData();
      Object.entries(this.formModel).forEach(([key, value]) => {
        if (value) formData.append(key, value);
      });

      let result = {};
      if (release) {
        await createRedeemCode(this.formModel).then(res => {
          result = res;
        });
      } else if (this.editData === undefined) {
        await createCampaignSetting(formData).then(res => {
          result = res;
        });
      } else if (this.isCampaign) {
        await updateRedeemCode(this.formModel).then(res => {
          result = res;
        });
      } else {
        await updateCampaignSetting(formData).then(res => {
          result = res;
        });
      }

      if (result) {
        this.$emit("toggle");
        this.$emit("search");
      }

      return;
    },
    disabledStart(date) {
      if (this.endTime) {
        return date > new Date(this.endTime + this.GLOBAL.endSecond)
      };
    },
    disabledEnd(date) {
      if (this.startTime) {
        return date < new Date(this.startTime + this.GLOBAL.startSecond)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.dialog-block {
  height: 100% !important;
}
</style>