import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function paymentCryptoList(data) {
    return request({
        url: "/paymentCrypto/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createPaymentCrypto(data) {
    return request({
        url: "/paymentCrypto/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editPaymentCrypto(data) {
    return request({
        url: "/paymentCrypto/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}