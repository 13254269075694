<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <img :src="image" >
  </div>
</template>

<script>

export default {
  name: "Photo",
  props: [
    "image",
  ],
};
</script>

<style lang="scss" scoped>
img {
  max-height: 80%;
  max-width: 80%;
  width: auto;
  height: auto;
  margin: auto;
}
@media screen and (max-width: 1280px) {
  img {
    max-height: 80%;
    max-width: 80%;
    width: auto;
    height: auto;
    margin: auto;
  }
}
</style>