import {parse} from "node-html-parser";

export let metaData = {
    title: "",
    meta: [{
        tag: "",
        tagValue: "",
        content: "",
    }],
    link: [
        [{
            tag: "",
            value: "",
        }],
    ],
    script: [{
        type: "",
        async: false,
        src: "",
        innerHtml: "",
    }],
}

export function addSeoArray(type, dataType, metaData) {
    console.log(metaData)
    let obj = {};
    let newMeataData = [];
    switch (dataType) {
        case 'meta':
            obj = {
                tag: "",
                tagValue: "",
                content: "",
            };
            return  type === "+"
                ? metaData.meta.push(obj)
                : metaData.meta.length === 1
                    ? metaData.meta
                    : metaData.meta.pop();
            break;
        case 'link':
            obj = [{
                tag: "",
                value: "",
            }];
            return type === "+"
                ? metaData.link.push(obj)
                : metaData.link.length === 1
                    ? metaData.link
                    : metaData.link.pop();
            break;
        case 'script':
            obj = {
                type: "",
                async: false,
                src: "",
                innerHtml: "",
            };
            return type === "+"
                ? metaData.script.push(obj)
                : metaData.script.length === 1
                    ? metaData.script
                    : metaData.script.pop();
            break;
        default:
            obj = {
                tag: "",
                value: "",
            };
            return type === "+"
                ? metaData.link[dataType].push(obj)
                : metaData.link[dataType].length === 1
                    ? metaData.link[dataType]
                    : metaData.link[dataType].pop();
            break;
    }
}

export function parseToSeoArray(metaData) {
    let SEOArray = '';
    SEOArray = (metaData?.title) ? '<title>'+metaData.title+'</title>\n' : "";
    SEOArray += metaData?.meta.map((meta) =>
        meta.tag || meta.tagValue || meta.content ? '<meta '+meta.tag+'="'+meta.tagValue+'" content="'+meta.content+'">\n' : '').join('');
    SEOArray += metaData?.link.map((link) => link[0].tag || link[0].value ? '<link '+link.map((data) => data.tag+'="'+data.value+'" ').join('')+'>\n' : '').join('');
    SEOArray += metaData?.script.filter((find) => find.async || find.src || find.innerHtml).map((script) =>
        '<script'+(!script.innerHtml && !script.type ? ((script.async ? ' async src="' : ' src="')+script?.src+'">')
            : script.type ? " type='" + script.type + "'>" : ">")+
        (!script.async && !script.src ? '\n' + script.innerHtml + "\n" : "")+'<\/script>\n').join('') ;

    return SEOArray;
}

export function parseToSeoHeader(html) {
    const root = parse(html);
    let header = {
        title: root.querySelector('title')?.rawText ?? '',
        meta: parseToMeta(root.querySelectorAll('meta')),
        link: parseToLink(root.querySelectorAll('link')),
        script: parseToScript(root.querySelectorAll('script'))
    };

    return header;
}

function parseToMeta(metaList) {
    let obj = [];

    for (let meta of metaList) {
        let m = {};

        for (let key in meta.attributes) {
            const index = Object.keys(meta.attributes).indexOf(key);
            if (index === 0) {
                m.tag = key;
                m.tagValue = meta.attributes[key]
            } else {
                m.content = meta.attributes[key];
            }
        }
        obj.push(m);
    }
    if(!obj.length)
        obj= [{
            tag: "",
            tagValue: "",
            content: "",
        }];

    return obj;
}

function parseToLink(link) {
    let linkList = [];

    for (let o of link) {
        let arr = [];

        for (let key in o.attributes) {
            let obj = {
                tag: key,
                value: o.attributes[key]
            }

            arr.push(obj);
        }

        linkList.push(arr);
    }

    if(!linkList.length)
        linkList= [
            [{
                tag: "",
                value: "",
            }],
        ];
    return linkList;
}

function parseToScript(script) {
    let scriptList = [];

    for (let s of script) {
        let obj = {
            async: false,
            src: '',
            type: '',
            innerHtml: s.rawText.replace(/^(\r\n|\n|\r|)+/gm, ""),
        }

        for (let key in s.attributes) {
            switch (key) {
                case 'async':
                    obj.async = true;
                    break
                case 'src':
                    obj.src = s.attributes[key];
                    break
                case 'type':
                    obj.type = s.attributes[key];
                    break
            }
        }

        scriptList.push(obj);
    }

    if(!scriptList.length)
        scriptList= [{
            type: "",
            async: false,
            src: "",
            innerHtml: "",
        }];
    return scriptList;
}

export function SEOSort(data) {
    return data.filter(data => data.isTop === true).sort((a,b) => new Date(b['updateTime']) - new Date(a['updateTime'])).concat(
        data.filter(data => data.isTop === false).sort((a,b) => new Date(b['publishTime']) - new Date(a['publishTime']))
    )
}