<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button 
            class="normal-btn" 
            :disabled="!getUserinfo.permissions.includes(10601)"
            @click="handleDialog(undefined, 'Create')"
          >
            {{ $t(`${i18nPrefix}create`) }}
          </button>
        </div>
      </div>
      <div class="divider"></div>
      <h5>{{ $t(`${i18nPrefix}setStar`) }}</h5>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}memberLevel`) }}</th>
                <th>{{ $t(`${i18nPrefix}count`) }}</th>
                <th>{{ $t(`${i18nPrefix}amountRange`) }}</th>
                <th>{{ $t(`${i18nPrefix}promotionBonus`) }}</th>
                <th>{{ $t(`${i18nPrefix}liveCasinoRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}slotRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}fishingRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}lotteryRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}sportRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}eSportRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}cardGameRate`) }}</th>
                <th>{{ $t(`${i18nPrefix}cockFighting`) }}</th>
                <th>{{ $t(`${i18nPrefix}arcade`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!starLevelList || !starLevelList.length">
                <td colspan="12">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="(list, index) of starLevelList" :key="index">
                <td>
                  <button
                    class="normal-btn mr-3"
                    :disabled="!getUserinfo.permissions.includes(10602)"
                    @click="handleDialog(list, 'Edit')"
                  >
                    {{ $t("default.edit") }}
                  </button>
                  <button
                    class="delete-btn"
                    :disabled="!getUserinfo.permissions.includes(10603)"
                    @click="handleDelete(list, 'deleteStarLevel')"
                  >
                    {{ $t("default.delete") }}
                  </button>
                </td>
                <td>{{ list.star + $t("default.star") }}</td>
                <td>{{ list.count }}</td>
                <td>{{ digital.format(list.fromAmount) }} - {{ digital.format(list.toAmount) }}</td>
                <td>{{ list.promotionBonus }}</td>
                <td>{{ list.liveCasinoRate }}</td>
                <td>{{ list.slotRate }}</td>
                <td>{{ list.fishingRate }}</td>
                <td>{{ list.lotteryRate }}</td>
                <td>{{ list.sportRate }}</td>
                <td>{{ list.eSportRate }}</td>
                <td>{{ list.cardGameRate }}</td>
                <td>{{ list.cockFightingRate }}</td>
                <td>{{ list.arcadeRate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t(`${i18nPrefix}starUp`) }}</h5>
        <div class="float-right">
          <button
              class="normal-btn"
              style="margin: auto"
              :disabled="!getUserinfo.permissions.includes(10604)"
              @click="handleCalc()"
          >
            {{ $t(`${i18nPrefix}handleCalc`) }}
          </button>
        </div>
      </div>
      <div class="divider"></div>
      <main>
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}betTimeFrom`) }}
            <input type="date" step="1" v-model="startTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}betTimeTo`) }}
            <input type="date" step="1" v-model="endTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}calculate`) }}
            <select v-model="upRecord.type">
              <option v-for="list of upRecordType" :key="list.id" :value="list.id">
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.remark") }}
            <textarea v-model="upRecord.remark"></textarea>
          </label>
          <label class="deleteBox">
            <input
              type="checkbox"
              v-model="deleteCheck"
            />
            {{ $t(`${i18nPrefix}showDel`) }}
          </label>
        </div>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>{{ $t(`${i18nPrefix}id`) }}</th>
                  <th>{{ $t(`${i18nPrefix}betTimeFrom`) }}</th>
                  <th>{{ $t(`${i18nPrefix}betTimeTo`) }}</th>
                  <th>{{ $t(`${i18nPrefix}upMemberCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}downMemberCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}untreatedCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}approveCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}refuseCount`) }}</th>
                  <th>{{ $t("default.remark") }}</th>
                  <th>{{ $t("default.status") }}</th>
                  <th>{{ $t("default.admin") }}</th>
                  <th>{{ $t(`${i18nPrefix}approveTime`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>
                    <button
                      class="normal-btn mr-3"
                      @click="handleDialog(list, 'UpRecordEdit')"
                      :disabled="list.starLevelUpStatus === 4 || !getUserinfo.permissions.includes(10604)"
                    >
                      {{ list.id }}
                    </button>
                    <button
                      class="delete-btn"
                      @click="handleDelete(list, 'deleteUpCalculate')"
                      v-show="list.starLevelUpStatus !== 4"
                      :disabled="!getUserinfo.permissions.includes(10604)"
                    >
                      {{ $t("default.delete") }}
                    </button>
                  </td>
                  <td>{{ list.betTimeFrom }}</td>
                  <td>{{ list.betTimeTo }}</td>
                  <td>{{ list.upMemberCount }}</td>
                  <td>{{ list.downMemberCount }}</td>
                  <td>{{ list.untreatedCount }}</td>
                  <td>{{ list.approveCount }}</td>
                  <td>{{ list.refuseCount }}</td>
                  <td>{{ list.remark }}</td>
                  <td>
                    {{
                      GLOBAL.i18nCheckName(i18nPrefix+
                      starLevelUpStatusList.find(
                        (data) => data.id === list.starLevelUpStatus
                      ))
                    }}
                  </td>
                  <td>{{ list.approveAccount }}</td>
                  <td>{{ list.approveTime }}</td>
                </tr>
              </tbody>
            </table>
            <Pagination
              v-if="totalCount > 0"
              :totalCount="totalCount"
              @handlePage="handlePage"
            ></Pagination>
          </div>
        </main>
      </main>
    </div>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Confirm
        v-if="getShowConfirm"
        @toggle="TOGGLE_CONFIRM"
        @delete="deleteApi"
      />
    </transition>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="upRecordCalc"
      />
    </transition>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :agentList="getAgentList"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
        @UpAudit="starLevelUpAudit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  starLevelList,
  createStarLevel,
  updateStarLevel,
  deleteStarLevel,
  upRecordList,
  upRecordCalculate,
  deleteUpCalculate,
  starLevelUpAudit,
} from "@/api/member/member-level";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import UpRecordEdit from "./dialog/upRecordEdit.vue";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import Pagination from "@/layout/components/Pagination";
import handleDialog from "@/layout/mixins/handleDialog";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";
import Confirm from "@/layout/components/Confirm";
import { transYestetday } from "@/utils/transDateUtils";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MemberLevel",
  props:["i18nPrefix"],
  data() {
    return {
      startTime: transYestetday(),
      endTime: transYestetday(),
      starLevelList: undefined,
      upRecord: {
        startTime: undefined,
        endTime: undefined,
        type: 1,
        remark: "",
      },
      alertMsg: undefined,
      deleteData: [],
      deleteCheck: false,
      starLevelUpStatusList: [
        {
          id: 1,
          name: "undone",
        },
        {
          id: 2,
          name: "success",
        },
        {
          id: 3,
          name: "fail",
        },
        {
          id: 4,
          name:  "delete",
        },
      ],
      apiData: [],
      tidyData: [],
      upRecordType: [
        {
          id: 1,
          name: `${this.i18nPrefix}all`
        },
        {
          id: 2,
          name: `${this.i18nPrefix}single`
        }
      ]
    };
  },
  watch: {
    // startTime(value) {
    //   this.chooseWeek(new Date(value));
    // },
    deleteCheck(value) {
      this.allData = value ? this.apiData : this.tidyData;
      this.dataSort("id", "number", true);
    }
  },
  // mounted() {
    // this.chooseWeek(new Date());
  // },
  components: {
    Create,
    Edit,
    UpRecordEdit,
    Alert,
    Confirm,
    Pagination,
  },
  computed: {
    ...mapGetters(["getShowConfirm","getUserinfo","getAgentList"]),
  },
  mixins: [handlePageUtils, handleDialog],
  created() {
    this.getList();
    this.getUpRecordList();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    getList() {
      this.dialogData = undefined;
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
      starLevelList().then((res) => {
        this.starLevelList = res.data;
      });
    },
    create(data) {
      createStarLevel(data).then((res) => {
        if(res) this.getList();
      });
    },
    edit(data) {
      updateStarLevel(data).then((res) => {
        if(res) this.getList();
      });
    },
    getUpRecordList() {
      this.dialogData = undefined;
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
      upRecordList().then((res) => {
        this.apiData = res.data;
        this.allData =  this.tidyData = this.apiData.filter((data) => data.starLevelUpStatus !== 4);
        this.dataSort("id", "number", true);
      });
    },
    handleCalc() {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
    },
    upRecordCalc() {
      this.alertMsg = undefined;
      if (
        this.startTime === undefined ||
        this.endTime === undefined 
      ) {
        Message.error(this.$t(`${this.i18nPrefix}alertMsg2`), 1000);
        return
      }
      this.upRecord.startTime = this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone;
      this.upRecord.endTime = this.endTime + this.GLOBAL.endHour+ this.GLOBAL.timeZone;
      upRecordCalculate(this.upRecord).then((res) => {
        if(res) this.getUpRecordList();
      });
      this.upRecord.remark = "";
    },
    starLevelUpAudit(data) {
      starLevelUpAudit(data).then((res) => {
        if(res) this.getUpRecordList();
      });
    },
    handleDelete(data, deleteUrl) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
      this.deleteUrl = deleteUrl;
    },
    deleteApi() {
      if (this.deleteUrl === "deleteStarLevel") {
        deleteStarLevel({ id: this.deleteData.id }).then((res) => {
          if(res) this.getList();
        });
        return;
      }
      deleteUpCalculate({ id: this.deleteData.id }).then((res) => {
        if(res) this.getUpRecordList();
      });
    },
    checkAmount(from, to) {
      if (!from || from.lengtn === 0) {
        return "＜＝　" + to;
      } else if (!to || to.lengtn === 0) {
        return "＞＝　" + from;
      } else if ((!from || from.lengtn === 0) && (!to || to.lengtn === 0)) {
        return this.$t(`${this.i18nPrefix}notDeposited`);
      } else {
        return from + " - " + to;
      }
    },
    chooseWeek(dateData) {
      let date = dateData;
      let year, month, day;

      date.setDate(date.getDate() - (date.getDay() ? date.getDay() : 7) + 1);
      year = date.getFullYear();
      month = date.getMonth() + 1;
      month = month.toString().length > 1 ? month : `0${month}`;
      day = date.getDate();
      day = day.toString().length > 1 ? day : `0${day}`;
      this.startTime = `${year}-${month}-${day}`;

      date.setDate(date.getDate() + 6);
      year = date.getFullYear();
      month = date.getMonth() + 1;
      month = month.toString().length > 1 ? month : `0${month}`;
      day = date.getDate();
      day = day.toString().length > 1 ? day : `0${day}`;
      this.endTime = `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  label {
    justify-content: flex-start;
    width: 20%;
  }
}
@media screen and (max-width: 1280px) {
  .input-block {
    label {
      width: 45% !important;
      margin: 5px auto;
    }
    .deleteBox {
      width: 10%;
    }
  }
}
.deleteBox {
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 10%;
}
</style>