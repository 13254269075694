export const emptyFormModel = {
    id: undefined,
    name: '',        // 標題
    discountCategory: 5,    //
    discountType: 36,       // 優惠類型
    startTime: undefined,   // 開始時間
    endTime: undefined,     // 結束時間
    sort: 99,               // 排序
}

export const ruleSetting = {
    auditMethod: 1,
    wageringRate: 0,
    settleMethod: {
        settleCycle: 1,
        settleTime: "00"
    },
    paymentAmountRange: {
        minimum: 1,
        maximum: 1
    },
    layersNumber: 1,
    calculationMethod: 1,
    gameConditions: [],
    participateStatus: {
        includeLevels: [],
        includeAgents: [],
        includeTags: []
    },
    achievements: {
        isEnabled: false,
        amount: 1,
        list: [
            {
                condition: {
                    numberOfMembers: 1
                },
                bonusAmount: 1,
                bettingTurnover: 1
            }
        ]
    },
    calculationRate: {
        layerRates: [
            {
                layer: 1,
                rebateRates: [{
                    totalBetAmount: 0,
                    rate: 0,
                }],
            }
        ],
        levelBonusRates: [
        ]
    },
    html: '',
}