var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [_c("img", { attrs: { src: _vm.image } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }