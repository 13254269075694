<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }}{{ $t(`title.gamesCategoryInfo`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}gameCategory`) }}
              <select v-model="createModel.GamesCategory">
                <option
                  v-for="list of gamesCategorys"
                  :key="list.id"
                  :value="list.id * 1"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="createModel.Status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.redirectUrl`) }}
              <input
                type="text"
                v-model="createModel.RedirectUrl"
              />
            </label>
          </div>
          <div class="textarea-div">
            {{ $t(`${i18nPrefix}dialog.description`) }}
            <textarea
              v-model="createModel.Description"
              rows="5"
            ></textarea>
          </div>
          <div class="imgDiv">
            <div class="previewDiv" @click="upload('File')">
              <span v-if="!File">{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}Image</span>
              <img v-else :src="File" />
            </div>
            <div class="previewDiv" @click="upload('MobileFile')">
              <span v-if="!MobileFile">{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}MobileImage</span>
              <img v-else :src="MobileFile" />
            </div>
            <div class="previewDiv" @click="upload('AppFile')">
              <span v-if="!AppFile">{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}AppImage</span>
              <img v-else :src="AppFile" />
            </div>
          </div>
          <input type="file" ref="file" class="custom-file" @change="fileSelect" />
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ["gamesCategorys", "i18nPrefix"],
  data() {
    return {
      createModel: {
        Status: 1,
        GamesCategory: undefined,
        RedirectUrl: undefined,
        Description: undefined,
        File: undefined,
        MobileFile: undefined,
        AppFile: undefined
      },
      formData: new FormData(),
      File: undefined,
      MobileFile: undefined,
      AppFile: undefined
    };
  },
  created() {
    this.createModel.GamesCategory = this.gamesCategorys[0].id;
  },
  methods: {
    create() {
      Object.entries(this.createModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });
      this.$emit("create", this.formData);
    },
    upload(tag) {
      this.chooiceImg = tag;
      this[tag] = undefined;
      this.formData.delete(tag);
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      if (!e.target.files?.length) return;
      this[this.chooiceImg] = URL.createObjectURL(e.target.files[0]);
      let newFile = new File(
        [e.target.files[0]],
        this.chooiceImg + e.target.files[0].name
      );
      this.formData.append(this.chooiceImg, newFile);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.textarea-div {
  display: flex;
  flex-direction: column;
}
.imgDiv {
  display: flex;
  flex-wrap: wrap;
  .previewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 200px;
    padding: 0.5em;
    background-color: gray;
    border-radius: 10px;
    margin: 20px auto;
    span {
      display: block;
      font-size: 20px;
    }
    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 95%;
      max-width: 95%;
    }
  }
}
</style>