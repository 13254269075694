import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function login(data) {
    return request({
        url: "/user/login",
        method: "post",
        data
    });
}

export function logout() {
    return request({
        url: "/user/logout",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function info() {
    return request({
        url: "/user/info",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function resetPassword(data) {
    return request({
        url: "/user/update_password",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function privateMessage() {
    return request({
        url: "/PrivateMessage/get",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function readPrivateMessage(data) {
    return request({
        url: "/PrivateMessage/SetRead",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function privateMessageList(data) {
    return request({
        url: "/PrivateMessage/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function memberCount() {
    return request({
        url: "/Member/count",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}
export function twoFactorAuthVerify(data) {
    return request({
        url: "/TwoFactorAuth/Verify",
        method: "post",
        data,
    });
}
export function getServerLang(lang) {
    return request({
        url: "https://wpp-media-prod.s3.ap-southeast-1.amazonaws.com/dashboard-language/" + lang + ".json?v=" + new Date().getTime(),
        method: "get",
    });
}