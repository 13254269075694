var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-view" },
      [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled:
                    !_vm.getUserinfo.permissions.includes(99999) &&
                    !_vm.getUserinfo.permissions.includes(60201),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.create")) + " ")]
            ),
          ]),
        ]),
        _c("main", [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}type`)))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}title`)))]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}announcement(zh-tw)`))
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}sort`)))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}active`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}isBroadcast`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createDate`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}creator`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateDate`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editor`)))]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.tableList, function (list) {
                  return _c("tr", { key: list.createTime }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn mr-1",
                          attrs: {
                            disabled:
                              !_vm.getUserinfo.permissions.includes(99999) &&
                              !_vm.getUserinfo.permissions.includes(60202),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(list, "Edit")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "delete-btn",
                          attrs: {
                            disabled:
                              !_vm.getUserinfo.permissions.includes(99999) &&
                              !_vm.getUserinfo.permissions.includes(60203),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(list)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("default.delete")) + " ")]
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.getAnnouncementTypes.find(
                                (data) => data.id === list.announcementType
                              )
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          list.title.length > 10
                            ? list.title.substring(0, 10) + " ..."
                            : list.title
                        )
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          list.content.length > 10
                            ? list.content.substring(0, 10) + " ..."
                            : list.content
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.sortId))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          list.status === 1
                            ? _vm.$t("default.active")
                            : _vm.$t("default.inactive")
                        )
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          list.isBroadcast
                            ? _vm.$t("default.active")
                            : _vm.$t("default.inactive")
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.createTime))]),
                    _c("td", [_vm._v(_vm._s(list.createAccount))]),
                    _c("td", [_vm._v(_vm._s(list.updateTime))]),
                    _c("td", [_vm._v(_vm._s(list.updateAccount))]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
        _vm.totalCount > 0
          ? _c("Pagination", {
              attrs: { totalCount: _vm.totalCount },
              on: { handlePage: _vm.handlePage },
            })
          : _vm._e(),
        _c(
          _vm.currDialog,
          _vm._b(
            {
              tag: "component",
              attrs: { announcementTypes: _vm.getAnnouncementTypes },
              on: {
                toggle: function ($event) {
                  _vm.currDialog = undefined
                },
                create: _vm.create,
                edit: _vm.edit,
              },
            },
            "component",
            _vm.dialogData,
            false
          )
        ),
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _vm.getShowConfirm
              ? _c("Confirm", {
                  on: {
                    toggle: _vm.TOGGLE_CONFIRM,
                    delete: _vm.deleteAnnouncement,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }