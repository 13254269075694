var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "time-block" }, [
    _c(
      "button",
      {
        staticClass: "quick-btn",
        class: { "quick-btn-active": _vm.now === "lastMonth" },
        on: {
          click: function ($event) {
            return _vm.changeDate("lastMonth")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("default.last-month")))]
    ),
    _c(
      "button",
      {
        staticClass: "quick-btn",
        class: { "quick-btn-active": _vm.now === "lastWeek" },
        on: {
          click: function ($event) {
            return _vm.changeDate("lastWeek")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("default.last-week")))]
    ),
    _c(
      "button",
      {
        staticClass: "quick-btn",
        class: { "quick-btn-active": _vm.now === "yesturday" },
        on: {
          click: function ($event) {
            return _vm.changeDate("yesturday")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("default.yesterday")))]
    ),
    _c(
      "button",
      {
        staticClass: "quick-btn",
        class: { "quick-btn-active": _vm.now === "today" },
        on: {
          click: function ($event) {
            return _vm.changeDate("today")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("default.today")))]
    ),
    _c(
      "button",
      {
        staticClass: "quick-btn",
        class: { "quick-btn-active": _vm.now === "week" },
        on: {
          click: function ($event) {
            return _vm.changeDate("week")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("default.this-week")))]
    ),
    _c(
      "button",
      {
        staticClass: "quick-btn",
        class: { "quick-btn-active": _vm.now === "month" },
        on: {
          click: function ($event) {
            return _vm.changeDate("month")
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("default.this-month")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }