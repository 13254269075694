<template>
</template>

<script>
export default {
  name: "empty",
  created() {
    this.$router.push({
      path: this.$route.query?.page,
    });
  },
};
</script>

<style lang="scss" scoped>
</style>