var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("main", [
            _c(
              "ul",
              { staticClass: "detail-ul" },
              _vm._l(_vm.list, function (item, index) {
                return _c("li", { key: index, staticClass: "detail-ul-li" }, [
                  _c("div", { staticClass: "detail-ul-li-title" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "detail-ul-li-value" }, [
                    _vm._v(_vm._s(item.value)),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }