export let searchModel = {
    memberAccount: "",
    discountName: undefined,
    discountTypeId: 0,
    agentId: 0,
    startTime: undefined,
    endTime: undefined,
    type: 1,
    page:  {
        "take": 10,
        "skip": 0
    }
}