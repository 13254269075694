<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t(`${i18nPrefix}rebate`) }}</h5>
        <div class="float-right">
          <button
              class="normal-btn"
              style="margin: auto"
              @click="handleCalc()"
              :disabled="!rebate.discountSettingId"
          >
            {{ $t(`${i18nPrefix}handleCalc`) }}
          </button>
        </div>
      </div>
      <div class="divider"></div>
      <main>
        <main>
          <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}betTimeFrom`) }}
            <input type="date" step="1" v-model="startTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}betTimeTo`) }}
            <input type="date" step="1" v-model="endTime" />
          </label>
          <label>
            <span>{{ $t(`${i18nPrefix}discountSetting`) }}</span>
            <select v-model="rebate.discountSettingId">
              <option v-show="!rebate.discountSettingId" :value="undefined">{{ $t(`${i18nPrefix}discountUnSetting`) }}</option>
              <option v-for="list of discountList" :key="list.id" :value="list.id">
                {{ list.name }}
              </option>
            </select>
          </label>
          <div class="condition-block">
            {{ $t(`${i18nPrefix}ignoreAgentIds`) }}
            <multiselect
                v-model="ignoreAgentIds"
                :placeholder="$t('default.plzSelect')"
                :options="options"
                group-values="agentList"
                group-label="all"
                :group-select="true"
                label="name"
                track-by="id"
                :multiple="true"
                :preserve-search="true"
                @search-change="mulSearch"
            ></multiselect>
          <div class="button-block">
            <button @click="ignoreAgentIds = [...getAgentList]">{{ $t("default.selectAll") }}</button>
            <button @click="ignoreAgentIds = []">{{ $t("default.clear") }}</button>
          </div>
        </div>
        </div>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>{{ $t(`${i18nPrefix}discountType`) }}</th>
                  <th>{{ $t(`${i18nPrefix}betTimeFrom`) }}</th>
                  <th>{{ $t(`${i18nPrefix}betTimeTo`) }}</th>
                  <th>{{ $t("default.admin") }}</th>
                  <th>{{ $t(`${i18nPrefix}approveTime`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>{{ list.discountSettingName }}</td>
                  <td>{{ list.timeFrom }}</td>
                  <td>{{ list.timeTo }}</td>
                  <td>{{ list.createAccount }}</td>
                  <td>{{ list.createTime }}</td>
                </tr>
              </tbody>
            </table>
            <Pagination
              v-if="totalCount > 0"
              :totalCount="totalCount"
              @handlePage="handlePage"
            ></Pagination>
          </div>
        </main>
      </main>
    </div>
    <!-- <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @rebateWeeklyCalc="rebateWeekly"
      ></component>
    </transition> -->
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="rebateWeekly"
      />
    </transition>
  </div>
</template>

<script>
import { rebateWeekly, getDiscountList, rebateList } from "@/api/member/member-level";
// import rebateWeeklyCalc from "./dialog/rebateWeeklyCalc";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Pagination from "@/layout/components/Pagination";
import Alert from "@/layout/components/Alert";
import Message from "@/layout/components/Message";
import { transYestetday } from "@/utils/transDateUtils";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "Rebate",
  props:["i18nPrefix"],
  data() {
    return {
      startTime: transYestetday(),
      endTime: transYestetday(),
      starLevelList: undefined,
      rebate: {
        startTime: undefined,
        endTime: undefined,
        discountSettingId: undefined,
        ignoreAgentIds: []
      },
      ignoreAgentIds: [],
      calaData: [],
      discountList: [],
      alertMsg: undefined,
      options: [
        {
          all: 'Select All',
          agentList: []
        }
      ]
    };
  },
  components: {
    Alert,
    Multiselect,
    Pagination,
  },
  mixins: [handlePageUtils,handleDialog],
  computed: {
    ...mapGetters(["getAgentList"]),
  },
  created() {
    this.getRebateList();
    this.options[0].agentList = this.getAgentList;
    getDiscountList().then( res => { 
      this.discountList = res.data;
      this.rebate.discountSettingId = this.discountList[0]?.id;
    })
  },
  methods: {
    mulSearch(data) {
      this.options[0].agentList = this.getAgentList.filter( agent => agent.name.match(data));
    },
    getRebateList() {
      this.dialogData = undefined;
      this.RESET_DIALOG();
      rebateList().then((res) => {
        this.allData = res.data;
        this.dataSort('id', 'number', true);
      });
    },
    handleCalc() {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
    },
    rebateWeekly() {
      this.alertMsg = undefined;
      if (
        this.startTime === undefined ||
        this.endTime === undefined 
      ) {
        Message.error(this.$t(`${this.i18nPrefix}alertMsg2`), 1000);
        return
      }
      this.rebate.startTime = this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone;
      this.rebate.endTime = this.endTime + this.GLOBAL.endHour+ this.GLOBAL.timeZone;
      this.rebate.ignoreAgentIds = this.ignoreAgentIds.map(
          (data) => data.id * 1
      );
      rebateWeekly(this.rebate).then((res) => {
        if(res) this.getRebateList();
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
main {
  label {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  .input-block {
    label,
    div {
      width: 60% !important;
      margin: 5px auto;
    }
  }
}
</style>