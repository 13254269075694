import { render, staticRenderFns } from "./SEO-blog.vue?vue&type=template&id=24ed2ee8&scoped=true"
import script from "./SEO-blog.vue?vue&type=script&lang=js"
export * from "./SEO-blog.vue?vue&type=script&lang=js"
import style0 from "./SEO-blog.vue?vue&type=style&index=0&id=24ed2ee8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ed2ee8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24ed2ee8')) {
      api.createRecord('24ed2ee8', component.options)
    } else {
      api.reload('24ed2ee8', component.options)
    }
    module.hot.accept("./SEO-blog.vue?vue&type=template&id=24ed2ee8&scoped=true", function () {
      api.rerender('24ed2ee8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SEO-Blog/SEO-blog/SEO-blog.vue"
export default component.exports