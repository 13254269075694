<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}loginList`) }}</h4>
        </div>
        <div>
          <button :class="{ 'btn-lightBlue': list.id == buttonType, 'btn-grey': list.id !== buttonType }"
            v-for="list of pageType" @click="buttonType = list.id">{{ $t(list.name) }}</button>
        </div>
        <div class="divider"></div>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th>{{ buttonType == 1 ? $t(`${i18nPrefix}ip`) : $t(`${i18nPrefix}device`) }}</th>
                <th>{{ $t(`${i18nPrefix}relationMember`) }}</th>
                <th>{{ $t(`${i18nPrefix}dialog.loginTime`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!tableList.length">
                <td colspan="3">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="(list, index) of tableList" :key="index">
                <td>{{ buttonType == 1 ? list.ip : list.deviceId }}</td>
                <td
                  @click="relationMemberDialog({ relationMember: list.relationMember })"
                  style="cursor: pointer; color:blue;"
                >{{ list.relationMember.length }}</td>
                <td>{{ buttonType == 1 ? list.lastLoginTime : list.loginTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage"></Pagination>
    </div>
    <transition name="fade" mode="out-in">
      <component :i18nPrefix="i18nPrefix" :is="currDialog" @toggle="currDialog = dialogData = undefined"
        v-bind="dialogData"></component>
    </transition>
  </div>
</template>

<script>
import { memberDeviceList } from "@/api/member/member";
import { memberLoginRecordList } from "@/api/report-various/member-login-record";
import RelationMember from "./relationMember.vue";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";

export default {
  name: "LoginList",
  props: ["id", "account", "i18nPrefix"],
  data() {
    return {
      buttonType: 1,
      loginRecordList: [],
      deviceList: [],
      pageType: [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}ip`)
        },
        {
          id: 2,
          name: this.$t(`${this.i18nPrefix}device`)
        }
      ],
      currDialog: undefined,
      dialogData: undefined
    };
  },
  components: {
    RelationMember,
    Pagination
  },
  watch: {
    "buttonType"(val) {
      this.allData = val == 1 ? this.loginRecordList : this.deviceList
    }
  },
  mixins: [handlePageUtils],
  created() {
    memberLoginRecordList({ memberAccount: this.account }).then(res => {
      this.allData = this.loginRecordList = res.data.sort(
        (a, b) => new Date(b["lastLoginTime"]) - new Date(a["lastLoginTime"])
      );
    });
    memberDeviceList({ id: this.id }).then(res => {
      this.deviceList = res.data.sort(
        (a, b) => new Date(b["loginTime"]) - new Date(a["loginTime"])
      );
    });
  },
  methods: {
    relationMemberDialog(list) {
      this.currDialog = "RelationMember";
      this.dialogData = list;
    }
  }
};
</script>
<style lang="scss" scoped></style>