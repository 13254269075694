<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            <h4>{{ $t(`${i18nPrefix}dialog.editTitle`) }}</h4>
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="inputTop">
              <div class="title">
                <label>
                  {{ $t(`${i18nPrefix}dialog.top`) }}
                  <div class="slide-block" :class="{ 'slide-block-on': createModel.isTop }">
                    <div
                      class="slide"
                      @click.self="toggleSlide"
                      :class="{ 'slide-on': createModel.isTop }"
                    >
                      <label for="isTop" style="display: none"></label>
                      <input type="checkbox" id="isTop" style="display: none" v-model="createModel.isTop" />
                    </div>
                  </div>
                </label>
              </div>
              <div class="order">
                <span>                   {{ $t(`${i18nPrefix}dialog.imageName`) }}
 </span>
                <input
                  type="text"
                  v-model="editModel.Name"
                  :placeholder="$t(`${i18nPrefix}dialog.enterName`)"
                />
              </div>
            </div>
            <div class="previewDiv">
              <div>
                <div class="handleFile">
                  <input
                    type="file"
                    ref="file"
                    class="custom-file"
                    @change="fileSelect"
                  />
                  <button class="upload" @click="upload">{{ $t(`${i18nPrefix}dialog.uploadImage`) }}</button>
                </div>
              </div>
              <div class="imgDiv">
                <img :src="imageUrl" class="image" />
              </div>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { toggleStyle } from "@/utils/slideUtils";

export default {
  name: "Edit",
  props: ["list"],
  data() {
    return {
      editModel: {
        id: this.list.id,
        isTop: this.list.isTop,
        Name: this.list.name,
        ImageUrl: this.list.url,
      },
      imageUrl: this.list.url,
      formData: new FormData(),
      i18nPrefix: "mediaLibrary.MediaLibraryImage."
    };
  },
  methods: {
    toggleSlide: toggleStyle,
    create() {
      this.formData.append("id", this.editModel.id);
      this.formData.append("isTop", this.editModel.isTop);
      this.formData.append("Name", this.editModel.Name);
      this.formData.append("ImageUrl", this.editModel.ImageUrl);

      this.$emit("update", this.formData);
    },
    upload() {
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    fileSelect(e) {
      this.imageUrl = URL.createObjectURL(e.target.files[0]);
      this.formData.append("File", e.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  border: 2px solid green;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border-radius: 10px;
  transition: 0.2s;
  &:hover {
    background-color: rgb(202, 202, 202);
  }
}
.custom-file {
  position: absolute;
  visibility: hidden;
}
span {
  color: black;
}
input {
  display: block;
  width: 100%;
}
.dialog-container {
  width: 40%;
  input {
    text-align: center;
  }
  .input-block {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    .inputTop {
      display: flex;
      .title {
        display: flex;
        width: 50%;
        margin: 5px 0 !important;
        flex-direction: row;
        align-items: center;
        .slide-block {
          // width: 70px !important;
          // margin-left: 5% !important;
        }
        span {
          width: 50px !important;
        }
      }
      .order {
        display: flex;
        width: 100%;
        margin: 5px 0 !important;
        flex-direction: row;
        align-items: center;
        input {
          width: 50%;
          padding-left: 0 !important;
          margin-left: 10px;
        }
      }
    }
    .previewDiv {
      margin: 5% 0;
      display: flex;
      width: 100%;
      img {
        max-height: 100%;
        max-width: 100%;
        display: block;
      }
      div {
        margin: auto;
        width: 45%;
      }
    }
  }
}
</style>