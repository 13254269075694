<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <select v-model="createModel.DiscountSettingId">
                <option
                  v-for="list of discountSettings"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              <!-- 創建日期
              <input type="datetime-local" step="1" disabled v-model="createModel.createTime" /> -->
            </label>
            <label>
              {{ $t("default.member") }}
              <select v-model="createModel.SelectMembers.type">
                <option v-for="list of member" :key="list.id" :value="list.id">
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label style="justify-content: flex-end">
              <select
                v-show="createModel.SelectMembers.type === 2"
                v-model="createModel.SelectMembers.agentid"
              >
                <option
                  v-for="list of agentList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
              <textarea
                v-show="createModel.SelectMembers.type === 3"
                v-model="members"
                :placeholder="$t(`${i18nPrefix}dialog.placeholder`)"
              ></textarea>
            </label>
            <label>
              <span>
                {{ $t(`${i18nPrefix}firstDepositAmount`) }}
                <!-- <button class="calc-btn" @click="calc()">{{ $t(`${i18nPrefix}dialog.calc`) }}</button> -->
              </span>
              <input type="text" v-model="createModel.FirstDepositAmount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bonusReceive`) }}
              <input type="text" v-model="createModel.bonusReceive"/>
            </label>
            <label>
              {{ $t(`${i18nPrefix}totalBetAmountLimit`) }}
              <input type="text" v-model="createModel.totalBetAmountLimit"/>
            </label>
            <label class="remark">
              {{ $t("default.remark") }}
              <textarea v-model="createModel.Remark"></textarea>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">
              {{ $t("default.confirm") }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { checkValue } from "@/utils/checkData";
import { selectMenu, calculateBonus } from "@/api/member/member-discount";
import { mapGetters } from "vuex";

export default {
  name: "Create",
  props: ["i18nPrefix"],
  data() {
    return {
      createModel: {
        DiscountSettingId: undefined,
        SelectMembers: {
          type: 3,
          agentid: undefined,
          members: [],
        },
        FirstDepositAmount: 0,
        bonusReceive: 0,
        totalBetAmountLimit: 0,
        Remark: undefined,
      },
      member: [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}dialog.allMember`),
        },
        {
          id: 2,
          name: this.$t("default.agent"),
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}dialog.selfFill`),
        },
      ],
      members: undefined,
      agentList: undefined,
      discountSettings: undefined,
      bonusAmount: undefined,
      totalBetAmount: undefined,
    };
  },
  computed: {
    ...mapGetters(["getAgentList"]),
  },
  created() {
    selectMenu().then((res) => {
      this.agentList = res.data.agents;
      this.createModel.SelectMembers.agentid = this.agentList[0]?.id;
      this.discountSettings = res.data.discountSettings;
      this.createModel.DiscountSettingId = this.discountSettings[0]?.id;
    });
  },
  methods: {
    create() {
      if ( this.createModel.SelectMembers.type === 1 ) {
        this.createModel.SelectMembers.agentid = undefined;
        this.createModel.SelectMembers.members = [];
      }else if ( this.createModel.SelectMembers.type === 2 ) {
        this.createModel.SelectMembers.members = [];
      }else {
        if(!checkValue(this.members, this.$t('default.member'))) return
        this.createModel.SelectMembers.agentid = undefined;
        this.createModel.SelectMembers.members = this.members.split("#");
      }
      this.createModel.FirstDepositAmount = this.createModel.FirstDepositAmount * 1;
      this.createModel.bonusReceive = this.createModel.bonusReceive * 1;
      this.createModel.totalBetAmountLimit = this.createModel.totalBetAmountLimit * 1;
      this.$emit("create", this.createModel);
    },
    calc() {
      calculateBonus({
        discountSettingId: this.createModel.DiscountSettingId,
        firstDepositAmount: this.createModel.FirstDepositAmount * 1 ,
      }).then((res) => {
        this.createModel.bonusReceive = res.data.bonusAmount;
        this.createModel.totalBetAmountLimit = res.data.totalBetAmount;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 70%;
  .input-block {
    label {
      width: 40%;
    }
    .remark {
      width: 97%;
    }
  }
  .member {
    width: 97%;
  }
}

.calc-btn {
  background-color: #f44236;
  color: #fff5e5 !important;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  width: 50px;
  height: 25px;
}
</style>