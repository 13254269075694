<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(99999) &&
              !getUserinfo.permissions.includes(61501)
            "
          >
            {{ $t("default.create")  + " " + $t("title." + $route.meta.title) }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th style="width:30%"><input type="text" v-model="keywords" :placeholder="$t(`${i18nPrefix}search`)"></th>
                <th>{{ $t(`${i18nPrefix}bankName`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bank of tableList" :key="bank.id">
                <td style="width: 200px">
                  <button
                    class="normal-btn"
                    @click="handleDialog(bank, 'Edit')"
                    :disabled="
                      !getUserinfo.permissions.includes(99999) &&
                      !getUserinfo.permissions.includes(61502)
                    "
                  >
                    {{ $t("default.edit") }}
                  </button>
                  <button
                    class="delete-btn"
                    @click="handleDelete(bank)"
                    :disabled="
                      !getUserinfo.permissions.includes(99999) &&
                      !getUserinfo.permissions.includes(61503)
                    "
                  >
                    {{ $t("default.delete") }}
                  </button>
                </td>
                <td>{{ bank.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>

    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Confirm
        v-if="getShowConfirm"
        @toggle="TOGGLE_CONFIRM"
        @delete="deleteBank"
      />
    </transition>
  </div>
</template>

<script>
import {
  bankList,
  createBank,
  deleteBank,
  editBank,
} from "@/api/features/bank";
import Confirm from "@/layout/components/Confirm";
import { mapGetters, mapMutations } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { checkData } from "@/utils/checkData";

export default {
  name: "Bank",
  data() {
    return {
      deleteData: undefined,
      keywords: undefined,
      dataList: [],
      i18nPrefix: "features.bank."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo"]),
  },
  created() {
    this.getBankList();
  },
  watch:{
    "keywords"(val) {
      this.allData = this.dataList.filter( bank => bank.name.indexOf(val) !==-1);
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    getBankList() {
      bankList().then((res) => {
        this.allData = this.dataList = res.data;
        this.deleteData = undefined;
        this.keywords = undefined;
        this.RESET_CONFIRM();
        this.RESET_DIALOG();
      });
    },
    handleDelete(bank) {
      this.TOGGLE_CONFIRM();
      this.deleteData = bank;
    },
    deleteBank() {
      deleteBank({ id: this.deleteData.id }).then((res) => {
        if(res) this.getBankList();
      });
    },
    create(data) {
      if (checkData(data, true)) {
        createBank(data).then((res) => {
          if(res) this.getBankList();
        });
      }
    },
    edit(data) {
      if (checkData(data, true)) {
        editBank({
        id: data.id,
        newName: data.name,
        code: data.code,
        }).then((res) => {
          if(res) this.getBankList();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  .input-block {
    label {
      width: 50%;
    }
  }
}
</style>