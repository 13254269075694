var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.auditTime`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.from`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.multiples`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.fromPreTradeBalance`))),
        ]),
        _c("th", [
          _vm._v(
            _vm._s(_vm.$t(`${_vm.i18nPrefix}table.fromAfterTradeBalance`))
          ),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.to`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.toPreTradeBalance`))),
        ]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.toAfterTradeBalance`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.transferForm.transferTime))]),
        _c("td", [_vm._v(_vm._s(_vm.transferForm.memberAccount))]),
        _c("td", [
          _vm._v(
            _vm._s(_vm.$t("GamePlatform." + _vm.transferForm.fromPlatform))
          ),
        ]),
        _c("td", [_vm._v(_vm._s(_vm.transferForm.multiples))]),
        _c("td", [_vm._v(_vm._s(_vm.transferForm.fromPreTradeBalance))]),
        _c("td", [_vm._v(_vm._s(_vm.transferForm.fromAfterTradeBalance))]),
        _c("td", { class: _vm.fontColor(_vm.transferForm.amount) }, [
          _vm._v(_vm._s(_vm.transferForm.amount)),
        ]),
        _c("td", [
          _vm._v(_vm._s(_vm.$t("GamePlatform." + _vm.transferForm.toPlatform))),
        ]),
        _c("td", [_vm._v(_vm._s(_vm.transferForm.toPreTradeBalance))]),
        _c("td", [_vm._v(_vm._s(_vm.transferForm.toAfterTradeBalance))]),
        _c("td", [_vm._v(_vm._s(_vm.$t(_vm.transferForm.orderStatus)))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }