var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask create-member",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.detail")))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.gamesCategory")))]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}validBetAmount`))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    !_vm.tableList || !_vm.tableList.length
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.tableList, function (list, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(list.memberAccount))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.GamesCategorys.find(
                                  (data) => data.id === list.gamesCategory
                                )
                              )
                            )
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.validBetAmount))]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }