var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "agent-block" }, [
        _c("div", { staticClass: "container-view" }, [
          _c(
            "button",
            {
              staticClass: "search-btn main-style-btn",
              on: {
                click: function ($event) {
                  return _vm.passAgentId(_vm.agentData.id)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("default.search")))]
          ),
          _c("div", { staticClass: "header" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  attrs: {
                    disabled:
                      !_vm.getUserinfo.permissions.includes(99999) &&
                      !_vm.getUserinfo.permissions.includes(20101),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialog(
                        { agentId: _vm.agentId },
                        "Create"
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("default.create") + " " + _vm.$t("default.agent")
                    )
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "main advance-block" }, [
            _c("div", { staticClass: "input-block" }, [
              _c(
                "div",
                { staticClass: "input-block-multiselect" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
                  _c("multiselect", {
                    attrs: {
                      label: "name",
                      "track-by": "id",
                      options: _vm.getAgentListAddDefault,
                      "allow-empty": false,
                      preselectFirst: true,
                      "clear-on-select": false,
                      "preserve-search": true,
                      "custom-label": _vm.GLOBAL.nameWithLang,
                    },
                    model: {
                      value: _vm.agentData,
                      callback: function ($$v) {
                        _vm.agentData = $$v
                      },
                      expression: "agentData",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: {
                  click: function ($event) {
                    return _vm.passAgentId(_vm.agentData.id)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "container-view" }, [
          _c(
            "main",
            [
              _c("div", { staticClass: "total-block-v2" }, [
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(_vm._s(_vm.$t("default.totalDataCount")) + ":"),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.page.total)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "table-block" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.agentId !== _vm.getUserinfo.parentId,
                                expression: "agentId !== getUserinfo.parentId",
                              },
                            ],
                            staticClass: "normal-btn",
                            on: {
                              click: function ($event) {
                                return _vm.passAgentId(_vm.backData)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}back`)))]
                        ),
                        _vm.haveMember
                          ? _c(
                              "button",
                              {
                                staticClass: "normal-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDialog(
                                      { id: _vm.agentId },
                                      "Member"
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.member")))]
                            )
                          : _vm._e(),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}upperAgent`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}level`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}account`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}name`))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionLink`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}domain`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}parameter`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}balance`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createTime`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}status`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("default.processAccount"))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.allData || _vm.allData.length === 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "12" } }, [
                              _vm._v(_vm._s(_vm.$t("default.noData"))),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.allData, function (list) {
                        return _c("tr", { key: list.id }, [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "table-btn main-style-btn",
                                attrs: {
                                  disabled:
                                    !_vm.getUserinfo.permissions.includes(
                                      99999
                                    ) &&
                                    !_vm.getUserinfo.permissions.includes(
                                      20102
                                    ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDialog(list, "Edit")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.edit")))]
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  list.parentLevelNumber !== -1
                                    ? _vm.$t(
                                        "default." +
                                          (list.parentLevelNumber
                                            ? `levelNumber`
                                            : "mainAgent"),
                                        { input: list.parentLevelNumber }
                                      ) +
                                        " / " +
                                        list.parentName
                                    : _vm.$t("default.noData")
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "default." +
                                    (list.levelNumber
                                      ? `levelNumber`
                                      : "mainAgent"),
                                  { input: list.levelNumber }
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "agent",
                              on: {
                                click: function ($event) {
                                  return _vm.passAgentId(list.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(list.account))]
                          ),
                          _c("td", [_vm._v(_vm._s(list.name))]),
                          _c("td", [_vm._v(_vm._s(list.promotionLink))]),
                          _c(
                            "td",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _vm._v(" " + _vm._s(list.domain) + " "),
                                  _c("div", { staticClass: "d-flex ml-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "table-icon mr-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyDomain(
                                              "https://" + list.domain
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/icon/copy.svg"),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "table-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadQRCode(
                                              list.subDomain
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/icon/download.svg"),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("qrcode", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                attrs: {
                                  background: "#fff",
                                  cls: "qrcode" + list.subDomain,
                                  value: "https://" + list.domain,
                                },
                              }),
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.agentDamain +
                                            "?code=" +
                                            list.account
                                        ) +
                                        " "
                                    ),
                                    _c("div", { staticClass: "d-flex ml-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "table-icon mr-2",
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyDomain(
                                                "https://" +
                                                  _vm.agentDamain +
                                                  "?code=" +
                                                  list.account
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/icon/copy.svg"),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "table-icon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadQRCode(
                                                list.subDomain
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/icon/download.svg"),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("qrcode", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  attrs: {
                                    background: "#fff",
                                    cls: "qrcode" + list.subDomain,
                                    value:
                                      "https://" +
                                      _vm.agentDamain +
                                      "?code=" +
                                      list.account,
                                  },
                                }),
                              ],
                            ],
                            2
                          ),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center" },
                              [
                                _vm._v(
                                  " " + _vm._s("?code=" + list.account) + " "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "table-icon ml-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyDomain(
                                          "?code=" + list.account
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/icon/copy.svg"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(list.balance))]),
                          _c("td", [_vm._v(_vm._s(list.createTime))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                list.status === 1
                                  ? _vm.$t("default.yes")
                                  : _vm.$t("default.no")
                              )
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "table-btn main-style-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.getProcess(list.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("default.processAccount")))]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm.allData
                ? _c("serverPagination", {
                    attrs: { page: _vm.page },
                    on: {
                      ServerPageUtils: _vm.getAgentList,
                      changeTake: _vm.changeTake,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  agentId: _vm.agentId,
                  agentDamain: _vm.agentDamain,
                  permissionsData: _vm.permissionsData,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    return _vm.closeDialog()
                  },
                  reload: _vm.getAgentList,
                  create: _vm.create,
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _vm.processData
        ? _c("Process", {
            attrs: {
              processTitle: _vm.processTitle,
              processData: _vm.processData,
            },
            on: {
              toggle: function ($event) {
                _vm.processData = undefined
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "oldValue_Status",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          props.Status === 1
                            ? _vm.$t("default.yes")
                            : _vm.$t("default.no")
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "newValue_Status",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          props.Status === 1
                            ? _vm.$t("default.yes")
                            : _vm.$t("default.no")
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "oldValue_CommissionCalculationCycle",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.GLOBAL.settlement.find(
                                (data) =>
                                  props.CommissionCalculationCycle === data.id
                              )
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "newValue_CommissionCalculationCycle",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.GLOBAL.settlement.find(
                                (data) =>
                                  props.CommissionCalculationCycle === data.id
                              )
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3797406409
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }