<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(140301)"
          >{{ $t("default.create") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>
                <div class="btn-td">
                  <!-- <input type="checkbox" v-if="tableList" v-model="checkAllBtn" @click="checkAll()" /> -->
                  <!-- <button
                    class="delete-btn"
                    v-if="tableList"
                    @click="handleDelete(ids)"
                    :disabled="!getUserinfo.permissions.includes(140103)"
                  >刪除</button> -->
                </div>
              </th>
              <th>{{ $t(`${i18nPrefix}gameCategory`) }}</th>
              <th>{{ $t(`${i18nPrefix}status`) }}</th>
              <th>{{ $t(`${i18nPrefix}preview`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || !tableList.length">
              <td colspan="14">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list) of tableList" :key="list.id">
              <td class="small-td">
                <div class="btn-td">
                  <!-- <input type="checkbox" v-model="ids" :value="list.id" /> -->
                  <button
                    class="normal-btn"
                    @click="handleDialog( { list: list }, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(140302)"
                  >{{ $t("default.edit") }}</button>
                </div>
              </td>
              <td class="small-td">
                {{
                GLOBAL.i18nCheckName(gamesCategorys.find(
                (data) => list.gamesCategory === data.id
                ))
                }}
              </td>
              <td class="small-td">{{ list.status ? $t("default.active") : $t("default.inactive") }}</td>
              <td class="url-td">
                <span
                  v-show="list.imageUrl"
                  @click="handleDialog({image: list.imageUrl + '?v=' + new Date().getTime()}, 'Photo')"
                  :title="list.imageUrl"
                >image</span>
                <span
                  v-show="list.mobileImageUrl"
                  @click="handleDialog({image: list.mobileImageUrl + '?v=' + new Date().getTime()}, 'Photo')"
                  :title="list.mobileImageUrl"
                >mobileImage</span>
                <span
                  v-show="list.appImageUrl"
                  @click="handleDialog({image: list.appImageUrl + '?v=' + new Date().getTime()}, 'Photo')"
                  :title="list.imageUrl"
                >appImage</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        v-bind="dialogData"
        :gamesCategorys="gamesCategorys"
        :i18nPrefix="i18nPrefix"
        @toggle="currDialog = undefined"
        @edit="edit"
        @create="create"
      ></component>
    </transition>

    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="deleteGamesCategoryInfo"
      />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getGamesCategoryBannerList,
  createGamesCategoryBanner,
  updateGamesCategoryBanner
} from "@/api/operationManagement/GamesCategoryBanner";
import { mapGetters } from "vuex";
import Edit from "./dialog/edit";
import Create from "./dialog/create";
import Photo from "@/layout/components/photo";
import Alert from "@/layout/components/Alert";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
export default {
  name: "GamesCategoryBanner",
  components: {
    Edit,
    Photo,
    Alert,
    Create,
    Pagination
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      checkAllBtn: false,
      alertMsg: undefined,
      ids: [],
      gamePlatforms: [],
      gamesCategorys: [],
      platformVersions: [],
      i18nPrefix: "operationManagement.gamesCategoryBanner."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getAuditStatusList",
      "getUserinfo",
      "getGamePlatforms",
      "getGamesCategorys"
    ])
  },
  created() {
    this.gamePlatforms = this.getGamePlatforms;
    this.gamesCategorys = this.getGamesCategorys;
    this.search();
  },
  watch: {
    ids() {
      this.checkAllBtn =
        this.ids.length === this.tableList.length ? true : false;
    }
  },
  methods: {
    search() {
      getGamesCategoryBannerList({}).then(res => {
        this.allData = res.data;
        this.currDialog = undefined;
      });
    },
    create(data) {
      createGamesCategoryBanner(data).then(res => {
        if (res) this.search();
      });
    },
    edit(data) {
      updateGamesCategoryBanner(data).then(res => {
        if (res) this.search();
      });
    },
    checkAll() {
      this.ids = this.checkAllBtn ? [] : this.tableList.map(data => data.id);
    },
    handleDelete() {
      this.alertMsg = "確定刪除 ?";
    },
    deleteGamesCategoryInfo() {
      this.alertMsg = "";
      deleteGamesCategoryInfo({ ids: this.ids }).then(res => {
        if (res) this.search();
      });
      this.ids = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.small-td {
  width: 5%;
}
.middle-td {
  width: 10%;
}
.btn-td {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>