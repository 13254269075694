<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="update()" :disabled="!getUserinfo.permissions.includes(140402)">{{
            $t("default.edit") }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
        </div>
      </div>
    </div>
    <div class="container-view gamesCategory">
      <label>
        {{ $t("default.gamesCategory") + "：" }}
        <select v-model="category">
          <option v-for="list of getGamesCategorys" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
        </select>
        <button class="normal-btn" @click="create()" :disabled="!getUserinfo.permissions.includes(140401)">{{
          $t("default.create") }}</button>
      </label>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.sort") }}</th>
              <th>up</th>
              <th>down</th>
              <th>{{ $t("default.gamesCategory") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="3">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="list.id">
              <td>{{ list.sort }}</td>
              <td><button class="normal-btn" @click="moveUp(index)">▲</button></td>
              <td><button class="delete-btn" @click="moveDown(index)">▼</button></td>
              <td>
                {{
                  GLOBAL.i18nCheckName(getGamesCategorys.find(
                    (data) => list.category === data.id
                  ))
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GamesCategorySortList,
  createGamesCategorySort,
  updateGamesCategorySort
} from "@/api/operationManagement/GamesCategorySort";
import { mapGetters } from "vuex";

export default {
  name: "GamesCategorySort",
  components: {
  },
  data() {
    return {
      allData: [],
      category: undefined,
    };
  },
  computed: {
    ...mapGetters(["getGamesCategorys", "getUserinfo"])
  },
  created() {
    this.category = this.getGamesCategorys[0]?.id;
    this.search()
  },
  watch: {
  },
  methods: {
    search() {
      GamesCategorySortList({}).then(res => {
        this.allData = res.data;
      });
    },
    create() {
      createGamesCategorySort({ category: this.category }).then(res => {
        if (res) this.search();
      });
    },
    update() {
      updateGamesCategorySort({ list: this.allData }).then(res => {
        if (res) this.search();
      });
    },
    moveUp(index) {
      let data = this.allData;
      if (index > 0 && index < data.length) {
        var tempSort = data[index]["sort"];
        data[index]["sort"] = data[index - 1]["sort"];
        data[index - 1]["sort"] = tempSort;

        // 重新排序
        data.sort((a, b) => a.sort - b.sort);
        this.allData = data;
      }
    },
    moveDown(index) {
      let data = this.allData
      if (index >= 0 && index < data.length - 1) {
        var tempSort = data[index]["sort"];
        data[index]["sort"] = data[index + 1]["sort"];
        data[index + 1]["sort"] = tempSort;

        // 重新排序
        data.sort((a, b) => a.sort - b.sort);
        this.allData = data;
        console.log(this.allData);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gamesCategory {
  display: flex;
  flex-direction: column;

  label {
    width: auto;

    select {
      width: 150px;
    }
  }
}
</style>