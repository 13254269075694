var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("Permission." + _vm.$route.meta.permissionName))
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.memberAccount,
                      expression: "editModel.memberAccount",
                    },
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.editModel.memberAccount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "memberAccount",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.remark,
                      expression: "editModel.remark",
                    },
                  ],
                  attrs: { rows: "5" },
                  domProps: { value: _vm.editModel.remark },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "remark", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "advance-block" },
              [
                [
                  _c(
                    "button",
                    {
                      staticClass: "normal-btn",
                      on: {
                        click: function ($event) {
                          if ($event.target !== $event.currentTarget)
                            return null
                          return _vm.handleCancel(2)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.accept")))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "delete-btn",
                      on: {
                        click: function ($event) {
                          if ($event.target !== $event.currentTarget)
                            return null
                          return _vm.handleCancel(3)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.reject")))]
                  ),
                ],
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.edit,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }