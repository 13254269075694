<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              <strong>{{ $t(`${i18nPrefix}deviceType`) }}</strong>
              <select v-model="createModel.deviceType">
                <option v-for="list of deviceType" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label v-show="!checkWeb()">
              <div>
                <strong>{{ $t(`${i18nPrefix}version`) }}</strong>
                {{ '(' + $t(`${i18nPrefix}msg`) + ')' }}
                <input type="text" v-model="createModel.version" />
              </div>
            </label>
            <strong>{{ $t(`${i18nPrefix}auto`) }}</strong>
            <div class="input-block-multiselect">
              {{ $t(`${i18nPrefix}country`) }}
              <multiselect v-model="country" label="name" track-by="id" :options="countrys" :custom-label="nameWithLang"
                :multiple="true"></multiselect>
            </div>
            <div class="input-block-multiselect" v-show="!checkWeb()">
              {{ $t(`${i18nPrefix}lang`) }}
              <multiselect v-model="lang" label="name" track-by="id" :options="langs" :multiple="true"></multiselect>
            </div>
            <label v-show="!checkWeb()">
              <strong>{{ $t(`${i18nPrefix}pattern`) }}</strong>
              <select v-model="createModel.pattern">
                <option v-for="list of pattern" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label v-show="createModel.pattern === 1">
              {{ $t(`${i18nPrefix}checkTime`) }}
              <date-picker v-model="firstInstallTime" format="YYYY-MM-DD HH:mm:ss" value-type="format"
                type="datetime"></date-picker>
            </label>
            <label v-show="createModel.pattern === 2">
              {{ $t(`${i18nPrefix}checkTime`) }}
              <date-picker v-model="lastInstallTime" format="YYYY-MM-DD HH:mm:ss" value-type="format"
                type="datetime"></date-picker>
            </label>
            <label v-show="checkWeb()">
              <strong>{{ $t(`${i18nPrefix}redirectUrl`) }}</strong>
              <input type="text" v-model="createModel.redirectUrl" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create" :disabled="checkWeb() ? false : checkBtn">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { transToday, joinT } from "@/utils/transDateUtils";

export default {
  name: "Create",
  props: ["langs", "countrys", "pattern", "deviceType", "i18nPrefix"],
  data() {
    return {
      createModel: {
        version: '1.0.0',
        deviceType: 3,
        pattern: 0,
        lang: [],
        country: [],
        firstInstallTime: undefined,
        lastInstallTime: undefined,
        redirectUrl: ""
      },
      lang: undefined,
      country: undefined,
      firstInstallTime: transToday("from") + this.GLOBAL.startSecond,
      lastInstallTime: transToday("to") + this.GLOBAL.endSecond,
      checkBtn: false
    };
  },
  components: {
    Multiselect
  },
  watch: {
    "createModel.version"(val) {
      this.createModel.version = val.trim()
      this.checkBtn = val.trim() === '' ? true : false
    },
    "createModel.deviceType"(val) {
      if (val == 1 || val == 2) {
        this.createModel.version = "";
        this.createModel.pattern = 0;
        this.lang = [];
      }
    }
  },
  methods: {
    create() {
      this.createModel.firstInstallTime = this.firstInstallTime
        ? joinT(this.firstInstallTime + this.GLOBAL.timeZone)
        : undefined;
      this.createModel.lastInstallTime = this.lastInstallTime
        ? joinT(this.lastInstallTime + this.GLOBAL.timeZone)
        : undefined;
      this.createModel.lang = this.lang ? this.lang.map(data => data.name) : [];
      this.createModel.country = this.country
        ? this.country.map(data => data.name)
        : [];
      this.$emit("create", this.createModel);
    },
    nameWithLang({ name, title }) {
      return `${name} (${title})`;
    },
    checkWeb() {
      return this.createModel.deviceType === 1 || this.createModel.deviceType === 2
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 30%;

  .input-block {

    .input-block-multiselect,
    input,
    label {
      width: 100%;
    }
  }

  .advance-block {
    margin-top: 100px;
  }
}
</style>