<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}account`) }}
              <input type="text" v-model="editModel.account" disabled/>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.password`) }}
              <input type="text" v-model="editModel.password" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}customRole`) }}
              <select v-model="editModel.customRoleId">
                <option
                  v-for="list of customRoleList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click.self="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @mousedown.self="$emit('toggle')">
              {{ $t("default.cancel") }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validForm } from "./utils";

export default {
  name: "Edit",
  props: ["customRoleList", "data","i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.data.id,
        status: this.data.status,
        account: this.data.account,
        password: "",
        customRoleId: this.data.customRoleId,
      },
    };
  },
  computed: {
    ...mapGetters(["getStatusList"]),
  },
  methods: {
    submit() {
      this.$emit("edit", this.editModel);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-block {
  label {
    width: 80%;
  }
}
</style>