export let searchModel = {
    submitStartTime: undefined,
    submitEndTime: undefined,
    auditStartTime: undefined,
    auditEndTime: undefined,
    memberAccount: "",
    agentId: 0,
    cryptoInfoId: undefined,
    cvsInfoId: undefined,
    memberBankId: 0,
    auditStatuses: [],
    tagId: 0,
    page:  {
        "take": 10,
        "skip": 0
    },
}