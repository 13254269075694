<template>
  <div class="form-box form-column shadow-sm px-3 py-4 bg-white rounded overview-table">
    <div class="table-block">
      <TreeTable ref="treeTable" :list="agentCommission.list" :column="tableColumn">
        <!-- 遊戲種類 -->
        <template
          v-slot:column_gamesCategory="props"
        >{{ `${(props.gamesCategory ? GLOBAL.i18nCheckName(getGamesCategorys.find( data => data.id == props.gamesCategory)) : $t(`${i18nPrefix}rebateGame.allGame`))}` }}</template>
        <!-- 總成本 -->
        <template v-slot:column_discountAmount="props">
          {{ !props.gamesCategory || props.gamesCategory == 99999 ? props.discountAmount : '' }}
        </template>
        <!-- 下線佣金 -->
        <template v-slot:column_childrenCommission="props">
          {{ props.childrenCommission }}
          <span
            class="rate"
            :class="{ childrenRate: props.gamesCategory }"
            v-show="props.gamesCategory && props.gamesCategory !== 99999"
          >{{ props.childrenRate + '%' }}</span>
        </template>
        <!-- 下線成本 -->
        <template v-slot:column_childrenCost="props">
          {{ !props.gamesCategory || props.gamesCategory == 99999 ? props.childrenCost : '' }}
          <span
            class="rate"
            :class="{ childrenRate: props.gamesCategory }"
            v-show="!props.gamesCategory"
          >{{ props.childrenCostRate + '%' }}</span>
        </template>
        <!-- 下線盈餘 -->
        <template v-slot:column_childrenSurplus="props">
          {{ !props.gamesCategory || props.gamesCategory == 99999 ? props.childrenSurplus : '' }}
        </template>
        <!-- 我的佣金 -->
        <template v-slot:column_selfCommission="props">
          {{ props.selfCommission }}
          <span
            class="rate"
            :class="{ childrenRate: props.gamesCategory }"
            v-show="props.gamesCategory && props.gamesCategory !== 99999"
          >{{ props.selfRate + '%' }}</span>
        </template>
        <!-- 我的成本 -->
        <template v-slot:column_selfCost="props">
          {{ !props.gamesCategory || props.gamesCategory == 99999 ? props.selfCost : '' }}
          <span
            class="rate"
            :class="{ childrenRate: props.gamesCategory }"
            v-show="!props.gamesCategory"
          >{{ props.selfCostRate + '%' }}</span>
        </template>
        <!-- 我的盈餘 -->
        <template v-slot:column_selfSurplus="props">
          <span class="rate">{{ props.selfSurplus }}</span>
        </template>
      </TreeTable>
    </div>
  </div>
</template>

<script>
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";

export default {
  name: "Commission",
  props: ["agentCommission", "i18nPrefix"],
  data() {
    return {
      rebateList: [],
    };
  },
  components: {
    TreeTable
  },
  computed: {
    ...mapGetters(["getGamesCategorys"]),
    tableColumn() {
      return [
        {
          key: "agentAccount",
          name: this.$t(`${this.i18nPrefix}rebateGame.agentAccount`),
          align: "left",
          width: 200
        },
        {
          key: "gamesCategory",
          name: this.$t(`${this.i18nPrefix}rebateGame.gamesCategory`)
        },
        {
          key: "winLoseAmount",
          name: this.$t(`${this.i18nPrefix}rebateGame.winLose`)
        },
        {
          key: "discountAmount",
          name: this.$t(`${this.i18nPrefix}rebateGame.cost`)
        },
        {
          key: "childrenCommission",
          name: this.$t(`${this.i18nPrefix}rebateGame.childrenRebateAmount`)
        },
        {
          key: "childrenCost",
          name: this.$t(`${this.i18nPrefix}rebateGame.childrenCost`)
        },
        {
          key: "childrenSurplus",
          name: this.$t(`${this.i18nPrefix}rebateGame.childrenSurplus`)
        },
        {
          key: "selfCommission",
          name: this.$t(`${this.i18nPrefix}rebateGame.rebateAmount`)
        },
        {
          key: "selfCost",
          name: this.$t(`${this.i18nPrefix}rebateGame.selfCost`)
        },
        {
          key: "selfSurplus",
          name: this.$t(`${this.i18nPrefix}rebateGame.selfSurplus`)
        }
      ];
    }
  },
  watch: {
  },
  async created() {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

.rate {
  border: 1px solid #ff0000;
  border-radius: 3px;
  background: #ff0000;
  color: #fff;
  margin-left: 5px;
  padding: 1px 6px;
}
.childrenRate {
  border: 1px solid gray;
  background: gray;
}
</style>