<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')">{{ $t("default.create") }}</button>
      </div>
      <div class="main">
        <div class="advance-block">
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}domain`) }}
              <select v-model="searchForm.agentId">
                <option :value="0 * 1">{{ $t("default.all") }}</option>
                <option
                    v-for="list of agentList"
                    :key="list.id"
                    :value="list.id * 1"
                >
                  {{ list.domain }}
                </option>
              </select>
            </label>
          </div>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
          <tr>
            <th></th>
            <th>{{ $t(`${i18nPrefix}name`) }}</th>
            <th>{{ $t(`${i18nPrefix}page`) }}</th>
            <th>SEO</th>
            <th>{{ $t(`${i18nPrefix}domain`) }}</th>
            <th>{{ $t("default.status") }}</th>
            <th>{{ $t(`${i18nPrefix}creator`) }}</th>
            <th>{{ $t(`${i18nPrefix}createTime`) }}</th>
            <th>{{ $t(`${i18nPrefix}editor`) }}</th>
            <th>{{ $t(`${i18nPrefix}editTime`) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!tableList || tableList.length === 0">
            <td colspan="10">{{ $t("default.noData") }}</td>
          </tr>
          <tr v-for="list of tableList" :key="list.id">
            <td><button class="delete-btn" @click="handleDelete({ id: list.id })">{{ $t("default.delete") }}</button></td>
            <td>{{ list.name }}</td>
            <td>{{ gamesCategoryList.find((data) => data.key === list.pageType).description }}</td>
            <td><button class="normal-btn" @click="handleDialog(list, 'EditSEO')">{{ $t("default.edit") }}</button></td>
            <td><button class="normal-btn" @click="handleDialog(list, 'Domain')">{{ $t("default.edit") }}</button></td>
            <td>
              <label>
                <div
                    class="slide-block"
                    style="margin: auto"
                    :class="{ 'slide-block-on': list.status }"
                >
                  <div
                      class="slide"
                      @click="toggleSlide"
                      :class="{ 'slide-on': list.status }"
                  >
                    <label for="deleteStatus" style="display: none"></label>
                    <input
                        type="checkbox"
                        id="deleteStatus"
                        v-model="list.status"
                        @click="updateStatus({ id: list.id , status: !list.status})"
                        style="display: none"
                    />
                  </div>
                </div>
              </label>
            </td>
            <td>{{ list.createUserAccount }}</td>
            <td>{{ list.createTime }}</td>
            <td>{{ list.updateUserAccount }}</td>
            <td>{{ list.updateTime }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
    />
    <transition name="fade" mode="out-in">
      <component
          :gamesCategoryList = "gamesCategoryList"
          :agentList = "agentList"
          :pageList = "allData"
          :is="currDialog"
          v-bind="dialogData"
          @toggle="currDialog = undefined"
          @create="create"
          @editSEO="updateMetaData"
          @createDomain="addDomains"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Confirm
          v-if="getShowConfirm"
          @toggle="TOGGLE_CONFIRM"
          @delete="deleteList"
      />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getSEOList,
  getAgentList,
  create,
  clone,
  deleteList,
  updateMetaData,
  updateStatus,
  addDomains,
} from "@/api/SEO/SEO";
import { toggleStyle } from "@/utils/slideUtils";
import Message from "@/layout/components/Message";
import Confirm from "@/layout/components/Confirm";
import Create from "./dialog/create.vue";
import EditSEO from "./dialog/edit-SEO.vue";
import Domain from "./dialog/domain.vue"
import { mapGetters, mapMutations } from "vuex";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "SEO",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      deleteData: undefined,
      agentList: [],
      gamesCategoryList: [],
      i18nPrefix: "SEO-Function.SEO."
    };
  },
  components: {
    Confirm,
    Create,
    EditSEO,
    Domain,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo"]),
  },
  created() {
    getAgentList().then((res) => {
      this.agentList = res.data.agents;
      this.gamesCategoryList = res.data.pageTypes;
    });
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    toggleSlide: toggleStyle,
    search() {
      this.currDialog = undefined;
      this.dialogData = undefined;
      this.deleteData = undefined;
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
      getSEOList(this.searchForm).then((res) => {
        this.allData = res.data.pages;
      });
    },
    updateStatus(data) {
      updateStatus(data).then((res) => {
        if(res) this.search();
      })
    },
    create(data) {
      if (data.pageId) {
        clone(data).then((res) => {
          if(res) this.search();
        });
        return
      }
      create(data).then((res) => {
        if(res) this.search();
      });
    },
    addDomains(data) {
      addDomains(data).then((res) => {
        if(res) this.search();
      });
    },
    handleDelete(data) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
    },
    deleteList() {
      deleteList(this.deleteData).then((res) => {
        if(res) this.search();
      });
    },
    updateMetaData(data){
      updateMetaData(data).then((res) => {
        if(res) this.search();
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>