export let searchModel = {
    startTime: undefined,
    endTime: undefined,
    memberAccount: undefined,
    transactionType: 0,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}