<template>
  <div class="navbar-block" ref="nav">
    <div
      class="hamburger-box"
      :class="{ hide: !sidebar }"
      @click="toggleSidebar"
    >
      <div class="hamburger"></div>
    </div>
    <div class="title">
      <p>
        <router-link :to="'/dashboard'">
          <span>{{ getUserinfo.name }}</span>
          <span>{{ $t("default.backManagement") }}</span>
        </router-link>
      </p>
      <div class="memberCount">
        <!-- {{ $t("default.onlineMemberAmount") }}：{{ memberCount }} -->
        <!-- <div class="lang">
          <button type="button" @click="i18nSet('tw')" class="btn" :class="{ choose: lang === 'tw'}">中文</button>
          <button type="button" @click="i18nSet('en')" class="btn" :class="{ choose: lang === 'en'}">English</button>
        </div> -->
      </div>
    </div>
    <!-- <div v-if="platformVersion == 'PRS'" class="logo">
      <img src="@/assets/navbar/prs_logo.png">
    </div> -->
    <div class="user">
      <div
        class="new"
        v-show="haveMail && getUserinfo.permissions.includes(80000)"
      >
        <div class="bell">
          <img class="bell-img" src="@/assets/navbar/bell.svg" @click.stop="toggleNewItem" />
        </div>
        <!-- <i class="fas fa-bell" @click.stop="toggleNewItem"></i> -->
        <div
          class="new-item"
          :class="[notification ? 'active' : '']"
          ref="newItem"
        >
          <router-link
            to="/member/member-deposit?permissionId=10200"
            v-show="getMailData.find((data) => data.permissionId === 10200)"
            @click.native="
              readMessage('/member/member-deposit?permissionId=10200')
            "
          >
            <img src="@/assets/deposit.png" />
          </router-link>
          <router-link
            to="/member/member-deposit-store?permissionId=10300"
            v-show="getMailData.find((data) => data.permissionId === 10300)"
            @click.native="
              readMessage('/member/member-deposit-store?permissionId=10300')
            "
          >
            <img src="@/assets/store.png" />
          </router-link>
          <router-link
            to="/member/member-withdraw?permissionId=10400"
            v-show="getMailData.find((data) => data.permissionId === 10400)"
            @click.native="
              readMessage('/member/member-withdraw?permissionId=10400')
            "
          >
            <img src="@/assets/member-withdraw.png" />
          </router-link>
          <router-link
            to="/member/member-discount?permissionId=10500"
            v-show="getMailData.find((data) => data.permissionId === 10500)"
            @click.native="
              readMessage('/member/member-discount?permissionId=10500')
            "
          >
            <img src="@/assets/bonus.png" />
          </router-link>
          <router-link
            to="/member/member-withdraw?permissionId=10106"
            v-show="getMailData.find((data) => data.permissionId === 10106)"
            @click.native="
              readMessage('/member/member-withdraw?permissionId=10106')
            "
          >
            <img src="@/assets/member-withdraw.png" />
          </router-link>
          <router-link
            to="/userInfo/mail-box?permissionId=61000"
            v-show="getMailData.find((data) => data.permissionId === 61000)"
          >
            <img src="@/assets/risk.png" />
          </router-link>
          <router-link
            to="/userInfo/mail-box?permissionId=120200"
            v-show="getMailData.find((data) => data.permissionId === 120200)"
          >
            <img src="@/assets/blackList.png" />
          </router-link>
          <router-link
            to="/userInfo/mail-box?permissionId=10109"
            v-show="getMailData.find((data) => data.permissionId === 10109)"
          >
            <img src="@/assets/bankPic.png" />
          </router-link>
          <router-link
            to="/agent/agent-withdrew?permissionId=20501"
            v-show="getMailData.find((data) => data.permissionId === 20501)"
          >
            <img src="@/assets/agent-withdraw.png" />
          </router-link>
          <router-link
            to="/agent/agent-bankCard-inquiry?permissionId=20303"
            v-show="getMailData.find((data) => data.permissionId === 20303)"
          >
            <img src="@/assets/deleteAgentCard.png" />
          </router-link>
          <router-link
            to="/member/Kyc"
            v-show="getMailData.find((data) => data.permissionId === 13000)"
          >
            <img src="@/assets/KYC.png" />
          </router-link>
          <!-- <router-link
            to="/member/member-deposit?permissionId=10200"
            @click.native="
              readMessage('/member/member-deposit?permissionId=10200')
            "
          >
            <img src="@/assets/deposit.png" />
          </router-link>
          <router-link
            to="/member/member-deposit-store?permissionId=10300"
            @click.native="
              readMessage('/member/member-deposit-store?permissionId=10300')
            "
          >
            <img src="@/assets/store.png" />
          </router-link>
          <router-link
            to="/member/member-withdraw?permissionId=10400"
            @click.native="
              readMessage('/member/member-withdraw?permissionId=10400')
            "
          >
            <img src="@/assets/member-withdraw.png" />
          </router-link>
          <router-link
            to="/member/member-withdraw?permissionId=10106"
            @click.native="
              readMessage('/member/member-withdraw?permissionId=10106')
            "
          >
            <img src="@/assets/member-withdraw.png" />
          </router-link>
          <router-link
            to="/userInfo/mail-box?permissionId=61000"
          >
            <img src="@/assets/risk.png" />
          </router-link>
          <router-link
            to="/userInfo/mail-box?permissionId=120200"
          >
            <img src="@/assets/blackList.png" />
          </router-link>
          <router-link
            to="/userInfo/mail-box?permissionId=10109"
          >
            <img src="@/assets/bankPic.png" />
          </router-link>
          <router-link
            to="/agent/agent-withdrew?permissionId=20501"
          >
            <img src="@/assets/agent-withdraw.png" />
          </router-link>
          <router-link
            to="/agent/agent-bankCard-inquiry?permissionId=20303"
          >
            <img src="@/assets/deleteAgentCard.png" />
          </router-link> -->
        </div>
      </div>
      <SelectStyleV1
        :list="GLOBAL.langes"
        :default="lang"
        :now.sync="nowLang"
        style="margin-right: 20px; color: #3D4F59"
      />
      <div class="user-account" @click.stop="toggleUserDropDown">
        {{ $store.getters.userName }}
      </div>
      <transition name="fade" mode="out-in">
        <UserDropDown v-show="drop"></UserDropDown>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { memberCount } from "@/api/user";
import UserDropDown from "./UserDropDown";
import { getServerLang } from "@/api/user";
import SelectStyleV1 from "./selectStyleV1";

export default {
  name: "Navbar",
  components: {
    UserDropDown,
    SelectStyleV1,
  },
  data() {
    return {
      nowLang: "",
      platformVersion: ""
    };
  },
  created() {
    document.title = this.getUserinfo.name;
    this.platformVersion = process.env.VUE_APP_platformVersion;
    // if(this.token){
    //   memberCount().then((res) => {
    //     this.SET_MEMBERCOUNT(res.data?.count);
    //   });
    // }
  },
  computed: {
    ...mapGetters([
      "token",
      "sidebar",
      "getUserDropDown",
      "getMail",
      "memberCount",
      "getUserinfo",
      "getMailData",
      "lang",
      "getNotification",
    ]),
    drop: {
      get() {
        return this.getUserDropDown;
      },
      set(value) {
        this.SET_USERDROPDOWN(value);
      },
    },
    haveMail: {
      get() {
        return this.getMail;
      },
      set(value) {
        this.SET_MAIL(value);
      },
    },
    notification: {
      get() {
        return this.getNotification;
      },
      set(value) {
        this.SET_NOTIFICATION(value);
      },
    },
  },
  watch: {
    nowLang: {
      handler(e) {
        this.i18nSet(e);
      },
    },
  },
  methods: {
    ...mapMutations({
      SET_USERDROPDOWN: "user/SET_USERDROPDOWN",
      SET_MAIL: "user/SET_MAIL",
      SET_MEMBERCOUNT: "user/SET_MEMBERCOUNT",
      SET_NOTIFICATION: "user/SET_NOTIFICATION",
    }),
    toggleSidebar() {
      this.$store.dispatch("app/toggleSidebar");
    },
    toggleUserDropDown() {
      this.drop = !this.drop;
    },
    readMessage(path) {
      this.$router.push({
        path: "/userInfo/empty",
        query: { page: path },
      });
    },
    async i18nSet(lang) {
      await this.$store.commit("user/SET_LANG", lang);
      await getServerLang(lang).then((res) => {
        this.$i18n.setLocaleMessage(
          "localeLang",
          Object.assign([], this.GLOBAL.langData[lang], res)
        )
      });
    },
    toggleNewItem() {
      this.notification = !this.notification;
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  .hamburger:before {
    transform: rotate(150deg);
    width: 65% !important;
    left: -1px;
    margin-top: 15%;
  }
  .hamburger:after {
    transform: rotate(-150deg);
    width: 65% !important;
    left: -1px;
    margin-bottom: 11%;
  }
}
.navbar-block {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  height: 70px;
  width: 100%;
  background-color: #749AAF;
  padding: 12px 16px;
  //box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
  .hamburger-box {
    position: relative;
    width: 24px;
    height: 54px;
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    // &:hover .hamburger,
    // &:hover .hamburger:before,
    // &:hover .hamburger:after {
    //   background-color: #fff;
    // }
    .hamburger {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: #D6E3EA;
      border-radius: 100px;
      transition-duration: 0.3s;
    }
    .hamburger:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -7px;
      width: 100%;
      height: 2px;
      background-color: #D6E3EA;
      border-radius: 100px;
      transition-duration: 0.3s;
    }
    .hamburger:before {
      content: "";
      display: block;
      position: absolute;
      top: -7.15px;
      width: 100%;
      height: 2px;
      background-color: #D6E3EA;
      border-radius: 100px;
      transition-duration: 0.3s;
    }
  }
  .title {
    display: flex;
    align-items: center;
    margin-right: auto;
    p {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      span {
        color: #fff;
      }
      span:first-child {
        margin-right: 10px;
      }
    }
    .memberCount {
      display: flex;
      align-items: center;
      color: #000000;
      font-weight: bold;
      .lang {
        margin-left: 10px !important;
      }
    }
  }
  .logo {
    margin-right: auto;
    img {
      width: 85%;
    }
  }
  .user {
    display: flex;
    align-items: center;
    button {
      transition: 0.2s;
    }
    button:hover {
      background-color: #fff;
      border: 1px solid #000;
      color: #000 !important;
    }
    .new {
      position: relative;
      margin-right: 15px;
      .fa-bell {
        color: white;
        font-size: 20px;
        animation: shiny 1s linear infinite alternate;
        cursor: pointer;
      }
      .bell {
        display: flex;
        background-color: #fff;
        border-radius: 999px;
        width: 30px;
        height: 30px;
        justify-content: center;
        .bell-img {
          width: 16px;
          animation: rotate 0.2s linear infinite;
        }
      }
      .new-item {
        position: absolute;
        top: 105%;
        right: 0;
        display: flex;
        align-items: center;
        gap: 0 5px;
        padding: 5px 10px;
        background-color: rgba(0, 0, 0, 0.529);
        border: 2px solid #000;
        border-radius: 3px;
        transform-origin: top;
        transform: scaleY(0);
        transition-duration: 300ms;
        overflow: auto;
        img {
          transition-duration: 200ms;
          &:hover {
            -webkit-filter: drop-shadow(0px 0px 7px #aaa);
            filter: drop-shadow(0px 0px 7px #aaa);
          }
        }
      }
      .active {
        transform: scaleY(100%);
      }
    }
    .user-account {
      display: flex;
      justify-content: center;
      background-color: #fff;
      border-radius: 30px;
      padding: 5px 15px;
      color: #3D4F59;
      border: 2px solid #CD7B00;
      cursor: pointer;
    }
  }
}

@keyframes shiny {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(20deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(-20deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@media screen and (max-width: 992px) {
  .navbar-block {
    .title {
      p {
        span:last-child {
          display: none;
        }
      }
    }
    .logo {
      display: none;
    }
  }
}
@media screen and (max-width: 650px) {
  .new-item {
    flex-wrap: wrap;
    right: 50% !important;
    transform: translateX(50%) scaleY(0) !important;
  }
  .active {
    transform: translateX(50%) scaleY(100%) !important;
  }
}
@media screen and (max-width: 500px) {
  .navbar-block {
    // padding: 12px 0;
    .user {
      top: 30px;
      // .new {
      //   display: flex;
      //   position: absolute;
      //   bottom: -40px;
      //   right: -30px !important;
      //   svg {
      //     margin: 0 10px;
      //     width: 20px !important;
      //     height: 20px !important;
      //     color: #fff;
      //   }
      // }
    }
    .title {
      display: none;
      span {
        line-height: 35px;
        font-size: 20px;
      }
      .memberCount {
        flex-direction: column;
        align-items: normal;
        .lang {
          margin: 0 !important;
        }
        .btn {
          padding: 0.1rem 0.3rem;
        }
      }
    }
  }
}
.btn {
  color: #000 !important;
  border-color: #000 !important;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
  }
}
.choose {
  color: #ffffff !important;
  background-color: #6c757d !important;
}
</style>