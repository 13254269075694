import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getGamesCategoryBannerList(data) {
    return request({
        url: "/GamesCategoryBanner/Query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createGamesCategoryBanner(data) {
    return request({
        url: "/GamesCategoryBanner/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateGamesCategoryBanner(data) {
    return request({
        url: "/GamesCategoryBanner/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}