<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") }}{{ $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}type`) }}
              <select v-model="editModel.announcementType">
                <option
                  v-for="list of announcementTypes"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}sort`) }}
              <input type="text" v-model="editModel.sortId" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}active`) }}
              <select v-model="editModel.status">
                <option :value="1 * 1">{{ $t("default.active") }}</option>
                <option :value="2 * 1">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}isBroadcast`) }}
              <select v-model="editModel.isBroadcast">
                <option :value="true">{{ $t("default.active") }}</option>
                <option :value="false">{{ $t("default.inactive") }}</option>
              </select>
            </label>
            <label class="title">
              {{ $t(`${i18nPrefix}title`) }}
              <textarea v-model="editModel.title" rows="3"></textarea>
            </label>
            <label class="CK">
              {{ $t(`${i18nPrefix}announcement(zh-tw)`) }}
              <ckeditor
                v-model="editModel.content"
                :editor-url="this.GLOBAL.CKEditorUrl"
                :config="editorConfig"
              ></ckeditor>
            </label>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="$emit('edit', editModel)">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  props: [
    "id",
    "status",
    "announcementType",
    "content",
    "sortId",
    "title",
    "isBroadcast",
    "announcementTypes"
  ],
  data() {
    return {
      editorConfig: {
        allowedContent: true,
        height: "400"
      },
      editModel: {
        id: this.id,
        status: this.status,
        announcementType: this.announcementType,
        content: this.content,
        sortId: this.sortId,
        title: this.title,
        isBroadcast: this.isBroadcast
      },
      i18nPrefix: "features.announcement."
    };
  }
};
</script>

<style lang="scss" scoped>
.input-block {
  label {
    justify-content: flex-start;
    width: 40%;
  }
  .CK {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .input-block {
    label {
      width: 50%;
    }
    .title {
      width: 100%;
    }
    .CK {
      width: 100%;
    }
  }
}
</style>