var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask create-member",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("default.edit")) +
                  _vm._s(_vm.$t(`title.gameInfo`)) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gateway`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.Name,
                      expression: "createModel.Name",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.Name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "Name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" RedirectType "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.RedirectType,
                        expression: "createModel.RedirectType",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "RedirectType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.redirectType, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "label",
                { staticClass: "w-100" },
                [
                  _c("h5", [_vm._v("Web Notice")]),
                  _vm._l(_vm.web, function (list) {
                    return [
                      _c("label", { staticClass: "textarea-div" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("LanguageType." + list.lang)) +
                            " "
                        ),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.value,
                              expression: "list.value",
                            },
                          ],
                          domProps: { value: list.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(list, "value", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "label",
                { staticClass: "w-100" },
                [
                  _c("h5", [_vm._v("App Notice")]),
                  _vm._l(_vm.app, function (list) {
                    return [
                      _c("label", { staticClass: "textarea-div" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("LanguageType." + list.lang)) +
                            " "
                        ),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.value,
                              expression: "list.value",
                            },
                          ],
                          domProps: { value: list.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(list, "value", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "imgDiv" }, [
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("File")
                    },
                  },
                },
                [
                  !_vm.File
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$$t("default.clickToUpload")) + "Image"
                        ),
                      ])
                    : _c("img", { attrs: { src: _vm.File } }),
                ]
              ),
            ]),
            _c("input", {
              ref: "file",
              staticClass: "custom-file",
              attrs: { type: "file" },
              on: { change: _vm.fileSelect },
            }),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.create } },
                [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }