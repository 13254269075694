<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label class="multiselectDiv">
              {{ $t(`${i18nPrefix}bankName`) }}
              <input type="text" :value="bankCode+'－'+bankName" disabled>
            </label>
            <label>
              {{ $t(`${i18nPrefix}bankAccountName`) }}
              <input type="text" v-model="editModel.bankAccountName" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bankAccountNumber`) }}
              <input type="text" v-model="editModel.bankAccountNumber" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.bank`) }}
              <select v-model="editModel.bankGroupId">
                <option
                  v-for="list of bankGroupList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validAllInput } from "@/utils/validate";
import Message from "@/layout/components/Message";

export default {
  name: "Edit",
  props: [
    "id",
    "bankCode",
    "bankName",
    "bankAccountName",
    "bankAccountNumber",
    "status",
    "bankGroupId",
    "bankGroupList",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        bankAccountName: this.bankAccountName,
        bankAccountNumber: this.bankAccountNumber,
        status: this.status,
        bankGroupId: this.bankGroupId
      }
    }
  },
  computed: {
    ...mapGetters(["getStatusList"]),
  },
  methods: {
    edit() {
      this.editModel.bankGroupName = this.bankGroupList.find((data) => {
        return data.id === this.editModel.bankGroupId;
      }).name;
      return validAllInput(this.editModel)
        ? Message.error(this.$t("default.fieldsNotEntered"), 1000)
        : this.$emit("edit", this.editModel);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-block {
  label {
    width: 40%;
    // &:last-child {
    //   width: 90%;
    // }
  }
}
@media screen and (max-width: 768px) {
  .input-block {
    label {
      width: 50% !important;
    }
    .multiselectDiv {
      width: 100% !important;
    }
  }
}
</style>