var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: {
              click: function ($event) {
                return _vm.search()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(140201),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.create")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block mb-3" }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`)) + " "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.gamePlatformId,
                      expression: "searchForm.gamePlatformId",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "gamePlatformId",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 * 1 } }, [
                    _vm._v(_vm._s(_vm.$t("default.all"))),
                  ]),
                  _vm._l(_vm.gamePlatforms, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gameName`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.name,
                    expression: "searchForm.name",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchForm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchForm, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}hotGame`)) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.isHot,
                      expression: "searchForm.isHot",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "isHot",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: undefined } }, [
                    _vm._v(_vm._s(_vm.$t("default.all"))),
                  ]),
                  _c("option", { domProps: { value: true } }, [
                    _vm._v(_vm._s(_vm.$t("default.active"))),
                  ]),
                  _c("option", { domProps: { value: false } }, [
                    _vm._v(_vm._s(_vm.$t("default.inactive"))),
                  ]),
                ]
              ),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}isNew`)) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.isNew,
                      expression: "searchForm.isNew",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "isNew",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: undefined } }, [
                    _vm._v(_vm._s(_vm.$t("default.all"))),
                  ]),
                  _c("option", { domProps: { value: true } }, [
                    _vm._v(_vm._s(_vm.$t("default.active"))),
                  ]),
                  _c("option", { domProps: { value: false } }, [
                    _vm._v(_vm._s(_vm.$t("default.inactive"))),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "advance-block pc-only" }, [
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: { click: _vm.search },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _vm.allData
            ? _c("serverPagination", {
                staticClass: "pc-only",
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allData && _vm.allData.length,
                            expression: "allData && allData.length",
                          },
                        ],
                        staticClass: "btn-td",
                      },
                      [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          on: {
                            click: function ($event) {
                              return _vm.checkAll()
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(140203),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(_vm.ids)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.delete")))]
                        ),
                      ]
                    ),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gameCategoryName`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gameName`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}hotGame`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}isNew`)))]),
                  _c("th", [_vm._v("gameCode")]),
                  _c("th", [_vm._v("SecondGameCode")]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}sort`)))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}status`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}preview`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.allData || !_vm.allData.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "14" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.allData, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c("div", { staticClass: "btn-td" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ids,
                                expression: "ids",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: list.id,
                              checked: Array.isArray(_vm.ids)
                                ? _vm._i(_vm.ids, list.id) > -1
                                : _vm.ids,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.ids,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = list.id,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.ids = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.ids = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.ids = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "table-btn main-style-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(140202),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    { list: list },
                                    "Edit"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.edit")))]
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.gamePlatforms.find(
                                  (data) => list.gamePlatformId === data.id
                                )
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.gamesCategorys.find(
                                  (data) => list.category === data.id
                                )
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.name))]),
                      _c("td", [
                        _c("label", [
                          _c(
                            "div",
                            {
                              staticClass: "slide-block",
                              class: { "slide-block-on": list.isHot },
                              staticStyle: { margin: "auto" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "slide",
                                  class: { "slide-on": list.isHot },
                                  on: { click: _vm.toggleSlide },
                                },
                                [
                                  _c("label", {
                                    staticStyle: { display: "none" },
                                    attrs: { for: "isHot" },
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: list.isHot,
                                        expression: "list.isHot",
                                      },
                                    ],
                                    staticStyle: { display: "none" },
                                    attrs: { type: "checkbox", id: "isHot" },
                                    domProps: {
                                      checked: Array.isArray(list.isHot)
                                        ? _vm._i(list.isHot, null) > -1
                                        : list.isHot,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateIsHot({
                                          id: list.id,
                                          isHot: !list.isHot,
                                        })
                                      },
                                      change: function ($event) {
                                        var $$a = list.isHot,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                list,
                                                "isHot",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                list,
                                                "isHot",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(list, "isHot", $$c)
                                        }
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("td", { staticClass: "small-td" }, [
                        _c("label", [
                          _c(
                            "div",
                            {
                              staticClass: "slide-block",
                              class: { "slide-block-on": list.isNew },
                              staticStyle: { margin: "auto" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "slide",
                                  class: { "slide-on": list.isNew },
                                  on: { click: _vm.toggleSlide },
                                },
                                [
                                  _c("label", {
                                    staticStyle: { display: "none" },
                                    attrs: { for: "isNew" },
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: list.isNew,
                                        expression: "list.isNew",
                                      },
                                    ],
                                    staticStyle: { display: "none" },
                                    attrs: { type: "checkbox", id: "isNew" },
                                    domProps: {
                                      checked: Array.isArray(list.isNew)
                                        ? _vm._i(list.isNew, null) > -1
                                        : list.isNew,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateIsNew({
                                          id: list.id,
                                          isNew: !list.isNew,
                                        })
                                      },
                                      change: function ($event) {
                                        var $$a = list.isNew,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                list,
                                                "isNew",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                list,
                                                "isNew",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(list, "isNew", $$c)
                                        }
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.gameCode))]),
                      _c("td", [_vm._v(_vm._s(list.secondGameCode))]),
                      _c("td", [_vm._v(_vm._s(list.sortId))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            list.status === 1
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "url-td" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: list.imageUrl,
                                expression: "list.imageUrl",
                              },
                            ],
                            attrs: { title: list.imageUrl },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(
                                  { image: list.imageUrl + _vm.v },
                                  "Photo"
                                )
                              },
                            },
                          },
                          [_vm._v("image")]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.allData
            ? _c("serverPagination", {
                attrs: { page: _vm.page },
                on: { ServerPageUtils: _vm.search, changeTake: _vm.changeTake },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  gamePlatforms: _vm.gamePlatforms,
                  gamesCategorys: _vm.gamesCategorys,
                  platformVersions: _vm.platformVersions,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                  create: _vm.create,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.deleteGameInfo,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }