import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function createAgent(data) {
    return request({
        url: "/agent/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editAgent(data) {
    return request({
        url: "/agent/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getList(data) {
    return request({
        url: "/agent/NewList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function agentMemberList(data) {
    return request({
        url: "/agent/listMember",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSelectMenu() {
    return request({
        url: "/Agent/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function agentTreeList(data) {
    return request({
        url: "/agent/tree",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getParentPermissions(data) {
    return request({
        url: "/agent/GetParentPermissions",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function agentInfo() {
    return request({
        url: "/agent/info",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function removeVerificationInfo(data) {
    return request({
        url: "/agent/RemoveVerificationInfo",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createAgentPromotionLink(data) {
    return request({
        url: "/AgentPromotionLink/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getAgentProcess(data) {
    return request({
        url: "/agent/Process",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}