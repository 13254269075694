var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(62301),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.create")) + " ")]
            ),
          ]),
        ]),
        _c(
          "main",
          [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}walletName`))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.currencyName")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.exchangeRate")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("default.processAccount"))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.processTime")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(62302),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog({ data: list }, "Edit")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.edit")))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.walletName))]),
                      _c("td", [_vm._v(_vm._s(list.currencyName))]),
                      _c("td", [_vm._v(_vm._s(list.exchangeRate))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            list.status === 1
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            on: {
                              click: function ($event) {
                                return _vm.getProcess(list.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.processAccount")))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.updateTime))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm.totalCount > 0
              ? _c("Pagination", {
                  attrs: { totalCount: _vm.totalCount },
                  on: { handlePage: _vm.handlePage },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  currency: _vm.currency,
                  wallet: _vm.wallet,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                  create: _vm.create,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _vm.processData
        ? _c("Process", {
            attrs: {
              processTitle: _vm.processTitle,
              processData: _vm.processData,
            },
            on: {
              toggle: function ($event) {
                _vm.processData = undefined
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "oldValue_Status",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          props.Status === 1
                            ? _vm.$t("default.active")
                            : _vm.$t("default.inactive")
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "newValue_Status",
                  fn: function (props) {
                    return [
                      _vm._v(
                        _vm._s(
                          props.Status === 1
                            ? _vm.$t("default.active")
                            : _vm.$t("default.inactive")
                        )
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1397880754
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }