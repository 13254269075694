var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dw-block" }, [
    _c(
      "div",
      { staticClass: "btn-list" },
      _vm._l(_vm.btnList, function (list) {
        return _c(
          "button",
          {
            key: list.id,
            class: { active: _vm.curr === list.id },
            attrs: {
              disabled:
                !_vm.getUserinfo.permissions.includes(10605) &&
                list.id === "Rebate",
            },
            on: {
              click: function ($event) {
                _vm.curr = list.id
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "component-block" },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _c(_vm.curr, {
              tag: "component",
              attrs: { i18nPrefix: _vm.i18nPrefix },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }