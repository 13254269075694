<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ id ? $t("default.audit") : $t(`${i18nPrefix}allAudit`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="contact-info">
              <label>
                {{ $t(`${i18nPrefix}dialog.way`) }}
                <select v-model="way" :disabled="auditStatus">
                  <option
                v-for="(list, key) in agentWithdrawalInfoList"
                :key="key"
                :value="key"
              >{{ $t("DepositTypeList." + key) }}</option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}paymentName`) }}
                <select
                  v-model="agentWithdrawal.agentWithdrawalPaymentId"
                  :disabled="auditStatus"
                >
                  <option
                    v-for="list of agentWithdrawalInfoList[way]"
                    :key="list.id"
                    :value="list.id"
                  >{{ list.name }}</option>
                </select>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click.self="audit(2)">{{ $t("default.accept") }}</button>
            <button
              class="delete-btn"
              @click.self="id ? audit(3) : $emit('toggle')"
            >{{ id ? $t("default.reject") : $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Audit",
  props: {
    id: Number,
    auditStatus: Number, 
    agentWithdrawalInfoList: Object,
    i18nPrefix: String
  },
  data() {
    return {
      way: Object.keys(this.agentWithdrawalInfoList)?.[0],
      agentWithdrawal: {
        id: this.id,
        auditStatus: undefined,
        agentWithdrawalPaymentId: this.agentWithdrawalInfoList?.[Object.keys(this.agentWithdrawalInfoList)?.[0]]?.[0]?.id,
      },
      depositList: []
    };
  },
  mounted() {
    const set = new Set();
    // this.depositList = this.agentWithdrawalInfoList
    //   .filter(el =>
    //     this.gatewayList
    //       .map(data => {
    //         return data.gateway;
    //       })
    //       .includes(el.gateway)
    //   )
    //   .filter(item => (!set.has(item.gateway) ? set.add(item.gateway) : false));
  },
  watch: {
    way: function(val) {
      this.agentWithdrawal.agentWithdrawalPaymentId = this.agentWithdrawalInfoList?.[val]?.[0]?.id;
    }
  },
  methods: {
    audit(auditStatus) {
      if (auditStatus) this.agentWithdrawal.auditStatus = auditStatus;
      if (this.auditStatus) {
        this.$emit("editDrawal", this.agentWithdrawal);
        return;
      }
      this.$emit("auditDrawal", this.agentWithdrawal);
    }
  }
};
</script>

<style lang="scss" scoped>
.require {
  border-color: red;
}
main {
  .input-block {
    .member-info,
    .contact-info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .member-info {
      label {
        width: 30%;
      }
    }
    .contact-info {
      label {
        width: 30%;
      }
    }
  }
}
.dialog-container {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .input-block {
    label {
      width: 30% !important;
    }
    .more-info {
      width: 100% !important;
    }
  }
  .advance-block {
    flex-direction: column;
    align-items: center;
    label {
      margin: 5px auto;
    }
    button {
      margin: 5px auto;
    }
  }
}
</style>