import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import _ from "lodash";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignInAlt, faExclamationTriangle, faWallet, faHandshakeAltSlash, faImages,
         faEdit, faEnvelope, faPlusCircle, faMinusCircle, faCopy, faPlus, faSortAmountDown, faSortAmountUp,
         faThList, faBell, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CKEditor from 'ckeditor4-vue';
import DatePicker from 'vue2-datepicker';
import "@/styles/datePicker.css";
import global_ from '@/layout/components/Global'
import IdleVue from 'idle-vue';
import i18n from './lang/lang';
import unleashPlugins from './plugins/unleash';
// import VueNativeSock from "vue-native-websocket";

// Vue.use(VueNativeSock, "ws://localhost:10002", {
//   websocket,
//   format: "json",
//   reconnection: true,
//   reconnectionAttempts: 5,
//   reconnectionDelay: 3000,
// });

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 8 * 60 * 60 * 1000,
  startAtIdle: false
});

Vue.config.productionTip = false;
Vue.use( CKEditor );
Vue.use( DatePicker );
Vue.use( unleashPlugins );
library.add(faSignInAlt, faExclamationTriangle, faWallet, faHandshakeAltSlash, faImages, 
            faEdit, faEnvelope, faPlusCircle, faMinusCircle, faCopy, faPlus, faSortAmountDown, faSortAmountUp,
            faThList, faBell, faDownload);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.prototype.GLOBAL = global_;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');