<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t('Permission.' + $route.meta.permissionName) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(62701)">
            {{ $t("default.create") + " " + $t('Permission.' + $route.meta.permissionName) }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.store") }}
            <select v-model="searchForm.name">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option v-for="(list, index) of CvsNameList" :key="index" :value="list">{{ $t(list) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.code") }}
            <input type="text" v-model="searchForm.code">
          </label>
          <label>
            {{ $t("default.storeName") }}
            <input type="text" v-model="searchForm.storeName">
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.store") }}</th>
              <th>{{ $t("default.city") }}</th>
              <th>{{ $t("default.district") }}</th>
              <th>{{ $t("default.code") }}</th>
              <th>{{ $t("default.storeName") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list of allData" :key="list.id">
              <td style="width: 200px">
                <button class="normal-btn" @click="handleDialog({ list: list }, 'Edit')"
                  :disabled="!getUserinfo.permissions.includes(62702)">
                  {{ $t("default.edit") }}
                </button>
                <button class="delete-btn" @click="handleDelete(list.id)"
                  :disabled="!getUserinfo.permissions.includes(62703)">
                  {{ $t("default.delete") }}
                </button>
              </td>
              <td>{{ list.name }}</td>
              <td>{{ list.city }}</td>
              <td>{{ list.district }}</td>
              <td>{{ list.code }}</td>
              <td>{{ list.storeName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" :CvsNameList="CvsNameList" :i18nPrefix="i18nPrefix" v-bind="dialogData"
        @toggle="currDialog = undefined" @create="create" @edit="edit"></component>
    </transition>

    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Confirm v-if="getShowConfirm" @toggle="TOGGLE_CONFIRM" @delete="deleteCvs" />
    </transition>
  </div>
</template>

<script>
import {
  CvsList,
  createCvs,
  editCvs,
  deleteCvs,
} from "@/api/features/CvsSetting";
import { getGetCvsName } from "@/api/selectMenu";
import { searchModel } from "./model";
import Confirm from "@/layout/components/Confirm";
import { mapGetters, mapMutations } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { checkData } from "@/utils/checkData";

export default {
  name: "CvsSetting",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      CvsNameList: [],
      deleteData: undefined,
      dataList: [],
      i18nPrefix: "features.bank."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    serverPagination,
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo"]),
  },
  created() {
    getGetCvsName().then(res => this.CvsNameList = res.data)
    this.search();
  },
  watch: {
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    search(page = false) {
      this.closeDialog();
      this.RESET_CONFIRM();
      this.deleteData = undefined;

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      CvsList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    handleDelete(id) {
      this.TOGGLE_CONFIRM();
      this.deleteData = id;
    },
    deleteCvs() {
      deleteCvs({ id: this.deleteData }).then((res) => {
        if (res) this.search();
      });
    },
    create(data) {
      if (checkData(data, true)) {
        createCvs(data).then((res) => {
          if (res) this.search();
        });
      }
    },
    edit(data) {
      if (checkData(data, true)) {
        editCvs(data).then((res) => {
          if (res) this.search();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  .input-block {
    label {
      width: 50%;
    }
  }
}
</style>