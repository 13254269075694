<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(60501)
            "
          >{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}cvsName`) }}</th>
                <th>{{ $t("default.status") }}</th>
                <th>{{ $t("default.remark") }}</th>
                <th>{{ $t("default.editor") }}</th>
                <th>{{ $t("default.updateDate") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button
                    class="table-btn main-style-btn"
                    @click="handleDialog({ list: list }, 'Edit')"
                    :disabled="
                      !getUserinfo.permissions.includes(60502)
                    "
                  >{{ $t("default.edit") }}</button>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    v-show="list.imageUrl"
                    @click="handleDialog({image: list.imageUrl + v}, 'Photo')"
                    :title="list.imageUrl"
                  >QR Code</span>
                </td>
                <td>{{ list.cvsName }}</td>
                <td>{{ list.status ? $t("default.active") : $t("default.inactive") }}</td>
                <td>{{ list.description }}</td>
                <td>{{ list.updateAccount }}</td>
                <td>{{ list.updateTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  paymentCVSList,
  createPaymentCVS,
  editPaymentCVS
} from "@/api/features/paymentCVS";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Photo from "@/layout/components/photo";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from "vuex";

export default {
  name: "PaymentCVS",
  data() {
    return {
      v: "?v=" + new Date().getTime(),
      i18nPrefix: "features.paymentCVS."
    };
  },
  components: {
    Create,
    Edit,
    Photo,
    Pagination
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      paymentCVSList({}).then(res => {
        this.allData = res.data;
        this.RESET_DIALOG();
        this.dialogData = undefined;
      });
    },
    create(data) {
      createPaymentCVS(data).then(res => {
        if (res) this.getList();
      });
    },
    edit(data) {
      editPaymentCVS(data).then(res => {
        if (res) this.getList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  color: blue;
  cursor: pointer;
  font-weight: bold;
}
</style>