<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}table.paymentName`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.bank`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.preTradeBalance`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.fee`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.amount`) }}</th>
        <th>{{ $t("default.status") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ atmForm.createTime }}</td>
        <td>{{ atmForm.memberAccount }}</td>
        <td>{{ atmForm.paymentName }}</td>
        <td>{{ $t(atmForm.gateway) }}</td>
        <td>{{ atmForm.originalAmount }}</td>
        <td>{{ atmForm.feeRate }}</td>
        <td>{{ atmForm.amount }}</td>
        <td>{{ $t(atmForm.paymentStatus) }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { atmModel } from "../model";
import { mapGetters } from "vuex";

export default {
  name: "atmForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      atmForm: _.cloneDeep(atmModel),
    };
  },
  watch: {
    detailsData() {
      this.atmForm = {
        id: this.detailsData.id,
        createTime: this.detailsData.createTime,
        memberAccount: this.detailsData.memberAccount,
        paymentName: this.detailsData.paymentName,
        gateway: this.detailsData.gateway === 1 
          ? `${this.i18nPrefix}table.atm` : `${this.i18nPrefix}table.card`,
        originalAmount: this.digital.format(this.detailsData.originalAmount),
        feeRate: this.detailsData.feeRate,
        amount: this.digital.format(this.detailsData.amount),
        paymentStatus: this.getPaymentStatusList.find(
          (data) => data.id === this.detailsData.paymentStatus
        )?.name,
      };
    },
  },
  computed: {
    ...mapGetters(["getPaymentStatusList"]),
  },
};
</script>
<style lang="scss" scoped>
</style>