import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function emergencyMaintainList() {
    return request({
        url: "emergencyMaintain/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createEmergencymaintain(data) {
    return request({
        url: "emergencymaintain/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editEmergencymaintain(data) {
    return request({
        url: "emergencymaintain/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteEmergencymaintain(data) {
    return request({
        url: "emergencymaintain/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function gameList() {
    return request({
        url: "emergencyMaintain/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}