import Vue from "vue";
import { UnleashClient } from 'unleash-proxy-client';

const token = process.env.VUE_APP_BASE === "development" ? 
  "default:development.f757cba0ccb2c45a804c2fc20faa240101bc3dcb786ad54baedd6a7e" :
  "default:production.5f14979dd94fda8159f8d716ec93062f3ee7a1e61ae134b54ed9cdaa";

const appName = process.env.VUE_APP_BASE === "development" ? 
  "web-client-dev" : 
  "web-client-prod";
  
var unleash = new UnleashClient({
  url: 'https://unleash-server.t9platform-prs.com/api/frontend',
  clientKey: token,
  appName: appName
});

unleash.start();

let unleashPlugins = {};

unleashPlugins.install = function(Vue, options) {
  Vue.prototype.unleash = unleash
}
// ex:  unleash.isEnabled('key')
export default unleashPlugins