var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "bookmark-box" }, [
        _c(
          "div",
          { staticClass: "bookmark-view" },
          _vm._l(_vm.bookmarkColumn, function (list) {
            return _c(
              "div",
              {
                key: list.id,
                class: { chooice: _vm.bookmarkNum == list.id },
                on: {
                  click: function ($event) {
                    return _vm.bookmark(list.id)
                  },
                },
              },
              [_vm._v(_vm._s(list.name))]
            )
          }),
          0
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "container-view" }, [
          _vm.bookmarkNum == 4 || _vm.bookmarkNum == 5
            ? _c(
                "button",
                {
                  staticClass: "search-btn main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.search")))]
              )
            : _vm._e(),
          _c("div", { staticClass: "header" }, [
            _c("h5", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}luckydraw`)))]),
            _c("div", { staticClass: "float-right" }, [
              _vm.bookmarkNum == 1
                ? _c(
                    "button",
                    {
                      staticClass: "create-btn main-style-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog(undefined, "Edit")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.create") + " ") +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}template`))
                      ),
                    ]
                  )
                : _vm.bookmarkNum == 3
                ? _c(
                    "button",
                    {
                      staticClass: "create-btn main-style-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog(undefined, "Style")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.create") + " ") +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}style`))
                      ),
                    ]
                  )
                : _vm.bookmarkNum == 4
                ? _c(
                    "button",
                    {
                      staticClass: "create-btn main-style-btn",
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog(undefined, "Ticket")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}ticket`)))]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "main" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bookmarkNum == 4 || _vm.bookmarkNum == 5,
                    expression: "bookmarkNum == 4 || bookmarkNum == 5",
                  },
                ],
                staticClass: "input-block",
              },
              [
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bookmarkNum == 4,
                        expression: "bookmarkNum == 4",
                      },
                    ],
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchForm.memberAccount,
                          expression: "searchForm.memberAccount",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.searchForm.memberAccount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchForm,
                            "memberAccount",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bookmarkNum == 4,
                        expression: "bookmarkNum == 4",
                      },
                    ],
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("default.startTime")) + " "),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD",
                        "value-type": "format",
                        type: "date",
                      },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bookmarkNum == 4,
                        expression: "bookmarkNum == 4",
                      },
                    ],
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("default.endTime")) + " "),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD",
                        "value-type": "format",
                        type: "date",
                      },
                      model: {
                        value: _vm.endTime,
                        callback: function ($$v) {
                          _vm.endTime = $$v
                        },
                        expression: "endTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("label", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bookmarkNum == 4,
                      expression: "bookmarkNum == 4",
                    },
                  ],
                }),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}date`)) + " "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchForm.id,
                          expression: "searchForm.id",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchForm,
                            "id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.bookmarkNum == 4,
                              expression: "bookmarkNum == 4",
                            },
                          ],
                          domProps: { value: undefined },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.none")))]
                      ),
                      _vm._l(_vm.periodList, function (d) {
                        return _c(
                          "option",
                          { key: d.id, domProps: { value: d.id } },
                          [_vm._v(_vm._s(d.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm.bookmarkNum == 4 || _vm.bookmarkNum == 5
              ? _c("div", { staticClass: "advance-block" }, [
                  _c(
                    "button",
                    {
                      staticClass: "search-pc-btn main-style-btn px-5",
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("default.search")) + " ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container-view" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bookmarkNum == 5,
                    expression: "bookmarkNum == 5",
                  },
                ],
                staticClass: "total-block-v2 row-4",
              },
              [
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalRecipientCount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalRecipientCount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalRedemptionCount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalRedemptionCount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalPayoutAmount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalPayoutAmount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalRegisterCount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalRegisterCount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalValidBetAmount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalValidBetAmount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalMemberDepositCount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalMemberDepositCount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalDepositCount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalDepositCount)),
                  ]),
                ]),
                _c("div", { staticClass: "total-block-v2-item" }, [
                  _c("div", { staticClass: "total-block-v2-item-left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}total.totalDepositAmount`)
                      ) + ":"
                    ),
                  ]),
                  _c("div", { staticClass: "total-block-v2-item-right" }, [
                    _vm._v(_vm._s(_vm.total.totalDepositAmount)),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "table-block" },
              [
                _c("TreeTable", {
                  ref: "treeTable",
                  attrs: { list: _vm.allData, column: _vm.tableColumn },
                  scopedSlots: _vm._u([
                    {
                      key: "column_CampaignSetting",
                      fn: function (props) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "normal-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    { editData: props },
                                    "Edit"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.edit")))]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "delete-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeleteSetting(props.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.delete")))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_CampaignLuckyDraw",
                      fn: function (props) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "normal-btn",
                              attrs: {
                                disabled:
                                  !_vm.getUserinfo.permissions.includes(170102),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    {
                                      isCampaign: true,
                                      detail:
                                        props.status == 1 ? undefined : true,
                                      editData: props,
                                    },
                                    "Edit"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  props.status == 1 &&
                                    new Date(
                                      props.endTime +
                                        _vm.$store.getters.timeZone
                                    ) > _vm.GLOBAL.getNow()
                                    ? _vm.$t("default.edit")
                                    : _vm.$t("default.detail")
                                )
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    props.status !== 3 &&
                                    new Date(
                                      props.endTime +
                                        _vm.$store.getters.timeZone
                                    ) > _vm.GLOBAL.getNow(),
                                  expression:
                                    "props.status !== 3 && new Date(props.endTime +  $store.getters.timeZone) >  GLOBAL.getNow()",
                                },
                              ],
                              class: {
                                "btn-orange": props.status == 1,
                                "delete-btn": props.status == 2,
                              },
                              on: {
                                click: function ($event) {
                                  props.status == 1
                                    ? _vm.release(props, 2)
                                    : _vm.handleDeleteLuckyDraw(props)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  props.status == 1
                                    ? _vm.$t(`${_vm.i18nPrefix}release`)
                                    : _vm.$t("default.inactive")
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_CampaignLuckyDrawStyle",
                      fn: function (props) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "normal-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    { editData: props },
                                    "Style"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.edit")))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_date",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(
                              `${props.id}- ${props.startTime}-${props.endTime}`
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_status",
                      fn: function (props) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.bookmarkNum == 2
                                  ? new Date(
                                      props.endTime +
                                        _vm.$store.getters.timeZone
                                    ) > _vm.GLOBAL.getNow()
                                    ? _vm.GLOBAL.i18nCheckName(
                                        _vm.luckyDrawStatus.find(
                                          (d) => d.id == props.status
                                        )
                                      )
                                    : _vm.GLOBAL.i18nCheckName(
                                        _vm.getStatusList[1]
                                      )
                                  : _vm.GLOBAL.i18nCheckName(
                                      _vm.getStatusList.find(
                                        (d) => d.id == props.status
                                      )
                                    )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_campaignLuckyDrawListId",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.GLOBAL.i18nCheckName(
                                _vm.periodList.find(
                                  (d) => d.id == props.campaignLuckyDrawListId
                                )
                              )
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_updateUser",
                      fn: function (props) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.bookmarkNum !== 2 ? props.updateUser : ""
                              ) +
                              " "
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.bookmarkNum == 2,
                                  expression: "bookmarkNum == 2",
                                },
                              ],
                              staticClass: "table-btn main-style-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.getProcess(props.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.processAccount")))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_imageUrl",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: props.imageUrl,
                                  expression: "props.imageUrl",
                                },
                              ],
                              staticClass: "example-tag",
                              attrs: { title: props.imageUrl },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDialog(
                                    { image: props.imageUrl + _vm.v },
                                    "Photo"
                                  )
                                },
                              },
                            },
                            [_vm._v("image")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm.bookmarkNum == 4 || _vm.bookmarkNum == 5
              ? _c("serverPagination", {
                  attrs: { page: _vm.page },
                  on: {
                    ServerPageUtils: _vm.search,
                    changeTake: _vm.changeTake,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  i18nPrefix: _vm.i18nPrefix,
                  starList: _vm.getStars,
                  tagList: _vm.tagList,
                  agentList: _vm.getAgentList,
                  periodType: _vm.periodType,
                  prizeType: _vm.prizeType,
                  periodList: _vm.periodList,
                },
                on: {
                  stylebookmark: function ($event) {
                    _vm.bookmarkNum = 3
                  },
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  search: _vm.search,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _vm.alertMsg
        ? _c("Alert", {
            attrs: { message: _vm.alertMsg },
            on: {
              toggle: function ($event) {
                _vm.alertMsg = undefined
              },
              doIt: _vm.deleteFunction,
            },
          })
        : _vm._e(),
      _vm.processData
        ? _c("Process", {
            attrs: {
              processTitle: _vm.processTitle,
              processData: _vm.processData,
            },
            on: {
              toggle: function ($event) {
                _vm.processData = undefined
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "oldValue_Status",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.luckyDrawStatus.find(
                                (data) => props.Status === data.id
                              )
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "newValue_Status",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.luckyDrawStatus.find(
                                (data) => props.Status === data.id
                              )
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3075701618
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }