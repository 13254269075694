var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t("default.create") +
                    " " +
                    _vm.$t("title." + _vm.$route.meta.title)
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.name,
                      expression: "createModel.name",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}description`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.description,
                      expression: "createModel.description",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "permission-block" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.permission`))),
              ]),
              _c(
                "div",
                { staticClass: "permission-box" },
                _vm._l(_vm.getPermissionsTitle, function (list, index) {
                  return _c(
                    "div",
                    { key: list.id, staticClass: "permission-list" },
                    [
                      _c("ul", [
                        _c("li", [
                          _c("label", { staticClass: "checkBox-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.createModel.permissions,
                                  expression: "createModel.permissions",
                                },
                              ],
                              attrs: {
                                name: "checkBox",
                                id: "main" + list.name,
                                type: "checkbox",
                              },
                              domProps: {
                                value: list.id,
                                checked: Array.isArray(
                                  _vm.createModel.permissions
                                )
                                  ? _vm._i(
                                      _vm.createModel.permissions,
                                      list.id
                                    ) > -1
                                  : _vm.createModel.permissions,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkAll("main" + list.name)
                                },
                                change: function ($event) {
                                  var $$a = _vm.createModel.permissions,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = list.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.createModel,
                                          "permissions",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.createModel,
                                          "permissions",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.createModel,
                                      "permissions",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Permission." + list.name)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.firstData[index],
                              function (firstTree, firstIndex) {
                                return [
                                  _c("li", { key: firstTree.id }, [
                                    _c(
                                      "label",
                                      { staticClass: "checkBox-label" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.createModel.permissions,
                                              expression:
                                                "createModel.permissions",
                                            },
                                          ],
                                          class: [
                                            "main" + list.name,
                                            "parent" + list.name,
                                          ],
                                          attrs: {
                                            name: "checkBox",
                                            id: firstTree.name,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            value: firstTree.id,
                                            checked: Array.isArray(
                                              _vm.createModel.permissions
                                            )
                                              ? _vm._i(
                                                  _vm.createModel.permissions,
                                                  firstTree.id
                                                ) > -1
                                              : _vm.createModel.permissions,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkAll(
                                                firstTree.name,
                                                "main" + list.name
                                              )
                                            },
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.createModel.permissions,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = firstTree.id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.createModel,
                                                      "permissions",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.createModel,
                                                      "permissions",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.createModel,
                                                  "permissions",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "Permission." + firstTree.name
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.secData[index][firstIndex],
                                      function (secTree, secIndex) {
                                        return _c(
                                          "li",
                                          { key: _vm.secData.id },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    secIndex ===
                                                      _vm.secData[index][
                                                        firstIndex
                                                      ].length -
                                                        1
                                                      ? "└"
                                                      : "├"
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "checkBox-label",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.createModel
                                                            .permissions,
                                                        expression:
                                                          "createModel.permissions",
                                                      },
                                                    ],
                                                    class: [
                                                      "main" + list.name,
                                                      firstTree.name,
                                                      "parent" + firstTree.name,
                                                    ],
                                                    attrs: {
                                                      name: "checkBox",
                                                      type: "checkbox",
                                                    },
                                                    domProps: {
                                                      value: secTree.id,
                                                      checked: Array.isArray(
                                                        _vm.createModel
                                                          .permissions
                                                      )
                                                        ? _vm._i(
                                                            _vm.createModel
                                                              .permissions,
                                                            secTree.id
                                                          ) > -1
                                                        : _vm.createModel
                                                            .permissions,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkAll(
                                                          false,
                                                          firstTree.name,
                                                          "main" + list.name
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.createModel
                                                              .permissions,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = secTree.id,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.createModel,
                                                                "permissions",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.createModel,
                                                                "permissions",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.createModel,
                                                            "permissions",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Permission." +
                                                            secTree.name
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    mousedown: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("default.cancel")) + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }