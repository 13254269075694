var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "divider-v2" }, [
      _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
        _vm._v(_vm._s(_vm.$t(`default.rule`))),
      ]),
    ]),
    _vm.selectDiscountType !== ""
      ? _c("div", { staticClass: "form-box" }, [
          _c("div", { staticClass: "form-box-half form-column" }, [
            _c("div", { staticClass: "form-box" }, [
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.apply`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.applyType,
                        expression: "formModel.applyType",
                      },
                    ],
                    attrs: { disabled: _vm.selectDiscountType != "LoseRebate" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formModel,
                          "applyType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.applyType, function (item) {
                    return _c(
                      "option",
                      { key: item.key, domProps: { value: item.value } },
                      [_vm._v(_vm._s(_vm.$t(item.name)))]
                    )
                  }),
                  0
                ),
                _c("span", {
                  ref: "rulesapplyType",
                  staticClass: "form-rules",
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.dispatch`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.dispatchType,
                        expression: "formModel.dispatchType",
                      },
                    ],
                    attrs: { disabled: _vm.selectDiscountType != "LoseRebate" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formModel,
                          "dispatchType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.dispatchType, function (item) {
                    return _c(
                      "option",
                      { key: item.key, domProps: { value: item.value } },
                      [_vm._v(_vm._s(_vm.$t(item.name)))]
                    )
                  }),
                  0
                ),
                _c("span", {
                  ref: "rulesdispatchType",
                  staticClass: "form-rules",
                }),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.selectDiscountType == "LoseRebate" &&
                    _vm.formModel.dispatchType == 1,
                  expression:
                    "selectDiscountType == 'LoseRebate' && formModel.dispatchType == 1",
                },
              ],
              staticClass: "form-box-half form-column",
            },
            [
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.period`)) + " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.calculationCycle,
                          expression: "formModel.calculationCycle",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formModel,
                            "calculationCycle",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.GLOBAL.settlement, function (item) {
                      return _c(
                        "option",
                        { key: item.id, domProps: { value: item.id } },
                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "label",
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`))),
                    ]),
                    _c("date-picker", {
                      attrs: {
                        format: "HH",
                        "value-type": "format",
                        type: "time",
                      },
                      model: {
                        value: _vm.formModel.payTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "payTime", $$v)
                        },
                        expression: "formModel.payTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "form-box star-box" },
      _vm._l(_vm.rebateData, function (rebate) {
        return _c(
          "div",
          { key: rebate.star, staticClass: "divider-v2-area" },
          [
            _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      _vm.memberStar.list.find((e) => e.star == rebate.star)
                        .name
                    )
                  ) +
                  " "
              ),
              _c(
                "span",
                {
                  staticClass: "copy",
                  on: {
                    click: function ($event) {
                      return _vm.copy(rebate.star)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.applied`)))]
              ),
            ]),
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.bounsMultiple`)) +
                  " "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: rebate.bounsMultiple,
                    expression: "rebate.bounsMultiple",
                  },
                ],
                attrs: { type: "number", min: "0" },
                domProps: { value: rebate.bounsMultiple },
                on: {
                  keyup: function ($event) {
                    rebate.bounsMultiple = _vm.GLOBAL.checkValue(
                      rebate.bounsMultiple,
                      2,
                      0,
                      100
                    )
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(rebate, "bounsMultiple", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._l(rebate.gameCategories, function (gamesCategory) {
              return _c("label", { key: gamesCategory.id }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        _vm.gamesCategorys.find((e) => e.id == gamesCategory.id)
                          .name
                      ) + " ％"
                    ) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: gamesCategory.rate,
                      expression: "gamesCategory.rate",
                    },
                  ],
                  attrs: { type: "number", min: "0" },
                  domProps: { value: gamesCategory.rate },
                  on: {
                    keyup: function ($event) {
                      gamesCategory.rate = _vm.GLOBAL.checkValue(
                        gamesCategory.rate,
                        2,
                        0,
                        100
                      )
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(gamesCategory, "rate", $event.target.value)
                    },
                  },
                }),
              ])
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }