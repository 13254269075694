<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.setting") }}</h4>
        </div>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("default.status") }}</th>
                <th>{{ $t(`${i18nPrefix}dialog.name`) }}</th>
                <th>{{ $t(`${i18nPrefix}dialog.code`) }}</th>
                <th>{{ $t(`${i18nPrefix}dialog.value`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <button class="create-btn main-style-btn" @click="create()">{{ $t("default.create") }}</button>
                </td>
                <td>
                  <div
                    class="slide-block"
                    style="margin: auto"
                    @click="createModel.status = !createModel.status"
                    :class="{ 'slide-block-on': createModel.status }"
                  >
                    <div class="slide" :class="{ 'slide-on': createModel.status }">
                      <label for="status" style="display: none"></label>
                      <input
                        type="checkbox"
                        id="status"
                        v-model="createModel.status"
                        style="display: none"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <input type="text" v-model="createModel.name" />
                </td>
                <td>
                  <input type="text" v-model="createModel.code" />
                </td>
                <td>
                  <input type="text" v-model="createModel.value" />
                </td>
              </tr>
              <tr v-for="(list, index) of tableList" :key="index">
                <td v-if="editStatus === list.id">
                  <button class="normal-btn" @click="edit(list)">{{ $t("default.edit") }}</button>
                  <button
                    class="delete-btn"
                    @click="handleDelete(list.id)"
                  >{{ $t("default.delete") }}</button>
                </td>
                <td v-else>
                  <button class="btn-blue" @click="editStatus=list.id">{{ $t("default.setting") }}</button>
                </td>
                <td>
                  <div
                    class="slide-block"
                    style="margin: auto"
                    :disabled="editStatus !== list.id"
                    @click="list.status = (editStatus !== list.id) ? list.status : !list.status"
                    :class="{ 'slide-block-on': list.status }"
                  >
                    <div class="slide" :class="{ 'slide-on': list.status }">
                      <label style="display: none"></label>
                      <input
                        type="checkbox"
                        v-model="list.status"
                        style="display: none"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <input type="text" v-model="list.name" :disabled="editStatus !== list.id" />
                </td>
                <td>
                  <input type="text" v-model="list.code" :disabled="editStatus !== list.id" />
                </td>
                <td>
                  <input type="text" v-model="list.value" :disabled="editStatus !== list.id" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="deleteCompanyDepositSettings"
      />
    </transition>
  </div>
</template>

<script>
import {
  getCompanyDepositSettingsArgList,
  createCompanyDepositSettingsArg,
  editCompanyDepositSettingsArg,
  deleteCompanyDepositSettingsArg
} from "@/api/features/companyDepositSettings";
import Alert from "@/layout/components/Alert";
import handlePageUtils from "@/layout/mixins/handlePageUtils";

export default {
  name: "Bank",
  props: ["id", "i18nPrefix"],
  mixins: [handlePageUtils],
  data() {
    return {
      createModel: {
        companyDepositSettingsId: this.id,
        name: undefined,
        code: undefined,
        value: undefined,
        status: true
      },
      editStatus: undefined,
      alertMsg: "",
      deleteData: {}
    };
  },
  components: {
    Alert
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getCompanyDepositSettingsArgList({
        companyDepositSettingsId: this.id
      }).then(res => {
        this.allData = res.data.companyDepositSettingsArgs;
      });
    },
    create() {
      createCompanyDepositSettingsArg(this.createModel).then(res => {
        if (res) {
          this.getList();
          this.createModel.name = undefined;
          this.createModel.code = undefined;
          this.createModel.value = undefined;
        }
      });
    },
    edit(list) {
      this.editStatus = undefined;
      editCompanyDepositSettingsArg(list).then(res => {
        if (res) this.getList();
      });
    },
    handleDelete(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteCompanyDepositSettings() {
      this.alertMsg = "";
      deleteCompanyDepositSettingsArg(this.deleteData).then(res => {
        if (res) this.getList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  flex-wrap: nowrap !important;
  .dialog-container {
    width: 50%;
    label {
      width: 40% !important;
    }
  }
}
</style>