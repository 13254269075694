import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function discountCreate(data) {
    return request({
        url: "/memberdiscount/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function discountConfirm(data) {
    return request({
        url: "/MemberDiscount/Confirm",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function discountList(data) {
    return request({
        url: "/MemberDiscount/query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function discountUpdate(data) {
    return request({
        url: "/memberdiscount/update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function selectMenu() {
    return request({
        url: "/memberDiscount/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function calculateBonus(data) {
    return request({
        url: "/memberdiscount/calculateBonus",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTotal(data) {
    return request({
        url: "/MemberDiscount/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getExcel(data) {
    return request({
        url: "/MemberDiscount/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function getMemberDiscountProcess(data) {
    return request({
        url: "/MemberDiscount/Process",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}