<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(62201)">{{ $t("default.create") }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
          </label>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}gateway`) }}</th>
              <th>Icon</th>
              <th>RedirectType</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list of tableList" :key="list.id">
              <td class="small-td">
                <div class="btn-td">
                  <button class="table-btn main-style-btn" @click="handleDialog({ list: list }, 'Edit')"
                    :disabled="!getUserinfo.permissions.includes(62202)">
                    {{ $t("default.edit") }}
                  </button>
                </div>
              </td>
              <td>{{ list.name }}</td>
              <td class="url-td">
                <span v-show="list.iconWebUrl" @click="handleDialog({ image: list.iconWebUrl + v }, 'Photo')"
                  :title="list.imageUrl">Web</span>
                <span v-show="list.iconAppUrl" @click="handleDialog({ image: list.iconAppUrl + v }, 'Photo')"
                  :title="list.imageUrl">App</span>
              </td>
              <td>{{
                GLOBAL.i18nCheckName(redirectType.find(
                  (data) => list.redirectType === data.id
                ))
              }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :languageType="languageType" :redirectType="redirectType" :i18nPrefix="i18nPrefix"
        v-bind="dialogData" @toggle="currDialog = undefined" @edit="edit" @create="create"></component>
    </transition>
  </div>
</template>

<script>
import {
  selectMenu,
  getCompanyDepositInfoSettingsList,
  createCompanyDepositInfoSettings,
  editCompanyDepositInfoSettings
} from "@/api/features/companyDepositInfoSettings";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from "vuex";
import Photo from "@/layout/components/photo";

export default {
  name: "CompanyDepositSettingsV2",
  data() {
    return {
      languageType: [],
      redirectType: [],
      v: "?v=" + new Date().getTime(),
      i18nPrefix: "features.companyDepositSettings."
    };
  },
  components: {
    Create,
    Edit,
    Photo,
    Pagination
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo", "getStatusListAddDefault"])
  },
  async created() {
    await this.getList();
    await selectMenu().then(res => {
      this.languageType = res.data.languageType.map(data => {
        return { id: data.id, name: data.name };
      });
      this.redirectType = res.data.redirectType.map(data => {
        return { id: data.id, name: "DepositRedirectType." + data.name };
      });
    });
  },
  methods: {
    getList() {
      this.RESET_DIALOG();
      getCompanyDepositInfoSettingsList({}).then(res => {
        this.allData = res.data;
      });
    },
    create(data) {
      createCompanyDepositInfoSettings(data).then(res => {
        if (res) this.getList();
      });
    },
    edit(data) {
      editCompanyDepositInfoSettings(data).then(res => {
        if (res) this.getList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.url-td {
  width: 20%;
  white-space: unset !important;

  span {
    margin: auto 5px;
  }
}
</style>