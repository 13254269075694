import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getPayment(data) {
  return request({
    url: "/DataAnalysis/payment",
    method: "post",
    data,
    headers: { "Authorization": `token ${getToken()}` }
  });
}

export function getRegister(data) {
  return request({
    url: "/DataAnalysis/register",
    method: "post",
    data,
    headers: { "Authorization": `token ${getToken()}` }
  });
}

export function getGame(data) {
  return request({
    url: "/DataAnalysis/game",
    method: "post",
    data,
    headers: { "Authorization": `token ${getToken()}` }
  });
}