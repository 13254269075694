<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}create`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="contact-info">
              <label>
                <div>
                  {{ $t("default.member") }}
                  <button
                    class="normal-btn"
                    @click.self="getMemberBankInfoList"
                  >
                    {{ $t(`${i18nPrefix}dialog.checkAccount`) }}
                  </button>
                </div>
                <input type="text" v-model="account" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}bankName`) }}
                <select
                  v-model="createModel.memberBankInfoId"
                  :disabled="!createModel.id"
                >
                  <option
                    v-for="list of bankInfoList"
                    :key="list.memberBankInfoId"
                    :value="list.memberBankInfoId"
                  >
                    {{ list.bankCode + ' － ' + list.bankName }}
                  </option>
                </select>
              </label>
              <label>
              {{ $t(`${i18nPrefix}wallet`) }}
              <input type="text" v-model="amount" :disabled="true" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}bankAccountName`) }}
                <input type="text" v-model="bankAccountName" :disabled="true" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}bankAccountNumber`) }}
                <input
                  type="text"
                  v-model="bankAccountNumber"
                  :disabled="true"
                />
              </label>
              <label>
                {{ $t("default.amount") }}
                <input type="text" v-model="createModel.amount" />
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click.self="create">{{ $t(`${i18nPrefix}create`) }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { getMemberBankInfoList } from "@/api/member/withdraw.js";
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: {
    i18nPrefix: String
  },
  data() {
    return {
      createModel: {
        id: undefined,
        memberBankInfoId: undefined,
        amount: undefined,
      },
      bankAccountName: undefined,
      bankAccountNumber: undefined,
      account: undefined,
      bankInfoList: [],
      amount: undefined,
    };
  },
  watch: {
    "createModel.memberBankInfoId": function (val) {
      let bankData = this.bankInfoList.find(
        (data) => data.memberBankInfoId === val
      );
      this.bankAccountName = bankData.bankAccountName;
      this.bankAccountNumber = bankData.bankAccountNumber;
    },
    "createModel.amount": function (val, oldVal) {
      if (val[0] === "0" || /^[0-9]*$/.test(val) === false) {
        this.createModel.amount = oldVal;
        Message.error(this.$t("default.checkNum"), 1000);
      }
    }
  },
  methods: {
    create() {
      // if (this.createModel.amount < 100) {
      //   Message.error("提款最少一百", 1000);
      //   return;
      // }
      this.createModel.amount = this.createModel.amount * 1;
      this.$emit("create", this.createModel);
    },
    getMemberBankInfoList() {
      getMemberBankInfoList({ account: this.account }).then((res) => {
        if(!res) return
        this.createModel.id = res.data.memberId;
        this.bankInfoList = res.data.bankInfoList;
        this.createModel.memberBankInfoId =
        this.bankInfoList[0].memberBankInfoId;
        this.amount = new Intl.NumberFormat().format(res.data.balance);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.require {
  border-color: red;
}
main {
  .input-block {
    .member-info,
    .contact-info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .member-info {
      label {
        width: 30%;
      }
    }
    .contact-info {
      label {
        width: 30%;
      }
    }
  }
}
.dialog-container {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .input-block {
    label {
      width: 30% !important;
    }
    .more-info {
      width: 100% !important;
    }
  }
  .advance-block {
    flex-direction: column;
    align-items: center;
    label {
      margin: 5px auto;
    }
    button {
      margin: 5px auto;
    }
  }
}
</style>