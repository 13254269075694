<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="create-group dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") }}{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}groupName`) }}
              <input type="text" v-model="createModel.name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}stage`) }}
              <select v-model="createModel.starLevelSettingId">
                <option
                  v-for="list of starLevelList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.star }} {{ $t("default.star") }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}active`) }}
              <select v-model="createModel.status">
                <option value="1">{{ $t("default.active") }}</option>
                <option value="2">{{ $t("default.inactive") }}</option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { validAllInput } from "@/utils/validate";
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ["starLevelList"],
  data() {
    return {
      createModel: {
        name: undefined,
        groupType: 1,
        status: 1,
        starLevelSettingId: this.starLevelList[0]?.id,
      },
      i18nPrefix: "features.group."
    };
  },
  methods: {
    create() {
      return validAllInput(this.createModel)
        ? Message.error("有欄位尚未填妥", 1000)
        : this.$emit("create", {
            name: this.createModel.name,
            groupType: parseInt(this.createModel.groupType),
            status: parseInt(this.createModel.status),
            starLevelSettingId: parseInt(this.createModel.starLevelSettingId),
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-group {
  width: 20%;
  .input-block {
    label {
      width: 100%;
    }
  }
}
</style>