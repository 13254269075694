<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{  $t("default.create") + $t(`${i18nPrefix}accountBank`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="bank-info">
              <label>
                {{ $t("default.bankType") }}
                <select v-model="createModel.bankType">
                  <option v-for="list of getBankTypeList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                </select>
              </label>
              <label></label>
              <div class="multiselectDiv" v-show="createModel.bankType === 1 || createModel.bankType === 2">
              {{  $t(`${i18nPrefix}bank`) }}
                <multiselect
                  v-model="value"
                  label="name"
                  track-by="id"
                  :placeholder="$t(`${i18nPrefix}dialog.bank.choose`)"
                  :custom-label="nameWithLang"
                  :options="getBankList"
                  :allow-empty="false"
                  :preselectFirst="true"
                >
                </multiselect>
              </div>
              <label v-show="createModel.bankType === 1 || createModel.bankType === 2">
                {{ $t("default.bankBranchCode") }}
                <input type="text" v-model="createModel.bankBranchCode" />
              </label>
              <label v-show="createModel.bankType === 1 || createModel.bankType === 2">
                {{ $t(`${i18nPrefix}dialog.bank.bankBranch`) }}
                <input type="text" v-model="createModel.bankBranch" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.bank.bankAccountName`) }}
                <input type="text" v-model="createModel.bankAccountName" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.bank.bankAccountNumber`) }}
                <input type="text" v-model="createModel.bankAccountNumber" />
              </label>
              <label style="text-align: center">
                {{ $t(`${i18nPrefix}dialog.bank.isDefault`) }}
                <div
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': createModel.isDefault, 'disabled': !status }"
                >
                  <div
                    class="slide"
                    @click="status ? toggleSlide : ''"
                    :class="{ 'slide-on': createModel.isDefault }"
                  >
                    <label for="isDefault" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="isDefault"
                      v-model="createModel.isDefault"
                      style="display: none"
                      :disabled="!status"
                    />
                  </div>
                </div>
              </label>
              <label style="text-align: center">
                {{ status ? $t("default.isVerify") : $t("default.noVerify")}}
                <div
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': status }"
                >
                  <div
                    class="slide"
                    @click="toggleSlide"
                    :class="{ 'slide-on': status }"
                  >
                    <label for="verifyStatus" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="verifyStatus"
                      v-model="status"
                      style="display: none"
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toggleStyle } from "@/utils/slideUtils";
import Message from "@/layout/components/Message";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateBank",
  props: [
    "id",
    "i18nPrefix"
  ],
  data() {
    return {
      createModel: {
        memberId: this.id,
        bankId: undefined,
        bankBranchCode: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
        verifyStatus: undefined,
        bankBranch: undefined,
        isDefault: false,
        bankType: 1,
      },
      status: false,
      value: [],
    };
  },
  components: { Multiselect },
  computed: {
    ...mapGetters(["getBankList","getBankTypeList"]),
  },
  watch: {
    // "createModel.bankAccountName": function (val, oldVal) {
    //   if (/^[\u4E00-\u9FA5]+$/.test(val) === false && val !== '') {
    //     this.createModel.bankAccountName = oldVa`l ? oldVal : '';
    //     Message.error("請輸入正確文字", 1000);
    //   }
    // },
    "createModel.bankAccountNumber": function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false) {
        this.createModel.bankAccountNumber = (oldVal ?? '');
        Message.error(this.$t("default.checkNum"), 1000);
      }
    },
    "status": function (val) {
      if (!val) this.createModel.isDefault = false;
    }
  },
  methods: {
    toggleSlide: toggleStyle,
    create() {
      this.createModel.bankId = (this.createModel.bankType === 1 || this.createModel.bankType === 2) ? this.value?.id : 0;
      this.createModel.verifyStatus = this.status ? 1 : 2;
      if (!this.createModel.bankAccountName || !this.createModel.bankAccountNumber) {
        return Message.error(this.$t(`${this.i18nPrefix}dialog.bank.errorMsg`), 2000);
      }
      this.$emit("createBank", this.createModel);
    },
    nameWithLang ({ name, code }) {
      return `${code}－${name}`
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.bank-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width:100%;
  label {
    width: 45%;
    .disabled {
      background-color: gray;
    }
  }
  .multiselectDiv{
    width: 45%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>