<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label class="multiselectDiv">
              {{ $t(`${i18nPrefix}bankName`) }}
              <multiselect
                v-model="value"
                label="name"
                track-by="id"
                :placeholder="$t('default.plzSelect')"
                :custom-label="nameWithLang"
                :options="bankList"
                :allow-empty="false"
                :preselectFirst="true"
              >
              </multiselect>
            </label>
            <label>
              {{ $t(`${i18nPrefix}bankAccountName`) }}
              <input type="text" v-model="createModel.bankAccountName" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bankAccountNumber`) }}
              <input type="text" v-model="createModel.bankAccountNumber" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.status">
                <option v-for="list of getStatusList" :key="list.id" :value="list.id">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.bank`) }}
              <select v-model="createModel.bankGroupId">
                <option v-for="list of bankGroupList" :key="list.id" :value="list.id">
                  {{ list.name }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="create">{{ $t("default.confirm") }}</button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validAllInput } from "@/utils/validate";
import Message from "@/layout/components/Message";
import Multiselect from "vue-multiselect";

export default {
  name: "Create",
  props: ["bankList","bankGroupList","i18nPrefix"],
  computed: {
    ...mapGetters(["getStatusList"])
  },
  data() {
    return {
      createModel: {
        status: undefined,
        bankId: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
        bankGroupId: undefined,
        bankGroupName: undefined
      },
      value: undefined,
      // banksList: [],
    }
  },
  components: { Multiselect },
  created() {
    this.createModel.status = this.getStatusList[0].id;
    this.createModel.bankGroupId = this.bankGroupList[0].id;
  },
  methods: {
    create() {
      this.createModel.bankId = this.value.id;
      this.createModel.bankGroupName = this.bankGroupList.find((data) => {
        return data.id === this.createModel.bankGroupId;
      }).name;
      return validAllInput(this.createModel)
        ? Message.error(this.$t("default.fieldsNotEntered"), 1000)
        : this.$emit("create", this.createModel);
    },
    nameWithLang ({ name, code }) {
      return `${code}－${name}`
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.input-block {
  label {
    width: 40%;
  }
  .multiselectDiv{
    width: 40%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .input-block {
    label {
      width: 50% !important;
    }
    .multiselectDiv {
      width: 100% !important;
    }
  }
}
</style>