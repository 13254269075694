var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        _c("div", { staticClass: "float-right" }, [
          _c(
            "button",
            {
              staticClass: "create-btn main-style-btn",
              attrs: {
                disabled: !_vm.getUserinfo.permissions.includes(140402),
              },
              on: {
                click: function ($event) {
                  return _vm.update()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("default.edit")))]
          ),
        ]),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "container-view gamesCategory" }, [
      _c("label", [
        _vm._v(" " + _vm._s(_vm.$t("default.gamesCategory") + "：") + " "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.category,
                expression: "category",
              },
            ],
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.category = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.getGamesCategorys, function (list) {
            return _c(
              "option",
              { key: list.id, domProps: { value: list.id } },
              [_vm._v(_vm._s(_vm.$t(list.name)))]
            )
          }),
          0
        ),
        _c(
          "button",
          {
            staticClass: "normal-btn",
            attrs: { disabled: !_vm.getUserinfo.permissions.includes(140401) },
            on: {
              click: function ($event) {
                return _vm.create()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.create")))]
        ),
      ]),
      _c("div", { staticClass: "table-block" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("default.sort")))]),
              _c("th", [_vm._v("up")]),
              _c("th", [_vm._v("down")]),
              _c("th", [_vm._v(_vm._s(_vm.$t("default.gamesCategory")))]),
            ]),
          ]),
          _c(
            "tbody",
            [
              !_vm.allData || !_vm.allData.length
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "3" } }, [
                      _vm._v(_vm._s(_vm.$t("default.noData"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.allData, function (list, index) {
                return _c("tr", { key: list.id }, [
                  _c("td", [_vm._v(_vm._s(list.sort))]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "normal-btn",
                        on: {
                          click: function ($event) {
                            return _vm.moveUp(index)
                          },
                        },
                      },
                      [_vm._v("▲")]
                    ),
                  ]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "delete-btn",
                        on: {
                          click: function ($event) {
                            return _vm.moveDown(index)
                          },
                        },
                      },
                      [_vm._v("▼")]
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GLOBAL.i18nCheckName(
                            _vm.getGamesCategorys.find(
                              (data) => list.category === data.id
                            )
                          )
                        ) +
                        " "
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "input-block" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }