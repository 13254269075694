export const emptyFormModel = {
    id: undefined,
    name: '',        // 標題
    discountCategory: 4,    //
    discountType: 34,       // 優惠類型
    startTime: undefined,   // 開始時間
    endTime: undefined,     // 結束時間
    sort: 99,               // 排序
}

export const ruleSetting = {
    basicInformation: {
        lotteryCondition: {
            isEnabled: false,
            totalLotteryCount: 0,
        },
        memberCondition: {
            isEnabled: false,
            periodType: 1,
            lotteryCountLimit: 0
        }
    },
    participateStatus: {
        isNewMember: false,
        depositCondition: {
            periodType: 1,
            amount: 100
        },
        includeLevels: [],
        includeAgents: [],
        includeTags: []
    },
    luckyDrawExchangeConditions: {
        depositCondition: {
            isEnabled: false,
            periodType: 1,
            amount: 0
        },
        validBetAmountCondition: {
            isEnabled: false,
            periodType: 1,
            amount: 0,
            gameConditions: [
            ],
            turnoverRatio: [
            ]
        },
        memberLoginCondition: {
            isEnabled: false,
            periodType: 1,
            ticket: 0
        }
    },
    rewordSetting: {
        rewardList: [
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
            {
                "isBroadcast": false,
                "prizeType": 1,
                "prizeName": "",
                "wageringRate": 1,
                "guarantCount": 0,
                "prizeCount": 1,
                "lotteryCountlimit": 0,
                "prizeWeight": 0
            },
        ]
    },
    html: '',
    luckyDrawStyleId: undefined
}

export const emptyStyleModel = {
    id: undefined,
    name: undefined,
    luckyDrawCode: undefined,
    status: 1,
    File: undefined
}

export const emptyTicketModel = {
    id: undefined,
    memberAccount: undefined,        // 會員帳號
    ticketCount: 1,    // 補發張數
    remark: undefined,       // 備註
    file: undefined,   // 匯入檔案
}