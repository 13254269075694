var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("default.create") + " " + _vm.$t("default.agent"))
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block up-input" }, [
              _c("div", { staticClass: "domain" }, [
                _c("label", { staticClass: "checkbox-row m-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.isSelfDomain,
                        expression: "createModel.isSelfDomain",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.createModel.isSelfDomain)
                        ? _vm._i(_vm.createModel.isSelfDomain, null) > -1
                        : _vm.createModel.isSelfDomain,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.createModel.isSelfDomain,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.createModel,
                                "isSelfDomain",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.createModel,
                                "isSelfDomain",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.createModel, "isSelfDomain", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isSelfDomain`)) + " "
                  ),
                ]),
                _c("span", [
                  _vm._v(" https:// "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.subDomain,
                        expression: "subDomain",
                      },
                    ],
                    class: { selfDomain: _vm.createModel.isSelfDomain },
                    attrs: { type: "text" },
                    domProps: { value: _vm.subDomain },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.subDomain = $event.target.value
                      },
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.createModel.isSelfDomain
                          ? ""
                          : "." + _vm.agentDamain
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.name,
                      expression: "createModel.name",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}account`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.account,
                      expression: "createModel.account",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "account", $event.target.value)
                    },
                  },
                }),
                _vm.accountError
                  ? _c("span", { staticClass: "input-error" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.accountCheck`))
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.password`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.password,
                      expression: "createModel.password",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.createModel.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "password", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.CommissionCalculationCycle`
                      )
                    ) +
                    " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.commissionCalculationCycle,
                        expression: "createModel.commissionCalculationCycle",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "commissionCalculationCycle",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.GLOBAL.settlement, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id * 1 } },
                      [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}status`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.createModel.status,
                        expression: "createModel.status",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.createModel,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.getStatusList, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}phoneNumber`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phoneNumber,
                      expression: "phoneNumber",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.phoneNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.phoneNumber = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}discountCommissionRate`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.createModel.discountCommissionRate,
                      expression: "createModel.discountCommissionRate",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.discountCommissionRate },
                  on: {
                    keyup: function ($event) {
                      _vm.createModel.discountCommissionRate =
                        _vm.GLOBAL.checkValue(
                          _vm.createModel.discountCommissionRate,
                          2
                        )
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "discountCommissionRate",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("label"),
              _c("label", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.getUserinfo.permissions.includes(20103),
                    expression: "!getUserinfo.permissions.includes(20103)",
                  },
                ],
              }),
              _c(
                "label",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.getUserinfo.permissions.includes(20103),
                      expression: "getUserinfo.permissions.includes(20103)",
                    },
                  ],
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}proxyAgent`)) + " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.createModel.proxyAgentId,
                          expression: "createModel.proxyAgentId",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.createModel,
                            "proxyAgentId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: null * 1 } }, [
                        _vm._v(_vm._s(_vm.$t("default.none"))),
                      ]),
                      _vm._l(_vm.proxyAgentList, function (list) {
                        return _c(
                          "option",
                          { key: list.id, domProps: { value: list.id } },
                          [_vm._v(_vm._s(_vm.$t(list.account)))]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "divider-h-2" }),
            _c("div", { staticClass: "permission-block" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.permissions`))),
              ]),
              _c(
                "div",
                { staticClass: "permission-box" },
                _vm._l(_vm.getPermissionsTitle, function (list, index) {
                  return _c(
                    "div",
                    { key: list.id, staticClass: "permission-list" },
                    [
                      _c("ul", [
                        _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.createModel.permissions,
                                  expression: "createModel.permissions",
                                },
                              ],
                              attrs: {
                                name: "checkBox",
                                id: "main" + list.name,
                                type: "checkbox",
                              },
                              domProps: {
                                value: list.id,
                                checked: Array.isArray(
                                  _vm.createModel.permissions
                                )
                                  ? _vm._i(
                                      _vm.createModel.permissions,
                                      list.id
                                    ) > -1
                                  : _vm.createModel.permissions,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkAll("main" + list.name)
                                },
                                change: function ($event) {
                                  var $$a = _vm.createModel.permissions,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = list.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.createModel,
                                          "permissions",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.createModel,
                                          "permissions",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.createModel,
                                      "permissions",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Permission." + list.name)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.firstData[index],
                              function (firstTree, firstIndex) {
                                return [
                                  _c("li", { key: firstTree.id }, [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.createModel.permissions,
                                            expression:
                                              "createModel.permissions",
                                          },
                                        ],
                                        class: [
                                          "main" + list.name,
                                          "parent" + list.name,
                                        ],
                                        attrs: {
                                          name: "checkBox",
                                          id: firstTree.name,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          value: firstTree.id,
                                          checked: Array.isArray(
                                            _vm.createModel.permissions
                                          )
                                            ? _vm._i(
                                                _vm.createModel.permissions,
                                                firstTree.id
                                              ) > -1
                                            : _vm.createModel.permissions,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkAll(
                                              firstTree.name,
                                              "main" + list.name
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a =
                                                _vm.createModel.permissions,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = firstTree.id,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.createModel,
                                                    "permissions",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.createModel,
                                                    "permissions",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.createModel,
                                                "permissions",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "Permission." + firstTree.name
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.secData[index][firstIndex],
                                      function (secTree, secIndex) {
                                        return _c(
                                          "li",
                                          { key: _vm.secData.id },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    secIndex ===
                                                      _vm.secData[index][
                                                        firstIndex
                                                      ].length -
                                                        1
                                                      ? "└"
                                                      : "├"
                                                  ) +
                                                  " "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.createModel
                                                        .permissions,
                                                    expression:
                                                      "createModel.permissions",
                                                  },
                                                ],
                                                class: [
                                                  "main" + list.name,
                                                  firstTree.name,
                                                  "parent" + firstTree.name,
                                                ],
                                                attrs: {
                                                  name: "checkBox",
                                                  type: "checkbox",
                                                },
                                                domProps: {
                                                  value: secTree.id,
                                                  checked: Array.isArray(
                                                    _vm.createModel.permissions
                                                  )
                                                    ? _vm._i(
                                                        _vm.createModel
                                                          .permissions,
                                                        secTree.id
                                                      ) > -1
                                                    : _vm.createModel
                                                        .permissions,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.checkAll(
                                                      false,
                                                      firstTree.name,
                                                      "main" + list.name
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.createModel
                                                          .permissions,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = secTree.id,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.createModel,
                                                            "permissions",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.createModel,
                                                            "permissions",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.createModel,
                                                        "permissions",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Permission." +
                                                        secTree.name
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    mousedown: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }