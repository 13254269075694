import { mapGetters, mapMutations } from "vuex";

export default {
    data() {
        return {
            allData: undefined,
            tableList: undefined,
            digital: new Intl.NumberFormat(),
            sort: false,
            chooice: undefined,
        }
    },
    watch: {
        allData: {
            handler(value) {
                this.tableList = this.sliceData_resetPage(value);
            },
            immediate: true
        },
        getShowCount: {
            handler() {
                this.tableList = this.sliceData_resetPage(this.allData);
            },
        }
    },
    computed: {
        ...mapGetters(["getPage", "getShowCount"]),
        totalCount() {
            return this.allData?.length || 0;
        },
    },
    created() {
        this.SET_PAGE(1);
    },
    methods: {
        ...mapMutations({
            SET_PAGE: "app/SET_PAGE"
        }),
        handlePage() {
            this.tableList = this.allData?.slice(
                (this.getPage - 1) * this.getShowCount,
                this.getPage * this.getShowCount
            );
        },
        sliceData_resetPage(value) {
            this.SET_PAGE(1);
            // this.SET_PAGE(this.getShowCount > this.totalCount ? 1 : this.getPage);
            return value?.slice(
                (this.getPage - 1) * (this.getShowCount === "all" ? value.length : this.getShowCount),
                this.getPage * (this.getShowCount === "all" ? value.length : this.getShowCount)
            );
        },
        dataSort(sortName,sortType,sort = undefined) {
            this.chooice = sortName;
            let newData = [];
            this.sort = (sort !== undefined ? sort : !this.sort);
            if(sortType === 'time') {
                newData = this.allData = this.allData.sort((a,b) => (this.sort) ? new Date(b[sortName]) - new Date(a[sortName]) : new Date(a[sortName]) - new Date(b[sortName]));
            }else if(sortType === 'number'){
                newData = this.allData = this.allData.sort((a,b) => (this.sort) ? b[sortName] - a[sortName] : a[sortName] - b[sortName]);
            }else{
                newData = this.allData.sort();
            }
            return this.allData = newData;
        },
        direction(chooiceTitle) {
            return this.chooice === chooiceTitle ? this.sort ? "⬇" : "⬆" : "";
        },
    }
}