<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.edit")
            }}{{ $t(`title.SEO-gameBlog`) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="inputTop">
              <div class="title">
                {{ $t(`${i18nPrefix}dialog.articleStatus`) }}
                <label
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': editModel.status }"
                >
                  <div
                    class="slide"
                    @click="toggleSlide"
                    :class="{ 'slide-on': editModel.status }"
                  >
                    <label for="deleteStatus" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="deleteStatus"
                      v-model="editModel.status"
                      style="display: none"
                    />
                  </div>
                </label>
              </div>
              <div class="title">
                {{ $t(`${i18nPrefix}dialog.top`) }}
                <label
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': editModel.isTop }"
                >
                  <div
                    class="slide"
                    @click="toggleSlide"
                    :class="{ 'slide-on': editModel.isTop }"
                  >
                    <label for="isTop" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="isTop"
                      v-model="editModel.isTop"
                      style="display: none"
                    />
                  </div>
                </label>
              </div>
              <div class="title">
                {{ $t(`${i18nPrefix}dialog.previewStatus`) }}
                <label
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': editModel.previewTextStatus }"
                >
                  <div
                    class="slide"
                    @click="toggleSlide"
                    :class="{ 'slide-on': editModel.previewTextStatus }"
                  >
                    <label
                      for="previewTextStatus"
                      style="display: none"
                    ></label>
                    <input
                      type="checkbox"
                      id="previewTextStatus"
                      v-model="editModel.previewTextStatus"
                      style="display: none"
                    />
                  </div>
                </label>
              </div>
              <div class="select-div">
                {{ $t(`${i18nPrefix}dialog.domain`) }}
                <select v-model="editModel.agentId">
                  <option
                    v-for="list of agentList"
                    :value="list.id"
                    :key="list.id"
                  >
                    {{ list.domain }}
                  </option>
                </select>
              </div>
              <div class="select-div">
                {{ $t(`${i18nPrefix}dialog.blogCategoryName`) }}
                <select v-model="editModel.seoBlogCategoryId">
                  <option
                    v-for="list of selectSEOGameList"
                    :value="list.id"
                    :key="list.id"
                  >
                    {{ list.name }}
                  </option>
                </select>
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}dialog.path`) }}
                </span>
                <input
                  type="text"
                  v-model="editModel.subPath"
                  :placeholder="$t(`${i18nPrefix}dialog.enterPath`)"
                />
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}dialog.articleTitle`) }}
                </span>
                <input
                  type="text"
                  v-model="editModel.title"
                  :placeholder="$t(`${i18nPrefix}dialog.enterTitlePlaceholder`)"
                />
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}dialog.previewText`) }}
                </span>
                <input
                  type="text"
                  v-model="editModel.previewText"
                  oninput="if(value.length>42)value=value.slice(0,42)"
                  :placeholder="$t(`${i18nPrefix}dialog.enterContent`)"
                />
              </div>
              <div class="order">
                <span>
                  {{ $t(`${i18nPrefix}postDate`) }}
                </span>
                <date-picker
                  v-model="editModel.publishTime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  type="datetime"
                ></date-picker>
              </div>
            </div>
            <div class="previewDiv" @click="imageDialog()">
              <span v-if="!editModel.previewImageUrl"
                >{{ $t(`${i18nPrefix}dialog.clickToUpload`) }}{{ $t("default.previewImage") }}</span
              >
              <img :src="editModel.previewImageUrl" style="display: block" />
            </div>
            <div class="metaTitle">
              <span> title </span>
              <input
                type="text"
                v-model="editModel.metadata.title"
                :placeholder="$t(`${i18nPrefix}dialog.enterTitle`)"
              />
            </div>
            <div class="add-th">
              meta
              <button
                class="add-btn"
                style="background-color: green; margin: 5px"
                @click="addData('+', 'meta')"
              >
                <span>+</span>
              </button>
              <button class="add-btn" @click="checkDelete('meta')">
                <span>-</span>
              </button>
            </div>
            <div class="meta">
              <div
                v-for="(list, index) of editModel.metadata.meta"
                :key="'meta' + index"
                class="metaForDiv"
              >
                <div class="metaDiv">
                  <input
                    type="text"
                    :placeholder="$t(`${i18nPrefix}dialog.enterAttr`)"
                    v-model="list.tag"
                  />
                  <input
                    type="text"
                    :placeholder="$t(`${i18nPrefix}dialog.enterAttrValue`)"
                    v-model="list.tagValue"
                  />
                  <textarea
                    v-model="list.content"
                    :placeholder="$t(`${i18nPrefix}dialog.enterContent`)"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="add-th">
              link
              <button
                class="add-btn"
                style="background-color: green; margin: 5px"
                @click="addData('+', 'link')"
              >
                <span>+</span>
              </button>
              <button class="add-btn" @click="checkDelete('link')">
                <span>-</span>
              </button>
            </div>
            <div class="meta">
              <div
                v-for="(link, index) of editModel.metadata.link"
                :key="'link' + index"
                class="metaForDiv linkForDiv"
              >
                <div class="linkDiv">
                  <div class="add-th">
                    <button
                      class="add-btn"
                      style="background-color: green; margin: 5px"
                      @click="addData('+', index)"
                    >
                      <span>+</span>
                    </button>
                    <button class="add-btn" @click="checkDelete(index)">
                      <span>-</span>
                    </button>
                  </div>
                  <div
                    class="inputDiv link"
                    v-for="(list, linkIndex) of link"
                    :key="'link' + index + linkIndex"
                  >
                    <input
                      type="text"
                      :placeholder="$t(`${i18nPrefix}dialog.enterAttr`)"
                      v-model="list.tag"
                    />
                    <input
                      type="text"
                      :placeholder="$t(`${i18nPrefix}dialog.enterAttrValue`)"
                      v-model="list.value"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="add-th">
              script
              <button
                class="add-btn"
                style="background-color: green; margin: 5px"
                @click="addData('+', 'script')"
              >
                <span>+</span>
              </button>
              <button class="add-btn" @click="checkDelete('script')">
                <span>-</span>
              </button>
            </div>
            <div class="script">
              <div
                class="scriptDiv"
                v-for="(list, index) of editModel.metadata.script"
                :key="index"
              >
                <div class="box" v-show="!list.innerHtml && !list.type">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="list.async"
                    :disabled="list.innerHtml !== '' || list.type !== ''"
                  />async
                  <div class="inputDiv">
                    src<input
                      type="text"
                      :placeholder="$t(`${i18nPrefix}dialog.enterSrc`)"
                      v-model="list.src"
                      :disabled="list.innerHtml !== '' || list.type !== ''"
                    />
                  </div>
                </div>
                <div v-show="!list.src && !list.async">
                  <div class="link">
                    type<input
                      type="text"
                      :placeholder="$t(`${i18nPrefix}dialog.enterType`)"
                      v-model="list.type"
                      :disabled="list.src || list.async"
                    />
                    {{ $t(`${i18nPrefix}dialog.enterScriptContent`) }}
                    <textarea
                      v-model="list.innerHtml"
                      :placeholder="$t(`${i18nPrefix}dialog.enterScript`)"
                      rows="5"
                      :disabled="list.src || list.async"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {{ $t(`${i18nPrefix}dialog.preview`) }}
          <button class="normal-btn" @click="parseToSeo(preview)">
            {{ $t(`${i18nPrefix}dialog.reverseInput`) }}
          </button>
          <div class="textarea">
            <textarea v-model="preview" rows="20"></textarea>
          </div>
          <div class="editor-block">
            <ckeditor
              v-model="editModel.html"
              :editor-url="this.GLOBAL.CKEditorUrl"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        @toggle="currDialog = undefined"
        @choiceImage="choiceImage"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="deleteMetaData"
      />
    </transition>
  </div>
</template>

<script>
import imagePage from "@/layout/components/mediaLibraryImage.vue";
import { toggleStyle } from "@/utils/slideUtils";
import { joinT } from "@/utils/transDateUtils";
import {
  parseToSeoHeader,
  parseToSeoArray,
  addSeoArray,
} from "@/utils/SEOFunction";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";

export default {
  name: "Edit",
  props: ["agentList", "SEOGameList", "list"],
  data() {
    return {
      editorConfig: {
        allowedContent: true,
        height: "900",
      },
      editModel: {
        id: this.list.id,
        status: this.list.status === 1 ? true : false,
        agentId: undefined,
        isTop: this.list.isTop,
        seoBlogCategoryId: this.list.seoBlogCategoryId,
        subPath: this.list.subPath,
        title: this.list.title,
        metadata: [],
        previewImageUrl: this.list.previewImageUrl,
        previewText: this.list.previewText,
        previewTextStatus: this.list.previewTextStatus,
        publishTime: this.list.publishTime,
        html: this.list.html,
      },
      preview: undefined,
      status: false,
      currDialog: undefined,
      selectSEOGameList: [],
      alertMsg: undefined,
      deleteData: undefined,
    };
  },
  components: {
    imagePage,
    Alert,
  },
  watch: {
    "editModel.metadata": {
      handler() {
        this.preview = parseToSeoArray(this.editModel.metadata);
      },
      deep: true,
    },
    "editModel.agentId": function (val) {
      this.selectSEOGameList = this.SEOGameList.filter(
        (data) => data.agentId === val
      );
      this.editModel.seoBlogCategoryId = this.selectSEOGameList.find(
        (data) => data.id === this.editModel.seoBlogCategoryId
      )
        ? this.editModel.seoBlogCategoryId
        : this.selectSEOGameList[0].id;
    },
  },
  created() {
    this.editModel.metadata = this.list.metadata;
    this.editModel.agentId = this.list.agentId;
  },
  methods: {
    toggleSlide: toggleStyle,
    addData(type, dataType) {
      addSeoArray(type, dataType, this.editModel.metadata);
    },
    edit() {
      if (
        !this.SEOGameList.find(
          (data) => data.id === this.editModel.seoBlogCategoryId
        )
      ) {
        Message.error("類別資料錯誤", 1000);
        return;
      }
      this.editModel.publishTime = this.editModel.publishTime
        ? joinT(this.editModel.publishTime + this.GLOBAL.timeZone)
        : undefined;
      this.editModel.status = this.editModel.status ? 1 : 2;

      this.$emit("update", this.editModel);
    },
    imageDialog() {
      this.currDialog = "imagePage";
    },
    choiceImage(url) {
      this.editModel.previewImageUrl = url;
      this.currDialog = "";
    },
    parseToSeo(previewData) {
      this.editModel.metadata = parseToSeoHeader(previewData);
    },
    checkDelete(data) {
      this.alertMsg =
        "確認刪除" + (isNaN(Number(data)) ? data : "Link子") + "標籤 ?";
      this.deleteData = data;
    },
    deleteMetaData() {
      this.alertMsg = undefined;
      this.addData("-", this.deleteData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/SEOPage.scss";
</style>