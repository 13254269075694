var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" }, on: { click: _vm.clearDropDown } },
    [
      _c("audio", {
        ref: "deposit",
        attrs: { src: require("./assets/deposit.mp3") },
      }),
      _c("audio", {
        ref: "withdraw",
        attrs: { src: require("./assets/withdraw.mp3") },
      }),
      _c("audio", {
        ref: "discount",
        attrs: { src: require("./assets/discount.mp3") },
      }),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }