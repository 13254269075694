<template>
    <div class="dialog-mask" @mousedown.self="$emit('toggle')">
        <div class="dialog-container">
          <div class="dialog-block">
            <div class="header">
              <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
            </div>
            <div class="divider"></div>
            <main>
              <div class="input-block">
                <label>
                  {{ $t(`${i18nPrefix}dialog.code`) }}
                  <input type="text" v-model="editModel.code" />
                </label>
                <label>
                  {{ $t(`${i18nPrefix}dialog.name`) }}
                  <input type="text" v-model="editModel.name" />
                </label>
              </div>
              <div class="advance-block">
                <button class="normal-btn" @click="$emit('edit', editModel)">{{ $t("default.confirm") }}</button>
                <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
              </div>
            </main>
          </div>
        </div>
      </div>
</template>

<script>
export default {
    name: "Edit",
    props: ["id", "name", "code","i18nPrefix"],
    data() {
      return {
        editModel: {
          id: this.id,
          name: this.name,
          code: this.code,
        }
      }
    },
}
</script>

<style lang="scss" scoped>
.dialog-mask {
  .input-block {
    flex-direction: column;
    label {
      width: 50%;
    }
  }
}
</style>