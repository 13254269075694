<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')" :disabled="!getUserinfo.permissions.includes(100101)">{{ $t("default.create") }}</button>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}domain`) }}
            <select v-model="searchForm.agentId">
              <option :value="Number(0)">{{ $t("default.all") }}</option>
              <option v-for="list of agentList" :value="list.id" :key="list.id">{{ list.domain }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}keyword`) }}
            <input type="text" v-model="searchForm.name">
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
          <tr>
            <th><input type="checkbox" v-model="checkAllBtn" @click="checkAll()"></th>
            <th>{{ $t("default.edit") }}</th>
            <th>{{ $t(`${i18nPrefix}blogCategory`) }}</th>
            <th>{{ $t(`${i18nPrefix}pathName`) }}</th>
            <th>{{ $t(`${i18nPrefix}domain`) }}</th>
            <th>{{ $t(`${i18nPrefix}createDate`) }}</th>
            <th>{{ $t(`${i18nPrefix}editDate`) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!tableList || tableList.length === 0">
            <td colspan="9">{{ $t("default.noData") }}</td>
          </tr>
          <tr v-for="(list, index) of tableList" :key="index">
            <td><input type="checkbox" v-model="ids" :value="list.id"></td>
            <td><button class="normal-btn" :disabled="!getUserinfo.permissions.includes(100102)" @click="handleDialog(list, 'edit')">{{ $t("default.edit") }}</button></td>
            <td>{{ list.name }}</td>
            <td>{{ list.path }}</td>
            <td>{{ agentList.find(data => data.id === list.agentId ).domain }}</td>
            <td>{{ list.createTime }}</td>
            <td>{{ list.updateTime }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="delete-btn" @click="handleDelete(ids)" :disabled="!getUserinfo.permissions.includes(100103)">{{ $t("default.delete") }}</button>
    </div>
    <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
    />
    <transition name="fade" mode="out-in">
      <component
          :agentList = "agentList"
          :is="currDialog"
          v-bind="dialogData"
          @toggle="currDialog = undefined"
          @create="create"
          @edit="edit"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Confirm
          v-if="getShowConfirm"
          @toggle="TOGGLE_CONFIRM"
          @delete="deleteSEOBlogCategory"
      />
    </transition>
  </div>
</template>

<script>
import { searchModel } from "./model";
import { getAgentList } from "@/api/SEO/SEO";
import {
  createSEOBlogCategory,
  getSEOBlogCategoryList,
  editSEOBlogCategory,
  deleteSEOBlogCategory,
} from "@/api/SEO/SEO-blog";
import { toggleStyle } from "@/utils/slideUtils";
import Message from "@/layout/components/Message";
import Confirm from "@/layout/components/Confirm";
import Create from "./dialog/create.vue";
import Edit from "./dialog/edit.vue";
import { mapGetters, mapMutations } from "vuex";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "SEOBlogCategory",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      deleteData: undefined,
      agentList: [],
      gamesCategoryList: [],
      ids: [],
      checkAllBtn: false,
      i18nPrefix: "SEO-Blog-Function.SEO-blogCategory."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo"]),
  },
  async created() {
    this.searchForm.type = this.GLOBAL.SEOBlogCategoryType.blog;
    await getAgentList().then((res) => {
      this.agentList = res.data.agents;
    });
    this.search();
  },
  watch: {
    ids() {
      this.checkAllBtn = (this.ids.length === this.allData.length) ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    toggleSlide: toggleStyle,
    search() {
      this.deleteData = undefined;
      this.RESET_CONFIRM();
      this.RESET_DIALOG();
      getSEOBlogCategoryList(this.searchForm).then((res) => {
        this.allData = res.data.categories;
      });
    },
    create(data) {
      data.type = this.GLOBAL.SEOBlogCategoryType.blog;
      createSEOBlogCategory(data).then((res) => {
        if(res) this.search();
      });
    },
    edit(data) {
      editSEOBlogCategory(data).then(res => {
        if(res) this.search();
      })
    },
    handleDelete(data) {
      this.TOGGLE_CONFIRM();
      this.deleteData = data;
    },
    deleteSEOBlogCategory() {
      deleteSEOBlogCategory({ ids: this.deleteData }).then(res => {
        if(res) this.search();
      })
    },
    checkAll() {
      this.ids = this.checkAllBtn ? [] : this.allData.map(data => data.id);
    }
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  margin-top: 5px;
}
.delete-btn {
  margin-top: 10px;
}
</style>