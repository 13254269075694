var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-mask2" }, [
    _c("div", { ref: "dialog", staticClass: "dialog-container" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t(
                `default.${_vm.editData !== undefined ? "edit" : "create"}`
              ) +
                " " +
                _vm.$t("title." + _vm.$route.meta.title)
            ) + " "
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "dialog-close",
            on: {
              click: function ($event) {
                return _vm.$emit("toggle")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
      _c("div", { staticClass: "dialog-block" }, [
        _c("main", [
          _c(
            "div",
            { staticClass: "input-block" },
            [
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.basicSettings`))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("div", { staticClass: "form-box-half" }, [
                  _c("label", [
                    _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.name,
                          expression: "formModel.name",
                        },
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(`${_vm.i18nPrefix}name`),
                      },
                      domProps: { value: _vm.formModel.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formModel, "name", $event.target.value)
                        },
                      },
                    }),
                    _c("span", { ref: "rulesname", staticClass: "form-rules" }),
                  ]),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}discountType`)) +
                        " "
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.discountType,
                            expression: "formModel.discountType",
                          },
                        ],
                        attrs: { disabled: _vm.editData !== undefined },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formModel,
                              "discountType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.discountTypeList, function (item) {
                        return _c(
                          "option",
                          { key: item.id, domProps: { value: item.value } },
                          [_vm._v(_vm._s(_vm.$t(item.name)) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("span", {
                      ref: "rulesdiscountType",
                      staticClass: "form-rules",
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-box-half" }, [
                  _c("label", [
                    _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formModel.remark,
                          expression: "formModel.remark",
                        },
                      ],
                      staticStyle: { height: "auto" },
                      attrs: { rows: "3" },
                      domProps: { value: _vm.formModel.remark },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formModel, "remark", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.startupSettings`))
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-box", staticStyle: { width: "100%" } },
                [
                  _c("label", [
                    _vm._v(" " + _vm._s(_vm.$t(`default.status`)) + " "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.discountStatus,
                            expression: "formModel.discountStatus",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formModel,
                              "discountStatus",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.discountStatus, function (item) {
                        return _c(
                          "option",
                          { key: item.key, domProps: { value: item.key } },
                          [_vm._v(_vm._s(_vm.$t(item.name)))]
                        )
                      }),
                      0
                    ),
                    _c("span", {
                      ref: "rulesdiscountStatus",
                      staticClass: "form-rules",
                    }),
                  ]),
                  _c(
                    "label",
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.formModel.discountStatus === 3,
                              expression: "formModel.discountStatus === 3",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}startTime`)))]
                      ),
                      _c("date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formModel.discountStatus === 3,
                            expression: "formModel.discountStatus === 3",
                          },
                        ],
                        attrs: {
                          format: "YYYY-MM-DD HH:mm",
                          "value-type": "format",
                          type: "datetime",
                        },
                        model: {
                          value: _vm.startTime,
                          callback: function ($$v) {
                            _vm.startTime = $$v
                          },
                          expression: "startTime",
                        },
                      }),
                      _c("span", {
                        ref: "rulesstartTime",
                        staticClass: "form-rules",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.formModel.discountStatus === 3,
                              expression: "formModel.discountStatus === 3",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`)))]
                      ),
                      _c("date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formModel.discountStatus === 3,
                            expression: "formModel.discountStatus === 3",
                          },
                        ],
                        attrs: {
                          format: "YYYY-MM-DD HH:mm",
                          "value-type": "format",
                          type: "datetime",
                        },
                        model: {
                          value: _vm.endTime,
                          callback: function ($$v) {
                            _vm.endTime = $$v
                          },
                          expression: "endTime",
                        },
                      }),
                      _c("span", {
                        ref: "rulesendTime",
                        staticClass: "form-rules",
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                _vm.discountTypeList.find(
                  (e) => e.id === _vm.selectDiscountType
                ).component,
                {
                  tag: "component",
                  attrs: {
                    i18nPrefix: _vm.i18nPrefix,
                    selectDiscountType: _vm.selectDiscountType,
                    discountRule: _vm.discountRule,
                    formModel: _vm.formModel,
                    applyType: _vm.applyType,
                    dispatchType: _vm.dispatchType,
                    memberTag: _vm.memberTag,
                    memberDiscount: _vm.memberDiscount,
                    memberStar: _vm.memberStar,
                    editModel: _vm.editModel,
                    editModelTmp: _vm.editModelTmp,
                    imageUrlShow: _vm.imageUrlShow,
                    rulesError: _vm.rulesError,
                    gamesCategorys: _vm.getGamesCategorys,
                    rebateData: _vm.rebateData,
                  },
                  on: { formulaDisabled: _vm.changeFormulaDisabled },
                }
              ),
              _c("div", { staticClass: "divider-v2" }, [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editV2.front`))),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("div", { staticClass: "form-box-half form-column" }, [
                  _c("div", { staticClass: "form-box" }, [
                    _vm.discountRule[_vm.selectDiscountType].isShowFont ==
                    undefined
                      ? _c("label", { staticClass: "label-row" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formModel.isShowFont,
                                expression: "formModel.isShowFont",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.formModel.isShowFont)
                                ? _vm._i(_vm.formModel.isShowFont, null) > -1
                                : _vm.formModel.isShowFont,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.formModel.isShowFont,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formModel,
                                        "isShowFont",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formModel,
                                        "isShowFont",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.formModel, "isShowFont", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(`${_vm.i18nPrefix}dialog.showInFront`)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("label", [
                      _vm._v(" " + _vm._s(_vm.$t(`default.sort`)) + " "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.sort,
                            expression: "formModel.sort",
                          },
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t(`default.sort`),
                        },
                        domProps: { value: _vm.formModel.sort },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.formModel, "sort", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("label", { staticClass: "fileName" }, [
                    _vm._v(" " + _vm._s(_vm.$t(`default.image`)) + " "),
                    _c("div", { staticClass: "handleFile" }, [
                      _c("input", {
                        ref: "file",
                        staticClass: "custom-file",
                        attrs: { type: "file" },
                        on: { change: _vm.fileSelect },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editModel.imageName,
                            expression: "editModel.imageName",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.editModel.imageName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.editModel,
                              "imageName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "button",
                        { staticClass: "upload", on: { click: _vm.upload } },
                        [_vm._v(_vm._s(_vm.$t("default.browse")))]
                      ),
                      _c(
                        "button",
                        {
                          class: _vm.formModel.isDeleteFile
                            ? "delete-active"
                            : "delete",
                          on: {
                            click: function ($event) {
                              _vm.formModel.isDeleteFile =
                                !_vm.formModel.isDeleteFile
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.delete")))]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.formModel.isDeleteFile,
                          expression: "!formModel.isDeleteFile",
                        },
                      ],
                      staticClass: "form-box",
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          display: "block",
                          margin: "auto",
                        },
                        attrs: { src: _vm.imageUrlShow },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        ref: "img",
                        staticStyle: { display: "block", margin: "auto" },
                        attrs: { src: _vm.imageUrlShow },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "form-box-half form-column" }, [
                  _c(
                    "label",
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}editV2.activityContent`)
                          ) +
                          " "
                      ),
                      _c("ckeditor", {
                        attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
                        model: {
                          value: _vm.formModel.html,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "html", $$v)
                          },
                          expression: "formModel.html",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "advance-block" }, [
        _c(
          "button",
          {
            staticClass: "confirm-btn main-style-btn",
            attrs: { disabled: _vm.formulaDisabled },
            on: { click: _vm.submit },
          },
          [_vm._v(_vm._s(_vm.$t("default.confirm")))]
        ),
        _c(
          "button",
          {
            staticClass: "normal-btn-v2 main-style-btn",
            on: {
              click: function ($event) {
                return _vm.$emit("toggle")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.cancel")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }