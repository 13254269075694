<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-status">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.remark") }}</h4>
          <button class="confirm-btn main-style-btn" @click="$emit('updateRemark', member)">{{ $t("default.confirm") }}</button>
        </div>
        <div class="divider"></div>
        <label>
          <textarea v-model="member.remark"></textarea>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Remark",
  props: [
    "id",
    "remark",
    "i18nPrefix"
  ],
  data() {
    return {
      member: {
        id: this.id,
        remark: this.remark
      }
    }
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.dialog-container{
  width: auto !important;
  label textarea{
    width: 500px;
    height: 200px;
  }
}
@media screen and (max-width: 768px) {
  .dialog-container{
    width: 90% !important;
    label { 
      width: 100%;
      textarea {
        width: 100%;
      }
    }
  }
}
</style>