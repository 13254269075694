var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-container edit-member" },
        [
          _c("div", { staticClass: "dialog-block" }, [
            _c("div", { staticClass: "header" }, [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}operateList`))),
              ]),
            ]),
            _c("div", { staticClass: "divider" }),
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}eventTime`))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    !_vm.tableList
                      ? _c("tr", [
                          _c("td", [_vm._v(_vm._s(_vm.$t("default.noData")))]),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.tableList, function (list, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(list))]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = _vm.dialogData = undefined
                  },
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }