import { render, staticRenderFns } from "./DiscountForm.vue?vue&type=template&id=06374ff2"
import script from "./DiscountForm.vue?vue&type=script&lang=js"
export * from "./DiscountForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06374ff2')) {
      api.createRecord('06374ff2', component.options)
    } else {
      api.reload('06374ff2', component.options)
    }
    module.hot.accept("./DiscountForm.vue?vue&type=template&id=06374ff2", function () {
      api.rerender('06374ff2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/report/quota-history/table/DiscountForm.vue"
export default component.exports