var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.create")))]
            ),
          ]),
        ]),
        _c("main", [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}version`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}country`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}lang`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}pattern`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}checkTime`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.tableList, function (list) {
                  return _c("tr", { key: list.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "table-btn main-style-btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog({ list: list }, "Edit")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.edit")))]
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          (list.version || "") +
                            " " +
                            _vm.GLOBAL.i18nCheckName(
                              _vm.deviceType.find(
                                (data) => data.id === list.deviceType
                              )
                            )
                        )
                      ),
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(JSON.parse(list.country).countrys)),
                    ]),
                    _c("td", [_vm._v(_vm._s(JSON.parse(list.lang).langs))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.GLOBAL.i18nCheckName(
                            _vm.pattern.find((data) => data.id === list.pattern)
                          )
                        )
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          list.pattern === 1
                            ? list.firstInstallTime
                            : list.pattern === 2
                            ? list.lastInstallTime
                            : ""
                        )
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  langs: _vm.langs,
                  countrys: _vm.countrys,
                  pattern: _vm.pattern,
                  deviceType: _vm.deviceType,
                  i18nPrefix: _vm.i18nPrefix,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }