var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "form-box form-column shadow-sm px-3 py-4 bg-white rounded overview-table",
    },
    [
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c("TreeTable", {
            ref: "treeTable",
            attrs: { list: _vm.agentRebate.list, column: _vm.tableColumn },
            scopedSlots: _vm._u([
              {
                key: "column_gamesCategory",
                fn: function (props) {
                  return [
                    _vm._v(
                      _vm._s(
                        `${
                          props.gamesCategory
                            ? _vm.GLOBAL.i18nCheckName(
                                _vm.getGamesCategorys.find(
                                  (data) => data.id == props.gamesCategory
                                )
                              )
                            : _vm.$t(`${_vm.i18nPrefix}rebateGame.allGame`)
                        }`
                      )
                    ),
                  ]
                },
              },
              {
                key: "column_childrenRebate",
                fn: function (props) {
                  return [
                    _vm._v(" " + _vm._s(props.childrenRebate) + " "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              props.gamesCategory &&
                              props.gamesCategory !== 99999,
                            expression:
                              "props.gamesCategory && props.gamesCategory !== 99999",
                          },
                        ],
                        staticClass: "rate",
                        class: { childrenRate: props.gamesCategory },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            props.childrenRate +
                              (props.gamesCategory ? "%" : "")
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "column_selfRebate",
                fn: function (props) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          props.gamesCategory && props.gamesCategory !== 99999
                            ? props.selfRebate
                            : ""
                        ) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "rate",
                        class: {
                          childrenRate:
                            props.gamesCategory &&
                            props.gamesCategory !== 99999,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            props.gamesCategory && props.gamesCategory !== 99999
                              ? props.selfRate + "%"
                              : props.selfRebate
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }