<template>
  <div class="dialog-mask2">
    <div ref="dialog" class="dialog-container">
      <div class="dialog-header">
        <h4>{{ $t(`default.${editData !== undefined ? 'edit' : 'create'}`) }}</h4>
        <div class="dialog-close" @click="$emit('toggle')">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="dialog-block">
        <main>
          <div class="input-block">
            <div class="divider-v2">
              <!-- 基本設定 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.basicSettings`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 名稱 -->
                <label>
                  {{ $t(`${i18nPrefix}campaignName`) }}
                  <input type="text" :placeholder="$t(`${i18nPrefix}campaignName`)" v-model="formModel.name" />
                </label>
              </div>
              <div class="form-box">
                <!-- 發放方式 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.auditMethod`) }}
                  <select v-model="ruleSetting.auditMethod">
                    <option v-for="list of auditMethod" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                  </select>
                </label>
                <!-- 流水倍率 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.wageringRate`) + "(%)" }}
                  <input type="number" min="0" max="100" v-model.number="ruleSetting.wageringRate"
                    @keyup="ruleSetting.wageringRate = GLOBAL.checkValue(ruleSetting.wageringRate, 1)" />
                </label>
              </div>
              <div class="form-box">
                <!-- 發放金額上限 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.maximum`) }}
                  <input type="number" min="0" v-model.number="ruleSetting.paymentAmountRange.maximum" />
                </label>
                <!-- 發放金額下限 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.minimum`) }}
                  <input type="number" min="0" v-model.number="ruleSetting.paymentAmountRange.minimum" />
                </label>
              </div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 計算方式 -->
                <label>
                  <!-- <span class="span-row">
                    <input type="checkbox">
                    系統回收列入計算
                  </span> -->
                  <div class="w-100">
                    {{ $t(`${i18nPrefix}dialog.calculationMethod`) }}
                    <select class="w-100" v-model="ruleSetting.calculationMethod">
                      <option v-for="list of calculationMethod" :key="list.id" :value="list.id">{{ $t(list.name) }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
              <div class="form-box">
                <!-- startTime -->
                <label>
                  <span>{{ $t('default.startTime') }}</span>
                  <date-picker v-model="startTime" format="YYYY-MM-DD HH" value-type="format" type="datetime"
                    :disabled-date="disabledStart"></date-picker>
                  <span ref="rulesstartTime" class="form-rules"></span>
                </label>
                <!-- endTime -->
                <label>
                  <span>{{ $t('default.endTime') }}</span>
                  <date-picker v-model="endTime" format="YYYY-MM-DD HH" value-type="format" type="datetime"
                    :disabled-date="disabledEnd"></date-picker>
                  <span ref="rulesendTime" class="form-rules"></span>
                </label>
              </div>
              <div class="form-box">
                <!-- 發放日期 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.settleCycle`) }}
                  <select v-model="ruleSetting.settleMethod.settleCycle">
                    <option v-for="list of settleCycle" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                  </select>
                </label>
                <label>
                  <span>{{ $t(`${i18nPrefix}dialog.settleTime`) }}</span>
                  <date-picker v-model="ruleSetting.settleMethod.settleTime" format="HH" value-type="format" type="time"
                    :disabled-date="disabledStart"></date-picker>
                  <span ref="rulesstartTime" class="form-rules"></span>
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.proportion`) }}</div>
            </div>
            <div class="form-box form-column">
              <div class="form-box">
                <label style="width: auto;">
                  {{ $t(`${i18nPrefix}dialog.layersNumber`) }}
                  <select v-model="ruleSetting.layersNumber">
                    <!-- <option v-for="list of auditMethod" :key="list.id" :value="list.id">{{ $t(list.name) }}</option> -->
                    <option :value="1 * 1">{{ $t(`${i18nPrefix}dialog.one`) }}</option>
                    <option :value="2 * 1">{{ $t(`${i18nPrefix}dialog.two`) }}</option>
                  </select>
                </label>
              </div>
              <div class="table-block table-layers mb-4">
                <div class="div-layers">
                  {{ $t(`${i18nPrefix}dialog.one`) }}
                  <div class="add-th">
                    <button class="add-btn" style="background-color: green;margin: 5px;" @click="addData('+', 0)">
                      <span>+</span>
                    </button>
                    <button class="add-btn" @click="addData('-', 0)">
                      <span>-</span>
                    </button>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>{{ $t(`${i18nPrefix}dialog.totalBetAmount`) }}</th>
                        <th>{{ $t(`${i18nPrefix}dialog.rate`) + "(%)" }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="list of ruleSetting.calculationRate.layerRates[0].rebateRates" :key="list.level">
                        <td>
                          <input type="number" min="0" v-model.number="list.totalBetAmount" />
                        </td>
                        <td>
                          <input type="number" min="0" v-model.number="list.rate"
                            @keyup="list.rate = GLOBAL.checkValue(list.rate, 2)" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="div-layers" v-if="ruleSetting.layersNumber == 2">
                  {{ $t(`${i18nPrefix}dialog.two`) }}
                  <div class="add-th">
                    <button class="add-btn" style="background-color: green;margin: 5px;" @click="addData('+', 1)">
                      <span>+</span>
                    </button>
                    <button class="add-btn" @click="addData('-', 1)">
                      <span>-</span>
                    </button>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>{{ $t(`${i18nPrefix}dialog.totalBetAmount`) }}</th>
                        <th>{{ $t(`${i18nPrefix}dialog.rate`) + "(%)" }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="list of ruleSetting.calculationRate.layerRates[1].rebateRates" :key="list.level">
                        <td>
                          <input type="number" min="0" v-model.number="list.totalBetAmount" />
                        </td>
                        <td>
                          <input type="number" min="0" v-model.number="list.rate"
                            @keyup="list.rate = GLOBAL.checkValue(list.rate, 2)" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="table-block w-100">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t(`${i18nPrefix}dialog.name`) }}</th>
                      <th>{{ $t("default.star") }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.levelBonusRates`) + "(%)" }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list of levelBonusRates" :key="list.level">
                      <td>
                        {{ list.name }}
                      </td>
                      <td>
                        {{ list.level }}
                      </td>
                      <td>
                        <input type="number" min="0" v-model.number="list.rate" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- 任務 -->
            <div class="divider-v2" v-show="unleash.isEnabled('member_referral_achievement')">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.mission`) }}</div>
            </div>
            <div class="divider-v2-area" style="display: block"
              v-show="unleash.isEnabled('member_referral_achievement')">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.achievements`) }}</div>
              <div class="table-block w-100 p-3">
                <div class="add-th">
                  <button class="add-btn" style="background-color: green;margin: 5px;"
                    @click="ruleSetting.achievements.isEnabled ? addAchievements('+') : ''">
                    <span>+</span>
                  </button>
                  <button class="add-btn" @click="ruleSetting.achievements.isEnabled ? addAchievements('-') : ''">
                    <span>-</span>
                  </button>
                </div>
                <div class="days-row" style="padding: 15px;">
                  <input type="checkbox" v-model="ruleSetting.achievements.isEnabled" />
                  {{ $t(`${i18nPrefix}dialog.isEnabled`) }}
                  <label style="width: 30% !important">
                    {{ $t("default.amount") }}
                    <input type="number" min="1" v-model.number="ruleSetting.achievements.amount"
                      :disabled="!ruleSetting.achievements.isEnabled" />
                  </label>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t(`${i18nPrefix}dialog.numberOfMembers`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.bonusAmount`) }}</th>
                      <th>{{ $t(`${i18nPrefix}dialog.bettingTurnover`) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(list, index) of ruleSetting.achievements.list" :key="'achievements' + index">
                      <td>
                        <input type="number" min="1" v-model.number="list.condition.numberOfMembers"
                          :disabled="!ruleSetting.achievements.isEnabled" />
                      </td>
                      <td>
                        <input type="number" min="1" v-model.number="list.bonusAmount"
                          :disabled="!ruleSetting.achievements.isEnabled" />
                      </td>
                      <td>
                        <input type="number" min="1" v-model.number="list.bettingTurnover"
                          :disabled="!ruleSetting.achievements.isEnabled" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.eligibility`) }}</div>
            </div>
            <div class="form-box-half form-column">
              <div class="form-box">
                <!-- 限定代理 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.includeAgents`) }}
                  <multiselect v-model="agentData" label="name" track-by="id" :placeholder="''" :options="agentList"
                    :multiple="true" :close-on-select="false">
                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
              <div class="form-box">
                <!-- 限定會員等級 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberLevel`) }}
                  <multiselect v-model="memberStar" label="name" track-by="star" :placeholder="''" :options="starList"
                    :multiple="true" :close-on-select="false"></multiselect>
                </label>
              </div>
            </div>
            <div class="form-box-half">
              <div class="form-box">
                <!-- 限定會員標籤 -->
                <label>
                  {{ $t(`${i18nPrefix}dialog.limitMemberTag`) }}
                  <multiselect v-model="memberTag" label="name" track-by="id" :placeholder="''" :options="tagList"
                    :multiple="true" :close-on-select="false">

                    <template slot="noOptions">{{ $t("default.noData") }}</template>
                  </multiselect>
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.CategorysAndPlatforms`) }}</div>
            </div>
            <!-- 平台類型 -->
            <div class="form-box star-box w-100">
              <div class="divider-v2-area" v-for="(list, key) of getGameCategoryAndPlatform" :key="key">
                <div class="divider-v2-text divider-v2-left">{{ GLOBAL.i18nCheckName(getGamesCategorys.find(data =>
          data.id == key)) }}</div>
                <!-- 各遊戲種類倍率 -->
                <label class="span-row" v-for="gamePlatforms of list" :key="gamePlatforms.id">
                  <input type="checkbox" :value="{ 'category': key * 1, 'platform': gamePlatforms.id }"
                    v-model="ruleSetting.gameConditions" />
                  {{ GLOBAL.i18nCheckName(getGamePlatforms.find(data => data.id == gamePlatforms.id)) }}
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <!-- 前台 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.front`) }}</div>
            </div>
            <div class="form-box w-100">
              <label class="w-100">
                {{ $t(`${i18nPrefix}dialog.copywriting`) }}
                <ckeditor v-model="ruleSetting.html" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
              </label>
            </div>
          </div>
        </main>
      </div>
      <div class="advance-block-release" v-show="detail == undefined">
        <div class="m-auto">
          <button class="normal-btn" @click="submit()">{{ $t("default.confirm") }}</button>
          <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
        </div>
        <button class="btn-orange" v-show="isCampaign == undefined && editData !== undefined" @click="submit(true)">{{
          $t(`${i18nPrefix}release`) }}</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  createCampaignSetting,
  updateCampaignSetting,
  createMemberReferral,
  updateMemberReferral
} from "@/api/campaign/memberReferral";
import { emptyFormModel, ruleSetting } from "./model";
import {
  transTodayHour,
  joinT,
} from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "Edit",
  props: [
    "detail",
    "isCampaign",
    "editData",
    "starList",
    "tagList",
    "agentList",
    "auditMethod",
    "calculationMethod",
    "settleCycle",
    "i18nPrefix"
  ],
  components: {
    Multiselect
  },
  data() {
    return {
      emptyFormModel,
      ruleSetting,
      levelBonusRates: [],
      isFrist: false, // 判斷是否是修改時欄位注入
      memberTag: [],
      memberStar: [],
      agentData: [],
      formModel: undefined,
      startTime: transTodayHour("from"),
      endTime: transTodayHour("from"),
      editModel: {},
      editModelTmp: {},
      formData: new FormData(),
      rulesError: []
    };
  },
  watch: {
    "formModel.name"(val) {
      this.formModel.name = val.trim();
    },
    "ruleSetting.layersNumber"(val) {
      if (this.isFrist) return

      if (val == 2) {
        let obj = [
          {
            layer: 2,
            rebateRates: [{
              totalBetAmount: 0,
              rate: 0,
            }]
          }
        ];

        this.ruleSetting.calculationRate.layerRates = this.ruleSetting.calculationRate.layerRates.concat(obj);
      }
      this.ruleSetting.calculationRate.layerRates.splice(val);
    },
  },
  computed: {
    ...mapGetters(["getGamePlatforms", "getUserinfo", "getGamesCategorys", "getGameCategoryAndPlatform"])
  },
  async created() {
    this.formModel = _.cloneDeep(this.emptyFormModel);

    this.levelBonusRates = this.starList.map(data => {
      return { name: data.name, level: data.star, rate: 0 };
    });;

    // 處理修改欄位
    this.processEditColumn();

    setTimeout(() => {
      this.isFrist = false;
    }, 200);
  },
  async beforeCreate() { },
  methods: {
    /**
     * 處理修改欄位
     */
    processEditColumn() {
      if (this.editData !== undefined) {
        this.isFrist = true;

        const ruleSetting = JSON.parse(this.editData.ruleSetting);
        for (const [key, value] of Object.entries(this.formModel)) {
          if (this.editData[key] !== undefined) {
            this.formModel[key] = this.editData[key];
          }
        }

        this.startTime = this.startTimeForOrin(this.formModel.startTime);
        this.endTime = this.startTimeForOrin(this.formModel.endTime);
        this.formModel.html = this.formModel.html ?? "";
        this.levelBonusRates = [...ruleSetting.calculationRate.levelBonusRates];

        let obj = {
          achievements: {
            isEnabled: false,
            amount: 1,
            list: []
          },
        };
        this.ruleSetting = !ruleSetting?.achievements ? {
          ...ruleSetting,
          ...obj
        } : ruleSetting
        if (!this.ruleSetting.achievements?.amount) {
          let amount = {
            amount: 1,
          }
          this.ruleSetting.achievements = {
            ...ruleSetting.achievements,
            ...amount
          }
        }

        this.ruleSetting.settleMethod.settleTime = ruleSetting.settleMethod.settleTime.substring(0, 2);

        // 處理多選套件格式
        if (ruleSetting.participateStatus.includeTags.length > 0) {
          this.memberTag = this.tagList.filter(e => {
            return ruleSetting.participateStatus.includeTags.indexOf(e.id) > -1;
          });
        }
        if (ruleSetting.participateStatus.includeLevels.length > 0) {
          this.memberStar = this.starList.filter(e => {
            return (
              ruleSetting.participateStatus.includeLevels.indexOf(e.star) > -1
            );
          });
        }
        if (ruleSetting.participateStatus.includeAgents.length > 0) {
          this.agentData = this.agentList.filter(e => {
            return (
              ruleSetting.participateStatus.includeAgents.indexOf(e.id) > -1
            );
          });
        }
      }
      this.isFrist = false;
    },
    /**
     * 送出
     */
    async submit(release = false) {
      this.formModel.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startMinute + this.GLOBAL.timeZone)
        : undefined;
      this.formModel.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.startMinute + this.GLOBAL.timeZone)
        : undefined;

      this.ruleSetting.calculationRate.levelBonusRates = [...this.levelBonusRates]


      this.ruleSetting.settleMethod.settleTime = this.ruleSetting.settleMethod.settleTime + ":00:00";
      // 填入 等級＆代理＆會員標籤
      this.ruleSetting.participateStatus.includeTags = [];
      this.ruleSetting.participateStatus.includeLevels = [];
      this.ruleSetting.participateStatus.includeAgents = [];
      if (this.memberTag.length > 0) {
        this.ruleSetting.participateStatus.includeTags = this.memberTag.map(
          e => {
            return e.id;
          }
        );
      }

      if (this.memberStar.length > 0) {
        this.ruleSetting.participateStatus.includeLevels = this.memberStar.map(
          e => {
            return e.star;
          }
        );
      }

      if (this.agentData.length > 0) {
        this.ruleSetting.participateStatus.includeAgents = this.agentData.map(
          e => {
            return e.id;
          }
        );
      }
      this.formModel.ruleSetting = JSON.stringify(this.ruleSetting);

      Object.entries(this.formModel).forEach(([key, value]) => {
        if (value) this.formData.append(key, value);
      });

      let result = {};
      if (release) {
        await createMemberReferral(this.formModel).then(res => {
          result = res;
        });
      } else if (this.editData === undefined) {
        await createCampaignSetting(this.formData).then(res => {
          result = res;
        });
      } else if (this.isCampaign) {
        await updateMemberReferral(this.formModel).then(res => {
          result = res;
        });
      } else {
        await updateCampaignSetting(this.formData).then(res => {
          result = res;
        });
      }

      if (result) {
        this.$emit("toggle");
        this.$emit("search");
      }

      return;
    },
    addData(type, layer) {
      let obj = [
        {
          totalBetAmount: 0,
          rate: 0,
        },
      ];

      let rebateRatesList = [...this.ruleSetting.calculationRate.layerRates[layer].rebateRates];
      if (
        type === "+"
      ) {
        this.ruleSetting.calculationRate.layerRates[layer].rebateRates = rebateRatesList.concat(obj);
      } else if (
        type === "-" &&
        this.ruleSetting.calculationRate.layerRates[layer].rebateRates.length !== 1
      ) {
        this.ruleSetting.calculationRate.layerRates[layer].rebateRates.pop();
      } else {
        return
      }
    },
    addAchievements(type) {
      let obj = [
        {
          "condition": {
            "numberOfMembers": 1
          },
          "bonusAmount": 1,
          "bettingTurnover": 1
        },
      ];

      let rebateRatesList = [...this.ruleSetting.achievements.list];
      if (
        type === "+"
      ) {
        this.ruleSetting.achievements.list = rebateRatesList.concat(obj);
      } else if (
        type === "-" &&
        this.ruleSetting.achievements.list.length !== 1
      ) {
        this.ruleSetting.achievements.list.pop();
      } else {
        return
      }
    },
    disabledStart(date) {
      if (this.endTime) {
        return date > new Date(this.endTime + this.GLOBAL.startMinute)
      };
    },
    disabledEnd(date) {
      if (this.startTime) {
        return date < new Date(this.startTime + this.GLOBAL.startMinute)
      };
    },
    startTimeForOrin(date) {
      date = date.split("");
      date.splice(13);
      return date.join("");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.table-layers {
  width: 100%;
  display: flex;
  flex-direction: row;

  .div-layers {
    min-width: 45% !important;
    margin: 0 auto;
  }
}

.star-box {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

@media screen and (max-width: 768px) {
  .star-box {
    grid-template-columns: repeat(1, 100%);
  }
}

.days-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>