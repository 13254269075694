var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "form-box form-column shadow-sm px-3 py-4 bg-white rounded overview-table",
    },
    [
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c("TreeTable", {
            ref: "treeTable",
            attrs: { list: _vm.agentCommission.list, column: _vm.tableColumn },
            scopedSlots: _vm._u([
              {
                key: "column_gamesCategory",
                fn: function (props) {
                  return [
                    _vm._v(
                      _vm._s(
                        `${
                          props.gamesCategory
                            ? _vm.GLOBAL.i18nCheckName(
                                _vm.getGamesCategorys.find(
                                  (data) => data.id == props.gamesCategory
                                )
                              )
                            : _vm.$t(`${_vm.i18nPrefix}rebateGame.allGame`)
                        }`
                      )
                    ),
                  ]
                },
              },
              {
                key: "column_discountAmount",
                fn: function (props) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !props.gamesCategory || props.gamesCategory == 99999
                            ? props.discountAmount
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "column_childrenCommission",
                fn: function (props) {
                  return [
                    _vm._v(" " + _vm._s(props.childrenCommission) + " "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              props.gamesCategory &&
                              props.gamesCategory !== 99999,
                            expression:
                              "props.gamesCategory && props.gamesCategory !== 99999",
                          },
                        ],
                        staticClass: "rate",
                        class: { childrenRate: props.gamesCategory },
                      },
                      [_vm._v(_vm._s(props.childrenRate + "%"))]
                    ),
                  ]
                },
              },
              {
                key: "column_childrenCost",
                fn: function (props) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !props.gamesCategory || props.gamesCategory == 99999
                            ? props.childrenCost
                            : ""
                        ) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !props.gamesCategory,
                            expression: "!props.gamesCategory",
                          },
                        ],
                        staticClass: "rate",
                        class: { childrenRate: props.gamesCategory },
                      },
                      [_vm._v(_vm._s(props.childrenCostRate + "%"))]
                    ),
                  ]
                },
              },
              {
                key: "column_childrenSurplus",
                fn: function (props) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !props.gamesCategory || props.gamesCategory == 99999
                            ? props.childrenSurplus
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "column_selfCommission",
                fn: function (props) {
                  return [
                    _vm._v(" " + _vm._s(props.selfCommission) + " "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              props.gamesCategory &&
                              props.gamesCategory !== 99999,
                            expression:
                              "props.gamesCategory && props.gamesCategory !== 99999",
                          },
                        ],
                        staticClass: "rate",
                        class: { childrenRate: props.gamesCategory },
                      },
                      [_vm._v(_vm._s(props.selfRate + "%"))]
                    ),
                  ]
                },
              },
              {
                key: "column_selfCost",
                fn: function (props) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !props.gamesCategory || props.gamesCategory == 99999
                            ? props.selfCost
                            : ""
                        ) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !props.gamesCategory,
                            expression: "!props.gamesCategory",
                          },
                        ],
                        staticClass: "rate",
                        class: { childrenRate: props.gamesCategory },
                      },
                      [_vm._v(_vm._s(props.selfCostRate + "%"))]
                    ),
                  ]
                },
              },
              {
                key: "column_selfSurplus",
                fn: function (props) {
                  return [
                    _c("span", { staticClass: "rate" }, [
                      _vm._v(_vm._s(props.selfSurplus)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }