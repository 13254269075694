
export let searchModel = {
    account: undefined,
    bankGroupId: 0,
    startCreateTime: undefined,
    endCreateTime: undefined,
    referralCode: undefined,
    bankId: 0,
    bankAccountName: undefined,
    bankAccountNumber: undefined,
    agentId: 0,
    tagId: 0,
    phoneNumber: undefined,
    name: undefined,
    socialId: undefined,
    star: undefined,
    ip: undefined,
    email: undefined,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}