import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function paymentgatewayList() {
    return request({
        url: "/paymentgateway/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
    });
}

export function editPaymentgateway(data) {
    return request({
        url: "/paymentgateway/update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}