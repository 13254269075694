var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pagination-block" }, [
    _c("div", { staticClass: "count" }, [
      _vm._v(
        _vm._s(_vm.$t("pagination.total")) +
          " " +
          _vm._s(_vm.totalCount) +
          " " +
          _vm._s(_vm.$t("pagination.records"))
      ),
    ]),
    _c(
      "ul",
      [
        _c(
          "li",
          {
            class: { displayNone: _vm.getPage === 1 },
            on: { click: _vm.handlePage },
          },
          [_vm._v("««")]
        ),
        _c(
          "li",
          {
            class: { displayNone: _vm.getPage === 1 },
            on: { click: _vm.handlePage },
          },
          [_vm._v("«")]
        ),
        _vm.server
          ? _vm._l(_vm.pageCount, function (list) {
              return _c(
                "li",
                {
                  key: list,
                  class: { active: _vm.serverHandlePage === list },
                  on: {
                    click: function ($event) {
                      return _vm.handlePage(list)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.page.current) + " ")]
              )
            })
          : _vm._l(_vm.pageCount, function (list) {
              return _c(
                "li",
                {
                  key: list,
                  class: { active: _vm.getPage === list },
                  on: {
                    click: function ($event) {
                      return _vm.handlePage(list)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(list) + " ")]
              )
            }),
        _c(
          "li",
          {
            class: { displayNone: _vm.getPage === _vm.pageCount.length },
            on: { click: _vm.handlePage },
          },
          [_vm._v("»")]
        ),
        _c(
          "li",
          {
            class: { displayNone: _vm.getPage === _vm.pageCount.length },
            on: { click: _vm.handlePage },
          },
          [_vm._v("»»")]
        ),
      ],
      2
    ),
    _c("div", [
      _c("span", { staticClass: "mr-2" }, [
        _vm._v(_vm._s(_vm.$t("pagination.showRecordsPerPage"))),
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.showCount,
              expression: "showCount",
            },
          ],
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.showCount = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.changeShowCount,
            ],
          },
        },
        [
          _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
          _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
          _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
          _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
          _c("option", { attrs: { value: "200" } }, [_vm._v("200")]),
          _c("option", { attrs: { value: "all" } }, [_vm._v("ALL")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }