<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.agent") }}
            <select v-model="searchForm.agentId">
              <option v-for="list of getAgentListAddDefault" :key="list.id" :value="list.id">{{ $t(list.account) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="submitStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="submitEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeFrom") }}
            <date-picker v-model="auditStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeTo") }}
            <date-picker v-model="auditEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}bank`) }}
            <select v-model="searchForm.memberBankId">
              <option v-for="list of getBankListAddDefault" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t("default.tag") }}
            <select v-model="searchForm.tagId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of tagList" :key="list.id" :value="list.id * 1">{{ list.name }}</option>
            </select>
          </label>
        </div>
        <!-- <div class="condition-block">
          <multiselect v-model="auditStatuses" label="name" track-by="id" :placeholder="$t('default.plzSelect')"
            :custom-label="GLOBAL.i18nCheckName" :options="getAuditStatusList" :multiple="true" :searchable="false">
          </multiselect>
          <div class="button-block">
            <button @click="auditStatuses = [...getAuditStatusList]">{{ $t("default.selectAll") }}</button>
            <button @click="auditStatuses = []">{{ $t("default.clear") }}</button>
          </div>
        </div> -->
      </div>
      <div class="divider-h-2"></div>
      <MultiSelectBox :selectData.sync="auditStatuses" :list="getAuditStatusList" :multiple="true" />
      <div class="advance-block">
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalDataCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalMemberCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalMemberCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalAmount) }}</div>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.tag") }}</th>
              <th>{{ $t(`${i18nPrefix}memberBankInfo`) }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t(`${i18nPrefix}companyBankInfo`) }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="10">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>{{ list.submitTime }}</td>
              <td>{{ list.memberAccount }}</td>
              <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
              </td>
              <td>{{ list.memberBankInfo }}</td>
              <td>{{ list.amount }}</td>
              <td>{{ list.companyBankInfo }}</td>
              <td>
                {{
                  GLOBAL.i18nCheckName(getAuditStatusList.find(
                    (data) => data.id === list.auditStatus
                  ))
                }}
              </td>
              <td>{{ list.remark }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.auditTime }}</td>
            </tr>
            <tr v-show="allData && allData.length">
              <td colspan="3"></td>
              <td>{{ $t("default.total") }}</td>
              <td>{{ digital.format(TotalAmount) }}</td>
              <td colspan="5"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
  </div>
</template>

<script>
import {
  memberDepositReportATMList,
  getDepositReportTotal
} from "@/api/report/member-deposit";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import Tags from "@/layout/components/tags";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { transToday } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import { joinT } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";

export default {
  name: "MemberDepositReportATM",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      submitStartTime: transToday("from"),
      submitEndTime: transToday("to"),
      auditStartTime: undefined,
      auditEndTime: undefined,
      TotalAmount: 0,
      auditStatuses: [],
      total: {
        totalDataCount: 0,
        totalMemberCount: 0,
        totalAmount: 0
      },
      i18nPrefix: "report.member-deposit."
    };
  },
  components: {
    Tags,
    serverPagination,
    QuickSearch,
    Multiselect,
    MultiSelectBox
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getBankListAddDefault",
      "getAuditStatusList",
      "tagList",
      "getAgentListAddDefault"
    ])
  },
  created() {
    this.auditStatuses = [
      this.getAuditStatusList[0],
      this.getAuditStatusList[4]
    ];
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.submitStartTime = this.submitStartTime
        ? joinT(
          this.submitStartTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.submitEndTime = this.submitEndTime
        ? joinT(
          this.submitEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStartTime = this.auditStartTime
        ? joinT(
          this.auditStartTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditEndTime = this.auditEndTime
        ? joinT(
          this.auditEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStatuses = this.auditStatuses.map(
        data => data.id * 1
      );

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      memberDepositReportATMList(this.searchForm).then(res => {
        this.allData = res.data.records.list;
        this.TotalAmount = res.data.totalAmount;
        this.page = res.data.records.page;
      });
      getDepositReportTotal(this.searchForm).then(
        res => (this.total = res.data)
      );
    },
    changeTime(time) {
      this.submitStartTime = time.startTime;
      this.submitEndTime = time.endTime;
      this.search();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>