<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="wrap">
        <div class="section">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}submitAutoSettleAmount`) }}</h3>
            <button class="table-btn main-style-btn" @click="submitAutoSettleAmount" :disabled="!getUserinfo.permissions.includes(60806)">
              {{ $t("default.edit") }}
            </button>
          </div>
          <label>
            {{ $t(`${i18nPrefix}balance`) }}
            <input type="number" v-model.number="dataModel.autoSettleAmount" />
          </label>
        </div>
        <div class="section">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}memberWithdrawalPasswordFeatureToggle`) }}</h3>
            <div class="header-flex">
              <label>
                <div class="slide-block" style="margin: auto"
                  :class="{ 'slide-block-on': dataModel.memberWithdrawalPasswordFeatureToggle }">
                  <div class="slide" :class="{ 'slide-on': dataModel.memberWithdrawalPasswordFeatureToggle }">
                    <label for="memberWithdrawalPasswordFeatureToggle" style="display: none"></label>
                    <input type="checkbox" id="memberWithdrawalPasswordFeatureToggle" v-model="dataModel.memberWithdrawalPasswordFeatureToggle" style="display: none" />
                  </div>
                </div>
              </label>
              <button class="table-btn main-style-btn" @click="submitMemberWithdrawalPasswordFeatureToggle"
                :disabled="!getUserinfo.permissions.includes(60809)">
                {{ $t("default.edit") }}
              </button>
            </div>
          </div>
          <label class="pc-only">
            <div class="slide-block" style="margin: auto"
              :class="{ 'slide-block-on': dataModel.memberWithdrawalPasswordFeatureToggle }">
              <div class="slide" :class="{ 'slide-on': dataModel.memberWithdrawalPasswordFeatureToggle }">
                <label for="memberWithdrawalPasswordFeatureToggle" style="display: none"></label>
                <input type="checkbox" id="memberWithdrawalPasswordFeatureToggle" v-model="dataModel.memberWithdrawalPasswordFeatureToggle" style="display: none" />
              </div>
            </div>
          </label>
        </div>
        <div class="section DepositLimit">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}submitDepositLimit`) }}</h3>
            <button class="table-btn main-style-btn" @click="submitDepositLimit" :disabled="!getUserinfo.permissions.includes(60810)">
              {{ $t("default.edit") }}
            </button>
          </div>
          <label>
            {{ $t(`${i18nPrefix}depositLimitElapsedMinutes`) }}
            <input type="number" v-model.number="dataModel.depositLimitElapsedMinutes" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}depositLimitElapsedMinutesCount`) }}
            <input type="number" v-model.number="dataModel.depositLimitElapsedMinutesCount" />
          </label>
        </div>
      </div>
      <div class="header mt-5">
        <h5>{{ $t(`${i18nPrefix}other`) }}</h5>
      </div>
      <div class="wrap">
        <!-- <div class="section">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}submitWinLose`) }}</h3>
            <button
                class="table-btn main-style-btn"
                @click="submitWinLose"
                :disabled="!getUserinfo.permissions.includes(60801)"
            >
              {{ $t("default.edit") }}
            </button>
          </div>
          <div class="divider"></div>
          <label>
            {{ $t(`${i18nPrefix}maxWinLoseDaily`) }}
            <input type="text" v-model="dataModel.maxWinLoseDaily" />
          </label>
        </div> -->
        <div class="section">
          <div class="header">
            <h3>{{ $t(`${i18nPrefix}submitDepositMultiple`) }}</h3>
            <button class="table-btn main-style-btn" @click="submitDepositMultiple" :disabled="!getUserinfo.permissions.includes(60807)">
              {{ $t("default.edit") }}
            </button>
          </div>
          <label>
            {{ $t(`${i18nPrefix}depositMultiple`) }}
            <input type="number" v-model.number="dataModel.depositMultiple" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDepositWithdrawData,
  editWinLose,
  editAutoSettleAmount,
  editDepositMultiple,
  updateDepositLimit,
  updateWthdrawPassword
} from "@/api/features/deposit-withdraw-setting";
import { mapGetters } from 'vuex';

export default {
  name: "DepositWithdrawSetting",
  data() {
    return {
      dataModel: {
        maxWinLoseDaily: undefined,
        autoSettleAmount: undefined,
        depositMultiple: undefined,
        depositLimitElapsedMinutes: undefined,
        depositLimitElapsedMinutesCount: undefined,
        memberWithdrawalPasswordFeatureToggle: undefined
      },
      i18nPrefix: "features.deposit-withdraw-setting."
    };
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getDepositWithdrawData().then((res) => {
        this.dataModel = res.data;
      });
    },
    submitAutoSettleAmount() {
      const data = {
        autoSettleAmount: parseInt(this.dataModel.autoSettleAmount),
      };
      editAutoSettleAmount(data).then((res) => {
        if (res) this.init();
      });
    },
    submitWinLose() {
      const data = {
        maxWinLoseDaily: parseInt(this.dataModel.maxWinLoseDaily),
      };
      editWinLose(data).then((res) => {
        if (res) this.init();
      });
    },
    submitDepositMultiple() {
      const data = {
        depositMultiple: parseInt(this.dataModel.depositMultiple),
      };
      editDepositMultiple(data).then((res) => {
        if (res) this.init();
      });
    },
    submitDepositLimit() {
      const data = {
        depositLimitElapsedMinutes: parseInt(this.dataModel.depositLimitElapsedMinutes),
        depositLimitElapsedMinutesCount: parseInt(this.dataModel.depositLimitElapsedMinutesCount),
      };
      updateDepositLimit(data).then((res) => {
        if (res) this.init();
      });
    },
    submitMemberWithdrawalPasswordFeatureToggle() {
      const data = {
        memberWithdrawalPasswordFeatureToggle: this.dataModel.memberWithdrawalPasswordFeatureToggle,
      };
      updateWthdrawPassword(data).then((res) => {
        if (res) this.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 10px;
  justify-content: flex-start;
}

.section {
  display: inline-table;
  border-radius: 8px;
  border: 1.5px solid #7E96A3;
  background: #FFF;
  padding: 30px;

  label {
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin: 15px 15px;
    position: relative;

    span {
      position: absolute;
      color: rgb(255, 0, 0, 0.8) !important;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      bottom: -20px;
      animation: fadeDown 0.2s linear;
    }
  }

  .header {
    justify-content: space-between !important;
    align-items: center;

    h3 {
      margin-bottom: 0 !important;
    }
    button {
      white-space:nowrap;
    }
    .header-flex {
      display: flex;
      align-items: center;
      gap: 3px;
      label {
        margin-bottom: 0 !important;
        display: none;
      }
    }
  }
}

// .DepositLimit {
//   width: 50%;

//   label {
//     width: 100%;
//   }
// }
@media screen and (max-width: 768px) {
  .section {
    padding: 10px;
    .header {
      margin: 10px 0 !important;
      h3 {
        font-size: 20px;
      }
      .header-flex {
        label {
          display: block;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .wrap {
    grid-template-columns: repeat(1, 100%);
    flex-direction: column;

    .section {
      width: 100% !important;
      margin: 5px;
    }
  }
}
</style>