<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(this, 'Create')"
            :disabled="
              !getUserinfo.permissions.includes(99999) &&
              !getUserinfo.permissions.includes(10501)
            "
          >{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}name`) }}
            <input type="text" v-model="searchForm.Name" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}discountType`) }}
            <select v-model="searchForm.discountType">
              <option
                v-for="list of getDiscountTypeListAddDefault"
                :key="list.id"
                :value="list.id"
              >{{ $t(list.name) }}</option>
              <!-- <option value="13">{{ $t(`${i18nPrefix}memberClass`) }}</option> -->
            </select>
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.discountAuditStatus">
              <option
                v-for="list of getDiscountAuditStatusAddDefault"
                :key="list.id"
                :value="list.id"
              >{{ $t(list.name) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startCreateTime`) }}
            <date-picker
              v-model="startCreateTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endCreateTime`) }}
            <date-picker
              v-model="endCreateTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}auditStartTime`) }}
            <date-picker
              v-model="auditStartTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}auditEndTime`) }}
            <date-picker
              v-model="auditEndTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search()">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ total.totalCount }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalMemberCount") }}:</div>
          <div class="total-block-v2-item-right">{{ total.totalMemberCount }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ total.totalBonusReceive }}</div>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>
                <button
                  v-if="allData && allData.find((data) => data.discountAuditStatus === 1)"
                  class="normal-btn main-style-btn"
                  @click="handleDialog({ tableData : allData }, 'BatchEdit')"
                  :disabled="
                    !getUserinfo.permissions.includes(99999) &&
                    !getUserinfo.permissions.includes(10502)
                  "
                >{{ $t(`${i18nPrefix}batchEdit`) }}</button>
              </th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t(`${i18nPrefix}name`) }}</th>
              <th
                class="sort"
                @click="dataSort('createTime', sort)"
              >{{ $t(`${i18nPrefix}createTime`) }} {{ direction('createTime') }}</th>
              <th>{{ $t(`${i18nPrefix}settleDate`) }}</th>
              <th>{{ $t(`${i18nPrefix}discountType`) }}</th>
              <th>{{ $t("default.status") }}</th>
              <!-- <th>享有日期</th> -->
              <th>{{ $t(`${i18nPrefix}firstDepositAmount`) }}</th>
              <th>{{ $t(`${i18nPrefix}bonusReceive`) }}</th>
              <th>{{ $t(`${i18nPrefix}totalBetAmountLimit`) }}</th>
              <th>{{ $t(`${i18nPrefix}totalValidBetAmount`) }}</th>
              <th>{{ $t("default.processAccount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="16">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of allData" :key="list.id">
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog(list, 'Edit')"
                  :disabled="
                    !getUserinfo.permissions.includes(99999) &&
                    !getUserinfo.permissions.includes(10502)
                  "
                  v-show="list.discountAuditStatus === 1 && list.discountType !== 29 && list.discountType !== 30"
                >{{ list.discountAuditStatus === 1 ? $t("default.edit") : $t(`${i18nPrefix}editRemark`) }}</button>
              </td>
              <td>{{ list.memberAccount }}</td>
              <td>{{ list.remark}}</td>
              <td>{{ list.name }}</td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.auditTime }}</td>
              <td>
                {{
                GLOBAL.i18nCheckName(getDiscountTypeListAddDefault.find(
                (data) => data.id === list.discountType
                ))
                }}
              </td>
              <td>
                {{
                GLOBAL.i18nCheckName(getDiscountAuditStatusAddDefault.find(
                (data) => data.id === list.discountAuditStatus
                ))
                }}
              </td>
              <!-- <td>{{ list.startTime }}</td> -->
              <td>{{ list.firstDepositAmount }}</td>
              <td>{{ list.bonusReceive }}</td>
              <td>{{ list.totalBetAmountLimit }}</td>
              <td>{{ list.totalValidBetAmount }}</td>
              <td>
                <button
                  @click="getProcess(list.id)"
                  class="table-btn main-style-btn"
                >{{ $t("default.processAccount") }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination
        v-if="allData"
        :page="page"
        @ServerPageUtils="search"
        @changeTake="changeTake"
      />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :getDiscountTypeListAddDefault="getDiscountTypeListAddDefault"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @edit="edit"
        @create="create"
      ></component>
    </transition>
    <transition name="fade" mode="out-in">
      <Alert v-if="alertMsg" :message="alertMsg" setTime="500" @toggle="Cancel" @doIt="Confirm" />
    </transition>
    <Process
      v-if="processData"
      :processTitle="processTitle"
      :processData="processData"
      @toggle="processData = undefined"
    >
      <template v-slot:oldValue_DiscountAuditStatus="props">
        {{ GLOBAL.i18nCheckName(
        getDiscountAuditStatusAddDefault.find(
        (data) => props.DiscountAuditStatus === data.id
        )) }}
      </template>
      <template v-slot:newValue_DiscountAuditStatus="props">
        {{ GLOBAL.i18nCheckName(
        getDiscountAuditStatusAddDefault.find(
        (data) => props.DiscountAuditStatus === data.id
        )) }}
      </template>
    </Process>
  </div>
</template>

<script>
import {
  discountList,
  discountCreate,
  discountConfirm,
  discountUpdate,
  getTotal,
  getExcel,
  getMemberDiscountProcess
} from "@/api/member/member-discount";
import handleDialog from "@/layout/mixins/handleDialog";
import Edit from "./dialog/edit.vue";
import BatchEdit from "./dialog/batchEdit.vue";
import Create from "./dialog/create.vue";
import Alert from "@/layout/components/Alert";
import Process from "@/layout/components/Process";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import { checkData } from "@/utils/checkData";
import QuickSearch from "@/layout/components/QuickSearch";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import { download } from "@/utils/download";

export default {
  name: "MemberDiscount",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startCreateTime: undefined,
      endCreateTime: undefined,
      auditStartTime: undefined,
      auditEndTime: undefined,
      alertMsg: undefined,
      confirmId: undefined,
      total: {
        totalBonusReceive: 0,
        totalMemberCount: 0,
        totalCount: 0,
      },
      processData: undefined,
      i18nPrefix: "member.member-discount."
    };
  },
  components: {
    Edit,
    BatchEdit,
    serverPagination,
    Create,
    Process,
    QuickSearch,
    Alert
  },
  computed: {
    ...mapGetters([
      "getDiscountTypeListAddDefault",
      "getUserinfo",
      "getDiscountAuditStatusAddDefault"
    ]),
    processTitle() {
      return [
        {
          key: "DiscountAuditStatus",
          name: this.$t("default.status")
        },
        {
          key: "FirstDepositAmount",
          name: this.$t(`${this.i18nPrefix}firstDepositAmount`)
        },
        {
          key: "BonusReceive",
          name: this.$t(`${this.i18nPrefix}bonusReceive`)
        },
        {
          key: "TotalBetAmountLimit",
          name: this.$t(`${this.i18nPrefix}totalBetAmountLimit`)
        },
        {
          key: "Remark",
          name: this.$t("default.remark")
        }
      ];
    }
  },
  mixins: [handleServerPageUtils, handleDialog],
  created() {
    if (this.$route.query?.permissionId) {
      this.searchForm.discountAuditStatus = 1;
      this.search(true);
    }
  },
  methods: {
    async search(page = false) {
      this.currDialog = undefined;
      this.searchForm.createStartTime = this.startCreateTime
        ? this.startCreateTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
        : undefined;
      this.searchForm.createEndTime = this.endCreateTime
        ? this.endCreateTime + this.GLOBAL.endHour + this.GLOBAL.timeZone
        : undefined;
      this.searchForm.auditStartTime = this.auditStartTime
        ? this.auditStartTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
        : undefined;
      this.searchForm.auditEndTime = this.auditEndTime
        ? this.auditEndTime + this.GLOBAL.endHour + this.GLOBAL.timeZone
        : undefined;
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      if (checkData(this.searchForm)) {
        discountList(this.searchForm).then(res => {
          this.allData = res.data.list;
          this.page = res.data.page;
        });
      }
      getTotal(this.searchForm).then(
        res => this.total = res.data
      );
    },
    edit(data) {
      discountUpdate(data).then(res => {
        if (res) this.init();
      });
    },
    create(data) {
      discountCreate(data).then(res => {
        this.currDialog = undefined;
        this.searchForm = _.cloneDeep(searchModel);
        if (res?.data.status === 1) {
          this.confirmId = res.data.confirmId;
          this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`, {
            input: res.data.members.join(",")
          });
          return;
        }
        if (res) this.init();
      });
    },
    Confirm(data = true) {
      this.alertMsg = undefined;
      let obj = {
        confirmId: this.confirmId,
        isContinue: data
      };
      discountConfirm(obj).then(res => {
        if (res) this.init();
      });
    },
    getProcess(id) {
      getMemberDiscountProcess({ id }).then(res => {
        this.processData = res.data;
      });
    },
    Cancel() {
      this.alertMsg = undefined;
      this.Confirm(false);
    },
    init() {
      this.RESET_DIALOG();
      if (checkData(this.searchForm, false, true)) this.search();
    },
    changeTime(time) {
      this.startCreateTime = time.startTime.substring(0, 10);
      this.endCreateTime = time.endTime.substring(0, 10);
      this.search();
    },
    download() {
      getExcel(this.searchForm).then(res =>
        download(res, this.startCreateTime + "MemberDiscount.xlsx")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.sort {
  cursor: pointer;
}
</style>