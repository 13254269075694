import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu() {
    return request({
        url: "/CampaignMemberSignIn/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteCampaignSetting(data) {
    return request({
        url: "/CampaignSetting/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getMemberSignInList(data) {
    return request({
        url: "/CampaignMemberSignIn/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMemberSignIn(data) {
    return request({
        url: "/CampaignMemberSignIn/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMemberSignIn(data) {
    return request({
        url: "/CampaignMemberSignIn/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSignInRecordList(data) {
    return request({
        url: "/CampaignMemberSignIn/SignInRecord",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}