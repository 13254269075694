var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container edit-status" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
            _c(
              "button",
              {
                staticClass: "confirm-btn main-style-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("updateRemark", _vm.member)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.confirm")))]
            ),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("label", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.member.remark,
                  expression: "member.remark",
                },
              ],
              domProps: { value: _vm.member.remark },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.member, "remark", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }