import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function quotaHistoryList(data) {
    return request({
        url: "/memberMainWalletActionRecord/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDetail(data) {
    return request({
        url: "/memberMainWalletActionRecord/getDetail",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getTransactionType() {
    return request({
        url: "/memberMainWalletActionRecord/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}