var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c(
            "button",
            {
              staticClass: "create-btn main-style-btn",
              attrs: {
                disabled:
                  !_vm.$store.getters.getUserinfo.permissions.includes(61701),
              },
              on: { click: _vm.create },
            },
            [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}create`)))]
          ),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createForm.name,
                    expression: "createForm.name",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.createForm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.createForm, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}fontColor`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createForm.fontColor,
                    expression: "createForm.fontColor",
                  },
                ],
                staticClass: "color",
                attrs: { type: "color" },
                domProps: { value: _vm.createForm.fontColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.createForm, "fontColor", $event.target.value)
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}backgroundColor`)) + " "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createForm.backgroundColor,
                    expression: "createForm.backgroundColor",
                  },
                ],
                staticClass: "color",
                attrs: { type: "color" },
                domProps: { value: _vm.createForm.backgroundColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.createForm,
                      "backgroundColor",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}isBoldFont`)) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createForm.isBoldFont,
                    expression: "createForm.isBoldFont",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.createForm.isBoldFont)
                    ? _vm._i(_vm.createForm.isBoldFont, null) > -1
                    : _vm.createForm.isBoldFont,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.createForm.isBoldFont,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.createForm,
                            "isBoldFont",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.createForm,
                            "isBoldFont",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.createForm, "isBoldFont", $$c)
                    }
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}name`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}fontColor`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}backgroundColor`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}isBoldFont`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.tableList || _vm.tableList.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "6" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.$store.getters.getUserinfo.permissions.includes(
                                  61702
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(list, "edit")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.edit")))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.name))]),
                      _c("td", [
                        _c("div", {
                          staticClass: "tdColor",
                          style: { backgroundColor: list.fontColor },
                        }),
                      ]),
                      _c("td", [
                        _c("div", {
                          staticClass: "tdColor",
                          style: { backgroundColor: list.backgroundColor },
                        }),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            list.isBoldFont
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  update: _vm.update,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }