<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(62301)">
            {{ $t("default.create") }}
          </button>
        </div>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t(`${i18nPrefix}walletName`) }}</th>
                <th>{{ $t("default.currencyName") }}</th>
                <th>{{ $t("default.exchangeRate") }}</th>
                <th>{{ $t("default.status") }}</th>
                <th>{{ $t("default.processAccount") }}</th>
                <th>{{ $t("default.processTime") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button class="table-btn main-style-btn" @click="handleDialog({ data: list }, 'Edit')" :disabled="!getUserinfo.permissions.includes(62302)
                    ">{{ $t("default.edit") }}</button>
                </td>
                <td>{{ list.walletName }}</td>
                <td>{{ list.currencyName }}</td>
                <td>{{ list.exchangeRate }}</td>
                <td>{{ list.status === 1 ? $t("default.active") : $t("default.inactive") }}</td>
                <td>
                  <button @click="getProcess(list.id)" class="table-btn main-style-btn">{{ $t("default.processAccount") }}</button>
                </td>
                <td>{{ list.updateTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
      </main>
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" :currency="currency" :wallet="wallet" v-bind="dialogData" :i18nPrefix="i18nPrefix" @toggle="currDialog = undefined"
        @edit="edit" @create="create"></component>
    </transition>
    <Process v-if="processData" :processTitle="processTitle" :processData="processData" @toggle="processData = undefined">
      <template v-slot:oldValue_Status="props">{{ props.Status === 1 ? $t("default.active") : $t("default.inactive")
      }}</template>
      <template v-slot:newValue_Status="props">{{ props.Status === 1 ? $t("default.active") : $t("default.inactive")
      }}</template>
    </Process>
  </div>
</template>

<script>
import {
  getCryptoWithdrawalSettingList,
  createCryptoWithdrawalSetting,
  editCryptoWithdrawalSetting,
  getCryptoWithdrawalSettingProcess,
  getSelectMenu
} from "@/api/features/cryptoWithdrawalSetting";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Process from "@/layout/components/Process";
import { mapGetters } from "vuex";

export default {
  name: "CryptoWithdrawalSetting",
  data() {
    return {
      currency: [],
      wallet: [],
      processData: undefined,
      i18nPrefix: "features.cryptoWithdrawalSetting."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  components: {
    Create,
    Edit,
    Process,
    Pagination
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
    processTitle() {
      return [
        {
          key: "ExchangeRate",
          name: this.$t("default.exchangeRate")
        },
        {
          key: "Status",
          name: this.$t("default.status")
        }
      ];
    }
  },
  created() {
    this.getList();
    getSelectMenu().then(res => {
      this.currency = res.data.currency;
      this.wallet = res.data.wallet;
    });
  },
  methods: {
    getList() {
      getCryptoWithdrawalSettingList({}).then(res => {
        this.allData = res.data;
        this.RESET_DIALOG();
      });
    },
    create(data) {
      createCryptoWithdrawalSetting(data).then(res => {
        if (res) this.getList();
      });
    },
    edit(data) {
      editCryptoWithdrawalSetting(data).then(res => {
        if (res) this.getList();
      });
    },
    getProcess(id) {
      getCryptoWithdrawalSettingProcess({ id }).then(res => {
        this.processData = res.data;
      });
    },
  }
};
</script>

<style lang="scss" scoped></style>