export let searchModel = {
    submitStartTime: undefined,
    submitEndTime: undefined,
    auditStartTime: undefined,
    auditEndTime: undefined,
    memberAccount: undefined,
    agentId: 0,
    auditAccountId: 0,
    auditStatus: 0,
    tagId: 0,
    paymentName: '',
    paymentOrderNumber: undefined,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
}