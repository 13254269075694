var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t(`${_vm.i18nPrefix}memberLevel`) +
                    _vm.$t("default.edit")
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}memberLevel`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.star,
                      expression: "editModel.star",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.star },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "star", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label"),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.fromAmount`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.fromAmount,
                      expression: "editModel.fromAmount",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.fromAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "fromAmount", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.toAmount`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.toAmount,
                      expression: "editModel.toAmount",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.toAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "toAmount", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}liveCasinoRate`)) +
                    " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.liveCasinoRate,
                      expression: "editModel.liveCasinoRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.liveCasinoRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "liveCasinoRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}slotRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.slotRate,
                      expression: "editModel.slotRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.slotRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "slotRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}fishingRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.fishingRate,
                      expression: "editModel.fishingRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.fishingRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "fishingRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}lotteryRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.lotteryRate,
                      expression: "editModel.lotteryRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.lotteryRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "lotteryRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}sportRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.sportRate,
                      expression: "editModel.sportRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.sportRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "sportRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}eSportRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.eSportRate,
                      expression: "editModel.eSportRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.eSportRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "eSportRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}cardGameRate`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.cardGameRate,
                      expression: "editModel.cardGameRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.cardGameRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "cardGameRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}cockFighting`)) + " % "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.cockFightingRate,
                      expression: "editModel.cockFightingRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.cockFightingRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "cockFightingRate",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}arcade`)) + " % "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.arcadeRate,
                      expression: "editModel.arcadeRate",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.arcadeRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "arcadeRate", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.promotionBonus,
                      expression: "editModel.promotionBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.promotionBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "promotionBonus",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.edit } },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }