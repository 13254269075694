var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c(
          "div",
          { staticClass: "dialog-block" },
          [
            _c("div", { staticClass: "header" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editTag`)))]),
            ]),
            _c("div", { staticClass: "divider" }),
            _c("h5", [
              _vm._v(
                _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.editTag.optionalTag`))
              ),
            ]),
            _vm._l(_vm.tagList, function (tag) {
              return [
                _c(
                  "span",
                  {
                    style: {
                      color: tag.fontColor,
                      "background-color": tag.backgroundColor,
                      "font-weight": tag.isBoldFont ? "bolder" : "normal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("editTag", {
                          type: "add",
                          data: { memberId: _vm.id, tagId: tag.id },
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(tag.name) + " ")]
                ),
              ]
            }),
            _vm.haveTag
              ? _c("h5", { staticStyle: { "margin-top": "20px" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`${_vm.i18nPrefix}dialog.editTag.selectedTag`)
                    )
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.haveTag, function (tag) {
              return [
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.Tags.find((data) => data.id === tag).fontColor,
                      "background-color": _vm.Tags.find(
                        (data) => data.id === tag
                      ).backgroundColor,
                      "font-weight": _vm.Tags.find((data) => data.id === tag)
                        .isBoldFont
                        ? "bolder"
                        : "normal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("editTag", {
                          type: "delete",
                          data: { memberId: _vm.id, tagId: tag },
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.Tags.find((data) => data.id === tag).name) +
                        " "
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }