<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}sendMail`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="messageInfo">
              <div>
                {{ $t(`${i18nPrefix}messageType`) }}
                <select v-model="messageType">
                  <option
                      v-for="list of messageTypeList"
                      :key="list.id"
                      :value="list.id"
                  >
                    {{ $t(list.name) }}
                  </option>
                  <!-- <option value="0">{{ $t("default.all") }}</option> -->
                </select>
              </div>
              <label>
              {{ $t("default.member") }}
              <select v-model="memberModel.selectMembers.type">
                <option v-for="list of member" :key="list.id" :value="list.id">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              <select
                v-show="memberModel.selectMembers.type === 2"
                v-model="memberModel.selectMembers.agentid"
              >
                <option
                  v-for="list of agentList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.account }}
                </option>
              </select>
              <textarea
                v-show="memberModel.selectMembers.type === 3"
                v-model="members"
                rows="5" 
                :placeholder="$t(`${i18nPrefix}dialog.placeholder`)"
              ></textarea>
            </label>
            </div>
            <div class="message" v-show="messageType !== 2">
              <label>
                {{ $t(`${i18nPrefix}dialog.mailTemplate`) }}
                <select v-model="memberType">
                  <option
                      v-for="list of memberTemplateList"
                      :key="list.type"
                      :value="list.type"
                  >
                    {{ list.title }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.mailTitle`) }}
                <input type="text" v-model="memberModel.title">
              </label>
              <label class="remark">
                {{ $t(`${i18nPrefix}dialog.mailMessage`) }}
                <textarea v-model="memberModel.message" rows="12"></textarea>
              </label>
            </div>
            <div class="message" v-show="messageType !== 1">
              <label>
                {{ $t(`${i18nPrefix}dialog.snsTemplate`) }}
                <select v-model="smsType">
                  <option
                      v-for="list of smsTemplateList"
                      :key="list.type"
                      :value="list.type"
                  >
                    {{ list.title }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.snsTitle`) }}
                <input type="text" v-model="smsModel.title">
              </label>
              <label class="remark">
                {{ $t(`${i18nPrefix}dialog.snsMessage`) }}
                <textarea v-model="smsModel.message" rows="12"></textarea>
              </label>
            </div>
            </div>
          <div class="advance-block">
            <button class="normal-btn" @click="createMessage">
              {{ $t(`${i18nPrefix}dialog.submit`) }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTemplateList,
  createMemberMessage,
  createSmsMessage,
} from "@/api/features/memberPrivateMessage";
import Message from "@/layout/components/Message";

export default {
  name: "Create",
  props: ['messageTypeList','agentList','i18nPrefix'],
  data() {
    return {
      memberModel: {
        selectMembers: {
          type: 3,
          agentid: undefined,
          members: [],
        },
        title: undefined,
        message: undefined,
      },
      smsModel: {
        title: undefined,
        message: undefined,
      },
      messageType: 1,
      memberTemplateList: [],
      smsTemplateList: [],
      memberType: undefined,
      smsType: undefined,
      members: [],
      member: [
        {
          id: 1,
          name: `${this.i18nPrefix}dialog.allMember`,
        },
        {
          id: 2,
          name: "default.agent",
        },
        {
          id: 3,
          name: `${this.i18nPrefix}dialog.selfFill`,
        },
      ],
    };
  },
  watch: {
    memberType() {
      this.memberModel.title = this.memberTemplateList.find(data => data.type === this.memberType)?.title;
      this.memberModel.message = this.memberTemplateList.find(data => data.type === this.memberType)?.message;
    },
    smsType() {
      this.smsModel.title = this.smsTemplateList.find(data => data.type === this.smsType)?.title;
      this.smsModel.message = this.smsTemplateList.find(data => data.type === this.smsType)?.message.replace(/\\n/ig, '\n')
    },
  },
  created() {
    this.memberModel.selectMembers.agentid = this.agentList[0].id;
    getTemplateList().then((res) => {
      this.memberTemplateList = res.data.memberTemplateList;
      this.smsTemplateList = res.data.smsTemplateList;
      this.memberType = this.memberTemplateList[0]?.type;
      this.smsType = this.smsTemplateList[0]?.type;
    });
  },
  methods: {
    async createMessage() {
      if ( this.memberModel.selectMembers.type === 1 ) {
        this.memberModel.selectMembers.agentid = undefined;
        this.memberModel.selectMembers.members = [];
      }else if ( this.memberModel.selectMembers.type === 2 ) {
        this.memberModel.selectMembers.members = [];
      }else {
        this.memberModel.selectMembers.agentid = undefined;
        this.memberModel.selectMembers.members = this.members.split("#");
      }
      if(this.messageType !== 2) {
         await createMemberMessage(this.memberModel).then((res) => {
        });
      }
      if(this.messageType !== 1) {
        await createSmsMessage(this.smsModel).then((res) => {
        });
      }
      this.$emit('toggle');
    },
 }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 80%;
  .input-block {
    .messageInfo {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 40%;
      }
      label {
        margin: auto;
        width: 40%;
      }
    }
    .message {
      background-color: #fafafa;
      border-radius: 10px;
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 3px 1px 1px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 50%);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 10px;
      label {
        width: 40%;
      }
      .remark {
        width: 90%;
      }
    }
  }
}

</style>