<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmark"
          :key="list.id" @click="chooice = list.id" :class="{ 'chooice': chooice == list.id }">{{ $t(list.name) }}</div>
      </div>
    </div>
    <component :is="chooice"></component>
  </div>
</template>

<script>
import Create from "./create";
import Search from "./search";

export default {
  name: "index",
  data() {
    return {
      i18nPrefix: "features.batchCreateMember.",
      chooice: "Create",
      bookmark: [
        {
          id: "Create",
          name: "default.create",
        },
        {
          id: "Search",
          name: "default.search",
        }
      ]
    };
  },
  components: {
    Create,
    Search
  },
  computed: {
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>