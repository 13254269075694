import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getAgentList() {
    return request({
        url: "/seo/page/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createSEOBlogCategory(data) {
    return request({
        url: "/seo/blog/createSeoBlogCategory",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editSEOBlogCategory(data) {
    return request({
        url: "/seo/blog/editSeoBlogCategory",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSEOBlogCategoryList(data) {
    return request({
        url: "/seo/blog/seoBlogCategoryList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteSEOBlogCategory(data) {
    return request({
        url: "/seo/blog/deleteSeoBlogCategory",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSEOBlogList(data) {
    return request({
        url: "/seo/blog/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createSEOBlog(data) {
    return request({
        url: "/seo/blog/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editSEOBlog(data) {
    return request({
        url: "/seo/blog/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteSEOBlog(data) {
    return request({
        url: "/seo/blog/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSEOGameList() {
    return request({
        url: "/seo/blog/Game/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSEOGameBlogList(data) {
    return request({
        url: "/seo/blog/Game/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createSEOGameBlog(data) {
    return request({
        url: "/seo/blog/Game/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editSEOGameBlog(data) {
    return request({
        url: "/seo/blog/Game/edit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteSEOGameBlog(data) {
    return request({
        url: "/seo/blog/Game/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
