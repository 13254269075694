<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t('Permission.' + $route.meta.permissionName) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}searchType`) }}
            <select v-model="searchType">
              <option v-for="list of searchList" :key="list.id" :value="list.id">{{ list.name }}</option>
            </select>
          </label>
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="memberAccount" />
          </label>
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.status">
              <option :value="undefined">{{ $t("default.all") }}</option>
              <option v-for="list of statusList" :key="list.id" :value="list.id">{{ list.name }}</option>
            </select>
          </label>
          <label>
          </label>
          <label>
            {{ $t("default.startTime") }}
            <date-picker v-model="startCreateTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker v-model="endCreateTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeFrom") }}
            <date-picker v-model="startAuditTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeTo") }}
            <date-picker v-model="endAuditTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
        </div>
        <div class="divider-h-2"></div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t(`${i18nPrefix}firstName`) }}</th>
              <th>{{ $t(`${i18nPrefix}lastName`) }}</th>
              <th>{{ $t(`${i18nPrefix}katakanaFirstName`) }}</th>
              <th>{{ $t(`${i18nPrefix}katakanaLastName`) }}</th>
              <th>{{ $t(`${i18nPrefix}birthday`) }}</th>
              <th>{{ $t(`${i18nPrefix}photo`) }}</th>
              <th>{{ $t("default.createTime") }}</th>
              <th>{{ $t("default.processTime") }}</th>
              <th>{{ $t("default.processAccount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="10">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="list.id">
              <td>
                <button v-if="list.status != 3" @click="handleDialog(list, 'audit')" class="table-btn main-style-btn"
                  :disabled="!getUserinfo.permissions.includes(13002)">{{ list.status == 2 ? $t("default.edit") : $t("default.audit") }}</button>
              </td>
              <td>
                {{
        GLOBAL.i18nCheckName(
          statusList.find(
            (data) => list.status === data.id
          ))
      }}
              </td>
              <td>{{ list.remark }}</td>
              <td>{{ list.memberAccount }}</td>
              <td>{{ list.firstName }}</td>
              <td>{{ list.lastName }}</td>
              <td>{{ list.katakanaFirstName }}</td>
              <td>{{ list.katakanaLastName }}</td>
              <td>{{ list.birthday }}</td>
              <td class="url-td">
                <span v-show="list.imageFront" @click="handleDialog({ image: list.imageFront }, 'Photo')"
                  :title="list.imageFront">正面</span>
                <br>
                <span v-show="list.imageBack" @click="handleDialog({ image: list.imageBack }, 'Photo')"
                  :title="list.imageBack">反面</span>
              </td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.updateTime }}</td>
              <td>
                <button @click="getProcess(list.id)" class="table-btn main-style-btn">{{ $t("default.processAccount")
                  }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>

    <transition name="fade" mode="out-in">
      <component :is="currDialog" :i18nPrefix="i18nPrefix" v-bind="dialogData" @toggle="currDialog = undefined"
        @audit="audit"></component>
    </transition>
    <Process v-if="processData" :processTitle="processTitle" :processData="processData"
      @toggle="processData = undefined">
      <template v-slot:oldValue_Status="props">
        {{ GLOBAL.i18nCheckName(
        statusList.find(
          (data) => props.Status === data.id
        )) }}
      </template>

      <template v-slot:newValue_Status="props">
        {{ GLOBAL.i18nCheckName(
        statusList.find(
          (data) => props.Status === data.id
        )) }}
      </template>
    </Process>
  </div>
</template>

<script>
import { searchModel } from "./model";
import {
  getKycList,
  auditKyc,
  getKycExcel,
  getKycProcess
} from "@/api/member/Kyc";
import { mapGetters } from "vuex";
import Audit from "./dialog/audit";
import Process from "@/layout/components/Process";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import QuickSearch from "@/layout/components/QuickSearch";
import { joinT } from "@/utils/transDateUtils";
import { download } from "@/utils/download";
import Photo from "@/layout/components/photo";

export default {
  name: "Kyc",
  components: {
    Audit,
    Photo,
    Process,
    serverPagination,
    QuickSearch,
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startCreateTime: undefined,
      endCreateTime: undefined,
      startAuditTime: undefined,
      endAuditTime: undefined,
      processData: undefined,
      searchType: 0,
      memberAccount: undefined,
      i18nPrefix: "member.Kyc."
    };
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getAuditStatusList",
      "getUserinfo",
    ]),
    processTitle() {
      return [
        {
          key: "Status",
          name: this.$t("default.status")
        },
      ];
    },
    searchList() {
      return [
        {
          id: 0,
          name: "會員帳號"
        },
        {
          id: 1,
          name: "真實姓名"
        },
        {
          id: 2,
          name: "片假名"
        }
      ]
    },
    statusList() {
      return [
        {
          id: 1,
          name: this.$t("KycStatus.Processing")
        },
        {
          id: 2,
          name: this.$t("KycStatus.Approve")
        },
        {
          id: 3,
          name: this.$t("KycStatus.Reject")
        }
      ]
    },
  },
  created() {
    this.search();
  },
  methods: {
    async search(page = false) {
      this.searchForm.startCreateTime = this.startCreateTime
        ? joinT(
          this.startCreateTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.endCreateTime = this.endCreateTime
        ? joinT(
          this.endCreateTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.startAuditTime = this.startAuditTime
        ? joinT(
          this.startAuditTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.endAuditTime = this.endAuditTime
        ? joinT(
          this.endAuditTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;

      this.searchForm.memberAccount = (this.searchType == 0 ? this.memberAccount : undefined)
      this.searchForm.memberName = (this.searchType == 1 ? this.memberAccount : undefined)
      this.searchForm.katakana = (this.searchType == 2 ? this.memberAccount : undefined)

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      await getKycList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page
        this.closeDialog();
      });
    },
    audit(data) {
      auditKyc(data).then(res => {
        if (res) this.search();
      });
    },
    getProcess(id) {
      getKycProcess({ id }).then(res => {
        this.processData = res.data;
      });
    },
    changeTime(time) {
      this.startCreateTime = time.startTime;
      this.endCreateTime = time.endTime;
      this.search();
    },
    download() {
      getKycExcel(this.searchForm).then(res =>
        download(res, this.startCreateTime + "KycExcel.xlsx")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.copyBtn {
  font-size: 1rem !important;
}

.copyBtn {
  transition: 0.5s;

  &:hover {
    color: #c700c7;
  }
}

.copyed {
  color: #c700c7;
}
</style>