var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}wallet`)))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", { staticClass: "mt-3" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.gamePlatform`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.memberAccount`))
                      ),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}wallet`))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    !_vm.tableList || !_vm.tableList.length
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(_vm._s(_vm.$t("default.noData"))),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tableList && _vm.tableList.length,
                            expression: "tableList && tableList.length",
                          },
                        ],
                      },
                      [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.wallet`))
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.memberAccount))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              new Intl.NumberFormat().format(_vm.mainBlance)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._l(_vm.tableList, function (list, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.GLOBAL.i18nCheckName(
                                  _vm.getGamePlatforms.find(
                                    (data) => data.id === list.gamePlatformId
                                  )
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.customGameAccount))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              list.isMaintain
                                ? "X"
                                : new Intl.NumberFormat().format(list.balance)
                            )
                          ),
                        ]),
                      ])
                    }),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tableList.length,
                            expression: "tableList.length",
                          },
                        ],
                      },
                      [
                        _c("td"),
                        _c("td", [_vm._v(_vm._s(_vm.$t("default.total")))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              new Intl.NumberFormat().format(
                                _vm.totalGameBalance + _vm.mainBlance
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }