var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "loadingBackground",
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("figure", [
      _c("div", { staticClass: "dot white" }),
      _c("div", { staticClass: "dot" }),
      _c("div", { staticClass: "dot" }),
      _c("div", { staticClass: "dot" }),
      _c("div", { staticClass: "dot" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }