<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="getList">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.status") }}
            <select v-model="searchForm.status">
              <option
                v-for="list of getStatusListAddDefault"
                :key="list.id"
                :value="list.id"
              >{{ $t(list.name) }}</option>
            </select>
          </label>
        </div>
      </div>
      <div class="advance-block">
        <button class="search-pc-btn main-style-btn px-5" @click="getList">{{ $t("default.search") }}</button>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}gateway`) }}</th>
              <th>{{ $t("features.payment-gateway.paymentCompanyInfo") }}</th>
              <th>{{ $t(`${i18nPrefix}paymentCompanyInfo`) }}</th>
              <th>{{ $t(`${i18nPrefix}subName`) }}</th>
              <th></th>
              <th>{{ $t(`${i18nPrefix}oldMemberMinDeposit`) }}</th>
              <th>{{ $t(`${i18nPrefix}oldMemberMaxDeposit`) }}</th>
              <th>{{ $t(`${i18nPrefix}newMemberMinDeposit`) }}</th>
              <th>{{ $t(`${i18nPrefix}newMemberMaxDeposit`) }}</th>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t(`${i18nPrefix}updateUser`) }}</th>
              <th>{{ $t(`${i18nPrefix}updateTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list of tableList" :key="list.id">
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog(list, 'Edit')"
                  :disabled="!getUserinfo.permissions.includes(61801)"
                >{{ $t("default.edit") }}</button>
              </td>
              <td>{{ $t("DepositTypeList." + list.gateway) }}</td>
              <td>{{ getName(list.paymentCompanyInfoId) }}</td>
              <td>{{ GLOBAL.i18nCheckName(paymentCompanyInfo.find(data => data.id === list.paymentCompanyInfoId)) }}</td>
              <td>{{ list.subName }}</td>
              <td>
                <button
                  class="table-btn main-style-btn"
                  @click="handleDialog(list, 'bank')"
                  :disabled="!getUserinfo.permissions.includes(61802)"
                >{{ $t("default.setting") }}</button>
              </td>
              <td>{{ list.oldMemberMinDeposit }}</td>
              <td>{{ list.oldMemberMaxDeposit }}</td>
              <td>{{ list.newMemberMinDeposit }}</td>
              <td>{{ list.newMemberMaxDeposit }}</td>
              <td>{{ list.status === 1 ? $t("default.yes") : $t("default.no") }}</td>
              <td>{{ list.updateUser }}</td>
              <td>{{ list.updateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :paymentCompanyInfo="paymentCompanyInfo"
        :companyDepositSettingsSub="companyDepositSettingsSub"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @edit="edit"
      ></component>
    </transition>
  </div>
</template>

<script>
import {
  selectMenu,
  getCompanyDepositSettingsList,
  editCompanyDepositSettings
} from "@/api/features/companyDepositSettings";
import Edit from "./dialog/edit";
import Bank from "./dialog/bank";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { mapGetters } from "vuex";
import { searchModel } from "./model";

export default {
  name: "CompanyDepositSettings",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      paymentCompanyInfo: [],
      companyDepositSettingsSub: [],
      i18nPrefix: "features.companyDepositSettings."
    };
  },
  components: {
    Edit,
    Bank,
    Pagination
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo", "getStatusListAddDefault"])
  },
  async created() {
    await this.getList();
    await selectMenu().then(res => {
      this.paymentCompanyInfo = res.data.paymentCompanyInfo;
      this.companyDepositSettingsSub = res.data.companyDepositSettingsSub;
    });
  },
  methods: {
    getList() {
      getCompanyDepositSettingsList(this.searchForm).then(res => {
        this.allData = res.data;
        this.RESET_DIALOG();
        this.dialogData = undefined;
      });
    },
    edit(data) {
      editCompanyDepositSettings(data).then(res => {
        if (res) this.getList();
      });
    },
    getName(id) {
      return this.paymentCompanyInfo.find(data => data.id === id)?.nickname;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>