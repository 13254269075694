import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function selectMenu(data) {
    return request({
        url: "/member/withdrawal/selectMenu",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function withdrawList(data) {
    return request({
        url: "/member/withdrawal/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditWithdraw(data) {
    return request({
        url: "/member/withdrawal/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditPayment(data) {
    return request({
        url: "/member/withdrawal/auditPayment",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createWithdraw(data) {
    return request({
        url: "/member/withdrawal/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getMemberBankInfoList(data) {
    return request({
        url: "/member/withdrawal/getMemberBankInfoList",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getWithdrawTotal(data) {
    return request({
        url: "/member/withdrawal/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getWithdrawalProcess(data) {
    return request({
        url: "/member/withdrawal/Process",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}