<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}memberLevel`) + $t("default.edit") }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}memberLevel`) }}
              <input type="number" v-model="editModel.star" />
            </label>
            <label> </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.fromAmount`) }}
              <input type="number" v-model="editModel.fromAmount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.toAmount`) }}
              <input type="number" v-model="editModel.toAmount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}liveCasinoRate`) }} %
              <input type="number" v-model="editModel.liveCasinoRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}slotRate`) }} %
              <input type="number" v-model="editModel.slotRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}fishingRate`) }} %
              <input type="number" v-model="editModel.fishingRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}lotteryRate`) }} %
              <input type="number" v-model="editModel.lotteryRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}sportRate`) }} %
              <input type="number" v-model="editModel.sportRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}eSportRate`) }} %
              <input type="number" v-model="editModel.eSportRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}cardGameRate`) }} %
              <input type="number" v-model="editModel.cardGameRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}cockFighting`) }} %
              <input type="number" v-model="editModel.cockFightingRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}arcade`) }} %
              <input type="number" v-model="editModel.arcadeRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}promotionBonus`) }}
              <input type="number" v-model="editModel.promotionBonus" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";

export default {
  name: "Edit",
  props:[
    "id",
    "star",
    "fromAmount",
    "toAmount",
    "liveCasinoRate",
    "slotRate",
    "fishingRate",
    "lotteryRate",
    "sportRate",
    "eSportRate",
    "cardGameRate",
    "cockFightingRate",
    "arcadeRate",
    "promotionBonus",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        star: this.star,
        fromAmount: this.fromAmount,
        toAmount: this.toAmount,
        liveCasinoRate: this.liveCasinoRate,
        slotRate: this.slotRate,
        fishingRate: this.fishingRate,
        lotteryRate: this.lotteryRate,
        sportRate: this.sportRate,
        eSportRate: this.eSportRate,
        cardGameRate: this.cardGameRate,
        cockFightingRate: this.cockFightingRate,
        arcadeRate: this.arcadeRate,
        promotionBonus: this.promotionBonus,
      },
    };
  },
  methods: {
    edit() {
      if ((~~this.editModel.fromAmount > ~~this.editModel.toAmount)) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.alertMsg`), 1000)
        return
      }
      for (const [key, value] of Object.entries(this.editModel)) {
        this.editModel[key] = (!this.editModel[key] || this.editModel[key].lengtn === 0) ? 0 : value * 1;
      }
      this.$emit('edit', this.editModel );
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 60%;
  .input-block {
    label {
      width: 40%;
    }
  }
}
</style>