<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search()">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t('Permission.' + $route.meta.permissionName) }}</h5>
        <button class="download-btn main-style-btn" @click="download">
          <img class="icon" src="@/assets/download.svg" />
          {{ $t('default.download') }}
        </button>
      </div>
      <div class="main">
        <div class="input-block">
          <label class="input-block-multiselect">
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.agent") }}</span>
              <input
                class="pc-block"
                id="isIncludeSubAgents"
                type="checkbox"
                v-model="searchForm.isIncludeSubAgents"
              />
              <label class="pc-block" for="isIncludeSubAgents">{{ $t("default.isIncludeSubAgent") }}</label>
            </div>
            <multiselect
              v-model="agentData"
              label="name"
              track-by="id"
              :options="getAgentListAddDefault"
              :allow-empty="false"
              :preselectFirst="true"
              :clear-on-select="false"
              :preserve-search="true"
              :custom-label="GLOBAL.nameWithLang"
            ></multiselect>
          </label>
          <div class="mobile-block">
            <div class="input-block-check">
              <input
                id="isIncludeSubAgents"
                type="checkbox"
                v-model="searchForm.isIncludeSubAgents"
              />
              <label for="isIncludeSubAgents">{{ $t("default.isIncludeSubAgent") }}</label>
            </div>
          </div>
          <label>
            {{ $t("default.startTime") }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
          <label>
            {{ $t("default.endTime") }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD"
              value-type="format"
              type="datetime"
              :disabled-date="GLOBAL.ontSeason"
            ></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime" :controlType="true"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <TreeTable
          ref="treeTable"
          :showColumnCheckbox="true"
          :list="allData"
          :column="tableColumn"
          :footer="footer"
        ></TreeTable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOperatingReport,
  getOperatingExcel
} from "@/api/statistical-report/operatingReport";
import { searchModel } from "../bet-reportV2/model";
import { transTodayDate } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import TreeTable from "@/layout/components/TreeTable";
import { download } from "@/utils/download";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "OperatingReport",
  data() {
    return {
      allData: [],
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate(),
      endTime: transTodayDate(),
      agentData: [],
      total: [],
      i18nPrefix: "statistical-report.operatingReport.",
      digital: new Intl.NumberFormat()
    };
  },
  watch: {},
  components: {
    Multiselect,
    QuickSearch,
    TreeTable
  },
  computed: {
    ...mapGetters(["getAgentListAddDefault"]),
    tableColumn() {
      return [
        {
          key: "date",
          name: this.$t(`${this.i18nPrefix}date`)
        },
        {
          key: "memberRegistryCount",
          name: this.$t(`${this.i18nPrefix}memberRegistryCount`)
        },
        {
          key: "memberActiveCount",
          name: this.$t(`${this.i18nPrefix}memberActiveCount`)
        },
        {
          key: "totalDepositMemberCount",
          name: this.$t(`${this.i18nPrefix}totalDepositMemberCount`)
        },
        {
          key: "totalDepositCount",
          name: this.$t(`${this.i18nPrefix}totalDepositCount`)
        },
        {
          key: "totalWithdrawalMemberCount",
          name: this.$t(`${this.i18nPrefix}totalWithdrawalMemberCount`)
        },
        {
          key: "totalWithdrawalCount",
          name: this.$t(`${this.i18nPrefix}totalWithdrawalCount`)
        },
        {
          key: "totalFirstDepositMemberCount",
          name: this.$t(`${this.i18nPrefix}totalFirstDepositCount`)
        },
        {
          key: "totalRepeatDepositMemberCount",
          name: this.$t(`${this.i18nPrefix}totalRepeatDepositCount`)
        },
        {
          key: "totalFirstDepositAmount",
          name: this.$t(`${this.i18nPrefix}totalFirstDepositAmount`)
        },
        {
          key: "totalDepositAmount",
          name: this.$t(`${this.i18nPrefix}totalDepositAmount`)
        },
        {
          key: "totalWithdrawalAmount",
          name: this.$t(`${this.i18nPrefix}totalWithdrawalAmount`)
        },
        {
          key: "totalFeeAmount",
          name: this.$t(`${this.i18nPrefix}totalFeeAmount`)
        },
        {
          key: "memberBetCount",
          name: this.$t(`${this.i18nPrefix}memberBetCount`)
        },
        {
          key: "totalBetCount",
          name: this.$t(`${this.i18nPrefix}totalBetCount`)
        },
        {
          key: "totalValidBetAmount",
          name: this.$t(`${this.i18nPrefix}totalValidBetAmount`)
        },
        {
          key: "totalBetAmount",
          name: this.$t(`${this.i18nPrefix}totalBetAmount`)
        },
        {
          key: "totalPayoutAmount",
          name: this.$t(`${this.i18nPrefix}totalPayoutAmount`)
        },
        {
          key: "totalWinLose",
          name: this.$t(`${this.i18nPrefix}totalWinLose`)
        },
        {
          key: "cumulativeWinLose",
          name: this.$t(`${this.i18nPrefix}cumulativeWinLose`)
        },
        {
          key: "totalDiscountAmount",
          name: this.$t(`${this.i18nPrefix}totalDiscountAmount`)
        },
        {
          key: "totalAdjustmentAmount",
          name: this.$t(`${this.i18nPrefix}totalAdjustmentAmount`)
        },
        {
          key: "adjustmentAddAmount",
          name: this.$t(`${this.i18nPrefix}adjustmentAddAmount`)
        },
        {
          key: "adjustmentReduceAmount",
          name: this.$t(`${this.i18nPrefix}adjustmentReduceAmount`)
        }
      ];
    },
    footer() {
      return [
        {
          key: "total",
          data: this.total,
          column: {
            date: { value: this.$t("default.total") },
            totalDepositAmount: {
              key: "totalDepositAmount"
            },
            totalWithdrawalAmount: {
              key: "totalWithdrawalAmount"
            },
            totalValidBetAmount: { key: "totalValidBetAmount" },
            totalWinLose: { key: "totalWinLose" },
            totalDiscountAmount: { key: "totalDiscountAmount" }
          }
        }
      ];
    }
  },
  created() {},
  methods: {
    async search() {
      //時間參數
      this.searchForm.startTime = this.startTime
        ? this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
        : undefined;
      this.searchForm.endTime = this.endTime
        ? this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone
        : undefined;

      this.searchForm.agentId = this.agentData.id;
      getOperatingReport(this.searchForm).then(res => {
        this.allData = res.data.records;
        this.total = res.data.total;
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    download() {
      getOperatingExcel(this.searchForm).then(res =>
        download(res, this.searchForm.startTime + "OperatingReport.xlsx")
      );
    }
  }
};
</script>
