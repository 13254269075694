import Cookies from "js-cookie";

const TokenKey = "token";

export function setToken(token) {
    localStorage.setItem(TokenKey, token)
    return Cookies.set(TokenKey, token, { expires: 1 });
}

export function getToken() {
    return Cookies.get(TokenKey);
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
    return Cookies.remove(TokenKey);
}
