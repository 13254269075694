// const _legend = {
//   orient: 'horizontal',
//   left: 'center',
//   bottom: '',
//   top: '',
//   data: []
// };

const _xAxis = {
  name: '',
  type: 'category',
  data: []
};

const _yAxis = {
  name: '',
  type: 'value',
  axisLabel: {},
  min: 0,
  max: 0,
  interval: 0
};

const _series = {
  name: '',
  type: '',
  data: []
};

/**
 * title = { 可空
 *  text: '' 標題,
 *  left: 'center' 默認左測
 * }
 * legend = {
 *  orient: vertical 垂直 可空
 *  align: 'left or right or bottom' 位待預設中間
 *  type: 'scroll' 滾動 未輸入默認一般, 可空
 *  data: [] 自訂順序 依照 series.name命名 可空
 *  // 以下為位置官方設定
 *  left: 'auto' 可空
 *  right: 'auto', 可空
 *  top: 'auto' 未輸入此為默認 可空
 *  bottom: '10%' or '10', 可空
 * }
 * xAxis = {
 *  name: '座標名稱' 可空,
 *  type: 'category' 目前固定此值,
 *  data: [] X軸節點
 * }
 * yAxis = [{ // 混和圖 2個物件陣列 為左右兩側都有刻度
 *  name: '座標名稱' 可空,
 *  type: 'value'  目前固定此值,
 *  unit: 'ml' 會顯示 100 ml 可空,
 * }]
 * series = {
 *  name: '名稱',
 *  type: '' 類型 bar: 柱狀, line: 折線, pie: 圓餅,
 *  unit: 'ml' 會顯示 100 ml 可空,
 *  yAxisIndex: 1, 混和圖才需輸入, 右側Y軸的數字
 *  // 柱狀&折線
 *  data: [] 顯示的數字基本與xAxis要對得上,
 *  // 圓餅
 *  data: [{ value: 100, name: 'aaa' }, { value: 200, name: 'bbb' }] 須為物件value&name組合
 * }
 * @param {*} title
 * @param {*} legend 
 * @param {*} xAxis 
 * @param {*} yAxis 
 * @param {*} series 
 */
export function option(title= {}, legend = {}, xAxis = {}, yAxis = [], series = []) {
  return new Promise(resolve => {
    var option = {};
    // 標題
    if (!isEmpty(title)) {
      option.title = title;
    }
    // 滑鼠事件
    if (!isEmpty(xAxis)) {
      option.tooltip = {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      }
    } else {
      option.tooltip = {
        trigger: 'item'
      }
    }
    // 圖例組件
    if (!isEmpty(legend)) {
      option.legend = legend;
    }
    // x軸
    if (!isEmpty(xAxis)) {
      option.xAxis = {..._xAxis};
      if (xAxis.name) {
        option.xAxis.name = xAxis.name;
      }
      option.xAxis.data = xAxis.data;
      option.xAxis.axisPointer = {
        type: 'shadow'
      };
    }
    // y軸
    if (yAxis.length > 0) {
      option.yAxis = [];
      var yBetween = calcY(series);
      var i = 0;
      yAxis.forEach(item => {
        var y = {..._yAxis};
        var calc = i == 0 ? yBetween.left : yBetween.right;
        if (item.name) {
          y.name = item.name;
        }
        y.axisLabel = { 
          formatter: '{value}' + (item.unit ? ` ${item.unit}` : '')
        };
        y.max = calc.max;
        y.interval = calc.interval;
        option.yAxis.push(y);
        i++;
      });
    } else if (!isEmpty(xAxis)) {
      option.yAxis = {
        type: 'value'
      };
    }
    // 內容
    if (series.length > 0) {
      option.series = [];
      series.forEach(d => {
        var item = {..._series};
        item.name = d.name;
        item.type = d.type;
        item.data = d.data;

        // 是否以右側Y軸為主
        if (d.yAxisIndex) {
          item.yAxisIndex = 1;
        }
        // 是否為堆疊
        if (d.isStack) {
          item.stack = 'Total';
        }

        item.tooltip = {
          valueFormatter: function (value) {
            return value + (d.unit ? ` ${d.unit}` : '');
          }
        }
        if (d.type == 'pie') {
          item.radius = '50%';
          item.emphasis = {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          };
        }

        option.series.push(item)
      });
    }

    resolve(option);
  });
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

// 計算刻度
function calcY(data) {
  // 没有 yAxisIndex 的所有物件之间的 data 最大值
  const leftMax = data
  .filter(item => !item.yAxisIndex)
  .reduce((max, item) => Math.max(max, ...item.data), 0);

  // 有 yAxisIndex 的所有物件之间的 data 最大值
  const rightMax = data
  .filter(item => item.yAxisIndex !== undefined)
  .reduce((max, item) => Math.max(max, ...item.data), 0);

  var leftInterval = Math.ceil(leftMax/5);
  var rightInterval = Math.ceil(rightMax/5);

  return {
    left: {
      interval: leftInterval,
      max: leftInterval * 6
    },
    right: {
      interval: rightInterval,
      max: rightInterval * 6
    }
  }
}