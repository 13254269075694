<template>
  <div>
    <LoadingBar />
    <navbar></navbar>
    <SidebarV2 v-if="windoWidth > 768"></SidebarV2>
    <Sidebar v-else></Sidebar>
    <app-main>
    </app-main>
    <transition name="fade" mode="out-in">
      <component
        :is="agentInfo"
        @toggle="close"
      ></component>
    </transition>
  </div>
</template>

<script>
import Navbar from "@/layout/components/Navbar";
import Sidebar from "@/layout/components/Sidebar";
import SidebarV2 from "@/layout/components/SidebarV2";
import AppMain from "@/layout/components/AppMain";
import LoadingBar from "@/layout/components/LoadingBar";
import AgentInfo from "@/views/agent/agent-info/index";
import { mapGetters } from 'vuex';

export default {
  name: "Layout",
  components: {
    Navbar,
    Sidebar,
    SidebarV2,
    AppMain,
    LoadingBar,
    AgentInfo,
  },
  data() {
    return {
      windoWidth: 0
    }
  },
  computed: {
    ...mapGetters(["getUserinfo","agentInfo"])
  },
  created() {
    this.close();
    this.$store.dispatch("select_menu/getAllSelect", this.getUserinfo);
    // 監控瀏覽器大小
    this.detectWindowWidth();
    window.addEventListener("resize", this.detectWindowWidth);
  },
  mounted() {
    
  },
  watch: {
    "$route.path": {
      handler(value) {
        // 儀錶板轉址
        var url = value;
        var path = url.split('/');
        if (path.length == 2 && path[1] == 'dashboard') {
          this.$router.push("/dashboard/index");
        }
      },
      immediate: true
    }
  },
  mounted() {
    
  },
  watch: {
    "$route.path": {
      handler(value) {
        // 儀錶板轉址
        var url = value;
        var path = url.split('/');
        if (path.length == 2 && path[1] == 'dashboard') {
          this.$router.push("/dashboard/index");
        }
      },
      immediate: true
    }
  },
  mounted() {
    
  },
  watch: {
    "$route.path": {
      handler(value) {
        // 儀錶板轉址
        var url = value;
        var path = url.split('/');
        if (path.length == 2 && path[1] == 'dashboard') {
          this.$router.push("/dashboard/index");
        }
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$store.commit("app/SET_AGENTINFO", false);
    },
    detectWindowWidth() {
      this.windoWidth = window.innerWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>