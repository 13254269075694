var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}submitTime`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.paymentName`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.bank`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.preTradeBalance`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.fee`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.amount`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.atmForm.createTime))]),
        _c("td", [_vm._v(_vm._s(_vm.atmForm.memberAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.atmForm.paymentName))]),
        _c("td", [_vm._v(_vm._s(_vm.$t(_vm.atmForm.gateway)))]),
        _c("td", [_vm._v(_vm._s(_vm.atmForm.originalAmount))]),
        _c("td", [_vm._v(_vm._s(_vm.atmForm.feeRate))]),
        _c("td", [_vm._v(_vm._s(_vm.atmForm.amount))]),
        _c("td", [_vm._v(_vm._s(_vm.$t(_vm.atmForm.paymentStatus)))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }