import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getBlacklistAppSetting(data) {
    return request({
        url: "/BlacklistAppSetting/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createBlacklistAppSetting(data) {
    return request({
        url: "/BlacklistAppSetting/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteBlacklistAppSetting(data) {
    return request({
        url: "/BlacklistAppSetting/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}