<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}table.adjustmentType`) }}</th>
        <th>{{ $t(`${i18nPrefix}preBalance`) }}</th>
        <th>{{ $t("default.amount") }}</th>
        <th>{{ $t(`${i18nPrefix}afterBalance`) }}</th>
        <th>{{ $t("default.remark") }}</th>
        <th>{{ $t("default.admin") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ quotaForm.submitTime }}</td>
        <td>{{ quotaForm.memberAccount }}</td>
        <td>{{ $t(quotaForm.adjustmentType) }}</td>
        <td>{{ quotaForm.preTradeBalance }}</td>
        <td :class="fontColor(quotaForm.amount)">{{ quotaForm.amount }}</td>
        <td>{{ quotaForm.afterTradeBalance }}</td>
        <td>{{ quotaForm.remark }}</td>
        <td>{{ quotaForm.auditAccount }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { quotaModel } from "../model";

export default {
  name: "quotaForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      quotaForm: _.cloneDeep(quotaModel),
    };
  },
  watch: {
    detailsData() {
      this.quotaForm = {
        id: this.detailsData.id,
        submitTime: this.detailsData.submitTime,
        memberAccount: this.detailsData.memberAccount,
        adjustmentType:
          this.detailsData.adjustmentType === 1
            ? `${this.i18nPrefix}table.balanceAdjustmentAdd`
            : this.detailsData.adjustmentType === 2
            ? `${this.i18nPrefix}table.balanceAdjustmentReduce`
            : `${this.i18nPrefix}table.deposit`,
        preTradeBalance: this.digital.format(this.detailsData.preTradeBalance),
        amount: this.digital.format(this.detailsData.amount),
        afterTradeBalance: this.digital.format(
          this.detailsData.afterTradeBalance
        ),
        remark: this.detailsData.remark,
        auditAccount: this.detailsData.auditAccount,
      };
    },
  },
  methods: {
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  },
};
</script>
<style lang="scss" scoped>
</style>