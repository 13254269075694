var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-view" },
      [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        ]),
        _c("main", [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}account`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}phoneNumber`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}verificationCode`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createTime`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.tableList || _vm.tableList.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "4" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.createTime }, [
                      _c("td", [_vm._v(_vm._s(list.account))]),
                      _c("td", [_vm._v(_vm._s(list.phoneNumber))]),
                      _c("td", [_vm._v(_vm._s(list.verificationCode))]),
                      _c("td", [_vm._v(_vm._s(list.createTime))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm.totalCount > 0
          ? _c("Pagination", {
              attrs: { totalCount: _vm.totalCount },
              on: { handlePage: _vm.handlePage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }