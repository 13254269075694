<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t(`${i18nPrefix}table.submitTime`) }}</th>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}table.bankName`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.bankAccountName`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.bankAccountNumber`) }}</th>
        <th>{{ $t("default.amount") }}</th>
        <th>{{ $t("default.status") }}</th>
        <th>{{ $t("default.remark") }}</th>
        <th>{{ $t("default.admin") }}</th>
        <th>{{ $t(`${i18nPrefix}table.auditTime`) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ withdrawalForm.submitTime }}</td>
        <td>{{ withdrawalForm.memberAccount }}</td>
        <td>{{ withdrawalForm.bankName }}</td>
        <td>{{ withdrawalForm.bankAccountName }}</td>
        <td>{{ withdrawalForm.bankAccountNumber }}</td>
        <td :class="fontColor(withdrawalForm.amount)">{{ withdrawalForm.amount }}</td>
        <td>{{ $t(withdrawalForm.auditStatus) }}</td>
        <td>{{ withdrawalForm.remark }}</td>
        <td>{{ withdrawalForm.auditAccount }}</td>
        <td>{{ withdrawalForm.auditTime }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters } from "vuex";
import { withdrawalModel } from "../model";

export default {
  name: "withdrawalForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      withdrawalForm: _.cloneDeep(withdrawalModel),
    };
  },
  watch: {
    detailsData() {
      this.withdrawalForm = {
        id: this.detailsData.id,
        submitTime: this.detailsData.submitTime,
        memberAccount: this.detailsData.memberAccount,
        bankName: this.detailsData.bankName,
        bankAccountName: this.detailsData.bankAccountName,
        bankAccountNumber: this.detailsData.bankAccountNumber,
        amount: this.digital.format(this.detailsData.amount),
        auditStatus: this.getAuditStatusList.find(
          (data) => data.id === this.detailsData.auditStatus
        )?.name,
        remark: this.detailsData.remark,
        auditAccount: this.detailsData.auditAccount,
        auditTime: this.detailsData.auditTime,
      };
    },
  },
  computed: {
    ...mapGetters(["getAuditStatusList"]),
  },
  methods: {
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  },
};
</script>
