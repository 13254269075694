<template>
  <div class="form-box form-column shadow-sm px-3 py-4 bg-white rounded overview-table">
    <div class="table-block">
      <TreeTable ref="treeTable" :list="agentRebate.list" :column="tableColumn">
        <!-- 遊戲種類 -->
        <template
          v-slot:column_gamesCategory="props"
        >{{ `${(props.gamesCategory ? GLOBAL.i18nCheckName(getGamesCategorys.find( data => data.id == props.gamesCategory)) : $t(`${i18nPrefix}rebateGame.allGame`))}` }}</template>
        <!-- 下線返水 -->
        <template v-slot:column_childrenRebate="props">
          {{ props.childrenRebate }}
          <span
            class="rate"
            :class="{ childrenRate: props.gamesCategory }"
            v-show="props.gamesCategory && props.gamesCategory !== 99999"
          >{{ props.childrenRate + (props.gamesCategory ? '%' : '') }}</span>
        </template>
        <!-- 我的返水 -->
        <template v-slot:column_selfRebate="props">
          {{ props.gamesCategory && props.gamesCategory !== 99999 ? props.selfRebate : '' }}
          <span
            class="rate"
            :class="{ childrenRate: props.gamesCategory && props.gamesCategory !== 99999 }"
          >{{ props.gamesCategory && props.gamesCategory !== 99999 ? props.selfRate + '%' : props.selfRebate }}</span>
        </template>
      </TreeTable>
    </div>
  </div>
</template>

<script>
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";

export default {
  name: "Rebate",
  props: ["agentRebate", "i18nPrefix"],
  data() {
    return {
      rebateList: [],
    };
  },
  components: {
    TreeTable
  },
  computed: {
    ...mapGetters(["getGamesCategorys"]),
    tableColumn() {
      return [
        {
          key: "agentAccount",
          name: this.$t(`${this.i18nPrefix}rebateGame.agentAccount`),
          align: "left",
          width: 200
        },
        {
          key: "gamesCategory",
          name: this.$t(`${this.i18nPrefix}rebateGame.gamesCategory`)
        },
        {
          key: "betAmount",
          name: this.$t(`${this.i18nPrefix}rebateGame.betAmount`),
        },
        {
          key: "childrenRebate",
          name: this.$t(`${this.i18nPrefix}rebateGame.childrenRebate`),
        },
        {
          key: "selfRebate",
          name: this.$t(`${this.i18nPrefix}rebateGame.selfRebate`),
        }
      ];
    }
  },
  watch: {
  },
  async created() {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

.rate {
  border: 1px solid #ff0000;
  border-radius: 3px;
  background: #ff0000;
  color: #fff;
  margin-left: 5px;
  padding: 1px 6px;
}
.childrenRate {
  border: 1px solid gray;
  background: gray;
}
</style>