/**
 * @summary 用來檢查所有欄位都必須要輸入的情況(對象為物件類型)
 * @param obj 欲迭代的物件
 * @returns boolean 如果有欄位偽填回傳true否則false
 */
export function validAllInput(obj) {
    let tmp = [];
    for (let i in obj) {
        tmp.push(obj[i]);
    }
    return check(tmp);
}

function check(arr) {
    return arr.some((data) => (data === undefined || !data.toString().trim()));
}