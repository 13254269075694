<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="handleDialog(undefined, 'Create')"
          >
            {{ $t(`default.create`) }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t(`${i18nPrefix}maintain`) }}</th>
              <th>{{ $t(`${i18nPrefix}sort`) }}</th>
              <th>{{ $t(`${i18nPrefix}imageLink`) }}</th>
              <th>{{ $t(`${i18nPrefix}mobileImageLink`) }}</th>
              <th>{{ $t(`${i18nPrefix}videoLink`) }}</th>
              <th>{{ $t(`${i18nPrefix}createDate`) }}</th>
              <th>{{ $t(`${i18nPrefix}updateDate`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || tableList.length === 0">
              <td colspan="8">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList" :key="list.id">
              <td>
                <button
                  class="normal-btn"
                  @click="handleDialog( list , 'Edit')"
                >
                  {{ $t("default.edit") }}
                </button>
                <button
                    class="delete-btn"
                    @click="handleDelete(list.id)"
                >
                  {{ $t("default.delete") }}
                </button>
              </td>
              <td>{{ list.isMaintain ? $t("default.active") : $t("default.inactive") }}</td>
              <td>{{ list.sort }}</td>
              <td class="url" @click="handleDialog({image: list.imageUrl}, 'Photo')" :title="list.imageUrl">{{ $t("default.preview") }}</td>
              <td class="url" @click="handleDialog({image: list.mobileImageUrl}, 'Photo')" :title="list.mobileImageUrl">{{ $t("default.preview") }}</td>
              <td>{{ list.url }}</td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.updateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
          v-if="alertMsg"
          :message="alertMsg"
          @toggle="alertMsg = undefined"
          @doIt="deleteVideoSetting"
      />
    </transition>
  </div>
</template>

<script>
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Photo from "./dialog/photo";
import Alert from "@/layout/components/Alert";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import {
  getVideoSettingList,
  createVideoSetting,
  updateVideoSetting,
  deleteVideoSetting,
} from "@/api/SEO-function/VideoSetting";
import Message from "@/layout/components/Message";

export default {
  name: "VideoSetting",
  data() {
    return {
      alertMsg: undefined,
      deleteData: {},
      i18nPrefix: "SEO-Function.VideoSetting."
    };
  },
  mixins: [handlePageUtils, handleDialog],
  components: {
    Create,
    Edit,
    Photo,
    Alert,
    Pagination,
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      this.RESET_DIALOG();
      getVideoSettingList([]).then((res) => {
        this.allData = res.data;
        this.dataSort('updateTime','time', true)
      });
    },
    async create(data) {
      await createVideoSetting(data).then((res) => {
        if(res) this.search();
      });
    },
    async edit(data) {
      await updateVideoSetting(data).then((res) => {
        if(res) this.search();
      });
    },
    handleDelete(data) {
      this.alertMsg = "確認刪除此筆資料 ?";
      this.deleteData = { id : data };
    },
    deleteVideoSetting() {
      this.alertMsg = undefined;
      deleteVideoSetting(this.deleteData).then((res) => {
        if(res) this.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.url {
  color: blue;
  cursor: pointer;
  font-weight:bold;
}
</style>