import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function downloadYearReportList(data) {
    return request({
        url: "/YearlyReport/Generate",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}

export function getYearReportList(data) {
    return request({
        url: "/YearlyReport/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}