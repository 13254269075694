export let searchModel = {
    name: undefined,
    city: undefined,
    district: undefined,
    code: undefined,
    storeName: undefined,
    page:  {
        "take": 10,
        "skip": 0
    },
}