var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.submitTime`)))]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.orderNumber`))),
        ]),
        _c("th", [
          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.paymentName`))),
        ]),
        _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}table.gateway`)))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.amount")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("default.admin")))]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.storeForm.memberAccount))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.takeNumberTime))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.orderNumber))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.paymentName))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.gateway))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.amount))]),
        _c("td", [_vm._v(_vm._s(_vm.$t(_vm.storeForm.paymentStatus)))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.remark))]),
        _c("td", [_vm._v(_vm._s(_vm.storeForm.auditAccount))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }