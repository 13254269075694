<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}game`) }}
              <select v-model="createModel.gamePlatform">
                <option v-for="list of gameList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}day`) }}
              <select v-model="createModel.dayOfWeek">
                <option
                  v-for="list of getWeekList"
                  :key="list.name"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}timeFrom`) }}
              <date-picker v-model="timeFrom" format="HH:mm" value-type="format" type="time"></date-picker>
            </label>
            <label>
              {{ $t(`${i18nPrefix}timeTo`) }}
              <date-picker v-model="timeTo" format="HH:mm" value-type="format" type="time"></date-picker>
            </label>
            <label>
              {{ $t(`${i18nPrefix}status`) }}
              <select v-model="createModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.name"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { joinT, transTodayDate } from "@/utils/transDateUtils";


export default {
  name: "Create",
  props: {
    i18nPrefix: String,
    gameList: []
  },
  data() {
    return {
      createModel: {
        status: 1,
        gamePlatform: 1,
        dayOfWeek: 0,
        timeFrom: undefined,
        timeTo: undefined,
      },
      timeFrom: "00:00",
      timeTo: "23:59",
      gameList: []
    };
  },
  computed: {
    ...mapGetters(["getStatusList", "getWeekList"]),
  },
  components: {
    
  },
  methods: {
    submit() {
      this.createModel.timeFrom = joinT(transTodayDate() + " " + this.timeFrom + this.GLOBAL.startSecond+ this.GLOBAL.timeZone);
      this.createModel.timeTo = joinT(transTodayDate() + " " + this.timeTo + this.GLOBAL.endSecond+ this.GLOBAL.timeZone);
      this.$emit("create", this.createModel);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  .input-block {
    label {
      width: 45%;
    }
  }
}
</style>