<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label v-show="isThirdParty">
              {{ $t(`${i18nPrefix}gateway`) }}
              <select v-model="gateway" :disabled="auditStatus !== 1">
                <option 
                  v-for="(list, key, index) in thirdPartyList"
                  :key="key + index"
                  :value="key"
                >
                {{ $t("DepositTypeList." + key) }}
                </option>
              </select>
            </label>
            <label v-show="isThirdParty">
              {{ $t(`${i18nPrefix}paymentName`) }}
              <select v-model="editModel.payment" :disabled="auditStatus !== 1">
                <option 
                  v-for="list of thirdPartyList[gateway]"
                  :key="list.id"
                  :value="list.id"
                >
                {{ list.name }}
                </option>
              </select>
            </label>
            <label>
              {{ $t("default.member") }}
              <input type="text" v-model="editModel.memberAccount" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}submitTime`) }}
              <input type="text" v-model="editModel.submitTime" disabled />
            </label>
            <label>
              {{ $t("default.amount") }}
              <input type="text" v-model="editModel.amount" disabled/>
            </label>
            <label>
              {{ $t(`${i18nPrefix}feePercent`) }}
              <input type="text" v-model="editModel.feePercent" :disabled="auditStatus !== (isThirdParty ? 1 : 5)"/>
            </label>
            <label>
              {{ $t("default.remark") }}
              <textarea v-model="editModel.remark"></textarea>
            </label>
          </div>
          <div class="advance-block">
            <button v-if="auditStatus !== 1 && auditStatus !== 5" class="normal-btn" @click.self="editRemark()">{{ $t(`${i18nPrefix}editRemark`) }}</button>
            <template v-else>
              <button class="normal-btn" @click.self="edit(2)">{{ $t(`${i18nPrefix}dialog.accept`) }}</button>
              <button class="delete-btn" @click.self="(isThirdParty && auditStatus === 5) ? handleCancel() : edit(3)">{{ $t(`${i18nPrefix}dialog.reject`) }}</button>
            </template>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="edit(3)"
      />
    </transition>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";

export default {
  name: "Audit",
  props: [
    "id",
    "memberAccount",
    "auditStatus",
    "submitTime",
    "amount",
    "remark",
    "isThirdParty",
    "feePercent",
    "thirdPartyList",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        oldStatus: this.auditStatus,
        id: this.id,
        memberAccount: this.memberAccount,
        submitTime: this.submitTime,
        amount: this.amount,
        feePercent: this.feePercent,
        payment: this.thirdPartyList?.[Object.keys(this.thirdPartyList)?.[0]]?.[0]?.id,
        isThirdParty: this.isThirdParty,
        remark: this.remark
      },
      gateway: Object.keys(this.thirdPartyList)?.[0],
      alertMsg: undefined,
    }
  },
  components: {
    Alert,
  },
  watch: {
    gateway(value) {
            this.editModel.payment = this.thirdPartyList?.[value]?.[0]?.id;
        },
  },
  methods: {
    edit(Status) {
      this.alertMsg = undefined;
      if (!this.editModel.remark.trim().length && Status === 3) {
        Message.error(this.$t("default.checkInput" , { input: this.$t("default.remark")}), 1000);
        return;
      }
      this.editModel.auditStatus = Status;
      this.$emit("audit", this.editModel);
    },
    editRemark() {
      this.editModel.auditStatus = this.auditStatus;
      this.$emit("editRemark", this.editModel);
    },
    handleCancel() {
      this.alertMsg = this.$t(`${this.i18nPrefix}dialog.alertMsg`);
    },
  }
};
</script>
<style lang="scss" scoped>
.dialog-block {
  .input-block {
    label {
      width: 60%;
      margin: 15px auto;
    }
    textarea {
      height: 100px;
    }
  }
}
</style>