var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        _c("div", { staticClass: "float-right" }, [
          _c(
            "button",
            {
              staticClass: "create-btn main-style-btn",
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("default.new")) + " ")]
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container-view" },
      [_c("Excel", { on: { UpdateFile: _vm.UpdateFile } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }