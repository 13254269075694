var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header tree-header" }, [
            _c("h3", [_vm._v(_vm._s(_vm.mainAccount))]),
            _c("div", { staticClass: "header-container" }, [
              _c("div", { staticClass: "btn btn-lightBlue" }, [
                _vm._v(
                  _vm._s(
                    `${_vm.date} ${_vm.$t(
                      `${_vm.i18nPrefix}auditStatus.${_vm.auditStatus}`
                    )}`
                  )
                ),
              ]),
              _c("div", { staticClass: "totle" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}tree.totalAmount`)) +
                    ": "
                ),
                _c("div", { staticClass: "tag" }, [
                  _vm._v(_vm._s(_vm.totalAmount)),
                ]),
              ]),
            ]),
          ]),
          _c("main", [
            _c(
              "div",
              { staticClass: "table-block" },
              [
                _c("TreeTable", {
                  ref: "treeTable",
                  attrs: {
                    list: _vm.list,
                    column: _vm.treeColumn,
                    showFold: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "column_agentAccount",
                      fn: function (props) {
                        return [
                          _c("div", { staticClass: "agebt-tag" }, [
                            _vm._v(
                              _vm._s(
                                props.levelNumber === 0
                                  ? _vm.$t("default.mainAgent")
                                  : _vm.$t("default.levelNumber", {
                                      input: props.levelNumber,
                                    })
                              )
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `#/historyReport?agentId=${props.agentId}&agentRebateGeneralId=${_vm.rebateId}&lang=${_vm.lang}`,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(props.agentAccount))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "column_remitAmount",
                      fn: function (props) {
                        return [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.payouts[
                                    _vm.payouts.findIndex(
                                      (e) => e.id === props.id
                                    )
                                  ].payoutAmount,
                                expression:
                                  "payouts[payouts.findIndex(e => e.id === props.id)].payoutAmount",
                              },
                            ],
                            attrs: { type: "number" },
                            domProps: {
                              value:
                                _vm.payouts[
                                  _vm.payouts.findIndex(
                                    (e) => e.id === props.id
                                  )
                                ].payoutAmount,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.payouts[
                                    _vm.payouts.findIndex(
                                      (e) => e.id === props.id
                                    )
                                  ],
                                  "payoutAmount",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "column_remark",
                      fn: function (props) {
                        return [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.payouts[
                                    _vm.payouts.findIndex(
                                      (e) => e.id === props.id
                                    )
                                  ].remark,
                                expression:
                                  "payouts[payouts.findIndex(e => e.id === props.id)].remark",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value:
                                _vm.payouts[
                                  _vm.payouts.findIndex(
                                    (e) => e.id === props.id
                                  )
                                ].remark,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.payouts[
                                    _vm.payouts.findIndex(
                                      (e) => e.id === props.id
                                    )
                                  ],
                                  "remark",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "dialog-footer" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn-blue",
                  staticStyle: {
                    "font-size": "12px",
                    "margin-right": "3px",
                    "line-height": "24px",
                  },
                  on: { click: _vm.processNegative },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.negative`)) +
                      " "
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  on: {
                    click: function ($event) {
                      _vm.alertMsg = _vm.$t(`${_vm.i18nPrefix}dialog.alertMsg3`)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}payout`)) + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.runPayout,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }