<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>修改 支付網關</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}gateway`) }}
              <input
                type="text"
                v-model="editModel.name"
                :disabled="true"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}paymentCompanyInfo`) }}
              <input
                type="text"
                v-model="editModel.nickName"
              />
            </label>
            <label>
              {{ $t("default.remark") }}
              <input type="text" v-model="editModel.remark" />
            </label>
            <label>
              {{ $t("default.sort") }}
              <input type="text" v-model="editModel.sortNumber" />
            </label>
            <label>
              {{ $t("default.domain") }}
              <input type="text" v-model="editModel.domain" />
            </label>
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('edit', editModel)">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Edit",
  props: ["dialogData", "i18nPrefix"],
  data() {
    return {
      editModel: _.cloneDeep(this.dialogData)
    };
  },
  computed: {
    ...mapGetters(["getStatusList"])
  }
};
</script>

<style lang="scss" scoped>
label {
  width: 40% !important;
}
</style>