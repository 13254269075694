var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "dashboard", staticClass: "container-view" }, [
      _c(
        "div",
        { staticClass: "d-flex mb-3" },
        [
          _c("QuickSearch", {
            ref: "quickSearch",
            on: { changeTime: _vm.changeTime },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "chart" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("Chart", {
              ref: "chartRegister",
              attrs: {
                width: _vm.chartWidth,
                title: _vm.chartRegister.title,
                xAxis: _vm.chartRegister.xAxis,
                yAxis: _vm.chartRegister.yAxis,
                series: _vm.chartRegister.series,
              },
            }),
            _c("Chart", {
              ref: "chartGame",
              attrs: {
                width: _vm.chartWidth,
                title: _vm.chartGame.title,
                legend: _vm.chartGame.legend,
                series: _vm.chartGame.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("Chart", {
              ref: "chartPayment",
              attrs: {
                width: _vm.chartWidth,
                title: _vm.chartPayment.title,
                legend: _vm.chartPayment.legend,
                xAxis: _vm.chartPayment.xAxis,
                yAxis: _vm.chartPayment.yAxis,
                series: _vm.chartPayment.series,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }