var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.isColumn ? "tag-column" : "" },
    _vm._l(_vm.tags, function (tag) {
      return _c(
        "span",
        {
          style: {
            color: _vm.tagList.find((data) => data.id === tag).fontColor,
            "background-color": _vm.tagList.find((data) => data.id === tag)
              .backgroundColor,
            "font-weight": _vm.tagList.find((data) => data.id === tag)
              .isBoldFont
              ? "bolder"
              : "normal",
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.tagList.find((data) => data.id === tag).name) + " "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }