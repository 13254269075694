var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
      ]),
      _c("div", { staticClass: "divider" }),
      _c("main", [
        _c("div", { staticClass: "reset-block" }, [
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$t("reset-password.oldPassword")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetModel.oldPassword,
                  expression: "resetModel.oldPassword",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.resetModel.oldPassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.resetModel, "oldPassword", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$t("reset-password.newPassword")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetModel.newPassword,
                  expression: "resetModel.newPassword",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.resetModel.newPassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.resetModel, "newPassword", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("reset-password.confirmPassword"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetModel.confirmPassword,
                  expression: "resetModel.confirmPassword",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.resetModel.confirmPassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.resetModel,
                    "confirmPassword",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "advance-block" }, [
          _c(
            "button",
            { staticClass: "normal-btn", on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("reset-password.submit")))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }