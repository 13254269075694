var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}create`)))]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.name = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}memberLevel`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.star,
                      expression: "createModel.star",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.star },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.createModel, "star", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.upgradeConditions`))
                  ),
                ]),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}depositRequired`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.depositRequired,
                      expression: "createModel.depositRequired",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.depositRequired },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "depositRequired",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}betRequired`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.betRequired,
                      expression: "createModel.betRequired",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.betRequired },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "betRequired",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.levelLimit`))),
                ]),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalRequired`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.withdrawalRequired,
                      expression: "createModel.withdrawalRequired",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.withdrawalRequired },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "withdrawalRequired",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalLimit`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.withdrawalLimit,
                      expression: "createModel.withdrawalLimit",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.withdrawalLimit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "withdrawalLimit",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dayWithdrawalLimit`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.dayWithdrawalLimit,
                      expression: "createModel.dayWithdrawalLimit",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.dayWithdrawalLimit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "dayWithdrawalLimit",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label"),
              _c("label", { staticClass: "title" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`))),
                ]),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.promotionBonus,
                      expression: "createModel.promotionBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.promotionBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "promotionBonus",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}weekBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.weekBonus,
                      expression: "createModel.weekBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.weekBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "weekBonus",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}monthBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.monthBonus,
                      expression: "createModel.monthBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.monthBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "monthBonus",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}bounsMultiple`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createModel.bounsMultiple,
                      expression: "createModel.bounsMultiple",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.createModel.bounsMultiple },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.createModel,
                        "bounsMultiple",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.create } },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }