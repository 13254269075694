/**
 * 檢查是否至少輸入一項
 * Form 為物件
 */
import Message from "@/layout/components/Message";
import i18n from '@/lang/lang'

export function checkData(Form, all = false, noreturn = false){
    let emptyCount = 0;
    let DataCount = 0;
    Object.values(Form).forEach((element) => {
        if (element === "" || element === undefined) emptyCount++;
        DataCount++;
    });

    if (emptyCount !== 0 && all) {
        if (noreturn) return false;
        Message.error(i18n.t("default.fieldsNotEntered"), 1000);
        return false;
    }
    if (emptyCount === DataCount && !all) {
        if (noreturn) return false;
        Message.error(i18n.t("default.minEnterOne"), 1000);
        return false;
    }
    return true;
}


export function checkValue(value, message = false){
    if (!value) {
        Message.error(i18n.t("default.enterPlz") + (message ?? ""), 1000);
        return false;
    }
    return true;
}

/*
    param1 JSONstr 未格式化的JSON字串
    return 格式化後的JSON字串
*/
export function JSONFormat(JSONstr) {
    JSONstr = JSONTrim(JSONstr); // 初步格式化json
  
    let re = new RegExp('\\{|\\}|,|:', 'g'); // 匹配格式化後的json中的{},:
    let exec = null;
    let InvalidFs = 0;
    let InvalidBs = 0;
    while(exec = re.exec(JSONstr)) { // 找{},:
      let frontToCurrent = exec.input.substr(0, exec.index + 1); // 匹配開頭到當前匹配字元之間的字串
      if (frontToCurrent.replace(/\\"/g, "").replace(/[^"]/g, "").length%2 != 0) { // 測試當前字元到開頭"的數量，為雙數則被判定為目標物件
        if(exec[0] === '{') InvalidFs++;
        else if(exec[0] === '}') InvalidBs++;
        continue; // 不是目標物件，手動跳過
      }
      let keyTimesF = frontToCurrent.replace(/[^\{]/g, '').length - InvalidFs; // 找出當前匹配字元之前所有{的個數
      let keyTimesB = frontToCurrent.replace(/[^\}]/g, '').length - InvalidBs; // 找出當前匹配字元之前所有}的個數
      let indentationTimes = keyTimesF - keyTimesB; // 根據{個數計算縮排
  
      if (exec[0] === '{') {
        JSONstr = JSONstr.slice(0,exec.index + 1) + '\n' + '\t'.repeat(indentationTimes) + JSONstr.slice(exec.index + 1); // 將縮排加入字串
      } else if(exec[0] === '}') {
        JSONstr = JSONstr.slice(0,exec.index) + '\n' + '\t'.repeat(indentationTimes) + JSONstr.slice(exec.index) // 將縮排加入字串
        re.exec(JSONstr); // 在查詢目標前面插入字串會回退本次查詢，所以手動跳過本次查詢
      } else  if(exec[0] === ',') {
        JSONstr = JSONstr.slice(0,exec.index + 1) + '\n' + '\t'.repeat(indentationTimes) + JSONstr.slice(exec.index + 1)
      } else if (exec[0] === ':') {
        JSONstr = JSONstr.slice(0,exec.index + 1) + ' ' + JSONstr.slice(exec.index + 1)
      } else {
        console.log(`匹配到了來路不明的${exec[0]}`)
      }
    }

    return JSONstr === null ? false : JSONstr;

    function JSONTrim(JSONstr) {
        try {
            JSONstr = JSONstr.replace(/'/g, '"');
            JSONstr = JSON.stringify(JSON.parse(JSONstr));
            let re = new RegExp('\\{|\\}|,|:', 'g');
            if (re.exec(JSONstr) === null) {
                JSONstr = null
            }
        } catch (error) {
            // 轉換失敗錯誤提示
            // console.error('json資料格式有誤...');
            // console.error(error);
            JSONstr = null;
        }
        return JSONstr;
    }
  }