<template>
  <div class="d-flex form-column align-items-center h-full">
    <div class="title">{{ $t(`${i18nPrefix}lossGames.title`) }}</div>
    <div class="table-block-white-v2">
      <!-- <TreeTable ref="treeTable" :list="list" :column="tableColumn">
        <template v-slot:column_gamePlatform="props">
          {{ getGamePlatformName(props.gamePlatform) }}
        </template>
      </TreeTable> -->
      <table>
        <thead>
          <tr>
            <th>{{ $t(`${this.i18nPrefix}lossGames.gamePlatform`) }}</th>
            <th>{{ $t(`${this.i18nPrefix}lossGames.winLose`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) of list" :key="index">
            <td>
              {{ getGamePlatformName(item.gamePlatform) }}
            </td>
            <td>
              {{ item.winLose }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TreeTable from "@/layout/components/TreeTable";
import { mapGetters } from "vuex";

export default {
  name: "LossGames",
  props: ['list', 'i18nPrefix'],
  data() {
    return {
      digital: new Intl.NumberFormat(),
    };
  },
  watch: {

  },
  components: {
    TreeTable
  },
  computed: {
    ...mapGetters(["getGamePlatforms"]),
    tableColumn() {
      return [
        {
          key: 'gamePlatform',
          name: this.$t(`${this.i18nPrefix}lossGames.gamePlatform`)
        },
        {
          key: 'winLose',
          name: this.$t(`${this.i18nPrefix}lossGames.winLose`)
        },
      ];
    }
  },
  created() {
    
  },
  methods: {
    getGamePlatformName(id) {
      const index = this.getGamePlatforms.findIndex(e=> e.id == id);
      return this.$t(this.getGamePlatforms[index].name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

.h-full {
  height: 100%;
}
</style>