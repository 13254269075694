<template>
  <div class="pagination-block">
    <div class="count">{{ $t("pagination.total") }} {{ totalCount }} {{ $t("pagination.records") }}</div>
    <ul>
      <li @click="handlePage" :class="{ displayNone: getPage === 1 }">&laquo;&laquo;</li>
      <li @click="handlePage" :class="{ displayNone: getPage === 1 }">&laquo;</li>
      <template v-if="server">
        <li
            v-for="list of pageCount"
            :key="list"
            @click="handlePage(list)"
            :class="{ active: serverHandlePage === list }"
        >
          {{ page.current }}
        </li>
      </template>
      <template v-else>
        <li
            v-for="list of pageCount"
            :key="list"
            @click="handlePage(list)"
            :class="{ active: getPage === list }"
        >
          {{ list }}
        </li>
      </template>
      <li @click="handlePage" :class="{ displayNone: getPage === pageCount.length }">&raquo;</li>
      <li @click="handlePage" :class="{ displayNone: getPage === pageCount.length }">&raquo;&raquo;</li>
    </ul>
    <div>
      <span class="mr-2">{{ $t("pagination.showRecordsPerPage") }}</span>
        <select v-model="showCount" @change="changeShowCount">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="all">ALL</option>
        </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Pagination",
  props: ["totalCount", "server"],
  data() {
    return {
      // 因一次顯示8筆，算出共有幾頁。
      arr: [],
      serverHandlePage: 1,
      showCount: 10
    };
  },
  created() {
    for (let i = 1; i <= this.count; i++) {
      this.arr.push(i);
    }
  },
  watch: {
    count() {
      this.arr = [];
      for (let i = 1; i <= this.count; i++) {
        this.arr.push(i);
      }
    },
  },
  computed: {
    count() {
      let count = Math.ceil(this.totalCount / (this.getShowCount === "all" ? this.totalCount : this.getShowCount));
      return count;
    },
    ...mapGetters(["getPage", "getShowCount"]),
    setPage: {
      get() {
        return this.getPage;
      },
      set(value) {
        if (value > this.count || value === 0) return;
        this.SET_PAGE(value);
      },
    },
    setShowCount: {
      get() {
        return this.getShowCount;
      },
      set(value) {
        this.SET_SHOWCOUNT(value);
      }
    },
    pageCount() {
      if (this.count > 11) {
        if (this.getPage <= 5) {
          return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        } else {
          if (this.getPage + 5 <= this.count) {
            let result = [];
            for (let i = 1; i <= 10; i++) {
              result.push(i + this.getPage - 5);
            }
            return result;
          } else if (this.getPage + 5 > this.count) {
            let result = [];
            for (let i = this.count - 9; i <= this.count; i++) {
              result.push(i);
            }
            return result;
          }
        }
      } else {
        return this.arr;
      }
    },
  },
  methods: {
    ...mapMutations({
      SET_PAGE: "app/SET_PAGE",
      SET_SHOWCOUNT: "app/SET_SHOWCOUNT"
    }),
    transferPage(page, toPage) {
      const lastPage = this.pageCount[this.pageCount.length - 1];
      if (page instanceof MouseEvent) {
        if (page.target.outerText === "«") {
          return toPage === 1 ? 1 : (toPage -= 1);
        } else if (page.target.outerText === "««") {
          return 1;
        } else if (page.target.outerText === "»") {
          return toPage === lastPage ? lastPage : (toPage += 1);
        } else {
          return this.count;
        }
      } else {
        return page;
      }
    },
    handlePage(page) {
      if (this.server) {
        this.serverHandlePage = this.transferPage(page, this.serverHandlePage);
        this.$emit("handlePage", this.serverHandlePage);
      } else {
        this.setPage = this.transferPage(page, this.setPage);
        this.$emit("handlePage");
      }
    },
    changeShowCount(e) {
      if (e.target.value === "all") {
        this.setShowCount = "all";
      } else {
        this.setShowCount = e.target.value * 1;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.pagination-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .count {
    left: 2%;
    width: 110px;
    font-weight: 600;
    color: #000;
  }
  ul {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #3D4F59;
    li {
      cursor: pointer;
      padding: 0.2em 0.6em;
      margin-right: 5px;
      font-weight: 600;
      font-size: .8rem;
      transition: 0.2s;
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .page-down {
    span {
      font-weight: 600;
      color: #000;
      margin-right: 5px;
    }
    select {
      border-radius: 20px !important;
      padding: 0 5px;
      color: rgba(0, 0, 0, 0.50);
    }
  }
}

.active {
  background-color: #516875;
  color: #fff !important;
  border-radius: 8px;
}
.displayNone {
  visibility:hidden;
}

@media screen and (max-width: 500px) {
  .pagination-block {
    flex-wrap: wrap;
    align-items: unset;
    justify-content: center;
    margin-top: 10px;
    ul {
      // flex-wrap: wrap;
      margin-right: 0;
      width: 100%;
      li {
        flex-grow: 1;
      }
    }
    .count {
      width: 100%;
      text-align: end;
    }
  }
}
</style>