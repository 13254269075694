import { render, staticRenderFns } from "./member-depositCrypto.vue?vue&type=template&id=8f654956&scoped=true"
import script from "./member-depositCrypto.vue?vue&type=script&lang=js"
export * from "./member-depositCrypto.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f654956",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f654956')) {
      api.createRecord('8f654956', component.options)
    } else {
      api.reload('8f654956', component.options)
    }
    module.hot.accept("./member-depositCrypto.vue?vue&type=template&id=8f654956&scoped=true", function () {
      api.rerender('8f654956', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/member/member-deposit/member-depositCrypto.vue"
export default component.exports