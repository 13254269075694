var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-mask2" }, [
    _c("div", { ref: "dialog", staticClass: "dialog-container" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t(
                `default.${_vm.editData !== undefined ? "edit" : "create"}`
              )
            )
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "dialog-close",
            on: {
              click: function ($event) {
                return _vm.$emit("toggle")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
      _c("div", { staticClass: "dialog-block" }, [
        _c("main", [
          _c("div", { staticClass: "input-block" }, [
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.basicSettings`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}campaignName`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.name,
                        expression: "formModel.name",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}campaignName`),
                    },
                    domProps: { value: _vm.formModel.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formModel, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.auditMethod`)) +
                      " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.auditMethod,
                          expression: "ruleSetting.auditMethod",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ruleSetting,
                            "auditMethod",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.auditMethod, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id } },
                        [_vm._v(_vm._s(_vm.$t(list.name)))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}dialog.wageringRate`) + "(%)"
                      ) +
                      " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.wageringRate,
                        expression: "ruleSetting.wageringRate",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number", min: "0", max: "100" },
                    domProps: { value: _vm.ruleSetting.wageringRate },
                    on: {
                      keyup: function ($event) {
                        _vm.ruleSetting.wageringRate = _vm.GLOBAL.checkValue(
                          _vm.ruleSetting.wageringRate,
                          1
                        )
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting,
                          "wageringRate",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.maximum`)) +
                      " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.paymentAmountRange.maximum,
                        expression: "ruleSetting.paymentAmountRange.maximum",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number", min: "0" },
                    domProps: {
                      value: _vm.ruleSetting.paymentAmountRange.maximum,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting.paymentAmountRange,
                          "maximum",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.minimum`)) +
                      " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.ruleSetting.paymentAmountRange.minimum,
                        expression: "ruleSetting.paymentAmountRange.minimum",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number", min: "0" },
                    domProps: {
                      value: _vm.ruleSetting.paymentAmountRange.minimum,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.ruleSetting.paymentAmountRange,
                          "minimum",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _c("div", { staticClass: "w-100" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.calculationMethod`)
                        ) +
                        " "
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ruleSetting.calculationMethod,
                            expression: "ruleSetting.calculationMethod",
                          },
                        ],
                        staticClass: "w-100",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.ruleSetting,
                              "calculationMethod",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.calculationMethod, function (list) {
                        return _c(
                          "option",
                          { key: list.id, domProps: { value: list.id } },
                          [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("default.startTime")))]),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD HH",
                        "value-type": "format",
                        type: "datetime",
                        "disabled-date": _vm.disabledStart,
                      },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                    _c("span", {
                      ref: "rulesstartTime",
                      staticClass: "form-rules",
                    }),
                  ],
                  1
                ),
                _c(
                  "label",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("default.endTime")))]),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD HH",
                        "value-type": "format",
                        type: "datetime",
                        "disabled-date": _vm.disabledEnd,
                      },
                      model: {
                        value: _vm.endTime,
                        callback: function ($$v) {
                          _vm.endTime = $$v
                        },
                        expression: "endTime",
                      },
                    }),
                    _c("span", {
                      ref: "rulesendTime",
                      staticClass: "form-rules",
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.settleCycle`)) +
                      " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.settleMethod.settleCycle,
                          expression: "ruleSetting.settleMethod.settleCycle",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ruleSetting.settleMethod,
                            "settleCycle",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.settleCycle, function (list) {
                      return _c(
                        "option",
                        { key: list.id, domProps: { value: list.id } },
                        [_vm._v(_vm._s(_vm.$t(list.name)))]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "label",
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.settleTime`))
                      ),
                    ]),
                    _c("date-picker", {
                      attrs: {
                        format: "HH",
                        "value-type": "format",
                        type: "time",
                        "disabled-date": _vm.disabledStart,
                      },
                      model: {
                        value: _vm.ruleSetting.settleMethod.settleTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleSetting.settleMethod,
                            "settleTime",
                            $$v
                          )
                        },
                        expression: "ruleSetting.settleMethod.settleTime",
                      },
                    }),
                    _c("span", {
                      ref: "rulesstartTime",
                      staticClass: "form-rules",
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.proportion`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c("label", { staticStyle: { width: "auto" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.layersNumber`)) +
                      " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.layersNumber,
                          expression: "ruleSetting.layersNumber",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ruleSetting,
                            "layersNumber",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: 1 * 1 } }, [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.one`))),
                      ]),
                      _c("option", { domProps: { value: 2 * 1 } }, [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.two`))),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "table-block table-layers mb-4" }, [
                _c("div", { staticClass: "div-layers" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.one`)) + " "
                  ),
                  _c("div", { staticClass: "add-th" }, [
                    _c(
                      "button",
                      {
                        staticClass: "add-btn",
                        staticStyle: {
                          "background-color": "green",
                          margin: "5px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addData("+", 0)
                          },
                        },
                      },
                      [_c("span", [_vm._v("+")])]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "add-btn",
                        on: {
                          click: function ($event) {
                            return _vm.addData("-", 0)
                          },
                        },
                      },
                      [_c("span", [_vm._v("-")])]
                    ),
                  ]),
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.totalBetAmount`)
                            )
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.rate`) + "(%)"
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.ruleSetting.calculationRate.layerRates[0]
                          .rebateRates,
                        function (list) {
                          return _c("tr", { key: list.level }, [
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.totalBetAmount,
                                    expression: "list.totalBetAmount",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: { type: "number", min: "0" },
                                domProps: { value: list.totalBetAmount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "totalBetAmount",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.rate,
                                    expression: "list.rate",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: { type: "number", min: "0" },
                                domProps: { value: list.rate },
                                on: {
                                  keyup: function ($event) {
                                    list.rate = _vm.GLOBAL.checkValue(
                                      list.rate,
                                      2
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "rate",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
                _vm.ruleSetting.layersNumber == 2
                  ? _c("div", { staticClass: "div-layers" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.two`)) +
                          " "
                      ),
                      _c("div", { staticClass: "add-th" }, [
                        _c(
                          "button",
                          {
                            staticClass: "add-btn",
                            staticStyle: {
                              "background-color": "green",
                              margin: "5px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addData("+", 1)
                              },
                            },
                          },
                          [_c("span", [_vm._v("+")])]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "add-btn",
                            on: {
                              click: function ($event) {
                                return _vm.addData("-", 1)
                              },
                            },
                          },
                          [_c("span", [_vm._v("-")])]
                        ),
                      ]),
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `${_vm.i18nPrefix}dialog.totalBetAmount`
                                  )
                                )
                              ),
                            ]),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(`${_vm.i18nPrefix}dialog.rate`) + "(%)"
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.ruleSetting.calculationRate.layerRates[1]
                              .rebateRates,
                            function (list) {
                              return _c("tr", { key: list.level }, [
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: list.totalBetAmount,
                                        expression: "list.totalBetAmount",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    attrs: { type: "number", min: "0" },
                                    domProps: { value: list.totalBetAmount },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          list,
                                          "totalBetAmount",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: list.rate,
                                        expression: "list.rate",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    attrs: { type: "number", min: "0" },
                                    domProps: { value: list.rate },
                                    on: {
                                      keyup: function ($event) {
                                        list.rate = _vm.GLOBAL.checkValue(
                                          list.rate,
                                          2
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          list,
                                          "rate",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "table-block w-100" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.name`))),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.star")))]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.levelBonusRates`) +
                              "(%)"
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.levelBonusRates, function (list) {
                      return _c("tr", { key: list.level }, [
                        _c("td", [_vm._v(" " + _vm._s(list.name) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(list.level) + " ")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: list.rate,
                                expression: "list.rate",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: { type: "number", min: "0" },
                            domProps: { value: list.rate },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  list,
                                  "rate",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.unleash.isEnabled("member_referral_achievement"),
                    expression:
                      "unleash.isEnabled('member_referral_achievement')",
                  },
                ],
                staticClass: "divider-v2",
              },
              [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.mission`))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.unleash.isEnabled("member_referral_achievement"),
                    expression:
                      "unleash.isEnabled('member_referral_achievement')",
                  },
                ],
                staticClass: "divider-v2-area",
                staticStyle: { display: "block" },
              },
              [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.achievements`))
                  ),
                ]),
                _c("div", { staticClass: "table-block w-100 p-3" }, [
                  _c("div", { staticClass: "add-th" }, [
                    _c(
                      "button",
                      {
                        staticClass: "add-btn",
                        staticStyle: {
                          "background-color": "green",
                          margin: "5px",
                        },
                        on: {
                          click: function ($event) {
                            _vm.ruleSetting.achievements.isEnabled
                              ? _vm.addAchievements("+")
                              : ""
                          },
                        },
                      },
                      [_c("span", [_vm._v("+")])]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "add-btn",
                        on: {
                          click: function ($event) {
                            _vm.ruleSetting.achievements.isEnabled
                              ? _vm.addAchievements("-")
                              : ""
                          },
                        },
                      },
                      [_c("span", [_vm._v("-")])]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "days-row",
                      staticStyle: { padding: "15px" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ruleSetting.achievements.isEnabled,
                            expression: "ruleSetting.achievements.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.achievements.isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.achievements.isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.achievements.isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.ruleSetting.achievements.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting.achievements,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting.achievements,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.achievements,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isEnabled`)) +
                          " "
                      ),
                      _c(
                        "label",
                        { staticStyle: { width: "30% !important" } },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("default.amount")) + " "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.ruleSetting.achievements.amount,
                                expression: "ruleSetting.achievements.amount",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "number",
                              min: "1",
                              disabled: !_vm.ruleSetting.achievements.isEnabled,
                            },
                            domProps: {
                              value: _vm.ruleSetting.achievements.amount,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.ruleSetting.achievements,
                                  "amount",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.numberOfMembers`)
                            )
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.bonusAmount`)
                            )
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}dialog.bettingTurnover`)
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.ruleSetting.achievements.list,
                        function (list, index) {
                          return _c("tr", { key: "achievements" + index }, [
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.condition.numberOfMembers,
                                    expression:
                                      "list.condition.numberOfMembers",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  disabled:
                                    !_vm.ruleSetting.achievements.isEnabled,
                                },
                                domProps: {
                                  value: list.condition.numberOfMembers,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list.condition,
                                      "numberOfMembers",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.bonusAmount,
                                    expression: "list.bonusAmount",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  disabled:
                                    !_vm.ruleSetting.achievements.isEnabled,
                                },
                                domProps: { value: list.bonusAmount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "bonusAmount",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.bettingTurnover,
                                    expression: "list.bettingTurnover",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  disabled:
                                    !_vm.ruleSetting.achievements.isEnabled,
                                },
                                domProps: { value: list.bettingTurnover },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "bettingTurnover",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.eligibility`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.includeAgents`)
                        ) +
                        " "
                    ),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "id",
                          placeholder: "",
                          options: _vm.agentList,
                          multiple: true,
                          "close-on-select": false,
                        },
                        model: {
                          value: _vm.agentData,
                          callback: function ($$v) {
                            _vm.agentData = $$v
                          },
                          expression: "agentData",
                        },
                      },
                      [
                        _c("template", { slot: "noOptions" }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberLevel`)
                        ) +
                        " "
                    ),
                    _c("multiselect", {
                      attrs: {
                        label: "name",
                        "track-by": "star",
                        placeholder: "",
                        options: _vm.starList,
                        multiple: true,
                        "close-on-select": false,
                      },
                      model: {
                        value: _vm.memberStar,
                        callback: function ($$v) {
                          _vm.memberStar = $$v
                        },
                        expression: "memberStar",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberTag`)
                        ) +
                        " "
                    ),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "id",
                          placeholder: "",
                          options: _vm.tagList,
                          multiple: true,
                          "close-on-select": false,
                        },
                        model: {
                          value: _vm.memberTag,
                          callback: function ($$v) {
                            _vm.memberTag = $$v
                          },
                          expression: "memberTag",
                        },
                      },
                      [
                        _c("template", { slot: "noOptions" }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(`${_vm.i18nPrefix}dialog.CategorysAndPlatforms`)
                  )
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-box star-box w-100" },
              _vm._l(_vm.getGameCategoryAndPlatform, function (list, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "divider-v2-area" },
                  [
                    _c(
                      "div",
                      { staticClass: "divider-v2-text divider-v2-left" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.GLOBAL.i18nCheckName(
                              _vm.getGamesCategorys.find(
                                (data) => data.id == key
                              )
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._l(list, function (gamePlatforms) {
                      return _c(
                        "label",
                        { key: gamePlatforms.id, staticClass: "span-row" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleSetting.gameConditions,
                                expression: "ruleSetting.gameConditions",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: {
                                category: key * 1,
                                platform: gamePlatforms.id,
                              },
                              checked: Array.isArray(
                                _vm.ruleSetting.gameConditions
                              )
                                ? _vm._i(_vm.ruleSetting.gameConditions, {
                                    category: key * 1,
                                    platform: gamePlatforms.id,
                                  }) > -1
                                : _vm.ruleSetting.gameConditions,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.ruleSetting.gameConditions,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = {
                                      category: key * 1,
                                      platform: gamePlatforms.id,
                                    },
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.ruleSetting,
                                        "gameConditions",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.ruleSetting,
                                        "gameConditions",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.ruleSetting,
                                    "gameConditions",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.GLOBAL.i18nCheckName(
                                  _vm.getGamePlatforms.find(
                                    (data) => data.id == gamePlatforms.id
                                  )
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.front`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box w-100" }, [
              _c(
                "label",
                { staticClass: "w-100" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.copywriting`)) +
                      " "
                  ),
                  _c("ckeditor", {
                    attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
                    model: {
                      value: _vm.ruleSetting.html,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleSetting, "html", $$v)
                      },
                      expression: "ruleSetting.html",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detail == undefined,
              expression: "detail == undefined",
            },
          ],
          staticClass: "advance-block-release",
        },
        [
          _c("div", { staticClass: "m-auto" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.confirm")))]
            ),
            _c(
              "button",
              {
                staticClass: "delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggle")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.cancel")))]
            ),
          ]),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isCampaign == undefined && _vm.editData !== undefined,
                  expression:
                    "isCampaign == undefined && editData !== undefined",
                },
              ],
              staticClass: "btn-orange",
              on: {
                click: function ($event) {
                  return _vm.submit(true)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}release`)))]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }