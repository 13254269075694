import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getMediaLibraryList(data) {
    return request({
        url: "/MediaLibrary/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createMediaLibrary(data) {
    return request({
        url: "/MediaLibrary/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMediaLibrary(data) {
    return request({
        url: "/MediaLibrary/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteMediaLibrary(data) {
    return request({
        url: "/MediaLibrary/Delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}