<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <main>
          <ul class="detail-ul">
            <li class="detail-ul-li" v-for="(item, index) of list" :key="index">
              <div class="detail-ul-li-title">{{ item.name }}</div>
              <div class="detail-ul-li-value">{{ item.value }}</div>
            </li>
          </ul>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryList",
  props: ["history", "i18nPrefix"],
  data() {
    return {};
  },
  components: {},
  computed: {
    list() {
      return [
        {
          name: this.$t(`${this.i18nPrefix}dialog.historyList.totalDeposit`),
          value: this.history.totalDeposit
        },
        {
          name: this.$t(`${this.i18nPrefix}dialog.historyList.totalWithdrawal`),
          value: this.history.totalWithdrawal
        },
        {
          name: this.$t(`${this.i18nPrefix}dialog.historyList.totalWinLose`),
          value: this.history.totalWinLose
        }
      ];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 20%;
  .input-block {
    label {
      width: 40%;
    }
  }
  .detail-ul {
    font-size: 14px;
    &-li {
      padding: 5px;
      border-bottom: 1px solid #c6c6c6;
      display: flex;
      &-title {
        flex-basis: 25%;
      }
      &-value {
        flex-basis: 75%;
      }
    }
  }
}
.opacity-5 {
  opacity: 0.5;
}
</style>