<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t(`${i18nPrefix}table.auditTime`) }}</th>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}table.from`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.multiples`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.fromPreTradeBalance`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.fromAfterTradeBalance`) }}</th>
        <th>{{ $t("default.amount") }}</th>
        <th>{{ $t(`${i18nPrefix}table.to`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.toPreTradeBalance`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.toAfterTradeBalance`) }}</th>
        <th>{{ $t("default.status") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ transferForm.transferTime }}</td>
        <td>{{ transferForm.memberAccount }}</td>
        <td>{{ $t("GamePlatform." + transferForm.fromPlatform) }}</td>
        <td>{{ transferForm.multiples }}</td>
        <td>{{ transferForm.fromPreTradeBalance }}</td>
        <td>{{ transferForm.fromAfterTradeBalance }}</td>
        <td :class="fontColor(transferForm.amount)">{{ transferForm.amount }}</td>
        <td>{{ $t("GamePlatform." + transferForm.toPlatform) }}</td>
        <td>{{ transferForm.toPreTradeBalance }}</td>
        <td>{{ transferForm.toAfterTradeBalance }}</td>
        <td>{{ $t(transferForm.orderStatus) }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { transferModel } from "../model";

export default {
  name: "transferForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      transferForm: _.cloneDeep(transferModel),
      orderStatusList: [
        {
          id: 1,
          name: `${this.i18nPrefix}table.undone`,
        },
        {
          id: 2,
          name: `${this.i18nPrefix}table.success`,
        },
        {
          id: 3,
          name: `${this.i18nPrefix}table.fail`,
        },
      ],
    };
  },
  watch: {
    detailsData() {
      this.transferForm = {
        id: this.detailsData.id,
        transferTime: this.detailsData.transferTime,
        memberAccount: this.detailsData.memberAccount,
        fromPlatform: this.detailsData.fromPlatform,
        multiples: this.detailsData.multiples,
        fromPreTradeBalance: this.digital.format(
          this.detailsData.fromPreTradeBalance
        ),
        fromAfterTradeBalance: this.digital.format(
          this.detailsData.fromAfterTradeBalance
        ),
        amount: this.digital.format(this.detailsData.amount),
        toPlatform: this.detailsData.toPlatform,
        toPreTradeBalance: this.digital.format(
          this.detailsData.toPreTradeBalance
        ),
        toAfterTradeBalance: this.digital.format(
          this.detailsData.toAfterTradeBalance
        ),
        orderStatus: this.orderStatusList.find(
          (data) => data.id === this.detailsData.orderStatus
        )?.name,
      };
    },
  },
  methods: {
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
