<template>
  <table>
    <thead>
      <tr>
        <th>{{ $t(`${i18nPrefix}table.name`) }}</th>
        <th v-show="detailsData.discountType">{{ $t(`${i18nPrefix}table.discountType`) }}</th>
        <th>{{ $t("default.member") }}</th>
        <th>{{ $t(`${i18nPrefix}table.createTime`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.bonusReceive`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.totalBetAmountLimit`) }}</th>
        <th>{{ $t(`${i18nPrefix}table.totalValidBetAmount`) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ discountForm.name }}</td>
        <td v-show="detailsData.discountType">{{ GLOBAL.i18nCheckName(getDiscountTypeList.find(
          (data) => data.id === detailsData.discountType
        )) }}</td>
        <td>{{ discountForm.memberAccount }}</td>
        <td>{{ discountForm.createTime }}</td>
        <td>{{ discountForm.bonusReceive }}</td>
        <td>{{ discountForm.totalBetAmountLimit }}</td>
        <td>{{ discountForm.betAmountLimit }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { discountModel } from "../model";
import { mapGetters } from "vuex";

export default {
  name: "discountForm",
  props: {
    detailsData: {},
    digital: {},
    i18nPrefix: String,
  },
  data() {
    return {
      discountForm: _.cloneDeep(discountModel),
    };
  },
  watch: {
    detailsData() {
      this.discountForm = {
        name: this.detailsData.name,
        discountType: this.detailsData?.discountType,
        memberAccount: this.detailsData.memberAccount,
        createTime: this.detailsData.createTime,
        bonusReceive: this.digital.format(this.detailsData.bonusReceive),
        totalBetAmountLimit: this.digital.format(
          this.detailsData.totalBetAmountLimit
        ),
        betAmountLimit: this.digital.format(
          this.detailsData.totalValidBetAmount
        ),
      };
    },
  },
  computed: {
    ...mapGetters(["getDiscountTypeList"]),
  },
};
</script>
