<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <div class="btn-list">
          <button
            v-for="list of getDeviceType"
            :key="list.id"
            @click="deviceType = list.id"
            :class="{ active: deviceType === list.id }"
          >{{ $t(list.name) }}</button>
        </div>
        <main>
          <div class="input-block">
            <div style="width: 100%">
              <img
                v-if="deviceType === 0"
                :src="editModel.imageUrl"
                style="width: auto; max-width: 80%; display: block; margin: auto"
              />
              <img
                v-else-if="deviceType === 1"
                :src="editModel.mobileImageUrl"
                style="width: auto; max-width: 80%; display: block; margin: auto"
              />
              <img
                v-else
                :src="editModel.appImageUrl"
                style="width: auto; max-width: 80%; display: block; margin: auto"
              />
              <img
                v-show="false"
                :src="editModel.imageUrl"
                ref="imageImg"
                style="display: block; margin: auto;"
              />
              <img
                v-show="false"
                :src="editModel.mobileImageUrl"
                ref="mobileImg"
                style="display: block; margin: auto;"
              />
              <img
                v-show="false"
                :src="editModel.appImageUrl"
                ref="appImg"
                style="display: block; margin: auto;"
              />
            </div>
            <label>
              {{ $t(`${i18nPrefix}subject`) }}
              <select v-model="editModel.subject">
                <option v-for="list of subjects" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}description`) }}
              <input
                type="text"
                v-model="editModel.Description"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.page`) }}
              <select v-model="editModel.page">
                <option v-for="list of pages" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <!-- <label>
              {{ $t(`${i18nPrefix}dialog.discountType`) }}
              <select v-if="editModel.page === 1" v-model="editModel.discountType">
                <option v-for="list of SelectMenu" :key="list.id" :value="list.id">
                  {{ list.name }}
                </option>
              </select>
              <select v-else v-model="editModel.pageType">
                <option  v-for="list of gameCategoryList" :key="list.id" :value="list.id">
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>-->
            <label>
              {{ $t("default.status") }}
              <select v-model="editModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.sort") }}
              <input type="number" v-model="editModel.order" />
            </label>
            <label style="width: 80%">
              {{ $t(`${i18nPrefix}dialog.redirectUrl`) }}
              <input
                v-if="deviceType !== 2"
                type="text"
                v-model="editModel.redirectUrl"
              />
              <input
                v-else
                type="text"
                v-model="editModel.appRedirectUrl"
              />
            </label>
            <label class="fileName pc-style">
              {{ $t(`${i18nPrefix}dialog.fileName`) }}
              <div v-if="deviceType === 0" class="handleFile">
                <input type="file" ref="imageFile" class="custom-file" @change="fileSelect" />
                <input type="text" v-model="editModel.imageName" />
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
              <div v-else-if="deviceType === 1" class="handleFile">
                <input type="file" ref="mobileFile" class="custom-file" @change="fileSelect" />
                <input type="text" v-model="editModel.mobileImageName" />
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
              <div v-else class="handleFile">
                <input type="file" ref="appFile" class="custom-file" @change="fileSelect" />
                <input type="text" v-model="editModel.appImageName" />
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
            </label>
            <label class="mobile-style fileName">
              <div class="handleFile">
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageType`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="editModel.imageType"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="editModel.mobileImageType"
                disabled
              />
              <input v-else type="text" v-model="editModel.appImageType" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageSize`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="editModel.imageSize"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="editModel.mobileImageSize"
                disabled
              />
              <input v-else type="text" v-model="editModel.appImageSize" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageWidth`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="editModel.imageWidth"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="editModel.mobileImageWidth"
                disabled
              />
              <input v-else type="text" v-model="editModel.appImageWidth" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageHeight`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="editModel.imageHeight"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="editModel.mobileImageHeight"
                disabled
              />
              <input v-else type="text" v-model="editModel.appImageHeight" disabled />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/layout/components/Message";
import { mapGetters, mapMutations } from "vuex";
import { checkData } from "@/utils/checkData";

export default {
  name: "Edit",
  props: [
    "id",
    "bindId",
    "bindType",
    "description",
    "order",
    "redirectUrl",
    "appRedirectUrl",
    "status",
    "pageType",
    "imageUrl",
    "imageName",
    "imageSize",
    "imageType",
    "imageWidth",
    "imageHeight",
    "mobileImageUrl",
    "mobileImageName",
    "mobileImageType",
    "mobileImageSize",
    "mobileImageWidth",
    "mobileImageHeight",
    "appImageUrl",
    "appImageName",
    "appImageType",
    "appImageSize",
    "appImageWidth",
    "appImageHeight",
    "i18nPrefix"
  ],
  data() {
    return {
      editModel: {
        subject: this.pageType,
        status: this.status,
        Description: this.description,
        order: this.order,
        redirectUrl: this.redirectUrl,
        appRedirectUrl: this.appRedirectUrl,
        imageUrl: this.imageUrl
          ? this.imageUrl + "?v=" + new Date().getTime()
          : "",
        imageName: this.imageName,
        imageType: this.imageType,
        imageSize: this.imageSize,
        imageWidth: this.imageWidth,
        imageHeight: this.imageHeight,
        mobileImageUrl: this.mobileImageUrl
          ? this.mobileImageUrl + "?v=" + new Date().getTime()
          : "",
        mobileImageName: this.mobileImageName,
        mobileImageType: this.mobileImageType,
        mobileImageSize: this.mobileImageSize,
        mobileImageWidth: this.mobileImageWidth,
        mobileImageHeight: this.mobileImageHeight,
        appImageUrl: this.appImageUrl
          ? this.appImageUrl + "?v=" + new Date().getTime()
          : "",
        appImageName: this.appImageName,
        appImageType: this.appImageType,
        appImageSize: this.appImageSize,
        appImageWidth: this.appImageWidth,
        appImageHeight: this.appImageHeight,
        discountType: this.bindId,
        pageType: this.bindId,
        page: this.bindType
      },
      formData: new FormData(),
      subjects: [
        {
          id: 1,
          name: "features.banner.home"
        }
      ],
      pages: [
        {
          id: 1,
          name: "features.banner.dialog.discount"
        },
        {
          id: 2,
          name: "features.banner.dialog.page2"
        }
      ],
      deviceType: 0,
      chooiceImg: undefined
    };
  },
  computed: {
    ...mapGetters(["getStatusList", "getDeviceType"])
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM"
    }),
    async submit() {
      if (!this.editModel.mobileImageUrl || !this.editModel.imageUrl) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.msg`), 1000);
        return;
      }
      if (!checkData(this.editModel)) return;
      this.formData.append("id", this.id);
      this.formData.append("PageType", this.editModel.subject);
      this.formData.append("Description", this.editModel.Description);
      this.formData.append("Order", this.editModel.order);
      this.formData.append("RedirectUrl", this.editModel.redirectUrl);
      this.formData.append("appRedirectUrl", this.editModel.appRedirectUrl);
      this.formData.append("Status", this.editModel.status);
      this.formData.append("imageUrl", this.editModel.imageUrl);
      this.formData.append("imageName", this.editModel.imageName);
      this.formData.append("ImageType", this.editModel.imageType);
      this.formData.append("imageSize", this.editModel.imageSize);
      this.formData.append("imageHeight", this.editModel.imageHeight);
      this.formData.append("imageWidth", this.editModel.imageWidth);
      this.formData.append("mobileImageUrl", this.editModel.mobileImageUrl);
      this.formData.append("mobileImageName", this.editModel.mobileImageName);
      this.formData.append("mobileImageType", this.editModel.mobileImageType);
      this.formData.append("mobileImageSize", this.editModel.mobileImageSize);
      this.formData.append(
        "mobileImageHeight",
        this.editModel.mobileImageHeight
      );
      this.formData.append("mobileImageWidth", this.editModel.mobileImageWidth);
      this.formData.append("appImageUrl", this.editModel.appImageUrl);
      this.formData.append("appImageName", this.editModel.appImageName);
      this.formData.append("appImageType", this.editModel.appImageType);
      this.formData.append("appImageSize", this.editModel.appImageSize);
      this.formData.append("appImageHeight", this.editModel.appImageHeight);
      this.formData.append("appImageWidth", this.editModel.appImageWidth);
      this.formData.append("BindType", this.editModel.page);
      this.formData.append(
        "BindId",
        this.editModel.page === 1
          ? this.editModel.discountType
          : this.editModel.pageType
      );

      this.$emit("edit", this.formData);
    },
    upload(deviceType) {
      var file = '';
      switch(deviceType) {
        case 0:
          file = 'image';
          break;
        case 1:
          file = 'mobile'; 
          break;
        case 2:
          file = 'app';
          break;
      }

      this.chooiceImg = file;
      this.$refs[file + "File"].value = "";
      this.$refs[file + "File"].click();
    },
    fileSelect(e) {
      const type = this.chooiceImg;
      this.editModel[
        (type === "image" ? type : type + "Image") + "Url"
      ] = URL.createObjectURL(e.target.files[0]);
      setTimeout(() => {
        this.editModel[
          (type === "image" ? type : type + "Image") + "Height"
        ] = `${this.$refs[type + "Img"].height}px`;
        this.editModel[
          (type === "image" ? type : type + "Image") + "Width"
        ] = `${this.$refs[type + "Img"].width}px`;
      }, 100);
      this.editModel[(type === "image" ? type : type + "Image") + "Name"] =
        e.target.files[0].name;
      this.editModel[(type === "image" ? type : type + "Image") + "Type"] =
        e.target.files[0].type;
      this.editModel[(type === "image" ? type : type + "Image") + "Size"] =
        e.target.files[0].size;
      this.formData.append(
        (type === "image" ? "" : type) + "File",
        e.target.files[0]
      );
    },
    deleteFunc(deviceType) {
      if (deviceType === 0) {
        this.editModel.imageUrl = undefined;
        this.editModel.imageName = undefined;
        this.editModel.imageType = undefined;
        this.editModel.imageSize = undefined;
        this.editModel.imageSize = undefined;
        this.editModel.imageWidth = undefined;
        this.editModel.imageHeight = undefined;
        this.formData.delete("File");
      } else if (deviceType === 1) {
        this.editModel.mobileImageUrl = undefined;
        this.editModel.mobileImageName = undefined;
        this.editModel.mobileImageType = undefined;
        this.editModel.mobileImageSize = undefined;
        this.editModel.mobileImageSize = undefined;
        this.editModel.mobileImageWidth = undefined;
        this.editModel.mobileImageHeight = undefined;
        this.formData.delete("mobileFile");
      } else {
        this.editModel.appImageUrl = undefined;
        this.editModel.appImageName = undefined;
        this.editModel.appImageType = undefined;
        this.editModel.appImageSize = undefined;
        this.editModel.appImageSize = undefined;
        this.editModel.appImageWidth = undefined;
        this.editModel.appImageHeight = undefined;
        this.formData.delete("appFile");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.active {
  background-color: #111fa0 !important;
  color: white;
  border: none;
}
.btn-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  button {
    width: 20%;
    margin: 1%;
    padding: 1%;
    border-radius: 10px;
    border: 1px solid #999;
    background-color: #17a2b8;
    &:hover {
      background-color: #117a8b;
      color: white;
    }
  }
}
.mobile-style {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-style {
    display: flex;
    justify-content: flex-end;
    padding: 5px 2px;
    .handleFile {
      button {
        width: 100% !important;
      }
    }
  }
  .pc-style {
    .handleFile {
      input {
        width: 100% !important;
      }
      .upload, .delete {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .btn-list {
    button {
      width: 35%;
    }
  }
}
</style>