<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}create`) }}</h4>
        </div>
        <div class="divider"></div>
        <h3>{{ $t(`${i18nPrefix}id`) }}：{{ id }}</h3>
        <div>
          <button class="normal-btn mr-3" @click="handleEdit(2)">{{ $t(`${i18nPrefix}dialog.accept`) }}</button>
          <button class="delete-btn" @click="handleEdit(3)">{{ $t(`${i18nPrefix}dialog.reject`) }}</button>
        </div>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" id="checkAll" @click="checkAll" />
                  </th>
                  <th>{{ $t("default.agent") }}</th>
                  <th>{{ $t("default.member") }}</th>
                  <th>{{ $t(`${i18nPrefix}betTimeFrom`) }}</th>
                  <th>{{ $t(`${i18nPrefix}betTimeTo`) }}</th>
                  <th>{{ $t(`${i18nPrefix}toAmount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.gamePlatform`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.gamesCategory`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.originStarLevel`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.newStarLevel`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.movementList`) }}</th>
                  <th>{{ $t("default.status") }}</th>
                  <th>{{ $t("default.remark") }}</th>
                  <th>{{ $t("default.admin") }}</th>
                  <th>{{ $t(`${i18nPrefix}approveTime`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <button class="normal-btn" @click="Clear">{{ $t(`${i18nPrefix}dialog.clear`) }}</button>
                  </th>
                  <th></th>
                  <th>
                    <input type="text" v-model="searchForm.memberAccount" />
                  </th>
                  <th>
                    <input
                      id="sTime"
                      type="date"
                      v-model="searchForm.betTimeFrom"
                    />
                    <button
                      class="normal-btn"
                      @click="searchForm.betTimeFrom = undefined"
                    >
                      {{ $t(`${i18nPrefix}dialog.clear`) }}
                    </button>
                  </th>
                  <th>
                    <input
                      id="eTime"
                      type="date"
                      v-model="searchForm.betTimeTo"
                    />
                    <button
                      class="normal-btn"
                      @click="searchForm.betTimeTo = undefined"
                    >
                      {{ $t(`${i18nPrefix}dialog.clear`) }}
                    </button>
                  </th>
                  <th></th>
                  <th>
                    <!-- <input type="text" v-model="searchForm.gamePlatformId" /> -->
                  </th>
                  <th>
                    <!-- <input type="text" v-model="searchForm.gameCategory" /> -->
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                    <select v-model="searchForm.movement">
                      <option
                        v-for="list of movementList"
                        :key="list.id"
                        :value="list.id * 1"
                      >
                        {{ list.name }}
                      </option>
                    </select>
                  </th>
                  <th>
                    <select v-model="searchForm.starLevelAuditStatus">
                      <option
                        v-for="list of starLevelAuditStatusList"
                        :key="list.id"
                        :value="list.id * 1"
                      >
                        {{ list.name }}
                      </option>
                    </select>
                  </th>
                  <th><input type="text" v-model="searchForm.remark" /></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>
                    <input
                      class="checkAll"
                      v-if="
                        list.starLevelAuditStatus !== 2 &&
                        list.starLevelAuditStatus !== 3
                      "
                      :id="list.id"
                      type="checkbox"
                      v-model="editModel.ids"
                      :value="list.id"
                    />
                    <input v-else type="checkbox" style="visibility: hidden" />
                  </td>
                  <td>{{ agentList.find(data => data.id === list.agentId).account }}</td>
                  <td>{{ list.memberAccount }}</td>
                  <td>{{ list.betTimeFrom }}</td>
                  <td>{{ list.betTimeTo }}</td>
                  <td>{{ list.singleGamePlatformBetMaximum }}</td>
                  <td>{{ gamePlatformId(list.gamePlatformId) }}</td>
                  <td>{{ gameCategory(list.gameCategory) }}</td>
                  <td>{{ list.originStarLevel + $t("default.star") }}</td>
                  <td>{{ list.newStarLevel + $t("default.star") }}</td>
                  <td>{{ movementList.find((data) => data.id === list.movement).name }}</td>
                  <td>{{ starLevelAuditStatusList.find((data) => data.id === list.starLevelAuditStatus).name }}</td>
                  <td>{{ list.remark }}</td>
                  <td>{{ list.auditAccount }}</td>
                  <td>{{ list.auditTime }}</td>
                </tr>
              </tbody>
            </table>
            <Pagination
              v-if="totalCount > 0"
              :totalCount="totalCount"
              @handlePage="handlePage"
            ></Pagination>
          </div>
        </main>
      </div>
    </div>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
          v-if="alertMsg"
          :message="alertMsg"
          @toggle="alertMsg = undefined"
          @doIt="edit"
      />
    </transition>
  </div>
</template>

<script>
import { starLevelUpList, selectMenu, upRecordSelectMenu } from "@/api/member/member-level";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import Pagination from "@/layout/components/Pagination";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";

export default {
  name: "UpRecordEdit",
  props: ["id","agentList","i18nPrefix"],
  data() {
    return {
      defaultData: undefined,
      editModel: {
        auditStatus: undefined,
        ids: [],
      },
      searchForm: {
        memberAccount: undefined,
        betTimeFrom: undefined,
        betTimeTo: undefined,
        // gamePlatformId: undefined,
        // gameCategory: undefined,
        movement: undefined,
        starLevelAuditStatus: undefined,
        remark: undefined,
      },
      alertMsg: undefined,
      gamePlatformIdList: [],
      movementList: [
        {
          id: "0",
          name: "",
        },
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}dialog.promotion`),
        },
        {
          id: 2,
          name: this.$t(`${this.i18nPrefix}dialog.downgrade`),
        },
      ],
      starLevelAuditStatusList: [
        {
          id: "0",
          name: "",
        },
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}dialog.pending`),
        },
        {
          id: 2,
          name: this.$t(`${this.i18nPrefix}dialog.accept`),
        },
        {
          id: 3,
          name: this.$t(`${this.i18nPrefix}dialog.reject`),
        },
      ],
      gameCategoryList: [],
    };
  },
  components: {
    Alert,
    Pagination,
  },
  watch: {
    searchForm: {
      handler() {
        this.searchTable();
      },
      deep: true,
    },
    "editModel.ids": function () {
      let checkElements = document.getElementsByClassName("checkAll");
      for (let index = 0; index < checkElements.length; index++) {
        let checkCount = 0;
        for (let i = 0; i < checkElements.length; i++) {
          if (
            this.editModel.ids.find(
              (data) => data === checkElements[i].value * 1
            )
          )
            checkCount++;
        }
        if (checkCount === checkElements.length) {
          document.getElementById("checkAll").checked = true;
        } else {
          document.getElementById("checkAll").checked = null;
        }
      }
    },
  },
  mixins: [handlePageUtils],
  created() {
    selectMenu().then((res) => {
      this.gamePlatformIdList = res.data.gamePlatformList
          .map(data => { return { id: data.id , name: "GamePlatform."+data.name}})
    });
    upRecordSelectMenu().then((res) => {
      this.gameCategoryList = res.data.gameCategory
          .map(data => { return { id: data.id , name: "GameCategory."+data.name}})
    })
    starLevelUpList({ starLevelUpRecordId: this.id }).then((res) => {
      this.allData = res.data;
      this.defaultData = _.cloneDeep(this.allData);
    });
  },
  methods: {
    handleEdit(auditStatus) {
      this.alertMsg = this.$t(`${this.i18nPrefix}dialog.alertMsg2`);
      this.editModel.auditStatus = auditStatus
    },
    edit() {
      this.alertMsg = undefined;
      this.$emit("UpAudit", this.editModel);
    },
    searchTable() {
      this.allData = this.defaultData;
      if (this.searchForm.memberAccount)
        this.allData = this.allData.filter(
          (data) =>
            data.memberAccount.indexOf(this.searchForm.memberAccount) !== -1
        );
      if (this.searchForm.betTimeFrom)
        this.allData = this.allData.filter(
          (data) => data.betTimeFrom.indexOf(this.searchForm.betTimeFrom) !== -1
        );
      if (this.searchForm.betTimeTo)
        this.allData = this.allData.filter(
          (data) => data.betTimeTo.indexOf(this.searchForm.betTimeTo) !== -1
        );
      // if (this.searchForm.gamePlatformId)
      //   this.allData = this.allData.filter(
      //     (data) => data.gamePlatformId === this.searchForm.gamePlatformId * 1
      //   );
      // if (this.searchForm.gameCategory)
      //   this.allData = this.allData.filter(
      //     (data) => data.gameCategory === this.searchForm.gameCategory * 1
      //   );
      if (this.searchForm.movement)
        this.allData = this.allData.filter(
          (data) => data.movement === this.searchForm.movement * 1
        );
      if (this.searchForm.starLevelAuditStatus)
        this.allData = this.allData.filter(
          (data) =>
            data.starLevelAuditStatus ===
            this.searchForm.starLevelAuditStatus * 1
        );
      if (this.searchForm.remark)
        this.allData = this.allData.filter(
          (data) => data.remark.indexOf(this.searchForm.remark) !== -1
        );
    },
    gameCategory(category) {
      return (
        this.$t(this.gameCategoryList.find((data) => data.id === category)?.name) || ""
      );
    },
    gamePlatformId(platformId) {
      return (
        this.$t(this.gamePlatformIdList.find((data) => data.id === platformId)?.name) ||
        ""
      );
    },
    Clear() {
      this.searchForm.memberAccount = undefined;
      this.searchForm.betTimeFrom = undefined;
      this.searchForm.betTimeTo = undefined;
      this.searchForm.movement = undefined;
      this.searchForm.starLevelAuditStatus = undefined;
      this.searchForm.remark = undefined;
    },
    checkAll() {
      let checkElements = document.getElementsByClassName("checkAll");
      for (var i = 0; i < checkElements.length; i++) {
        if (document.getElementById("checkAll").checked) {
          if (this.editModel.ids.indexOf(checkElements[i].value * 1) === -1)
            this.editModel.ids.push(checkElements[i].value * 1);
        } else {
          this.editModel.ids.splice(
            this.editModel.ids.indexOf(checkElements[i].value * 1),
            1
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  .input-block {
    label {
      width: 40%;
    }
  }
}
</style>