const state = {
    // create: false,
    // edit: false,
    // status: false,
    // agent: false,
    // createBank: false,
    // editBank: false,
    currentDialogComponent: undefined
}
const mutations = {
    // TOGGLE_CREATE(state) {
    //     state.create = !state.create;
    // },
    // TOGGLE_EDIT(state) {
    //     state.edit = !state.edit;
    // },
    // TOGGLE_STATUS(state) {
    //     state.status = !state.status;
    // },
    // TOGGLE_AGENT(state) {
    //     state.agent = !state.agent;
    // },
    // TOGGLE_CREATE_BANK(state) {
    //     state.createBank = !state.createBank;
    // },
    // TOGGLE_EDIT_BANK(state) {
    //     state.editBank = !state.editBank;
    // },
    RESET_DIALOG(state) {
        // state.create = false;
        // state.edit = false;
        // state.status = false;
        // state.agent = false;
        // state.createBank = false;
        // state.editBank = false;
        state.currentDialogComponent = undefined;
    },
    SET_CURRDIALOG(state, payload) {
        state.currentDialogComponent = payload;
    }
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}