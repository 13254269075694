<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.create") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <div class="btn-list">
          <button
            v-for="list of getDeviceType"
            :key="list.id"
            @click="deviceType = list.id"
            :class="{ active: deviceType === list.id }"
          >{{ $t(list.name) }}</button>
        </div>
        <main>
          <div class="input-block">
            <div style="width: 100%">
              <img
                v-if="deviceType === 0"
                :src="createModel.imageUrl"
                style="width: auto; max-width: 80%; display: block; margin: auto"
              />
              <img
                v-else-if="deviceType === 1"
                :src="createModel.mobileImageUrl"
                style="width: auto; max-width: 80%; display: block; margin: auto"
              />
              <img
                v-else
                :src="createModel.appImageUrl"
                style="width: auto; max-width: 80%; display: block; margin: auto"
              />
              <img
                v-show="false"
                :src="createModel.imageUrl"
                ref="imageImg"
                style="display: block; margin: auto;"
              />
              <img
                v-show="false"
                :src="createModel.mobileImageUrl"
                ref="mobileImg"
                style="display: block; margin: auto;"
              />
              <img
                v-show="false"
                :src="createModel.appImageUrl"
                ref="appImg"
                style="display: block; margin: auto;"
              />
            </div>
            <label>
              {{ $t(`${i18nPrefix}subject`) }}
              <select v-model="createModel.subject">
                <option v-for="list of subjects" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t(`${i18nPrefix}description`) }}
              <input
                type="text"
                v-model="createModel.Description"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.page`) }}
              <select v-model="createModel.page">
                <option v-for="list of pages" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
              </select>
            </label>
            <!-- <label>
              {{ $t(`${i18nPrefix}dialog.discountType`) }}
              <select v-if="createModel.page === 1" v-model="createModel.discountType">
                <option
                  v-for="list of SelectMenu"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
              <select v-else v-model="createModel.pageType">
                <option
                  v-for="list of gameCategoryList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ $t(list.name) }}
                </option>
              </select>
            </label>-->
            <label>
              {{ $t("default.status") }}
              <select v-model="createModel.status">
                <option
                  v-for="list of getStatusList"
                  :key="list.id"
                  :value="list.id"
                >{{ $t(list.name) }}</option>
              </select>
            </label>
            <label>
              {{ $t("default.sort") }}
              <input type="number" v-model="createModel.order" />
            </label>
            <label style="width: 80%">
              {{ $t(`${i18nPrefix}dialog.redirectUrl`) }}
              <input
                v-if="deviceType !== 2"
                type="text"
                v-model="createModel.redirectUrl"
              />
              <input
                v-else
                type="text"
                v-model="createModel.appRedirectUrl"
              />
            </label>
            <label class="fileName pc-style">
              {{ $t(`${i18nPrefix}dialog.fileName`) }}
              <div v-if="deviceType === 0" class="handleFile">
                <input type="file" ref="imageFile" class="custom-file" @change="fileSelect" />
                <input type="text" v-model="createModel.imageName" />
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
              <div v-else-if="deviceType === 1" class="handleFile">
                <input type="file" ref="mobileFile" class="custom-file" @change="fileSelect" />
                <input type="text" v-model="createModel.mobileImageName" />
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
              <div v-else class="handleFile">
                <input type="file" ref="appFile" class="custom-file" @change="fileSelect" />
                <input type="text" v-model="createModel.appImageName" />
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
            </label>
            <label class="mobile-style fileName">
              <div class="handleFile">
                <button class="upload" @click="upload(deviceType)">{{ $t("default.browse") }}</button>
                <button class="delete" @click="deleteFunc(deviceType)">{{ $t("default.delete") }}</button>
              </div>
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageType`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="createModel.imageType"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="createModel.mobileImageType"
                disabled
              />
              <input v-else type="text" v-model="createModel.appImageType" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageSize`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="createModel.imageSize"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="createModel.mobileImageSize"
                disabled
              />
              <input v-else type="text" v-model="createModel.appImageSize" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageWidth`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="createModel.imageWidth"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="createModel.mobileImageWidth"
                disabled
              />
              <input v-else type="text" v-model="createModel.appImageWidth" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.imageHeight`) }}
              <input
                v-if="deviceType === 0"
                type="text"
                v-model="createModel.imageHeight"
                disabled
              />
              <input
                v-else-if="deviceType === 1"
                type="text"
                v-model="createModel.mobileImageHeight"
                disabled
              />
              <input v-else type="text" v-model="createModel.appImageHeight" disabled />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Message from "@/layout/components/Message";
import { checkData } from "@/utils/checkData";

export default {
  name: "Create",
  props: {
    i18nPrefix: String
  },
  data() {
    return {
      createModel: {
        subject: 1,
        status: 2,
        Description: "",
        order: 1,
        redirectUrl: "",
        appRedirectUrl: "",
        imageUrl: undefined,
        imageName: undefined,
        imageType: undefined,
        imageSize: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
        mobileImageUrl: undefined,
        mobileImageName: undefined,
        mobileImageType: undefined,
        mobileImageSize: undefined,
        mobileImageWidth: undefined,
        mobileImageHeight: undefined,
        appImageUrl: undefined,
        appImageName: undefined,
        appImageType: undefined,
        appImageSize: undefined,
        appImageWidth: undefined,
        appImageHeight: undefined,
        discountType: 1,
        pageType: 1,
        page: 1
      },
      formData: new FormData(),
      subjects: [
        {
          id: 1,
          name: "features.banner.home"
        }
      ],
      pages: [
        {
          id: 1,
          name: "features.banner.dialog.discount"
        },
        {
          id: 2,
          name: "features.banner.dialog.page2"
        }
      ],
      deviceType: 0,
      chooiceImg: undefined
    };
  },
  computed: {
    ...mapGetters(["getStatusList", "getDeviceType"])
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM"
    }),
    async submit() {
      if (
        !this.createModel.mobileImageUrl ||
        !this.createModel.imageUrl ||
        !this.createModel.appImageUrl
      ) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.msg`), 1000);
        return;
      }
      // if(!checkData(this.createModel, true)) return;
      this.formData.append("PageType", this.createModel.subject);
      this.formData.append("Description", this.createModel.Description);
      this.formData.append("Order", this.createModel.order);
      this.formData.append("RedirectUrl", this.createModel.redirectUrl);
      this.formData.append("appRedirectUrl", this.createModel.appRedirectUrl);
      this.formData.append("Status", this.createModel.status);
      this.formData.append("imageName", this.createModel.imageName);
      this.formData.append("ImageType", this.createModel.imageType);
      this.formData.append("imageSize", this.createModel.imageSize);
      this.formData.append("imageHeight", this.createModel.imageHeight);
      this.formData.append("imageWidth", this.createModel.imageWidth);
      this.formData.append("mobileImageName", this.createModel.mobileImageName);
      this.formData.append("mobileImageType", this.createModel.mobileImageType);
      this.formData.append("mobileImageSize", this.createModel.mobileImageSize);
      this.formData.append(
        "mobileImageHeight",
        this.createModel.mobileImageHeight
      );
      this.formData.append(
        "mobileImageWidth",
        this.createModel.mobileImageWidth
      );
      this.formData.append("appImageName", this.createModel.appImageName);
      this.formData.append("appImageType", this.createModel.appImageType);
      this.formData.append("appImageSize", this.createModel.appImageSize);
      this.formData.append("appImageHeight", this.createModel.appImageHeight);
      this.formData.append("appImageWidth", this.createModel.appImageWidth);
      this.formData.append("BindType", this.createModel.page);
      this.formData.append(
        "BindId",
        this.createModel.page === 1
          ? this.createModel.discountType
          : this.createModel.pageType
      );
      this.$emit("create", this.formData);
    },
    upload(deviceType) {
      var file = '';
      switch(deviceType) {
        case 0:
          file = 'image';
          break;
        case 1:
          file = 'mobile'; 
          break;
        case 2:
          file = 'app';
          break;
      }

      this.chooiceImg = file;
      this.$refs[file + "File"].value = "";
      this.$refs[file + "File"].click();
    },
    fileSelect(e) {
      const type = this.chooiceImg;
      this.createModel[
        (type === "image" ? type : type + "Image") + "Url"
      ] = URL.createObjectURL(e.target.files[0]);
      setTimeout(() => {
        this.createModel[
          (type === "image" ? type : type + "Image") + "Height"
        ] = `${this.$refs[type + "Img"].height}px`;
        this.createModel[
          (type === "image" ? type : type + "Image") + "Width"
        ] = `${this.$refs[type + "Img"].width}px`;
      }, 100);
      this.createModel[(type === "image" ? type : type + "Image") + "Name"] =
        e.target.files[0].name;
      this.createModel[(type === "image" ? type : type + "Image") + "Type"] =
        e.target.files[0].type;
      this.createModel[(type === "image" ? type : type + "Image") + "Size"] =
        e.target.files[0].size;
      this.formData.append(
        (type === "image" ? "" : type) + "File",
        e.target.files[0]
      );
    },
    deleteFunc(deviceType) {
      if (deviceType === 0) {
        this.createModel.imageUrl = undefined;
        this.createModel.imageName = undefined;
        this.createModel.imageType = undefined;
        this.createModel.imageSize = undefined;
        this.createModel.imageSize = undefined;
        this.createModel.imageWidth = undefined;
        this.createModel.imageHeight = undefined;
        this.formData.delete("File");
      } else if (deviceType === 1) {
        this.createModel.mobileImageUrl = undefined;
        this.createModel.mobileImageName = undefined;
        this.createModel.mobileImageType = undefined;
        this.createModel.mobileImageSize = undefined;
        this.createModel.mobileImageSize = undefined;
        this.createModel.mobileImageWidth = undefined;
        this.createModel.mobileImageHeight = undefined;
        this.formData.delete("mobileFile");
      } else {
        this.createModel.appImageUrl = undefined;
        this.createModel.appImageName = undefined;
        this.createModel.appImageType = undefined;
        this.createModel.appImageSize = undefined;
        this.createModel.appImageSize = undefined;
        this.createModel.appImageWidth = undefined;
        this.createModel.appImageHeight = undefined;
        this.formData.delete("appFile");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";
.active {
  background-color: #111fa0 !important;
  color: white;
  border: none;
}
.btn-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  button {
    width: 20%;
    margin: 1%;
    padding: 1%;
    border-radius: 10px;
    border: 1px solid #999;
    background-color: #17a2b8;
    &:hover {
      background-color: #117a8b;
      color: white;
    }
  }
}
.mobile-style {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-style {
    display: flex;
    justify-content: flex-end;
    padding: 5px 2px;
    .handleFile {
      button {
        width: 100% !important;
      }
    }
  }
  .pc-style {
    .handleFile {
      input {
        width: 100% !important;
      }
      .upload, .delete {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .btn-list {
    button {
      width: 35%;
    }
  }
}
</style>