<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.detail") }}</h4>
          <button class="download-btn main-style-btn" @click="download">
          {{ $t("default.download") }}
        </button>
        </div>
        <div class="divider"></div>
        <main>
          <div class="table-block">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("default.totalDataCount") }}</th>
                  <th>{{ $t("default.completedCount") }}</th>
                  <th>{{ $t("default.success") }}</th>
                  <th>{{ $t("default.fail") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ data.count }}</td>
                  <td>{{ data.completedCount }}</td>
                  <td>{{ data.successCount }}</td>
                  <td>{{ data.failedCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBatchCreateMemberCount,
  batchCreateMemberExcel,
} from "@/api/features/batchCreateMember";
import { download } from "@/utils/download";

export default {
  name: "Count",
  props: [
    "id",
  ],
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.getCount();
  },
  methods: {
    getCount() {
      getBatchCreateMemberCount({ batchId: this.id }).then(res => {
        this.data = res.data;
      });
    },
    download() {
      batchCreateMemberExcel({ batchId: this.id}).then(res => download(res, this.id + 'batchCreateMemberExcel.xlsx'));
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 70% !important;
}
</style>