var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "nav", staticClass: "navbar-block" }, [
    _c(
      "div",
      {
        staticClass: "hamburger-box",
        class: { hide: !_vm.sidebar },
        on: { click: _vm.toggleSidebar },
      },
      [_c("div", { staticClass: "hamburger" })]
    ),
    _c("div", { staticClass: "title" }, [
      _c(
        "p",
        [
          _c("router-link", { attrs: { to: "/dashboard" } }, [
            _c("span", [_vm._v(_vm._s(_vm.getUserinfo.name))]),
            _c("span", [_vm._v(_vm._s(_vm.$t("default.backManagement")))]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "memberCount" }),
    ]),
    _c(
      "div",
      { staticClass: "user" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.haveMail && _vm.getUserinfo.permissions.includes(80000),
                expression:
                  "haveMail && getUserinfo.permissions.includes(80000)",
              },
            ],
            staticClass: "new",
          },
          [
            _c("div", { staticClass: "bell" }, [
              _c("img", {
                staticClass: "bell-img",
                attrs: { src: require("@/assets/navbar/bell.svg") },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleNewItem.apply(null, arguments)
                  },
                },
              }),
            ]),
            _c(
              "div",
              {
                ref: "newItem",
                staticClass: "new-item",
                class: [_vm.notification ? "active" : ""],
              },
              [
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 10200
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 10200)",
                      },
                    ],
                    attrs: { to: "/member/member-deposit?permissionId=10200" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.readMessage(
                          "/member/member-deposit?permissionId=10200"
                        )
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/deposit.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 10300
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 10300)",
                      },
                    ],
                    attrs: {
                      to: "/member/member-deposit-store?permissionId=10300",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.readMessage(
                          "/member/member-deposit-store?permissionId=10300"
                        )
                      },
                    },
                  },
                  [_c("img", { attrs: { src: require("@/assets/store.png") } })]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 10400
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 10400)",
                      },
                    ],
                    attrs: { to: "/member/member-withdraw?permissionId=10400" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.readMessage(
                          "/member/member-withdraw?permissionId=10400"
                        )
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/member-withdraw.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 10500
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 10500)",
                      },
                    ],
                    attrs: { to: "/member/member-discount?permissionId=10500" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.readMessage(
                          "/member/member-discount?permissionId=10500"
                        )
                      },
                    },
                  },
                  [_c("img", { attrs: { src: require("@/assets/bonus.png") } })]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 10106
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 10106)",
                      },
                    ],
                    attrs: { to: "/member/member-withdraw?permissionId=10106" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.readMessage(
                          "/member/member-withdraw?permissionId=10106"
                        )
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/member-withdraw.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 61000
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 61000)",
                      },
                    ],
                    attrs: { to: "/userInfo/mail-box?permissionId=61000" },
                  },
                  [_c("img", { attrs: { src: require("@/assets/risk.png") } })]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 120200
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 120200)",
                      },
                    ],
                    attrs: { to: "/userInfo/mail-box?permissionId=120200" },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/blackList.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 10109
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 10109)",
                      },
                    ],
                    attrs: { to: "/userInfo/mail-box?permissionId=10109" },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/bankPic.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 20501
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 20501)",
                      },
                    ],
                    attrs: { to: "/agent/agent-withdrew?permissionId=20501" },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/agent-withdraw.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 20303
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 20303)",
                      },
                    ],
                    attrs: {
                      to: "/agent/agent-bankCard-inquiry?permissionId=20303",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/deleteAgentCard.png") },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getMailData.find(
                          (data) => data.permissionId === 13000
                        ),
                        expression:
                          "getMailData.find((data) => data.permissionId === 13000)",
                      },
                    ],
                    attrs: { to: "/member/Kyc" },
                  },
                  [_c("img", { attrs: { src: require("@/assets/KYC.png") } })]
                ),
              ],
              1
            ),
          ]
        ),
        _c("SelectStyleV1", {
          staticStyle: { "margin-right": "20px", color: "#3D4F59" },
          attrs: {
            list: _vm.GLOBAL.langes,
            default: _vm.lang,
            now: _vm.nowLang,
          },
          on: {
            "update:now": function ($event) {
              _vm.nowLang = $event
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "user-account",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleUserDropDown.apply(null, arguments)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$store.getters.userName) + " ")]
        ),
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _c("UserDropDown", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drop,
                  expression: "drop",
                },
              ],
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }