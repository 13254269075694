<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="editModel.name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}description`) }}
              <input type="text" v-model="editModel.description" />
            </label>
          </div>
          <div class="permission-block">
            <p>{{ $t(`${i18nPrefix}dialog.permission`) }}</p>
            <div class="permission-box">
              <div
                class="permission-list"
                v-for="(list,index) of getPermissionsTitle"
                :key="list.id"
              >
                <ul>
                  <li>
                    <label class="checkBox-label">
                      <input
                          name="checkBox"
                          :id="'main' + list.name"
                          type="checkbox"
                          :value="list.id"
                          v-model="editModel.permissions"
                          @click="checkAll('main' + list.name)"
                      />
                      <span class="checkmark"></span>
                      {{ $t("Permission." + list.name) }}
                    </label>
                  </li>
                  <ul>
                    <template
                        v-for="(firstTree, firstIndex) of firstData[index]"
                    >
                    <li :key="firstTree.id">
                      <label class="checkBox-label">
                        <input
                          name="checkBox"
                          :id="firstTree.name"
                          :class="['main' + list.name, 'parent'+list.name]"
                          type="checkbox"
                          v-model="editModel.permissions"
                          :value="firstTree.id"
                          @click="checkAll(firstTree.name, 'main' + list.name)"
                        />
                        <span class="checkmark"></span>
                        {{ $t("Permission." + firstTree.name) }}
                      </label>
                    </li>
                      <ul>
                        <li
                            v-for="(secTree, secIndex) of secData[index][firstIndex]"
                            :key="secData.id"
                        >
                          <label>
                            {{
                              secIndex === secData[index][firstIndex].length - 1
                                  ? "└"
                                  : "├"
                            }}
                            <div class="checkBox-label">
                              <input
                                  name="checkBox"
                                  :class="['main' + list.name, firstTree.name, 'parent'+firstTree.name]"
                                  type="checkbox"
                                  v-model="editModel.permissions"
                                  :value="secTree.id"
                                  @click="checkAll(false, firstTree.name, 'main' + list.name)"
                              />
                              <span class="checkmark"></span>
                              {{ $t("Permission." + secTree.name) }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </template>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click.self="submit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @mousedown.self="$emit('toggle')">
              {{ $t("default.cancel") }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Edit",
  props: ["id", "name", "description", "permissions",
    "getPermissionsTitle",
    "firstData",
    "secData",
    "i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.id,
        name: this.name,
        description: this.description,
        permissions: _.cloneDeep(this.permissions),
      },
    };
  },
  methods: {
    submit() {
      this.editModel.permissions = [];
      document.querySelectorAll('input[name="checkBox"]:checked').forEach((checkbox) => {
        this.editModel.permissions.push(checkbox.value * 1);
      });
      this.$emit("edit", this.editModel);
    },
    checkAll(type =false ,parentType = false, main = false) {
      if(type) {
        let checkElements = document.getElementsByClassName(type);
        for (var i = 0; i < checkElements.length; i++) checkElements[i].checked = (document.getElementById(type).checked) ? true : false;
      }
      if(parentType) {
        document.getElementById(parentType).checked = true;
        if(type) {
          let checkMainElements = Object.values(document.getElementsByClassName(parentType));
          document.getElementById(parentType).checked = (checkMainElements.find(x => x.checked)) ? true : false;
        }
        if(main) document.getElementById(main).checked = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.permission-list {
  input {
    height: inherit;
    padding: initial;
  }
  ul {
    margin-top: 5px;
    margin-left: 20px;
  }
}
.permission-box {
  justify-content: left !important;
}
.checkBox-label {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 16px;
    width: 16px;
    background-color: #f0f0f0;
    border: 1.5px solid #3D4F59;
    border-radius: 5px;
  }
  input:checked ~ .checkmark {
    background-color: #779C0C;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>