var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: {
              click: function ($event) {
                return _vm.search()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "download-btn main-style-btn",
                on: { click: _vm.download },
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/download.svg") },
                }),
                _vm._v(" " + _vm._s(_vm.$t("default.download")) + " "),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block" }, [
            _c(
              "label",
              { staticClass: "input-block-multiselect" },
              [
                _c("div", { staticClass: "input-block-check" }, [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(_vm._s(_vm.$t("default.agent"))),
                  ]),
                ]),
                _c("multiselect", {
                  attrs: {
                    label: "name",
                    "track-by": "id",
                    options: _vm.getAgentListAddDefault,
                    "allow-empty": false,
                    preselectFirst: true,
                    "clear-on-select": false,
                    "preserve-search": true,
                    "custom-label": _vm.GLOBAL.nameWithLang,
                  },
                  model: {
                    value: _vm.agentData,
                    callback: function ($$v) {
                      _vm.agentData = $$v
                    },
                    expression: "agentData",
                  },
                }),
              ],
              1
            ),
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.memberAccount,
                    expression: "searchForm.memberAccount",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchForm.memberAccount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchForm,
                      "memberAccount",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c(
              "label",
              [
                _vm._v(" " + _vm._s(_vm.$t("default.startTime")) + " "),
                _c("date-picker", {
                  attrs: {
                    format: "YYYY-MM-DD",
                    "value-type": "format",
                    type: "datetime",
                    "disabled-date": _vm.GLOBAL.ontSeason,
                  },
                  model: {
                    value: _vm.startTime,
                    callback: function ($$v) {
                      _vm.startTime = $$v
                    },
                    expression: "startTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "label",
              [
                _vm._v(" " + _vm._s(_vm.$t("default.endTime")) + " "),
                _c("date-picker", {
                  attrs: {
                    format: "YYYY-MM-DD",
                    "value-type": "format",
                    type: "datetime",
                    "disabled-date": _vm.GLOBAL.ontSeason,
                  },
                  model: {
                    value: _vm.endTime,
                    callback: function ($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime",
                  },
                }),
              ],
              1
            ),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamesCategory`)) + " "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.gamesCategory,
                      expression: "searchForm.gamesCategory",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "gamesCategory",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.getGamesCategorysAddDefault, function (list) {
                  return _c(
                    "option",
                    { key: list.id, domProps: { value: list.id } },
                    [_vm._v(_vm._s(_vm.$t(list.name)))]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "advance-block" },
            [
              _c("QuickSearch", {
                ref: "quickSearch",
                attrs: { controlType: true },
                on: { changeTime: _vm.changeTime },
              }),
              _c(
                "button",
                {
                  staticClass: "search-pc-btn main-style-btn px-5",
                  on: { click: _vm.search },
                },
                [_vm._v(_vm._s(_vm.$t("default.search")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "total-block-v2 row-4" }, [
          _c(
            "div",
            {
              staticClass: "total-block-v2-item",
              staticStyle: { "align-items": "center" },
            },
            [
              _c("div", { staticClass: "total-block-v2-item-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betCount`)) + ":"),
              ]),
              _c("div", { staticClass: "total-block-v2-item-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.digital.format(
                      _vm.total.totalBetCount ? _vm.total.totalBetCount : 0
                    )
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "total-block-v2-item",
              staticStyle: { "align-items": "center" },
            },
            [
              _c("div", { staticClass: "total-block-v2-item-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}validBetAmount`)) + ":"),
              ]),
              _c("div", { staticClass: "total-block-v2-item-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.digital.format(
                      _vm.total.totalValidBetAmount
                        ? _vm.total.totalValidBetAmount
                        : 0
                    )
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "total-block-v2-item",
              staticStyle: { "align-items": "center" },
            },
            [
              _c("div", { staticClass: "total-block-v2-item-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}winLose`)) + ":"),
              ]),
              _c("div", { staticClass: "total-block-v2-item-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.digital.format(
                      _vm.total.totalWinLose ? _vm.total.totalWinLose : 0
                    )
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "total-block-v2-item",
              staticStyle: { "flex-wrap": "wrap", "align-items": "center" },
            },
            [
              _c("div", { staticClass: "agent-count" }, [
                _c("div", { staticClass: "total-block-v2-item-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}agentParentCount`)) + ":"
                  ),
                ]),
                _c("div", { staticClass: "total-block-v2-item-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.digital.format(
                        _vm.total.totalAgentParentCount
                          ? _vm.total.totalAgentParentCount
                          : 0
                      )
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "agent-count" }, [
                _c("div", { staticClass: "total-block-v2-item-left" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}agentChildCount`)) + ":"
                  ),
                ]),
                _c("div", { staticClass: "total-block-v2-item-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.digital.format(
                        _vm.total.totalAgentChildCount
                          ? _vm.total.totalAgentChildCount
                          : 0
                      )
                    )
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "table-block" },
          [
            _c("TreeTable", {
              ref: "treeTable",
              attrs: {
                list: _vm.allData,
                column: _vm.tableColumn,
                bookmarkColumn: _vm.bookmarkColumn,
                notShowChildren: true,
              },
              on: { sort: _vm.dataSort, bookmark: _vm.bookmark },
              scopedSlots: _vm._u([
                {
                  key: "column_name",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.searchForm.type != 3
                              ? _vm.searchForm.type != 1
                                ? props.name
                                : `${props.name} (${_vm.GLOBAL.i18nCheckName(
                                    _vm.getAgentListAddDefault.find(
                                      (data) => data.account == props.name
                                    )
                                  )})`
                              : _vm.GLOBAL.i18nCheckName(
                                  _vm.getGamesCategorysAddDefault.find(
                                    (data) => data.id == props.name
                                  )
                                )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "column_totalAgentRebateAmount",
                  fn: function (props) {
                    return [
                      _c(
                        "span",
                        {
                          attrs: { role: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRebate(props.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.digital.format(props.totalAgentRebateAmount)
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "column_action",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn",
                          on: {
                            click: function ($event) {
                              return _vm.bookmark(2, props.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.member")))]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "normal-btn ml-2",
                          on: {
                            click: function ($event) {
                              return _vm.bookmark(3, props.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}game`)))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }