import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from "../layout/index";
// import login from "../views/login/login";
import HistoryReport from "../views/historyReport/index";
import { getToken } from "@/utils/auth";
import store from "@/store";

Vue.use(VueRouter)

function loadComponent(path) {
  return require('../views/login/' + path + '/login').default;
}

const routes = [
  // 登入
  {
    path: "/",
    redirect: "/login",
    meta: { hidden: true }
  },
  {
    path: "/login",
    name: "Login",
    component: loadComponent(process.env.VUE_APP_platformVersion),
    meta: { title: "login", hidden: true, pass: true }
  },
  {
    path: "/historyReport",
    name: "HistoryReport",
    component: HistoryReport,
    meta: { hidden: true, pass: true }
  },
  // 右上會員選項
  {
    path: "/userInfo",
    name: "UserInfo",
    component: layout,
    meta: { title: "login", hidden: true },
    children: [
      {
        path: "mail-box",
        name: "MailBox",
        component: () => import("@/views/mail-box"),
        meta: { title: "mail-box", permission: 80000 }
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("@/views/reset-password"),
        meta: { title: "reset-password", pass: true }
      },
      {
        path: "agent-wallet",
        name: "AgentWallet ",
        component: () => import("@/views/agent/agent-wallet/agent-wallet"),
        meta: { title: "agent-wallet", permission: 150000 }
      },
      {
        path: "agent-info",
        name: "AgentInfo ",
        component: () => import("@/views/agent/agent-info/index"),
        meta: { title: "agent-info", permission: 150300 }
      },
      {
        path: "empty",
        name: "Empty",
        component: () => import("@/views/empty"),
        meta: { hidden: true, pass: true }
      },
    ]
  },

  // 後臺
  // {
  //   path: "/backstage",
  //   component: layout,
  //   redirect: "/backstage/backstage-management",
  //   name: "Backstage",
  //   meta: { title: "後臺", hiden: false },
  //   children: [
  //     {
  //       path: "backstage-management",
  //       name: "BackstageManagement",
  //       component: () => import("@/views/backstage/backstage-management/backstage-management"),
  //       meta: { title: "後臺帳號管理" }
  //     },
  //     {
  //       path: "test",
  //       name: "test",
  //       component: () => import("@/views/backstage/test/test"),
  //       meta: { title: "測試用" }
  //     }
  //   ]
  // },

  {
    path: "/dashboard",
    component: layout,
    name: "Dashboard",
    meta: { title: "dashboard", hidden: false, permission: 180000 },
    children: [
      {
        path: "index",
        name: "DashboardSub",
        component: () => import("@/views/dashboard/index"),
        meta: { title: "dashboard", hidden: true, permission: 180000 }
      }
    ]
  },
  // 會員管理
  {
    path: "/member",
    component: layout,
    name: "Member",
    meta: { title: "member", hidden: false, permission: 10000 },
    children: [
      {
        path: "member-management",
        name: "MemberManagement",
        component: () => import("@/views/member/member-management/member-management"),
        meta: { title: "member-management", hidden: false, permission: 10100 }
      },
      {
        path: "member-deposit",
        name: "MemberDeposit",
        component: () => import("@/views/member/member-deposit/index"),
        meta: { title: "member-deposit", hidden: false, permission: 10200 }
      },
      {
        path: "member-deposit-store",
        name: "MemberDepositStore",
        component: () => import("@/views/member/member-deposit-store/index"),
        meta: { title: "member-deposit-store", hidden: false, permission: 10300 }
      },
      {
        path: "member-withdraw",
        name: "MemberWithdraw",
        component: () => import("@/views/member/member-withdraw/member-withdraw"),
        meta: { title: "member-withdraw", hidden: false, permission: 10400 }
      },
      {
        path: "member-discount",
        name: "MemberDiscount",
        component: () => import("@/views/member/member-discount/member-discount"),
        meta: { title: "member-discount", hidden: false, permission: 10500 }
      },
      {
        path: "member-level",
        name: "MemberLevelIndex",
        component: () => import("@/views/member/member-level/member-level-index"),
        meta: { title: "member-level", hidden: false, permission: 10600 }
      },
      {
        path: "member-levelv2",
        name: "MemberLevelIndex",
        component: () => import("@/views/member/member-levelv2/member-level"),
        meta: { title: "member-level", ver: "V2", hidden: false, permission: 11000 }
      },
      {
        path: "rebate",
        name: "Rebate",
        component: () => import("@/views/member/rebate/index"),
        meta: { title: "rebate", permissionName: "MemberRebateV2", ver: "V2", hidden: false, permission: 10700 }
      },
      {
        path: "betAmountLimit",
        name: "BetAmountLimit",
        component: () => import("@/views/member/betAmountLimit/betAmountLimit"),
        meta: { title: "betAmountLimit", hidden: false, permission: 10900 }
      },
      {
        path: "Kyc",
        name: "Kyc",
        component: () => import("@/views/member/Kyc/Kyc"),
        meta: { permissionName: "Kyc", hidden: false, permission: 13000 }
      },
      // {
      //   path: "balance-adjustment",
      //   name: "BalanceAdjustment",
      //   component: () => import("@/views/member/balance-adjustment/balance-adjustment"),
      //   meta: { title: "金額調整", hidden: false, permission: 10700 }
      // }
    ]
  },

  // 信用代理管理
  {
    path: "/agent",
    component: layout,
    name: "Agent",
    meta: { title: "agent", hidden: false, permission: 20000 },
    children: [
      {
        path: "agent-management",
        name: "AgentManagement",
        component: () => import("@/views/agent/agent-management/agent-management"),
        meta: { title: "agent-management", permission: 20100 }
      },
      {
        path: "agent-statistics",
        name: "AgentStatistics",
        component: () => import("@/views/agent/agent-statistics/agent-statistics"),
        meta: { title: "agent-statistics", permission: 20200 }
      },
      {
        path: "agent-bankCard-inquiry",
        name: "BgentBankCardInquiry",
        component: () => import("@/views/agent/agent-bankCard-inquiry/agent-bankCard-inquiry"),
        meta: { title: "agent-bankCard-inquiry", permission: 20300 }
      },
      {
        path: "agent-withdrew",
        name: "AgentWithdrew",
        component: () => import("@/views/agent/agent-withdraw/agent-withdraw"),
        meta: { title: "agent-withdrew", permission: 20500 }
      },
      {
        path: "agent-rebateCalc",
        name: "AgentRebateCalc",
        component: () => import("@/views/agent/agent-rebateCalc/agent-rebateCalc"),
        meta: { title: "agent-rebateCalc", permission: 20400 }
      },
      {
        path: "agent-rebateCalcV2",
        name: "AgentRebateCalc",
        component: () => import("@/views/agent/agent-rebateCalcV2/agent-rebateCalc"),
        meta: { title: "agent-rebateCalc", ver: "V2", permission: 20600 }
      }
    ]
  },

  // 會員報表
  {
    path: "/report",
    component: layout,
    name: "Report",
    meta: { title: "report", hidden: false, permission: 30000 },
    children: [
      {
        path: "member-deposit",
        name: "MemberDeposit",
        component: () => import("@/views/report/member-deposit/index"),
        meta: { title: "member-deposit", permission: 30100 }
      },
      {
        path: "member-deposit-payment-report",
        name: "MemberDepositPaymentReport",
        component: () => import("@/views/report/member-deposit-payment-report/index"),
        meta: { title: "member-deposit-payment-report", permission: 30200 }
      },
      // {
      //   path: "member-deposit-payment-report-net",
      //   name: "MemberDepositPaymentReportNet",
      //   component: () => import("@/views/report/member-deposit-payment-report-net/member-deposit-payment-report-net"),
      //   meta: { title: "網銀存款", permission: 30300 }
      // },
      {
        path: "member-withdrawal-report",
        name: "MemberWithdrawalReport",
        component: () => import("@/views/report/member-withdrawal-report/member-withdrawal-report"),
        meta: { title: "member-withdrawal-report", permission: 30400 }
      },
      {
        path: "member-transfer",
        name: "MemberTransfer",
        component: () => import("@/views/report/member-transfer/member-transfer"),
        meta: { title: "member-transfer", permission: 30500 }
      },
      {
        path: "wallet-history",
        name: "WalletHistory",
        component: () => import("@/views/report/wallet-history/wallet-history"),
        meta: { title: "wallet-history", permission: 30600 }
      },
      {
        path: "quota-history",
        name: "QuotaHistory",
        component: () => import("@/views/report/quota-history/quota-history"),
        meta: { title: "quota-history", permission: 30800 }
      },
      {
        path: "balance-adjustment-report",
        name: "BalanceAdjustmentReport",
        component: () => import("@/views/report/balance-adjustment-report/balance-adjustment-report"),
        meta: { title: "balance-adjustment-report", permission: 30700 }
      },
    ]
  },

  // 注單報表
  {
    path: "/report-bet-count",
    component: layout,
    name: "ReportBetCount",
    meta: { title: "report-bet-count", hidden: false, permission: 40000 },
    children: [
      {
        path: "bet-record",
        name: "BetRecord",
        component: () => import("@/views/report-bet-count/bet-record/bet-record"),
        meta: { title: "bet-record", permission: 40300 }
      },
      {
        path: "week-lose-win",
        name: "WeekLoseWin",
        component: () => import("@/views/report-bet-count/week-lose-win/week-lose-win"),
        meta: { title: "week-lose-win", permission: 40100 }
      }
    ]
  },

  // 統計報表
  {
    path: "/statistical-report",
    component: layout,
    name: "StatisticalReport",
    meta: { title: "statistical-report", permissionName: "StatisticalReport", hidden: false, permission: 160000 },
    children: [
      {
        path: "overview",
        name: "Overview",
        component: () => import("@/views/statistical-report/overview/index"),
        meta: { title: "overview", permission: 160300 }
      },
      {
        path: "bet-report",
        name: "BetReport",
        component: () => import("@/views/statistical-report/bet-reportV2/bet-report"),
        meta: { title: "bet-report", permissionName: "BettingReportV2", permission: 160100 }
      },
      {
        path: "operatingReport",
        name: "OperatingReport",
        component: () => import("@/views/statistical-report/operatingReport/operatingReport"),
        meta: { title: "bet-report", permissionName: "OperatingReport", permission: 160200 }
      },
      {
        path: "gameStatisticalAnalysis",
        name: "GameStatisticalAnalysis",
        component: () => import("@/views/statistical-report/gameStatisticalAnalysis/index"),
        meta: { title: "gameStatisticalAnalysis", permission: 160400 }
      },
      {
        path: "agentStatisticalAnalysis",
        name: "AgentStatisticalAnalysis",
        component: () => import("@/views/statistical-report/agentStatisticalAnalysis/index"),
        meta: { title: "agentStatisticalAnalysis", permissionName: "AgentStatisticalAnalysis", permission: 160500 }
      },
      {
        path: "agentDiscountStatistical",
        name: "AgentDiscountStatistical",
        component: () => import("@/views/statistical-report/agentDiscountStatistical/index"),
        meta: { title: "agentDiscountStatistical", permissionName: "AgentDiscountStatistical", permission: 160600 }
      },
      {
        path: "campaignDiscountStatistical",
        name: "CampaignDiscountStatistical",
        component: () => import("@/views/statistical-report/CampaignDiscountStatistical/index"),
        meta: { permissionName: "CampaignDiscountStatistical", permission: 160900  }
      },
      {
        path: "memberRank",
        name: "MemberRank",
        component: () => import("@/views/statistical-report/memberRank/index"),
        meta: { title: "memberRank", permissionName: "MemberRanking", permission: 160700 }
      },
      {
        path: "memberRetention",
        name: "MemberRetention",
        component: () => import("@/views/statistical-report/memberRetention/index"),
        meta: { title: "memberRetention", permissionName: "MemberRetention", permission: 160800 }
      }
    ],
  },

  // 其他設定
  {
    path: "/features",
    component: layout,
    name: "Features",
    meta: { title: "features", hidden: false, permission: 60000 },
    children: [
      {
        path: "companySettings",
        name: "CompanySettings",
        component: () => import("@/views/features/companySettings/companySettings"),
        meta: { title: "companySettings", permission: 62000 }
      },
      {
        path: "announcement",
        name: "Announcement",
        component: () => import("@/views/features/announcement/announcement"),
        meta: { title: "announcement", permission: 60200 }
      },
      {
        path: "group",
        name: "GroupMember",
        component: () => import("@/views/features/group/group-member"),
        meta: { title: "group", permission: 61100 }
      },
      {
        path: "bank",
        name: "Bank",
        component: () => import("@/views/features/bank/bank"),
        meta: { title: "bank", permission: 61500 }
      },
      {
        path: "CvsSetting",
        name: "CvsSetting",
        component: () => import("@/views/features/CvsSetting/CvsSetting"),
        meta: { permissionName: "CvsList", permission: 62700 }
      },
      {
        path: "bank-group",
        name: "BankGroup",
        component: () => import("@/views/features/bank-group/bank-group"),
        meta: { title: "bank-group", permission: 60400 }
      },
      {
        path: "payment-gateway",
        name: "PaymentGateway",
        component: () => import("@/views/features/payment-gateway/payment-gateway"),
        meta: { title: "payment-gateway", permission: 61400 }
      },
      {
        path: "companyDepositSettings",
        name: "CompanyDepositSettings",
        component: () => import("@/views/features/companyDepositSettings/companyDepositSettings"),
        meta: { title: "companyDepositSettings", permission: 61800 }
      },
      {
        path: "companyDepositSettingsV2",
        name: "companyDepositSettingsV2",
        component: () => import("@/views/features/companyDepositSettingsV2/companyDepositSettingsV2"),
        meta: { title: "companyDepositSettings", ver: "App", permission: 62200 }
      },
      {
        path: "bankTypeSetting",
        name: "BankTypeSetting",
        component: () => import("@/views/features/bankTypeSetting/bankTypeSetting"),
        meta: { title: "bankTypeSetting", permission: 62400 }
      },
      {
        path: "cryptoWithdrawalSetting",
        name: "CryptoWithdrawalSetting",
        component: () => import("@/views/features/cryptoWithdrawalSetting/cryptoWithdrawalSetting"),
        meta: { title: "cryptoWithdrawalSetting", permission: 62300 }
      },
      {
        path: "banner",
        name: "Banner",
        component: () => import("@/views/features/banner/banner"),
        meta: { title: "banner", permission: 60700 }
      },
      // {
      //   path: "discount-setup",
      //   name: "DiscountSetup",
      //   component: () => import("@/views/features/discount-setup/discount-setup"),
      //   meta: { title: "discount-setup", permission: 60900 }
      // },
      {
        path: "discount-setupV2",
        name: "DiscountSetup",
        component: () => import("@/views/features/discount-setupV2/index"),
        meta: { title: "discount-setup", ver: "V2", permission: 62100 }
      },
      {
        path: "deposit-withdraw-setting",
        name: "DepositWithdrawSetting",
        component: () => import("@/views/features/deposit-withdraw-setting/deposit-withdraw-setting"),
        meta: { title: "deposit-withdraw-setting", permission: 60800 }
      },
      {
        path: "risk-control",
        name: "RiskControl",
        component: () => import("@/views/features/risk-control/risk-control"),
        meta: { title: "risk-control", permission: 61000 }
      },
      // {
      //   path: "rebate-discount-setup",
      //   name: "RebateDiscountSetup",
      //   component: () => import("@/views/features/rebate-discount-setup/rebate-discount-setup"),
      //   meta: { title: "返水優惠設置", permission: 60900 }
      // },
      {
        path: "verification-code",
        name: "VerificationCode",
        component: () => import("@/views/features/verification-code/verification-code"),
        meta: { title: "verification-code", permission: 61200 }
      },
      {
        path: "game-maintain",
        name: "GameMaintain",
        component: () => import("@/views/features/game-maintain/game-maintain"),
        meta: { title: "game-maintain", permission: 61300 }
      },
      {
        path: "game-maintain-emergency",
        name: "GameMaintainEmergency",
        component: () => import("@/views/features/game-maintain-emergency/game-maintain-emergency"),
        meta: { title: "game-maintain-emergency", permission: 61300 }
      },
      {
        path: "memberPrivateMessage",
        name: "MemberPrivateMessage",
        component: () => import("@/views/features/memberPrivateMessage/memberPrivateMessage"),
        meta: { title: "memberPrivateMessage", permission: 61600 }
      },
      {
        path: "tagManagement",
        name: "TagManagement",
        component: () => import("@/views/features/tagManagement/tagManagement"),
        meta: { title: "tagManagement", permission: 61700 }
      },
      // {
      //   path: "common-problem",
      //   name: "CommonProblem",
      //   component: () => import("@/views/features/common-problem/common-problem"),
      //   meta: { title: "常見問題", permission: 60600 }
      // },
      {
        path: "paymentCVS",
        name: "PaymentCVS",
        component: () => import("@/views/features/paymentCVS/index"),
        meta: { title: "paymentCVS", permission: 60500 }
      },
      {
        path: "paymentCrypto",
        name: "PaymentCrypto",
        component: () => import("@/views/features/paymentCrypto/index"),
        meta: { title: "paymentCrypto", permission: 60600 }
      },
      {
        path: "appVersionSetting",
        name: "AppVersionSetting",
        component: () => import("@/views/features/appVersionSetting/appVersionSetting"),
        meta: { title: "appVersionSetting", permission: 63000 }
      },
      {
        path: "batchCreateMember",
        name: "index",
        component: () => import("@/views/features/batchCreateMember/index"),
        meta: { title: "batchCreateMember", permission: 60900 }
      },
    ]
  },

  // 遊戲管理
  {
    path: "/operationManagement",
    component: layout,
    name: "operationManagement",
    meta: { title: "operationManagement", hidden: false, permission: 140000 },
    children: [
      {
        path: "gamesCategoryInfo",
        name: "GamesCategoryInfo",
        component: () => import("@/views/operationManagement/gamesCategoryInfo/gamesCategoryInfo"),
        meta: { title: "gamesCategoryInfo", permission: 140100 }
      },
      {
        path: "gameInfo",
        name: "GameInfo",
        component: () => import("@/views/operationManagement/gameInfo/gameInfo"),
        meta: { title: "gameInfo", permission: 140200 }
      },
      {
        path: "gamesCategoryBanner",
        name: "GamesCategoryBanner",
        component: () => import("@/views/operationManagement/gamesCategoryBanner/index"),
        meta: { title: "gamesCategoryBanner", permission: 140300 }
      },
      {
        path: "gamesCategorySort",
        name: "GamesCategorySort",
        component: () => import("@/views/operationManagement/gamesCategorySort/gamesCategorySort"),
        meta: { title: "gamesCategorySort", permission: 140400 }
      }
    ]
  },

  // 黑名單功能
  {
    path: "/blackList",
    component: layout,
    name: "BlackList",
    meta: { title: "blackList", hidden: false, permission: 120000 },
    children: [
      {
        path: "blacklistRules",
        name: "BlacklistRules",
        component: () => import("@/views/black-List/blacklistRules/blacklistRules"),
        meta: { title: "blacklistRules", permission: 120100 }
      },
      {
        path: "blacklistWarnings",
        name: "BlacklistWarnings",
        component: () => import("@/views/black-List/blacklistWarnings/blacklistWarnings"),
        meta: { title: "blacklistWarnings", permission: 120200 }
      },
      {
        path: "appBlackList",
        name: "AppBlackList",
        component: () => import("@/views/black-List/appBlackList/appBlackList"),
        meta: { title: "appBlackList", permission: 120300 }
      },
    ]
  },

  // 訪問控制
  {
    path: "/visit-control",
    component: layout,
    name: "VisitControl",
    meta: { title: "visit-control", hidden: false, permission: 70000 },
    children: [
      {
        path: "custom-role",
        name: "CustomRole",
        component: () => import("@/views/visit-control/custom-role/custom-role"),
        meta: { title: "custom-role", permission: 70100 }
      },
      {
        path: "office-user",
        name: "OfficeUser",
        component: () => import("@/views/visit-control/office-user/office-user"),
        meta: { title: "office-user", permission: 70200 }
      },
      {
        path: "rdAccount",
        name: "RdAccount",
        component: () => import("@/views/visit-control/rdAccount/rdAccount"),
        meta: { title: "rdAccount", permission: 70400 }
      },      
    ]
  },

  // 後台紀錄
  {
    path: "/report-various",
    component: layout,
    name: "ReportVarious",
    meta: { title: "report-various", hidden: false, permission: 50000 },
    children: [
      {
        path: "member-update-record",
        name: "MemberUpdateRecord",
        component: () => import("@/views/report-various/member-update-record/member-update-record"),
        meta: { title: "member-update-record", permission: 50100 }
      },
      {
        path: "manager-record",
        name: "ManagerRecord",
        component: () => import("@/views/report-various/manager-record/manager-record"),
        meta: { title: "manager-record", permission: 50300 }
      },
      {
        path: "member-login-record",
        name: "MemberLoginRecord",
        component: () => import("@/views/report-various/member-login-record/member-login-record"),
        meta: { title: "member-login-record", permission: 50200 }
      },
      {
        path: "mail-history",
        name: "MailHistory",
        component: () => import("@/views/report-various/mail-history/mail-history"),
        meta: { title: "mail-history", permission: 50200 }
      },
    ]
  },

  // 行銷功能
  {
    path: "/SEO-Function",
    component: layout,
    name: "SEOFunction",
    meta: { title: "SEO-Function", hidden: false, permission: 90000 },
    children: [
      {
        path: "SEO",
        name: "SEO",
        component: () => import("@/views/SEO-function/SEO/SEO"),
        meta: { title: "SEO", permission: 90100 }
      },
      {
        path: "MemberAttribution",
        name: "MemberAttribution",
        component: () => import("@/views/SEO-function/MemberAttribution/MemberAttribution"),
        meta: { title: "MemberAttribution", permission: 90200 }
      },
      {
        path: "VideoSetting",
        name: "VideoSetting",
        component: () => import("@/views/SEO-function/VideoSetting/VideoSetting"),
        meta: { title: "VideoSetting", permission: 90300 }
      },
    ]
  },

  // SEO部落格功能
  {
    path: "/SEO-Blog-Function",
    component: layout,
    name: "SEOBlogFunction",
    meta: { title: "SEO-Blog-Function", hidden: false, permission: 100000 },
    children: [
      {
        path: "SEO-blogCategory",
        name: "SEOBlogCategory",
        component: () => import("@/views/SEO-Blog/SEO-blogCategory/SEO-blogCategory"),
        meta: { title: "SEO-blogCategory", permission: 100100 }
      },
      {
        path: "SEO-blog",
        name: "SEOBlog",
        component: () => import("@/views/SEO-Blog/SEO-blog/SEO-blog"),
        meta: { title: "SEO-blog", permission: 100200 }
      },
      {
        path: "SEO-gameBlog",
        name: "SEOGameBlog",
        component: () => import("@/views/SEO-Blog/SEO-gameBlog/SEO-gameBlog"),
        meta: { title: "SEO-gameBlog", permission: 100300 }
      },
      {
        path: "SEO-blogActivity",
        name: "SEOBlogActivity",
        component: () => import("@/views/SEO-Blog/SEO-blogActivity/SEO-blogActivity"),
        meta: { title: "SEO-blogActivity", permission: 100400 }
      },
    ]
  },

  // 媒體庫
  {
    path: "/mediaLibrary",
    component: layout,
    name: "mediaLibrary",
    meta: { title: "mediaLibrary", hidden: false, permission: 110000 },
    children: [
      {
        path: "MediaLibraryImage",
        name: "MediaLibraryImage",
        component: () => import("@/views/mediaLibrary/mediaLibraryImage/mediaLibraryImage"),
        meta: { title: "MediaLibraryImage", permission: 110100 }
      },
    ]
  },

  // 媒體庫
  {
    path: "/accountingReport",
    component: layout,
    name: "accountingReport",
    meta: { title: "accountingReport", hidden: false, permission: 130000 },
    children: [
      {
        path: "registeredMemberExcel",
        name: "RegisteredMemberExcel",
        component: () => import("@/views/accountingReport/registeredMemberExcel/registeredMemberExcel"),
        meta: { title: "registeredMemberExcel", permission: 130100 }
      },
      {
        path: "annualstatistics-report",
        name: "AnnualStatisticsReport",
        component: () => import("@/views/accountingReport/annualstatistics-report/annualstatistics-report"),
        meta: { title: "annualstatistics-report", permission: 130200 }
      }
    ]
  },
  // 活動
  {
    path: "/campaign",
    component: layout,
    name: "campaign",
    meta: { permissionName: "Campaign", hidden: false, permission: 170000 },
    children: [
      {
        path: "luckydraw",
        name: "LuckyDraw",
        component: () => import("@/views/campaign/luckydraw/luckydraw"),
        meta: { title: "luckydraw", permission: 170100 } 
      },
      {
        path: "memberReferral",
        name: "MemberReferral",
        component: () => import("@/views/campaign/memberReferral/memberReferral"),
        meta: { title: "memberReferral", permission: 170500 }
      },
      {
        path: "memberSignIn",
        name: "MemberSignIn",
        component: () => import("@/views/campaign/memberSignIn/memberSignIn"),
        meta: { title: "memberSignIn", permission: 171000 }
      },
      {
        path: "redeemCode",
        name: "RedeemCode",
        component: () => import("@/views/campaign/redeemCode/redeemCode"),
        meta: { title: "redeemCode", permission: 171400 }
      },
    ]
  },
  // T9真人
  {
    path: "/T9Gaming",
    component: layout,
    name: "T9Gaming",
    meta: { permissionName: "T9Gaming", hidden: false, permission: 190000 },
    children: [
      {
        path: "T9GamingBetLimit",
        name: "T9GamingBetLimit",
        component: () => import("@/views/T9Gaming/T9GamingBetLimit/T9GamingBetLimit"),
        meta: { title: "T9GamingBetLimit", permission: 190100 } 
      },
    ]
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // if (getToken() && (to.name === "GroupMember" || to.name === "BankGroup" || to.name === "MemberManagement")) {
  //   memberSelectMenu(store.getters.getUserinfo).then((res) => {
  //     store.commit("select_menu/SET_BANKLIST", res.data.banks);
  //     store.commit("select_menu/SET_BANKGROUPLIST", res.data.bankGroups);
  //     store.commit("select_menu/SET_AGENTLIST", res.data.agents);
  //   }); 
  // }
  // if (to.name !== "Login" && (!getToken() || !store.getters.getUserinfo.permissions.includes(to.meta.permission)) && !to.meta?.pass) {
  if ((to.name !== "Login" && !getToken()) || (!to.meta?.pass && !store.getters.getUserinfo.permissions.includes(to.meta.permission))) {
    next("/")
    return
  }
  next();
});

export default router
