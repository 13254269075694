var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.msgDetail`))),
            ]),
          ]),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message",
                    },
                  ],
                  attrs: { rows: "12" },
                  domProps: { value: _vm.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.message = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }