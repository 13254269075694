var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c(
          "button",
          {
            staticClass: "search-btn main-style-btn",
            on: { click: _vm.getList },
          },
          [_vm._v(_vm._s(_vm.$t("default.search")))]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "input-block" }, [
            _c("label", [
              _vm._v(" " + _vm._s(_vm.$t("default.status")) + " "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.status,
                      expression: "searchForm.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchForm,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.getStatusListAddDefault, function (list) {
                  return _c(
                    "option",
                    { key: list.id, domProps: { value: list.id } },
                    [_vm._v(_vm._s(_vm.$t(list.name)))]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "advance-block" }, [
          _c(
            "button",
            {
              staticClass: "search-pc-btn main-style-btn px-5",
              on: { click: _vm.getList },
            },
            [_vm._v(_vm._s(_vm.$t("default.search")))]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gateway`))),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("features.payment-gateway.paymentCompanyInfo")
                      )
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}paymentCompanyInfo`))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}subName`))),
                  ]),
                  _c("th"),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}oldMemberMinDeposit`))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}oldMemberMaxDeposit`))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}newMemberMinDeposit`))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}newMemberMaxDeposit`))
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateUser`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateTime`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.tableList, function (list) {
                  return _c("tr", { key: list.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "table-btn main-style-btn",
                          attrs: {
                            disabled:
                              !_vm.getUserinfo.permissions.includes(61801),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(list, "Edit")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.edit")))]
                      ),
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("DepositTypeList." + list.gateway))),
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.getName(list.paymentCompanyInfoId))),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.GLOBAL.i18nCheckName(
                            _vm.paymentCompanyInfo.find(
                              (data) => data.id === list.paymentCompanyInfoId
                            )
                          )
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.subName))]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "table-btn main-style-btn",
                          attrs: {
                            disabled:
                              !_vm.getUserinfo.permissions.includes(61802),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialog(list, "bank")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.setting")))]
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.oldMemberMinDeposit))]),
                    _c("td", [_vm._v(_vm._s(list.oldMemberMaxDeposit))]),
                    _c("td", [_vm._v(_vm._s(list.newMemberMinDeposit))]),
                    _c("td", [_vm._v(_vm._s(list.newMemberMaxDeposit))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          list.status === 1
                            ? _vm.$t("default.yes")
                            : _vm.$t("default.no")
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.updateUser))]),
                    _c("td", [_vm._v(_vm._s(list.updateTime))]),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  i18nPrefix: _vm.i18nPrefix,
                  paymentCompanyInfo: _vm.paymentCompanyInfo,
                  companyDepositSettingsSub: _vm.companyDepositSettingsSub,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }