var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex form-column align-items-center h-full" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}lossGames.title`))),
      ]),
      _c("div", { staticClass: "table-block-white-v2" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t(`${this.i18nPrefix}lossGames.gamePlatform`))
                ),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t(`${this.i18nPrefix}lossGames.winLose`))),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.list, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getGamePlatformName(item.gamePlatform)) +
                      " "
                  ),
                ]),
                _c("td", [_vm._v(" " + _vm._s(item.winLose) + " ")]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }