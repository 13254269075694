var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("default.edit")) +
                  _vm._s(_vm.$t("title." + _vm.$route.meta.title))
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}type`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.announcementType,
                        expression: "editModel.announcementType",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "announcementType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.announcementTypes, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(_vm._s(_vm.$t(list.name)))]
                    )
                  }),
                  0
                ),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}sort`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.sortId,
                      expression: "editModel.sortId",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.editModel.sortId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "sortId", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}active`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.status,
                        expression: "editModel.status",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 1 * 1 } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: 2 * 1 } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}isBroadcast`)) + " "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.isBroadcast,
                        expression: "editModel.isBroadcast",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "isBroadcast",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(_vm._s(_vm.$t("default.active"))),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(_vm._s(_vm.$t("default.inactive"))),
                    ]),
                  ]
                ),
              ]),
              _c("label", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}title`)) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.title,
                      expression: "editModel.title",
                    },
                  ],
                  attrs: { rows: "3" },
                  domProps: { value: _vm.editModel.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "title", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "label",
                { staticClass: "CK" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}announcement(zh-tw)`)) +
                      " "
                  ),
                  _c("ckeditor", {
                    attrs: {
                      "editor-url": this.GLOBAL.CKEditorUrl,
                      config: _vm.editorConfig,
                    },
                    model: {
                      value: _vm.editModel.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.editModel, "content", $$v)
                      },
                      expression: "editModel.content",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "confirm-btn main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit", _vm.editModel)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "normal-btn-v2 main-style-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }