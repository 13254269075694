<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <main>
        <div class="reset-block">
          <div>
            <label>{{ $t("reset-password.oldPassword") }}</label>
            <input type="text" v-model="resetModel.oldPassword" />
          </div>
          <div>
            <label>{{ $t("reset-password.newPassword") }}</label>
            <input type="text" v-model="resetModel.newPassword" />
          </div>
          <div>
            <label>{{ $t("reset-password.confirmPassword") }}</label>
            <input type="text" v-model="resetModel.confirmPassword" />
          </div>
        </div>
        <div class="advance-block">
          <button class="normal-btn" @click="submit">{{ $t("reset-password.submit") }}</button>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { resetPassword } from "@/api/user";
import Message from "@/layout/components/Message";
import { validAllInput } from "@/utils/validate";

export default {
  name: "ResetPassword",
  data() {
    return {
      resetModel: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      },
    };
  },
  methods: {
    submit() {
      if (validAllInput(this.resetModel))
        return Message.error(this.$t("default.fieldsNotEntered"), 2000);
      if (this.resetModel.newPassword !== this.resetModel.confirmPassword)
        return Message.error(this.$t("reset-password.errorMsg"), 2000);
      resetPassword(this.resetModel).then((res) => {
        if(res) this.resetInput();
      });
    },
    resetInput() {
      for (let i in this.resetModel) {
        this.resetModel[i] = undefined;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  border: 2px solid #fff;
  border-radius: 8px;
  padding: 30px;
  margin: auto;
  .reset-block {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      label {
        display: block;
      }
    }
  }
  .advance-block {
    justify-content: center;
    margin-bottom: 0;
  }
}
</style>
