
export let searchModel = {
    memberAccount: undefined,
    createStartTime: undefined,
    createEndTime: undefined,
    auditStartTime: undefined,
    auditEndTime: undefined,
    discountAuditStatus: 1,
    discountType: 0,
    Name: undefined,
    isFuzzySearch: false,
    page:  {
        "take": 10,
        "skip": 0
    },
    sort: [
        {
            "column": "createTime",
            "order": 2
        }
    ]
}