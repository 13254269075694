<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
        </div>
        <div class="divider"></div>
        <div class="dataDiv">
          <div class="textareaDiv">
            {{ $t("default.remark") }}
            <textarea rows="7" v-model="editModel.remark"></textarea>
          </div>
          <div class="btnDiv">
            <button class="normal-btn mr-3" @click="edit(2)">{{ $t(`${i18nPrefix}dialog.edit`) }}</button>
            <button class="delete-btn" @click="edit(5)">{{ $t(`${i18nPrefix}dialog.reject`) }}</button>
          </div>
        </div>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" id="checkAll" @click="checkAll" />
                  </th>
                  <th>{{ $t("default.member") }}</th>
                  <th>{{ $t(`${i18nPrefix}name`) }}</th>
                  <th
                    @click="dataSort('createTime', 'time')"
                  >{{ $t(`${i18nPrefix}createTime`) }} {{ direction('createTime') }}</th>
                  <th>{{ $t(`${i18nPrefix}submitTime`) }}</th>
                  <th>{{ $t(`${i18nPrefix}discountType`) }}</th>
                  <th>{{ $t("default.status") }}</th>
                  <!-- <th>享有日期</th> -->
                  <th>{{ $t(`${i18nPrefix}firstDepositAmount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}bonusReceive`) }}</th>
                  <th>{{ $t(`${i18nPrefix}totalBetAmountLimit`) }}</th>
                  <th>{{ $t(`${i18nPrefix}totalValidBetAmount`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>
                    <input
                      class="checkAll"
                      v-if="
                        list.discountAuditStatus === 1 && list.discountType !== 29 && list.discountType !== 30
                      "
                      :id="list.id"
                      type="checkbox"
                      v-model="editModel.ids"
                      :value="list.id"
                    />
                    <input v-else type="checkbox" style="visibility: hidden" />
                  </td>
                  <td>{{ list.memberAccount }}</td>
                  <td>{{ list.name }}</td>
                  <td>{{ list.createTime }}</td>
                  <td>{{ list.submitTime }}</td>
                  <td>
                    {{
                    GLOBAL.i18nCheckName(getDiscountTypeListAddDefault.find(
                    (data) => data.id === list.discountType
                    ))
                    }}
                  </td>
                  <td>
                    {{
                    GLOBAL.i18nCheckName(getDiscountAuditStatus.find(
                    (data) => data.id === list.discountAuditStatus
                    ))
                    }}
                  </td>
                  <!-- <td>{{ list.startTime }}</td> -->
                  <td>{{ list.firstDepositAmount }}</td>
                  <td>{{ list.bonusReceive }}</td>
                  <td>{{ list.totalBetAmountLimit }}</td>
                  <td>{{ list.totalBetAmountLimit }}</td>
                </tr>
              </tbody>
            </table>
            <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage"></Pagination>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import Pagination from "@/layout/components/Pagination";
import { mapGetters } from "vuex";

export default {
  name: "BatchEdit",
  props: ["tableData", "i18nPrefix"],
  data() {
    return {
      editModel: {
        ids: [],
        discountAuditStatus: undefined,
        remark: undefined,
        isSingle: false
      }
    };
  },
  components: {
    Pagination
  },
  computed: {
    ...mapGetters([
      "getDiscountTypeListAddDefault",
      "getUserinfo",
      "getDiscountAuditStatus"
    ])
  },
  watch: {
    "editModel.ids": function() {
      let checkElements = document.getElementsByClassName("checkAll");
      for (let index = 0; index < checkElements.length; index++) {
        let checkCount = 0;
        for (let i = 0; i < checkElements.length; i++) {
          if (
            this.editModel.ids.find(data => data === checkElements[i].value * 1)
          )
            checkCount++;
        }
        if (checkCount === checkElements.length) {
          document.getElementById("checkAll").checked = true;
        } else {
          document.getElementById("checkAll").checked = null;
        }
      }
    }
  },
  mixins: [handlePageUtils],
  created() {
    this.allData = this.tableData;
  },
  methods: {
    edit(Status) {
      this.editModel.discountAuditStatus = Status;
      this.$emit("edit", this.editModel);
    },
    checkAll() {
      let checkElements = document.getElementsByClassName("checkAll");
      for (var i = 0; i < checkElements.length; i++) {
        if (document.getElementById("checkAll").checked) {
          if (this.editModel.ids.indexOf(checkElements[i].value * 1) === -1)
            this.editModel.ids.push(checkElements[i].value * 1);
        } else {
          this.editModel.ids.splice(
            this.editModel.ids.indexOf(checkElements[i].value * 1),
            1
          );
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  .dataDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .textareaDiv {
      display: flex;
      flex-direction: column;
      width: 400px;
      textarea {
        width: 300px;
      }
    }
    .btnDiv {
      display: flex;
      flex-direction: column;
      button {
        width: 100px;
        margin: 5px;
      }
    }
  }
  .input-block {
    label {
      width: 40%;
    }
  }
}
</style>