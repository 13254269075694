var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask create-member",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}currentTicket`))),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c(
            "main",
            [
              _c("div", { staticClass: "input-block" }, [
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}date`)) + " "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ticketModel.id,
                          expression: "ticketModel.id",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ticketModel,
                            "id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.periodList, function (d) {
                      return _c(
                        "option",
                        { key: "data" + d.id, domProps: { value: d.id } },
                        [_vm._v(_vm._s(d.name))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.addOption`)) +
                      " "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.option,
                          expression: "option",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.option = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.memberAccount`)
                          )
                        ),
                      ]),
                      _c("option", { domProps: { value: 2 } }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.addOption`))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm.option == 1
                  ? _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.memberAccount`)
                          ) +
                          " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ticketModel.memberAccount,
                            expression: "ticketModel.memberAccount",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.ticketModel.memberAccount },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ticketModel,
                              "memberAccount",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.option == 1
                  ? _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}currentTicketCount`)
                          ) +
                          " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.ticketModel.ticketCount,
                            expression: "ticketModel.ticketCount",
                            modifiers: { number: true },
                          },
                        ],
                        attrs: { type: "number", min: "1" },
                        domProps: { value: _vm.ticketModel.ticketCount },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ticketModel,
                              "ticketCount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.option == 1
                  ? _c("div", { staticClass: "textarea-div" }, [
                      _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ticketModel.remark,
                            expression: "ticketModel.remark",
                          },
                        ],
                        attrs: { rows: "5" },
                        domProps: { value: _vm.ticketModel.remark },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.ticketModel,
                              "remark",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.option == 2,
                      expression: "option == 2",
                    },
                  ],
                  staticClass: "sample link",
                  on: { click: _vm.getSample },
                },
                [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.example`)))]
              ),
              _c("Excel", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.option == 2,
                    expression: "option == 2",
                  },
                ],
                on: { UpdateFile: _vm.UpdateFile },
              }),
              _c("div", { staticClass: "advance-block" }, [
                _c(
                  "button",
                  { staticClass: "normal-btn", on: { click: _vm.submit } },
                  [_vm._v(_vm._s(_vm.$t("default.confirm")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "delete-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("toggle")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.cancel")))]
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }