var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t(`title.SEO`)) + _vm._s(_vm.$t(`default.edit`))
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _vm._v(
              " " +
                _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.edit-SEO.enterSEO`)) +
                " "
            ),
            _c("div", { staticClass: "textarea" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.preview,
                    expression: "preview",
                  },
                ],
                attrs: { rows: "39" },
                domProps: { value: _vm.preview },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.preview = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                {
                  staticClass: "normal-btn",
                  on: {
                    click: function ($event) {
                      _vm.$emit("editSEO", {
                        id: _vm.id,
                        metadata: _vm.parseToSeo(_vm.preview),
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("default.confirm")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }