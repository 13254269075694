import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getOperatingReport(data) {
    return request({
        url: "/OperatingReport/query",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getOperatingExcel(data) {
    return request({
        url: "/OperatingReport/Export",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` },
        responseType: 'blob'
    });
}