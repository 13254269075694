<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>二次驗證</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <img :src="verifyData.qrCodeImageUrl" v-show="verifyData.qrCodeImageUrl">
            <div class="verifyData">
              <label v-show="verifyData.qrCodeImageUrl">
                手動輸入金鑰
                <input type="text" v-model="verifyData.manualEntrySetupCode" disabled />
              </label>
              <label>
                驗證碼
                <input type="text" v-model="code" />
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="$emit('twoFactorAutn', { code , verificationId: verificationId })" :disabled="check">
              {{ $t("default.confirm")}}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TwoFactor",
  props: {
    verifyData: Object,
    verificationId: String,
  },
  watch: {
    code(val) {
      this.code = val.trim();
      this.check = (val == '') ? true : false;
    }
  },
  data() {
    return {
      code: "",
      check: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 30% !important;
  .input-block {
    align-items: center;
    justify-content: center;
  }
  img {
    width: auto;
  }
  .verifyData {
    width: 65%;
    label {
      width: 100%;
    }
  }
}
</style>