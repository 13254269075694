export default {
    data() {
        return {
            allData: undefined,
            tableList: undefined,
            digital: new Intl.NumberFormat(),
            sort: true,
            chooice: undefined,
            take: 10,
            page: 1,
        }
    },
    methods: {
        changeTake(val) {
            this.take = val;
        },
        dataSort(chooiceTitle, sort) {
            this.searchForm.sort[0].column = chooiceTitle;
            this.searchForm.sort[0].order = sort ? 1 : 2;
            this.chooice = chooiceTitle;
            this.sort = !sort;
            this.search();
        },
        direction(chooiceTitle) {
            return this.chooice === chooiceTitle ? this.sort ? "⬇" : "⬆" : "";
        },
    }
}