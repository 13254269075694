<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}relationMember`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="list">
            <div v-if="!relationMember.length">
              <span>{{ $t("default.noData") }}</span>
            </div>
            <div v-for="(list, index) of relationMember" :key="index">
              <span>{{ list }}</span>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RelationMember",
  props: ["relationMember", "i18nPrefix"],
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 80%;

  main {
    .list {
      display: grid;
      grid-template-columns: repeat(10, 9%);
      grid-gap: 1em;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #999;

        span {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }

    @media screen and (max-width: 1280px) {
      .list {
        grid-template-columns: repeat(5, 19%);
      }
    }
  }
}</style>