<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{  $t("default.edit") + $t(`${i18nPrefix}accountBank`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="bank-info">
              <label>
                {{ $t("default.bankType") }}
                <select v-model="editModel.bankType" disabled  v-if="editModel.bankType !== 9">
                  <option v-for="list of getBankTypeList" :key="list.id" :value="list.id">{{ $t(list.name) }}</option>
                </select>
                <input type="text" v-else :value="bankDetail.walletName" disabled>
              </label>
              <label></label>
              <div class="multiselectDiv" v-show="editModel.bankType === 1 || editModel.bankType === 2">
              {{  $t(`${i18nPrefix}bank`) }}
                <multiselect
                  v-model="value"
                  label="name"
                  track-by="id"
                  :placeholder="$t(`${i18nPrefix}dialog.bank.choose`)"
                  :custom-label="nameWithLang"
                  :options="getBankList"
                  :allow-empty="false"
                  :preselectFirst="true"
                >
                </multiselect>
              </div>
              <label v-show="editModel.bankType === 1 || editModel.bankType === 2">
                {{ $t("default.bankBranchCode") }}
                <input type="text" v-model="editModel.bankBranchCode" />
              </label>
              <label v-show="editModel.bankType === 1 || editModel.bankType === 2">
                {{ $t(`${i18nPrefix}dialog.bank.bankBranch`) }}
                <input type="text" v-model="editModel.bankBranch" />
              </label>
              <label v-if="editModel.bankType !== 9">
                {{ $t(`${i18nPrefix}dialog.bank.bankAccountName`) }}
                <input type="text" v-model="editModel.bankAccountName" />
              </label>
              <label v-else>
                {{ $t("default.currencyName") }}
                <input type="text" v-model="bankDetail.currencyName" disabled />
              </label>
              <label>
                {{  editModel.bankType !== 9 ? $t(`${i18nPrefix}dialog.bank.bankAccountNumber`) : $t(`${i18nPrefix}dialog.bank.walletAddress`) }}
                <input type="text" v-model="editModel.bankAccountNumber" />
              </label>
              <label style="text-align: center">
                {{ $t(`${i18nPrefix}dialog.bank.isDefault`) }}
                <div
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': editModel.isDefault, 'disabled': !status }"
                >
                  <div
                    class="slide"
                    @click="status ? toggleSlide : ''"
                    :class="{ 'slide-on': editModel.isDefault }"
                  >
                    <label for="isDefault" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="isDefault"
                      v-model="editModel.isDefault"
                      style="display: none"
                      :disabled="!status"
                    />
                  </div>
                </div>
              </label>
              <label style="text-align: center">
                {{ status ? $t("default.isVerify") : $t("default.noVerify")}}
                <div
                  class="slide-block"
                  style="margin: auto"
                  :class="{ 'slide-block-on': status }"
                >
                  <div
                    class="slide"
                    @click="getUserinfo.permissions.includes(10109) ? toggleSlide : ''"
                    :class="{ 'slide-on': status }"
                  >
                    <label for="verifyStatus" style="display: none"></label>
                    <input
                      type="checkbox"
                      id="verifyStatus"
                      v-model="status"
                      @click="changeStatus"
                      style="display: none"
                      :disabled="!getUserinfo.permissions.includes(10109)"
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="advance-block">
            <button class="confirm-btn main-style-btn" @click="edit" :disabled="!getUserinfo.permissions.includes(10105)">
              {{ $t("default.confirm") }}
            </button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
          <div class="advance-block">
            <button class="delete-btn" @click="TOGGLE_CONFIRM()" :disabled="!getUserinfo.permissions.includes(10106)">
              {{ $t(`${i18nPrefix}dialog.bank.deleteBank`) }}
            </button>
            <!-- <button class="delete-btn" @click="$emit('deleteBank', memberBankInfoId )">刪除銀行</button> -->
          </div>
        </main>
        <div class="divider" v-show="editModel.bankType === 1 || editModel.bankType === 2"></div>
        <main  v-show="editModel.bankType === 1 || editModel.bankType === 2">
          <div class="photo-wrap">
            <div class="title">
              <div class="type-btn-div">
                <button v-for="list of typeList" :key="'typeBtn'+list.id"
                        class="type-btn" :class="{ active: list.id === chooseType}"
                        @click="chooseType = list.id">
                  {{ list.name }}
                </button>
              </div>
              <h5>{{ $t(`${i18nPrefix}dialog.bank.uploadedFile`) }}</h5>
              <button v-show="deleteStatus" class="delete-btn" @click="deletePhotos" :disabled="!getUserinfo.permissions.includes(10110)">
                {{ $t(`${i18nPrefix}dialog.bank.delPhoto`) }}
              </button>
              <div class="delete">
                <span>{{ $t(`${i18nPrefix}dialog.bank.batchDelete`) }}</span>
                <label>
                  <div
                      class="slide-block"
                      style="margin: auto"
                      :class="{ 'slide-block-on': deleteStatus }"
                  >
                    <div
                        class="slide"
                        @click="getUserinfo.permissions.includes(10110) ? toggleSlide : ''"
                        :class="{ 'slide-on': deleteStatus }"
                    >
                      <label for="deleteStatus" style="display: none"></label>
                      <input
                          type="checkbox"
                          id="deleteStatus"
                          v-model="deleteStatus"
                          @click="deleteStatus = !deleteStatus"
                          style="display: none"
                          :disabled="!getUserinfo.permissions.includes(10110)"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="label-wrap">
              <label :for="deleteStatus ? image.fileName : 1" class="photoDiv" v-for="(image,index) of Files" :key="index"  @click=" deleteStatus ? undefined : handleDialog(image.url)">
                <input class="checkbox" :id="image.fileName" :value="image.fileName" type="checkbox" v-model="deleteImages[chooseType].fileNames" style="display: none">
                <svg width="80" height="80">
                  <circle class="circle" fill="none" transform="rotate(-90 200 200)" stroke="#68E534" stroke-width="5" cx="360"
                          cy="40" r="30" stroke-linecap="round" />
                  <polyline class="tick" fill="none" stroke="#68E534" stroke-width="5" points="25,42 35,52 55,32"
                            stroke-linecap="round" stroke-linejoin="round"></polyline>
                </svg>
                <img class="photo" :src="image.url">
              </label>
            </div>
          </div>
          <div v-show="chooseType === 1">
            <file-pond
                ref="idBank"
                class-name="my-pond"
                allow-multiple="true"
                :labelIdle="$t(`${i18nPrefix}dialog.bank.bankIdle`)"
                accepted-file-types="image/jpeg, image/png"
                instantUpload="false"
                :files="uploadIdBank"
                credits="false"
                maxFiles="20"
                maxFileSize="5MB"
                :labelMaxFileSizeExceeded="$t(`${i18nPrefix}dialog.bank.maxSizeExceeded`)"
                :labelMaxFileSize="$t(`${i18nPrefix}dialog.bank.maxSize`)"
                imagePreviewHeight="126"
                :disabled="!getUserinfo.permissions.includes(10108)"
            />
            <button class="upload-btn" @click="updatePhotos(1)" :disabled="!getUserinfo.permissions.includes(10108)">
              {{ $t(`${i18nPrefix}dialog.bank.update`) + $t(`${i18nPrefix}dialog.bank.bankData`) }}
            </button>
          </div>
          <div v-show="chooseType === 2">
            <file-pond
                ref="credit"
                class-name="my-pond"
                allow-multiple="true"
                :labelIdle="$t(`${i18nPrefix}dialog.bank.creditIdle`)"
                accepted-file-types="image/jpeg, image/png"
                instantUpload="false"
                :files="uploadCredit"
                credits="false"
                maxFiles="20"
                maxFileSize="5MB"
                :labelMaxFileSizeExceeded="$t(`${i18nPrefix}dialog.bank.maxSizeExceeded`)"
                :labelMaxFileSize="$t(`${i18nPrefix}dialog.bank.maxSize`)"
                imagePreviewHeight="126"
                :disabled="!getUserinfo.permissions.includes(10108)"
            />
            <button class="upload-btn" @click="updatePhotos(2)" :disabled="!getUserinfo.permissions.includes(10108)">
              {{ $t(`${i18nPrefix}dialog.bank.update`) + $t(`${i18nPrefix}creditCard`) }}
            </button>
          </div>
        </main>
        <transition name="fade" mode="out-in">
          <component
              :is="currDialog"
              @toggle="currDialog = dialogData = undefined"
              v-bind="dialogData"
          ></component>
        </transition>
        <transition name="fade" mode="out-in">
          <Confirm
            v-if="getShowConfirm"
            @toggle="TOGGLE_CONFIRM"
            @delete="$emit('deleteBank', bankDetail.id), TOGGLE_CONFIRM()"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { listPhoto,uploadPhotos,deletePhotos } from "@/api/member/member.js"
import { mapGetters, mapMutations } from "vuex";
import { toggleStyle } from "@/utils/slideUtils";
import Message from "@/layout/components/Message";
import Confirm from "@/layout/components/Confirm";
import Photo from "@/layout/components/photo";
// Import FilePond
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import Multiselect from "vue-multiselect";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "EditBank",
  props: [
    "bankDetail",
    "id",
    "i18nPrefix"
  ],
  components: {
    Confirm,
    FilePond,
    Photo,
    Multiselect,
  },
  data() {
    return {
      editModel: {
        memberBankInfoId: this.bankDetail.id,
        bankId: this.bankDetail.bankId,
        bankType: this.bankDetail.bankType,
        bankName: this.bankDetail.bankName,
        bankAccountName: this.bankDetail.bankAccountName,
        bankAccountNumber: this.bankDetail.bankAccountNumber,
        verifyStatus: this.bankDetail.verifyStatus,
        bankBranch: this.bankDetail.bankBranch,
        bankBranchCode: this.bankDetail.bankBranchCode,
        isDefault: this.bankDetail.isDefault,
      },
      typeList: [
        {
          id: 1,
          name: this.$t(`${this.i18nPrefix}dialog.bank.bankData`),
        },
        {
          id: 2,
          name: this.$t(`${this.i18nPrefix}creditCard`),
        }
      ],
      chooseType: 1,
      status: false,
      Files: [],
      uploadIdBank: [],
      uploadBank: [],
      uploadCredit: [],
      photoList: [],
      currDialog: undefined,
      dialogData: undefined,
      deleteStatus: false,
      deleteImages: {
        1:
          {
            type: 1,
            fileNames: [],
          },
        2:
          {
            type: 2 ,
            fileNames: [],
          },
      },
      value: [],
    };
  },
  watch: {
    "chooseType": function(val){
      this.Files = this.photoList.find((data) => data.type === val)?.images;
    },
    "deleteStatus": function (val){
      if(!val){
        let checkElements = document.getElementsByClassName('checkbox');
        for (var i = 0; i < checkElements.length; i++) {
          checkElements[i].checked = false;
        }
        this.deleteImages[1].fileNames = [];
        this.deleteImages[2].fileNames = [];
      }
    },
    // "editModel.bankAccountName": function (val, oldVal) {
    //   if (/^[\u4E00-\u9FA5]+$/.test(val) === false && val !== '') {
    //     this.editModel.bankAccountName = oldVal ? oldVal : '';
    //     Message.error("請輸入正確文字", 1000);
    //   }
    // },
    "editModel.bankAccountNumber": function (val, oldVal) {
      if (/^[0-9]*$/.test(val) === false) {
        this.editModel.bankAccountNumber = (oldVal ?? '');
        Message.error(this.$t("default.checkNum"), 1000);
      }
    },
    "status": function (val) {
      if (!val) this.editModel.isDefault = false;
    }
  },
  computed: {
    ...mapGetters(["getBankList", "getShowConfirm","getUserinfo","getBankTypeList"]),
  },
  created() {
    this.value = this.getBankList.find(data => data.id === this.bankDetail.bankId);
    this.getPhotoList();
    this.status = this.editModel.verifyStatus === 1 ? true : false;
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
    }),
    toggleSlide: toggleStyle,
    getPhotoList() {
      listPhoto({ memberId: this.id }).then((res) => {
        this.photoList = res.data.typeList;
        this.Files = this.photoList.find((data) => data.type === this.chooseType)?.images;
      })
    },
    deletePhotos() {
      let deleteData = {
        memberId: this.id,
        deleteImages: [this.deleteImages[1],this.deleteImages[2]],
      }
      deletePhotos(deleteData).then((res) => {
        if(res) this.getPhotoList();
      })
    },
    edit() {
      this.editModel.bankId = (this.editModel.bankType === 1 || this.editModel.bankType === 2) ? this.value?.id : 0;
      this.editModel.verifyStatus = this.status ? 1 : 2;
      if (!this.editModel.bankAccountName || !this.editModel.bankAccountNumber) {
        return Message.error(this.$t(`${this.i18nPrefix}dialog.bank.errorMsg`), 2000);
      }
      this.$emit("editBank", this.editModel);
    },
    changeStatus() {
      this.status = !this.status;
    },
    updatePhotos(type) {
      let formData = new FormData();
      let photoData = undefined;
      let photoCount = 0;
      formData.append("MemberId", this.id);
      formData.append("Type", type);
      switch (type) {
        case 1:
          photoData = this.$refs.idBank.getFiles();
          break;
        case 2:
          photoData = this.$refs.credit.getFiles();
          break;
      };
      if(!photoData?.length) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.bank.errorMsg2`), 2000);
        return
      }
      photoCount = photoData?.length + this.Files?.length;
      if (photoCount > 20) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.bank.errorMsg3`), 2000);
        return
      }
      if(photoData.find((data) => data.file.name.includes(',')) !== undefined) {
        Message.error(this.$t(`${this.i18nPrefix}dialog.bank.errorMsg4`), 2000);
        return
      }
      photoData.map((data) => formData.append("images", data.file, data.file.name));
      uploadPhotos(formData).then((res) => {
        if(res) this.getPhotoList();
      });
      this.uploadIdBank = [];
      this.uploadCredit = [];
    },
    handleDialog(image) {
      this.currDialog = "Photo";
      this.dialogData = {image};
    },
    nameWithLang ({ name, code }) {
      return `${code}－${name}`
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.dialog-container {
  width: 90%;
}
.bank-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width:100%;
  label {
    width: 45%;
    .disabled {
      background-color: gray;
    }
  }
}
.photo-wrap {
  border-radius:10px;
  border-color: black;
  border-style:solid;
  margin-bottom: 10px;
  min-height: 80px;
  background-color: gray;
  .title {
    display: block;
    position:relative;
    width: 100%;
    margin-bottom: 60px;
    h5 {
      position: absolute;
      width: 100%;
      text-align:center;
      line-height:70px;
    }
    .type-btn-div {
      position: absolute;
      z-index: 999;
      display: flex;
      flex-wrap: wrap;
      margin: 20px;
      .type-btn {
        background-color: #d28600;
        color: #fff5e5 !important;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        margin: auto;
        margin-left: 10px;
        display: block;
      }
      .active {
        background-color: #e3ae5e;
      }
    }
    .delete-btn {
      position: absolute;
      margin-top: 25px;
      right: 130px;
    }
    .delete {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 999;
      width: 100px;
      right: 20px;
      margin-top: 10px;
      span {
        display: block;
        text-align:center;
        line-height:20px;
      }
      label {
        display: block ;
      }
    }
  }
  .label-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .photoDiv {
      height: 200px;
      margin: 0.5rem;
      padding: 0.5rem;
      cursor: pointer;
      position:relative;
      background-color: black;
      .photo {
        width: 100%;
        height: 100%;
      }
      svg {
        right: 0px;
        top: 5px;
        position: absolute;
      }
    }
  }
}

.multiselectDiv{
  width: 45%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.upload-btn {
  background-color: #d28600;
  color: #fff5e5 !important;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: auto;
  margin-bottom: 20px;
  display: block;
}
.filepond--panel-root {
  border-radius: 5px;
  cursor: pointer;
}
.filepond--item {
  width: calc(50% - 0.5em);
}
</style>
<style lang="scss">
.filepond--panel-root {
  border-radius: 5px;
  cursor: pointer;
}
.filepond--item {
  width: calc(50% - 0.5em);
}
.circle {
  stroke-dasharray: 1194;
  stroke-dashoffset: 1194;
}

.tick {
  stroke-dasharray: 350;
  stroke-dashoffset: 350;
}

input[type="checkbox"]:checked+svg .circle {
  animation: circle 0.5s ease-in-out forwards;
}

input[type="checkbox"]:checked+svg .tick {
  animation: tick 0.3s 0.55s ease-in-out forwards;
}

@keyframes circle {
  from {
    stroke-dashoffset: 1194;
  }

  to {
    stroke-dashoffset: 2388;
  }
}

@keyframes tick {
  from {
    stroke-dashoffset: 350;
  }

  to {
    stroke-dashoffset: 0;
  }
}
</style>