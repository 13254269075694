var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "imgDiv" }, [
      _c(
        "div",
        {
          staticClass: "previewDiv",
          on: {
            click: function ($event) {
              return _vm.upload("File")
            },
            drop: _vm.drop,
            dragenter: _vm.stopDefault,
            dragleave: _vm.stopDefault,
            dragover: _vm.stopDefault,
          },
        },
        [
          !_vm.File
            ? _c("div", { staticClass: "upload" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/icon/upload.svg") },
                }),
                _c("span", [_vm._v(_vm._s(_vm.$t("default.uploadFile")))]),
              ])
            : _c("span", [_vm._v(_vm._s(_vm.File.name))]),
        ]
      ),
    ]),
    _c("input", {
      ref: "file",
      staticClass: "custom-file",
      attrs: { type: "file" },
      on: { change: _vm.fileSelect },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }