var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t(`${_vm.i18nPrefix}memberLevel`) +
                    _vm.$t("default.edit")
                )
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "delete-btn float-right",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(11003),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDelete(_vm.list, "deleteStarLevel")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.delete")))]
            ),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}name`)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.name = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}memberLevel`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.star,
                      expression: "editModel.star",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.star },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "star", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("default.remark")) + " "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.upgradeConditions`))
                  ),
                ]),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}depositRequired`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.depositRequired,
                      expression: "editModel.depositRequired",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.depositRequired },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "depositRequired",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}betRequired`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.betRequired,
                      expression: "editModel.betRequired",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.betRequired },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "betRequired",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.levelLimit`))),
                ]),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalRequired`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.withdrawalRequired,
                      expression: "editModel.withdrawalRequired",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.withdrawalRequired },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "withdrawalRequired",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalLimit`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.withdrawalLimit,
                      expression: "editModel.withdrawalLimit",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.withdrawalLimit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "withdrawalLimit",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`${_vm.i18nPrefix}dayWithdrawalLimit`)) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.dayWithdrawalLimit,
                      expression: "editModel.dayWithdrawalLimit",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.dayWithdrawalLimit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "dayWithdrawalLimit",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", { staticClass: "title" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`))),
                ]),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.promotionBonus,
                      expression: "editModel.promotionBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.promotionBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "promotionBonus",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}weekBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.weekBonus,
                      expression: "editModel.weekBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.weekBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "weekBonus", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}monthBonus`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.monthBonus,
                      expression: "editModel.monthBonus",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.monthBonus },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.editModel, "monthBonus", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}bounsMultiple`)) + " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editModel.bounsMultiple,
                      expression: "editModel.bounsMultiple",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.editModel.bounsMultiple },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.editModel,
                        "bounsMultiple",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.edit } },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.alertMsg
            ? _c("Alert", {
                attrs: { message: _vm.alertMsg },
                on: {
                  toggle: function ($event) {
                    _vm.alertMsg = undefined
                  },
                  doIt: _vm.deleteStarLevel,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }