<template>
  <div>
    <div class="bookmark-box">
      <div class="bookmark-view">
        <div v-for="list of bookmark" :key="list.id" @click="chooice = list.id"
          :class="{ 'chooice': chooice == list.id }">{{ $t(list.name) }}</div>
      </div>
    </div>
    <component :i18nPrefix="i18nPrefix" :is="chooice"></component>
  </div>
</template>

<script>
import MemberDepositPaymentReport from "./member-deposit-payment-report";
import MemberDepositCryptoPaymentReport from "./member-depositCrypto-payment-report";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
      i18nPrefix: "report.member-deposit.",
      chooice: "member-deposit-payment-report",
      bookmark: [
        {
          id: "member-deposit-payment-report",
          name: "default.deposit"
        },
        {
          id: "member-depositCrypto-payment-report",
          name: "default.depositCrypto"
        }
      ]
    };
  },
  components: {
    MemberDepositPaymentReport,
    MemberDepositCryptoPaymentReport
  },
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  methods: {}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>