import { memberSelectMenu } from "@/api/member/member";
import { paymentList } from "@/api/member/deposit-store";
import {
    getGamePlatforms,
    getGamesCategories,
    getDiscountTypes,
    getAgentWithdrawalInfoList,
    getAnnouncementTypes,
    getRebateGamePlatformList,
    getCompanyDepositSettingsList,
    getPaymentStatus,
    getAuditStatus,
    getDiscountAuditStatuses,
    getGameCategoryAndPlatform
} from "@/api/selectMenu";
import { bankTypeList } from "@/api/features/bankTypeSetting";
import { tagList } from "@/api/features/tagManagement";
import store from "@/store";

const state = {
    statusList: [
        {
            id: 1,
            name: "default.active"
        },
        {
            id: 2,
            name: "default.inactive"
        }
    ],
    week: [
        {
            id: 0,
            name: "week.sun"
        },
        {
            id: 1,
            name: "week.mon"
        },
        {
            id: 2,
            name: "week.tue"
        },
        {
            id: 3,
            name: "week.wed"
        },
        {
            id: 4,
            name: "week.thu"
        },
        {
            id: 5,
            name: "week.fri"
        },
        {
            id: 6,
            name: "week.sat"
        }
    ],
    deviceList: [
        {
            id: 0,
            name: "deviceList.allPlatforms"
        },
        {
            id: 1,
            name: "deviceList.mobile"
        },
        {
            id: 2,
            name: "deviceList.computer"
        },
        {
            id: 3,
            name: "deviceList.ios"
        },
        {
            id: 4,
            name: "deviceList.android"
        }
    ],
    deviceType: [
        {
            id: 0,
            name: "deviceType.computer"
        },
        {
            id: 1,
            name: "deviceType.mobile"
        },
        {
            id: 2,
            name: "deviceType.app"
        },
    ],
    none: { id: 0, gateway: 0, key: 0, name: "default.all", account: "default.all" },
    errorCode: [
        {
            id: 0,
            name: "errorCode.error0"
        },
        {
            id: 1,
            name: "errorCode.error1"
        },
        {
            id: 25,
            name: "errorCode.error1"
        },
        {
            id: 26,
            name: "errorCode.error2"
        },
        {
            id: 27,
            name: "errorCode.error3"
        },
        {
            id: 28,
            name: "errorCode.error4"
        },
        {
            id: 29,
            name: "errorCode.error5"
        },
        {
            id: 30,
            name: "errorCode.error6"
        },
        {
            id: 31,
            name: "errorCode.error7"
        },
        {
            id: 32,
            name: "errorCode.error8"
        },
        {
            id: 33,
            name: "errorCode.error9"
        },
    ],
    mailTypeList: [
        {
            id: 10200,
            name: "mailTypeList.mailType0"
        },
        {
            id: 10300,
            name: "mailTypeList.mailType1"
        },
        {
            id: 10400,
            name: "mailTypeList.mailType2"
        },
        {
            id: 61000,
            name: "mailTypeList.mailType3"
        },
        {
            id: 10500,
            name: "mailTypeList.mailType4"
        },
        {
            id: 10109,
            name: "mailTypeList.mailType5"
        },
        {
            id: 120200,
            name: "mailTypeList.mailType6"
        },
    ],
    gamePlatforms: [],
    gamesCategorys: [],
    stars: [],
    agentWithdrawalInfoList: [],
    companyDepositSettingsList: [],
    announcementTypes: [],
    rebateGamePlatformList: [],
    tagList: [],
    bankList: [],
    bankGroupList: [],
    levelGroupsList: [],
    paymentList: [],
    agentList: [],
    discountTypes: [],
    bankTypes: [],
    auditStatus: [],
    paymentStatus: [],
    discountAuditStatus: [],
    gameCategoryAndPlatform: [],
    loginType: [],
}
const mutations = {
    SET_GAMELIST(state, payload) {
        state.gameList = payload;
    },
    SET_BANKLIST(state, payload) {
        state.bankList = payload;
    },
    SET_BANKGROUPLIST(state, payload) {
        state.bankGroupList = payload;
    },
    SET_LEVELGROUPS(state, payload) {
        state.levelGroupsList = payload;
    },
    SET_PAYMENTLIST(state, payload) {
        state.paymentList = payload;
    },
    SET_AGENTLIST(state, payload) {
        state.agentList = payload;
    },
    SET_DISCOUNTTYPES(state, payload) {
        state.discountTypes = payload;
    },
    SET_BANKTYPES(state, payload) {
        state.bankTypes = payload;
    },
    SET_GAMEPLATFORMS(state, payload) {
        state.gamePlatforms = payload;
    },
    SET_GAMESCATEGORYS(state, payload) {
        state.gamesCategorys = payload;
    },
    SET_STARS(state, payload) {
        state.stars = payload;
    },
    SET_AGENTWITHDRAWALINFOLIST(state, payload) {
        state.agentWithdrawalInfoList = payload;
    },
    SET_COMPANYDEPOSITSETTINGSLIST(state, payload) {
        state.companyDepositSettingsList = payload;
    },
    SET_ANNOUNCEMENTTPYES(state, payload) {
        state.announcementTypes = payload;
    },
    SET_REBATEGAMEPLATFROMLIST(state, payload) {
        state.rebateGamePlatformList = payload;
    },
    SET_TAGLIST(state, payload) {
        state.tagList = payload;
    },
    SET_PAYMENTSTATUS(state, payload) {
        state.paymentStatus = payload;
    },
    SET_AUDITSTATUS(state, payload) {
        state.auditStatus = payload;
    },
    SET_DISCOUNTAUDITSTATUS(state, payload) {
        state.discountAuditStatus = payload;
    },
    SET_GAMECATEGORYANDPLATFORM(state, payload) {
        state.gameCategoryAndPlatform = payload;
    },
    SET_LOGINTYPE(state, payload) {
        state.loginType = payload;
    }
}
const actions = {
    async getAllSelect({ commit }, payload) {
        await memberSelectMenu(store.getters.getUserinfo).then((res) => {
            commit("SET_BANKLIST", res.data.banks);
            commit("SET_BANKGROUPLIST", res.data.bankGroups);
            commit("SET_AGENTLIST", res.data.agents);
            commit("SET_STARS", res.data.stars);
            commit("SET_LOGINTYPE", res.data.loginType);
        });
        paymentList().then((res) => {
            commit("SET_PAYMENTLIST", res.data.paymentCompanies);
        });
        getGamePlatforms().then((res) => {
            commit("SET_GAMEPLATFORMS", res.data
                .map(data => { return { id: parseInt(data.name), name: "GamePlatform." + data.name } }));
        });
        getGamesCategories().then((res) => {
            commit("SET_GAMESCATEGORYS", res.data
                .map(data => { return { id: parseInt(data.id), name: "GameCategory." + data.name } }));
        });
        getDiscountTypes().then((res) => {
            commit("SET_DISCOUNTTYPES", res.data
                .map(data => { return { id: data.id, name: "DiscountType." + data.name } }));
        });
        bankTypeList().then((res) => {
            commit("SET_BANKTYPES", res.data
                .map(data => { return { id: data.bankTypeId, name: "BankType." + data.bankTypeName } }));
        });
        getAgentWithdrawalInfoList().then((res) => {
            commit("SET_AGENTWITHDRAWALINFOLIST", res.data);
        });
        getCompanyDepositSettingsList().then((res) => {
            commit("SET_COMPANYDEPOSITSETTINGSLIST", res.data);
        });
        getAnnouncementTypes().then((res) => {
            commit("SET_ANNOUNCEMENTTPYES", res.data
                .map(data => { return { id: data.id, name: "AnnouncementType." + data.name } }));
        });
        getRebateGamePlatformList().then((res) => {
            commit("SET_REBATEGAMEPLATFROMLIST", res.data
                .map(data => { return { id: data.id, name: "GamePlatform." + data.gamePlatformName } }));
        });
        tagList().then(res => {
            commit("SET_TAGLIST", res.data)
        });
        getPaymentStatus().then((res) => {
            commit("SET_PAYMENTSTATUS", res.data
                .map(data => { return { id: data.id, name: "PaymentStatus." + data.name } }));
        });
        getAuditStatus().then((res) => {
            commit("SET_AUDITSTATUS", res.data
                .map(data => { return { id: data.id, name: "AuditStatus." + data.name } }));
        });
        getDiscountAuditStatuses().then((res) => {
            commit("SET_DISCOUNTAUDITSTATUS", res.data
                .map(data => { return { id: data.id, name: "DiscountAuditStatus." + data.name } }));
        });
        getGameCategoryAndPlatform().then((res) => {
            commit("SET_GAMECATEGORYANDPLATFORM", res.data);
        });
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}