<template>
  <div class="time-block"> <!-- w-100 -->
      <button class="quick-btn" :class="{'quick-btn-active': now === 'lastMonth'}" @click="changeDate('lastMonth')">{{ $t("default.last-month") }}</button>
      <button class="quick-btn" :class="{'quick-btn-active': now === 'lastWeek'}" @click="changeDate('lastWeek')">{{ $t("default.last-week") }}</button>
      <button class="quick-btn" :class="{'quick-btn-active': now === 'yesturday'}" @click="changeDate('yesturday')">{{ $t("default.yesterday") }}</button>
      <button class="quick-btn" :class="{'quick-btn-active': now === 'today'}" @click="changeDate('today')">{{ $t("default.today") }}</button>
      <button class="quick-btn" :class="{'quick-btn-active': now === 'week'}" @click="changeDate('week')">{{ $t("default.this-week") }}</button>
      <button class="quick-btn" :class="{'quick-btn-active': now === 'month'}" @click="changeDate('month')">{{ $t("default.this-month") }}</button>
  </div>
</template>

<script>
export default {
  name: "QuickSearch",
  props:["controlType"],
  data() {
    return {
      year: undefined,
      month: undefined,
      day: undefined,
      timeObj: {
        startTime: undefined,
        endTime: undefined,
      },
      now: undefined
    };
  },
  methods: {
    changeDate(condition) {
      let fromTime = (this.controlType) ? "" : " 00:00";
      let toTime = (this.controlType) ? "" : " 23:59";
      let date = new Date();

      if (condition === "lastMonth") {
        date = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        this.getDateData(date);
        this.timeObj.startTime = `${this.year}-${this.month}-01${fromTime}`;
        this.timeObj.endTime = `${this.year}-${this.month}-${this.daysInMonth(this.year, this.month)}${toTime}`;
      }else if (condition === "lastWeek") {
        date.setDate(date.getDate() - ((date.getDay())? date.getDay() : 7 ) - 6);
        this.getDateData(date);
        this.timeObj.startTime = `${this.year}-${this.month}-${this.day}${fromTime}`;
        date.setDate(date.getDate() + 6);
        this.getDateData(date);
        this.timeObj.endTime = `${this.year}-${this.month}-${this.day}${toTime}`;
      }else if (condition === "today") {
        this.getDateData(date);
        this.timeObj.startTime = `${this.year}-${this.month}-${this.day}${fromTime}`;
        this.timeObj.endTime = `${this.year}-${this.month}-${this.day}${toTime}`;
      }else if (condition === "yesturday") {
        date.setDate(date.getDate() - 1);
        this.getDateData(date);
        this.timeObj.startTime = `${this.year}-${this.month}-${this.day}${fromTime}`;
        this.timeObj.endTime = `${this.year}-${this.month}-${this.day}${toTime}`;
      }else if (condition === "week") {
        date.setDate(date.getDate() - ((date.getDay())? date.getDay() : 7 ) + 1);
        this.getDateData(date);
        this.timeObj.startTime = `${this.year}-${this.month}-${this.day}${fromTime}`;
        date.setDate(date.getDate() + 6);
        this.getDateData(date);
        this.timeObj.endTime = `${this.year}-${this.month}-${this.day}${toTime}`;
      }else if(condition === "month") {
        this.getDateData(date);
        this.timeObj.startTime = `${this.year}-${this.month}-01${fromTime}`;
        this.timeObj.endTime = `${this.year}-${this.month}-${this.daysInMonth(this.year, this.month)}${toTime}`;
      }
      this.$emit("changeTime", this.timeObj);
      this.now = condition;

    },
    getDateData(date) {
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.month = (this.month).toString().length > 1 ? this.month : `0${this.month}`;
      this.day = date.getDate();
      this.day = (this.day).toString().length > 1 ? this.day : `0${this.day}`;
    },
    daysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
  },
};
</script>

<style lang="scss" scoped>
.time-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // gap: 5px;
  margin-top: 20px;
  font-size: .7em;
  button {
    // background-color: #00a65a;
    background-color: #fff;
    padding: 0 30px;
    // color: #fff;
    color: #000;
    // border-radius: 5px;
    // border: 3px solid transparent;
    border: 1.5px solid #7E96A3;
    border-right: 0;
    min-height: 30px;
    font-size: 12px;
    &:hover {
        // background-color: #008d4c;
        background-image: linear-gradient(180deg, #7E96A3 0%, #52656F 100%);
        color: #fff;
    }
  }
  .quick-btn:first-child {
    border-radius: 8px 0 0 8px;
  }
  .quick-btn:last-child {
    border-right: 1.5px solid #7E96A3;
    border-radius: 0 8px 8px 0;
  }
  .quick-btn-active {
    background-image: linear-gradient(180deg, #7E96A3 0%, #52656F 100%);
    color: #fff;
  }
  span {
    display: flex;
    align-items: center;
    padding-right: 1.5%;
  }
}
@media screen and (max-width: 768px) {
  .time-block {
    width: 100%;
    justify-content: flex-start;
    button {
      padding: 5px 10px;
      flex-grow: 1;
    }
  }
}
@media screen and (max-width: 500px) {
  .time-block {
    width: 100%;
    font-size: .7em;
    button {
      padding: 5px 10px;
      flex-grow: 1;
    }
  }
}
</style>