<template>
  <div class="dialog-mask create-member" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>
            {{ $t("default.edit") }}{{ $t("title." + $route.meta.title) }}
          </h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label class="w-100">
              {{ $t("default.bankType") }}
              <select v-model="createModel.bankTypeId">
                <option v-for="list of bankTypeList" :key="list.id" :value="list.id * 1">
                  {{ $t("BankType." + list.name) }}
                </option>
              </select>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="create">
              {{ $t("default.confirm") }}
            </button>
            <button class="delete-btn" @click="$emit('toggle')">
              {{ $t("default.cancel") }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: [
    "bankTypeList",
    "i18nPrefix"
  ],
  data() {
    return {
      createModel: {
        bankTypeId: undefined,
        bankTypeName: undefined
      },
    };
  },
  created() {
    this.createModel.bankTypeId = this.bankTypeList[0]?.id
  },
  methods: {
    create() {
      this.createModel.bankTypeName = this.bankTypeList.find( d => d.id == this.createModel.bankTypeId ).name;
      this.$emit("create", this.createModel);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/banner.scss";

.dialog-container {
  width: 80% !important;
}
</style>