<template>
  <!-- <div v-show="loading" class="loading-bar">
    <div class="loader-overlay">
      <div class="loader"></div>
    </div>
  </div> -->
  <div v-show="loading" class="loadingBackground">
    <figure>
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoadingBar",
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style lang="scss" scoped>
// .loader-overlay {
//   position: fixed;
//   width: 100%;
//   z-index: 500000;
//   top: 0;
//   .loader {
//     height: 4px;
//     width: 100%;
//     position: relative;
//     overflow: hidden;
//     background-color: #fff;
//     &:before {
//       display: block;
//       position: absolute;
//       content: "";
//       left: -200px;
//       width: 200px;
//       height: 4px;
//       background-color: red;
//       animation: loading 2s linear infinite;
//     }
//   }
// }

// @keyframes loading {
//   from {
//     left: -200px;
//     width: 30%;
//   }
//   50% {
//     width: 30%;
//   }
//   70% {
//     width: 70%;
//   }
//   80% {
//     left: 50%;
//   }
//   95% {
//     left: 120%;
//   }
//   to {
//     left: 100%;
//   }
// }

body { background: #222; }
.loadingBackground {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  background-color: rgba(0, 0, 0, 0.384);
  z-index: 500000;
  figure { 
    position: absolute;
    margin: auto;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 6.250em; height: 6.250em;
    animation: rotate 2.4s linear infinite;
  }
  .white { 
    top: 0; bottom: 0; left: 0; right: 0; 
    background: white; 
    animation: flash 2.4s linear infinite;
    opacity: 0;
  }
  .dot {
    position: absolute;
    margin: auto;
    width: 2.4em; height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease;
  }
  .dot:nth-child(2) { top: 0; bottom: 0; left: 0; background: #FF4444; animation: dotsY 2.4s linear infinite; }
  .dot:nth-child(3) { left: 0; right: 0; top: 0; background: #FFBB33; animation: dotsX 2.4s linear infinite; }
  .dot:nth-child(4) { top: 0; bottom: 0; right: 0; background: #99CC00; animation: dotsY 2.4s linear infinite; }
  .dot:nth-child(5) { left: 0; right: 0; bottom: 0; background: #33B5E5; animation: dotsX 2.4s linear infinite; }

  @keyframes rotate {
    0% { transform: rotate( 0 ); }
    10% { width: 6.250em; height: 6.250em; }
    66% { width: 2.4em; height: 2.4em; }
    100%{ transform: rotate(360deg); width: 6.250em; height: 6.250em; }
  }

  @keyframes dotsY {
    66% { opacity: .1; width: 2.4em; }
    77%{ opacity: 1; width: 0; }
  }
  @keyframes dotsX {
    66% { opacity: .1; height: 2.4em;}
    77%{ opacity: 1; height: 0; }
  }

  @keyframes flash {
    33% { opacity: 0; border-radius: 0%; }
    55%{ opacity: .6; border-radius: 100%; }
    66%{ opacity: 0; }
  }
}
</style>