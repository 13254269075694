var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "create-btn main-style-btn",
                attrs: {
                  disabled: !_vm.getUserinfo.permissions.includes(11001),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}create`)) + " ")]
            ),
          ]),
        ]),
        _c("main", [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}name`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}memberLevel`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}starTotal`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}depositRequired`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betRequired`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}bounsMultiple`))),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalRequired`))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}withdrawalLimit`))),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dayWithdrawalLimit`))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}promotionBonus`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}weekBonus`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}monthBonus`))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.starLevelList || !_vm.starLevelList.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "12" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.starLevelList, function (list, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(11002),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog({ list }, "Edit")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.name))]),
                      _c("td", [
                        _vm._v(_vm._s(list.star + _vm.$t("default.star"))),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.starTotal))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.digital.format(list.depositRequired))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.digital.format(list.betRequired))),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.bounsMultiple))]),
                      _c("td", [_vm._v(_vm._s(list.withdrawalRequired))]),
                      _c("td", [_vm._v(_vm._s(list.withdrawalLimit))]),
                      _c("td", [_vm._v(_vm._s(list.dayWithdrawalLimit))]),
                      _c("td", [_vm._v(_vm._s(list.promotionBonus))]),
                      _c("td", [_vm._v(_vm._s(list.weekBonus))]),
                      _c("td", [_vm._v(_vm._s(list.monthBonus))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  agentList: _vm.getAgentList,
                  i18nPrefix: _vm.i18nPrefix,
                  getUserinfo: _vm.getUserinfo,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                  deleteStarLevel: _vm.deleteStarLevel,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }