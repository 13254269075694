<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="create-btn main-style-btn" @click="handleDialog(undefined, 'Create')"
            :disabled="!getUserinfo.permissions.includes(62401)">{{ $t("default.create") }}</button>
        </div>
      </div>
      <!-- <div class="main">
        <div class="input-block">
          <label>
          </label>
        </div>
      </div> -->
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th></th>
              <th style="width: 80% !important;">{{ $t("default.bankType") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list of tableList" :key="list.id">
              <td class="small-td">
                <div class="btn-td">
                  <button class="delete-btn" 
                    @click="handleDelete(list.id)"
                    :disabled="!getUserinfo.permissions.includes(62402)">
                    {{ $t("default.delete") }}
                  </button>
                </div>
              </td>
              <td>{{
                $t("BankType." + list.bankTypeName)
              }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
    <transition name="fade" mode="out-in">
        <Alert
          v-if="alertMsg"
          :message="alertMsg"
          @toggle="alertMsg = undefined"
          @doIt="deleteBankType"
        />
      </transition>
    <transition name="fade" mode="out-in">
      <component :is="currDialog" :bankTypeList="bankTypeList"
        v-bind="dialogData" @toggle="currDialog = undefined" @create="create"></component>
    </transition>
  </div>
</template>

<script>
import {
  getBankTypeList,
  createBankType,
  deleteBankType,
} from "@/api/features/bankTypeSetting";
import {
  getbankTypes,
} from "@/api/selectMenu";
import Create from "./dialog/create";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Alert from "@/layout/components/Alert";
import { mapGetters } from "vuex";

export default {
  name: "BankTypeSetting",
  data() {
    return {
      bankTypeList: [],
      alertMsg: undefined,
      deleteData: undefined
    };
  },
  components: {
    Create,
    Alert,
    Pagination
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getUserinfo"])
  },
  async created() {
    await getbankTypes().then(res => this.bankTypeList = res.data )
    this.search();
  },
  methods: {
    search() {
      this.closeDialog();
      getBankTypeList({}).then(res => {
        this.allData = res.data;
      });
    },
    create(data) {
      createBankType(data).then(res => {
        if (res) this.search();
      });
    },
    handleDelete(id) {
      this.alertMsg = this.$t("default.delMsg");
      this.deleteData = { id };
    },
    deleteBankType() {
      this.alertMsg = "";
      deleteBankType(this.deleteData).then(res => {
        if (res) this.search();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.url-td {
  width: 20%;
  white-space: unset !important;

  span {
    margin: auto 5px;
  }
}
</style>