<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}memberLevel`) + $t("default.edit") }}</h4>
          <button
            class="delete-btn float-right"
            :disabled="!getUserinfo.permissions.includes(11003)"
            @click="handleDelete(list, 'deleteStarLevel')"
          >{{ $t("default.delete") }}</button>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t(`${i18nPrefix}name`) }}
              <input type="text" v-model="name" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}memberLevel`) }}
              <input type="number" v-model="editModel.star" />
            </label>
            <label class="title">
              {{ $t("default.remark") }}
              <textarea v-model="description"></textarea>
            </label>
            <label class="title">
              <h4>{{ $t(`${i18nPrefix}dialog.upgradeConditions`) }}</h4>
            </label>
            <label>
              {{ $t(`${i18nPrefix}depositRequired`) }}
              <input type="number" v-model="editModel.depositRequired" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}betRequired`) }}
              <input type="number" v-model="editModel.betRequired" />
            </label>
            <label class="title">
              <h4>{{ $t(`${i18nPrefix}dialog.levelLimit`) }}</h4>
            </label>
            <label>
              {{ $t(`${i18nPrefix}withdrawalRequired`) }}
              <input type="number" v-model="editModel.withdrawalRequired" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}withdrawalLimit`) }}
              <input type="number" v-model="editModel.withdrawalLimit" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dayWithdrawalLimit`) }}
              <input type="number" v-model="editModel.dayWithdrawalLimit" />
            </label>
            <label class="title">
              <h4>{{ $t(`${i18nPrefix}promotionBonus`) }}</h4>
            </label>
            <label>
              {{ $t(`${i18nPrefix}promotionBonus`) }}
              <input
                type="number"
                v-model="editModel.promotionBonus"
              />
            </label>
            <label>
              {{ $t(`${i18nPrefix}weekBonus`) }}
              <input type="number" v-model="editModel.weekBonus" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}monthBonus`) }}
              <input type="number" v-model="editModel.monthBonus" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}bounsMultiple`) }}
              <input type="number" v-model="editModel.bounsMultiple" />
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="edit">{{ $t("default.confirm") }}</button>
            <button class="delete-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
          </div>
        </main>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="deleteStarLevel"
      />
    </transition>
  </div>
</template>

<script>
import Alert from "@/layout/components/Alert";

export default {
  name: "Edit",
  props: ["list", "getUserinfo", "i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.list.id,
        name: this.list.name,
        star: this.list.star,
        depositRequired: this.list.depositRequired,
        betRequired: this.list.betRequired,
        withdrawalRequired: this.list.withdrawalRequired,
        withdrawalLimit: this.list.withdrawalLimit,
        dayWithdrawalLimit: this.list.dayWithdrawalLimit,
        promotionBonus: this.list.promotionBonus,
        weekBonus: this.list.weekBonus,
        monthBonus: this.list.monthBonus,
        bounsMultiple: this.list.bounsMultiple,
        description: this.list.description
      },
      name: this.list.name,
      description: this.list.description,
      alertMsg: undefined,
      deleteData: []
    };
  },
  components: {
    Alert
  },
  methods: {
    edit() {
      for (const [key, value] of Object.entries(this.editModel)) {
        this.editModel[key] =
          !this.editModel[key] || this.editModel[key].lengtn === 0
            ? 0
            : value * 1;
      }
      this.editModel.name = this.name;
      this.editModel.description = this.description;
      this.$emit("edit", this.editModel);
    },
    handleDelete() {
      this.alertMsg = this.$t("default.delMsg");
    },
    deleteStarLevel() {
      this.alertMsg = undefined;
      this.$emit("deleteStarLevel", { id: this.list.id });
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 60%;
  .input-block {
    label {
      width: 40%;
    }
    .title {
      width: 90% !important;
      margin: 0 auto !important;
      h4 {
        margin-top: 15px;
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>