export const emptyFormModel = {
    id: undefined,
    name: '',        // 標題
    discountCategory: 6,    
    discountType: 37,       // 優惠類型
    startTime: undefined,   // 開始時間
    endTime: undefined,     // 結束時間
    sort: 99,               // 排序
}

export const ruleSetting = {
    rewardSetting: {
        dates: []
    },
    accumulatedSignInRewardSetting: {
        isEnabled: true,
        days: [
            {
                day: 1,
                rewardType: 0,
                rewardCount: 1,
                wageringRate: 1,
                campaignLuckyDrawListId: 0
            },
        ]
    },
    participateStatus: {
        isNewMember: false,
        includeLevels: [],
        includeAgents: [],
        includeTags: []
    },
    html: '',
    isShowFont: '',
    imageUrl: '',
    mobileImageUrl: '',
    appImageUrl: ''
}