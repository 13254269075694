import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function groupSelectMenu() {
    return request({
        url: "/group/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function groupList() {
    return request({
        url: "/group/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createGroup(data) {
    return request({
        url: "/group/create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function editGroup(data) {
    return request({
        url: "/group/update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}