<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <button
          class="confirm-btn main-style-btn"
          @click="handleDialog(undefined, 'Create')"
          :disabled="
            !getUserinfo.permissions.includes(99999) &&
            !getUserinfo.permissions.includes(70101)
          "
        >
          {{ $t("default.create") }}
        </button>
      </div>
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th style="width: 15%"></th>
                <th>{{ $t(`${i18nPrefix}name`) }}</th>
                <th>{{ $t(`${i18nPrefix}description`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list of tableList" :key="list.id">
                <td>
                  <button
                    class="normal-btn"
                    @click="handleDialog(list, 'Edit')"
                    :disabled="
                      !getUserinfo.permissions.includes(99999) &&
                      !getUserinfo.permissions.includes(70102)
                    "
                  >
                    {{ $t("default.edit") }}
                  </button>
                  <button
                    class="delete-btn"
                    @click="handleDelete(list)"
                    :disabled="
                      !getUserinfo.permissions.includes(99999) &&
                      !getUserinfo.permissions.includes(70103)
                    "
                  >
                    {{ $t("default.delete") }}
                  </button>
                </td>
                <td>{{ list.name }}</td>
                <td>{{ list.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        :getPermissionsTitle="getPermissionsTitle"
        :firstData="firstData"
        :secData="secData"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @create="create"
        @edit="edit"
      ></component>
    </transition>

    <transition name="fade" mode="out-in">
      <Confirm
        v-if="getShowConfirm"
        @toggle="TOGGLE_CONFIRM"
        @delete="deleteFunc"
      />
    </transition>
  </div>
</template>

<script>
import {
  customroleList,
  createCustomrole,
  editCustomrole,
  deleteCustomrole,
} from "@/api/features/custom-role";
import { permissionList } from "@/api/visit-control/custom-role";
import Confirm from "@/layout/components/Confirm";
import { mapGetters, mapMutations } from "vuex";
import Create from "./dialog/create";
import Edit from "./dialog/edit";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import handleDialog from "@/layout/mixins/handleDialog";

export default {
  name: "CustomRole",
  data() {
    return {
      deleteData: undefined,
      permissionsList: [],
      getPermissionsTitle: [],
      firstData: [],
      secData: [],
      i18nPrefix: "visit-control.custom-role."
    };
  },
  components: {
    Confirm,
    Create,
    Edit,
    Pagination,
  },
  mixins: [handlePageUtils, handleDialog],
  computed: {
    ...mapGetters(["getShowConfirm", "getUserinfo"]),
  },
  created() {
    this.getList();
    permissionList().then( res => {
      this.permissionsList = 
       this.getUserinfo.role === 1 ? res.data : res.data.filter(el => this.getUserinfo.permissions.includes(el.id))
       
      this.getPermissionsTitle = this.permissionsList.filter(
        (data) => data.id % 10000 === 0);
      this.firstData = this.getPermissionsTitle.map((data) => this.permissionsList.filter(
        (firstdata) =>
            parseInt(data.id / 10000) ===
            parseInt(firstdata.id / 10000) &&
            (data.id - firstdata.id) % 100 === 0 &&
            firstdata.id % 10000 !== 0
      ));
      this.secData = this.firstData.map((firstdata) => firstdata.map((data) => this.permissionsList.filter(
        (secData) => parseInt(data.id / 100) === parseInt(secData.id / 100) && data.id !== secData.id
      )));
    })
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    getList() {
      customroleList().then((res) => {
        this.allData = res.data.customRoles;
        this.deleteData = undefined;
        this.RESET_CONFIRM();
        this.RESET_DIALOG();
      });
    },
    create(data) {
      createCustomrole(data).then((res) => {
        if(res) this.getList();
      });
    },
    edit(data) {
      editCustomrole(data).then((res) => {
        if(res) this.getList();
      });
    },
    handleDelete(bank) {
      this.TOGGLE_CONFIRM();
      this.deleteData = bank;
    },
    deleteFunc() {
      deleteCustomrole({ id: this.deleteData.id }).then((res) => {
        if(res) this.getList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>