<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
            class="create-btn main-style-btn"
            @click="submit()"
          >
            {{ $t("default.new") }}
          </button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <Excel @UpdateFile="UpdateFile"></Excel>
    </div>
  </div>
</template>

<script>
import {
  batchCreateMember
} from "@/api/features/batchCreateMember";
import Excel from "@/layout/components/Excel.vue";

export default {
  name: "Create",
  data() {
    return {
      formData: new FormData(),
    };
  },
  components: {
    Excel
  },
  mixins: [],
  computed: {
  },
  async created() {
  },
  methods: {
    async submit() {
      await batchCreateMember(this.formData).then(res => {
        if(res) this.formData.delete("File");
      });
    },
    UpdateFile(File) {
      this.formData.delete("File");
      this.formData.append("File", File);
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.remark {
  max-width: 300px;
  white-space: unset !important;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
