var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "bookmark-box" }, [
        _c(
          "div",
          { staticClass: "bookmark-view" },
          _vm._l(_vm.bookmark, function (list) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.getUserinfo.permissions.includes(
                      list.permissions
                    ),
                    expression:
                      "getUserinfo.permissions.includes(list.permissions)",
                  },
                ],
                key: list.id,
                class: { chooice: _vm.chooice == list.id },
                on: {
                  click: function ($event) {
                    _vm.chooice = list.id
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t(list.name)))]
            )
          }),
          0
        ),
      ]),
      _c(_vm.chooice, { tag: "component" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }