var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-view" }, [
        _c("div", { staticClass: "header" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
        ]),
        _c(
          "main",
          [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gateway`))),
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}paymentCompanyInfo`))
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.remark")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.sort")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("default.status")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(99999) &&
                                !_vm.getUserinfo.permissions.includes(61402),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(
                                  { dialogData: list },
                                  "Edit"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("default.edit")))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.name))]),
                      _c("td", [_vm._v(_vm._s(list.nickName))]),
                      _c("td", [_vm._v(_vm._s(list.remark))]),
                      _c("td", [_vm._v(_vm._s(list.sortNumber))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            list.status === 1
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm.totalCount > 0
              ? _c("Pagination", {
                  attrs: { totalCount: _vm.totalCount },
                  on: { handlePage: _vm.handlePage },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { i18nPrefix: _vm.i18nPrefix },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }