import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function announcementList() {
    return request({
        url: "/announcement/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data: {}
    });
}
export function createAnnouncement(data) {
    return request({
        url: "/announcement/create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}
export function editAnnouncement(data) {
    return request({
        url: "/announcement/update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}
export function deleteAnnouncement(data) {
    return request({
        url: "/announcement/delete",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}