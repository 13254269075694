var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-style-v1" }, [
    _c(
      "div",
      { staticClass: "select-style-v1-now", on: { click: _vm.change } },
      [
        _vm._v(" " + _vm._s(_vm.value) + " "),
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/navbar/down.svg") },
        }),
      ]
    ),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showOption,
            expression: "showOption",
          },
        ],
        staticClass: "select-style-v1-option",
      },
      _vm._l(_vm.list, function (item) {
        return _c(
          "li",
          {
            key: item.key,
            staticClass: "select-style-v1-option-item",
            on: {
              click: function ($event) {
                return _vm.select(item)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.value) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }