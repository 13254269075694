<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}dialog.title`) }}</h4>
          <button class="normal-btn float-right" @click="$emit('update', editModel)">
            {{ $t(`${i18nPrefix}dialog.title`) }}
          </button>
        </div>
        <div class="divider"></div>
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}name`) }}
            <input type="text" v-model="editModel.name"/>
          </label>
          <label>
            {{ $t(`${i18nPrefix}fontColor`) }}
            <input class="color" type="color" v-model="editModel.fontColor"/>
          </label>
          <label>
            {{ $t(`${i18nPrefix}backgroundColor`) }}
            <input class="color" type="color" v-model="editModel.backgroundColor"/>
          </label>
          <label>
            {{ $t(`${i18nPrefix}isBoldFont`) }}
            <input type="checkbox" v-model="editModel.isBoldFont"/>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Edit",
  props: ["id","name","fontColor","backgroundColor","isBoldFont","i18nPrefix"],
  data() {
    return {
      editModel: {
        id: this.id,
        name: this.name,
        backgroundColor: this.backgroundColor,
        fontColor: this.fontColor,
        isBoldFont: this.isBoldFont
      },
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 60% !important;
  height: auto !important;
  .input-block {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-gap: 1em;
    .color {
      padding: 0 10px!important;
    }
    label {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      align-items: center!important;
      input {
        width: 80%;
      }
    }
  }
}
</style>