import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getDepositWithdrawData() {
    return request({
        url: "company/get_deposit_withdrawal",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editWinLose(data) {
    return request({
        url: "company/update_winlose",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editAutoSettleAmount(data) {
    return request({
        url: "company/update_auto_settle_amount",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function editDepositMultiple(data) {
    return request({
        url: "company/update_deposit_multiple",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateDepositLimit(data) {
    return request({
        url: "/Company/update_deposit_limit",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function updateWthdrawPassword(data) {
    return request({
        url: "/Company/update_withdraw_password",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}