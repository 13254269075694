<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t(`${i18nPrefix}wallet`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>{{ $t(`${i18nPrefix}dialog.gamePlatform`) }}</th>
                  <th>{{ $t(`${i18nPrefix}dialog.memberAccount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}wallet`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!tableList || !tableList.length">
                  <td colspan="3">{{ $t("default.noData") }}</td>
                </tr>
                <tr v-show="tableList && tableList.length">
                  <td>{{ $t(`${i18nPrefix}dialog.wallet`) }}</td>
                  <td>{{ memberAccount }}</td>
                  <td>{{ new Intl.NumberFormat().format(mainBlance) }}</td>
                </tr>
                <tr v-for="(list, index) of tableList" :key="index">
                  <td>
                    {{
                    GLOBAL.i18nCheckName(getGamePlatforms.find(
                    (data) => data.id === list.gamePlatformId
                    ))
                    }}
                  </td>
                  <td>{{ list.customGameAccount }}</td>
                  <td>{{ list.isMaintain ? 'X' :new Intl.NumberFormat().format(list.balance) }}</td>
                </tr>
                <tr v-show="tableList.length">
                  <td></td>
                  <td>{{ $t("default.total") }}</td>
                  <td>{{ new Intl.NumberFormat().format(totalGameBalance + mainBlance ) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { memberGameWalletList } from "@/api/member/member";

export default {
  name: "Wallet",
  props: ["memberId", "memberAccount", "getGamePlatforms", "i18nPrefix"],
  data() {
    return {
      mainBlance: 0,
      totalGameBalance: 0,
      tableList: []
    };
  },
  async created() {
    await this.GetMemberGameWalletList();
  },
  methods: {
    async GetMemberGameWalletList() {
      await memberGameWalletList({ MemberId: this.memberId }).then(res => {
        this.mainBlance = res.data.mainBlance;
        this.totalGameBalance = res.data.totalGameBalance;
        this.tableList = res.data.platformBalance;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: auto !important;
}
</style>