<template>
  <div id="app" @click="clearDropDown">
    <audio ref="deposit" src="./assets/deposit.mp3"></audio>
    <audio ref="withdraw" src="./assets/withdraw.mp3"></audio>
    <audio ref="discount" src="./assets/discount.mp3"></audio>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { privateMessage, memberCount } from "@/api/user";
import { mapGetters, mapMutations } from "vuex";
import { getServerLang } from "@/api/user";

export default {
  name: "App",
  watch: {
    $route() {
      document.body.style.overflow = "";
      this.$store.commit("dialog/RESET_DIALOG");
      this.$store.commit("confirm/RESET_CONFIRM");
      this.$store.commit("user/CLOSE_USERDROPDOWN");
    },
    isIdle(val) {
      if (val) {
        // alert("isIdle:"+this.token)
        this.$store.dispatch("user/logout");
      }
    }
  },
  computed: {
    ...mapGetters([
      "getMailLastId",
      "getUserinfo",
      "token",
      "lang",
      "getUserDropDown",
      "getNotification"
    ]),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    drop: {
      get() {
        return this.getUserDropDown;
      },
      set(value) {
        this.SET_USERDROPDOWN(value);
      }
    },
    notification: {
      get() {
        return this.getNotification;
      },
      set(value) {
        this.SET_NOTIFICATION(value);
      }
    }
  },
  async created() {
    window.addEventListener("storage", e => {
      if (e.key == "token") {
        location.reload();
      }
    });
    getServerLang(this.lang).then(res => {
      this.$i18n.setLocaleMessage(
        "localeLang",
        Object.assign([], this.GLOBAL.langData[this.lang], res)
      );
    });
    // setInterval(() => {
    //   if (this.$route.name !== "Login" && this.token) {
    //     memberCount().then((res) => {
    //       this.SET_MEMBERCOUNT(res.data?.count);
    //     });
    //   }
    // }, 10 * 60 * 1000);

    // this.$unleash.on('update', () => {
    //   if (this.$unleash.isEnabled("dashboard_test")) {
    //     this.$store.commit("unleash/SET_FEATURES", "dashboard_test");
    //     console.log('dashboard_test', true);
    //   } else {
    //     this.$store.commit("unleash/DELETE_FEATURES", "dashboard_test");
    //     console.log('dashboard_test', false);
    //   }
    // });
  },
  mounted() {
    setInterval(() => {
      if (
        this.$route.name !== "Login" &&
        this.token &&
        this.getUserinfo.permissions.includes(80000)
      ) {
        privateMessage().then(res => {
          let mailData = res.data.sort((a, b) => b.id - a.id);
          let lastId = mailData[0]?.id ?? 0;
          if (lastId > this.getMailLastId) {
            switch (mailData[0]?.permissionId) {
              case 10400:
              case 10106:
              case 20501:
                this.$refs.withdraw.play();
                break;
              case 10500:
                this.$refs.discount.play();
                break;
              default:
                this.$refs.deposit.play();
                break;
            }
          }
          if (lastId) this.SET_MAIL_LAST_ID(lastId);
          res.data?.length > 0 ? this.SET_MAIL(true) : this.SET_MAIL(false);
          this.SET_MAIL_DATA(mailData);
        });
      }
    }, 5 * 1000);
  },
  methods: {
    ...mapMutations({
      SET_MAIL: "user/SET_MAIL",
      SET_MAIL_LAST_ID: "user/SET_MAIL_LAST_ID",
      SET_MAIL_DATA: "user/SET_MAIL_DATA",
      SET_MEMBERCOUNT: "user/SET_MEMBERCOUNT",
      SET_USERDROPDOWN: "user/SET_USERDROPDOWN",
      SET_NOTIFICATION: "user/SET_NOTIFICATION"
    }),
    clearDropDown() {
      this.drop = false;
      this.notification = false;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "~@/styles/style.scss";
</style>
 