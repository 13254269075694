<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button class="download-btn main-style-btn" @click="download">
            <img class="icon" src="@/assets/download.svg" />
            {{ $t('default.download') }}
          </button>
        </div>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            <div class="input-block-check">
              <span style="margin-right: 10px;">{{ $t("default.member") }}</span>
              <input id="isFuzzySearch" type="checkbox" v-model="searchForm.isFuzzySearch" />
              <label for="isFuzzySearch">{{ $t("default.isFuzzySearch") }}</label>
            </div>
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}gateway`) }}
            <select v-model="searchForm.gateway">
              <option value="0">{{ $t("default.all") }}</option>
              <option v-for="(list, key) in getCompanyDepositSettingsList" :key="key" :value="key">{{
        $t("DepositTypeList." + key) }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}paymentName`) }}
            <select v-model="searchForm.paymentName">
              <option value>{{ $t("default.all") }}</option>
              <option v-for="payment of getPaymentList" :key="payment.name" :value="payment.name">{{ payment.name }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.agent") }}
            <select v-model="searchForm.agentId">
              <option v-for="list of getAgentListAddDefault" :key="list.id" :value="list.id">{{ $t(list.account) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.startCreateTime") }}
            <date-picker v-model="createStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.endCreateTime") }}
            <date-picker v-model="createEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeFrom") }}
            <date-picker v-model="auditStartTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.auditTimeTo") }}
            <date-picker v-model="auditEndTime" format="YYYY-MM-DD HH:mm" value-type="format"
              type="datetime"></date-picker>
          </label>
          <label>
            {{ $t("default.tag") }}
            <select v-model="searchForm.tagId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of tagList" :key="list.id" :value="list.id * 1">{{ list.name }}</option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}orderNumber`) }}
            <input type="text" v-model="searchForm.orderNumber" />
          </label>
        </div>
        <!-- <div class="condition-block">
          <multiselect v-model="paymentStatuses" label="name" track-by="id" :placeholder="$t('default.plzSelect')"
            :custom-label="GLOBAL.i18nCheckName" :options="getPaymentStatusList" :multiple="true" :searchable="false">
          </multiselect>
          <div class="button-block">
            <button @click="paymentStatuses = [...getPaymentStatusList]">{{ $t("default.selectAll") }}</button>
            <button @click="paymentStatuses = []">{{ $t("default.clear") }}</button>
          </div>
        </div> -->
        <div class="divider-h-2"></div>
        <MultiSelectBox :selectData.sync="paymentStatuses" :list="getPaymentStatusList" :multiple="true" />
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="total-block-v2 row-4">
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalDataCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalMemberCount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalMemberCount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalAmount) }}</div>
        </div>
        <div class="total-block-v2-item">
          <div class="total-block-v2-item-left">{{ $t("default.totalReceiveAmount") }}:</div>
          <div class="total-block-v2-item-right">{{ digital.format(total.totalReceiveAmount) }}</div>
        </div>
      </div>
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.status") }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t(`${i18nPrefix}takeNumberTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}paymentName`) }}</th>
              <th>{{ $t(`${i18nPrefix}gateway`) }}</th>
              <th>{{ $t(`${i18nPrefix}orderNumber`) }}</th>
              <th>{{ $t(`${i18nPrefix}infoContent`) }}</th>
              <th>{{ $t("default.member") }}</th>
              <th>{{ $t("default.tag") }}</th>
              <th>{{ $t("default.amount") }}</th>
              <th>{{ $t("default.exchangeRate") }}</th>
              <th>{{ $t("default.receiveAmount") }}</th>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t("default.createTime") }}</th>
              <th>{{ $t(`${i18nPrefix}auditTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!allData || !allData.length">
              <td colspan="16">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="(list, index) of allData" :key="index">
              <td>{{ $t(GLOBAL.i18nCheckName(getPaymentStatusList.find((data) => data.id === list.paymentStatus))) }}
              </td>
              <td>{{ list.remark }}</td>
              <td>{{ list.takeNumberTime }}</td>
              <td>{{ list.paymentName }}</td>
              <td>{{ $t("DepositTypeList." + list.gateway) }}</td>
              <td>{{ list.orderNumber }}</td>
              <td>{{ list.infoContent }}</td>
              <td>{{ list.memberAccount }}</td>
              <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
              </td>
              <td>{{ list.amount }}</td>
              <td>{{ list.exchangeRate }}</td>
              <td>{{ list.receiveAmount }}</td>
              <td>{{ list.auditAccount }}</td>
              <td>{{ list.createTime }}</td>
              <td>{{ list.auditTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <serverPagination v-if="allData" :page="page" @ServerPageUtils="search" @changeTake="changeTake" />
    </div>
  </div>
</template>

<script>
import {
  memberDepositCryptoPaymentReportList,
  getDepositCryptoPaymentReportExcel,
  getDepositCryptoPaymentReportTotal
} from "@/api/report/member-deposit-payment-report";
import { searchModel } from "./model";
import { mapGetters } from "vuex";
import Tags from "@/layout/components/tags";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import { transToday } from "@/utils/transDateUtils";
import QuickSearch from "@/layout/components/QuickSearch";
import MultiSelectBox from "@/layout/components/MultiSelectBox";
import { joinT } from "@/utils/transDateUtils";
import Multiselect from "vue-multiselect";
import { download } from "@/utils/download";

export default {
  name: "MemberDepositCryptoPaymentReport",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      createStartTime: transToday("from"),
      createEndTime: transToday("to"),
      auditStartTime: "",
      auditEndTime: "",
      paymentStatuses: [],
      total: {
        totalCount: 0,
        totalMemberCount: 0,
        totalAmount: 0,
        totalReceiveAmount: 0
      },
      i18nPrefix: "report.member-deposit-payment-report."
    };
  },
  components: {
    Tags,
    serverPagination,
    QuickSearch,
    Multiselect,
    MultiSelectBox
  },
  mixins: [handleServerPageUtils, handleDialog],
  computed: {
    ...mapGetters([
      "getPaymentList",
      "getPaymentStatusList",
      "getAgentListAddDefault",
      "tagList",
      "getCompanyDepositSettingsList"
    ])
  },
  created() {
    // this.paymentStatuses = [
    //   this.getPaymentStatusList[3],
    //   this.getPaymentStatusList[5]
    // ];
    this.search();
  },
  methods: {
    search(page = false) {
      this.searchForm.createStartTime = this.createStartTime
        ? joinT(
          this.createStartTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.createEndTime = this.createEndTime
        ? joinT(
          this.createEndTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone
        )
        : undefined;
      this.searchForm.auditStartTime = this.auditStartTime
        ? joinT(
          this.auditStartTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined;

      this.searchForm.auditEndTime = this.auditEndTime
        ? joinT(
          this.auditEndTime +
          this.GLOBAL.startSecond +
          this.GLOBAL.timeZone
        )
        : undefined

      this.searchForm.paymentStatuses = this.paymentStatuses.map(
        data => data.id * 1
      );

      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? page - 1 : 0);
      memberDepositCryptoPaymentReportList(this.searchForm).then(res => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
      getDepositCryptoPaymentReportTotal(this.searchForm).then(
        res => (this.total = res.data)
      );
    },
    changeTime(time) {
      this.createStartTime = time.startTime;
      this.createEndTime = time.endTime;
      this.search();
    },
    download() {
      getDepositCryptoPaymentReportExcel(this.searchForm).then(res =>
        download(res, this.createStartTime + "MemberDepositPaymentReport.xlsx")
      );
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped></style>