<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t(`${i18nPrefix}dialog.memberData`) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <div class="member-info">
              <label>
                {{ $t(`${i18nPrefix}loginType`) }}
                <select v-model="editModel.loginType" :disabled="loginType==1" >
                  <option
                    v-for="list of selectLoginTypeList"
                    :key="list.id"
                    :value="list.id"
                  >
                    {{ list.name }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}account`) }}
                <input type="text" v-model="account" :disabled="true" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}name`) }}
                <input type="text" v-model="editModel.name" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.password`) }}
                <input type="text" :class="changeLoginTypePassword ? 'input-red' : ''" v-model="editModel.password" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}recommend`) }}
                <input type="text" v-model="editModel.referralCode" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}line`) }}
                <input type="text" v-model="editModel.socialId" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.withdrawalPassword`) }}
                <input type="text" :class="changeLoginTypeWithdrawalPassword ? 'input-red' : ''" v-model="editModel.withdrawalPassword" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}phone`) }}
                <input type="text" :class="changeLoginTypePhoneNumber ? 'input-red' : ''" v-model="editModel.phoneNumber" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}group`) }}
                <select v-model="editModel.bankGroupId">
                  <option
                    v-for="list of getBankGroupList"
                    :key="list.id"
                    :value="list.id"
                  >
                    {{ list.name }}
                  </option>
                </select>
              </label>
              <label>
                {{ $t(`${i18nPrefix}dialog.levelNumber`) }}
                <input type="text" v-model="editModel.levelNumber" />
              </label>
              <label>
                {{ $t(`${i18nPrefix}mail`) }}
                <input type="text" v-model="editModel.email"/>
              </label>
            <div class="divider-v2-area" v-show="paymentNumbers.length">
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}dialog.paymentNumbers`) }}</div>
              <div class="paymentNumbers" v-for="(phoneNumber,index)  of paymentNumbers" :key="index">
                <span>
                  {{ phoneNumber }}
                </span>
              </div>
            </div>
            </div>
          </div>
          <div class="divider"></div>
          <h4>{{ $t(`${i18nPrefix}dialog.riskControl`) }}</h4>
          <div class="risk-block">
            <label>
              {{ $t(`${i18nPrefix}dialog.winLoseDaily`) }}
              <input type="text" v-model="editModel.winLoseDaily" disabled />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.maxWinLoseDaily`) }}
              <input type="text" v-model="editModel.maxWinLoseDaily" :disabled="!getUserinfo.permissions.includes(10111)"/>
            </label>
          </div>
          <div class="advance-block">
            <button 
              class="confirm-btn main-style-btn"
              @click="$emit('edit', editModel)"
              :disabled="changeLoginTypePassword || changeLoginTypeWithdrawalPassword || changeLoginTypePhoneNumber"
            >
              {{ $t("default.confirm")}}
            </button>
            <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel")}}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { winLoseToday } from "@/api/member/member";
import { toggleStyle } from "@/utils/slideUtils";
import { mapGetters } from "vuex";

export default {
  name: "Edit",
  props: [
    "id",
    "account",
    "memberName",
    "bankGroupId",
    "referralCode",
    "maxWinLoseDaily",
    "levelNumber",
    "phoneNumber",
    "paymentNumbers",
    "socialId",
    "email",
    "i18nPrefix",
    "loginType"
  ],
  data() {
    return {
      editModel: {
        id: this.id,
        name: this.memberName,
        bankGroupId: this.bankGroupId,
        referralCode: this.referralCode,
        winLoseDaily: undefined,
        maxWinLoseDaily: this.maxWinLoseDaily,
        levelNumber: this.levelNumber,
        password: "",
        withdrawalPassword: "",
        phoneNumber: this.phoneNumber,
        socialId: this.socialId,
        email: this.email,
        loginType: this.loginType
      },
    };
  },
  created() {
    winLoseToday({ id: this.editModel.id }).then( res => this.editModel.winLoseDaily = res.data.winLose)
  },
  computed: {
    ...mapGetters(["getBankGroupList","getUserinfo","getLoginType"]),
    selectLoginTypeList() {
      return this.getLoginType.filter(x=> x.id == this.loginType || x.id == 1);
    },
    // 更改帳號類型強制填寫密碼
    changeLoginTypePassword() {
      if (this.loginType != 1 && this.editModel.loginType == 1 && !this.editModel.password) {
        return true;
      }

      return false;
    },
    // 更改帳號類型強制填寫提款密碼
    changeLoginTypeWithdrawalPassword() {
      if (this.loginType != 1 && this.editModel.loginType == 1 && !this.editModel.withdrawalPassword) {
        return true;
      }

      return false;
    },
    // 更改帳號類型強制填寫手機
    changeLoginTypePhoneNumber() {
      if (this.loginType != 1 && this.editModel.loginType == 1 && !this.editModel.phoneNumber) {
        return true;
      }

      return false;
    }
  },
  methods: {
    toggleSlide: toggleStyle,
  },
};
</script>
<style lang="scss" scoped>
main {
  .input-block {
    .member-info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      label {
        width: 30%;
      }
      .divider-v2-area {
        display: grid;
        grid-template-columns: repeat(4, 23%);
        grid-gap: 1em;
        width: 100%;
        padding: 20px;
        .paymentNumbers {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-radius: 10px;
          border: 1px solid #999;
          margin: 2px 17px;
          span {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  .risk-block {
    display: flex;
    justify-content: space-around;
    label {
      display: flex;
      flex-direction: column;
      width: 40%;
    }
  }
  .edit-member-bank-block {
    label {
      width: 40%;
      &:last-child div {
        display: flex;
        flex-direction: column;
        align-items: center;
        .slide-on {
          transform: translateX(-20px);
        }
        .slide-off {
          transform: translateX(20px);
        }
      }
    }
  }
}
.dialog-mask {
  .dialog-container {
    position: fixed;
    max-height: 100vh;
    overflow: auto;
    width: 50%;
  }
}
.input-red {
  border-color: #f00 !important;
}

@media screen and (max-width: 960px) {
  .input-block {
    label {
      width: 30% !important;
    }
    .more-info {
      width: 100% !important;
    }
  }
  .advance-block {
    align-items: center;
    label {
      margin: 5px auto;
    }
    button {
      margin: 5px auto;
    }
  }
  .divider-v2-area {
    grid-template-columns: repeat(3, 30%) !important;
    padding: 12px !important;
    .divider-v2-left {
      left: 8px !important;
    }
    .paymentNumbers {
      margin: 1px 0px !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .member-info {
    flex-direction: column;
    width: 90%;
    label {
      width: 100% !important;
    }
  }
  .risk-block {
    flex-direction: column;
    width: 100%;
    label {
      width: 100% !important;
    }
  }
}
</style>