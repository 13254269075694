import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function SelectMenu() {
    return request({
        url: "/GamesCategoryInfo/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getGamesCategoryInfoList(data) {
    return request({
        url: "/GamesCategoryInfo/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function createGamesCategoryInfo(data) {
    return request({
        url: "/GamesCategoryInfo/Create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteGamesCategoryInfo(data) {
    return request({
        url: "/GamesCategoryInfo/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateGamesCategoryInfo(data) {
    return request({
        url: "/GamesCategoryInfo/Update",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}
