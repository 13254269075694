<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
        <div class="float-right">
          <button
              class="normal-btn"
              style="margin: auto"
              @click="handleCalc()"
              :disabled="!getUserinfo.permissions.includes(20401)"
          >
            {{ $t(`${i18nPrefix}handleCalc`) }}
          </button>
        </div>
      </div>
      <div class="divider"></div>
      <main>
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}timeFrom`) }}
            <input type="date" step="1" v-model="startTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}timeTo`) }}
            <input type="date" step="1" v-model="endTime" />
          </label>
          <label>
            {{ $t("default.remark") }}
            <textarea v-model="upRecord.remark"></textarea>
          </label>
        </div>
        <main>
          <div class="table-block">
            <table class="mt-3">
              <thead>
                <tr>
                  <th>{{ $t(`${i18nPrefix}id`) }}</th>
                  <th>{{ $t(`${i18nPrefix}timeFrom`) }}</th>
                  <th>{{ $t(`${i18nPrefix}timeTo`) }}</th>
                  <th>{{ $t(`${i18nPrefix}approveCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}untreatedCount`) }}</th>
                  <th>{{ $t(`${i18nPrefix}refuseCount`) }}</th>
                  <th>{{ $t("default.remark") }}</th>
                  <th>{{ $t("default.status") }}</th>
                  <th>{{ $t("default.admin") }}</th>
                  <th>{{ $t(`${i18nPrefix}approveTime`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) of allData" :key="index">
                  <td>
                    <button
                      class="normal-btn mr-3"
                      @click="handleDialog(list, 'UpRecordEdit')"
                      :disabled="!getUserinfo.permissions.includes(20402)"
                    >
                      {{ list.id }}
                    </button>
                  </td>
                  <td>{{ list.timeFrom }}</td>
                  <td>{{ list.timeTo }}</td>
                  <td>{{ list.approveCount }}</td>
                  <td>{{ list.untreatedCount }}</td>
                  <td>{{ list.refuseCount }}</td>
                  <td>{{ list.remark }}</td>
                  <td>
                    {{ GLOBAL.i18nCheckName(rebateCalcStatus.find( data => data.id === list.status)) }}
                  </td>
                  <td>{{ list.approveAccount }}</td>
                  <td>{{ list.approveTime }}</td>
                </tr>
              </tbody>
            </table>
            <serverPagination
              v-if="allData"
              :page="page"
              @ServerPageUtils="getList"
              @changeTake="changeTake"
            ></serverPagination>
          </div>
        </main>
      </main>
    </div>
    <!-- 確認彈出視窗 -->
    <transition name="fade" mode="out-in">
      <Alert
        v-if="alertMsg"
        :message="alertMsg"
        @toggle="alertMsg = undefined"
        @doIt="upRecordCalc"
      />
    </transition>
    <transition name="fade" mode="out-in">
      <component
        :is="currDialog"
        :i18nPrefix="i18nPrefix"
        v-bind="dialogData"
        @toggle="currDialog = undefined"
        @audit="audit"
      ></component>
    </transition>
  </div>
</template>

<script>
import { getList , Calculate , auditRebate } from "@/api/agent-management/agent-rebateCalc";
import { searchModel } from "./model";
import UpRecordEdit from "./dialog/upRecordEdit.vue";
import serverPagination from "@/layout/components/serverPagination";
import handleServerPageUtils from "@/layout/mixins/handleServerPageUtils";
import handleDialog from "@/layout/mixins/handleDialog";
import Message from "@/layout/components/Message";
import Alert from "@/layout/components/Alert";
import { transYestetday } from "@/utils/transDateUtils";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AgentRebateCalc",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transYestetday(),
      endTime: transYestetday(),
      starLevelList: undefined,
      upRecord: {
        timeFrom: undefined,
        timeTo: undefined,
        remark: "",
      },
      alertMsg: undefined,
      rebateCalcStatus: [
        {
          id: 1,
          name: "rebateCalcStatus.pending",
        },
        {
          id: 2,
          name: "rebateCalcStatus.success",
        },
        {
          id: 3,
          name: "rebateCalcStatus.failed",
        },
        {
          id: 4,
          name:  "rebateCalcStatus.delete",
        },
      ],
      i18nPrefix: "agent.agent-rebateCalc.",
    };
  },
  components: {
    UpRecordEdit,
    Alert,
    serverPagination,
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
  mixins: [handleServerPageUtils, handleDialog],
  created() {
    this.getList();
  },
  methods: {
    ...mapMutations({
      TOGGLE_CONFIRM: "confirm/TOGGLE_CONFIRM",
      RESET_CONFIRM: "confirm/RESET_CONFIRM",
    }),
    getList(page = false) {
      this.closeDialog()
      this.searchForm.page.take = this.take * 1;
      this.searchForm.page.skip = this.take * (page ? (page - 1) : 0);
      getList(this.searchForm).then((res) => {
        this.allData = res.data.list;
        this.page = res.data.page;
      });
    },
    audit(data) {
      auditRebate(data).then((res) => {
        if(res) this.getList();
      });
    },
    handleCalc() {
      this.alertMsg = this.$t(`${this.i18nPrefix}alertMsg`);
    },
    upRecordCalc() {
      this.alertMsg = undefined;
      if (
        this.startTime === undefined ||
        this.endTime === undefined 
      ) {
        Message.error(this.$t(`${this.i18nPrefix}alertMsg2`), 1000);
        return
      }
      this.upRecord.timeFrom = this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone;
      this.upRecord.timeTo = this.endTime + this.GLOBAL.endHour+ this.GLOBAL.timeZone;
      Calculate(this.upRecord).then((res) => {
        if(res) this.getList();
      });
      this.upRecord.remark = "";
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  label {
    justify-content: flex-start;
    width: 20%;
  }
}
@media screen and (max-width: 1280px) {
  .input-block {
    label {
      width: 45% !important;
      margin: 5px auto;
    }
    .deleteBox {
      width: 10%;
    }
  }
}
</style>