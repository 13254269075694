import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function appVersionSettingSelectMenu() {
    return request({
        url: "/AppVersionSetting/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getAppVersionSettingList(data) {
    return request({
        url: "/AppVersionSetting/list",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function createAppVersionSetting(data) {
    return request({
        url: "/AppVersionSetting/create",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}

export function editAppVersionSetting(data) {
    return request({
        url: "/AppVersionSetting/update",
        method: "post",
        headers: { "Authorization": `token ${getToken()}` },
        data
    });
}