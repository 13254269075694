export let searchModel = {
    memberAccount: "",          // 會員帳號
    gamesCategory: 0,           // 遊戲種類
    gamePlatform: undefined,
    agentId: 0,
    startTime: undefined,
    endTime: undefined,
    type: 1,
    page:  {
        "take": 10,
        "skip": 0
    }
}