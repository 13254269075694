import { mapGetters, mapMutations } from "vuex";

export default {
    data() {
        return {
            dialogData: undefined,
        }
    },
    watch: {
        currDialog(value) {
            document.body.style.overflow = value ? "hidden" : "";
        },
    },
    computed: {
        ...mapGetters(["getCurrDialog"]),
        currDialog: {
            get() {
                return this.getCurrDialog;
            },
            set(value) {
                this.SET_CURRDIALOG(value);
            },
        },
    },
    methods: {
        ...mapMutations({
            SET_SHOWCOUNT: "app/SET_SHOWCOUNT",
            SET_CURRDIALOG: "dialog/SET_CURRDIALOG",
            RESET_DIALOG: "dialog/RESET_DIALOG",
        }),
        handleDialog(data, componentName) {
            this.currDialog = componentName;
            this.dialogData = data;
        },
        closeDialog() {
            this.SET_SHOWCOUNT(10);
            this.dialogData = undefined;
            this.RESET_DIALOG();
        },
    }
}