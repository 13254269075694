<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container">
      <div class="dialog-block">
        <div class="header tree-header">
          <h3>{{ mainAccount }}</h3>
          <div class="header-container">
            <select v-model="rebateId">
              <option
                v-for="item of rebateList"
                :key="item.id"
                :value="item.id"
              >{{ item.createTime }}</option>
            </select>
            <div class="totle">
              {{ $t(`${i18nPrefix}tree.totalAmount`) }}:
              <div class="tag">{{ totalAmount }}</div>
            </div>
          </div>
        </div>
        <main>
          <div class="table-block">
            <TreeTable ref="treeTable" :list="list" :column="treeColumn" :showFold="true">
              <template v-slot:column_agentAccount="props">
                <div
                  class="agebt-tag"
                >{{ props.levelNumber === 0 ? $t('default.mainAgent') : $t('default.levelNumber', {input: props.levelNumber}) }}</div>
                <a
                  :href="`#/historyReport?agentId=${props.agentId}&agentRebateGeneralId=${rebateId}&lang=${lang}`"
                  target="_blank"
                >{{ props.agentAccount }}</a>
              </template>
            </TreeTable>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/layout/components/Alert";
import TreeTable from "@/layout/components/TreeTable";
import {
  getTree,
  getRebateTime
} from "@/api/agent-management/agent-rebateCalcV2";

export default {
  name: "TreeHistory",
  props: ["lang", "agentId", "mainAccount", "i18nPrefix"],
  data() {
    return {
      treeColumn: [
        {
          key: "agentAccount",
          name: this.$t("default.agent"),
          width: 600,
          align: "left"
        },
        {
          key: "amount",
          name: this.$t(`${this.i18nPrefix}tree.amount`)
        },
        {
          key: "adjustmentAmount",
          name: this.$t(`${this.i18nPrefix}tree.reviseAmount`)
        },
        {
          key: "payoutAmount",
          name: this.$t(`${this.i18nPrefix}tree.remitAmount`)
        }
      ],
      list: [],
      rebateList: [],
      rebateId: undefined,
      totalAmount: 0
    };
  },
  components: {
    Alert,
    TreeTable
  },
  computed: {},
  watch: {
    rebateId: {
      handler(e) {
        this.getList();
      }
    }
  },
  created() {
    this.getRebateList();
  },
  methods: {
    async getRebateList() {
      await getRebateTime({ agentId: this.agentId }).then(res => {
        this.rebateList = res.data;
      });

      if (this.rebateList.length > 0) {
        this.rebateId = this.rebateList[0].id;
      }
    },
    async getList() {
      this.list = [];
      await getTree({
        agentId: this.agentId,
        agentRebateGeneralId: this.rebateId
      }).then(res => {
        this.list.push(res.data.tree);
        this.totalAmount = res.data.totalAmount;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 60%;
  .input-block {
    label {
      width: 40%;
    }
  }
}
.opacity-5 {
  opacity: 0.5;
}
.agebt-tag {
  border: 1px solid #c27cf6;
  border-radius: 3px;
  background: #faefff;
  display: inline-block;
  padding: 1px 3px;
  color: #7634c1;
  margin-right: 3px;
}
.tree-header {
  align-items: flex-start;
  flex-direction: column;
}
.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .totle {
    display: flex;
    align-items: center;
    .tag {
      border: 1px solid #52058b;
      border-radius: 3px;
      background: #52058b;
      color: #fff;
      margin-left: 5px;
      padding: 1px 6px;
    }
  }
}
</style>