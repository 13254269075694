<template>
  <ul>
    <template v-for="list of treeData">
      <li
        :key="list.id"
        @click="$emit('passAgentId', list)"
        :class="{ active: list.id === agentId }"
      >
        <i class="fa fa-user"></i>
        <span>
          {{ list.name }}
        </span>
      </li>
      <template v-if="list.subAgents">
        <TreeNode
          :key="list.subAgents.id"
          :treeData="list.subAgents"
          :agentId="agentId"
          @passAgentId="passAgentId"
        />
      </template>
    </template>
  </ul>
</template>

<script>
import TreeNode from "@/layout/components/TreeNode";

export default {
  name: "TreeNode",
  props: ["treeData", "agentId"],
  components: {
    TreeNode,
  },
  watch: {
    agentId() {},
  },
  methods: {
    passAgentId(data) {
      this.$emit("passAgentId", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.fa {
  margin-right: 5px;
}
ul {
  margin: 10px 0 10px 20px;
  li {
    position: relative;
    cursor: pointer;
    transition: 0.25s;
    padding: 10px;
    border-radius: 5px;
    span {
      transition: 0.25s;
    }
    &:hover {
      background-color: #e4e4e4;
    }
    &:hover span {
      padding-left: 10px;
    }
  }
}
.active {
  background-color: #e4e4e4;
  span {
    padding-left: 10px;
  }
}
</style>