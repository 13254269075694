<template>
  <div class="dialog-mask" @mousedown.self="$emit('toggle')">
    <div class="dialog-container edit-member">
      <div class="dialog-block">
        <div class="header">
          <h4>{{ $t("default.edit") + " " + $t("title." + $route.meta.title) }}</h4>
        </div>
        <div class="divider"></div>
        <main>
          <div class="input-block">
            <label>
              {{ $t("default.member") }}
              <input type="text" disabled v-model="data.memberAccount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.calculationRate`) + "(%)" }}
              <input type="text" disabled v-model="data.calculationRate" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}dialog.levelBonusRate`) + "(%)" }}
              <input type="text" disabled v-model="data.levelBonusRate" />
            </label>
            <label>
              {{ $t("default.amount") }}
              <input type="text" disabled v-model="data.bonusAmount" />
            </label>
            <label>
              {{ $t(`${i18nPrefix}systemRecoveryAmount`) }}
              <input type="text" disabled v-model="data.systemRecoveryAmount" />
            </label>
            <label class="remark">
              {{ $t("default.remark") }}
              <textarea v-model="auditModel.remark"></textarea>
            </label>
          </div>
          <div class="advance-block">
            <button class="normal-btn" @click="edit(2)">{{ $t("default.accept") }}</button>
            <button class="delete-btn" @click="edit(5)">{{ $t("default.reject") }}</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Audit",
  props: [
    "data",
    "i18nPrefix"
  ],
  data() {
    return {
      auditModel: {
        id: this.data.id,
        auditStatus: this.data.discountAuditStatus,
        remark: ""
      },
    };
  },
  methods: {
    edit(auditStatus) {
      this.auditModel.auditStatus = auditStatus
      this.$emit("audit", this.auditModel);
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 70%;

  .input-block {
    label {
      width: 40%;
    }

    .remark {
      width: 97%;
    }
  }

  .member {
    width: 97%;
  }
}
</style>