<template>
  <div class="dialog-mask2">
    <div ref="dialog" class="dialog-container">
      <div class="dialog-header">
        <h4>{{ $t(`default.${editData !== undefined ? 'edit' : 'create'}`) + " " + $t("title." + $route.meta.title) }}
        </h4>
        <div class="dialog-close" @click="$emit('toggle')">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="dialog-block">
        <main>
          <div class="input-block">
            <div class="divider-v2">
              <!-- 基本設定 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}editV2.basicSettings`) }}</div>
            </div>
            <div class="form-box">
              <div class="form-box-half">
                <!-- 標題 -->
                <label>
                  {{ $t(`${i18nPrefix}name`) }}
                  <input type="text" :placeholder="$t(`${i18nPrefix}name`)" v-model="formModel.name" />
                  <span ref="rulesname" class="form-rules"></span>
                </label>
                <!-- 類別 -->
                <label>
                  {{ $t(`${i18nPrefix}discountType`) }}
                  <select v-model="formModel.discountType" :disabled="editData !== undefined">
                    <option v-for="item of discountTypeList" :key="item.id" :value="item.value">{{ $t(item.name) }}
                    </option>
                  </select>
                  <span ref="rulesdiscountType" class="form-rules"></span>
                </label>
              </div>
              <div class="form-box-half">
                <!-- 備註 -->
                <label>
                  {{ $t('default.remark') }}
                  <textarea v-model="formModel.remark" rows="3" style="height: auto;"></textarea>
                </label>
              </div>
            </div>
            <div class="divider-v2">
              <!-- 啟動設定 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}editV2.startupSettings`) }}</div>
            </div>
            <div class="form-box" style="width:100%;">
              <label>
                {{ $t(`default.status`) }}
                <select v-model="formModel.discountStatus">
                  <option v-for="item of discountStatus" :key="item.key" :value="item.key">{{ $t(item.name) }}</option>
                </select>
                <span ref="rulesdiscountStatus" class="form-rules"></span>
              </label>
              <!-- startTime -->
              <label>
                <span v-show="formModel.discountStatus === 3">{{ $t(`${i18nPrefix}startTime`) }}</span>
                <date-picker v-show="formModel.discountStatus === 3" v-model="startTime" format="YYYY-MM-DD HH:mm"
                  value-type="format" type="datetime"></date-picker>
                <span ref="rulesstartTime" class="form-rules"></span>
              </label>
              <!-- endTime -->
              <label>
                <span v-show="formModel.discountStatus === 3">{{ $t(`${i18nPrefix}endTime`) }}</span>
                <date-picker v-show="formModel.discountStatus === 3" v-model="endTime" format="YYYY-MM-DD HH:mm"
                  value-type="format" type="datetime"></date-picker>
                <span ref="rulesendTime" class="form-rules"></span>
              </label>
            </div>
            <component :is="discountTypeList.find(e=> e.id === selectDiscountType).component" :i18nPrefix="i18nPrefix"
              :selectDiscountType="selectDiscountType" :discountRule="discountRule" :formModel="formModel"
              :applyType="applyType" :dispatchType="dispatchType" :memberTag="memberTag" :memberDiscount="memberDiscount"
              :memberStar="memberStar" :editModel="editModel" :editModelTmp="editModelTmp" :imageUrlShow="imageUrlShow"
              :rulesError="rulesError" :gamesCategorys="getGamesCategorys" :rebateData="rebateData" @formulaDisabled="changeFormulaDisabled" />
            <div class="divider-v2">
              <!-- 前台 -->
              <div class="divider-v2-text divider-v2-left">{{ $t(`${i18nPrefix}editV2.front`) }}</div>
            </div>
            <div class="form-box">
              <div class="form-box-half form-column">
                <div class="form-box">
                  <!-- 前台顯示 -->
                  <label class="label-row" v-if="discountRule[selectDiscountType].isShowFont == undefined">
                    <input type="checkbox" v-model="formModel.isShowFont" />
                    {{ $t(`${i18nPrefix}dialog.showInFront`) }}
                  </label>
                  <!-- 排序 -->
                  <label>
                    {{ $t(`default.sort`) }}
                    <input type="number" :placeholder="$t(`default.sort`)" v-model="formModel.sort" />
                  </label>
                </div>
                <label class="fileName">
                  {{ $t(`default.image`) }}
                  <div class="handleFile">
                    <input type="file" ref="file" class="custom-file" @change="fileSelect" />
                    <input type="text" v-model="editModel.imageName" readonly />
                    <button class="upload" @click="upload">{{ $t("default.browse") }}</button>
                    <button :class="formModel.isDeleteFile ? 'delete-active' : 'delete'"
                      @click="formModel.isDeleteFile = !formModel.isDeleteFile">{{ $t("default.delete") }}</button>
                  </div>
                </label>
                <div class="form-box" v-show="!formModel.isDeleteFile">
                  <img :src="imageUrlShow" style="width: 100%; display: block; margin: auto" />
                  <img v-show="false" :src="imageUrlShow" ref="img" style="display: block; margin: auto;" />
                </div>
                <!-- <div class="form-box">
                    <label>
                      {{ $t(`${i18nPrefix}dialog.imageType`) }}
                      <input type="text" v-model="editModel.imageType" disabled />
                    </label>
                    <label>
                      {{ $t(`${i18nPrefix}dialog.imageSize`) }}
                      <input type="text" v-model="editModel.imageSize" disabled />
                    </label>
                </div>-->
              </div>
              <div class="form-box-half form-column">
                <label>
                  {{ $t(`${i18nPrefix}editV2.activityContent`) }}
                  <ckeditor v-model="formModel.html" :editor-url="this.GLOBAL.CKEditorUrl"></ckeditor>
                </label>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div class="advance-block">
        <button class="confirm-btn main-style-btn" @click="submit" :disabled="formulaDisabled">{{ $t("default.confirm") }}</button>
        <button class="normal-btn-v2 main-style-btn" @click="$emit('toggle')">{{ $t("default.cancel") }}</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  emptyFormModel,
  rules,
  discountTypeList,
  discountRule,
  discountStatus,
  apiSubmitData
} from "./model";
import {
  getPlatformList,
  discountCreate,
  discountUpdate
} from "@/api/features/discountV2";
import {
  transToday,
  joinT,
  transDateForOrin,
  spliceTForDate
} from "@/utils/transDateUtils";
import { JSONFormat } from "@/utils/checkData";
import Message from "@/layout/components/Message";
import DepositEdit from "./components/deposit.vue";
import RebateEdit from "./components/rebate.vue";

export default {
  name: "Edit",
  props: ["editData", "i18nPrefix"],
  components: {
    DepositEdit,
    RebateEdit
  },
  data() {
    return {
      emptyFormModel,
      rules,
      discountTypeList,
      discountRule,
      discountStatus,
      apiSubmitData,
      isFrist: false, // 判斷是否是修改時欄位注入
      memberTag: {
        list: [],
        select: []
      },
      memberDiscount: {
        list: [],
        select: []
      },
      memberStar: {
        list: [],
        select: []
      },
      formModel: undefined,
      selectDiscountType: "",
      startTime: transToday("from"),
      endTime: transToday("to"),
      editModel: {},
      editModelTmp: {},
      formData: new FormData(),
      imageUrlShow: "",
      currEditComponent: undefined,
      rulesError: [],
      rebateData: [],
      editFirstField: {
        discountType: false,
        dispatchType: false,
        applyType: false
      },
      formulaDisabled: false
    };
  },
  watch: {
    "formModel.discountType": {
      handler(type) {
        this.selectDiscountType = this.discountTypeList.find(
          e => e.value === type
        ).id;

        // 移除申請方式
        if (
          !this.isFrist &&
          this.selectDiscountType !== "RebateV2" &&
          this.selectDiscountType !== "LoseRebate" &&
          this.selectDiscountType !== "realTimeRebate"
        ) {
          this.formModel.applyType = "";
        }

        // 移除公式
        if (
          this.selectDiscountType === "RebateV2" ||
          this.selectDiscountType === "LoseRebate" ||
          this.selectDiscountType === "realTimeRebate"
        ) {
          this.formModel.formula = "";
        }
        // 移除前台顯示
        if (this.selectDiscountType == "realTimeRebate") {
          this.formModel.isShowFont = false;
        }

        this.formModel.discountCategory = this.discountTypeList.find(
          e => e.value === type
        ).discountCategory;

        if (type === 12) {
          this.formModel.stairBase = 999;
        } else {
          this.formModel.stairBase = 1;
        }

        if (!this.isFrist) this.formModel.applyType = this.applyType[0]?.value;

        if (this.isFrist) {
          this.editFirstField.discountType = true;
        }
      }
    },
    "formModel.isDeleteFile": {
      handler(e) {
        this.editModel = e ? {} : _.cloneDeep(this.editModelTmp);
      }
    },
    "formModel.bounsAmountLimit": {
      handler(e) {
        this.formModel.bounsAmountLimit = e < -1 ? -1 : e;
      }
    },
    "formModel.bounsAmountLower": {
      handler(e) {
        this.formModel.bounsAmountLower = e < 0 ? 0 : e;
      }
    },
    "formModel.bounsMultiple": {
      handler(e) {
        this.formModel.bounsMultiple = e < 0 ? 0 : e;
        this.formModel.bounsMultiple = Math.trunc(this.formModel.bounsMultiple);
      }
    },
    "formModel.applyType": {
      handler(e) {
        if (!this.isFrist && (e !== 1 || (e == 2 && this.formModel.dispatchType !== 3))) this.formModel.isBlockDuplicateIP = false;
        if (e !== 2) this.formModel.isMutuallyExclusivesType = false;
        if (
          !this.isFrist &&
          this.selectDiscountType !== "RebateV2" &&
          this.selectDiscountType !== "realTimeRebate"
        ) {
          this.formModel.dispatchType = "";
        }
        if (!this.isFrist && this.selectDiscountType !== "LoseRebate") this.formModel.dispatchType = 1;

        if (!this.isFrist) this.formModel.dispatchType = this.dispatchType[0]?.value;

        if (this.isFrist) {
          this.editFirstField.applyType = true;
        }
      }
    },
    "formModel.dispatchType": {
      handler(e) {
        if (!this.isFrist && this.formModel.applyType == 2 && e !== 3) this.formModel.isBlockDuplicateIP = false;

        if (this.isFrist) {
          this.editFirstField.dispatchType = true;
        }
      }
    },
    "formModel.name"(val) {
      this.formModel.name = val.trim();
    },
    isFrist(v) {
      var tmp = this.handleIsFirst;
    }
  },
  computed: {
    ...mapGetters(["getDiscountTypeList", "getUserinfo", "getGamesCategorys"]),
    applyType() {
      let tmp = _.cloneDeep(this.discountRule.applyType);

      if (this.selectDiscountType === "NewRegister") {
        // 移除 客服申請
        tmp.splice(
          tmp.findIndex(e => e.key === "customer"),
          1
        );
        // 移除 手動
        tmp.splice(
          tmp.findIndex(e => e.key === "manual"),
          1
        );
      }

      if (this.selectDiscountType === "LoseRebate") {
        // 移除 手動
        tmp.splice(
          tmp.findIndex(e => e.key === "manual"),
          1
        );
      }

      if (this.selectDiscountType === "PeriodFirstDeposit") {
        // 移除 客服申請
        tmp.splice(
          tmp.findIndex(e => e.key === "customer"),
          1
        );
      }

      return tmp;
    },
    dispatchType() {
      let tmp = _.cloneDeep(this.discountRule.dispatchType);
      // 移除 系統派發
      if (this.formModel.applyType !== 1) {
        tmp.splice(
          tmp.findIndex(e => e.key === "auto"),
          1
        );
      }
      // 移除 手動派發
      if (this.selectDiscountType === "PeriodFirstDeposit" || (this.formModel.applyType == 1 && this.selectDiscountType === "LoseRebate")) {
        tmp.splice(
          tmp.findIndex(e => e.key === "manual"),
          1
        );
      }
      // 移除 入金審核派發
      if (this.formModel.applyType !== 2 || this.selectDiscountType === "LoseRebate") {
        tmp.splice(
          tmp.findIndex(e => e.key === "payBonusByOrder"),
          1
        );
      }
      if (this.formModel.applyType === "") {
        tmp = [];
      }

      return tmp;
    },
    handleIsFirst() {
      let hasFalse = Object.values(this.editFirstField).includes(false);

      if (!hasFalse) {
        this.isFrist = false;
      }
    }
  },
  async created() {
    this.formModel = _.cloneDeep(this.emptyFormModel);

    // 取得互斥優惠 會員等級 會員標籤
    await getPlatformList(this.editData?.id ?? 0).then(res => {
      this.memberTag.list = res.data.tag ?? [];
      this.memberDiscount.list = res.data.discountSettingList ?? [];
      this.memberStar.list = res.data.star ?? [];
    });

    // 處理修改欄位
    await this.processEditColumn();
  },
  methods: {
    /**
     * 處理修改欄位
     */
    processEditColumn() {
      if (this.editData !== undefined) {
        this.isFrist = true;

        const ruleSetting = JSON.parse(this.editData.ruleSetting);
        for (const [key, value] of Object.entries(this.formModel)) {
          if (this.editData[key] !== undefined) {
            this.formModel[key] = this.editData[key];
          }
          if (ruleSetting[key] !== undefined) {
            this.formModel[key] = ruleSetting[key];
          }
        }
        if (ruleSetting["starList"]) {
          this.rebateData = ruleSetting["starList"];
        }
        this.formModel.payTime = ruleSetting?.payTime?.match(/^(\d{1,2}):/)[1];

        this.formModel.formula = JSON.stringify(this.formModel.formula);
        this.formModel.startTime = spliceTForDate(this.formModel.startTime);
        this.formModel.endTime = spliceTForDate(this.formModel.endTime);
        this.startTime = transDateForOrin(this.formModel.startTime);
        this.endTime = transDateForOrin(this.formModel.endTime);
        this.imageUrlShow = this.editData.imageUrl;
        this.formModel.remark = this.formModel.remark ?? "";
        this.formModel.html = this.formModel.html ?? "";

        // 處理多選套件格式
        if (this.formModel.includeTags.length > 0) {
          this.memberTag.select = this.memberTag.list.filter(e => {
            return this.formModel.includeTags.indexOf(e.id) > -1;
          });
        }
        if (this.formModel.includeLevels.length > 0) {
          this.memberStar.select = this.memberStar.list.filter(e => {
            return this.formModel.includeLevels.indexOf(e.star) > -1;
          });
        }
        if (this.formModel.mutuallyExclusives.length > 0) {
          this.memberDiscount.select = this.memberDiscount.list.filter(e => {
            return this.formModel.mutuallyExclusives.indexOf(e.id) > -1;
          });
        }
      }
    },
    /**
     * 送出
     */
    async submit() {
      this.formModel.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.formModel.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone)
        : undeffined;
      this.formModel.formula = this.formModel.formula.replace(/\n/g, "");
      this.formModel.includeTags = [];
      this.formModel.includeLevels = [];
      this.formModel.mutuallyExclusives = [];

      // 輸退反水時間格式調整
      this.formModel.payTime = this.formModel.payTime + this.GLOBAL.startMinute;

      if (this.memberTag.select.length > 0) {
        this.formModel.includeTags = this.memberTag.select.map(e => {
          return e.id;
        });
      }

      if (this.memberStar.select.length > 0) {
        this.formModel.includeLevels = this.memberStar.select.map(e => {
          return e.star;
        });
      }

      if (this.memberDiscount.select.length > 0) {
        this.formModel.mutuallyExclusives = this.memberDiscount.select.map(
          e => {
            return e.id;
          }
        );
      }
      // 刪除圖片
      if (this.formModel.isDeleteFile) {
        this.formData.delete("file");
      }
      // 驗證欄位
      this.checkParms()
        .then(async () => {
          let data = _.cloneDeep(this.apiSubmitData);
          // api 參數處理
          data = await this.apiParms(data);
          data.ruleSetting.formula =
            data.ruleSetting.formula !== ""
              ? JSON.parse(data.ruleSetting.formula)
              : "";

          for (const [key, value] of Object.entries(data)) {
            this.formData.delete(key);
            this.formData.append(
              key,
              key === "ruleSetting" ? JSON.stringify(value) : value
            );
          }

          let result = {};
          if (this.editData === undefined) {
            await discountCreate(this.formData).then(res => {
              result = res;
            });
          } else {
            await discountUpdate(this.formData).then(res => {
              result = res;
            });
          }

          if (result) {
            this.$emit("toggle");
            this.$emit("search");
          }
        })
        .catch(e => {
          console.log("error", e);
        });
    },
    /**
     * 檢查驗證
     */
    checkParms() {
      const vm = this;
      this.rulesError = [];
      return new Promise((resolve, reject) => {
        let success = true;
        let i = 0;

        for (const [key, rule] of Object.entries(vm.rules)) {
          try {
            rule.forEach(function (r) {
              let message = "";
              const skip = r.skipType?.indexOf(vm.selectDiscountType) > -1;
              if (skip) {
                message = "";
              } else if (
                r.required === true &&
                (vm.formModel[key] === undefined || vm.formModel[key] === "")
              ) {
                success = false;
                message = vm.$t(r.message);
              } else if (
                r.checkJson === true &&
                vm.formModel[key] !== undefined &&
                vm.formModel[key] !== ""
              ) {
                let json = JSONFormat(vm.formModel[key]);
                if (!json) {
                  success = false;
                  message = vm.$t(r.message);
                }
              }

              vm.rulesError.push({
                key: key,
                message: message
              });
              if (vm.$refs[`rules${key}`]) {
                vm.$refs[`rules${key}`].innerText = message;
              }

              if (message !== "") {
                if (i === 0) {
                  Message.error(message, 1000);
                  vm.$refs.dialog.scrollTop = 0;
                }
                i++;
                throw new Error();
              }
            });
          } catch (e) { }
        }

        if (success) {
          resolve();
        } else {
          // 回傳失敗
          reject();
        }
      });
    },
    /**
     * api 參數處理
     */
    async apiParms(obj) {
      let result = {};
      await this.processParms(obj).then(e => {
        result = e;
      });
      return result;
    },
    processParms(obj) {
      const vm = this;
      return new Promise(async resolve => {
        let tmp = {};
        for (const [key, value] of Object.entries(obj)) {
          if (typeof value === "object") {
            tmp[key] = await vm.apiParms(value);
          } else {
            if (key === "starList") {
              tmp[key] = this.rebateData.map(e => {
                return {
                  star: e.star,
                  bounsMultiple: e.bounsMultiple * 1,
                  gameCategories: e.gameCategories.map(data => {
                    return { id: data.id, rate: data.rate * 1 };
                  })
                };
              });
            } else {
              tmp[key] =
                isNaN(vm.formModel[key]) ||
                  typeof vm.formModel[key] === "boolean" ||
                  typeof vm.formModel[key] === "object" ||
                  vm.formModel[key] === ""
                  ? vm.formModel[key]
                  : Number(vm.formModel[key]);
              //tmp[key] = vm.formModel[key];
            }
          }
        }

        resolve(tmp);
      });
    },
    /**
     * 圖片處理
     */
    upload() {
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    async fileSelect(e) {
      this.formData.delete("file");

      this.editModel.imageUrl = URL.createObjectURL(e.target.files[0]);
      this.imageUrlShow = URL.createObjectURL(e.target.files[0]);
      this.editModel.imageName = e.target.files[0].name;
      this.editModel.imageType = e.target.files[0].type;
      this.editModel.imageSize = e.target.files[0].size;

      this.editModelTmp = _.cloneDeep(this.editModel);
      this.formModel.isDeleteFile = false;
      this.formData.append("file", e.target.files[0]);
    },
    changeFormulaDisabled(data) {
      this.formulaDisabled = data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.dialog-container {
  width: 100% !important;

  .editor-block {
    width: 100%;
    padding: 0 5px;
  }
}

.hideData {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-box .example {
  padding: 0 5px;

  &-row {
    margin-bottom: 3px;
    white-space: pre-line;
  }
}
</style>