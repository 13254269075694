<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.tag") }}
            <input type="text" v-model="keyData" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}value`) }}
            <input type="text" v-model="valueData" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}type`) }}
            <select v-model="searchForm.type" disabled>
              <option :value="1 * 1">
                {{ $t(`${i18nPrefix}register`) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}registerDateFrom`) }}
            <date-picker v-model="createStartTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}registerDateTo`) }}
            <date-picker v-model="createEndTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}lastLoginDateFrom`) }}
            <date-picker v-model="lastLoginStartTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}lastLoginDateTo`) }}
            <date-picker v-model="lastLoginEndTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
          <tr>
            <th>{{ $t("default.member") }}</th>
            <th>{{ $t("default.tag") }}</th>
            <th>{{ $t(`${i18nPrefix}value`) }}</th>
            <th class="sort" @click="dataSort('updateTime', 'time')">{{ $t(`${i18nPrefix}registerDate`) }} {{ direction("memberCreateTime") }}</th>
            <th class="sort" @click="dataSort('publishTime', 'time')">{{ $t(`${i18nPrefix}lastLoginDate`) }} {{ direction("memberLastLoginTime") }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!tableList || tableList.length === 0">
            <td colspan="5">{{ $t("default.noData") }}</td>
          </tr>
          <tr v-for="list of tableList" :key="list.id">
            <td>{{ list.memberAccount }}</td>
            <td>{{ list.key }}</td>
            <td>{{ list.value }}</td>
            <td>{{ list.memberCreateTime }}</td>
            <td>{{ list.memberLastLoginTime }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
    />
  </div>
</template>

<script>
import { searchModel } from "./model";
import { getMemberAttributionList } from "@/api/SEO-function/MemberAttribution";
import Pagination from "@/layout/components/Pagination";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import { joinT } from "@/utils/transDateUtils";
import Message from "@/layout/components/Message";

export default {
  name: "MemberAttribution",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      createStartTime: undefined,
      createEndTime: undefined,
      lastLoginStartTime: undefined,
      lastLoginEndTime: undefined,
      keyData: undefined,
      valueData: undefined,
      i18nPrefix: "SEO-Function.MemberAttribution."
    };
  },
  components: {
    Pagination,
  },
  mixins: [handlePageUtils],
  methods: {
    search() {
      this.searchForm.createStartTime = this.createStartTime ? joinT(this.createStartTime + this.GLOBAL.startSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.createEndTime = this.createEndTime ? joinT(this.createEndTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.lastLoginStartTime = this.lastLoginStartTime ? joinT(this.lastLoginStartTime + this.GLOBAL.startSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.lastLoginEndTime = this.lastLoginEndTime ? joinT(this.lastLoginEndTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      if ( this.keyData && this.valueData ) {
        this.searchForm.keyValue = {};
        this.searchForm.keyValue.key = this.keyData;
        this.searchForm.keyValue.value = this.valueData;
      }else if(( this.keyData || this.valueData )){
        Message.error("缺少標籤或值", 1000)
        return
      }else {
        this.searchForm.keyValue = undefined;
      }
      getMemberAttributionList(this.searchForm).then((res) => {
        this.allData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>