<template>
  <div style="width:100%;">
    <div class="divider-v2">
      <!-- 規則 -->
      <div class="divider-v2-text divider-v2-left">{{ $t(`default.rule`) }}</div>
    </div>
    <div class="form-box" v-if="selectDiscountType !== ''">
      <div class="form-box-half form-column">
        <div class="form-box">
          <!-- 申請方式 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.apply`) }}
            <select v-model="formModel.applyType" :disabled="selectDiscountType != 'LoseRebate'">
              <option v-for="item of applyType" :key="item.key" :value="item.value">{{ $t(item.name) }}</option>
            </select>
            <span ref="rulesapplyType" class="form-rules"></span>
          </label>
          <!-- 派發方式 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.dispatch`) }}
            <select v-model="formModel.dispatchType" :disabled="selectDiscountType != 'LoseRebate'">
              <option v-for="item of dispatchType" :key="item.key" :value="item.value">{{ $t(item.name) }}</option>
            </select>
            <span ref="rulesdispatchType" class="form-rules"></span>
          </label>
        </div>
      </div>
      <div class="form-box-half form-column" v-show="selectDiscountType == 'LoseRebate' && formModel.dispatchType == 1">
        <div class="form-box">
          <!-- 申請方式 -->
          <label>
            {{ $t(`${i18nPrefix}editV2.period`) }}
            <select v-model="formModel.calculationCycle">
              <option v-for="item of GLOBAL.settlement" :key="item.id" :value="item.id">{{ $t(item.name) }}</option>
            </select>
          </label>
          <!-- endTime -->
          <label>
            <span>{{ $t(`${i18nPrefix}endTime`) }}</span>
            <date-picker v-model="formModel.payTime" format="HH" value-type="format" type="time"></date-picker>
          </label>
        </div>
      </div>
    </div>
    <div class="form-box star-box">
      <div class="divider-v2-area" v-for="rebate of rebateData" :key="rebate.star">
        <div class="divider-v2-text divider-v2-left">
          {{ $t(memberStar.list.find(e=> e.star == rebate.star).name) }} 
          <span class="copy" @click="copy(rebate.star)">{{ $t(`${i18nPrefix}editV2.applied`) }}</span>
        </div>
        <!-- 優惠出款賠率 -->
        <label>
          {{ $t(`${i18nPrefix}editV2.bounsMultiple`) }}
          <input type="number" min="0" v-model="rebate.bounsMultiple" @keyup="rebate.bounsMultiple =
        GLOBAL.checkValue(rebate.bounsMultiple, 2, 0, 100)" />
        </label>
        <!-- 各遊戲種類倍率 -->
        <label v-for="gamesCategory of rebate.gameCategories" :key="gamesCategory.id">
          {{ $t(gamesCategorys.find(e=> e.id == gamesCategory.id).name) + ' ％' }}
          <input type="number" min="0"
            v-model="gamesCategory.rate"
            @keyup="gamesCategory.rate =
        GLOBAL.checkValue(gamesCategory.rate, 2, 0, 100)" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "RebateEdit",
  props: [
    "i18nPrefix",
    "selectDiscountType",
    "discountRule",
    "formModel",
    "payTime",
    "applyType",
    "dispatchType",
    "memberTag",
    "memberDiscount",
    "memberStar",
    "editModel",
    "editModelTmp",
    "imageUrlShow",
    "rulesError",
    "rebateData",
    "gamesCategorys"
  ],
  components: { Multiselect },
  data() {
    return {
    };
  },
  watch: {
    rulesError: {
      handler(e) {
        if (e.length > 0) {
          const vm = this;
          e.forEach(function (item) {
            if (vm.$refs[`rules${item.key}`]) {
              vm.$refs[`rules${item.key}`].innerText = item.message;
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
  },
  async created() {
    // this.formModel = _.cloneDeep(this.emptyFormModel);
    if (this.rebateData?.length === 0) {
      this.processRebatData(); 
      this.formModel.applyType =
        this.selectDiscountType == 'realTimeRebate' || this.selectDiscountType == 'LoseRebate' ? 1 : 3;
      this.formModel.dispatchType =
        this.selectDiscountType == 'realTimeRebate' || this.selectDiscountType == 'LoseRebate' ? 1 : 2;
    }
    if (this.selectDiscountType == 'realTimeRebate') this.formModel.isShowFont = false;
  },
  async beforeCreate() { },
  methods: {
    processRebatData() {
      const vm = this;
      this.memberStar.list.forEach(function (star, index) {
        vm.$set(vm.rebateData, index, {
          star: star.star,
          bounsMultiple: 0,
          gameCategories: []
        });
        vm.gamesCategorys.forEach(function (item, _index) {
          vm.$set(vm.rebateData[index].gameCategories, _index, {
            id: item.id,
            rate: 0
          });
        });
      });
    },
    copy(star) {
      const vm = this;
      var copyData = {...this.rebateData.find(e=> e.star == star)};
      var copyGameCategories = [...copyData.gameCategories];
      vm.rebateData.forEach(function (item, index) {
        if (item.star <= star) {
          return;
        }
        
        var tmp = {...item};
        // vm.rebateData[index] = copyData;
        // vm.rebateData[index].star = tmp.star

        vm.$set(vm.rebateData, index, {
          star: tmp.star,
          bounsMultiple: copyData.bounsMultiple,
          gameCategories: []
        });

        copyGameCategories.forEach(function (item, _index) {
          var tmp = {...item};
          vm.$set(vm.rebateData[index].gameCategories, _index, {
            id: tmp.id,
            rate: tmp.rate
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
@import "~@/styles/dialogCloseIcon.scss";
@import "~@/styles/discountSetup.scss";

.hideData {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-box .example {
  padding: 0 5px;

  &-row {
    margin-bottom: 3px;
    white-space: pre-line;
  }
}

.star-box {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

.copy {
  color: #f1a813;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .star-box {
    grid-template-columns: repeat(1, 100%);
  }

}
</style>