var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "header" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "create-btn main-style-btn",
                  attrs: {
                    disabled:
                      !_vm.getUserinfo.permissions.includes(99999) &&
                      !_vm.getUserinfo.permissions.includes(61101),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialog(undefined, "Create")
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("default.create")) +
                      _vm._s(_vm.$t("title.group")) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}groupName`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}stage`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}numberOfMember`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}bankCount`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}active`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}creator`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}createDate`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}editor`))),
                    ]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}updateDate`))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableList, function (group) {
                    return _c("tr", { key: group.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "table-btn main-style-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(99999) &&
                                !_vm.getUserinfo.permissions.includes(61102),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(group, "Edit")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(group.name))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.starLevelList.find(
                              (data) => data.id === group.starLevelSettingId
                            ).star
                          ) +
                            " " +
                            _vm._s(_vm.$t("default.star"))
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(group.memberCount))]),
                      _c("td", [_vm._v(_vm._s(group.agentBankCount))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            group.status === 1
                              ? _vm.$t("default.active")
                              : _vm.$t("default.inactive")
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(group.createAccount))]),
                      _c("td", [_vm._v(_vm._s(group.createTime))]),
                      _c("td", [_vm._v(_vm._s(group.updateAccount))]),
                      _c("td", [_vm._v(_vm._s(group.updateTime))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: { starLevelList: _vm.starLevelList },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }