<template>
  <div>
    <div class="container-view">
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="divider"></div>
      <div class="control">
        <!-- <button @click="control(0)">會員存款</button>
        <button @click="control(1)">會員提款</button> -->
        <button @click="control(61000)" :class="{ active: controlType == 61000 }">{{ $t("mail-box.riskControl") }}</button>
        <button @click="control(10109)" :class="{ active: controlType == 10109 }">{{ $t("mail-box.bankPic") }}</button>
        <!-- <button @click="control(4)">會員優惠</button> -->
        <button @click="control(120200)" :class="{ active: controlType == 120200 }">{{ $t("mail-box.blackList") }}</button>
      </div>
    </div>
    <div class="container-view">
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th style="width: 20%"></th>
                <th>{{ $t("mail-box.type") }}</th>
                <th style="width: 20%">{{ $t("mail-box.number") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!mailList || !mailList.length">
                <td colspan="3">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="mail of mailList" :key="mail.id">
                <td>
                  <button class="normal-btn" @click="readMail(mail)">Go</button>
                </td>
                <td>
                  {{
                    GLOBAL.i18nCheckName(getMailTypeList.find(data => data.id === mail.permissionId))
                  }}
                </td>
                <td>No. {{ mail.orderId }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { privateMessage, readPrivateMessage } from "@/api/user";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MailBox",
  data() {
    return {
      controlType : 61000,
      mailList: undefined,
    };
  },
  created() {
    this.controlType = this.$route.query?.permissionId ?? this.controlType;
    this.control(this.controlType);
  },
  watch: {
    "getMailData": function(){
      this.control(this.controlType);
    },
    "$route.query.permissionId": function(val){
      this.control(val);
    },
  },
  computed: {
    ...mapGetters(["getMailData","getMailTypeList"]),
  },
  methods: {
    ...mapMutations({
      SET_MAIL: "user/SET_MAIL",
    }),
    async readMail(mail) {
      const routes = this.$router.options.routes.filter((data) => {
        return data.children;
      });
      const path = this.matchPath(routes, mail);
      if(this.controlType != 10109) await readPrivateMessage({ id: mail.id });
      this.$router.push({
        path: `${path.parent}/${path.children}`,
        query: { orderId: `${mail.orderId}` },
      });
    },
    matchPath(elem, mail) {
      let done = false;
      let path = "";
      if (mail.permissionId === 10109) mail.permissionId = 10100
      for (let i = 0; i < elem.length; i++) {
        if (done) break;
        for (let j = 0; j < elem[i].children.length; j++) {
          if (mail.permissionId === elem[i].children[j].meta?.permission) {
            path = { parent: elem[i].path, children: elem[i].children[j].path };
            done = true;
            break;
          }
        }
      }
      return path;
    },
    control(type) {
      this.controlType = type;
      this.mailList = this.getMailData.filter(
        data => data.permissionId == type).sort((a,b) => b.orderId - a.orderId);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  button {
    background-color: #00a65a;
    border: none;
    border-radius: 5px;
    padding: 0.5% 1%;
    transition: 0.2s;
    margin: 0 1%;
    color: #fff;
    transition: .3s;
    &:hover {
        background-color: #008d4c;
    }
  }
}

.active {
  background-color: #036738 !important;
}
</style>