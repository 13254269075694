var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c(
          "div",
          { staticClass: "dialog-block" },
          [
            _c("div", { staticClass: "header" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("default.process")))]),
            ]),
            _c("div", { staticClass: "divider" }),
            _vm._l(_vm.processData, function (list, index) {
              return _c(
                "a",
                {
                  key: list.id,
                  class: { red: _vm.nowId === list.id },
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.showProcess(_vm.processData[index])
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1))]
              )
            }),
            _c("main", [
              _c("div", { staticClass: "table-block" }, [
                _c("table", { staticClass: "mt-3" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c("th", { staticClass: "w-25" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("default.processAccount") +
                                  ": " +
                                  _vm.auditAccount
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("default.processTime") +
                                  ": " +
                                  _vm.processTime
                              ) +
                              " "
                          ),
                        ]),
                        _vm._l(_vm.processTitle, function (title) {
                          return _c("th", { key: title.key }, [
                            _vm._v(_vm._s(title.name)),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("tbody", [
                    !_vm.oldValue && !_vm.newValue
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: _vm.processTitle.length + 1 } },
                            [_vm._v(_vm._s(_vm.$t("default.noData")))]
                          ),
                        ])
                      : _vm._e(),
                    _vm.oldValue
                      ? _c(
                          "tr",
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("default.oldValue"))),
                            ]),
                            _vm._l(_vm.processTitle, function (data) {
                              return _c(
                                "td",
                                { key: "old" + data.key },
                                [
                                  _vm._t(
                                    `oldValue_${data.key}`,
                                    function () {
                                      return [
                                        _vm._v(_vm._s(_vm.oldValue[data.key])),
                                      ]
                                    },
                                    null,
                                    _vm.oldValue
                                  ),
                                ],
                                2
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.newValue
                      ? _c(
                          "tr",
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("default.newValue"))),
                            ]),
                            _vm._l(_vm.processTitle, function (data) {
                              return _c(
                                "td",
                                { key: "new" + data.key },
                                [
                                  _vm._t(
                                    `newValue_${data.key}`,
                                    function () {
                                      return [
                                        _vm._v(_vm._s(_vm.newValue[data.key])),
                                      ]
                                    },
                                    null,
                                    _vm.newValue
                                  ),
                                ],
                                2
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }