<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.admin") }}
            <input type="text" v-model="searchForm.userAccount" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}ip`) }}
            <input type="text" v-model="searchForm.ip" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker v-model="startTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker v-model="endTime" format="YYYY-MM-DD HH:mm" value-type="format" type="datetime"></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}device`) }}
            <select v-model="searchForm.device">
              <option
                v-for="list of getDeviceList"
                :key="list.id"
                :value="list.id"
              >
                {{ $t(list.name) }}
              </option>
            </select>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th>{{ $t("default.admin") }}</th>
              <th>{{ $t(`${i18nPrefix}lastLoginTime`) }}</th>
              <th>{{ $t(`${i18nPrefix}ip`) }}</th>
              <th>{{ $t("default.remark") }}</th>
              <th>{{ $t(`${i18nPrefix}device`) }}</th>
              <th>{{ $t(`${i18nPrefix}browser`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList || tableList.length === 0">
              <td colspan="7">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="list of tableList" :key="list.userAccount + list.lastLoginTime">
              <td>{{ list.userAccount }}</td>
              <td>{{ list.lastLoginTime }}</td>
              <td>{{ list.ip }}</td>
              <td>{{ list.remark }}</td>
              <td>
                {{ GLOBAL.i18nCheckName(getDeviceList.find((data) => {
                  return data.id === list.device
                })) }}
              </td>
              <td>{{ list.browser }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="totalCount > 0"
        :totalCount="totalCount"
        @handlePage="handlePage"
      />
    </div>
  </div>
</template>

<script>
import { managerLoginRecordList } from "@/api/report-various/manager-login-record";
import { mapGetters } from "vuex";
import { searchModel } from "./model";
import Pagination from "@/layout/components/Pagination";
import { transToday,joinT } from "@/utils/transDateUtils";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import QuickSearch from "@/layout/components/QuickSearch";


export default {
  name: "ManagerRecord",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      i18nPrefix: "report-various.manager-record."
    };
  },
  mixins: [handlePageUtils],
  components: {
    Pagination,
    QuickSearch,
    
  },
  computed: {
    ...mapGetters(["getDeviceList"]),
  },
  created() {
    this.searchForm.device = this.getDeviceList[0].id;
  },
  methods: {
    search() {
      this.searchForm.startTime = this.startTime ? joinT(this.startTime + this.GLOBAL.startSecond+ this.GLOBAL.timeZone) : undefined;
      this.searchForm.endTime = this.endTime ? joinT(this.endTime + this.GLOBAL.endSecond+ this.GLOBAL.timeZone) : undefined;
      managerLoginRecordList(this.searchForm).then((res) => {
        this.allData = res.data;
        this.dataSort('lastLoginTime', 'time', true);
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>