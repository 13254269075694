<template>
  <div class="d-flex form-column align-items-center h-full">
    <div class="title">{{ $t(`${i18nPrefix}lossMembers.title`) }}</div>
    <div class="table-block-white-v2">
      <!-- <TreeTable ref="treeTable" :list="list" :column="tableColumn">
        <template v-slot:column_agent="props">
          {{ `${(props.referralCode ? props.referralCode : '')}/${props.agentAccount}/${props.generalAgentAccount}` }}
        </template>
      </TreeTable> -->
      <table>
        <thead>
          <tr>
            <th>{{ $t(`${this.i18nPrefix}lossMembers.memberAccount`) }}</th>
            <th>{{ $t(`${this.i18nPrefix}lossMembers.agent`) }}</th>
            <th>{{ $t(`${this.i18nPrefix}lossMembers.winLose`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) of list" :key="index">
            <td>
              {{ item.memberAccount }}
            </td>
            <td>
              {{ `${(item.referralCode ? item.referralCode : '')}/${item.agentAccount}/${item.generalAgentAccount}` }}
            </td>
            <td>
              {{ item.winLose }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TreeTable from "@/layout/components/TreeTable";

export default {
  name: "lossMembers",
  props: ['list', 'i18nPrefix'],
  data() {
    return {
      digital: new Intl.NumberFormat(),
    };
  },
  watch: {

  },
  components: {
    TreeTable
  },
  computed: {
    tableColumn() {
      return [
        {
          key: 'memberAccount',
          name: this.$t(`${this.i18nPrefix}lossMembers.memberAccount`)
        },
        {
          key: 'agent',
          name: this.$t(`${this.i18nPrefix}lossMembers.agent`)
        },
        {
          key: 'winLose',
          name: this.$t(`${this.i18nPrefix}lossMembers.winLose`)
        },
      ];
    }
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";

.h-full {
  height: 100%;
}
</style>