var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container-view" },
        [
          _c("div", { staticClass: "header" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title))),
            ]),
            _c(
              "button",
              {
                staticClass: "confirm-btn main-style-btn",
                attrs: {
                  disabled:
                    !_vm.getUserinfo.permissions.includes(99999) &&
                    !_vm.getUserinfo.permissions.includes(70101),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleDialog(undefined, "Create")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.create")) + " ")]
            ),
          ]),
          _c("main", [
            _c("div", { staticClass: "table-block" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "15%" } }),
                    _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}name`)))]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}description`))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableList, function (list) {
                    return _c("tr", { key: list.id }, [
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "normal-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(99999) &&
                                !_vm.getUserinfo.permissions.includes(70102),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDialog(list, "Edit")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.edit")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            attrs: {
                              disabled:
                                !_vm.getUserinfo.permissions.includes(99999) &&
                                !_vm.getUserinfo.permissions.includes(70103),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(list)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("default.delete")) + " ")]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(list.name))]),
                      _c("td", [_vm._v(_vm._s(list.description))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm.totalCount > 0
            ? _c("Pagination", {
                attrs: { totalCount: _vm.totalCount },
                on: { handlePage: _vm.handlePage },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.currDialog,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  i18nPrefix: _vm.i18nPrefix,
                  getPermissionsTitle: _vm.getPermissionsTitle,
                  firstData: _vm.firstData,
                  secData: _vm.secData,
                },
                on: {
                  toggle: function ($event) {
                    _vm.currDialog = undefined
                  },
                  create: _vm.create,
                  edit: _vm.edit,
                },
              },
              "component",
              _vm.dialogData,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.getShowConfirm
            ? _c("Confirm", {
                on: { toggle: _vm.TOGGLE_CONFIRM, delete: _vm.deleteFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }