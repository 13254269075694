var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-view" }, [
      _c(
        "button",
        { staticClass: "search-btn main-style-btn", on: { click: _vm.search } },
        [_vm._v(_vm._s(_vm.$t("default.search")))]
      ),
      _c("div", { staticClass: "header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("title." + _vm.$route.meta.title)))]),
      ]),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "input-block" }, [
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("default.member")) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchForm.memberAccount,
                  expression: "searchForm.memberAccount",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.searchForm.memberAccount },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.searchForm, "memberAccount", $event.target.value)
                },
              },
            }),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("default.agent")) + " "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.agentId,
                    expression: "searchForm.agentId",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.searchForm,
                      "agentId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.getAgentListAddDefault, function (list) {
                return _c(
                  "option",
                  { key: list.id, domProps: { value: list.id } },
                  [_vm._v(_vm._s(_vm.$t(list.account)) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}startTime`)) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.startTime,
                  expression: "startTime",
                },
              ],
              attrs: { type: "date" },
              domProps: { value: _vm.startTime },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.startTime = $event.target.value
                },
              },
            }),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}endTime`)) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.endTime,
                  expression: "endTime",
                },
              ],
              attrs: { type: "date" },
              domProps: { value: _vm.endTime },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.endTime = $event.target.value
                },
              },
            }),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`)) + " "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.gamePlatform,
                    expression: "searchForm.gamePlatform",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.searchForm,
                      "gamePlatform",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.getGamePlatformsAddDefault, function (list) {
                return _c(
                  "option",
                  { key: list.id, domProps: { value: list.id } },
                  [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("default.tag")) + " "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchForm.tagId,
                    expression: "searchForm.tagId",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.searchForm,
                      "tagId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: 0 * 1 } }, [
                  _vm._v(_vm._s(_vm.$t("default.all"))),
                ]),
                _vm._l(_vm.tagList, function (list) {
                  return _c(
                    "option",
                    { key: list.id, domProps: { value: list.id * 1 } },
                    [_vm._v(_vm._s(list.name))]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticClass: "permission-block" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}choice`)))]),
          _c(
            "div",
            { staticClass: "permission-box" },
            _vm._l(_vm.moreFilter, function (list) {
              return _c("label", { key: list.id, staticClass: "isIgnore" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchForm.ignoreGamesCategories,
                      expression: "searchForm.ignoreGamesCategories",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: list.id,
                    checked: Array.isArray(_vm.searchForm.ignoreGamesCategories)
                      ? _vm._i(_vm.searchForm.ignoreGamesCategories, list.id) >
                        -1
                      : _vm.searchForm.ignoreGamesCategories,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.searchForm.ignoreGamesCategories,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = list.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.searchForm,
                              "ignoreGamesCategories",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.searchForm,
                              "ignoreGamesCategories",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.searchForm, "ignoreGamesCategories", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$t(list.name)) + " "),
              ])
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "advance-block" },
          [
            _c("QuickSearch", {
              attrs: { controlType: _vm.controlType },
              on: { changeTime: _vm.changeTime },
            }),
            _c(
              "button",
              {
                staticClass: "search-pc-btn main-style-btn px-5",
                on: { click: _vm.search },
              },
              [_vm._v(_vm._s(_vm.$t("default.search")))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container-view" },
      [
        _c("main", [
          _c("div", { staticClass: "table-block" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.member")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("default.tag")))]),
                  _c(
                    "th",
                    {
                      staticClass: "sort",
                      on: {
                        click: function ($event) {
                          return _vm.dataSort("reportTime", "time")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}reportTime`)) +
                          " " +
                          _vm._s(_vm.direction("reportTime"))
                      ),
                    ]
                  ),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}gamePlatform`))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}star`)))]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}bankAccountName`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betAmount`))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}betInfo`))),
                  ]),
                  _c(
                    "th",
                    {
                      staticClass: "sort",
                      on: {
                        click: function ($event) {
                          return _vm.dataSort("winLose", "number")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}winLose`)) +
                          " " +
                          _vm._s(_vm.direction("winLose"))
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "tbody",
                [
                  !_vm.tableList || !_vm.tableList.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "9" } }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.tableList, function (list, index) {
                    return _c(
                      "tr",
                      {
                        key:
                          list.memberAccount +
                          "id_" +
                          list.betAmount +
                          "_" +
                          list.winLose +
                          "_" +
                          index,
                      },
                      [
                        _c("td", [_vm._v(_vm._s(list.memberAccount))]),
                        _c(
                          "td",
                          { class: { "tags-td": Array.isArray(list.tags) } },
                          [
                            list.tags
                              ? _c("Tags", {
                                  attrs: {
                                    tags: list.tags,
                                    tagList: _vm.tagList,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(list.reportTime))]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.GLOBAL.i18nCheckName(
                                  _vm.getGamePlatformsAddDefault.find(
                                    (data) => data.id === list.gamePlatform
                                  )
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(list.star) +
                              " " +
                              _vm._s(_vm.$t("default.star"))
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(list.bankAccountName))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.digital.format(list.betAmount))),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.digital.format(list.validBetAmount))
                          ),
                        ]),
                        _c("td", { class: _vm.fontColor(list.winLose) }, [
                          _vm._v(_vm._s(_vm.digital.format(list.winLose))),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tableList && _vm.tableList.length,
                          expression: "tableList && tableList.length",
                        },
                      ],
                    },
                    [
                      _c("td", { attrs: { colspan: "5" } }),
                      _c("td", [_vm._v(_vm._s(_vm.$t("default.total")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.digital.format(_vm.totalBetAmount))),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.digital.format(_vm.totalValidBetAmount))
                        ),
                      ]),
                      _c("td", { class: _vm.fontColor(_vm.totalWinLose) }, [
                        _vm._v(_vm._s(_vm.digital.format(_vm.totalWinLose))),
                      ]),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm.totalCount > 0
          ? _c("Pagination", {
              attrs: { totalCount: _vm.totalCount },
              on: { handlePage: _vm.handlePage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }