import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function getAgentList() {
    return request({
        url: "/seo/page/selectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateStatus(data) {
    return request({
        url: "/seo/page/updateStatus",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getSEOList(data) {
    return request({
        url: "/seo/page/list",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function create(data) {
    return request({
        url: "/seo/page/create",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function clone(data) {
    return request({
        url: "/seo/page/clone",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteList(data) {
    return request({
        url: "/seo/page/delete",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getMetaData(data) {
    return request({
        url: "/seo/page/getMetaData",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function updateMetaData(data) {
    return request({
        url: "/seo/page/updateMetaData",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDomains(data) {
    return request({
        url: "/seo/page/getDomains",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function addDomains(data) {
    return request({
        url: "/seo/page/addDomains",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function deleteDomain(data) {
    return request({
        url: "/seo/page/deleteDomain",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}