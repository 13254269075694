export let searchModel = {
    startTime: undefined,
    endTime: undefined,
    account: undefined,
    auditStatuses: undefined,
    amount: 0,
    page:  {
        "take": 10,
        "skip": 0
    },
}