<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <date-picker
              v-model="startTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <date-picker
              v-model="endTime"
              format="YYYY-MM-DD HH:mm"
              value-type="format"
              type="datetime"
            ></date-picker>
          </label>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <div class="table-block">
        <table>
          <thead>
            <tr>
              <th style="width: 20%"></th>
              <th>{{ $t(`${i18nPrefix}mailType`) }}</th>
              <th style="width: 20%">{{ $t(`${i18nPrefix}orderId`) }}</th>
              <th style="width: 20%">{{ $t(`${i18nPrefix}createTime`) }}</th>
              <th style="width: 20%">{{ $t(`${i18nPrefix}remark`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!tableList">
              <td colspan="5">{{ $t("default.noData") }}</td>
            </tr>
            <tr v-for="mail of tableList" :key="mail.id">
              <td>
                <button class="table-btn main-style-btn" @click="readMail(mail)">Go</button>
              </td>
              <td>{{ $t("Permission." + mail.permissionName) }}</td>
              <td>No. {{ mail.orderId }}</td>
              <td>{{ mail.createTime }}</td>
              <td>{{ mail.remark ? mail.remark : $t(`${i18nPrefix}notRead`) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
  </div>
</template>

<script>
import { privateMessageList, readPrivateMessage } from "@/api/user";
import { mapGetters } from "vuex";
import { searchModel } from "./model";
import { transToday, joinT } from "@/utils/transDateUtils";
import Pagination from "@/layout/components/Pagination";
import QuickSearch from "@/layout/components/QuickSearch";
import handlePageUtils from "@/layout/mixins/handlePageUtils";

export default {
  name: "MailHistory",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transToday("from"),
      endTime: transToday("to"),
      i18nPrefix: "report-various.mail-history."
    };
  },
  mixins: [handlePageUtils],
  components: {
    Pagination,
    QuickSearch
  },
  computed: {
    ...mapGetters(["getMailTypeList"])
  },
  methods: {
    search() {
      this.searchForm.startTime = this.startTime
        ? joinT(this.startTime + this.GLOBAL.startSecond + this.GLOBAL.timeZone)
        : undefined;
      this.searchForm.endTime = this.endTime
        ? joinT(this.endTime + this.GLOBAL.endSecond + this.GLOBAL.timeZone)
        : undefined;
      privateMessageList(this.searchForm).then(res => {
        this.allData = res.data;
        this.dataSort("createTime", "time", true);
      });
    },
    async readMail(mail) {
      const routes = this.$router.options.routes.filter(data => {
        return data.children;
      });
      const path = this.matchPath(routes, mail);
      await readPrivateMessage({ id: mail.id });
      this.$router.push({
        path: `${path.parent}/${path.children}`,
        query: { orderId: `${mail.orderId}` }
      });
    },
    matchPath(elem, mail) {
      let done = false;
      let path = "";
      let permission = Math.floor(mail.permissionId / 10) * 10
      for (let i = 0; i < elem.length; i++) {
        if (done) break;
        for (let j = 0; j < elem[i].children.length; j++) {
          if (permission === elem[i].children[j].meta?.permission) {
            path = { parent: elem[i].path, children: elem[i].children[j].path };
            done = true;
            break;
          }
        }
      }
      return path;
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>