var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _vm._l(_vm.treeData, function (list) {
        return [
          _c(
            "li",
            {
              key: list.id,
              class: { active: list.id === _vm.agentId },
              on: {
                click: function ($event) {
                  return _vm.$emit("passAgentId", list)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-user" }),
              _c("span", [_vm._v(" " + _vm._s(list.name) + " ")]),
            ]
          ),
          list.subAgents
            ? [
                _c("TreeNode", {
                  key: list.subAgents.id,
                  attrs: { treeData: list.subAgents, agentId: _vm.agentId },
                  on: { passAgentId: _vm.passAgentId },
                }),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }