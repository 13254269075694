var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-mask2" }, [
    _c("div", { ref: "dialog", staticClass: "dialog-container" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t(
                `default.${_vm.editData !== undefined ? "edit" : "create"}`
              )
            )
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "dialog-close",
            on: {
              click: function ($event) {
                return _vm.$emit("toggle")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
      _c("div", { staticClass: "dialog-block" }, [
        _c("main", [
          _c("div", { staticClass: "input-block" }, [
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.basicSettings`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c("label", [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}campaignName`)) + " "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formModel.name,
                        expression: "formModel.name",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(`${_vm.i18nPrefix}campaignName`),
                    },
                    domProps: { value: _vm.formModel.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formModel, "name", $event.target.value)
                      },
                    },
                  }),
                  _c("span", { ref: "rulesname", staticClass: "form-rules" }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("default.startTime")))]),
                    _c("date-picker", {
                      attrs: {
                        format: "YYYY-MM-DD",
                        "value-type": "format",
                        type: "datetime",
                      },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("label", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.days`))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.days,
                        expression: "days",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: "1",
                      oninput: "value=value.replace(/^0+(\\d)|[^\\d]+/g,'')",
                    },
                    domProps: { value: _vm.days },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.days = _vm._n($event.target.value)
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "normal-btn calc w-100",
                    on: { click: _vm.generateDateArray },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`${_vm.i18nPrefix}dialog.generateDateArray`)
                      )
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.eligibility`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  {
                    staticClass: "label-row",
                    staticStyle: { padding: "15px", width: "50% !important" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ruleSetting.participateStatus.isNewMember,
                          expression:
                            "ruleSetting.participateStatus.isNewMember",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.ruleSetting.participateStatus.isNewMember
                        )
                          ? _vm._i(
                              _vm.ruleSetting.participateStatus.isNewMember,
                              null
                            ) > -1
                          : _vm.ruleSetting.participateStatus.isNewMember,
                      },
                      on: {
                        change: function ($event) {
                          var $$a =
                              _vm.ruleSetting.participateStatus.isNewMember,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.ruleSetting.participateStatus,
                                  "isNewMember",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.ruleSetting.participateStatus,
                                  "isNewMember",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.ruleSetting.participateStatus,
                              "isNewMember",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isNewMember`)) +
                        " "
                    ),
                  ]
                ),
                _c("label", { attrs: { for: "" } }),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.includeAgents`)
                        ) +
                        " "
                    ),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "id",
                          placeholder: "",
                          options: _vm.agentList,
                          multiple: true,
                          "close-on-select": false,
                        },
                        model: {
                          value: _vm.agentData,
                          callback: function ($$v) {
                            _vm.agentData = $$v
                          },
                          expression: "agentData",
                        },
                      },
                      [
                        _c("template", { slot: "noOptions" }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-box-half form-column" }, [
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberLevel`)
                        ) +
                        " "
                    ),
                    _c("multiselect", {
                      attrs: {
                        label: "name",
                        "track-by": "star",
                        placeholder: "",
                        options: _vm.starList,
                        multiple: true,
                        "close-on-select": false,
                      },
                      model: {
                        value: _vm.memberStar,
                        callback: function ($$v) {
                          _vm.memberStar = $$v
                        },
                        expression: "memberStar",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-box" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`${_vm.i18nPrefix}dialog.limitMemberTag`)
                        ) +
                        " "
                    ),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "id",
                          placeholder: "",
                          options: _vm.tagList,
                          multiple: true,
                          "close-on-select": false,
                        },
                        model: {
                          value: _vm.memberTag,
                          callback: function ($$v) {
                            _vm.memberTag = $$v
                          },
                          expression: "memberTag",
                        },
                      },
                      [
                        _c("template", { slot: "noOptions" }, [
                          _vm._v(_vm._s(_vm.$t("default.noData"))),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.rewardSetting`))),
              ]),
            ]),
            _c("div", { staticClass: "form-box form-column" }, [
              _c("div", { staticClass: "date-table w-100" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.date`))),
                      ]),
                      _c("th", { attrs: { colspan: "4" } }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.taskSetting`))
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "4" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.baseRewardSetting`)
                          )
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "6" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}dialog.VIPRewardSetting`)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.taskType`))
                        ),
                      ]),
                      _c("th", { staticClass: "w-3words" }, [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.taskGoal`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.gameCategory`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.gamePlatform`))
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}rewardType`))),
                      ]),
                      _c("th", { staticClass: "w-3words" }, [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}rewardCount`))),
                      ]),
                      _c("th", { staticClass: "w-3words" }, [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}wageringRate`))),
                      ]),
                      _c("th", { staticClass: "w-4words" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}campaignLuckyDrawListId`)
                          )
                        ),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.VIP`))),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("default.star")))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}rewardType`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}rewardCount`))),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}wageringRate`))),
                      ]),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(`${_vm.i18nPrefix}campaignLuckyDrawListId`)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.ruleSetting.rewardSetting.dates,
                      function (list) {
                        return _c("tr", { key: list.date }, [
                          _c("td", [_vm._v(_vm._s(list.date))]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.taskSetting.taskType,
                                    expression: "list.taskSetting.taskType",
                                  },
                                ],
                                attrs: { disabled: _vm.isCampaign },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.taskSetting,
                                      "taskType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.taskType, function (list) {
                                return _c(
                                  "option",
                                  {
                                    key: list.id,
                                    domProps: { value: list.id },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(list.name)))]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: list.taskSetting.taskGoal,
                                  expression: "list.taskSetting.taskGoal",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "w-3words",
                              attrs: {
                                type: "number",
                                min: "0",
                                disabled:
                                  _vm.isCampaign ||
                                  list.taskSetting.taskType == 0,
                              },
                              domProps: { value: list.taskSetting.taskGoal },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    list.taskSetting,
                                    "taskGoal",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.taskSetting.gameCategory,
                                    expression: "list.taskSetting.gameCategory",
                                  },
                                ],
                                attrs: {
                                  disabled:
                                    _vm.isCampaign ||
                                    list.taskSetting.taskType == 0 ||
                                    list.taskSetting.taskType == 1,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.taskSetting,
                                      "gameCategory",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.getGamesCategorysAddDefault,
                                function (list) {
                                  return _c(
                                    "option",
                                    {
                                      key: list.id,
                                      domProps: { value: list.id },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.taskSetting.gamePlatform,
                                    expression: "list.taskSetting.gamePlatform",
                                  },
                                ],
                                attrs: {
                                  disabled:
                                    _vm.isCampaign ||
                                    list.taskSetting.taskType == 0 ||
                                    list.taskSetting.taskType == 1 ||
                                    list.taskSetting.gameCategory == 0,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.taskSetting,
                                      "gamePlatform",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.getGamePlatformsAddDefault,
                                function (list) {
                                  return _c(
                                    "option",
                                    {
                                      key: list.id,
                                      domProps: { value: list.id },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.baseRewardSetting.rewardType,
                                    expression:
                                      "list.baseRewardSetting.rewardType",
                                  },
                                ],
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.baseRewardSetting,
                                      "rewardType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.rewardType, function (list) {
                                return _c(
                                  "option",
                                  {
                                    key: list.id,
                                    domProps: { value: list.id },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(list.name)))]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: list.baseRewardSetting.rewardCount,
                                  expression:
                                    "list.baseRewardSetting.rewardCount",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "w-3words",
                              attrs: { type: "number", min: "0" },
                              domProps: {
                                value: list.baseRewardSetting.rewardCount,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    list.baseRewardSetting,
                                    "rewardCount",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: list.baseRewardSetting.wageringRate,
                                  expression:
                                    "list.baseRewardSetting.wageringRate",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "w-3words",
                              attrs: {
                                type: "number",
                                min: "0",
                                disabled:
                                  list.baseRewardSetting.rewardType == 2,
                              },
                              domProps: {
                                value: list.baseRewardSetting.wageringRate,
                              },
                              on: {
                                keyup: function ($event) {
                                  list.baseRewardSetting.wageringRate =
                                    _vm.GLOBAL.checkValue(
                                      list.baseRewardSetting.wageringRate
                                    )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    list.baseRewardSetting,
                                    "wageringRate",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      list.baseRewardSetting
                                        .campaignLuckyDrawListId,
                                    expression:
                                      "list.baseRewardSetting.campaignLuckyDrawListId",
                                  },
                                ],
                                staticClass: "w-3words",
                                attrs: {
                                  disabled:
                                    list.baseRewardSetting.rewardType == 1,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.baseRewardSetting,
                                      "campaignLuckyDrawListId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.periodList, function (list) {
                                return _c(
                                  "option",
                                  {
                                    key: list.id,
                                    domProps: { value: list.id },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(list.name)))]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: list.VIPRewardSetting.isEnabled,
                                  expression: "list.VIPRewardSetting.isEnabled",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  list.VIPRewardSetting.isEnabled
                                )
                                  ? _vm._i(
                                      list.VIPRewardSetting.isEnabled,
                                      null
                                    ) > -1
                                  : list.VIPRewardSetting.isEnabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = list.VIPRewardSetting.isEnabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          list.VIPRewardSetting,
                                          "isEnabled",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          list.VIPRewardSetting,
                                          "isEnabled",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      list.VIPRewardSetting,
                                      "isEnabled",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.VIPRewardSetting.memberLevel,
                                    expression:
                                      "list.VIPRewardSetting.memberLevel",
                                  },
                                ],
                                attrs: {
                                  disabled: !list.VIPRewardSetting.isEnabled,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.VIPRewardSetting,
                                      "memberLevel",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.starList, function (list) {
                                return _c(
                                  "option",
                                  {
                                    key: list.id,
                                    domProps: { value: list.id },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(list.name)))]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: list.VIPRewardSetting.rewardType,
                                    expression:
                                      "list.VIPRewardSetting.rewardType",
                                  },
                                ],
                                attrs: {
                                  disabled: !list.VIPRewardSetting.isEnabled,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.VIPRewardSetting,
                                      "rewardType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.rewardType, function (list) {
                                return _c(
                                  "option",
                                  {
                                    key: list.id,
                                    domProps: { value: list.id },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(list.name)))]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: list.VIPRewardSetting.rewardCount,
                                  expression:
                                    "list.VIPRewardSetting.rewardCount",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "w-3words",
                              attrs: {
                                type: "number",
                                min: "0",
                                disabled: !list.VIPRewardSetting.isEnabled,
                              },
                              domProps: {
                                value: list.VIPRewardSetting.rewardCount,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    list.VIPRewardSetting,
                                    "rewardCount",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: list.VIPRewardSetting.wageringRate,
                                  expression:
                                    "list.VIPRewardSetting.wageringRate",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "w-3words",
                              attrs: {
                                type: "number",
                                min: "0",
                                disabled:
                                  !list.VIPRewardSetting.isEnabled ||
                                  list.VIPRewardSetting.rewardType == 2,
                              },
                              domProps: {
                                value: list.VIPRewardSetting.wageringRate,
                              },
                              on: {
                                keyup: function ($event) {
                                  list.VIPRewardSetting.wageringRate =
                                    _vm.GLOBAL.checkValue(
                                      list.VIPRewardSetting.wageringRate
                                    )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    list.VIPRewardSetting,
                                    "wageringRate",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      list.VIPRewardSetting
                                        .campaignLuckyDrawListId,
                                    expression:
                                      "list.VIPRewardSetting.campaignLuckyDrawListId",
                                  },
                                ],
                                staticClass: "w-3words",
                                attrs: {
                                  disabled:
                                    !list.VIPRewardSetting.isEnabled ||
                                    list.VIPRewardSetting.rewardType == 1,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      list.VIPRewardSetting,
                                      "campaignLuckyDrawListId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.periodList, function (list) {
                                return _c(
                                  "option",
                                  {
                                    key: list.id,
                                    domProps: { value: list.id },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(list.name)))]
                                )
                              }),
                              0
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "divider-v2-area",
                staticStyle: { display: "block" },
              },
              [
                _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        `${_vm.i18nPrefix}dialog.accumulatedSignInRewardSetting`
                      )
                    )
                  ),
                ]),
                _c("div", { staticClass: "table-block w-100" }, [
                  _c("div", { staticClass: "add-th" }, [
                    _c(
                      "button",
                      {
                        staticClass: "add-btn",
                        staticStyle: {
                          "background-color": "green",
                          margin: "5px",
                        },
                        on: {
                          click: function ($event) {
                            _vm.ruleSetting.accumulatedSignInRewardSetting
                              .isEnabled
                              ? _vm.addData("+")
                              : ""
                          },
                        },
                      },
                      [_c("span", [_vm._v("+")])]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "add-btn",
                        on: {
                          click: function ($event) {
                            _vm.ruleSetting.accumulatedSignInRewardSetting
                              .isEnabled
                              ? _vm.addData("-")
                              : ""
                          },
                        },
                      },
                      [_c("span", [_vm._v("-")])]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "days-row",
                      staticStyle: { padding: "15px", width: "50% !important" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.ruleSetting.accumulatedSignInRewardSetting
                                .isEnabled,
                            expression:
                              "ruleSetting.accumulatedSignInRewardSetting.isEnabled",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.ruleSetting.accumulatedSignInRewardSetting
                              .isEnabled
                          )
                            ? _vm._i(
                                _vm.ruleSetting.accumulatedSignInRewardSetting
                                  .isEnabled,
                                null
                              ) > -1
                            : _vm.ruleSetting.accumulatedSignInRewardSetting
                                .isEnabled,
                        },
                        on: {
                          change: function ($event) {
                            var $$a =
                                _vm.ruleSetting.accumulatedSignInRewardSetting
                                  .isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.ruleSetting
                                      .accumulatedSignInRewardSetting,
                                    "isEnabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.ruleSetting
                                      .accumulatedSignInRewardSetting,
                                    "isEnabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.ruleSetting.accumulatedSignInRewardSetting,
                                "isEnabled",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.isEnabled`)) +
                          " "
                      ),
                    ]
                  ),
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.days`))
                          ),
                        ]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}rewardType`))),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}rewardCount`))
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.i18nPrefix}wageringRate`))
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`${_vm.i18nPrefix}campaignLuckyDrawListId`)
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.ruleSetting.accumulatedSignInRewardSetting.days,
                        function (list) {
                          return _c("tr", [
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.day,
                                    expression: "list.day",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  disabled:
                                    !_vm.ruleSetting
                                      .accumulatedSignInRewardSetting
                                      .isEnabled || _vm.isCampaign,
                                },
                                domProps: { value: list.day },
                                on: {
                                  keyup: function ($event) {
                                    list.day = _vm.GLOBAL.checknull(list.day)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "day",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.rewardType,
                                      expression: "list.rewardType",
                                    },
                                  ],
                                  attrs: {
                                    disabled:
                                      !_vm.ruleSetting
                                        .accumulatedSignInRewardSetting
                                        .isEnabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        list,
                                        "rewardType",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.rewardType, function (list) {
                                  return _c(
                                    "option",
                                    {
                                      key: list.id,
                                      domProps: { value: list.id * 1 },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(list.name)) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.rewardCount,
                                    expression: "list.rewardCount",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  disabled:
                                    !_vm.ruleSetting
                                      .accumulatedSignInRewardSetting.isEnabled,
                                },
                                domProps: { value: list.rewardCount },
                                on: {
                                  keyup: function ($event) {
                                    list.rewardCount = _vm.GLOBAL.checknull(
                                      list.rewardCount
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "rewardCount",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: list.wageringRate,
                                    expression: "list.wageringRate",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  disabled:
                                    !_vm.ruleSetting
                                      .accumulatedSignInRewardSetting.isEnabled,
                                },
                                domProps: { value: list.wageringRate },
                                on: {
                                  keyup: function ($event) {
                                    list.wageringRate = _vm.GLOBAL.checknull(
                                      list.wageringRate
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      list,
                                      "wageringRate",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.campaignLuckyDrawListId,
                                      expression:
                                        "list.campaignLuckyDrawListId",
                                    },
                                  ],
                                  attrs: {
                                    disabled:
                                      !_vm.ruleSetting
                                        .accumulatedSignInRewardSetting
                                        .isEnabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        list,
                                        "campaignLuckyDrawListId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.periodList, function (list) {
                                  return _c(
                                    "option",
                                    {
                                      key: list.id,
                                      domProps: { value: list.id },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(list.name)))]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "divider-v2" }, [
              _c("div", { staticClass: "divider-v2-text divider-v2-left" }, [
                _vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.front`))),
              ]),
            ]),
            _c("div", { staticClass: "imgDiv w-100" }, [
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("File")
                    },
                  },
                },
                [
                  !_vm.File
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("default.clickToUpload")) + " Image"
                        ),
                      ])
                    : _c("img", { attrs: { src: _vm.File } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("MobileFile")
                    },
                  },
                },
                [
                  !_vm.MobileFile
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("default.clickToUpload")) +
                            " MobileImage"
                        ),
                      ])
                    : _c("img", { attrs: { src: _vm.MobileFile } }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "previewDiv",
                  on: {
                    click: function ($event) {
                      return _vm.upload("AppFile")
                    },
                  },
                },
                [
                  !_vm.AppFile
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("default.clickToUpload")) + " AppImage"
                        ),
                      ])
                    : _c("img", { attrs: { src: _vm.AppFile } }),
                ]
              ),
            ]),
            _c("input", {
              ref: "file",
              staticClass: "custom-file",
              attrs: { type: "file" },
              on: { change: _vm.fileSelect },
            }),
            _c("div", { staticClass: "form-box w-100" }, [
              _c(
                "label",
                { staticClass: "w-100" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`${_vm.i18nPrefix}dialog.copywriting`)) +
                      " "
                  ),
                  _c("ckeditor", {
                    attrs: { "editor-url": this.GLOBAL.CKEditorUrl },
                    model: {
                      value: _vm.ruleSetting.html,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleSetting, "html", $$v)
                      },
                      expression: "ruleSetting.html",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detail == undefined,
              expression: "detail == undefined",
            },
          ],
          staticClass: "advance-block-release",
        },
        [
          _c("div", { staticClass: "m-auto" }, [
            _c(
              "button",
              {
                staticClass: "normal-btn",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.confirm")))]
            ),
            _c(
              "button",
              {
                staticClass: "delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggle")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("default.cancel")))]
            ),
          ]),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isCampaign == undefined && _vm.editData !== undefined,
                  expression:
                    "isCampaign == undefined && editData !== undefined",
                },
              ],
              staticClass: "btn-orange",
              on: {
                click: function ($event) {
                  return _vm.submit(true)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t(`${_vm.i18nPrefix}release`)))]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }