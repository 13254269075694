import request from "@/utils/request";
import { getToken } from "@/utils/auth";

export function depositList(data) {
    return request({
        url: "/member/deposit/Page",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditDeposit(data) {
    return request({
        url: "/member/deposit/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositTotal(data) {
    return request({
        url: "/member/deposit/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function SelectMenu(data) {
    return request({
        url: "/MemberDepositCrypto/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function depositCryptoList(data) {
    return request({
        url: "/MemberDepositCrypto/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditDepositCrypto(data) {
    return request({
        url: "/MemberDepositCrypto/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositCryptoTotal(data) {
    return request({
        url: "/MemberDepositCrypto/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function cvsSelectMenu(data) {
    return request({
        url: "/MemberDepositCVS/SelectMenu",
        method: "get",
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function depositCVSList(data) {
    return request({
        url: "/MemberDepositCVS/List",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function auditDepositCVS(data) {
    return request({
        url: "/MemberDepositCVS/audit",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}

export function getDepositCVSTotal(data) {
    return request({
        url: "/MemberDepositCVS/Total",
        method: "post",
        data,
        headers: { "Authorization": `token ${getToken()}` }
    });
}