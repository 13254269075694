<template>
  <div>
    <div class="container-view">
      <button class="search-btn main-style-btn" @click="search">{{ $t("default.search") }}</button>
      <div class="header">
        <h5>{{ $t("title." + $route.meta.title) }}</h5>
      </div>
      <div class="main">
        <div class="input-block">
          <label>
            {{ $t("default.member") }}
            <input type="text" v-model="searchForm.memberAccount" />
          </label>
          <label>
            {{ $t("default.agent") }}
            <select v-model="searchForm.agentId">
              <option v-for="list of getAgentListAddDefault" :key="list.id" :value="list.id">{{ $t(list.account) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t(`${i18nPrefix}startTime`) }}
            <input type="date" v-model="startTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}endTime`) }}
            <input type="date" v-model="endTime" />
          </label>
          <label>
            {{ $t(`${i18nPrefix}gamePlatform`) }}
            <select v-model="searchForm.gamePlatform">
              <option v-for="list of getGamePlatformsAddDefault" :key="list.id" :value="list.id">{{ $t(list.name) }}
              </option>
            </select>
          </label>
          <label>
            {{ $t("default.tag") }}
            <select v-model="searchForm.tagId">
              <option :value="0 * 1">{{ $t("default.all") }}</option>
              <option v-for="list of tagList" :key="list.id" :value="list.id * 1">{{ list.name }}</option>
            </select>
          </label>
        </div>
        <div class="permission-block">
          <p>{{ $t(`${i18nPrefix}choice`) }}</p>
          <div class="permission-box">
            <!-- <label
                class="permission-list"
            >
              <input
                  type="checkbox"
                  v-model="ignoreGamePlayType"
              />
              {{ $t(`${i18nPrefix}ignoreGamePlayType1`) }}
            </label>-->
            <label class="isIgnore" v-for="list of moreFilter" :key="list.id">
              <input type="checkbox" v-model="searchForm.ignoreGamesCategories" :value="list.id" />
              {{ $t(list.name) }}
            </label>
          </div>
        </div>
        <div class="advance-block">
          <QuickSearch @changeTime="changeTime" :controlType="controlType"></QuickSearch>
          <button class="search-pc-btn main-style-btn px-5" @click="search">{{ $t("default.search") }}</button>
        </div>
      </div>
    </div>
    <div class="container-view">
      <main>
        <div class="table-block">
          <table>
            <thead>
              <tr>
                <th>{{ $t("default.member") }}</th>
                <th>{{ $t("default.tag") }}</th>
                <th class="sort" @click="dataSort('reportTime', 'time')">{{ $t(`${i18nPrefix}reportTime`) }} {{
                  direction('reportTime') }}</th>
                <th>{{ $t(`${i18nPrefix}gamePlatform`) }}</th>
                <th>{{ $t(`${i18nPrefix}star`) }}</th>
                <th>{{ $t(`${i18nPrefix}bankAccountName`) }}</th>
                <th>{{ $t(`${i18nPrefix}betAmount`) }}</th>
                <th>{{ $t(`${i18nPrefix}betInfo`) }}</th>
                <th class="sort" @click="dataSort('winLose', 'number')">{{ $t(`${i18nPrefix}winLose`) }} {{
                  direction('winLose') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!tableList || !tableList.length">
                <td colspan="9">{{ $t("default.noData") }}</td>
              </tr>
              <tr v-for="(list, index) of tableList" :key="list.memberAccount +
                'id_' +
                list.betAmount +
                '_' +
                list.winLose +
                '_' +
                index
                ">
                <td>{{ list.memberAccount }}</td>
                <td :class="{ 'tags-td': Array.isArray(list.tags) }">
                  <Tags v-if="list.tags" :tags="list.tags" :tagList="tagList"></Tags>
                </td>
                <td>{{ list.reportTime }}</td>
                <td>
                  {{
                    GLOBAL.i18nCheckName(getGamePlatformsAddDefault.find((data) => data.id === list.gamePlatform))
                  }}
                </td>
                <td>{{ list.star }} {{ $t("default.star") }}</td>
                <td>{{ list.bankAccountName }}</td>
                <td>{{ digital.format(list.betAmount) }}</td>
                <td>{{ digital.format(list.validBetAmount) }}</td>
                <td :class="fontColor(list.winLose)">{{ digital.format(list.winLose) }}</td>
              </tr>
              <tr v-show="tableList && tableList.length">
                <td colspan="5"></td>
                <td>{{ $t("default.total") }}</td>
                <td>{{ digital.format(totalBetAmount) }}</td>
                <td>{{ digital.format(totalValidBetAmount) }}</td>
                <td :class="fontColor(totalWinLose)">{{ digital.format(totalWinLose) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <Pagination v-if="totalCount > 0" :totalCount="totalCount" @handlePage="handlePage" />
    </div>
  </div>
</template>

<script>
import {
  memberWinLoseList
} from "@/api/report-bet-count/week-lose-win";
import { searchModel } from "./model";
import Tags from "@/layout/components/tags";
import Pagination from "@/layout/components/Pagination";
import { transTodayDate } from "@/utils/transDateUtils";
import handlePageUtils from "@/layout/mixins/handlePageUtils";
import { mapGetters } from "vuex";
import QuickSearch from "@/layout/components/QuickSearch";

export default {
  name: "WeekLoseWin",
  data() {
    return {
      searchForm: _.cloneDeep(searchModel),
      startTime: transTodayDate("from"),
      endTime: transTodayDate("to"),
      ignoreGamePlayType: false,
      controlType: true,
      moreFilter: [
        {
          id: 5,
          name: "report-bet-count.week-lose-win.ignoreGamePlayType2"
        },
        {
          id: 6,
          name: "report-bet-count.week-lose-win.ignoreGamePlayType3"
        }
      ],
      total: 1000,
      totalBetAmount: 0,
      totalValidBetAmount: 0,
      totalWinLose: 0,
      i18nPrefix: "report-bet-count.week-lose-win."
    };
  },
  mixins: [handlePageUtils],
  components: {
    Tags,
    Pagination,
    QuickSearch
  },
  computed: {
    ...mapGetters([
      "getAgentListAddDefault",
      "tagList",
      "getGamePlatformsAddDefault"
    ])
  },
  methods: {
    search() {
      this.searchForm.ignoreGamePlayType = this.ignoreGamePlayType
        ? "539"
        : undefined;
      this.searchForm.gamePlatform =
        this.searchForm.gamePlatform === "0"
          ? 0 * 1
          : this.searchForm.gamePlatform;
      this.searchForm.startTime = this.startTime
        ? this.startTime + this.GLOBAL.startHour + this.GLOBAL.timeZone
        : undefined;
      this.searchForm.endTime = this.endTime
        ? this.endTime + this.GLOBAL.endHour + this.GLOBAL.timeZone
        : undefined;
      memberWinLoseList(this.searchForm).then(res => {
        this.allData = res.data.reports;
        this.totalBetAmount = res.data.totalBetAmount;
        this.totalValidBetAmount = res.data.totalValidBetAmount;
        this.totalWinLose = res.data.totalWinLose;
        this.dataSort("reportTime", "time", true);
      });
    },
    changeTime(time) {
      this.startTime = time.startTime;
      this.endTime = time.endTime;
      this.search();
    },
    fontColor(value) {
      return value < 0 ? "red" : "green";
    }
  }
};
</script>

<style lang="scss" scoped>
.permission-block {
  width: 100%;
  margin-top: 5px;
  margin-left: 17px;
  display: flex;
  flex-direction: column;

  .permission-box {
    label {
      margin-left: 15px;
      display: initial;
    }
  }

  .isIgnore {
    display: inline-block !important;
  }

  input {
    height: inherit;
    padding: initial;
    width: auto !important;
    height: auto !important;
  }
}

.sort {
  cursor: pointer;
}
</style>