var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "dialog-block" }, [
          _c("div", { staticClass: "header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t("default.edit") +
                    " " +
                    _vm.$t("title." + _vm.$route.meta.title)
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "divider" }),
          _c("main", [
            _c("div", { staticClass: "input-block" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}game`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.gamePlatform,
                        expression: "editModel.gamePlatform",
                      },
                    ],
                    attrs: { disabled: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "gamePlatform",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.gameList, function (list) {
                    return _c(
                      "option",
                      { key: list.id, domProps: { value: list.id } },
                      [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "label",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(`${_vm.i18nPrefix}emergencyTime`)) + " "
                  ),
                  _c("date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD HH:mm:ss",
                      "value-type": "format",
                      type: "datetime",
                    },
                    model: {
                      value: _vm.splitEmergencyTime,
                      callback: function ($$v) {
                        _vm.splitEmergencyTime = $$v
                      },
                      expression: "splitEmergencyTime",
                    },
                  }),
                ],
                1
              ),
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t(`${_vm.i18nPrefix}status`)) + " "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editModel.status,
                        expression: "editModel.status",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.editModel,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.getStatusList, function (list) {
                    return _c(
                      "option",
                      { key: list.name, domProps: { value: list.id } },
                      [_vm._v(" " + _vm._s(_vm.$t(list.name)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _c("div", { staticClass: "advance-block" }, [
              _c(
                "button",
                { staticClass: "normal-btn", on: { click: _vm.submit } },
                [_vm._v(_vm._s(_vm.$t("default.confirm")))]
              ),
              _c(
                "button",
                {
                  staticClass: "delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("default.cancel")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }