<template>
    <div class="dw-block">
      <div class="btn-list">
        <button
          v-for="list of btnList"
          :key="list.id"
          @click="curr = list.id"
          :disabled="!getUserinfo.permissions.includes(10605) && list.id === 'Rebate'"
          :class="{ active: curr === list.id }"
        >
          {{ $t(list.name) }}
        </button>
      </div>
      <div class="component-block">
        <transition name="fade" mode="out-in">
          <component 
          :is="curr"
          :i18nPrefix="i18nPrefix"
        ></component>
        </transition>
      </div>
    </div>
</template>
<script>
import MemberLevel from "./member-level";
import Rebate from "./rebate";
import { mapGetters } from "vuex";

export default {
  name: "MemberLevelIndex",
  data() {
    return {
      curr: "MemberLevel",
      btnList: [
        {
          id: "MemberLevel",
          name: 'member.member-level.memberLevel',
        },
        {
          id: "Rebate",
          name: 'member.member-level.rebate',
        },
      ],
      i18nPrefix: "member.member-level.",
    };
  },
  components: {
    MemberLevel,
    Rebate,
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
  },
};
</script>
<style lang="scss" scoped>
.component-block {
  border-radius: 4px;
  border: 1px solid #999;
}
.active {
    background-color: #111fa0 !important;
    color: white;
    border: none;
}
.btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      width: 20%;
      margin: 1%;
      padding: 1%;
      border-radius: 10px;
      border: 1px solid #999;
      background-color: #17a2b8;
      &:hover {
        background-color: #117a8b;
        color: white;
    }
    }
}
@media screen and (max-width: 500px) {
    .btn-list {
      button {
        width: 35%;
      }
    }
}
</style>